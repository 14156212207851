@if (docTypes?.length) {
  <section class="px-4 pt-4 border rounded mb-4"
  [ngClass]="borderClass">
    <h2 class="mb-4">{{ 'ATTACHMENTS' | translate }}</h2>
    @for (docType of docTypes; track docType.id) {
      @if (docType.labelKey) {
        <section>
          <div
            class="card mb-4"
            [ngClass]="
              shouldShowFileInputInvalidMessage(docType)
                ? 'border-danger border-2'
                : borderClass
            "
          >
          <div class="card-header text-white" [ngClass]="backgroundClass">
              {{ docType.labelKey | translate }}
            </div>
            <div class="card-body">
              @if (docType.uploadAvailable) {
                <gk-file-input
                  class="mb-2 bg-color-from-db-for-button-child"
                  [ngClass]="{ required: docType.isObligatory }"
                  [attr.aria-required]="docType.isObligatory"
                  [showPreviews]="true"
                  (filesChange)="handleFileInputAction(docType.id, $event)"
                >
                </gk-file-input>
              }
              @if (docType.matchingRequestFileId) {
                <button
                  type="button"
                  class="btn btn-outline-color-from-db"
                  [ngClass]="{ 'mt-2': docType.uploadAvailable }"
                  (click)="
                    downloadRequestFilePattern(docType.matchingRequestFileId)
                  "
                >
                  <i class="fa fa-download"></i>
                  @if (docType.downloadLabelKey) {
                    <span>{{ docType.downloadLabelKey | translate }}</span>
                  } @else {
                    {{ 'DOCS_TABLE.DOWNLOAD_PATTERN' | translate }}
                  }
                </button>
              }
            </div>
            @if (shouldShowFileInputInvalidMessage(docType)) {
              <div class="card-footer">
                <div class="alert alert-danger mt-2">
                  {{ 'NO_FILE_CHOSEN' | translate }}
                </div>
              </div>
            }
          </div>
        </section>
      }
      @if (isEnablementDocType(docType.id)) {
        <section>
          <div class="card mb-4" [ngClass]="borderClass">
            <div class="card-header text-white" [ngClass]="backgroundClass">
              {{
                geti18nPrefix() +
                  '.DOC_TYPE_DESCRIPTION.PROOF_OF_ENABLEMENT_PAYMENT'
                  | translate
              }}
            </div>
            <div class="card-body">
              <gk-file-input
                class="mb-2 bg-color-from-db-for-button-child"
                [showPreviews]="true"
                (filesChange)="
                  handleProofOfEnablementPaymentFileInputAction($event)
                "
              >
              </gk-file-input>
            </div>
          </div>
        </section>
      }
    }
  </section>
}
