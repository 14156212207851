export interface TfaTotpInitializationCredentialsApi {
  ResetToken: string;
  Url: string;
}

export class TfaTotpInitializationCredentials {
  constructor(public resetToken: string, public url: string) {}

  static fromApiToApp(
    data: TfaTotpInitializationCredentialsApi
  ): TfaTotpInitializationCredentials {
    return new this(data.ResetToken, data.Url);
  }
}
