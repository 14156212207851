import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { TranslateModule } from '@ngx-translate/core';
import { PprfBuildingFormComponent } from './pprf-building-form/pprf-building-form.component';
import { PprfParcelFormComponent } from './pprf-parcel-form/pprf-parcel-form.component';
import { PprfPremisesFormComponent } from './pprf-premises-form/pprf-premises-form.component';
import { PprfPropertyFormComponent } from './pprf-property-form/pprf-property-form.component';
import { PprfTransactionAndPricingFormComponent } from './pprf-transaction-and-pricing-form/pprf-transaction-and-pricing-form.component';
import { PropertyPriceRegisterFormComponent } from './property-price-register-form.component';

@NgModule({
  declarations: [
    PropertyPriceRegisterFormComponent,
    PprfTransactionAndPricingFormComponent,
    PprfPropertyFormComponent,
    PprfPremisesFormComponent,
    PprfParcelFormComponent,
    PprfBuildingFormComponent,
  ],
  imports: [
    CommonModule,
    FormlyModule.forRoot(),
    FormlyMaterialModule,
    ReactiveFormsModule,
    NgbModule,
    TranslateModule,
  ],
  exports: [PropertyPriceRegisterFormComponent],
})
export class PropertyPriceRegisterFormModule {}
