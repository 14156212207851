import { Component, ChangeDetectionStrategy } from '@angular/core';
import { MapControl } from '../../../../controls';
import { MapAction, ToolActionType } from '../../../../models';

@Component({
  selector: 'gk-popup-control',
  templateUrl: './popup-control.component.html',
  styleUrls: ['./popup-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupControlComponent extends MapControl {
  togglePopup(): void {
    this.dispatch.emit(
      new MapAction(ToolActionType.IsVisiblePopupChange, {
        value: !this.mapState.toolsState[this.toolType].isPopupVisible,
        options: { toolType: this.toolType }
      })
    );
  }
}
