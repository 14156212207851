<div>
  <kendo-label [text]="'GK.MAP.SELECT_GEOMETRY_FORMAT' | translate">
    <kendo-dropdownlist
      [data]="geometryFormatList"
      [(ngModel)]="selectedGeomFormat"
      (valueChange)="geomFormatValueChange($event)"
    ></kendo-dropdownlist>
  </kendo-label>
</div>

<div class="mt-2">
  <kendo-label
    [text]="'GK.MAP.SELECT_FILE_WITH_GEOMETRY_IN_FORMAT' | translate"
  >
    <kendo-fileselect
      [multiple]="false"
      [restrictions]="fileRestrictions"
      [(ngModel)]="selectedFiles"
      (select)="select($event)"
    ></kendo-fileselect>
  </kendo-label>
</div>

@if (selectedFiles) {
  <div class="mt-2">
    <p>
      <i [ngClass]="getValidationStatus(isValidFormat)" aria-hidden="true"></i>
      {{
        (isValidFormat ? 'GK.MAP.PROPER_FORMAT' : 'GK.MAP.WRONG_FORMAT')
          | translate
      }}
    </p>
    <p>
      <i
        [ngClass]="getValidationStatus(isValidGeometryType)"
        aria-hidden="true"
      ></i>
      {{
        (isValidGeometryType
          ? 'GK.MAP.WRONG_GEOMETRY_TYPE_EXTENT_POLYGON'
          : 'GK.MAP.WRONG_GEOMETRY_TYPE_EXTENT_NO_POLYGON'
        ) | translate
      }}
    </p>
    <p>
      <i [ngClass]="getValidationStatus(isValidRange)" aria-hidden="true"></i>
      {{
        (isValidRange
          ? 'GK.MAP.GEOMETRY_FITS_EXTENT'
          : 'GK.MAP.GEOMETRY_OUT_OF_EXTENT'
        ) | translate
      }}
    </p>
    <p>
      <i
        [ngClass]="getValidationStatus(isValidTopology)"
        aria-hidden="true"
      ></i>
      {{
        (isValidTopology ? 'GK.MAP.TOPOLOGY_VALID' : 'GK.MAP.TOPOLOGY_ERROR')
          | translate
      }}
    </p>
  </div>
}

@if (showFormatTxt) {
  <div class="flex-column">
    <span
      ><i>{{ 'GK.MAP.PAPER_SIZE' | translate }}: </i>"{{
        'GK.MAP.EXAMPLE_TXT_FORMAT' | translate
      }}"</span
    >
    <span
      ><i>{{ 'GK.MAP.EXAMPLE_WORD' | translate }}: </i>"{{
        'GK.MAP.EXAMPLE_COORDS' | translate
      }}"</span
    >
  </div>
}

@if (!!gkKendoGeomFileSelectActionButtonConfig) {
  <gk-kendo-action-buttons
    [buttonsConfigs]="gkKendoGeomFileSelectActionButtonConfig"
  ></gk-kendo-action-buttons>
}
