import { Component, Input } from '@angular/core';
import { District } from '../../../services/community/community.model';

@Component({
  selector: 'app-district-list',
  templateUrl: './district-list.component.html',
  styleUrls: ['./district-list.component.scss'],
})
export class DistrictListComponent {
  @Input() districts: District[] = [];
}
