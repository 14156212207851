import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { takeWhile } from 'rxjs/operators';
import { DictionaryField } from '../../../gk-dynamic-list/services/dictionary/dictionary.model';
import { FormAlertService } from '../../../services/form-alert/form-alert.service';
import { LegalPersonControlName } from '../../services/law-person-form/law-person-form.model';
import { LegalPersonTypeService } from '../../services/legal-person-type/legal-person-type.service';

@Component({
  selector: 'gk-legal-person-simple-form',
  templateUrl: './legal-person-simple-form.component.html',
})
export class LegalPersonSimpleFormComponent implements OnInit, OnDestroy {
  private isAlive = true;
  @Input() form: UntypedFormGroup;
  @Input() typeVisible = true;
  @Input() submitted: boolean;
  legalPersonControlName = LegalPersonControlName;
  legalPersonTypes: DictionaryField[];

  constructor(
    private legalPersonTypeService: LegalPersonTypeService,
    private formAlertService: FormAlertService
  ) {}

  ngOnInit(): void {
    this.subscribeToLegalPersonTypesList();
    this.setDefaultLegalPersonTypeOnValueReset();
  }

  subscribeToLegalPersonTypesList(): void {
    this.legalPersonTypeService.legalPersonTypes
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((legalPersonTypes) => {
        this.legalPersonTypes = legalPersonTypes;
        this.setDefaultLegalPersonTypeIfParameterIsDefined();
      });
  }

  setDefaultLegalPersonTypeOnValueReset(): void {
    this.getTypeIdFormControl()
      .valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe((value) => {
        if (!value) {
          this.setDefaultLegalPersonTypeIfParameterIsDefined();
        }
      });
  }

  setDefaultLegalPersonTypeIfParameterIsDefined(): void {
    this.getTypeIdFormControl().patchValue(
      this.legalPersonTypes &&
        this.legalPersonTypes[0] &&
        this.legalPersonTypes[0].id
    );
  }

  getTypeIdFormControl(): UntypedFormControl {
    return this.form.get(
      this.legalPersonControlName.TypeId
    ) as UntypedFormControl;
  }

  getNameFormControl(): UntypedFormControl {
    return this.form.get(
      this.legalPersonControlName.Name
    ) as UntypedFormControl;
  }

  shouldShowRequiredAlert(formControl: UntypedFormControl): boolean {
    return this.formAlertService.shouldShowErrorAlert(
      formControl,
      'required',
      this.submitted
    );
  }

  isRequired(formControl: UntypedFormControl): boolean {
    return this.formAlertService.isRequired(formControl);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
