import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {
  DictionaryField,
  DictionaryFieldFromApi,
} from '../../gk-dynamic-list/services/dictionary/dictionary.model';
import { ApiResponse } from '../services.model';
import { OwnershipForm } from './demonstration-of-applicant-right.model';

@Injectable()
export class DemonstrationOfApplicantRightService {
  private demonstrationOfApplicantRights: Observable<DictionaryField[]>;
  private simplifiedDemonstrationOfApplicantRights: Observable<
    DictionaryField[]
  >;
  readonly simplifiedDemonstrationOfApplicantRightsTypes = [
    OwnershipForm.Ownership,
    OwnershipForm.PerpetualUsufruct,
  ];

  constructor(private http: HttpClient) {}

  getSimplifiedDictionary(): Observable<DictionaryField[]> {
    if (!this.simplifiedDemonstrationOfApplicantRights) {
      this.simplifiedDemonstrationOfApplicantRights = this.getDictionary().pipe(
        map((fields) =>
          fields.filter((field) =>
            this.simplifiedDemonstrationOfApplicantRightsTypes.includes(
              +field.id
            )
          )
        )
      );
    }

    return this.simplifiedDemonstrationOfApplicantRights;
  }

  getDictionary(): Observable<DictionaryField[]> {
    if (!this.demonstrationOfApplicantRights) {
      this.demonstrationOfApplicantRights = this.http
        .get<ApiResponse<DictionaryFieldFromApi[]>>('/api/egib/slo/wlad')
        .pipe(
          map((res) =>
            res.Response.map((response) =>
              DictionaryField.fromApiToApp(response)
            )
          ),
          shareReplay(1)
        );
    }

    return this.demonstrationOfApplicantRights;
  }
}
