import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { atLeastOneValidator, getDefaultControlValue } from '@gk/gk-modules';
import { AuthorizedPersonControlName } from '../../../services/authorized-person-form/authorized-person-form.model';
import { AuthorizedPersonFormService } from '../../../services/authorized-person-form/authorized-person-form.service';
import { InvestorDetailsFormService } from '../../../services/investor-details-form/investor-details-form.service';
import { ShippingFormService } from '../../../services/shipping-form/shipping-form.service';
import { FeeWaiverControlName } from '../../../shared/fee-waiver/fee-waiver.model';
import { FeeWaiverFormService } from '../../../shared/fee-waiver/fee-waver-form.service';
import { ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestFormControlName } from './extract-or-certificate-from-local-spatial-development-plan-request-form.model';

@Injectable()
export class ExtractOrCertificateFromLocalSpatialDevelopmentPlanRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private investorDetailsFormService: InvestorDetailsFormService,
    public shippingFormService: ShippingFormService,
    private authorizedPersonFormService: AuthorizedPersonFormService,
    private feeWaiverFormService: FeeWaiverFormService,
  ) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestFormControlName.InvestorDetails]:
        this.investorDetailsFormService.getFormGroup(false, false, false),
      [ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestFormControlName.DismissalReasonExplanationDescription]:
        [getDefaultControlValue(true), [Validators.required]],
      [ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestFormControlName.Comments]:
        [getDefaultControlValue()],
      [ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestFormControlName.Quantity]:
        [1, [Validators.required, Validators.min(1)]],
      [ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestFormControlName.Subject]:
        this.formBuilder.group(
          {
            [ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestFormControlName.WrittenExtractCheckbox]:
              [true],
            [ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestFormControlName.GraphicExtractCheckbox]:
              [true],
            [ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestFormControlName.CertificateCheckbox]:
              [false],
          },
          { validator: atLeastOneValidator() },
        ),
      [ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestFormControlName.ShippingForm]:
        this.shippingFormService.getFormGroup(true, true),
      [AuthorizedPersonControlName.AuthorizedDetailsForm]:
        this.authorizedPersonFormService.getFormGroup(),
      [FeeWaiverControlName.FeeWaiverForm]:
        this.feeWaiverFormService.getFormGroup(),
      [ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestFormControlName.RodoClauseAgreeCheckbox]:
        [false],
    });
  }
}
