@if (formGroup) {
  <form [formGroup]="formGroup" class="form-horizontal">
    <ng-container
      [ngTemplateOutlet]="selectControl"
      [ngTemplateOutletContext]="{
        formGroupControlName: controlName.PrintTemplate,
        label: 'GK.MAP.PRINT.ATTRIBUTES_FORM.TEMPLATE' | translate,
        source: gmpPrintTemplateService.get() | async
      }"
    ></ng-container>

    <ng-container
      [ngTemplateOutlet]="selectControl"
      [ngTemplateOutletContext]="{
        formGroupControlName: controlName.MapScaleResolution,
        label: 'GK.MAP.PRINT.ATTRIBUTES_FORM.MAP_SCALE' | translate,
        source: mapScales,
        sourceValueField: 'zoom',
        sourceLabelField: 'name'
      }"
    ></ng-container>

    <ng-container
      [ngTemplateOutlet]="selectControl"
      [ngTemplateOutletContext]="{
        formGroupControlName: controlName.Quality,
        label: 'GK.MAP.PRINT.ATTRIBUTES_FORM.QUALITY_LABEL' | translate,
        source: gmpPrintQualityService.get() | async
      }"
    ></ng-container>

    <ng-container
      [ngTemplateOutlet]="selectControl"
      [ngTemplateOutletContext]="{
        formGroupControlName: controlName.PrintFooter,
        label: 'GK.MAP.PRINT.ATTRIBUTES_FORM.PRINT_FOOTER' | translate,
        completeObjectValue: true,
        source: gmpPrintFooterService.get() | async
      }"
    ></ng-container>

    <ng-container
      [ngTemplateOutlet]="inputControl"
      [ngTemplateOutletContext]="{
        formGroupControlName: controlName.Subtitle,
        label: 'GK.MAP.PRINT.ATTRIBUTES_FORM.SUBTITLE' | translate
      }"
    ></ng-container>

    <div class="text-end">
      <ng-container
        [ngTemplateOutlet]="matCheckboxControl"
        [ngTemplateOutletContext]="{
          formGroupControlName: controlName.ShowScaleInfo,
          label: 'GK.MAP.PRINT.ATTRIBUTES_FORM.SHOW_SCALE_INFO' | translate
        }"
      ></ng-container>

      <ng-container
        [ngTemplateOutlet]="matCheckboxControl"
        [ngTemplateOutletContext]="{
          formGroupControlName: controlName.ShowCoordinatesInfo,
          label:
            'GK.MAP.PRINT.ATTRIBUTES_FORM.SHOW_COORDINATES_INFO' | translate
        }"
      ></ng-container>

      <ng-container
        [ngTemplateOutlet]="matCheckboxControl"
        [ngTemplateOutletContext]="{
          formGroupControlName: controlName.ShowCornersCoordinates,
          label:
            'GK.MAP.PRINT.ATTRIBUTES_FORM.SHOW_CORNERS_COORDINATES' | translate
        }"
      ></ng-container>

      <!-- TODO: Handle the map legend on the printout -->
      <!-- <ng-container
        [ngTemplateOutlet]="matCheckboxControl"
        [ngTemplateOutletContext]="{
          formGroupControlName: controlName.ShowLegend,
          label:
            'GK.MAP.PRINT.ATTRIBUTES_FORM.SHOW_LEGEND' | translate
        }"
      ></ng-container> -->

      <ng-container
        [ngTemplateOutlet]="matCheckboxControl"
        [ngTemplateOutletContext]="{
          formGroupControlName: controlName.ShowNorthArrow,
          label: 'GK.MAP.PRINT.ATTRIBUTES_FORM.SHOW_NORTH_ARROW' | translate
        }"
      ></ng-container>

      <ng-container
        [ngTemplateOutlet]="matCheckboxControl"
        [ngTemplateOutletContext]="{
          formGroupControlName: controlName.ShowDocumentWithoutLegalForceLabel,
          label:
            'GK.MAP.PRINT.ATTRIBUTES_FORM.SHOW_DOCUMENT_WITHOUT_LEGAL_FORCE_LABEL'
            | translate
        }"
      ></ng-container>
    </div>

    <ng-template
      #selectControl
      let-formGroupControlName="formGroupControlName"
      let-label="label"
      let-source="source"
      let-sourceValueField="sourceValueField || 'value'"
      let-completeObjectValue="completeObjectValue || false"
      let-sourceLabelField="sourceLabelField || 'label'"
    >
      @if (shouldControlBeVisible(formGroupControlName)) {
        <div class="row mb-2 align-items-center">
          <div
            class="col-xl-5 col-md-12 col-5 text-xl-end text-md-start text-end"
          >
            <label class="col-form-label" [attr.for]="formGroupControlName">
              {{ label }}
            </label>
          </div>

          <div class="col">
            <select
              [id]="formGroupControlName"
              [name]="formGroupControlName"
              [formControlName]="formGroupControlName"
              class="form-control form-select form-select-sm"
            >
              @for (option of source; track $index) {
                <option
                  [ngValue]="
                    completeObjectValue ? option : option[sourceValueField]
                  "
                >
                  {{ option[sourceLabelField] | translate }}
                </option>
              }
            </select>
          </div>
        </div>
      }
    </ng-template>

    <ng-template
      #inputControl
      let-formGroupControlName="formGroupControlName"
      let-label="label"
      let-inputType="inputType || 'text'"
    >
      @if (shouldControlBeVisible(formGroupControlName)) {
        <div class="row mb-2 align-items-center">
          <div
            class="col-xl-5 col-md-12 col-5 text-xl-end text-md-start text-end"
          >
            <label class="col-form-label" [attr.for]="formGroupControlName">
              {{ label }}
            </label>
          </div>

          <div class="col">
            <input
              [id]="formGroupControlName"
              [name]="formGroupControlName"
              [formControlName]="formGroupControlName"
              class="form-control form-control-sm"
              [attr.type]="inputType"
            />
          </div>
        </div>
      }
    </ng-template>

    <ng-template
      #matCheckboxControl
      let-formGroupControlName="formGroupControlName"
      let-label="label"
    >
      @if (shouldControlBeVisible(formGroupControlName)) {
        <div class="row">
          <mat-checkbox
            class="p-0"
            [labelPosition]="'before'"
            [name]="formGroupControlName"
            [formControlName]="formGroupControlName"
          >
            {{ label }}
          </mat-checkbox>
        </div>
      }
    </ng-template>
  </form>
}
