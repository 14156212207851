import { Component, OnInit } from '@angular/core';
import { takeWhile } from 'rxjs';
import { AppTitleService } from '../../../services/app-title/app-title.service';
import { ConfigManagerService } from '../../../services/config-manager/config-manager.service';
import { WebPortalService } from '../../../services/web-portal/web-portal.service';
import { BaseRequestsListDirective } from '../../../shared/base-requests-list/base-requests-list.component';

@Component({
  selector: 'app-user-requests-dynamic',
  templateUrl: './user-requests-dynamic.component.html',
  styleUrls: ['./user-requests-dynamic.component.scss'],
})
export class UserRequestsDynamicComponent
  extends BaseRequestsListDirective
  implements OnInit
{
  constructor(
    private configManagerService: ConfigManagerService,
    public override appTitleService: AppTitleService,
    public override webPortalService: WebPortalService
  ) {
    super(appTitleService, webPortalService);
  }

  ngOnInit(): void {
    this.setListConfigs();
  }

  setListConfigs(): void {
    this.getCurrentPortalType()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((webPortal) => {
        this.listConfigs =
          this.configManagerService.getConfigForNetworkServiceOrder(
            webPortal.portalId
          );
      });
  }
}
