import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import {
  CustomFormError,
  NewPasswordControlName
} from '../../services/new-password-form/new-password-form.model';

export function samePasswordsValidator(): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    const pass = group.get(NewPasswordControlName.NewPassword).value;
    const confirmPass = group.get(NewPasswordControlName.PasswordConfirmation).value;

    return pass === confirmPass ? null : { [CustomFormError.NotSamePasswords]: true };
  };
}
