import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiResponse,
  DictionaryField,
  DictionaryFieldFromApi,
} from '@gk/gk-modules';
import { Observable, map, shareReplay } from 'rxjs';

@Injectable()
export class SingleFamilyBuildingConstructionIntentionTypeDictionaryService {
  private constructionIntentionTypeDictionary: Observable<DictionaryField[]>;

  constructor(private httpClient: HttpClient) {}

  get(): Observable<DictionaryField[]> {
    if (!this.constructionIntentionTypeDictionary) {
      this.constructionIntentionTypeDictionary = this.httpClient
        .get<ApiResponse<DictionaryFieldFromApi[]>>(
          '/api/interesant/slo/rodzajZamierzeniaBudowlanego/budJednorodzinny'
        )
        .pipe(
          map((data) => data.Response),
          map((constructionIntentionTypes) =>
            constructionIntentionTypes.map((constructionIntentionType) =>
              DictionaryField.fromApiToApp(constructionIntentionType)
            )
          ),
          shareReplay(1)
        );
    }

    return this.constructionIntentionTypeDictionary;
  }
}
