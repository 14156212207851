<div class="dynamic-field form-datepicker" [formGroup]="group">
  <label>{{config.label}}</label>
  <ngb-datepicker [formControlName]="config.name" [(ngModel)]="model" (ngModelChange)="checkModelAndChangeIfNecessary()"></ngb-datepicker>
</div>

<gk-form-control-validation [formGroup]="group" [fieldConfig]="config" [submitted]="submitted"></gk-form-control-validation>
@if (showAlert) {
  <div class="alert alert-danger">
    {{'GK.DYNAMIC_FORM.VALIDATION.START_DATE_SHOULD_BE_BEFORE_END_DATE' | translate}}
  </div>
}
