<div class="dynamic-field" [formGroup]="group">
  <label
    (click)="toggleDistrictView()"
    for="districtSelect"
    class="highlighted-label"
    placement="top"
    [ngbTooltip]="districtSelectTipContent"
    >{{ config.label | translate }}</label
  >
  <select
    id="districtSelect"
    class="form-select"
    [formControlName]="config.name"
    [ngClass]="{ error: isErrorDetected() }"
  >
    @if (config.allValuesEnabled) {
      <option value="">
        {{ 'GK.DYNAMIC_FORM.ALL' | translate }}
      </option>
    }
    @for (district of districtsForCommunity; track district) {
      @if (districtGusFirst) {
        <option [ngValue]="config.fullObjectFormValue ? district : district.id">
          {{ district.gus }} {{ district.name }}
        </option>
      }
      @if (!districtGusFirst) {
        <option [ngValue]="config.fullObjectFormValue ? district : district.id">
          {{ district.name }} [{{ district.gus }}]
        </option>
      }
    }
  </select>
</div>

<ng-template #districtSelectTipContent
  ><p [innerHTML]="'GK.MAP.SELECT_DISTRICT_TIP_CONTENT' | translate"></p
></ng-template>
