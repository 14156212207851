<div class="container container-e-service-request">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <h2 class="mt-5 mb-3">
      {{ 'CONSTRUCTION_PORTAL.CONSTRUCTION_LOG.TITLE' | translate }}
    </h2>

    <br />

    <section
      class="card mb-4"
      [ngClass]="
        checkInvestorDetailsFormGroupValidAndFormValidated()
          ? 'border-danger border-2'
          : 'border-dark'
      "
    >
      <div class="card-header text-white bg-dark">
        {{
          'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.INVESTOR_DETAILS.TITLE'
            | translate
        }}
      </div>

      <div class="card-body">
        <app-investor-details
          [investorDetailsFromGroup]="getInvestorDetailsFormGroup()"
        ></app-investor-details>
      </div>
    </section>

    <section
      class="card mb-4"
      [ngClass]="
        checkProxyDetailsFormGroupValidAndFormValidated()
          ? 'border-danger border-2'
          : 'border-dark'
      "
    >
      <div class="card-header text-white bg-dark">
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            [id]="controlName.ProxyDetailsCheckbox"
            [formControlName]="controlName.ProxyDetailsCheckbox"
          />

          <label
            class="form-check-label checkbox"
            [attr.for]="controlName.ProxyDetailsCheckbox"
          >
            {{
              'CONSTRUCTION_PORTAL.CONSTRUCTION_LOG.FIELDS.PROXY_DETAILS'
                | translate
            }}
          </label>
        </div>
      </div>

      @if (proxyDetailsVisible) {
        <div class="card-body">
          <app-investor-details
            [legalPersonEnabled]="false"
            [investorDetailsFromGroup]="getProxyDetailsFormGroup()"
            [automaticCopyingDataFromPz]="false"
          ></app-investor-details>
          <div class="alert alert-info mt-2" role="alert">
            {{
              'CONSTRUCTION_PORTAL.CONSTRUCTION_LOG.FIELDS.PROXY_DETAILS_INFO'
                | translate
            }}
          </div>
        </div>
      }
    </section>

    <section
      class="card mb-4"
      [ngClass]="
        checkDecisionFormControlsValidAndFormValidated()
          ? 'border-danger border-2'
          : 'border-dark'
      "
    >
      <div class="card-header text-white bg-dark">
        {{
          'CONSTRUCTION_PORTAL.CONSTRUCTION_LOG.FIELDS.CONSTRUCTION_LOG_REQUEST'
            | translate
        }}
      </div>

      <div class="card-body">
        <div class="row">
          <div class="form-group col-md-4">
            <label [attr.for]="controlName.DecisionNumber">
              {{
                'CONSTRUCTION_PORTAL.CONSTRUCTION_LOG.FIELDS.DECISION_NUMBER'
                  | translate
              }}
            </label>

            <input
              type="text"
              [name]="controlName.DecisionNumber"
              [id]="controlName.DecisionNumber"
              class="form-control form-control-sm required"
              aria-required="true"
              [formControlName]="controlName.DecisionNumber"
            />

            @if (shouldShowRequiredAlert(this.getDecisionNumberFormControl())) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>

          <div class="form-group col-md-4">
            <label [attr.for]="controlName.DecisionNumber">
              {{
                'CONSTRUCTION_PORTAL.CONSTRUCTION_LOG.FIELDS.DECISION_DATE'
                  | translate
              }}
            </label>

            <div class="input-group">
              <input
                class="form-control form-control-sm required"
                aria-required="true"
                [attr.placeholder]="
                  'GK.DYNAMIC_LIST.DATE_PLACEHOLDER' | translate
                "
                [id]="controlName.DecisionDate"
                [name]="controlName.DecisionDate"
                [formControlName]="controlName.DecisionDate"
                ngbDatepicker
                #d="ngbDatepicker"
              />

              <button
                class="btn btn-outline-secondary fa fa-calendar"
                (click)="d.toggle()"
                type="button"
              ></button>
            </div>

            @if (shouldShowRequiredAlert(this.getDecisionDateFormControl())) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>

          <div class="form-group col-md-4">
            <label [attr.for]="controlName.DecisionPublishedBy">
              {{
                'CONSTRUCTION_PORTAL.CONSTRUCTION_LOG.FIELDS.DECISION_PUBLISHED_BY'
                  | translate
              }}
            </label>

            <input
              type="text"
              [name]="controlName.DecisionPublishedBy"
              [id]="controlName.DecisionPublishedBy"
              class="form-control form-control-sm required"
              aria-required="true"
              [formControlName]="controlName.DecisionPublishedBy"
            />

            @if (
              shouldShowRequiredAlert(this.getDecisionPublishedByFormControl())
            ) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>
        </div>
      </div>
    </section>

    <section
      class="card mb-4"
      [ngClass]="
        checkConstructionLogTypeFormControlValidAndFormValidated()
          ? 'border-danger border-2'
          : 'border-dark'
      "
    >
      <label
        class="card-header text-white bg-dark"
        [attr.for]="controlName.ConstructionLogType"
      >
        {{
          'CONSTRUCTION_PORTAL.CONSTRUCTION_LOG.FIELDS.CONSTRUCTION_LOG_TYPE'
            | translate
        }}
      </label>

      <div class="card-body">
        <select
          [name]="controlName.ConstructionLogType"
          [id]="controlName.ConstructionLogType"
          [formControlName]="controlName.ConstructionLogType"
          class="form-select form-select-sm required"
          aria-required="true"
        >
          <option [ngValue]="null" hidden selected>
            {{
              'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.AGRICULTURAL_LAND_USE_REQUEST.PLACEHOLDERS.SELECT'
                | translate
            }}
          </option>

          <option [ngValue]="constructionLogType.DB1">
            {{ 'CONSTRUCTION_PORTAL.CONSTRUCTION_LOG.FIELDS.DR1' | translate }}
          </option>

          <option [ngValue]="constructionLogType.DB2">
            {{ 'CONSTRUCTION_PORTAL.CONSTRUCTION_LOG.FIELDS.DR2' | translate }}
          </option>

          <option [ngValue]="constructionLogType.DB2_A">
            {{
              'CONSTRUCTION_PORTAL.CONSTRUCTION_LOG.FIELDS.DR2_A' | translate
            }}
          </option>

          <option [ngValue]="constructionLogType.DB3">
            {{ 'CONSTRUCTION_PORTAL.CONSTRUCTION_LOG.FIELDS.DR3' | translate }}
          </option>
        </select>
      </div>
    </section>

    <app-authorized-person
      [submitted]="submitted"
      [parentForm]="getAuthorizedDetailsFormGroup()"
    ></app-authorized-person>

    <section class="px-4 pt-4 border border-dark rounded mb-4">
      <h2 class="mb-4">{{ 'ATTACHMENTS' | translate }}</h2>

      @for (docType of docTypes; track docType.id) {
        <div
          class="card mb-4"
          [ngClass]="
            shouldShowFileInputInvalidMessage(docType)
              ? 'border-danger border-2'
              : 'border-dark'
          "
        >
          <div class="card-header text-white bg-dark">
            {{ docType.labelKey | translate }}
          </div>
          <div class="card-body">
            <gk-file-input
              class="mb-2 bg-color-from-db-for-button-child"
              [ngClass]="{ required: checkDocTypeIsRequired(docType.id) }"
              [attr.aria-required]="checkDocTypeIsRequired(docType.id)"
              [showPreviews]="true"
              (filesChange)="handleFileInputAction(docType.id, $event)"
            >
            </gk-file-input>
          </div>
          @if (shouldShowFileInputInvalidMessage(docType)) {
            <div class="card-footer">
              <div class="alert alert-danger">
                {{
                  'CONSTRUCTION_PORTAL.CONSTRUCTION_LOG.VALIDATION.NO_FILE_CHOSEN'
                    | translate
                }}
              </div>
            </div>
          }
        </div>
      }
    </section>

    <section class="my-2">
      <app-cautions
        [portalId]="portalId"
        [formGroup]="formGroup"
        [submitted]="submitted"
      ></app-cautions>
    </section>

    <button type="submit" class="btn btn-primary">
      {{
        'CONSTRUCTION_PORTAL.CONSTRUCTION_LOG.FIELDS.SUBMIT_REQUEST' | translate
      }}
    </button>

    <div class="d-flex flex-column">
      @if (docSignMsgTxt) {
        <span [ngClass]="'text-' + docSignMsgType">
          {{ docSignMsgTxt }}
        </span>
      }

      @if (docSignUrl) {
        <span class="my-2 text-info">
          {{ docSignTranslations['SIGN_INFO'] }}
        </span>
      }

      @if (docSignUrl) {
        <a class="link" [href]="docSignUrl" target="_blank">
          {{ docSignTranslations['SIGN'] }}
        </a>
      }

      @if (docSignPending) {
        <div class="d-flex justify-content-center my-3">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
          </div>
        </div>
      }
    </div>

    <div class="mt-2"><app-form-legend></app-form-legend></div>
  </form>
</div>

<ng-template #canDeactivateModal let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'MODAL_CAN_DEACTIVATE.ALERT_TITLE' | translate }}
    </h4>
  </div>

  <div class="modal-body">
    {{ 'MODAL_CAN_DEACTIVATE.DESCRIPTION' | translate }}
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="close(false)">
      {{ 'MODAL_CAN_DEACTIVATE.CANCEL' | translate }}
    </button>

    <button type="button" class="btn btn-primary" (click)="close(true)">
      {{ 'MODAL_CAN_DEACTIVATE.CONFIRM' | translate }}
    </button>
  </div>
</ng-template>
