//https://github.com/ngx-formly/ngx-formly/blob/main/src/ui/kendo/select/src/select.type.ts
import { Component } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/kendo/form-field';
import { FormlyFieldSelectProps } from '@ngx-formly/core/select';
import { isObjectOfTypeObservable } from '../../../utils/utils';

export interface FormlyFieldKendoSelectComponentProps
  extends FormlyFieldSelectProps {
  /**
   * Specifies the type of the selected value
   * ([more information and example](https://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdownlist/value-binding/#toc-primitive-values-from-object-fields).
   * If set to `true`, the selected value has to be of a primitive value.
   */
  valuePrimitive?: boolean;
  labelProp: string;
  valueProp: string;
}

@Component({
  selector: 'gk-formly-field-kendo-select',
  template: `
    <kendo-dropdownlist
      [size]="'small'"
      [ngClass]="{ required: props.required }"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [defaultItem]="field.defaultValue ?? undefined"
      [data]="
        isObjectOfTypeObservable(field.props.options)
          ? (field.props.options | async)
          : field.props.options
      "
      [textField]="props.labelProp"
      [valueField]="props.valueProp"
      [valuePrimitive]="props.valuePrimitive ?? true"
      (valueChange)="props.change && props.change(field, $event)"
    >
      <ng-template kendoDropDownListValueTemplate let-dataItem>
        <span>{{
          (!dataItem
            ? ''
            : props.valueProp
              ? dataItem[props.labelProp]
              : dataItem
          ) | translate
        }}</span>
      </ng-template>
      <ng-template kendoDropDownListItemTemplate let-dataItem>
        <span>{{
          (!dataItem
            ? ''
            : props.labelProp
              ? dataItem[props.labelProp]
              : dataItem
          ) | translate
        }}</span>
      </ng-template>
    </kendo-dropdownlist>
  `,
})
export class FormlyFieldKendoSelectComponent extends FieldType<
  FieldTypeConfig<FormlyFieldKendoSelectComponentProps>
> {
  protected readonly isObjectOfTypeObservable = isObjectOfTypeObservable;
}

//      [data]="props.options | formlySelectOptions : field | async"
