export enum ConstructionLogDocTypeId {
  Other = 8,
  StampDuty = 10040,
  PowerOfAttorney = 10035,
  AdministrativeDecision = 112,
  PaymentConfirmation = 10023,
}

export enum ConstructionLogDocTypeLabelKey {
  Other = 'CONSTRUCTION_PORTAL.CONSTRUCTION_LOG.DOC_TYPE_DESCRIPTION.OTHER',
  StampDuty = 'CONSTRUCTION_PORTAL.CONSTRUCTION_LOG.DOC_TYPE_DESCRIPTION.STAMP_DUTY',
  PowerOfAttorney = 'CONSTRUCTION_PORTAL.CONSTRUCTION_LOG.DOC_TYPE_DESCRIPTION.POWER_OF_ATTORNEY',
  AdministrativeDecision = 'CONSTRUCTION_PORTAL.CONSTRUCTION_LOG.DOC_TYPE_DESCRIPTION.ADMINISTRATIVE_DECISION',
  PaymentConfirmation = 'CONSTRUCTION_PORTAL.CONSTRUCTION_LOG.DOC_TYPE_DESCRIPTION.PAYMENT_CONFIRMATION',
}

export function getConstructionLogDocTypeLabelKeyById(id: number): string {
  switch (id) {
    case ConstructionLogDocTypeId.Other: {
      return ConstructionLogDocTypeLabelKey.Other;
    }
    case ConstructionLogDocTypeId.StampDuty: {
      return ConstructionLogDocTypeLabelKey.StampDuty;
    }
    case ConstructionLogDocTypeId.PowerOfAttorney: {
      return ConstructionLogDocTypeLabelKey.PowerOfAttorney;
    }
    case ConstructionLogDocTypeId.AdministrativeDecision: {
      return ConstructionLogDocTypeLabelKey.AdministrativeDecision;
    }
    case ConstructionLogDocTypeId.PaymentConfirmation: {
      return ConstructionLogDocTypeLabelKey.PaymentConfirmation;
    }
    default: {
      return '';
    }
  }
}
