import { FilterOperatorType } from '../gk-dynamic-list.model';

export interface ApiResponseList<T> {
  Count: number;
  TotalCount: number;
  IsThatAll: boolean;
  MaxCountLimit: number;
  Response: T[];
}

export type ApiSortDir = 'asc' | 'desc';

export class ApiFilter {
  constructor(
    public field: string,
    public value: any,
    public operator: FilterOperatorType = FilterOperatorType.Equals,
    public ignoreCase = true
  ) {}
}

export class SortObject {
  constructor(public dir: string, public field: string) {}
}

export class ApiFullFilter {
  constructor(public filters: ApiFilter[], public logic = 'AND') {}
}

export class ApiListBody {
  constructor(
    public skip: number,
    public take: number,
    public filter: ApiFullFilter,
    public sort: SortObject[],
    public portalId?: number
  ) {}
}
