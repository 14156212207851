<div class="modal-header">
  <h4 class="modal-title">{{ 'CLOSED_AREAS.MODAL_INFO_HEADER' | translate }}</h4>
</div>

<div class="modal-body">
  <app-closed-areas-table [closedAreas]="closedAreas | async"></app-closed-areas-table>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="close()">
    {{ getUiBoolean(true) | async }}
  </button>
  <button type="button" class="btn btn-light" (click)="dismiss()">
    {{ getUiBoolean(false) | async }}
  </button>
</div>
