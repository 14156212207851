import { Component } from '@angular/core';
import { FormBaseComponent } from '../../form-base/form-base.component';
import { RadioOptionFieldConfig } from '../../gk-dynamic-form.model';

@Component({
  selector: 'gk-form-radio-option',
  templateUrl: './form-radio-option.component.html',
  styleUrls: ['./form-radio-option.component.scss'],
})
export class FormRadioOptionComponent extends FormBaseComponent {
  override config: RadioOptionFieldConfig;
}
