@if (loading) {
  <div class="d-flex justify-content-center my-3">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
    </div>
  </div>
}
@if (!loading) {
  @for (controlConfig of listDataConfig.controlConfigs; track controlConfig) {
    <gk-control-renderer
      [data]="getData()"
      [controlConfig]="controlConfig"
      (dispatchMapAction)="dispatchMapAction.emit($event)"
    ></gk-control-renderer>
  }
}
