<label for="communitySelect"
  >{{ 'NEW_REQUEST.CHOOSE_COMMUNITY' | translate }}:</label
>
<select
  class="form-select"
  id="communitySelect"
  required
  [(ngModel)]="chosenCommunity"
  name="communitySelect"
  #communitySelect="ngModel"
  aria-required="true"
  (ngModelChange)="initDistricts()"
>
  @for (community of allCommunities; track community.id) {
    <option [ngValue]="community">
      {{ community.name }}
    </option>
  }
</select>
<div class="row my-3">
  <div class="col-5">
    <label for="availableDistrictsMultiSelect"
      >{{ 'NEW_REQUEST.AVAILABLE_DISTRICTS' | translate }}:</label
    >
    <select
      multiple
      [size]="10"
      class="form-select"
      id="availableDistrictsMultiSelect"
      [(ngModel)]="selectedAvailableDistricts"
      name="availableDistrictsMultiSelect"
      #availableDistrictsMultiSelect="ngModel"
    >
      @for (district of availableDistricts; track district.id) {
        <option
          (dblclick)="moveAvailableSelectedToChosen($event)"
          [ngValue]="district"
        >
          {{ district.name }}
        </option>
      }
    </select>
  </div>
  <div class="col-2">
    <div class="d-flex justify-content-end flex-column h-100">
      <button
        type="button"
        class="my-2 btn btn-secondary-outline fa fa-angle-right"
        (click)="moveAvailableSelectedToChosen($event)"
        title="{{ 'NEW_REQUEST.MOVE_SELECTED_TO_CHOSEN' | translate }}"
        attr.aria-label="{{
          'NEW_REQUEST.MOVE_SELECTED_TO_CHOSEN' | translate
        }}"
      ></button>
      <button
        type="button"
        class="my-2 btn btn-secondary-outline fa fa-angle-double-right"
        (click)="moveAllToChosen($event)"
        title="{{ 'NEW_REQUEST.MOVE_ALL_TO_CHOSEN' | translate }}"
        attr.aria-label="{{ 'NEW_REQUEST.MOVE_ALL_TO_CHOSEN' | translate }}"
      ></button>
      <button
        type="button"
        class="my-2 btn btn-secondary-outline fa fa-angle-left"
        (click)="moveChosenSelectedToAvailable($event)"
        title="{{ 'NEW_REQUEST.MOVE_SELECTED_TO_AVAILABLE' | translate }}"
        attr.aria-label="{{
          'NEW_REQUEST.MOVE_SELECTED_TO_AVAILABLE' | translate
        }}"
      ></button>
      <button
        type="button"
        class="my-2 btn btn-secondary-outline fa fa-angle-double-left"
        (click)="moveAllToAvailable($event)"
        title="{{ 'NEW_REQUEST.MOVE_ALL_TO_AVAILABLE' | translate }}"
        attr.aria-label="{{ 'NEW_REQUEST.MOVE_ALL_TO_AVAILABLE' | translate }}"
      ></button>
    </div>
  </div>
  <div class="col-5">
    <label for="chosenDistrictsMultiSelect"
      >{{ 'NEW_REQUEST.CHOSEN_DISTRICTS' | translate }}:</label
    >
    <select
      multiple
      [size]="10"
      class="form-select"
      id="chosenDistrictsMultiSelect"
      [(ngModel)]="selectedChosenDistricts"
      name="chosenDistrictsMultiSelect"
      #chosenDistrictsMultiSelect="ngModel"
    >
      @for (district of chosenDistricts; track district.id) {
        <option
          (dblclick)="moveChosenSelectedToAvailable($event)"
          [ngValue]="district"
        >
          {{ district.name }}
        </option>
      }
    </select>
  </div>
</div>
