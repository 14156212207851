import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GkComponentsModule } from './gk-components/gk-components.module';
import { GkDynamicListModule } from './gk-dynamic-list/gk-dynamic-list.module';
import { GkDynamicNavbarModule } from './gk-dynamic-navbar/gk-dynamic-navbar.module';
import { GkMapPrintModule } from './gk-map-print/gk-map-print.module';
import { GkMapModule } from './gk-map/gk-map.module';
import { GkPaymentsModule } from './gk-payments/gk-payments.module';
import {
  PlannedApplicationMessageService,
  SystemConfigBlobGuestService,
} from './services';
import { ColorsService } from './services/colors/colors.service';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'pl',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    GkDynamicNavbarModule,
    GkMapModule,
    GkMapPrintModule,
    GkPaymentsModule,
    GkDynamicListModule,
  ],
  providers: [
    ColorsService,
    SystemConfigBlobGuestService,
    PlannedApplicationMessageService,
  ],
  exports: [
    GkComponentsModule,
    GkDynamicNavbarModule,
    GkDynamicListModule,
    GkMapModule,
    GkMapPrintModule,
  ],
})
export class GkModulesModule {}
