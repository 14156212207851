import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import * as _ from 'lodash';
import { AuthorizedPersonToRegistryView } from '../../../services/authorized-person-to-registry-view/authorized-person-to-registry-view.model';

@Component({
  selector: 'app-authorized-person-to-registry-view-list',
  templateUrl: './authorized-person-to-registry-view-list.component.html',
  styleUrls: ['./authorized-person-to-registry-view-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorizedPersonToRegistryViewListComponent {
  @Input() showInitialEmptyRequiredRow: boolean;
  @Input() authorizedPersonsToRegistryView: AuthorizedPersonToRegistryView[];
  @Output() authorizedPersonsToRegistryViewChange = new EventEmitter<
    AuthorizedPersonToRegistryView[]
  >();

  shouldShowEmptyRequiredRow(): boolean {
    return (
      _.isEmpty(this.authorizedPersonsToRegistryView) &&
      this.showInitialEmptyRequiredRow
    );
  }

  deletePerson(
    personToDelete: AuthorizedPersonToRegistryView,
    event: MouseEvent
  ): void {
    event.preventDefault();
    event.stopPropagation();
    this.authorizedPersonsToRegistryViewChange.emit(
      this.getArrayWithoutElementToDelete(personToDelete)
    );
  }

  getArrayWithoutElementToDelete(
    personToDelete: AuthorizedPersonToRegistryView
  ): AuthorizedPersonToRegistryView[] {
    return this.authorizedPersonsToRegistryView.filter(
      (person) => person.pesel !== personToDelete.pesel
    );
  }

  isSomeVisiblePerson(): boolean {
    return this.authorizedPersonsToRegistryView?.some(
      (person) => person.visible
    );
  }

  identify(_index: number, item: any): string {
    return item.pesel;
  }
}
