import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  GkComponentsModule,
  GkDynamicListModule,
  GkMapModule,
} from '@gk/gk-modules';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from '../../shared/shared.module';
import { AddressNumberRequestPortalRoutingModule } from './address-number-request-portal-routing.module';
import { AddressNumberRequestPortalComponent } from './address-number-request-portal.component';
import { NewRequestComponent } from './new-request/new-request.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    GkDynamicListModule,
    GkMapModule,
    GkComponentsModule,
    AddressNumberRequestPortalRoutingModule,
  ],
  declarations: [AddressNumberRequestPortalComponent, NewRequestComponent],
})
export class AddressNumberRequestPortalModule {}
