import { Component, Input } from '@angular/core';
import { AbortRequestConfig } from '../gk-kendo-grid/gk-kendo-grid.model';

@Component({
  selector: 'gk-loading-panel',
  templateUrl: './loading-panel.component.html',
  styleUrls: ['./loading-panel.component.scss'],
})
export class LoadingPanelComponent {
  public hostClass = true;
  @Input() isLoading = false;
  @Input() abortConfig: AbortRequestConfig;
  @Input() abortRequest: () => void;

  public handleAbortRequest(): void {
    if (typeof this.abortConfig?.callback === 'function') {
      this.abortConfig.callback();

      return;
    }
    this.abortRequest();
  }
}
