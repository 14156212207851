import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import html2canvas from 'html2canvas';
import * as jspdf from 'jspdf';
import * as _ from 'lodash';
import { Observable, combineLatest, finalize, of, takeWhile } from 'rxjs';
import { getScaleByResolution } from '../gk-map/configs';
import { MapControl } from '../gk-map/controls';
import {
  MapAction,
  MapState,
  PrintAttributesFormState,
  SourceType,
} from '../gk-map/models';
import { MapStateService } from '../gk-map/services';
import { GMPPrintAttributesFormComponent } from './components/print-attributes-form/print-attributes-form.component';
import { GMPPrintPreviewComponent } from './components/print-preview/print-preview.component';
import {
  GMPPrintAttributesControlName,
  GMPPrintAttributesFormGroup,
} from './services/print-attributes-form/print-attributes-form.model';
import { GMPPrintAttributesFormService } from './services/print-attributes-form/print-attributes-form.service';
import { GMPPrintService } from './services/print/print.service';

@Component({
  selector: 'gk-map-print',
  templateUrl: './gk-map-print.component.html',
  styleUrl: './gk-map-print.component.scss',
})
export class GkMapPrintComponent
  extends MapControl
  implements OnInit, OnDestroy
{
  private isAlive = true;
  printAttributesFormGroup: FormGroup<GMPPrintAttributesFormGroup>;
  @Input() fileName?: string;
  @ViewChild(GMPPrintPreviewComponent)
  gmpPrintPreviewComponent: GMPPrintPreviewComponent;
  @ViewChild(GMPPrintAttributesFormComponent)
  gmpPrintAttributesFormComponent: GMPPrintAttributesFormComponent;
  override sourceType = SourceType.PrintAttributesForm;

  constructor(
    public modal: NgbActiveModal,
    public gmpPrintService: GMPPrintService,
    private translateService: TranslateService,
    private mapStateService: MapStateService,
    private gmpPrintAttributesFormService: GMPPrintAttributesFormService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(): void {
    this.gmpPrintAttributesFormService
      .getFormGroup(
        getScaleByResolution(this.mapState.viewState.resolution).zoom,
        this.getPrintAttributesFormState().config,
      )
      .subscribe((formGroup) => {
        this.printAttributesFormGroup = formGroup;
      });
  }

  getPrintAttributesFormState(): PrintAttributesFormState {
    return this.mapState.toolsState[this.toolType][
      this.sourceType
    ] as PrintAttributesFormState;
  }

  handleMapAction(mapAction: MapAction): void {
    this.mapState = this.mapStateService.getUpdatedMapState(
      mapAction,
      this.mapState,
    );
  }

  setDeepCopyOfMapState(mapState: MapState): void {
    const mapStateCopy = _.cloneDeep(mapState);
    const newMapState = {
      ...mapStateCopy,
      viewState: {
        ...mapStateCopy.viewState,
        isSidebarExpanded: false,
      },
    };
    this.mapState = newMapState;
  }

  generatePdf(): void {
    this.gmpPrintService.printoutGenerationInProgress.next(true);
    combineLatest([this.renderCanvasWithDelayToShowLoader(), this.geFileName()])
      .pipe(
        takeWhile(() => this.isAlive),
        finalize(() => {
          this.gmpPrintService.printoutGenerationInProgress.next(false);
        }),
      )
      .subscribe(([canvas, fileName]) => {
        const contentDataURL = canvas.toDataURL('image/png');
        const pdf = new jspdf.jsPDF('p', 'cm', 'a4');
        pdf.addImage(contentDataURL, 'PNG', 0, 0, 21.0, 29.7);
        pdf.save(fileName);
      });
  }

  renderCanvasWithDelayToShowLoader(): Observable<HTMLCanvasElement> {
    const printContainerHTML =
      this.gmpPrintPreviewComponent.printContainer.nativeElement;
    const attributesFormValue =
      this.gmpPrintAttributesFormComponent.formGroup.value;

    return new Observable<HTMLCanvasElement>((observer) => {
      setTimeout(() => {
        html2canvas(printContainerHTML, {
          scale: attributesFormValue[GMPPrintAttributesControlName.Quality],
          useCORS: true,
          allowTaint: true,
        })
          .then((canvas) => {
            observer.next(canvas);
            observer.complete();
          })
          .catch((error) => {
            observer.error(error);
          });
      }, 100);
    });
  }

  geFileName(): Observable<string> {
    return this.fileName
      ? of(this.fileName)
      : this.translateService.get('GK.MAP.PRINT.DEFAULT_FILE_NAME');
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
