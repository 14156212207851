import { Component, Input } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { DictionaryControlConfig } from '../../../gk-dynamic-list.model';
import { DataService } from '../../../services/data/data.service';
import { DictionaryService } from '../../../services/dictionary/dictionary.service';
import { Control } from '../control';

@Component({
  selector: 'gk-control-dict',
  templateUrl: './control-dict.component.html',
})
export class ControlDictComponent extends Control<string | number> {
  @Input() override controlConfig: DictionaryControlConfig;

  constructor(
    private dictionaryService: DictionaryService,
    protected override translateService: TranslateService,
    protected override dataService: DataService
  ) {
    super(translateService, dataService);
  }

  getFieldName(): string {
    return this.dictionaryService.getFieldNameById(
      this.controlConfig.dictionaryType,
      this.getValue()
    );
  }
}
