import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BasicResponse } from '../../../utils/basic-response/basic-response.model';
import {
  PolygonsUnion,
  PolygonsUnionFromApi,
  PolygonTopologyValidation,
  PolygonValidationDto,
  Wkt,
} from '../../models';

@Injectable()
export class PolygonTopologyService {
  constructor(private http: HttpClient) {}

  getPolygonTopologyValidation(
    polygon: Wkt,
  ): Observable<PolygonTopologyValidation> {
    const params = {
      Wkt: polygon,
    };

    return this.http
      .post<PolygonValidationDto>('/api/mapa/geom/polygon/check', params)
      .pipe(
        map((data: PolygonValidationDto) =>
          PolygonTopologyValidation.fromApiToApp(data),
        ),
      );
  }

  convertRangeFileToWkt(fileString: string): Observable<Wkt> {
    let body;

    try {
      body = {
        File: btoa(fileString),
      };
    } catch (err) {
      return of('');
    }

    return this.http
      .post<BasicResponse<Wkt>>('/api/system/geometry/convert/file/wkt', body)
      .pipe(map((response) => response.Result));
  }

  convertRangeToTxt(geom: string): Observable<Wkt> {
    const body = {
      Wkt: geom,
    };

    return this.http.post('/api/system/geometry/convert/wkt/file', body, {
      responseType: 'text',
    });
  }

  getPolygonsUnion(
    polygons: Wkt[],
    buffer?: number,
  ): Observable<PolygonsUnion> {
    const body = {
      Geom: polygons,
      Bufor: buffer,
    };

    return this.http
      .post<PolygonsUnionFromApi>('/api/mapa/geom/polygons/union', body)
      .pipe(map((data) => PolygonsUnion.fromApiToApp(data)));
  }
}
