export enum BuildingPermitTransferControlName {
  BuildingNameAndType = 'buildingNameAndType',
  ProxyDetailsCheckbox = 'proxyDetailsCheckbox',
  ProxyDetails = 'proxyDetails',
  InvestorDetails = 'investorDetails',
  CurrentInvestorDetails = 'currentInvestorDetails',
  DecisionForm = 'decisionForm',
  ShippingForm = 'shippingForm',
  Place = 'place',
  Street = 'street',
  StreetAbsence = 'streetAbsence',
  BuildingNumber = 'buildingNumber',
  LocalNumber = 'localNumber',
  PostalCode = 'postalCode',
  PostOffice = 'postOffice',
  PermitTransferDate = 'permitTransferDate',
  PermitTransferNumber = 'permitTransferNumber'
}
