import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { GkComponentsModule, GkDynamicListModule, GkMapModule, } from '@gk/gk-modules';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PaymentSuccessComponent } from '../../components/payment-success/payment-success.component';
import { SharedModule } from '../../shared/shared.module';
import { DesignerMapComponent } from './designer-map/designer-map.component';
import { DesignerPortalRoutingModule } from './designer-portal-routing.module';
import { DesignerPortalComponent } from './designer-portal.component';
import {
  InfoCrossingClosedAreasModalComponent
} from './info-crossing-closed-areas-modal/info-crossing-closed-areas-modal.component';
import { DocsTableEditableComponent } from './new-designer-request/docs-table-editable/docs-table-editable.component';
import { NewDesignerRequestComponent } from './new-designer-request/new-designer-request.component';
import { RequestsListComponent } from './requests-list/requests-list.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    GkDynamicListModule,
    GkMapModule,
    GkComponentsModule,
    DesignerPortalRoutingModule,
  ],
  declarations: [
    DesignerPortalComponent,
    RequestsListComponent,
    PaymentSuccessComponent,
    NewDesignerRequestComponent,
    DesignerMapComponent,
    DocsTableEditableComponent,
    InfoCrossingClosedAreasModalComponent,
  ],
  exports: [DocsTableEditableComponent],
})
export class DesignerPortalModule {}
