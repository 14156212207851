import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiResponse,
  DictionaryField,
  DictionaryFieldFromApi,
} from '@gk/gk-modules';
import { Observable, map, shareReplay } from 'rxjs';

@Injectable()
export class WorkPurposeDictionaryService {
  private workPurposeDictionary: Observable<DictionaryField[]>;

  constructor(private httpClient: HttpClient) {}

  get(): Observable<DictionaryField[]> {
    if (!this.workPurposeDictionary) {
      this.workPurposeDictionary = this.httpClient
        .get<
          ApiResponse<DictionaryFieldFromApi[]>
        >('/api/interesant/slo/celpracy')
        .pipe(
          map((data) => data.Response),
          map((workPurposes) =>
            workPurposes.map((workPurpose) =>
              DictionaryField.fromApiToApp(workPurpose),
            ),
          ),
          shareReplay(1),
        );
    }

    return this.workPurposeDictionary;
  }
}
