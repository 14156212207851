import { Params } from '@angular/router';

export const CollapsibleTargetPrefix = 'collapsible';

export enum BsColor {
  Primary = 'primary',
  Secondary = 'secondary',
  Light = 'light',
  Dark = 'dark',
  Success = 'success',
  ColorFromDb = 'color-from-db',
}

export enum NavbarColor {
  Dark = 'navbar-dark',
  Light = 'navbar-light',
  ColorFromDb = '',
}

export enum TextColor {
  Dark = 'text-dark',
  Light = 'text-light',
  White = 'text-white',
  ColorFromDb = 'text-color-from-db',
}

export enum NavbarExpandBreakpoint {
  Sm = 'sm',
  Md = 'md',
  Lg = 'lg',
  Xl = 'xl',
}

export type NavItemId = string;

export class GeneralNavConfig {
  constructor(
    public id = 'default_nav_id',
    public color: BsColor = BsColor.Light,
    public expandBreakPoint = NavbarExpandBreakpoint.Xl,
    public additionalCssClasses = '',
  ) {}
}

export enum NavSectionType {
  NavItems = 'nav-items',
}

export abstract class NavSectionConfig {
  constructor(public type: NavSectionType) {}
}

export class NavItemsSectionConfig extends NavSectionConfig {
  constructor(
    public navItemConfigs: NavItemConfig[],
    public isCollapsible = true,
  ) {
    super(NavSectionType.NavItems);
  }
}

export class DynamicNavbarConfig {
  constructor(
    public generalNavConfig: GeneralNavConfig,
    public navSections: NavItemsSectionConfig[],
  ) {}
}

export enum NavItemType {
  Link = 'link',
  Dropdown = 'dropdown',
  ActionButton = 'action-button',
  LogoImageText = 'logo-image-text',
  LogoImage = 'logo-image',
  SimpleText = 'simple-text',
  AudioDescriptionToggleButton = 'audio-description-toggle-button',
  InfoServiceButton = 'info-service-button',
}

export abstract class NavItemConfig {
  constructor(
    public navItemId: NavItemId,
    public type: NavItemType,
    public show?: boolean,
    public title?: string,
  ) {}
}

export class LinkNavItemConfig extends NavItemConfig {
  constructor(
    public override navItemId: NavItemId,
    public override show: boolean,
    public linkLabel: string,
    public navigationRoute: string,
    public faIconName = '',
    public showLabel = true,
    public showIcon = false,
    public navigationQueryParams?: Params,
    public target = '_self',
    public customBtnClass = '',
  ) {
    super(navItemId, NavItemType.Link, show);
  }
}

export class ActionButtonNavItemConfig extends NavItemConfig {
  constructor(
    public override navItemId: NavItemId,
    public override show: boolean,
    public buttonLabel: string,
    public override title = '',
    public isDropdownItem = false,
    public faIconName = '',
    public showIcon = false,
    public showLabel = true,
    public showBadge = false,
    public badgeValue = '',
    public customBtnClass = '',
    public customIconSrc = '',
    public hideLabelOnDesktop = false,
  ) {
    super(navItemId, NavItemType.ActionButton, show);
  }
}

export class DropdownNavItemConfig extends NavItemConfig {
  constructor(
    public override navItemId: NavItemId,
    public override show: boolean,
    public dropdownLabel: string,
    public dropdownTitle: string,
    public dropdownItemConfigs: Array<
      ActionButtonNavItemConfig | LinkNavItemConfig
    >,
    public itemsHorizontal = false,
    public itemsLeft = false,
    public faIconName = '',
    public showSelectedImage = false,
    public customDropdownClass = '',
  ) {
    super(navItemId, NavItemType.Dropdown, show);
  }
}

export class LogoImageTextNavItemConfig extends NavItemConfig {
  constructor(
    public override navItemId: NavItemId,
    public override show: boolean,
    public textPart1: string,
    public textPart2 = '',
    public imgSrc = '',
    public imgAlt = 'portal-logo',
  ) {
    super(navItemId, NavItemType.LogoImageText, show);
  }
}

export class LogoImageNavItemConfig extends NavItemConfig {
  constructor(
    public override navItemId: NavItemId,
    public override show: boolean,
    public imgSrc = '',
    public imgAlt = 'portal-logo',
  ) {
    super(navItemId, NavItemType.LogoImage, show);
  }
}

export class SimpleTextNavItemConfig extends NavItemConfig {
  constructor(
    public override navItemId: NavItemId,
    public override show: boolean,
    public text: string,
    public override title = '',
    public text2 = '',
    public hideText2OnDesktop = false,
  ) {
    super(navItemId, NavItemType.SimpleText, show);
  }
}

export class AudioDescriptionToggleButtonNavItemConfig extends NavItemConfig {
  constructor(
    public override navItemId: NavItemId,
    public override show: boolean,
    public buttonLabel: string,
    public override title: string,
    public audioAssetSource: string,
    public autoPlay = true,
  ) {
    super(navItemId, NavItemType.AudioDescriptionToggleButton, show);
  }
}

export class ShowInfoServiceConfig extends NavItemConfig {
  constructor(
    public override navItemId: NavItemId,
    public override show: boolean,
    public buttonLabel: string,
    public override title: string,
    public faIconName = '',
  ) {
    super(navItemId, NavItemType.InfoServiceButton, show);
  }
}

export enum NavbarActionType {
  Click = 'click',
}

export enum DropdownNavItemId {
  TranslationDropdown = 'translation_dropdown',
}

export type NavbarActionPayload = {
  navItemId: string;
  customIconSrc?: string;
  dropdownItemId?: string;
};

export class NavbarAction {
  constructor(
    public type: NavbarActionType,
    public payload: NavbarActionPayload,
  ) {}
}

export function isActionButtonNavItemConfigType(
  item: ActionButtonNavItemConfig | LinkNavItemConfig,
): item is ActionButtonNavItemConfig {
  return 'customIconSrc' in item;
}
