import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class RequestService {
  constructor(private httpClient: HttpClient) {}

  cancelRequest(url: string, uuid: number): Observable<number> {
    return this.httpClient.post<number>(url, {
      Uuid: uuid
    });
  }
}
