import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map, takeWhile } from 'rxjs/operators';
import { PortalsPermissionsService } from '../../services/portals-permissions/portals-permissions.service';
import { FieldConfig, FieldConfigType } from '../gk-dynamic-form.model';
import { PortalsPermissions } from './../../services/portals-permissions/portals-permissions.model';

@Component({
  selector: 'gk-form-renderer',
  templateUrl: './form-renderer.component.html',
})
export class FormRendererComponent implements OnInit, OnDestroy {
  private isAlive = true;
  @Input()
  fullFormConfig: FieldConfig[];
  @Input()
  fieldConfig: FieldConfig;
  @Input()
  formGroup: UntypedFormGroup;
  @Input()
  submitted = false;
  fieldConfigType = FieldConfigType;
  portalsPermissions: PortalsPermissions;

  constructor(private portalsPermissionsService: PortalsPermissionsService) {}

  ngOnInit(): void {
    this.fetchPermissions();
  }

  fetchPermissions(): void {
    this.portalsPermissionsService
      .getPermissions()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe({
        next: (data) => {
          this.portalsPermissions = data;
        },
        error: () => {
          return;
        },
      });
  }

  doesUserHavePermissionToControl(): Observable<boolean> {
    if (!this.fieldConfig.permissionsKeys) {
      return of(true);
    }

    return this.portalsPermissionsService.getPermissions().pipe(
      takeWhile(() => this.isAlive),
      map((portalsPermissions) =>
        this.fieldConfig.permissionsKeys.checkPermissions(portalsPermissions)
      ),
      catchError(() => [])
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
