import { Component, Input, ViewChild } from '@angular/core';
import {
  PopupSettings,
  ToolBarComponent,
} from '@progress/kendo-angular-toolbar';
import {
  ExtendedGkKendoGridComponent,
  GridToolBarItem,
  GridToolBarItemType,
} from '../gk-kendo-grid.model';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { SVGIcon } from '@progress/kendo-svg-icons';
import { iwIconSelectAll } from '../../../assets/css/ewid-svg/svg-icons';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'gk-kendo-grid-toolbar',
  templateUrl: './gk-kendo-grid-toolbar.component.html',
  styleUrl: './gk-kendo-grid-toolbar.component.scss',
})
export class GkKendoGridToolbarComponent {
  protected readonly gridToolBarButtonType = GridToolBarItemType;
  @Input() gridToolbarItems: GridToolBarItem[] = [];
  @Input() selectable: SelectableSettings | boolean = {
    enabled: true,
    mode: 'single',
  };
  @ViewChild('kendoToolbar') private kendoToolbar: ToolBarComponent;
  @Input() parent: ExtendedGkKendoGridComponent<any>;
  @Input() position: 'top' | 'bottom';
  @Input() overflow = false;
  public iwIconSelectAll = iwIconSelectAll;

  constructor(public deviceService: DeviceDetectorService) {}

  gridToolbarPopupSettings: PopupSettings = {
    popupClass: 'vw-100-mobile',
  };

  getButtonClass(gridToolbarItem: GridToolBarItem): string {
    if (typeof gridToolbarItem.className === 'function') {
      return gridToolbarItem.className();
    }

    return gridToolbarItem.className;
  }

  getIconClass(gridToolbarItem: GridToolBarItem): string {
    if (typeof gridToolbarItem.iconClass === 'function') {
      return gridToolbarItem.iconClass();
    }
    return gridToolbarItem.iconClass;
  }

  getSvgIcon(gridToolbarItem: GridToolBarItem): SVGIcon {
    if (typeof gridToolbarItem.svgIcon === 'function') {
      return gridToolbarItem.svgIcon();
    }
    return gridToolbarItem.svgIcon;
  }

  onSelectAllGridItemsClick(): void {
    this.parent.gridComponent.selectionService.updateAll(true);
  }

  getToolbarItemDisabledState(gridToolbarItem: GridToolBarItem): boolean {
    if (typeof gridToolbarItem.disabled === 'function') {
      return gridToolbarItem.disabled();
    }

    return gridToolbarItem.disabled;
  }

  getToolbarItemVisibleState(
    visible: boolean | (() => boolean) | undefined,
    gridToolBarItemType?: GridToolBarItemType,
  ): boolean | GridToolBarItemType {
    if (visible === true || (typeof visible === 'function' && visible())) {
      return gridToolBarItemType || true;
    }
    if (visible === false || (typeof visible === 'function' && !visible())) {
      return false;
    }

    return gridToolBarItemType || true;
  }

  getToolbarItemText(gridToolbarItem: GridToolBarItem): string {
    if (typeof gridToolbarItem.text === 'function') {
      return gridToolbarItem.text();
    }
    return gridToolbarItem.text;
  }

  getToolbarItemTitle(gridToolbarItem: GridToolBarItem): string {
    if (typeof gridToolbarItem.title === 'function') {
      return gridToolbarItem.title();
    }
    return gridToolbarItem.title;
  }

  getToolbarItemSelectedValue(
    gridToolbarItem: GridToolBarItem,
  ): boolean | undefined {
    if (typeof gridToolbarItem.selected === 'function') {
      return gridToolbarItem.selected();
    }

    return gridToolbarItem.selected;
  }

  handleGridToolbarItemEvent(
    gridToolbarItem: GridToolBarItem,
    event?: Event | number,
  ): void {
    if (gridToolbarItem.callback) {
      gridToolbarItem.callback(event);
    }
    this.kendoToolbar.popupRef?.close();
  }

  checkIfMultipleSelectionMode(): boolean {
    if (this.selectable && typeof this.selectable !== 'boolean') {
      return this.selectable.mode === 'multiple';
    } else {
      return false;
    }
  }
}
