<div class="dynamic-field" [formGroup]="group">
  <label for="basicSelect">{{ config.label | translate }}</label>
  <select
    id="basicSelect"
    class="form-select"
    [formControlName]="config.name"
    [compareWith]="compareFormat"
    >
    @for (option of config.options; track option) {
      <option [ngValue]="option">
        {{ option.name }}
      </option>
    }
  </select>
  <gk-form-control-validation
    [formGroup]="group"
    [fieldConfig]="config"
    [submitted]="submitted"
  ></gk-form-control-validation>
</div>
