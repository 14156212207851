export interface ResetPasswordApi {
  username: string;
}

export class ResetPassword {
  constructor(public login: string, public password: string) {}

  static fromAppToApi(data: ResetPassword): ResetPasswordApi {
    return {
      username: data.login
    };
  }
}
