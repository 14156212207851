import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  Optional,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { containsExtent } from 'ol/extent';
import { Geometry } from 'ol/geom';
import { takeWhile } from 'rxjs/operators';
import { DynamicFormComponent } from '../../../../../gk-dynamic-form/gk-dynamic-form.component';
import { FieldConfig } from '../../../../../gk-dynamic-form/gk-dynamic-form.model';
import { RangeTypeValue } from '../../../../configs';
import { MapControl } from '../../../../controls';
import {
  FileLoaderFormResult,
  FileLoaderFormValue,
  FileState,
  GeometryFormat,
  MapObject,
  NamesFileLoadFormControls,
  OpenLayersGeometryType,
  SourceType,
  ToolType,
  Wkt,
} from '../../../../models';
import { MapStateService, PolygonTopologyService } from '../../../../services';
import { ConversionUtils, ShapeUtils } from '../../../../utils';

@Component({
  selector: 'gk-file-loader-form',
  templateUrl: './file-loader-form.component.html',
  providers: [PolygonTopologyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileLoaderFormComponent
  extends MapControl
  implements AfterViewInit, OnDestroy
{
  protected isAlive = true;
  @ViewChild('formContainer')
  formContainer: DynamicFormComponent;
  override toolType: ToolType;
  formValue: FileLoaderFormValue;
  convertedGeometry: Geometry;
  fileList: FileList;
  isValidFormat = false;
  isValidGeometryType = false;
  isValidRange = false;
  isValidTopology = false;
  override sourceType = SourceType.File;
  showFormatTxt: boolean;
  geometryTypesToCheckTopology = [
    OpenLayersGeometryType.Polygon,
    OpenLayersGeometryType.MultiPolygon,
  ];
  convertingGeometryInProgress: boolean;
  wkt: Wkt;
  polygonTopologyService = inject(PolygonTopologyService);
  changeDetectorRef = inject(ChangeDetectorRef);

  constructor(
    @Optional() private activeModal: NgbActiveModal,
    public mapStateService: MapStateService,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.setFormValueAndSubscribeToChanges();
  }

  setFormValueAndSubscribeToChanges(): void {
    const form = this.formContainer.form;
    this.formValue = form.value;

    form.valueChanges
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((formValue) => {
        this.formValue = formValue;
        this.showFormatTxt =
          formValue[NamesFileLoadFormControls.GeometryFormat].name ===
          GeometryFormat.Txt;
        this.resetGeometryValidationStatuses();
        this.handleCheckGeometry();
      });
  }

  handleCheckGeometry(): void {
    if (this.fileList && this.fileList.length) {
      const reader = new FileReader();
      reader.readAsText(this.fileList[0]);
      reader.onload = () => {
        this.handleGeometryValidation(
          reader.result as string,
          this.formContainer.form.getRawValue().geometryFormat.name,
        );
      };
    }
  }

  closeModal(zoomToSelected: boolean): void {
    this.activeModal.close({
      mapObjects: this.isValid() ? [this.getMapObject()] : [],
      zoomToSelected,
      wkt: this.wkt,
    } as FileLoaderFormResult);
  }

  getValidationStatus(validationParam: boolean): string {
    return this.convertingGeometryInProgress
      ? 'fa fa-spinner fa-pulse fa-fw text-primary'
      : validationParam
        ? 'fa fa-check text-success'
        : 'fa fa-times text-danger';
  }

  resetGeometryValidationStatuses(): void {
    this.isValidFormat = false;
    this.isValidGeometryType = false;
    this.isValidRange = false;
    this.isValidTopology = false;
    this.convertedGeometry = undefined;
  }

  handleFileInputChange(event: Event): void {
    this.fileList = (event.target as HTMLInputElement & EventTarget).files;
    if (this.fileList.length) {
      const reader = new FileReader();
      reader.readAsText(this.fileList[0]);
      reader.onload = () => {
        const geomFormat = ConversionUtils.getGeometryFormat(
          reader.result as string,
        );
        this.setValueSelect(geomFormat);
        this.handleGeometryValidation(
          reader.result as string,
          this.formContainer.form.getRawValue().geometryFormat.name,
        );
      };
    }
  }

  setValueSelect(geomFormat: string): void {
    const form = this.formContainer.form;
    switch (geomFormat) {
      case GeometryFormat.Wkt:
        this.showFormatTxt = false;
        form
          .get(NamesFileLoadFormControls.GeometryFormat)
          .setValue({ value: RangeTypeValue.Wkt, name: GeometryFormat.Wkt });

        return;
      case GeometryFormat.Txt:
        this.showFormatTxt = true;
        form
          .get(NamesFileLoadFormControls.GeometryFormat)
          .setValue({ value: RangeTypeValue.Txt, name: GeometryFormat.Txt });

        return;
      default:
        return;
    }
  }

  handleGeometryValidation(
    geometry: string,
    selectedGeomFormat: GeometryFormat,
  ): void {
    this.convertingGeometryInProgress = true;
    this.changeDetectorRef.markForCheck();
    this.resetGeometryValidationStatuses();
    this.handleGeometryFormatValidation(geometry, selectedGeomFormat);
  }

  handleGeometryFormatValidation(
    geometry: string,
    selectedGeomFormat: GeometryFormat,
  ): void {
    switch (selectedGeomFormat) {
      case GeometryFormat.Wkt:
        this.handleWktValidation(geometry);
        return;
      case GeometryFormat.Txt:
        this.handleTxtValidation(geometry);
        return;
      default:
        return;
    }
  }

  handleWktValidation(wkt: string): void {
    try {
      this.convertedGeometry = ConversionUtils.getGeometryFromWkt(
        wkt.replace(/['"]/g, ''),
      );
      this.wkt = wkt;
      this.geometryConvertSuccessCallback();
    } catch (err) {
      this.convertingGeometryInProgress = false;
      this.changeDetectorRef.markForCheck();
    }
  }

  handleTxtValidation(rangeFile: string): void {
    this.polygonTopologyService
      .convertRangeFileToWkt(rangeFile)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe({
        next: (wkt) => {
          try {
            this.convertedGeometry = ConversionUtils.getGeometryFromWkt(
              wkt.replace(/['"]/g, ''),
            );
            this.wkt = wkt;
            this.geometryConvertSuccessCallback();
          } catch (err) {
            this.convertingGeometryInProgress = false;
            this.changeDetectorRef.markForCheck();
          }
        },
        error: () => {
          this.convertingGeometryInProgress = false;
          this.changeDetectorRef.markForCheck();
        },
      });
  }

  geometryConvertSuccessCallback(): void {
    this.convertingGeometryInProgress = false;
    this.isValidFormat = true;
    const geometryType = this.convertedGeometry.getType();
    this.handleGeometryTypeValidation(geometryType);
    this.handleRangeValidation();
    this.handleTopologyValidation(geometryType);
  }

  handleGeometryTypeValidation(geometryType: any): void {
    if (
      _.includes(
        (this.mapState.toolsState[this.toolType][this.sourceType] as FileState)
          .geometryTypes,
        geometryType,
      )
    ) {
      this.isValidGeometryType = true;
    }
  }

  handleRangeValidation(): void {
    if (this.isGeometryExtentContainedInFullExtent()) {
      this.isValidRange = true;
    }
  }

  isGeometryExtentContainedInFullExtent(): boolean {
    return containsExtent(
      this.mapState.viewState.fullNativeExtent,
      this.convertedGeometry.getExtent(),
    );
  }

  handleTopologyValidation(geometryType: any): void {
    _.includes(this.geometryTypesToCheckTopology, geometryType)
      ? this.checkTopology()
      : (this.isValidTopology = true);
  }

  checkTopology(): void {
    this.polygonTopologyService
      .getPolygonTopologyValidation(
        ConversionUtils.getWktFromGeometry(this.convertedGeometry),
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((validationStatus) => {
        this.isValidTopology = validationStatus.isValid;
        this.changeDetectorRef.markForCheck();
      });
  }

  getMapObject(): MapObject {
    return ShapeUtils.getAnyGeometryMapObject(
      ConversionUtils.getWktFromGeometry(
        ConversionUtils.getReducedGeometry(this.convertedGeometry),
      ),
      this.mapState.toolsState[this.toolType].mapObjectApiType,
    );
  }

  getFileState(): FileState {
    return this.mapState.toolsState[this.toolType][
      this.sourceType
    ] as FileState;
  }

  getFieldConfigs(): FieldConfig[] {
    return this.getFileState().fieldConfigs;
  }

  getFormHeader(): string {
    return this.getFileState().formHeader;
  }

  isValid = (): boolean =>
    this.isValidFormat &&
    this.isValidGeometryType &&
    this.isValidRange &&
    this.isValidTopology;

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
