import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ColumnHeader,
  TableSelectionMode,
} from '../gk-components/table/table.model';
import {
  CallbackButtonConfig,
  RequestConfig,
} from '../gk-dynamic-list/gk-dynamic-list.model';
import { DataService } from '../gk-dynamic-list/services/data/data.service';
import { NewMessage } from './new-message.model';
import { map, takeWhile } from 'rxjs/operators';
import { DynamicListService } from '../gk-dynamic-list/services/dynamic-list/dynamic-list.service';

@Component({
  selector: 'gk-modal-new-messages-list',
  templateUrl: './gk-modal-new-messages-list.component.html',
})
export class GkModalNewMessagesListComponent implements OnInit, OnDestroy {
  private isAlive = true;
  newMessages: NewMessage[];
  selectedMessages: NewMessage[] = [];
  tableSelectionModeEnum = TableSelectionMode;
  showMessageNotation: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() title = 'Lista nowych wiadomości dla komunikatora';
  @Input() columns: ColumnHeader[];
  @Input() messagesListRequestConfig: RequestConfig;
  @Input() callbackButtonConfig: CallbackButtonConfig;
  @Input() callbackParseDataToApp: (item: any) => any;

  constructor(
    public activeModal: NgbActiveModal,
    protected dataService: DataService,
    public dynamicListService: DynamicListService
  ) {}

  ngOnInit(): void {
    this.fetchNewMessages();
  }

  onReadMessage(): void {
    this.callbackButtonConfig.callback(this.selectedMessages, undefined, () =>
      this.onRefreshList()
    );
    this.dynamicListService.handlePanelChange(this.selectedMessages[0].id);
  }

  fetchNewMessages(): void {
    this.dataService
      .getData(this.messagesListRequestConfig, [])
      .pipe(
        takeWhile(() => this.isAlive),
        map((data) => data.map((item) => this.callbackParseDataToApp(item)))
      )
      .subscribe((messages) => {
        this.newMessages = messages;
        this.showMessageNotation.emit(true);
      });
  }

  onRefreshList(): void {
    this.newMessages = [];
    this.selectedMessages = [];
    this.fetchNewMessages();
  }

  onMessageSelect(selectedMessages: NewMessage[]): void {
    this.selectedMessages = selectedMessages;
  }

  closeModal(): void {
    this.activeModal.close();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
