export interface SectionFromApi {
  Nazwa: string;
}

export class Section {
  constructor(public name: string) {}

  static fromApiToApp(sectionFromApi: SectionFromApi): Section {
    return new this(sectionFromApi.Nazwa);
  }
}
