import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { PzService } from '@gk/gk-modules';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { takeWhile } from 'rxjs/operators';
import { AuthorizedPersonToRegistryViewControlName } from '../../../services/authorized-person-to-registry-view-form/authorized-person-to-registry-view-form.model';
import { AuthorizedPersonToRegistryViewFormService } from '../../../services/authorized-person-to-registry-view-form/authorized-person-to-registry-view-form.service';
import { AuthorizedPersonToRegistryView } from '../../../services/authorized-person-to-registry-view/authorized-person-to-registry-view.model';

@Component({
  selector: 'app-authorized-person-to-registry-view-form',
  templateUrl: './authorized-person-to-registry-view-form.component.html',
  styleUrls: ['./authorized-person-to-registry-view-form.component.scss'],
})
export class AuthorizedPersonToRegistryViewFormComponent
  implements OnInit, OnDestroy
{
  private isAlive = true;
  initialAuthorizedPersonPesel: string;
  form: UntypedFormGroup;
  authorizedPersonToRegistryViewControlName =
    AuthorizedPersonToRegistryViewControlName;
  submitted: boolean;
  authorizedPersonDuplicateInfoTranslation = '';
  currentUserPeselValue: string;
  @Input() isAuthorizedPersonWithLoggedUserDuplicatePrevent = true;
  @Input() shouldBeMarkedAsRequired: boolean;
  @Output() newAuthorizedPersonToRegistryView =
    new EventEmitter<AuthorizedPersonToRegistryView>();
  constructor(
    private authorizedPersonToRegistryViewFormService: AuthorizedPersonToRegistryViewFormService,
    private pzService: PzService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.fetchCurrentUserPeselValue();
    this.fetchInitialAuthorizedPersonDuplicateInfoTranslation();
    this.createForm();
  }

  fetchCurrentUserPeselValue(): void {
    this.pzService
      .getPetentData()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((petentData) => {
        if (_.isEmpty(petentData)) {
          return;
        }
        this.initialAuthorizedPersonPesel = petentData.pesel;
      });
  }

  fetchInitialAuthorizedPersonDuplicateInfoTranslation(): void {
    this.translateService
      .get('NEW_REQUEST.INITIAL_AUTHORIZED_PERSON_DUPLICATE_INFO')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (translations) =>
          (this.authorizedPersonDuplicateInfoTranslation = translations)
      );
  }

  createForm(): void {
    this.form = this.authorizedPersonToRegistryViewFormService.getFormGroup();
  }

  createIdWithCompName(elementId: string): string {
    return `contact-person-${elementId}`;
  }

  shouldShowErrorAlert(
    formControl: AbstractControl,
    errorName: string
  ): boolean {
    return (
      this.isFieldReadyToShowAlert(formControl) &&
      this.hasError(formControl, errorName)
    );
  }

  hasError(formControl: AbstractControl, errorName: string): boolean {
    return !!(
      formControl &&
      formControl.errors &&
      formControl.errors[errorName]
    );
  }

  isFieldReadyToShowAlert(formControl: AbstractControl): boolean {
    return !!(
      formControl &&
      (formControl.dirty || formControl.touched || this.submitted)
    );
  }

  getNameSurnameFormControl(): AbstractControl {
    return this.form.get(
      this.authorizedPersonToRegistryViewControlName.NameSurname
    );
  }

  getPeselFormControl(): AbstractControl {
    return this.form.get(this.authorizedPersonToRegistryViewControlName.Pesel);
  }

  addContactPersonToList(): void {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    const formRawValue: any = this.form.getRawValue();
    const formValue = new AuthorizedPersonToRegistryView(
      formRawValue.nameSurname,
      formRawValue.pesel
    );
    if (
      this.isAuthorizedPersonWithLoggedUserDuplicatePrevent &&
      this.initialAuthorizedPersonPesel === formValue.pesel
    ) {
      this.toastr.warning(this.authorizedPersonDuplicateInfoTranslation);

      return;
    }
    this.newAuthorizedPersonToRegistryView.emit(formValue);
    this.resetForm();
  }

  resetForm(): void {
    this.form.reset();
    this.submitted = false;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
