import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FeeWaiverCause, FeeWaiverControlName } from './fee-waiver.model';

@Injectable()
export class FeeWaiverFormService {
  constructor(private formBuilder: UntypedFormBuilder) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [FeeWaiverControlName.FeeWaiverCheckbox]: [false],
      [FeeWaiverControlName.FeeWaiverCause]: [
        FeeWaiverCause.SocialAssistanceBecauseOfPoverty,
      ],
    });
  }
}
