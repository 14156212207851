import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { takeWhile } from 'rxjs/operators';
import { PaymentStatus } from '../../../../gk-payments/payments.model';
import { PaymentsService } from '../../../../gk-payments/payments.service';
import { CancelPaymentControlConfig } from '../../../gk-dynamic-list.model';
import {
  TriggerEmitter,
  TriggerId,
} from '../../../services/control-communication/control-communication.model';
import { ControlCommunicationService } from '../../../services/control-communication/control-communication.service';
import { DataService } from '../../../services/data/data.service';
import { Control } from '../control';

@Component({
  selector: 'gk-control-cancel-payment',
  templateUrl: './control-cancel-payment.component.html',
})
export class ControlCancelPaymentComponent
  extends Control<null | number>
  implements OnInit, TriggerEmitter
{
  @Input()
  override controlConfig: CancelPaymentControlConfig;
  loading = false;
  errorMsg = '';
  succeeded = false;
  translations: { [key: string]: string } = {};

  constructor(
    private paymentService: PaymentsService,
    private ngbModal: NgbModal,
    protected override controlCommunicationService: ControlCommunicationService,
    protected override translateService: TranslateService,
    protected override dataService: DataService
  ) {
    super(translateService, dataService, controlCommunicationService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.subscribeToControlCancelPaymentTranslations();
  }

  getTriggerIdToEmit(): TriggerId {
    return this.controlConfig.triggerIdToEmit;
  }

  emitPulse(): void {
    const triggerId = this.getTriggerIdToEmit();
    if (!triggerId) {
      return;
    }
    this.controlCommunicationService.getTrigger(triggerId).next();
  }

  subscribeToControlCancelPaymentTranslations(): void {
    this.translateService
      .get('GK.DYNAMIC_LIST.CONTROL_CANCEL_PAYMENT')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((translations) => (this.translations = translations));
  }

  canCancel(): boolean {
    const status = _.get(this.data, this.controlConfig.pathToStatus);
    const transId = this.getValue();

    if (!status || !_.isNumber(transId) || transId === 0) {
      return false;
    }

    return status === PaymentStatus.InProgress;
  }

  tryCancel(modalTemplate: TemplateRef<NgbModalRef>): void {
    this.ngbModal
      .open(modalTemplate)
      .result.then(() => {
        this.cancel();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  override shouldShowControl(): boolean {
    return super.shouldShowControl() && this.canCancel();
  }

  cancel(): void {
    this.loading = true;
    this.paymentService
      .cancelPayment(this.getValue())
      .pipe(takeWhile(() => this.isAlive))
      .subscribe({
        next: () => this.handleSuccessResponse(),
        error: (errorResponse: HttpErrorResponse) =>
          this.handleErrorResponse(errorResponse),
      });
  }

  handleSuccessResponse(): void {
    this.succeeded = true;
    this.unsetLoadingAndEmitPulse();
  }

  handleErrorResponse(errorResponse: HttpErrorResponse): void {
    this.errorMsg = _.get(
      errorResponse,
      'error.ResponseStatus.Message',
      this.translations['CANCEL_FAILED']
    );
    this.unsetLoadingAndEmitPulse();
  }

  unsetLoadingAndEmitPulse(): void {
    this.loading = false;
    this.emitPulse();
  }
}
