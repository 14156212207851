import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainRoutes } from '../../guards/guard.models';
import { RequestInProgressGuard } from '../../guards/request-in-progress/request-in-progress.guard';
import { SessionGuard } from '../../guards/session/session.guard';
import { MainRequestsListComponent } from '../../shared/main-requests-list/main-requests-list.component';
import { ATIOEAIPNewRequestComponent } from './new-request/new-request.component';

const accessToInformationOnEnvironmentAndItsProtectionRoutes: Routes = [
  {
    path: MainRoutes.AccessToInformationOnEnvironmentAndItsProtection,
    canActivate: [SessionGuard],
    children: [
      {
        path: '',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
      {
        path: 'new-request',
        component: ATIOEAIPNewRequestComponent,
        canDeactivate: [RequestInProgressGuard],
      },
      {
        path: 'requests-list',
        component: MainRequestsListComponent,
      },
      {
        path: '**',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(
      accessToInformationOnEnvironmentAndItsProtectionRoutes
    ),
  ],
  providers: [SessionGuard, RequestInProgressGuard],
  exports: [RouterModule],
})
export class AccessToInformationOnEnvironmentAndItsProtectionRoutingModule {}
