import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { GkComponentsModule, GkMapModule } from '@gk/gk-modules';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { createTranslateLoader } from '../address-number-request-portal/address-number-request-portal.module';
import { LandAndBuildingRegisterDataChangesNotificationRoutingModule } from './land-and-building-register-data-changes-notification-routing.module';
import { LandAndBuildingRegisterDataChangesNotificationComponent } from './land-and-building-register-data-changes-notification.component';
import { LBRCNewDataChangesNotificationComponent } from './new-data-changes-notification/new-data-changes-notification.component';
import { LBRCNNewDataChangesNotificationFormService } from './services/new-data-changes-notification-form/new-data-changes-notification-form.service';

@NgModule({
  declarations: [
    LandAndBuildingRegisterDataChangesNotificationComponent,
    LBRCNewDataChangesNotificationComponent,
  ],
  imports: [
    SharedModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    GkMapModule,
    GkComponentsModule,
    LandAndBuildingRegisterDataChangesNotificationRoutingModule,
  ],
  providers: [LBRCNNewDataChangesNotificationFormService],
})
export class LandAndBuildingRegisterDataChangesNotificationModule {}
