<select
  id="scale"
  class="btn btn-bg-color-from-db btn-sm bg-white text-secondary h-100"
  [title]="'GK.MAP.SCALE' | translate"
  [attr.aria-label]="'GK.MAP.SCALE' | translate"
  [ngModel]="getScaleNameByResolution(allowedResolution)"
  (ngModelChange)="emitResolutionChange($event)"
  [disabled]="restriction"
  >
  @for (scale of scales; track scale) {
    <option>{{ scale.name }}</option>
  }
</select>
