import { AbstractControl } from '@angular/forms';

const isEmailValid = (maybeEmail: string): boolean => {
  const regExp = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return regExp.test(maybeEmail);
};

export function ValidateEmail(control: AbstractControl) {
  return isEmailValid(control.value) ? null : { validEmail: true };
}
