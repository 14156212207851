import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { LawPersonAddressControlName } from '../services/law-person-form/law-person-form.model';

@Component({
  selector: 'gk-post-office-modal',
  templateUrl: './post-office-modal.component.html',
  styleUrls: ['./post-office-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostOfficeModalComponent {
  @Input() addressFormGroup: FormGroup;
  @Input() idPrefix = '';
  @ViewChild('postOfficeModal') postOfficeModal: NgbModalRef;
  customPostOfficeModalHeaderInfo: string;
  formControlNameEnum = LawPersonAddressControlName;

  constructor(private ngbModal: NgbModal) {}

  isPostOfficeFormControlValid(): boolean {
    const postOfficeFormControlValue = this.getPostOfficeFormControl().value;

    return !!postOfficeFormControlValue;
  }

  askForPostOfficeWhenPostalCodeIsNotFromDictionary(
    customPostOfficeModalHeaderInfo?: string
  ): Observable<boolean> {
    this.customPostOfficeModalHeaderInfo = customPostOfficeModalHeaderInfo;
    const postOfficeFormControl = this.getPostOfficeFormControl();
    postOfficeFormControl?.clearValidators();
    const postalCodeFormControl = this.getPostalCodeFormControl();
    return new Observable<boolean>((observer) => {
      if (postalCodeFormControl?.value && !postalCodeFormControl?.value.id) {
        this.setPostOfficeValidatorsAndPatchDefaultValue();
        const modal = this.openAndReturnPostOfficeModal();
        modal.result.then((submitted) => {
          if (submitted) {
            observer.next(submitted);
            observer.complete();
          } else {
            postOfficeFormControl.clearValidators();
            postOfficeFormControl.patchValue(null);
          }
        });
      } else {
        observer.next();
        observer.complete();
      }
    });
  }

  getPostalCodeFormControl(): AbstractControl {
    return this.addressFormGroup?.get(this.formControlNameEnum.PostalCode);
  }

  setPostOfficeValidatorsAndPatchDefaultValue(): void {
    const postOfficeFormControl = this.getPostOfficeFormControl();
    const placeFormControl = this.getPlaceFormControl();
    const placeFormControlValue =
      placeFormControl.value.name || placeFormControl.value;
    postOfficeFormControl.setValidators(Validators.required);
    postOfficeFormControl.patchValue(placeFormControlValue);
  }

  getPostOfficeFormControl(): AbstractControl {
    return this.addressFormGroup?.get(this.formControlNameEnum.PostOffice);
  }

  openAndReturnPostOfficeModal(): NgbModalRef {
    return this.ngbModal.open(this.postOfficeModal, {
      backdrop: 'static',
      keyboard: false,
    });
  }

  getPlaceFormControl(): AbstractControl {
    return this.addressFormGroup?.get(this.formControlNameEnum.Place);
  }
}
