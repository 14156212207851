import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { SessionService } from '../../services/session/session.service';
import { MainRoutes } from '../guard.models';

@Injectable()
export class GuestSessionGuard {
  constructor(
    private sessionService: SessionService,
    private router: Router,
  ) {}

  canActivate(): Observable<boolean> {
    return this.sessionService.getSystemSession().pipe(
      map((sessionState) => sessionState.isGuest),
      switchMap((isGuestSession) =>
        isGuestSession ? of(true) : this.sessionService.authenticateGuest(),
      ),
      map(() => true),
      catchError(() => {
        this.navigateToNotFound();

        return of(false);
      }),
    );
  }

  navigateToNotFound(): void {
    this.router.navigateByUrl(`/${MainRoutes.NotFound}`);
  }
}
