export enum SimplifiedForestManagementPlanDocTypeId {
  Other = 8,
  StampDuty = 10040,
  RequestStampDuty = 10040,
  PowerOfAttorney = 10035
}

export enum SimplifiedForestManagementPlanDocTypeName {
  ProxyStampDuty = 'proxy stamp duty',
  RequestStampDuty = 'request stamp duty'
}

export enum SimplifiedForestManagementPlanDocTypeLabelKey {
  Other = 'ENVIRONMENTAL_PROTECTION_PORTAL.SIMPLIFIED_FOREST_MANAGEMENT_PLAN.DOC_TYPE_DESCRIPTION.OTHER',
  RequestStampDuty = 'ENVIRONMENTAL_PROTECTION_PORTAL.SIMPLIFIED_FOREST_MANAGEMENT_PLAN.DOC_TYPE_DESCRIPTION.REQUEST_STAMP_DUTY',
  PowerOfAttorney = 'ENVIRONMENTAL_PROTECTION_PORTAL.SIMPLIFIED_FOREST_MANAGEMENT_PLAN.DOC_TYPE_DESCRIPTION.POWER_OF_ATTORNEY'
}

export function getSimplifiedForestManagementPlanDocTypeLabelKeyById(id: number): string {
  switch (id) {
    case SimplifiedForestManagementPlanDocTypeId.Other: {
      return SimplifiedForestManagementPlanDocTypeLabelKey.Other;
    }
    case SimplifiedForestManagementPlanDocTypeId.RequestStampDuty: {
      return SimplifiedForestManagementPlanDocTypeLabelKey.RequestStampDuty;
    }
    case SimplifiedForestManagementPlanDocTypeId.PowerOfAttorney: {
      return SimplifiedForestManagementPlanDocTypeLabelKey.PowerOfAttorney;
    }
    default: {
      return '';
    }
  }
}
