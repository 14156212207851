export const payerPersonTypeId = 1;
export const investorPersonTypeId = 2;
export const designerPersonTypeId = 3;
export const plenipotentiaryPersonTypeId = 6;
export const requiredPersonTypes = [payerPersonTypeId];

export interface ApiPersonType {
  Id: number;
  Nazwa: string;
  TypMulti: boolean;
}

export class PersonType {
  constructor(
    public id: number,
    public name: string,
    public multi: boolean,
    public isRequired?: boolean
  ) {}

  static fromApiToApp(apiPersonType: ApiPersonType): PersonType {
    return new this(
      apiPersonType.Id,
      apiPersonType.Nazwa,
      apiPersonType.TypMulti,
      requiredPersonTypes.includes(apiPersonType.Id)
    );
  }
}
