import { PermissionControlName } from './permission-form.model';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Injectable()
export class PermissionFormService {
  constructor(private formBuilder: UntypedFormBuilder) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [PermissionControlName.Number]: this.formBuilder.control(null, Validators.required)
    });
  }
}
