import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { createLandParcelDtoObjectFromString } from '../../../utils/utils';
import {
  ApiValue,
  Filter,
  FilterOperatorType,
  ValueType,
} from '../../gk-dynamic-list.model';
import { DateHelperUtil } from '../../utils/date-helper.util';
import { isDictionaryField } from '../dictionary/dictionary.model';
import {
  ApiFilter,
  ApiFullFilter,
  ApiListBody,
  SortObject,
} from '../services.model';
import { handleApiError } from '../services.utils';

@Injectable()
export class DynamicListService {
  public caseUuid = new Subject<string>();
  public reloadList = new Subject<void>();

  constructor(private httpClient: HttpClient) {}

  handlePanelChange(uuid: string): void {
    this.caseUuid.next(uuid);
  }

  handlePanelReload(): void {
    this.reloadList.next();
  }

  convertToApiValue(filter: Filter): ApiValue {
    switch (filter.columnConfig.controlConfig.valueType) {
      case ValueType.Number:
        return parseInt(filter.rightOperand as string, 10);
      case ValueType.DateTime:
        if (filter.operator.filterOperatorType !== FilterOperatorType.Between) {
          return DateHelperUtil.ngbDateToApiDateTime(
            filter.rightOperand as NgbDateStruct,
          );
        } else {
          const apiDateStruct = {
            DataOd: DateHelperUtil.ngbDateToApiDateTime(
              _.get(filter.rightOperand, 'from') as NgbDateStruct,
            ),
            DataDo: DateHelperUtil.ngbDateToApiDateTime(
              _.get(filter.rightOperand, 'to') as NgbDateStruct,
            ),
          };
          return apiDateStruct;
        }
      case ValueType.LandParcelNumber:
        return createLandParcelDtoObjectFromString(filter.rightOperand);
      case ValueType.Text:
      case ValueType.Bool:
      case ValueType.Dict:
        return filter.rightOperand;
      case ValueType.PlaceTypeahead:
      case ValueType.StreetTypeahead:
        return isDictionaryField(filter.rightOperand)
          ? filter.rightOperand.id
          : filter.rightOperand;
    }
  }

  getApiFullFilter(filters: Filter[]): ApiFullFilter {
    const apiFilters: ApiFilter[] = filters.map(
      (filter) =>
        new ApiFilter(
          filter.columnConfig.controlConfig.customFilterFieldName
            ? filter.columnConfig.controlConfig.customFilterFieldName
            : filter.columnConfig.controlConfig.pathToValue,
          this.convertToApiValue(filter),
          filter.operator.filterOperatorType,
        ),
    );

    return new ApiFullFilter(apiFilters);
  }

  getListData(
    url: string,
    skip: number,
    take: number,
    sort: SortObject[],
    filters?: Filter[],
    apiFullFilter?: ApiFullFilter,
    portalId?: number,
  ): Observable<any> {
    if (!filters && !apiFullFilter) {
      throw new Error('Filters are not defined!');
    }
    const ajaxFilters = apiFullFilter
      ? apiFullFilter
      : this.getApiFullFilter(filters);
    return this.httpClient
      .post(url, new ApiListBody(skip, take, ajaxFilters, sort, portalId))
      .pipe(catchError(handleApiError));
  }
}
