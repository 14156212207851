import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { SizeReportsLimitFromApi } from './size-reports-limit-model';
import { ApiResponse } from '../../../services';

@Injectable({
  providedIn: 'root',
})
export class SizeReportsLimitService {
  constructor(private httpClient: HttpClient) {}

  subscribeToSizeReportsLimitData(): Observable<SizeReportsLimitFromApi[]> {
    return this.httpClient
      .get<ApiResponse<SizeReportsLimitFromApi[]>>(
        '/api/geodeta/slo/operat/limit'
      )
      .pipe(map((response) => response.Response));
  }

  getLimitSizeValue(area: string): Observable<number> {
    return this.subscribeToSizeReportsLimitData().pipe(
      map((limits) => {
        const parseArea = parseFloat(area.split(' ')[0]);
        const limitItem = limits.find(
          (item) => item.HaOd < parseArea && item.HaDo > parseArea
        );

        return limitItem ? limitItem.MegaBytes : undefined;
      })
    );
  }
}
