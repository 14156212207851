import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DomRefService {
  constructor(@Inject(DOCUMENT) private dom: Document) {}

  get nativeDom(): Document {
    return this.dom;
  }
}
