import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { getDefaultControlValue } from '../../../utils/reactive-form/reactive-form.util';
import { atLeastOneValidator } from '../../../validators/at-least-one-validator/at-least-one-validator';
import { nipValidator } from '../../../validators/nip-validator/nip-validator';
import { peselValidator } from '../../../validators/pesel-validator/pesel-validator';
import { ValidatePostalCode } from '../../../validators/postal-code-validator/postal-code-validator';
import { regonValidator } from '../../../validators/regon-validator/regon-validator';
import { streetFromDictionaryValidator } from '../../../validators/street-from-dictionary-validator/street-from-dictionary-validator';
import { LawPersonType } from '../law-person/law-person.model';
import {
  LawPersonAddressControlName,
  LawPersonControlName,
  LegalPersonControlName,
  MaxLengthAddressField,
  NaturalPersonControlName,
} from './law-person-form.model';

@Injectable()
export class LawPersonFormService {
  savedPesel = '';
  savedPermissionNumber = '';
  noPeselRequired: boolean;

  constructor(private formBuilder: UntypedFormBuilder) {}

  getNaturalPersonFormGroup(forDesigner = false): UntypedFormGroup {
    const fg = this.formBuilder.group({
      [NaturalPersonControlName.FirstName]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [NaturalPersonControlName.LastName]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [NaturalPersonControlName.Pesel]: [
        getDefaultControlValue(),
        this.noPeselRequired
          ? [peselValidator()]
          : [Validators.required, peselValidator()],
      ],
      [NaturalPersonControlName.PermissionNumber]: [
        getDefaultControlValue(),
        Validators.required,
      ],
    });

    this.adjustFormByPersonType(fg, forDesigner);

    return fg;
  }

  private getNewPermissionNumberControl(): UntypedFormControl {
    return this.formBuilder.control(getDefaultControlValue(), [
      Validators.required,
    ]);
  }

  private getNewPeselControl(): UntypedFormControl {
    return this.formBuilder.control(
      getDefaultControlValue(),
      this.noPeselRequired
        ? [peselValidator()]
        : [Validators.required, peselValidator()],
    );
  }

  private addPeselControl(fg: UntypedFormGroup): void {
    fg.addControl(NaturalPersonControlName.Pesel, this.getNewPeselControl());
  }

  private addPermissionNumberControl(fg: UntypedFormGroup): void {
    fg.addControl(
      NaturalPersonControlName.PermissionNumber,
      this.getNewPermissionNumberControl(),
    );
  }

  private getPeselControl(fg: UntypedFormGroup): UntypedFormControl {
    return fg.get(NaturalPersonControlName.Pesel) as UntypedFormControl;
  }

  private getPermissionNumberControl(fg: UntypedFormGroup): UntypedFormControl {
    return fg.get(
      NaturalPersonControlName.PermissionNumber,
    ) as UntypedFormControl;
  }

  adjustFormByPersonType(
    fg: UntypedFormGroup,
    forDesigner = false,
    noPeselRequired?: boolean,
  ): void {
    if (!fg) {
      return;
    }
    this.noPeselRequired = noPeselRequired;
    const maybePeselControl = this.getPeselControl(fg);
    const maybePermissionNumberControl = this.getPermissionNumberControl(fg);
    this.savedPesel =
      (maybePeselControl && maybePeselControl.value) || this.savedPesel;
    this.savedPermissionNumber =
      (maybePermissionNumberControl && maybePermissionNumberControl.value) ||
      this.savedPermissionNumber;

    if (forDesigner) {
      this.addPermissionNumberControl(fg);
      fg.removeControl(NaturalPersonControlName.Pesel);

      if (this.savedPermissionNumber) {
        this.getPermissionNumberControl(fg).patchValue(
          this.savedPermissionNumber,
        );
      }
    } else {
      this.addPeselControl(fg);
      fg.removeControl(NaturalPersonControlName.PermissionNumber);

      if (this.savedPesel) {
        this.getPeselControl(fg).patchValue(this.savedPesel);
      }
    }
  }

  getFormGroup(
    enterNewPersonMode: boolean,
    forDesigner = false,
    noPeselRequired?: boolean,
    primitivePostalCode?: boolean,
    isAddressRequired = true,
  ): UntypedFormGroup {
    this.noPeselRequired = noPeselRequired;

    return this.formBuilder.group({
      [LawPersonControlName.Type]: LawPersonType.Legal,
      [LawPersonControlName.LegalPersonData]: this.formBuilder.group(
        {
          [LegalPersonControlName.Name]: [
            getDefaultControlValue(!enterNewPersonMode),
          ],
          [LegalPersonControlName.Nip]: [
            getDefaultControlValue(),
            nipValidator(),
          ],
          [LegalPersonControlName.Regon]: [
            getDefaultControlValue(),
            regonValidator(),
          ],
          [LegalPersonControlName.KrsNumber]: [
            getDefaultControlValue(),
            Validators.pattern('^[0-9]*$'),
          ],
          [LegalPersonControlName.TypeId]: [
            getDefaultControlValue(!enterNewPersonMode),
            Validators.required,
          ],
        },
        { validator: atLeastOneValidator([LegalPersonControlName.TypeId]) },
      ),
      [LawPersonControlName.NaturalPersonData]:
        this.getNaturalPersonFormGroup(forDesigner),
      [LawPersonControlName.Address]: this.formBuilder.group(
        {
          [LawPersonAddressControlName.Place]: [
            getDefaultControlValue(!enterNewPersonMode),
            [
              isAddressRequired ? Validators.required : null,
              Validators.maxLength(MaxLengthAddressField.Place),
            ].filter(Boolean),
          ],
          [LawPersonAddressControlName.Street]: [
            getDefaultControlValue(!enterNewPersonMode),
            [
              isAddressRequired ? Validators.required : null,
              Validators.maxLength(MaxLengthAddressField.Street),
            ].filter(Boolean),
          ],
          [LawPersonAddressControlName.StreetAbsence]: false,
          [LawPersonAddressControlName.BuildingNumber]: [
            getDefaultControlValue(!enterNewPersonMode),
            [
              isAddressRequired ? Validators.required : null,
              Validators.maxLength(MaxLengthAddressField.BuildingNumber),
            ].filter(Boolean),
          ],
          [LawPersonAddressControlName.LocalNumber]: [
            getDefaultControlValue(!enterNewPersonMode),
            Validators.maxLength(MaxLengthAddressField.LocalNumber),
          ],
          [LawPersonAddressControlName.PostalCode]: [
            getDefaultControlValue(!enterNewPersonMode),
            primitivePostalCode
              ? [
                  isAddressRequired ? Validators.required : null,
                  ValidatePostalCode,
                  Validators.maxLength(MaxLengthAddressField.PostalCode),
                ].filter(Boolean)
              : [
                  isAddressRequired ? Validators.required : null,
                  Validators.maxLength(MaxLengthAddressField.PostalCode),
                ].filter(Boolean),
          ],
          [LawPersonAddressControlName.PostOffice]: [getDefaultControlValue()],
        },
        { validator: streetFromDictionaryValidator() },
      ),
    });
  }
}
