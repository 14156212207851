<button
  class="btn btn-sm btn-success"
  [disabled]="!invoices.length || (paymentService.isPaymentInProgress | async)"
  (click)="pay()"
  [attr.aria-label]="'GK.DYNAMIC_LIST.MAKE_PAYMENT' | translate"
  [attr.title]="'GK.DYNAMIC_LIST.MAKE_PAYMENT' | translate"
>
  {{ 'GK.DYNAMIC_LIST.MAKE_PAYMENT' | translate }}
</button>
<i
  class="bi bi-question-circle ms-1"
  placement="top-right"
  [ngbTooltip]="'GK.DYNAMIC_LIST.TRADITIONAL_TRANSFER_INFO' | translate"
></i>
