import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

type MarkAsMethods = 'markAsTouched' | 'markAsUntouched' | 'markAsPristine';

function markFormGroupsAs(
  formGroup: UntypedFormGroup | UntypedFormGroup[],
  markMethodName: MarkAsMethods,
): void {
  if (Array.isArray(formGroup)) {
    formGroup.forEach((element) => markFormGroupsAs(element, markMethodName));
  } else if (formGroup.controls) {
    const keys = Object.keys(formGroup.controls);
    keys.forEach((key) => {
      const control = formGroup.controls[key];
      if (control instanceof UntypedFormControl) {
        control[markMethodName]();
      } else if (control instanceof UntypedFormGroup) {
        markFormGroupsAs(control, markMethodName);
      }
    });
  }
}

export function markFormGroupsTouched(
  formGroup: UntypedFormGroup | UntypedFormGroup[],
): void {
  markFormGroupsAs(formGroup, 'markAsTouched');
}

export function markFormGroupsUntouched(
  formGroup: UntypedFormGroup | UntypedFormGroup[],
): void {
  markFormGroupsAs(formGroup, 'markAsUntouched');
}

export function markFormGroupsPristine(
  formGroup: UntypedFormGroup | UntypedFormGroup[],
): void {
  markFormGroupsAs(formGroup, 'markAsPristine');
}

export const getDefaultControlValue = (
  disabled?: boolean,
): { value: string; disabled?: boolean } => ({
  value: null,
  disabled: disabled,
});

export function setDisabledStatusOfControls(
  controls: UntypedFormControl[],
  disabled: boolean,
  emitEvent = false,
): void {
  controls.forEach((control) => {
    if (disabled) {
      control.disable({ emitEvent });
    } else {
      control.enable({ emitEvent });
    }
  });
}
