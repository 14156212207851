import { Injectable } from '@angular/core';
import {
  MapRangePickerGridItem, PracaLocationResponseDto,
  SimpleDzialkaResponseDto
} from './map-range-picker-grid-data-model';
import { GridDataService } from '../services/grid-data.service';
import { RequestType } from '../../gk-dynamic-list/gk-dynamic-list.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MapRangePickerGridDataService extends GridDataService<
  SimpleDzialkaResponseDto,
  MapRangePickerGridItem
> {
  constructor() {
    super(undefined, RequestType.Post, (items): MapRangePickerGridItem[] => {
      return items.map((item) =>
        MapRangePickerGridItem.landParcelFromApiToApp(item),
      );
    });
  }

  checkRange(ranges: string[]): Observable<PracaLocationResponseDto> {
    return this.http.post<PracaLocationResponseDto>('/api/geodeta/prace/zakres/check', {
      Zakresy: ranges,
    })
  }
}
