@if (shouldShowControl()) {
  <div gkControlRow>
    <span class="w-100 my-3 fw-bold text-center">
      <gk-translated-label
        [configLabel]="controlConfig.label"
      ></gk-translated-label>
    </span>

    @if (controlConfig.printControlConfig) {
      <div>
        <gk-control-print
          [data]="data"
          [controlConfig]="controlConfig.printControlConfig"
        >
        </gk-control-print>
      </div>
    }
  </div>
}
