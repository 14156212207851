import { SelectItem } from '../../../services';

export enum GMPPrintQuality {
  Low = 0.7,
  Optimal = 1.1,
  Medium = 1.3,
  High = 1.6,
  Top = 2,
}

export const gmpPrintQualityOptions: SelectItem<number>[] = [
  new SelectItem(
    GMPPrintQuality.Low,
    'GK.MAP.PRINT.ATTRIBUTES_FORM.QUALITY.LOW',
  ),
  new SelectItem(
    GMPPrintQuality.Optimal,
    'GK.MAP.PRINT.ATTRIBUTES_FORM.QUALITY.OPTIMAL',
  ),
  new SelectItem(
    GMPPrintQuality.Medium,
    'GK.MAP.PRINT.ATTRIBUTES_FORM.QUALITY.MEDIUM',
  ),
  new SelectItem(
    GMPPrintQuality.High,
    'GK.MAP.PRINT.ATTRIBUTES_FORM.QUALITY.HIGH',
  ),
  new SelectItem(
    GMPPrintQuality.Top,
    'GK.MAP.PRINT.ATTRIBUTES_FORM.QUALITY.TOP',
  ),
];
