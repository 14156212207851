import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';

import { MapRangePickerGridDataService } from './map-range-picker-grid-data.service';
import {
  MapRangePickerGridFormControlValue,
  MapRangePickerGridItem,
  RangePickerFeatureType,
  SimpleDzialkaResponseDto,
} from './map-range-picker-grid-data-model';
import {
  getMapRangePickerGridToolbarItems,
  mapRangePickerGridColumns,
} from './map-range-picker-grid-model';
import { takeWhile } from 'rxjs';
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { GkKendoGridComponent } from '../gk-kendo-grid/gk-kendo-grid.component';
import {
  EgibObject,
  MapAction,
  MapObjectTableActionType,
  MapObjectTableState,
  SourceType,
  ToolActionType,
  ToolType,
  Wkt,
  WktType,
} from '../../gk-map/models';
import { KendoWindowInstanceNames } from '../services/kendo-window/kendo-window.model';
import { GkKendoGeomFileSelectComponent } from '../gk-kendo-grid/gk-kendo-geom-file-select/gk-kendo-geom-file-select.component';
import { ConversionUtils, GeomUtils } from '../../gk-map/utils';
import { GkMapSheetFormComponent } from '../gk-kendo-grid/gk-map-sheet-form/gk-map-sheet-form.component';
import { LandParcelSearchFormComponent } from '../formly/forms/land-parcel-search-form/land-parcel-search-form.component';
import { LandParcelSearchFormModel } from '../formly/forms/land-parcel-search-form/land-parcel-search-form.model';
import { PolygonTopologyService } from '../../gk-map/services';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { RangeTypeValue } from '../../gk-map/configs';
import { DOCUMENT } from '@angular/common';
import { GkKendoIdsFileSelectComponent } from '../gk-kendo-grid/gk-kendo-ids-file-select/gk-kendo-ids-file-select.component';

@Component({
  selector: 'gk-map-range-picker-grid',
  templateUrl: '../gk-kendo-grid/gk-kendo-grid.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MapRangePickerGridComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: MapRangePickerGridComponent,
    },
  ],
})
export class MapRangePickerGridComponent
  extends GkKendoGridComponent<SimpleDzialkaResponseDto, MapRangePickerGridItem>
  implements OnInit, Validator, AfterViewInit
{
  @Input() isNewLaw2020 = 1;
  @Input() coverageOfOriginalRangeValidation: boolean;
  public override columns = mapRangePickerGridColumns;
  public override selectable: SelectableSettings = {
    enabled: true,
    mode: 'single',
  };
  public selectByIdentifiersTranslation: string;

  protected override height = '160px';
  protected override unionResponseWithGridData = true;

  private noBufferTranslation: string;
  private noRangeTranslation: string;
  private wktFileNameTranslation: string;
  private txtFileNameTranslation: string;
  private rangeNotInAreaTranslation: string;
  private rangePartiallyNotInAreaTranslation: string;
  private rangeCoverageValidationTranslation: string;
  private initialRangesWktGeom: string[];

  constructor(
    private mapRangePickerGridDataService: MapRangePickerGridDataService,
    private polygonTopologyService: PolygonTopologyService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    super(mapRangePickerGridDataService);
    this.selectionKey = 'uuid';
  }

  public ngOnInit(): void {
    this.subscribeToCountChanges();
    this.fetchTxtFileNameTranslation();
    this.fetchWktFileNameTranslation();
    this.fetchRangeNotInAreaTranslation();
    this.fetchRangePartiallyNotInAreaTranslation();
    this.fetchRangeCoverageValidationTranslation();
    this.fetchSelectByIdentifiersTranslation();
    this.subscribeToGridDataBound();
  }

  public override ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  public getParcelsByRangeFromFile(): void {
    const windowRef = this.gkKendoWindowService.open({
      parent: this,
      windowInstanceName: KendoWindowInstanceNames.GeomFileSelection,
      title: 'Wyszukaj poprzez zakres z pliku',
      content: GkKendoGeomFileSelectComponent,
    });

    windowRef.result.pipe(takeWhile(() => this.isAlive)).subscribe((result) => {
      const data = result as string | undefined;
      if (typeof data === 'string') {
        const wktType = ConversionUtils.getWktType(data);
        if (wktType === WktType.Polygon || wktType === WktType.MultiPolygon) {
          this.searchMapObjects(data);
        }
      }
    });
  }

  public getParcelsByIdsFromFile(): void {
    const windowRef = this.gkKendoWindowService.open({
      parent: this,
      windowInstanceName: KendoWindowInstanceNames.IdsFileSelection,
      title: 'SURVEYOR_PORTAL.NEW_REQUEST.LOAD_BY_IDS_FROM_FILE',
      content: GkKendoIdsFileSelectComponent,
      width: 460,
    });

    windowRef.result.pipe(takeWhile(() => this.isAlive)).subscribe((result) => {
      if (Array.isArray(result)) {
        this.searchMapObjects(undefined, result);
      }
    });
  }

  public loadRangeFromFile(): void {
    const windowRef = this.gkKendoWindowService.open({
      parent: this,
      windowInstanceName: KendoWindowInstanceNames.GeomFileSelection,
      title: 'Wczytaj zakres z pliku',
      content: GkKendoGeomFileSelectComponent,
    });

    windowRef.result.pipe(takeWhile(() => this.isAlive)).subscribe((result) => {
      const data = result as string | undefined;
      if (typeof data === 'string') {
        const wktType = ConversionUtils.getWktType(data);
        if (wktType === WktType.Polygon || wktType === WktType.MultiPolygon) {
          this.addRangeToGrid(data);
        }
      }
    });
  }

  public addRangeToGrid(wkt: string): void {
    this.mapRangePickerGridDataService.next({
      data: [MapRangePickerGridItem.fromWktToRange(wkt)],
      total: 1,
    });
  }

  public addLandParcelsToGrid(landParcels: EgibObject[]): void {
    this.mapRangePickerGridDataService.next({
      data: landParcels.map((landParcel) =>
        MapRangePickerGridItem.fromEgibObjectToApp(landParcel),
      ),
      total: landParcels.length,
    });
  }

  public showMessageIfOnlyOneRangeAllowed(): boolean {
    if (this.getRangesWktGeom()?.length > 0) {
      this.toastr.warning('Dozwolony jest tylko pojedynczy zakres');
      return false;
    }
    return true;
  }

  public createRangeBySheet(): void {
    this.dispatchPreviewModeChange(true);
    this.dispatchToolAndSourceActivationChange(
      ToolType.RectangularExtent,
      SourceType.MapSheetForm,
      true,
    );

    const windowRef = this.gkKendoWindowService.open({
      parent: this,
      windowInstanceName: KendoWindowInstanceNames.MapSheetForm,
      title: 'Stwórz zakres za pomocą arkusza',
      content: GkMapSheetFormComponent,
    });
    windowRef.window.instance['el'].nativeElement.style['z-index'] = 100000001;

    windowRef.result.pipe(takeWhile(() => this.isAlive)).subscribe((result) => {
      const data = result as string | undefined;
      if (typeof data === 'string') {
        const wktType = ConversionUtils.getWktType(data);
        if (wktType === WktType.Polygon || wktType === WktType.MultiPolygon) {
          this.searchMapObjects(data);
        }
      }
      this.dispatchToolAndSourceActivationChange(
        ToolType.RectangularExtent,
        SourceType.MapSheetForm,
        false,
      );
      this.dispatchPreviewModeChange(false);
    });
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    const controlValue = control.value as MapRangePickerGridFormControlValue;

    if (!controlValue?.data.length) {
      return {
        required: true,
      };
    }

    const parcels = controlValue.data.filter(
      (item) => item.type === RangePickerFeatureType.LandParcel,
    );

    if (parcels.some((item) => item.isArchival)) {
      return {
        landParcelArchivalFound: true,
      };
    }

    const parcelsGeoms = parcels.map((item) => item.geom);

    if (
      this.isNewLaw2020 &&
      parcelsGeoms.length &&
      !GeomUtils.areLandParcelsAdjacent(parcelsGeoms)
    ) {
      return {
        notAdjacentParcels: true,
      };
    }

    const range = controlValue.data.find(
      (item) => item.type !== RangePickerFeatureType.LandParcel,
    );

    if (!range?.geom) {
      return {
        notSelectedRange: true,
      };
    }

    if (
      this.coverageOfOriginalRangeValidation &&
      this.initialRangesWktGeom?.length &&
      !GeomUtils.checkEditedGeometryCoverInitialGeometry(
        range.geom,
        this.initialRangesWktGeom[0],
      )
    ) {
      this.showRangeCoverageValidationToastr();

      return {
        rangesCoverageNotValid: true,
      };
    }

    if (
      parcelsGeoms.length &&
      this.checkSomeLandParcelGeomNotIntersectsRange(parcelsGeoms, range.geom)
    ) {
      return {
        notIntersectsRange: true,
      };
    }

    return null;
  }

  public openLandParcelSearchWindow(): void {
    const windowRef = this.gkKendoWindowService.open({
      parent: this,
      windowInstanceName: KendoWindowInstanceNames.LandParcelSearch,
      title: 'Wyszukaj działkę',
      content: LandParcelSearchFormComponent,
    });

    windowRef.content.instance.isLoading =
      this.mapRangePickerGridDataService.$loading;
    windowRef.content.instance.submitForm
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data: LandParcelSearchFormModel) => {
        this.mapRangePickerGridDataService.url = '/api/geodeta/dzialki/search';
        this.mapRangePickerGridDataService.queryByState({
          returnGeom: true,
          ...LandParcelSearchFormModel.fromAppToApi({
            landParcelSearch: data.landParcelSearch,
            addressSearch: data.addressSearch,
            landAndMortgageRegisterSearch: data.landAndMortgageRegisterSearch,
          }),
        });
      });
  }

  public createRangeFromParcels(): void {
    const parcelsRanges = this.getLandParcelsWkt();
    if (!parcelsRanges.length) {
      this.showNoParcelsNotification();

      return;
    }

    this.mapRangePickerGridDataService.$loading.next(true);

    this.polygonTopologyService
      .getPolygonsUnion(parcelsRanges)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe({
        next: (polygonsUnion) => {
          this.mapRangePickerGridDataService.$loading.next(false);
          this.addRangeToGrid(polygonsUnion.wkt);
          if (this.getRangesWktGeom().length) {
            this.toastr.warning(
              'Dotychczasowy obszar został zastąpiony obszarem utworzonym z działek',
            );
          }
        },
        error: () => {
          this.mapRangePickerGridDataService.$loading.next(false);
        },
      });
  }

  public validateAndIncreaseRangeByBuffer(bufferValue: number): void {
    this.mapRangePickerGridDataService.$loading.next(true);
    if (!bufferValue || bufferValue <= 0) {
      this.toastr.warning(this.noBufferTranslation);
      this.mapRangePickerGridDataService.$loading.next(false);

      return;
    }
    if (this.getRangesWktGeom().length !== 1) {
      this.toastr.warning(this.noRangeTranslation);
      this.mapRangePickerGridDataService.$loading.next(false);

      return;
    }
    this.polygonTopologyService
      .getPolygonsUnion(this.getRangesWktGeom(), bufferValue)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe({
        next: (polygonsUnion) => {
          this.mapRangePickerGridDataService.$loading.next(false);
          this.addRangeToGrid(polygonsUnion.wkt);
        },
        error: () => {
          this.mapRangePickerGridDataService.$loading.next(false);
        },
      });
  }

  public removeAllMapRangePickerGridItems(): void {
    this.gkKendoGridMapService.$pendingMapActions.next([
      new MapAction(ToolActionType.ClearAllMapObjects),
    ]);
    this.removeAllGridItems();
  }

  public onModifyGeometryToolbarButtonClick(): void {
    if (!this.showMessageIfNoRowsSelected()) {
      return;
    }

    if (!this.showMessageIfNoRangeSelected()) {
      return;
    }

    const selectedItem =
      this.mapRangePickerGridDataService.$selection.value?.selectedItems[0];

    const wktGeom = selectedItem.geom;

    this.showGeomOnMap(wktGeom, () => {
      const mapObjectTablState = this.getMapObjectTableState();
      this.addRangeToGrid(mapObjectTablState.editedMapObject.geom);
      this.dispatch(
        this.getNewMapActionWithMapObjectTableStateIndex(
          MapObjectTableActionType.RemoveFromEdition,
        ),
      );
    });

    this.gkKendoGridMapService.$pendingMapActions.next([
      new MapAction(MapObjectTableActionType.Edit, {
        geom: wktGeom,
      }),
    ]);
  }

  public saveRangeToFile(rangeType: RangeTypeValue): void {
    if (!this.showMessageIfNoRowsSelected()) {
      return;
    }
    if (!this.showMessageIfNoRangeSelected()) {
      return;
    }
    switch (rangeType) {
      case RangeTypeValue.Wkt: {
        this.downloadWkt();
        break;
      }
      case RangeTypeValue.Txt: {
        this.downloadTxt();
        break;
      }
    }
  }

  private subscribeToGridDataBound(): void {
    this.mapRangePickerGridDataService.$gridDataBound
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(() => {
        this.handleRangeValidation();
      });
  }

  private handleRangeValidation(): void {
    const rangesWktGeom = this.getRangesWktGeom();
    if (rangesWktGeom.length) {
      this.mapRangePickerGridDataService
        .checkRange(this.getRangesWktGeom())
        .pipe(takeWhile(() => this.isAlive))
        .subscribe((data) => {
          if (data.PracaNotInObszarMiasta) {
            this.toastr.warning(this.rangeNotInAreaTranslation);
          }
          if (data.PracaPartiallyInObszarMiasta) {
            this.toastr.warning(this.rangePartiallyNotInAreaTranslation);
          }
        });

      if (
        this.coverageOfOriginalRangeValidation &&
        !this.initialRangesWktGeom
      ) {
        this.initialRangesWktGeom = rangesWktGeom;
      }
    }
  }

  private fetchRangeNotInAreaTranslation(): void {
    this.translateService
      .get('SURVEYOR_PORTAL.NEW_REQUEST.RANGE_NOT_IN_AREA')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (translation) => (this.rangeNotInAreaTranslation = translation),
      );
  }

  private fetchRangeCoverageValidationTranslation(): void {
    this.translateService
      .get('GK.MAP.PREVIOUS_RANGE_COVERAGE_VALIDATION')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => {
        this.rangeCoverageValidationTranslation = data;
      });
  }

  private fetchRangePartiallyNotInAreaTranslation(): void {
    this.translateService
      .get('SURVEYOR_PORTAL.NEW_REQUEST.RANGE_PARTIALLY_NOT_IN_AREA')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (translation) =>
          (this.rangePartiallyNotInAreaTranslation = translation),
      );
  }

  private fetchTxtFileNameTranslation(): void {
    this.translateService
      .get('GK.MAP.TXT_FILE_NAME')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((translation) => (this.txtFileNameTranslation = translation));
  }

  private fetchWktFileNameTranslation(): void {
    this.translateService
      .get('GK.MAP.WKT_FILE_NAME')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((translation) => (this.wktFileNameTranslation = translation));
  }

  private fetchSelectByIdentifiersTranslation(): void {
    this.translateService
      .get('GK.MAP.SELECT_BY_IDENTIFIERS_FROM_FILE')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((translation) => {
        this.selectByIdentifiersTranslation = translation;
        this.topGridToolbarItems = getMapRangePickerGridToolbarItems(this);
      });
  }

  private searchMapObjects(wkt?: string, ids?: string[]): void {
    this.mapRangePickerGridDataService.url = '/api/mapa/dzialki/search';
    this.mapRangePickerGridDataService.queryByState(
      LandParcelSearchFormModel.fromAppToApi({
        geom: wkt,
        identifiers: ids,
      }),
    );
  }

  private showMessageIfNoRangeSelected(): boolean {
    if (!this.getSelectedRanges().length) {
      this.toastr.warning('Proszę wybrać zakres');
      return false;
    }
    return true;
  }

  private showRangeCoverageValidationToastr(): void {
    this.toastr.warning(this.rangeCoverageValidationTranslation);
  }

  private checkSomeLandParcelGeomNotIntersectsRange(
    parcelsGeoms: string[],
    rangeGeom: string,
  ): boolean {
    return parcelsGeoms.some((parcelGeom) => {
      const parcelGeometry = ConversionUtils.getGeometryFromWkt(parcelGeom);
      const rangeGeometry = ConversionUtils.getGeometryFromWkt(rangeGeom);
      return !parcelGeometry.intersectsExtent(rangeGeometry.getExtent());
    });
  }

  private showNoParcelsNotification(): void {
    this.toastr.warning(
      'Brak działek - zakres można utworzyć tylko na podstawie działek',
    );
  }

  private getLandParcelsWkt(): Wkt[] {
    return this.gridDataResult.data
      .filter((item) => item.type === RangePickerFeatureType.LandParcel)
      .map((item) => item.geom);
  }

  private getRangesWktGeom(): Wkt[] {
    return this.gridDataResult.data
      .filter((item) => item.type === RangePickerFeatureType.Range)
      .map((item) => item.geom);
  }

  private getSelectedRanges(): MapRangePickerGridItem[] {
    const selectedItems =
      this.mapRangePickerGridDataService.$selection.value?.selectedItems;
    return selectedItems.filter(
      (item) => item.type === RangePickerFeatureType.Range,
    );
  }

  private fetchNoBufferValueNotificationText(): void {
    this.translateService
      .get('GK.MAP.ENTER_BUFFER')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((translation) => (this.noBufferTranslation = translation));
  }

  private fetchNoSingleRangeNotificationText(): void {
    this.translateService
      .get('GK.MAP.NO_RANGE')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((translation) => (this.noRangeTranslation = translation));
  }

  private getMapObjectTableState(): MapObjectTableState {
    return this.gkKendoGridMapService.$mapState.value.mapObjectTablesState[0];
  }

  private downloadWkt(): void {
    const geom = this.getRangesWktGeom()[0];
    this.saveFileByTemporaryLink(geom, this.wktFileNameTranslation);
  }

  private downloadTxt(): void {
    const geom = this.getRangesWktGeom()[0];
    this.polygonTopologyService
      .convertRangeToTxt(geom)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => {
        this.saveFileByTemporaryLink(data, this.txtFileNameTranslation);
      });
  }

  private saveFileByTemporaryLink(geom: string, fileName: string): void {
    const dataURI = `data:text/plain;base64,${btoa(geom)}`;
    const link = this.document.createElement('a');
    this.document.body.appendChild(link);
    link.href = dataURI;
    link.download = fileName;
    link.click();
    link.remove();
  }

  private subscribeToCountChanges(): void {
    this.mapRangePickerGridDataService.$count
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((count) => {
        this.columns[0].title = `Obiekty (${count})`;
      });
    this.fetchNoSingleRangeNotificationText();
    this.fetchNoBufferValueNotificationText();
  }

  private dispatch(action: MapAction): void {
    this.gkKendoGridMapService.$pendingMapActions.next([action]);
  }

  private getNewMapActionWithMapObjectTableStateIndex(
    type: any,
    payload?: any,
  ): MapAction {
    return new MapAction(type, payload, 0);
  }
}
