import { LandParcelSearchFormComponent } from './land-parcel-search-form.component';
import { GridActionButtonsConfig } from '../../../gk-kendo-grid-action-buttons/gk-kendo-action-buttons-model';
import { LandParcelSearchFormFieldGroupFormModel } from '../../form-field-groups/land-parcel-search-form-field-group';
import { State } from '@progress/kendo-data-query/dist/npm/state';
import { AddressSearchFormFieldGroupFormModel } from '../../form-field-groups/address-search-form-field-group';
import { ApiFilter } from '../../../../gk-dynamic-list/services/services.model';
import { FilterOperatorType } from '../../../../gk-dynamic-list/gk-dynamic-list.model';
import { LandAndMortgageRegisterFormFieldGroupModel } from '../../form-field-groups/land-and-mortgage-register-form-field-group';
import {
  iwIconCheckmark,
  iwIconCross,
} from '../../../../assets/css/ewid-svg/svg-icons';

export enum LandParcelSearchFormControlName {
  ExampleControlName = 'exampleControlName',
}

interface LandParcelSearchFormState extends State {
  returnGeom: boolean;
}

export class LandParcelSearchFormModel {
  constructor(
    public landParcelSearch?: LandParcelSearchFormFieldGroupFormModel,
    public addressSearch?: AddressSearchFormFieldGroupFormModel,
    public landAndMortgageRegisterSearch?: LandAndMortgageRegisterFormFieldGroupModel,
    public geom?: string,
    public identifiers?: string[],
  ) {}

  static fromAppToApi(
    model: LandParcelSearchFormModel,
  ): LandParcelSearchFormState {
    return {
      returnGeom: true,
      take: 100,
      filter: {
        filters: [
          AddressSearchFormFieldGroupFormModel.getApiFilter(
            model.addressSearch,
          ),
          ...LandParcelSearchFormFieldGroupFormModel.getApiFilters(
            model.landParcelSearch,
          ),
          LandAndMortgageRegisterFormFieldGroupModel.getApiFilter(
            model.landAndMortgageRegisterSearch,
          ),
          new ApiFilter('Geom', model?.geom, FilterOperatorType.Intersects),
          new ApiFilter(
            'Identyfikator',
            model?.identifiers,
            FilterOperatorType.InList,
          ),
        ].filter((element) => element.value),
        logic: 'and',
      },
    };
  }
}

export const getLandParcelSearchFormModel = (): LandParcelSearchFormModel =>
  new LandParcelSearchFormModel(null, null, null, null);

export const landParcelSearchFormActionButtonsConfig: GridActionButtonsConfig =
  {
    actionsLayout: 'end',
    buttons: [
      {
        text: 'Wyczyść filtr',
        iconClass: 'fa fa-eraser',
        click: () => LandParcelSearchFormComponent.prototype.clearForm,
      },
      {
        text: 'Zastosuj filtr',
        type: 'submit',
        svgIcon: iwIconCheckmark,
        className: 'svg-icon-color-info',
      },
      {
        text: 'Anuluj',
        svgIcon: iwIconCross,
        className: 'svg-icon-red',
        click: () => LandParcelSearchFormComponent.prototype.onCancel,
      },
    ],
  };
