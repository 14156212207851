import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientSgkGuard } from '../../guards/client-sgk/client-sgk.guard';
import { MainRoutes } from '../../guards/guard.models';
import { RequestInProgressGuard } from '../../guards/request-in-progress/request-in-progress.guard';
import { SessionGuard } from '../../guards/session/session.guard';
import { MainRequestsListComponent } from '../../shared/main-requests-list/main-requests-list.component';
import { GACSDRNewRequestComponent } from './new-request/new-request.component';

const geodeticAndCartographicServiceDataRequestRoutes: Routes = [
  {
    path: MainRoutes.GeodeticAndCartographicServiceDataRequest,
    canActivate: [SessionGuard, ClientSgkGuard],
    children: [
      {
        path: '',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
      {
        path: 'new-request',
        component: GACSDRNewRequestComponent,
        canDeactivate: [RequestInProgressGuard],
      },
      {
        path: 'requests-list',
        component: MainRequestsListComponent,
      },
      {
        path: '**',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(geodeticAndCartographicServiceDataRequestRoutes),
  ],
  providers: [SessionGuard, RequestInProgressGuard, ClientSgkGuard],
  exports: [RouterModule],
})
export class GeodeticAndCartographicServiceDataRequestRoutingModule {}
