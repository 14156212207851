import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  Enablement,
  EnablementType,
  LawPersonSearch,
  LegalPersonControlName,
  LegalPersonSearch,
  NaturalPersonSearch,
} from '@gk/gk-modules';
import * as _ from 'lodash';
import { AttachmentEvent } from '../../services/designer-incoming-documents/designer-incoming-documents.model';
import { StringifiedDocFile } from '../../services/request-workspace-state/request-workspace-state.model';
import { fileToBase64 } from '../../utils/files/files.util';

@Component({
  selector: 'app-enablement',
  templateUrl: './enablement.component.html',
})
export class EnablementComponent implements OnInit {
  enablementTypeEnum = EnablementType;
  chosenLawPersons: LawPersonSearch[] = [];
  enablementDocumentFile: File;
  documentConvertLoading: boolean;
  base64Document: string;
  stringifiedEnablementDocumentFile: StringifiedDocFile;
  legalPersonControlName = LegalPersonControlName;
  @Input() staticSelectedTableValue: LawPersonSearch[] = [];
  @Input() enablement = Enablement.getInitialStruct();
  @Input() submitted: boolean;
  @Input() allowedNoEnablement = false;
  @Input() hideLackEnablement = false;
  @Input() addingPersonsDirectlyToDb = true;
  @Input() legalPersonOnly: boolean;
  @Input() primitivePostalCode: boolean;
  @Input() searchLegalPersonCustomUrl: string;
  @Output() enablementFormChange = new EventEmitter<Enablement>();

  ngOnInit(): void {
    this.emitEnablementChange();
  }

  isEnablementTypeChosen(selectedType: EnablementType): boolean {
    return this.enablement.type === selectedType;
  }

  isFormValid(): boolean {
    switch (this.enablement.type) {
      case EnablementType.Lack:
        return this.allowedNoEnablement;
      case EnablementType.Statutory:
        return this.isChosenPersonValid();
      case EnablementType.Written:
        return (
          this.isWrittenEnablementDocumentValid() && this.isChosenPersonValid()
        );
    }
  }

  isWrittenEnablementDocumentValid(): boolean {
    const isWrittenEnablement = this.isWrittenEnablement();
    return (
      !isWrittenEnablement ||
      (isWrittenEnablement &&
        !!this.base64Document &&
        !this.documentConvertLoading)
    );
  }

  isChosenPersonValid(): boolean {
    return this.isLackEnablement() || this.chosenLawPersons.length > 0;
  }

  isLackEnablement(): boolean {
    return Enablement.isLack(this.enablement.type);
  }

  isWrittenEnablement(): boolean {
    return Enablement.isWritten(this.enablement.type);
  }

  isStatutoryEnablement(): boolean {
    return Enablement.isStatutory(this.enablement.type);
  }

  handleEnablementDocumentInput(attachmentEvent: AttachmentEvent): void {
    this.enablementDocumentFile = attachmentEvent.payload;
    const file = attachmentEvent.payload;
    if (file) {
      this.documentConvertLoading = true;
      fileToBase64(file).then((base64) => {
        _.set(this.enablement, 'documentName', file.name);
        this.base64Document = base64;
        this.documentConvertLoading = false;
        this.emitEnablementChange();
      });
    } else {
      this.base64Document = undefined;
      _.set(this.enablement, 'documentName', undefined);
      this.emitEnablementChange();
    }
  }

  setValues(
    principal: LawPersonSearch,
    enablementType: EnablementType,
    principalSetByUser = true
  ): void {
    this.enablement.type = enablementType;
    this.chosenLawPersons = [principal];
    this.emitEnablementChange(principalSetByUser);
  }

  emitEnablementChange(principalSetByUser = true): void {
    this.enablementFormChange.emit(this.getForm(principalSetByUser));
  }

  getForm(principalSetByUser = true): Enablement {
    return {
      type: this.enablement.type,
      documentName: this.enablement.documentName,
      document: this.base64Document,
      principal: this.chosenLawPersons[0] as
        | NaturalPersonSearch
        | LegalPersonSearch,
      valid: this.isFormValid(),
      principalSetByUser,
    };
  }
}
