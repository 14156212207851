import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { combineLatestWith, map } from 'rxjs/operators';
import {
  AdministrativeUnitReference,
  MapAction,
  MapSettings,
  MapSettingsFromApi,
  PaperFromApi,
} from '../../gk-map/models';
import { ApiResponse } from '../services.model';
import { MapPortalName } from './map-settings.model';

@Injectable()
export class MapSettingsService {
  pendingMapActions = new BehaviorSubject<MapAction[]>([]);
  constructor(private http: HttpClient) {}

  getMapSettings(mapPortalName: MapPortalName): Observable<MapSettings> {
    return this.http
      .get<MapSettingsFromApi>(`/map/${mapPortalName}/settings`)
      .pipe(
        combineLatestWith(this.getPaperSizes()),
        map(([mapSettingsResponse, papers]) =>
          MapSettings.fromApiToApp({ ...mapSettingsResponse, papers })
        )
      );
  }

  getPaperSizes(): Observable<PaperFromApi[]> {
    return this.http
      .get<ApiResponse<PaperFromApi[]>>(`/api/interesant/slo/druk/zakres`)
      .pipe(map((data) => data.Response));
  }

  getMockedMapSettings(): Observable<MapSettings> {
    const applicantMapSettings = {
      administrativeUnitReference: {
        lowX: 7528463.86,
        highX: 7540846.32,
        lowY: 5505901.46,
        highY: 5519500.98,
        epsg: 2178,
      } as AdministrativeUnitReference,
    } as MapSettings;
    return of(applicantMapSettings);
  }
}
