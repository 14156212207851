export interface StreetFromApi {
  Id: number;
  Nazwa: string;
}

export class Street {
  constructor(public id: number | string, public name: string) {}

  static fromApiToApp(streetFromApi: StreetFromApi): Street {
    return new this(streetFromApi.Id, streetFromApi.Nazwa);
  }
}
