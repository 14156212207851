<div class="dynamic-field" [formGroup]="group">
  <label
    for="communitySelect"
    class="highlighted-label"
    (click)="toggleCommunityView()"
    [ngbTooltip]="communitySelectTipContent"
    >{{ config.label | translate }}</label
  >
  <select
    id="communitySelect"
    class="form-select"
    [formControlName]="config.name"
    (change)="changeCommunity()"
    [ngClass]="{ error: isErrorDetected() }"
  >
    @if (config.allValuesEnabled) {
      <option value="">
        {{ 'GK.DYNAMIC_FORM.ALL' | translate }}
      </option>
    }
    @for (community of communities; track community) {
      @if (communityGusFirst) {
        <option [ngValue]="community.id">
          [{{ community.gus }}] {{ community.name }}
        </option>
      }
      @if (!communityGusFirst) {
        <option [ngValue]="community.id">
          {{ community.name }} [{{ community.gus }}]
        </option>
      }
    }
  </select>
</div>

<ng-template #communitySelectTipContent
  ><p [innerHTML]="'GK.MAP.SELECT_COMMUNITY_TIP_CONTENT' | translate"></p
></ng-template>
