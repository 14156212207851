<div class="dynamic-field" [formGroup]="group">
  <label for="sectionSelect">{{ config.label | translate }}</label>
  <select
    id="sectionSelect"
    class="form-select"
    [formControlName]="config.name"
    [ngClass]="{ error: isErrorDetected() }"
    >
    @if (config.allValuesEnabled) {
      <option value="">
        {{ 'GK.DYNAMIC_FORM.ALL' | translate }}
      </option>
    }
    @for (section of sections; track section) {
      <option [value]="section.name">
        {{ section.name }}
      </option>
    }
  </select>
</div>
