import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GuestSessionGuard } from '../../guards/guest-session/guest-session.guard';
import { EcoDocumentRouteParams } from '../../services/eco-document/eco-document.model';
import { EcoDocumentDetailsComponent } from './eco-document-details/eco-document-details.component';
import { EcoNewsletterComponent } from './eco-newsletter/eco-newsletter.component';
import { EcoPortalComponent } from './eco-portal.component';
import { SearchEcoDocumentComponent } from './search-eco-document/search-eco-document.component';

const ecoPortalRoutes: Routes = [
  {
    path: 'eco-portal',
    component: EcoPortalComponent,
    canActivate: [GuestSessionGuard],
    children: [
      {
        path: '',
        redirectTo: 'search-document',
        pathMatch: 'full',
      },
      {
        path: 'search-document',
        component: SearchEcoDocumentComponent,
      },
      {
        path: `document-details/:${EcoDocumentRouteParams.Id}`,
        component: EcoDocumentDetailsComponent,
      },
      {
        path: 'newsletter',
        component: EcoNewsletterComponent,
      },
      {
        path: '**',
        redirectTo: 'search-document',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(ecoPortalRoutes)],
  providers: [GuestSessionGuard],
  exports: [RouterModule],
})
export class EcoPortalRoutingModule {}
