import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { takeWhile } from 'rxjs/operators';
import { CancelRequestControlConfig } from '../../../gk-dynamic-list.model';
import { DataService } from '../../../services/data/data.service';
import { DynamicListService } from '../../../services/dynamic-list/dynamic-list.service';
import { RequestService } from '../../../services/request/request.service';
import { Control } from '../control';

@Component({
  selector: 'gk-control-cancel-request',
  templateUrl: './control-cancel-request.component.html',
})
export class ControlCancelRequestComponent
  extends Control<null | number>
  implements OnInit
{
  @Input()
  override controlConfig: CancelRequestControlConfig;
  loading = false;
  errorMsg = '';
  succeeded = false;
  translations: { [key: string]: string } = {};

  constructor(
    private ngbModal: NgbModal,
    protected override translateService: TranslateService,
    protected requestService: RequestService,
    protected override dataService: DataService,
    private dynamicListService: DynamicListService,
  ) {
    super(translateService, dataService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.subscribeToControlCancelRequestTranslations();
  }

  subscribeToControlCancelRequestTranslations(): void {
    this.translateService
      .get('GK.DYNAMIC_LIST.CONTROL_CANCEL_REQUEST')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((translations) => (this.translations = translations));
  }

  canCancel(): boolean {
    const status = _.get(this.data, this.controlConfig.pathToStatus);

    if (!status) {
      return false;
    }

    if (!this.controlConfig.statusesRequiredToEnableCancel) {
      return false;
    }

    return this.controlConfig.statusesRequiredToEnableCancel.includes(status);
  }

  tryCancel(modalTemplate: TemplateRef<NgbModalRef>): void {
    this.ngbModal
      .open(modalTemplate)
      .result.then(() => {
        this.cancel();
      })
      .catch((err) => console.error(err));
  }

  override shouldShowControl(): boolean {
    return super.shouldShowControl() && this.canCancel();
  }

  cancel(): void {
    this.loading = true;

    const url = this.controlConfig.requestConfig.evaluateUrl
      ? (eval(this.controlConfig.requestConfig.url) as string)
      : this.controlConfig.requestConfig.url;

    this.requestService
      .cancelRequest(url, this.getValue())
      .pipe(takeWhile(() => this.isAlive))
      .subscribe({
        next: () => this.handleSuccessResponse(),
        error: (errorResponse: HttpErrorResponse) =>
          this.handleErrorResponse(errorResponse),
      });
  }

  handleSuccessResponse(): void {
    this.succeeded = true;
    this.unsetLoading();
    this.dynamicListService.handlePanelReload();
  }

  handleErrorResponse(errorResponse: HttpErrorResponse): void {
    this.errorMsg = _.get(
      errorResponse,
      'error.ResponseStatus.Message',
      this.translations['CANCEL_FAILED'],
    );
    this.unsetLoading();
  }

  unsetLoading(): void {
    this.loading = false;
  }
}
