@if (shouldShowControl()) {
  <div gkControlRow>
    <span gkControlLabel [showLabel]="controlConfig.showLabel">
      <gk-translated-label [configLabel]="controlConfig.label"></gk-translated-label>:</span>
      <span gkControlValue [showLabel]="controlConfig.showLabel">
        @switch (getValue()) {
          @case (true) {
            <span>{{'GK.DYNAMIC_LIST.YES' | translate}}</span>
          }
          @case (false) {
            <span>{{'GK.DYNAMIC_LIST.NO' | translate}}</span>
          }
          @default {
            <span>&#45;</span>
          }
        }
      </span>
    </div>
  }
