import { FormControl } from '@angular/forms';

export enum ECSControlName {
  ElectronicCorrespondenceStatement = 'electronicCorrespondenceStatement',
}

export enum ElectronicCorrespondenceStatement {
  Consent = 'electronicCorrespondenceStatementConsent',
  NotConsent = 'electronicCorrespondenceStatementNotConsent',
}

export interface ECSFormGroup {
  [ECSControlName.ElectronicCorrespondenceStatement]: FormControl<boolean>;
}
