import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map } from 'rxjs';
import { handleApiError } from '../services.utils';
import { allSelectOption } from './community.config';
import {
  Community,
  District,
  ResponseCommunityFromApi,
  SelectOption,
  Sheet,
} from './community.model';

@Injectable()
export class CommunityService {
  communities = new BehaviorSubject<Community[]>([]);

  constructor(private httpClient: HttpClient) {
    this.fetchCommunitiesWithDistricts();
  }

  static getCommunitiesSelectOptions(communities: Community[]): SelectOption[] {
    return [
      allSelectOption,
      ...communities.map(
        (community) => new SelectOption(community.id, community.name)
      ),
    ];
  }

  static getAllDistricts(communities: Community[]): District[] {
    return communities.reduce(
      (districts, nextCommunity) => [...districts, ...nextCommunity.districts],
      [] as District[]
    );
  }

  static getDistrictsSelectOptions(
    communities: Community[],
    communityId?: number
  ): SelectOption[] {
    if (communityId) {
      return communities
        .find((com) => com.id === communityId)
        .districts.map(
          (district) => new SelectOption(district.id, district.name)
        );
    }

    return [
      allSelectOption,
      ...this.getAllDistricts(communities).map(
        (dstr) => new SelectOption(dstr.id, dstr.name)
      ),
    ];
  }

  static getAllSheets(districts: District[]): Sheet[] {
    return districts.reduce(
      (sheets, nextDistrict) => [...sheets, ...nextDistrict.sheets],
      [] as Sheet[]
    );
  }

  static getSheets(communities: Community[], districtId?: number): Sheet[] {
    const allDistricts = this.getAllDistricts(communities);

    return districtId
      ? allDistricts.find((dstr) => dstr.id === districtId).sheets
      : this.getAllSheets(allDistricts);
  }

  private fetchCommunitiesWithDistricts(): void {
    this.httpClient
      .get<ResponseCommunityFromApi>('/api/interesant/slo/gminy')
      .pipe(
        map((data) => data.Response),
        map((communitiesFromApi) =>
          communitiesFromApi.map((communityFromApi) =>
            Community.fromApiToApp(communityFromApi)
          )
        ),
        catchError(handleApiError)
      )
      .subscribe((data: Community[]) => this.communities.next(data));
  }
}
