import { Component, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { FormBaseComponent } from '../../../form-base/form-base.component';
import { CommunitySelectFieldConfig } from '../../../gk-dynamic-form.model';
import { Community, District } from '../../../services/cached/cached.model';
import { CachedService } from '../../../services/cached/cached.service';

@Component({
  selector: 'gk-form-community-select',
  templateUrl: './community-select.component.html',
})
export class CommunitySelectComponent
  extends FormBaseComponent
  implements OnInit, OnDestroy
{
  private isAlive = true;
  override config: CommunitySelectFieldConfig;
  selectedCommunityId: number;
  districtsForCommunity: District[] = [];
  allDistricts: District[] = [];
  communities: Community[] = [];
  communityGusFirst = false;

  constructor(private cachedService: CachedService) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToCommunitiesWithDistrictsData();
  }

  subscribeToCommunitiesWithDistrictsData(): void {
    combineLatest([
      this.cachedService.communitiesWithDistricts,
      this.cachedService.allDistricts,
    ])
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(([data, allDistricts]) => {
        this.allDistricts = allDistricts;
        this.setCommunitiesAndDistricts(data);
        this.patchDefaultValue();
      });
  }

  setCommunitiesAndDistricts(communities: Community[]): void {
    this.communities = communities;
    if (this.communities.length && this.config.independentDistrictSearch) {
      this.districtsForCommunity = this.allDistricts;
    }
    this.dispatchNewDistrictsForCommunity();
  }

  toggleCommunityView(): void {
    this.communityGusFirst = !this.communityGusFirst;
  }

  patchDefaultValue(): void {
    if (this.config.allValuesEnabled) {
      this.patchEmptyValue();
      this.cachedService.allCommunitiesSelected.next(true);
      return;
    }

    if (this.communities.length === 1) {
      this.selectedCommunityId = _.first(this.communities).id;
      this.group.patchValue({ [this.config.name]: this.selectedCommunityId });
      this.populateDistrictsForCommunity(this.selectedCommunityId);
      this.cachedService.allCommunitiesSelected.next(false);
    }
  }

  changeCommunity(): void {
    const communityId = this.group.get(this.config.name).value;
    if (!communityId) {
      this.districtsForCommunity = this.allDistricts;
      this.dispatchNewDistrictsForCommunity();
      this.cachedService.allCommunitiesSelected.next(true);
      return;
    }

    this.selectedCommunityId = parseInt(communityId, 10);
    this.populateDistrictsForCommunity(this.selectedCommunityId);
    this.cachedService.allCommunitiesSelected.next(false);
  }

  populateDistrictsForCommunity(selectedCommunityId: number): void {
    this.districtsForCommunity = this.communities.find(
      (community) => community.id === selectedCommunityId,
    ).districts;
    this.dispatchNewDistrictsForCommunity();
  }

  dispatchNewDistrictsForCommunity(): void {
    this.cachedService.districtsForCommunity.next(this.districtsForCommunity);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
