@if (legalPersonEnabled) {
  <div class="ps-3">
    <gk-law-person-type-form
      [idPrefix]="idPrefix"
      [form]="investorDetailsFromGroup"
    ></gk-law-person-type-form>
  </div>
}

@if (isNaturalPersonChosen()) {
  <gk-natural-person-form
    [isRequired]="true"
    [form]="getNaturalPersonFormGroup()"
    [forDesignerPersonType]="false"
    [addingPersonsDirectlyToDb]="true"
    [idPrefix]="idPrefix"
    [isPeselVisible]="isPeselVisible"
  ></gk-natural-person-form>
}

@if (legalPersonEnabled && isLegalPersonChosen()) {
  <gk-legal-person-form
    [newPersonMode]="true"
    [isRequired]="true"
    [form]="getLegalPersonFormGroup()"
    [idPrefix]="idPrefix"
  ></gk-legal-person-form>
}

<form [formGroup]="investorDetailsFromGroup">
  <div class="row">
    @if (isNaturalPersonChosen() && isIdVisible) {
      <div
        class="col-md col-sm-6 col-xs-12 my-1"
        >
        <label
          class="text-nowrap"
          [attr.for]="idPrefix + investorDetailsControlName.IdNumber"
          >{{
          'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.INVESTOR_DETAILS.FIELDS.ID_NUMBER'
          | translate
          }}</label
          >
          <input
            type="text"
            [name]="idPrefix + investorDetailsControlName.IdNumber"
            [id]="idPrefix + investorDetailsControlName.IdNumber"
            class="form-control form-control-sm"
            [formControlName]="investorDetailsControlName.IdNumber"
            />
        </div>
      }
      @if (isPhoneNumberVisible) {
        <div class="col-md col-sm-6 col-xs-12 my-1">
          <label
            class="text-nowrap"
            [attr.for]="idPrefix + investorDetailsControlName.PhoneNumber"
            >{{
            'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.INVESTOR_DETAILS.FIELDS.PHONE_NUMBER'
            | translate
            }}</label
            >
            <input
              type="tel"
              [name]="idPrefix + investorDetailsControlName.PhoneNumber"
              [id]="idPrefix + investorDetailsControlName.PhoneNumber"
              class="form-control form-control-sm"
              [ngClass]="{ required: isPhoneNumberRequired }"
              [attr.aria-required]="isPhoneNumberRequired"
              [formControlName]="investorDetailsControlName.PhoneNumber"
              />
            @if (
              shouldShowErrorAlert(this.getPhoneNumberFormControl(), 'required')
              ) {
              <div
                class="alert alert-danger"
                tabindex="0"
                >
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
            @if (
              shouldShowErrorAlert(this.getPhoneNumberFormControl(), 'phoneInvalid')
              ) {
              <div
                class="alert alert-danger"
                tabindex="0"
                >
                {{ 'FIELD_FORMAT_WRONG' | translate }}
              </div>
            }
          </div>
        }
        @if (isEmailVisible) {
          <div class="col-md col-sm-6 col-xs-12 my-1">
            <label
              class="text-nowrap"
              [attr.for]="idPrefix + investorDetailsControlName.Email"
              >{{
              'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.INVESTOR_DETAILS.FIELDS.E_MAIL'
              | translate
              }}</label
              >
              <input
                type="text"
                [name]="idPrefix + investorDetailsControlName.Email"
                [id]="idPrefix + investorDetailsControlName.Email"
                class="form-control form-control-sm required"
                aria-required="true"
                [formControlName]="investorDetailsControlName.Email"
                />
              @if (shouldShowErrorAlert(this.getEmailFormControl(), 'required')) {
                <div
                  class="alert alert-danger"
                  tabindex="0"
                  >
                  {{ 'FIELD_REQUIRED' | translate }}
                </div>
              }
              @if (shouldShowErrorAlert(this.getEmailFormControl(), 'emailInvalid')) {
                <div
                  class="alert alert-danger"
                  tabindex="0"
                  >
                  {{ 'FIELD_FORMAT_WRONG' | translate }}
                </div>
              }
            </div>
          }
        </div>
      </form>

      <gk-address-form
        [isRequired]="true"
        [form]="getAddressFormGroup()"
        [idPrefix]="idPrefix"
        [primitivePostalCode]="primitivePostalCode"
        [wasFormValidated]="wasFormValidated"
      ></gk-address-form>

      <gk-post-office-modal
        [addressFormGroup]="getAddressFormGroup()"
        [idPrefix]="idPrefix"
        >
      </gk-post-office-modal>
