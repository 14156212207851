export enum AgriculturalLandUseDocTypeId {
  StampDuty = 10040,
  PowerOfAttorney = 10035,
  DecisionOfBuildingConditions = 10045,
  ExcerptAndExcerptFromTheSpatialDevelopmentPlan = 10046,
  DecisionOfBuildingConditionsOrExcerptAndExcerptFromTheSpatialDevelopmentPlan = 10046,
  Other = 8,
  LandParcelDevelopmentProject = 10047,
  LegalTitleToRealEstate = 10039
}

export enum AgriculturalLandUseDocTypeLabelKey {
  StampDuty = 'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.AGRICULTURAL_LAND_USE_REQUEST.DOC_TYPE_DESCRIPTION.STAMP_DUTY',
  PowerOfAttorney = 'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.AGRICULTURAL_LAND_USE_REQUEST.DOC_TYPE_DESCRIPTION.POWER_OF_ATTORNEY',
  DecisionOfBuildingConditions = 'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.AGRICULTURAL_LAND_USE_REQUEST.DOC_TYPE_DESCRIPTION.DECISION_OF_BUILDING_CONDITIONS',
  DecisionOfBuildingConditionsOrExcerptAndExcerptFromTheSpatialDevelopmentPlan = 'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.AGRICULTURAL_LAND_USE_REQUEST.DOC_TYPE_DESCRIPTION.DECISION_OF_BUILDING_CONDITIONS_OR_EXCERPT_AND_EXCERPT_FROM_THE_SPATIAL_DEVELOPMENT_PLAN',
  ExcerptAndExcerptFromTheSpatialDevelopmentPlan = 'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.AGRICULTURAL_LAND_USE_REQUEST.DOC_TYPE_DESCRIPTION.EXCERPT_AND_EXCERPT_FROM_THE_SPATIAL_DEVELOPMENT_PLAN',
  Other = 'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.AGRICULTURAL_LAND_USE_REQUEST.DOC_TYPE_DESCRIPTION.OTHER',
  LandParcelDevelopmentProject = 'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.AGRICULTURAL_LAND_USE_REQUEST.DOC_TYPE_DESCRIPTION.LAND_PARCEL_DEVELOPMENT_PROJECT',
  LegalTitleToRealEstate = 'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.AGRICULTURAL_LAND_USE_REQUEST.DOC_TYPE_DESCRIPTION.LEGAL_TITLE_TO_REAL_ESTATE'
}

export function getAgriculturalLandUseDocTypLabelKeyById(id: number): string {
  switch (id) {
    case AgriculturalLandUseDocTypeId.StampDuty: {
      return AgriculturalLandUseDocTypeLabelKey.StampDuty;
    }
    case AgriculturalLandUseDocTypeId.PowerOfAttorney: {
      return AgriculturalLandUseDocTypeLabelKey.PowerOfAttorney;
    }
    case AgriculturalLandUseDocTypeId.DecisionOfBuildingConditionsOrExcerptAndExcerptFromTheSpatialDevelopmentPlan: {
      return AgriculturalLandUseDocTypeLabelKey.DecisionOfBuildingConditionsOrExcerptAndExcerptFromTheSpatialDevelopmentPlan;
    }
    case AgriculturalLandUseDocTypeId.Other: {
      return AgriculturalLandUseDocTypeLabelKey.Other;
    }
    case AgriculturalLandUseDocTypeId.LandParcelDevelopmentProject: {
      return AgriculturalLandUseDocTypeLabelKey.LandParcelDevelopmentProject;
    }
    case AgriculturalLandUseDocTypeId.LegalTitleToRealEstate: {
      return AgriculturalLandUseDocTypeLabelKey.LegalTitleToRealEstate;
    }
    default: {
      return '';
    }
  }
}
