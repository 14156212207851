import { ColorSet, ColorSetFromApi } from '../colors/color-set.model';

export interface DefaultConfigGuestFromApi extends ColorSetFromApi {
  IsAdEnabled: boolean;
  IsPzConfigured: boolean;
  IsLoginGovConfigured: boolean;
  FileSizeWhenDownloadAsSingleFile: number;
  GoogleAnalyticsIdentyfikator: string;
  WebDeklaracjaDostepnosci: string;
  WebDeklaracjaDostepnosciAplikacjaMobilna: string;
  WebDeklaracjaDostepnosciPortalInteresanta: string;
  WebHeaderLogo: string;
  WebFooterLogo: string;
  Is2FaRequired: boolean;
  WebKey: string;
  WersjaEwida: number;
}

export class DefaultConfigGuest extends ColorSet {
  constructor(
    public authenticateByDomainEnabled: boolean,
    public isPzConfigured: boolean,
    public isLoginGovConfigured: boolean,
    public fileSizeWhenDownloadAsSingleFile: number,
    public googleAnalyticsId: string,
    public webAccessibilityStatement: string,
    public webMobileAccessibilityStatement: string,
    public webClientAccessibilityStatement: string,
    public webHeaderLogo: string,
    public webFooterLogo: string,
    public isTfaRequired: boolean,
    public webKey: string,
    public ewidVersion: number,
    public override main: string,
    public override mainBorder: string,
    public override mainFont: string,
    public override hover: string,
    public override hoverBorder: string,
    public override hoverFont: string,
    public override active: string,
    public override activeBorder: string,
    public override activeFont: string
  ) {
    super(
      main,
      mainBorder,
      mainFont,
      hover,
      hoverBorder,
      hoverFont,
      active,
      activeBorder,
      activeFont
    );
  }

  static fromApiToApp(data: DefaultConfigGuestFromApi): DefaultConfigGuest {
    return new this(
      data.IsAdEnabled,
      data.IsPzConfigured,
      data.IsLoginGovConfigured,
      data.FileSizeWhenDownloadAsSingleFile,
      data.GoogleAnalyticsIdentyfikator,
      data.WebDeklaracjaDostepnosci,
      data.WebDeklaracjaDostepnosciAplikacjaMobilna,
      data.WebDeklaracjaDostepnosciPortalInteresanta,
      data.WebHeaderLogo,
      data.WebFooterLogo,
      data.Is2FaRequired,
      data.WebKey,
      data.WersjaEwida,
      data.MainBarColor,
      data.MainBarBorderColor,
      data.MainBarFontColor,
      data.HoverColor,
      data.HoverBorderColor,
      data.HoverFontColor,
      data.ActiveHoverColor,
      data.ActiveHoverBorderColor,
      data.ActiveHoverFontColor
    );
  }
}
