import { FormControl } from '@angular/forms';
import { LawPerson } from '@gk/gk-modules';
import { DecisionFormType } from '../../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';

export enum ATIOEAIPNewRequestControlName {
  LawPerson = 'lawPerson',
  DecisionForm = 'decisionForm',
  ShippingForm = 'shippingForm',
  DetailedScope = 'detailedScope',
  AdditionalIndications = 'additionalIndications',
}

export interface ATIOEAIPNewRequestFormGroup {
  [ATIOEAIPNewRequestControlName.LawPerson]: FormControl<LawPerson>;
  [ATIOEAIPNewRequestControlName.DecisionForm]: FormControl<DecisionFormType>;
  [ATIOEAIPNewRequestControlName.ShippingForm]: FormControl<Shipping>;
  [ATIOEAIPNewRequestControlName.DetailedScope]: FormControl<string>;
  [ATIOEAIPNewRequestControlName.AdditionalIndications]: FormControl<string>;
}
