import { Pipe, PipeTransform } from '@angular/core';
import { DateHelperUtil } from '../../gk-dynamic-list/utils/date-helper.util';

@Pipe({
  name: 'dateWithoutTime',
})
export class DateWithoutTime implements PipeTransform {
  transform(date: string | undefined): string {
    return DateHelperUtil.isApiDateTimeFormat(date) ? date.split('T')[0] : date;
  }
}
