import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { debounceTime, distinctUntilChanged, takeWhile } from 'rxjs/operators';
import { FormBaseComponent } from '../../form-base/form-base.component';
import {
  CheckboxFieldConfig,
  CheckboxGroupRepresentativeFieldConfig,
  CheckboxState,
  FieldConfig,
  FieldConfigType,
} from '../../gk-dynamic-form.model';

@Component({
  selector: 'gk-form-checkbox-group-representative',
  templateUrl: './form-checkbox-group-representative.component.html',
})
export class FormCheckboxGroupRepresentativeComponent
  extends FormBaseComponent
  implements OnInit, OnDestroy
{
  private isAlive = true;
  @Input()
  fullFormConfig: FieldConfig[];
  override config: CheckboxGroupRepresentativeFieldConfig;
  checkboxStates: CheckboxState[];
  checkboxesBelongThisGroup: CheckboxFieldConfig[];

  ngOnInit(): void {
    this.findAndSaveCheckboxesBelongThisGroup();
    this.subscribeToFormChanges();
  }

  findAndSaveCheckboxesBelongThisGroup(): void {
    this.checkboxesBelongThisGroup = this.fullFormConfig.filter(
      (config) =>
        config.type === FieldConfigType.Checkbox &&
        (config as CheckboxFieldConfig).checkboxGroupName === this.config.name
    ) as CheckboxFieldConfig[];
  }

  subscribeToFormChanges(): void {
    this.group.valueChanges
      .pipe(
        takeWhile(() => this.isAlive),
        distinctUntilChanged(),
        debounceTime(300)
      )
      .subscribe(() => {
        this.handleFormValueChange();
      });
  }

  handleFormValueChange(): void {
    const newCheckboxesStates = this.getCheckboxesStates();
    const newValue = this.getNewValueForRepresentative(newCheckboxesStates);
    if (newValue !== this.getControl().value) {
      this.setValueForRepresentative(newValue);
    }
  }

  getNewValueForRepresentative(checkboxStates: CheckboxState[]): boolean {
    return checkboxStates.some((state) => state.value);
  }

  setValueForRepresentative(value: boolean): void {
    this.group.get(this.config.name).setValue(value);
  }

  getCheckboxesStates(): CheckboxState[] {
    return this.checkboxesBelongThisGroup.map((checkboxConfig) => {
      const configName = checkboxConfig.name;
      const value = this.group.get(configName).value;

      return new CheckboxState(configName, value);
    });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
