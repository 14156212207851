<a
  class="btn dropdown-toggle dropdown-toggle-size"
  [ngClass]="getButtonClass()"
  href="#"
  [id]="dropdownNavItemConfig.navItemId"
  data-bs-toggle="dropdown"
  aria-haspopup="true"
  aria-expanded="false"
  [title]="dropdownNavItemConfig.dropdownTitle | translate"
  >{{ dropdownNavItemConfig.dropdownLabel | translate }}
  <span class="float-start m-1" [ngClass]="getIconClassSet()"></span>
  @if (dropdownNavItemConfig.showSelectedImage) {
    <span>
      <img
        class="nav-dropdown-item-icon mb-1"
        [src]="getCustomIconSrc() | async"
        alt="selected-language-img"
      />
    </span>
  }
</a>
<div
  class="dropdown-menu"
  [ngClass]="getModifiers()"
  attr.aria-labelledby="{{ dropdownNavItemConfig.navItemId }}"
>
  @for (
    dropdownItemConfig of dropdownNavItemConfig.dropdownItemConfigs;
    track dropdownItemConfig
  ) {
    <gk-nav-item-renderer
      [navbarConfig]="navbarConfig"
      [navItemConfig]="dropdownItemConfig"
      [dropdownConfig]="dropdownNavItemConfig"
    ></gk-nav-item-renderer>
  }
</div>
