import { GkKendoGeomFileSelectComponent } from './gk-kendo-geom-file-select.component';
import { uploadIcon } from '@progress/kendo-svg-icons';
import { GridActionButtonsConfig } from '../../gk-kendo-grid-action-buttons/gk-kendo-action-buttons-model';

export const getGkKendoGeomFileSelectActionButtonConfig: (
  c: GkKendoGeomFileSelectComponent,
) => GridActionButtonsConfig = (c: GkKendoGeomFileSelectComponent) => {
  return {
    actionsLayout: 'end',
    buttons: [
      {
        text: 'Wczytaj',
        svgIcon: uploadIcon,
        click: () => c.onLoadClick(),
        disabled: () => !c.isValid(),
      },
    ],
  };
};
