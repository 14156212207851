<div class="modal-header">
  <h4 class="modal-title">
    {{ 'GK.USER_SETTINGS.USER_SETTINGS' | translate }}
  </h4>
  @if ((tfaService.forcedTfaActivationInProgress | async) !== true) {
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.close()"
    ></button>
  }
</div>
<div class="modal-body">
  <h2 class="mb-4">
    {{ 'GK.USER_SETTINGS.TFA' | translate }}
  </h2>
  @if (tfaService.forcedTfaActivationInProgress | async) {
    <div class="mb-4">
      <div class="alert alert-warning d-flex" role="alert">
        <i
          aria-hidden="true"
          class="fa fa-exclamation-circle align-self-center me-2"
        ></i>
        <span class="fw-bold">{{
          'GK.USER_SETTINGS.REQUIRED_TFA_INFO' | translate
        }}</span>
      </div>
    </div>
  }
  <div
    class="alert mb-3"
    role="alert"
    [ngClass]="
      (tfaService.isEmailEnabled() | async)
        ? 'alert-success'
        : 'alert-light border'
    "
    >
    @if (tfaService.isEmailEnabled() | async) {
      <span
        class="badge bg-success mb-2"
        >{{ 'GK.USER_SETTINGS.ACTIVE' | translate }}</span
        >
      }
      <h6>{{ 'GK.USER_SETTINGS.TFA_EMAIL' | translate }}</h6>
      <ng-container
        [ngTemplateOutlet]="enableButton"
      [ngTemplateOutletContext]="{
        clickMethod: enableTfaByEmail,
        isTfaTypeEnabled: tfaService.isEmailEnabled() | async,
        expectedTfaType: tfaType.Email
      }"
      ></ng-container>
      @if (tfaService.isEmailEnabled() | async) {
        <ng-template *ngTemplateOutlet="disableButton"></ng-template>
      }
    </div>

    <div
      class="alert mb-3"
      role="alert"
    [ngClass]="
      (tfaService.isTotpEnabled() | async)
        ? 'alert-success'
        : 'alert-light border'
    "
      >
      @if (tfaService.isTotpEnabled() | async) {
        <span
          class="badge bg-success mb-2"
          >{{ 'GK.USER_SETTINGS.ACTIVE' | translate }}</span
          >
        }
        <h6>{{ 'GK.USER_SETTINGS.TFA_TOTP' | translate }}</h6>
        <ng-container
          [ngTemplateOutlet]="enableButton"
      [ngTemplateOutletContext]="{
        clickMethod: enableTfaByTotp,
        isTfaTypeEnabled: tfaService.isTotpEnabled() | async,
        expectedTfaType: tfaType.Totp
      }"
        ></ng-container>
        @if (tfaService.isTotpEnabled() | async) {
          <ng-template *ngTemplateOutlet="disableButton"></ng-template>
        }
      </div>
      <div
        class="alert"
        role="alert"
    [ngClass]="
      (tfaService.isWebAuthnEnabled() | async)
        ? 'alert-success'
        : 'alert-light border'
    "
        >
        @if (tfaService.isWebAuthnEnabled() | async) {
          <span
            class="badge bg-success mb-2"
            >{{ 'GK.USER_SETTINGS.ACTIVE' | translate }}</span
            >
          }
          <h6>{{ 'GK.USER_SETTINGS.TFA_WEB_AUTHN' | translate }}</h6>
          @if (!isWebAuthnPublicKeySupported()) {
            <p
              class="alert alert-danger"
              role="alert"
              >
              {{ 'GK.USER_SETTINGS.WEB_AUTHN_NOT_SUPPORTED' | translate }}
            </p>
          }
          <ng-container
            [ngTemplateOutlet]="enableButton"
      [ngTemplateOutletContext]="{
        clickMethod: enableTfaByWebAuthn,
        isTfaTypeEnabled: tfaService.isWebAuthnEnabled() | async,
        expectedTfaType: tfaType.WebAuthn
      }"
          ></ng-container>
          @if (tfaService.isWebAuthnEnabled() | async) {
            <ng-template *ngTemplateOutlet="disableButton"></ng-template>
          }
          @if ((tfaService.forcedTfaActivationInProgress | async) !== true) {
            @if (tfaService.isWebAuthnEnabled() | async) {
              <button
                (click)="openWebAuthnSettings()"
                type="button"
                class="btn btn-sm btn-outline-primary ms-sm-2 mt-2 mt-sm-0 col-12 col-sm-4 col-lg-2"
                [attr.disabled]="(isAjaxPendingAction() | async) ? true : null"
                >
                {{ 'GK.USER_SETTINGS.SETTINGS' | translate }}
              </button>
            }
          }
        </div>
      </div>

      <ng-template #authenticationChangeConfirmationModal let-close="close">
        <div class="modal-header">
          <h4 class="modal-title">
            {{ 'GK.USER_SETTINGS.TFA_CHANGE.CONFIRMATION_TITLE' | translate }}
          </h4>
          <button
            type="button"
            class="btn-close"
            aria-label="close"
            (click)="close(false)"
          ></button>
        </div>
        <div class="modal-body">
          {{ 'GK.USER_SETTINGS.TFA_CHANGE.CONFIRMATION_TEXT' | translate }}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-color-from-db" (click)="close(true)">
            {{ 'CONFIRM' | translate }}
          </button>
          <button
            type="button"
            class="btn btn-color-from-db-outline"
            (click)="close(false)"
            >
            {{ 'DISMISS' | translate }}
          </button>
        </div>
      </ng-template>

      @if (tfaConfirmationService.tfaConfirmationInOperation | async) {
        <gk-session-tools
          [currentToolMode]="sessionToolMode.TfaConfirmation"
          [destroyOnForcedTfaActivation]="false"
        ></gk-session-tools>
      }

      <ng-template
        #enableButton
        let-clickMethod="clickMethod"
        let-isTfaTypeEnabled="isTfaTypeEnabled"
        let-expectedTfaType="expectedTfaType"
        >
        <button
          type="button"
          class="btn btn-sm btn-outline-primary me-2 col-12 col-sm-2 col-lg-1"
          (click)="clickMethod()"
    [attr.disabled]="
      isTfaTypeEnabled || (isAjaxPendingAction() | async) ? true : null
    "
          >
          @if (
            (tfaService.tfaTypeInOperation | async) === expectedTfaType ||
            (this.tfaService.currentTfaTypeNotFetchedYet() | async)) {
            <i
              class="fa fa-spinner fa-pulse fa-fw"
            ></i>
          } @else {
            {{
            'GK.USER_SETTINGS.ENABLE' | translate
            }}
          }
        </button>
      </ng-template>

      <ng-template #disableButton>
        @if ((tfaService.isTfaRequired() | async) === false) {
          @if ((tfaService.forcedTfaActivationInProgress | async) !== true) {
            <button
              type="button"
              class="btn btn-sm btn-outline-primary mt-2 mt-sm-0 col-12 col-sm-2 col-lg-1"
              (click)="disableTfa()"
      [attr.disabled]="
        (isAjaxPendingAction() | async) || (disableLoading | async)
          ? true
          : null
      "
              >
              @if (disableLoading | async) {
                <i
                  class="fa fa-spinner fa-pulse fa-fw"
                ></i>
              } @else {
                {{
                'GK.USER_SETTINGS.DISABLE' | translate
                }}
              }
            </button>
          }
        }
      </ng-template>
