import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiResponse,
  DictionaryField,
  DictionaryFieldFromApi,
} from '@gk/gk-modules';
import { BehaviorSubject, catchError, map } from 'rxjs';
import { handleApiError } from '../services.utils';

@Injectable()
export class DocumentTypeService {
  documentTypes = new BehaviorSubject<DictionaryField[]>([]);

  constructor(private httpClient: HttpClient) {
    this.fetchDocumentTypesList();
  }

  private fetchDocumentTypesList(): void {
    this.httpClient
      .get<ApiResponse<DictionaryFieldFromApi[]>>('/api/srodowisko/slo/rodzdok')
      .pipe(
        map((data) => data.Response),
        map((documentTypes) =>
          documentTypes.map((documentType) =>
            DictionaryField.fromApiToApp(documentType)
          )
        ),
        catchError(handleApiError)
      )
      .subscribe((data) => this.documentTypes.next(data));
  }
}
