<div class="k-actions k-actions-stretched">
  <button
    #clearButton
    type="button"
    class="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-clear-selected"
    [attr.tabIndex]="-1"
    (click)="onClearButtonClick($event)"
  >
    {{ 'UPLOAD_WINDOW.CLEAR' | translate }}
  </button>
  <button
    #uploadButton
    type="button"
    class="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-upload-selected"
    [attr.tabIndex]="-1"
    (click)="onUploadButtonClick($event)"
  >
    {{ 'UPLOAD_WINDOW.SEND' | translate }}
  </button>
</div>
