import { Component, OnInit } from '@angular/core';
import { AppStorageService } from '../../services';

@Component({
  selector: 'gk-app-cookies-bar',
  templateUrl: './cookies-bar.component.html',
  styleUrls: ['./cookies-bar.component.scss']
})
export class CookiesBarComponent implements OnInit {
  cookiesAccepted: boolean;

  constructor(private appStorageService: AppStorageService) {}

  ngOnInit(): void {
    this.checkCookiesDeclaration();
  }

  checkCookiesDeclaration(): void {
    this.cookiesAccepted = this.appStorageService.cookiesAccepted;
  }

  cookiesAgreement(): void {
    this.cookiesAccepted = true;
    this.appStorageService.cookiesAccepted = this.cookiesAccepted;
  }
}
