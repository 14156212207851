<div class="container container-e-service-request">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <h2 class="mt-5 mb-3">
      {{
        'ENVIRONMENTAL_PROTECTION_PORTAL.SIMPLIFIED_FOREST_MANAGEMENT_PLAN.TITLE'
          | translate
      }}
    </h2>

    <br />

    <section
      class="card mb-4"
      [ngClass]="
        checkInvestorDetailsFormGroupValidAndFormValidated()
          ? 'border-danger border-2'
          : 'border-dark'
      "
    >
      <div class="card-header text-white bg-dark">
        {{
          'ENVIRONMENTAL_PROTECTION_PORTAL.SIMPLIFIED_FOREST_MANAGEMENT_PLAN.APPLICANT_DETAILS.TITLE'
            | translate
        }}
      </div>

      <div class="card-body">
        <app-investor-details
          [investorDetailsFromGroup]="getInvestorDetailsFormGroup()"
        ></app-investor-details>
      </div>
    </section>

    <section
      class="card mb-4"
      [ngClass]="
        checkProxyDetailsFormGroupValidAndFormValidated()
          ? 'border-danger border-2'
          : 'border-dark'
      "
    >
      <div class="card-header text-white bg-dark">
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            [id]="controlName.ProxyDetailsCheckbox"
            [formControlName]="controlName.ProxyDetailsCheckbox"
          />

          <label
            class="form-check-label checkbox"
            [attr.for]="controlName.ProxyDetailsCheckbox"
          >
            {{
              'ENVIRONMENTAL_PROTECTION_PORTAL.SIMPLIFIED_FOREST_MANAGEMENT_PLAN.FIELDS.PROXY_DETAILS'
                | translate
            }}
          </label>
        </div>
      </div>

      @if (proxyDetailsVisible) {
        <div class="card-body">
          <app-investor-details
            [legalPersonEnabled]="false"
            [investorDetailsFromGroup]="getProxyDetailsFormGroup()"
            [automaticCopyingDataFromPz]="false"
          ></app-investor-details>
          <div class="alert alert-info mt-2" role="alert">
            {{
              'ENVIRONMENTAL_PROTECTION_PORTAL.SIMPLIFIED_FOREST_MANAGEMENT_PLAN.FIELDS.PROXY_DETAILS_INFO'
                | translate
            }}
          </div>
        </div>
      }
    </section>

    <section
      class="card mb-4"
      [ngClass]="
        checkParcelsAndExclusionAreasValidAndFormValidated()
          ? 'border-danger border-2'
          : 'border-dark'
      "
    >
      <div class="card-header text-white bg-dark">
        {{
          'ENVIRONMENTAL_PROTECTION_PORTAL.SIMPLIFIED_FOREST_MANAGEMENT_PLAN.FIELDS.PARCELS'
            | translate
        }}
      </div>

      <div class="card-body">
        <gk-map
          [mapState]="mapState"
          (dispatch)="handleMapAction($event)"
        ></gk-map>

        <div class="mt-2">
          @if (mapState.mapObjectTablesState[0].mapObjects.length) {
            <gk-map-object-table
              [mapState]="mapState"
              [mapObjectTableStateIndex]="0"
              (dispatch)="handleMapAction($event)"
            ></gk-map-object-table>
          }
        </div>
      </div>

      @if (submitted && !areParcelsChosen()) {
        <div class="card-footer">
          <div class="alert alert-danger mt-2">
            {{
              'ENVIRONMENTAL_PROTECTION_PORTAL.SIMPLIFIED_FOREST_MANAGEMENT_PLAN.FIELDS.PARCELS_REQUIRED'
                | translate
            }}
          </div>
        </div>
      }
    </section>

    <section class="px-4 pt-4 border border-dark rounded mb-4">
      <h2 class="mb-4">{{ 'ATTACHMENTS' | translate }}</h2>

      @for (
        docType of simplifiedForestManagementPlanDocTypeService.docTypes
          | async;
        track docType.id
      ) {
        @if (docType.labelKey) {
          <section>
            <div
              class="card mb-4"
              [ngClass]="
                shouldShowFileInputInvalidMessage(docType)
                  ? 'border-danger border-2'
                  : 'border-dark'
              "
            >
              <div class="card-header text-white bg-dark">
                {{ docType.labelKey | translate }}
              </div>
              <div class="card-body">
                <gk-file-input
                  class="mb-2 bg-color-from-db-for-button-child"
                  [ngClass]="{ required: checkDocTypeIsRequired(docType.id) }"
                  [attr.aria-required]="checkDocTypeIsRequired(docType.id)"
                  [showPreviews]="true"
                  (filesChange)="handleFileInputAction(docType.id, $event)"
                >
                </gk-file-input>
              </div>
              @if (shouldShowFileInputInvalidMessage(docType)) {
                <div class="card-footer">
                  <div class="alert alert-danger mt-2">
                    {{
                      'ENVIRONMENTAL_PROTECTION_PORTAL.SIMPLIFIED_FOREST_MANAGEMENT_PLAN.VALIDATION.NO_FILE_CHOSEN'
                        | translate
                    }}
                  </div>
                </div>
              }
            </div>
          </section>
        }
        @if (isStampDutyDocType(docType.id)) {
          <section>
            <div
              class="card mb-4"
              [ngClass]="
                shouldShowPaymentInputInvalidMessage(docType)
                  ? 'border-danger border-2'
                  : 'border-dark'
              "
            >
              <div class="card-header text-white bg-dark">
                {{
                  'ENVIRONMENTAL_PROTECTION_PORTAL.SIMPLIFIED_FOREST_MANAGEMENT_PLAN.DOC_TYPE_DESCRIPTION.PROXY_STAMP_DUTY'
                    | translate
                }}
              </div>
              <div class="card-body">
                <gk-file-input
                  class="mb-2 bg-color-from-db-for-button-child"
                  [ngClass]="{ required: proxyDetailsChecked }"
                  [attr.aria-required]="proxyDetailsChecked"
                  [showPreviews]="true"
                  (filesChange)="
                    handleProofOfEnablementPaymentFileInputAction($event)
                  "
                >
                </gk-file-input>
              </div>
              @if (shouldShowPaymentInputInvalidMessage(docType)) {
                <div class="card-footer">
                  <div class="alert alert-danger">
                    {{
                      'LANE_OCCUPANCY_PORTAL.BUILDING_OBJECTS.NO_FILE_CHOSEN'
                        | translate
                    }}
                  </div>
                </div>
              }
            </div>
          </section>
        }
      }
    </section>

    <section class="card border-dark mb-4">
      <div class="card-header text-white bg-dark">
        {{
          'ENVIRONMENTAL_PROTECTION_PORTAL.SIMPLIFIED_FOREST_MANAGEMENT_PLAN.FIELDS.DECISION_FORM'
            | translate
        }}
      </div>

      <div class="card-body">
        <div class="form-check form-check-inline">
          <input
            type="radio"
            [id]="decisionFormType.NonElectronicForm"
            [attr.name]="controlName.DecisionForm"
            [formControlName]="controlName.DecisionForm"
            [value]="decisionFormType.NonElectronicForm"
            class="form-check-input"
          />

          <label
            class="form-check-label"
            [attr.for]="decisionFormType.NonElectronicForm"
          >
            {{
              'ENVIRONMENTAL_PROTECTION_PORTAL.SIMPLIFIED_FOREST_MANAGEMENT_PLAN.FIELDS.NON_ELECTRONIC_FORM'
                | translate
            }}
          </label>
        </div>

        <div class="form-check form-check-inline">
          <input
            type="radio"
            [id]="decisionFormType.ElectronicForm"
            [attr.name]="controlName.DecisionForm"
            [formControlName]="controlName.DecisionForm"
            [value]="decisionFormType.ElectronicForm"
            class="form-check-input"
          />

          <label
            class="form-check-label"
            [attr.for]="decisionFormType.ElectronicForm"
          >
            {{
              'ENVIRONMENTAL_PROTECTION_PORTAL.SIMPLIFIED_FOREST_MANAGEMENT_PLAN.FIELDS.ELECTRONIC_FORM'
                | translate
            }}
          </label>
        </div>
      </div>
    </section>

    @if (portalId) {
      <section
        class="card mb-4"
        [ngClass]="
          checkShippingFormGroupValidAndFormSubmitted()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'ENVIRONMENTAL_PROTECTION_PORTAL.SIMPLIFIED_FOREST_MANAGEMENT_PLAN.FIELDS.SHIPPING_FORM'
              | translate
          }}
        </div>
        <div class="card-body">
          <app-shipping-form
            [shippingFormGroup]="getShippingFormGroup()"
            [portalId]="portalId"
            [decisionForm]="decisionFormValue"
          ></app-shipping-form>
        </div>
      </section>
    }

    <section class="my-2">
      <app-cautions
        [portalId]="portalId"
        [formGroup]="formGroup"
        [submitted]="submitted"
      ></app-cautions>
    </section>

    <button type="submit" class="btn btn-primary">
      {{
        'ENVIRONMENTAL_PROTECTION_PORTAL.SIMPLIFIED_FOREST_MANAGEMENT_PLAN.FIELDS.SUBMIT_REQUEST'
          | translate
      }}
    </button>

    <div class="d-flex flex-column">
      @if (docSignMsgTxt) {
        <span [ngClass]="'text-' + docSignMsgType">
          {{ docSignMsgTxt }}
        </span>
      }

      @if (docSignUrl) {
        <span class="my-2 text-info">
          {{ docSignTranslations['SIGN_INFO'] }}
        </span>
      }

      @if (docSignUrl) {
        <a class="link" [href]="docSignUrl" target="_blank">
          {{ docSignTranslations['SIGN'] }}
        </a>
      }

      @if (docSignPending) {
        <div class="d-flex justify-content-center my-3">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
          </div>
        </div>
      }
    </div>

    <div class="mt-2"><app-form-legend></app-form-legend></div>
  </form>
</div>
