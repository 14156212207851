<ng-template #modalContent let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'OFFICE_DEPARTMENTS_PORTAL.GEODESY.REQUESTS_LIST.TITLE' | translate }}
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="close(true)"
    ></button>
  </div>
  <div class="modal-body">
    <gk-table
      [records]="webRequestService.webRequestData | async"
      [selectionMode]="tableSelectionMode.Single"
      (select)="selectFile($event)"
      [loading]="downloadingInProgress"
      [settings]="getColumnHeaders()"
    ></gk-table>
  </div>
</ng-template>
