import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainRoutes } from '../../guards/guard.models';
import { SessionGuard } from '../../guards/session/session.guard';
import { MainRequestsListComponent } from '../../shared/main-requests-list/main-requests-list.component';
import { AgriculturalLandUseRequestComponent } from './agricultural-land-use-request/agricultural-land-use-request.component';
import { ExcludingLandFromAgriculturalProductionPortalComponent } from './excluding-land-from-agricultural-production-portal.component';
import { NonAgriculturalLandUseRequestComponent } from './non-agricultural-land-use-request/non-agricultural-land-use-request.component';

const routes: Routes = [
  {
    path: MainRoutes.ExcludingLandFromAgriculturalProductionPortalAgriculturalLandUse,
    component: ExcludingLandFromAgriculturalProductionPortalComponent,
    canActivate: [SessionGuard],
    children: [
      {
        path: '',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
      {
        path: 'new-request',
        component: AgriculturalLandUseRequestComponent,
      },
      {
        path: 'requests-list',
        component: MainRequestsListComponent,
      },
      {
        path: '**',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: MainRoutes.ExcludingLandFromAgriculturalProductionPortalNonAgriculturalLandUse,
    component: ExcludingLandFromAgriculturalProductionPortalComponent,
    canActivate: [SessionGuard],
    children: [
      {
        path: '',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
      {
        path: 'new-request',
        component: NonAgriculturalLandUseRequestComponent,
      },
      {
        path: 'requests-list',
        component: MainRequestsListComponent,
      },
      {
        path: '**',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ExcludingLandFromAgriculturalProductionPortalRoutingModule {}
