import { Injectable } from '@angular/core';
import {
  ExtractFromLocalSpatialDevelopmentPlanCalculatePaymentRequest,
  ExtractFromLocalSpatialDevelopmentPlanCalculatePaymentResponse,
  ExtractFromLocalSpatialDevelopmentPlanCalculatePaymentResponseDto,
  ExtractOrCertificateFromLocalSpatialDevelopmentPlanCalculatePaymentRequestDto
} from './extract-or-certificate-from-local-spatial-development-plan-request.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestService {
  constructor(private httpClient: HttpClient) {}

  calculatePayment(
    formValue: ExtractFromLocalSpatialDevelopmentPlanCalculatePaymentRequest
  ): Observable<ExtractFromLocalSpatialDevelopmentPlanCalculatePaymentResponse> {
    return this.httpClient
      .post<ExtractFromLocalSpatialDevelopmentPlanCalculatePaymentResponseDto>(
        '/api/interesant/planowanie/wypisWyrys/calculate/oplata',
        ExtractOrCertificateFromLocalSpatialDevelopmentPlanCalculatePaymentRequestDto.fromAppToApi(
          formValue
        )
      )
      .pipe(
        map(calculatedPaymentFromApi =>
          ExtractFromLocalSpatialDevelopmentPlanCalculatePaymentResponse.fromAppToApi(
            calculatedPaymentFromApi
          )
        )
      );
  }
}
