import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LawPersonSearch } from '../law-person/law-person-search.model';
import {
  ApiPersonType,
  PersonType,
  designerPersonTypeId,
  investorPersonTypeId,
  payerPersonTypeId,
  plenipotentiaryPersonTypeId,
} from './person-type.model';

@Injectable()
export class PersonTypeService {
  personTypes = new BehaviorSubject<PersonType[]>([]);

  constructor(private httpClient: HttpClient) {
    this.fetchPersonTypes();
  }

  private fetchPersonTypes(): Subscription {
    return this.httpClient
      .get<{ Response: ApiPersonType[] }>('/api/zudp/slo/osoba/typ')
      .pipe(
        map((response) => response.Response),
        map((apiPersonTypes) =>
          apiPersonTypes.map((apiPersonType) =>
            PersonType.fromApiToApp(apiPersonType)
          )
        )
      )
      .subscribe((personTypes) => this.personTypes.next(personTypes));
  }

  isDesignerPersonType(personType: PersonType): boolean {
    return personType && personType.id === designerPersonTypeId;
  }

  isPlenipotentiaryPersonType(personType: PersonType): boolean {
    return personType && personType.id === plenipotentiaryPersonTypeId;
  }

  isPayerPersonType(personType: PersonType): boolean {
    return personType && personType.id === payerPersonTypeId;
  }

  isPayerChosen(chosenLawPersons: LawPersonSearch[]): boolean {
    const chosenPersonTypes = chosenLawPersons
      .map((lp) => _.get(lp, 'personType'))
      .filter((pt) => pt);

    return !!chosenPersonTypes.find((pt) => this.isPayerPersonType(pt));
  }

  isInvestorChosen(chosenLawPersons: LawPersonSearch[]): boolean {
    const chosenPersonTypes = chosenLawPersons
      .map((lp) => _.get(lp, 'personType'))
      .filter((pt) => pt);

    return !!chosenPersonTypes.find((pt) => this.isInvestorPersonType(pt));
  }

  getDuplicatedNotMultiTypes(chosenLawPersons: LawPersonSearch[]): string[] {
    const types = new Set<string>(
      chosenLawPersons
        .filter((lawPerson, index) => {
          const personType = _.get(lawPerson, 'personType');
          const otherElementWithSameTypeExists =
            personType &&
            chosenLawPersons.indexOf(
              chosenLawPersons.find(
                (lawPersonEquivalent) =>
                  lawPersonEquivalent.personType &&
                  lawPersonEquivalent.personType.id === personType.id
              )
            ) !== index;
          const isMulti = _.get(personType, 'multi');

          return otherElementWithSameTypeExists && !isMulti;
        })
        .map((lawPerson) => _.get(lawPerson, 'personType.name'))
    );

    return Array.from(types);
  }

  isInvestorPersonType(personType: PersonType): boolean {
    return personType.id === investorPersonTypeId;
  }

  getPersonTypeById(personTypeId: number): PersonType {
    return this.personTypes.getValue().find((pt) => pt.id === personTypeId);
  }

  getPayerPersonType(): PersonType {
    return this.getPersonTypeById(payerPersonTypeId);
  }

  getDesignerPersonType(): PersonType {
    return this.getPersonTypeById(designerPersonTypeId);
  }

  getAllTypesWithProperRequiredAttributesForSelectedMainPersonOfDesginerPortal(
    chosenLawPersonsSearch: LawPersonSearch[]
  ): Observable<PersonType[]> {
    return new Observable<PersonType[]>((observer) => {
      this.personTypes
        .pipe(filter((value) => value && !!value.length))
        .subscribe({
          next: (allTypes) => {
            const mainDesignerPerson = chosenLawPersonsSearch.find(
              (lawPerson) => lawPerson.mainPersonOfDesginerPortal
            );
            let requiredPersons: number[] = [payerPersonTypeId];
            switch (
              mainDesignerPerson &&
              mainDesignerPerson.personType &&
              mainDesignerPerson.personType.id
            ) {
              case investorPersonTypeId: {
                requiredPersons = [payerPersonTypeId];

                break;
              }
              case plenipotentiaryPersonTypeId: {
                requiredPersons = [payerPersonTypeId, investorPersonTypeId];

                break;
              }
              case designerPersonTypeId: {
                requiredPersons = [payerPersonTypeId];

                break;
              }
            }

            observer.next(
              mainDesignerPerson &&
                mainDesignerPerson.personType &&
                mainDesignerPerson.personType.id
                ? allTypes.map((type) => {
                    _.set(
                      type,
                      'isRequired',
                      requiredPersons.includes(type.id)
                    );

                    return type;
                  })
                : allTypes
            );
            observer.complete();
          },
          error: (err) => {
            observer.error(err);
          },
        });
    });
  }

  getAvailablePersonTypesForPersonRelatedToLoggedUser(): Observable<
    PersonType[]
  > {
    return new Observable<PersonType[]>((observer) => {
      this.personTypes
        .pipe(filter((value) => value && !!value.length))
        .subscribe((value) => {
          const personTypes = value;
          const allowedTypes = [
            investorPersonTypeId,
            designerPersonTypeId,
            plenipotentiaryPersonTypeId,
          ];

          observer.next(
            _.intersectionWith(
              personTypes,
              allowedTypes,
              (type, id) => type.id === id
            )
          );
          observer.complete();
        });
    });
  }
}
