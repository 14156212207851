import {
  DistrictMapObject,
  EgibObject,
  Enablement,
  EnablementDto,
} from '@gk/gk-modules';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import {
  AuthorizedPersonToRegistryView,
  AuthorizedPersonToRegistryViewDto,
} from '../authorized-person-to-registry-view/authorized-person-to-registry-view.model';
import { RangeType } from '../parcel/parcel.model';
import { SharingPurpose } from '../sharing-purpose/sharing-purpose.model';

export const emptyToolType = 'emptyToolType';
export type EmptyToolType = typeof emptyToolType;

export class NewRequestModel {
  constructor(
    public applicantId: string,
    public purpose: SharingPurpose,
    public authorizedPersonsToRegistryView: AuthorizedPersonToRegistryView[],
    public period: NgbDateRange,
    public publicRegister: string,
    public districts: DistrictMapObject[],
    public parcels: EgibObject[],
    public wkt: string,
    public rangeType: RangeType,
    public enablement: Enablement,
    public personalData: boolean,
    public par1?: string,
    public par2?: string,
    public remarks?: string,
  ) {}

  static getInitialStruct(): NewRequestModel {
    return {
      rangeType: RangeType.Parcels,
      period: {},
      enablement: Enablement.getInitialStruct(),
      personalData: true,
    } as NewRequestModel;
  }
}

export interface CzasDostepu {
  CzasDostepuOd: string;
  CzasDostepuDo: string;
}

export class NgbDateRange {
  constructor(
    public from?: NgbDate,
    public to?: NgbDate,
  ) {}
}

export class PrzpZamowienieZakresRequestDto {
  constructor(
    public DzialkiUuids: null | string[],
    public ObrebIds: null | number[],
    public Wkt: null | string,
  ) {}
}

export interface NewRequestApiModel {
  IdPetenta: string | number;
  CelId: string;
  Par1: string;
  Par2: string;
  Uwagi: string;
  UpowaznieniInfo: AuthorizedPersonToRegistryViewDto[];
  CzasDostepu: CzasDostepu;
  RejPublId: number;
  ZakresDaneOs: boolean;
  Zakres: PrzpZamowienieZakresRequestDto;
  Pelnomocnictwo: EnablementDto;
}

export interface NewRequestResponse {
  ZamowId: number;
  ZamowNr: string;
  ZamowUuid: string;
}
