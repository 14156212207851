export enum ConstructionNotificationControlName {
  ConstructionWorksCommencementDate = 'constructionWorksCommencementDate',
  Description = 'description',
  BuildingNameAndType = 'buildingNameAndType',
  ProxyDetailsCheckbox = 'proxyDetailsCheckbox',
  ProxyDetails = 'proxyDetails',
  InvestorDetails = 'investorDetails',
  DecisionForm = 'decisionForm',
  ShippingForm = 'shippingForm',
  ConstructionIntention = 'constructionIntention',
  Place = 'place',
  Street = 'street',
  StreetAbsence = 'streetAbsence',
  BuildingNumber = 'buildingNumber',
  LocalNumber = 'localNumber',
  PostalCode = 'postalCode',
  PostOffice = 'postOffice'
}

export enum ConstructionNotificationType {
  ConstructionIntention = 0,
  ConstructionWorksExecution = 1
}
