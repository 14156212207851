export enum TreesAndShrubsFellingControlName {
  ProxyDetailsCheckbox = 'proxyDetailsCheckbox',
  ProxyDetails = 'proxyDetails',
  InvestorDetails = 'investorDetails',
  DecisionForm = 'decisionForm',
  ShippingForm = 'shippingForm',
  BusinessPurpose = 'businessPurpose',
  FellingDate = 'fellingDate',
  InvestmentDescription = 'investmentDescription',
  GrowthPlace = 'growthPlace',
  FellingCause = 'fellingCause',
  TreesList = 'treesList',
  ShrubsList = 'shrubsList',
  Place = 'fellingPlace',
  Street = 'fellingStreet',
  LandRegister = 'landRegister',
  LandNumber = 'fellingLandNumber',
  StreetAbsence = 'streetAbsence'
}

export enum FellingObjectsControlName {
  InventoryNumber = 'inventoryNumber',
  SpeciesName = 'speciesName',
  TrunkCircumference = 'trunkCircumference',
  ProjectionSurface = 'projectionSurface'
}

export enum FellingPurpose {
  NotBusiness = 0,
  Business = 1
}
