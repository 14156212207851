import {
  AuthorizedPersonControlName,
  AuthorizedPersonData,
  AuthorizedPersonDataRequestDto,
} from '../../../services/authorized-person-form/authorized-person-form.model';
import { ApiNewDokumentPowiazanyDalDto } from '../../../services/designer-incoming-documents/designer-incoming-documents.model';
import { ZamowienieDzialkaDto } from '../../../services/excluding-land-from-agricultural-production/agricultural-land-use/agricultural-land-use.model';
import {
  FeeWaiverCause,
  FeeWaiverCauseDto,
  FeeWaiverControlName,
  FeeWaiverForm,
} from '../../../shared/fee-waiver/fee-waiver.model';
import {
  InvestorDetails,
  InvestorDetailsRequestDto,
} from '../../../shared/investor-details/investor-details.model';
import {
  Shipping,
  ShippingDto,
} from '../../../shared/shipping-form/shipping-form.model';
import { PlanningDepartmentRequestType } from './planning-department.model';

export enum ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestSubject {
  Certificate = 1,
  ExtractFromSpatialDevelopmentPlan = 2,
  WrittenExtractFromSpatialDevelopmentPlan = 3,
  GraphicExtractFromSpatialDevelopmentPlan = 4,
  ExtractFromStudy = 5,
  WrittenExtractFromStudy = 6,
  GraphicExtractFromStudy = 7,
}

export interface ExtractOrCertificateFromLocalSpatialDevelopmentPlanRequestSubjectCheckbox {
  writtenExtractCheckbox: ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestSubject;
  graphicExtractCheckbox: ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestSubject;
  certificateCheckbox: ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestSubject;
}

export interface ExtractFromLocalSpatialDevelopmentPlanRequest {
  investorDetails: InvestorDetails;
  [AuthorizedPersonControlName.AuthorizedDetailsForm]: AuthorizedPersonData;
  comments: string;
  [FeeWaiverControlName.FeeWaiverForm]: FeeWaiverForm;
  dismissalReasonExplanationDescription: string;
  subject: ExtractOrCertificateFromLocalSpatialDevelopmentPlanRequestSubjectCheckbox;
  landParcels: ZamowienieDzialkaDto[];
  shippingForm: Shipping;
  quantity: number;
  attachments: ApiNewDokumentPowiazanyDalDto;
  requestFormType: PlanningDepartmentRequestType;
}

const getRequestSubjectValueFromCheckboxData = (
  data: ExtractOrCertificateFromLocalSpatialDevelopmentPlanRequestSubjectCheckbox,
  requestFormType: PlanningDepartmentRequestType
) => {
  let requestSubjectValue: ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestSubject;

  if (data.graphicExtractCheckbox && !data.writtenExtractCheckbox) {
    requestSubjectValue =
      requestFormType ===
      PlanningDepartmentRequestType.ExtractFromLocalSpatialDevelopmentPlan
        ? ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestSubject.GraphicExtractFromSpatialDevelopmentPlan
        : ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestSubject.GraphicExtractFromStudy;
  } else if (!data.graphicExtractCheckbox && data.writtenExtractCheckbox) {
    requestSubjectValue =
      requestFormType ===
      PlanningDepartmentRequestType.ExtractFromLocalSpatialDevelopmentPlan
        ? ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestSubject.WrittenExtractFromSpatialDevelopmentPlan
        : ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestSubject.WrittenExtractFromStudy;
  } else if (data.graphicExtractCheckbox && data.writtenExtractCheckbox) {
    requestSubjectValue =
      requestFormType ===
      PlanningDepartmentRequestType.ExtractFromLocalSpatialDevelopmentPlan
        ? ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestSubject.ExtractFromSpatialDevelopmentPlan
        : ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestSubject.ExtractFromStudy;
  } else if (data.certificateCheckbox) {
    requestSubjectValue =
      ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestSubject.Certificate;
  }

  return requestSubjectValue;
};

export class ExtractOrCertificateFromLocalSpatialDevelopmentPlanRequestDto {
  constructor(
    public Wnioskodawca: InvestorDetailsRequestDto,
    public DaneUpowaznionego: AuthorizedPersonDataRequestDto,
    public Uwagi: string,
    public PrzyczynaZwolnieniaZOplatySkarbowej: FeeWaiverCause,
    public PrzyczynaZwolnieniaOpis: string,
    public PrzedmiotWniosku: ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestSubject,
    public Dzialki: ZamowienieDzialkaDto[],
    public Odbior: ShippingDto,
    public IloscSztuk: number,
    public Zalaczniki: ApiNewDokumentPowiazanyDalDto[]
  ) {}

  static fromAppToApi(
    data: ExtractFromLocalSpatialDevelopmentPlanRequest,
    attachments: ApiNewDokumentPowiazanyDalDto[]
  ): ExtractOrCertificateFromLocalSpatialDevelopmentPlanRequestDto {
    return new this(
      InvestorDetailsRequestDto.fromAppToApi(data.investorDetails),
      AuthorizedPersonDataRequestDto.fromAppToApi(
        data[AuthorizedPersonControlName.AuthorizedDetailsForm]
      ),
      data.comments,
      FeeWaiverCauseDto.fromAppToApi(data[FeeWaiverControlName.FeeWaiverForm]),
      data.dismissalReasonExplanationDescription,
      getRequestSubjectValueFromCheckboxData(
        data.subject,
        data.requestFormType
      ),
      data.landParcels,
      ShippingDto.fromAppToApi(data.shippingForm),
      data.quantity,
      attachments
    );
  }
}

export interface ExtractFromLocalSpatialDevelopmentPlanCalculatePaymentRequest {
  subject: ExtractOrCertificateFromLocalSpatialDevelopmentPlanRequestSubjectCheckbox;
  landParcels: ZamowienieDzialkaDto[];
  requestFormType: PlanningDepartmentRequestType;
  quantity: number;
  [FeeWaiverControlName.FeeWaiverForm]: FeeWaiverForm;
  shippingForm: Shipping;
}

export class ExtractOrCertificateFromLocalSpatialDevelopmentPlanCalculatePaymentRequestDto {
  constructor(
    public PrzedmiotWniosku: ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestSubject,
    public Dzialki: ZamowienieDzialkaDto[],
    public IloscSztuk: number,
    public PrzyczynaZwolnieniaZOplatySkarbowej: FeeWaiverCause,
    public Odbior: ShippingDto
  ) {}

  static fromAppToApi(
    data: ExtractFromLocalSpatialDevelopmentPlanCalculatePaymentRequest
  ): ExtractOrCertificateFromLocalSpatialDevelopmentPlanCalculatePaymentRequestDto {
    return new this(
      getRequestSubjectValueFromCheckboxData(
        data.subject,
        data.requestFormType
      ),
      data.landParcels,
      data.quantity,
      FeeWaiverCauseDto.fromAppToApi(data[FeeWaiverControlName.FeeWaiverForm]),
      ShippingDto.fromAppToApi(data.shippingForm)
    );
  }
}

export interface ExtractFromLocalSpatialDevelopmentPlanCalculatePaymentResponseDto {
  Kwota: number;
  Status: string;
}

export class ExtractFromLocalSpatialDevelopmentPlanCalculatePaymentResponse {
  constructor(public amount: number, public status: string) {}

  static fromAppToApi(
    data: ExtractFromLocalSpatialDevelopmentPlanCalculatePaymentResponseDto
  ): ExtractFromLocalSpatialDevelopmentPlanCalculatePaymentResponse {
    return new this(data.Kwota, data.Status);
  }
}
