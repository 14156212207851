import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { takeWhile } from 'rxjs/operators';
import { TimerService } from '../../services/timer/timer.service';

@Component({
  selector: 'app-modal-prolong-session',
  templateUrl: './modal-prolong-session.component.html',
  styleUrls: ['./modal-prolong-session.component.scss'],
})
export class ModalProlongSessionComponent implements OnInit, OnDestroy {
  private isAlive = true;
  @ViewChild('modal') modalTemplateRef: TemplateRef<any>;
  ngbModalRef: NgbModalRef;

  constructor(
    private modalService: NgbModal,
    private timerService: TimerService
  ) {}

  ngOnInit(): void {
    this.subscribeToModalTrigger();
  }

  subscribeToModalTrigger(): void {
    this.timerService.modalVisible
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((modalVisible) => {
        if (modalVisible) {
          this.showModalToProlongSession();
        } else {
          this.close();
        }
      });
  }

  getModalOptions(): NgbModalOptions {
    return {
      beforeDismiss: () => false,
    };
  }

  showModalToProlongSession(): void {
    this.ngbModalRef = this.modalService.open(
      this.modalTemplateRef,
      this.getModalOptions()
    );

    this.ngbModalRef.result.then(() => {
      this.timerService.prolongSessionTrigger.next();
    });
  }

  close(): void {
    this.ngbModalRef.close();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
