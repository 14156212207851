<h5 [innerHTML]="getHtmlResultsHeaderWithCount()"></h5>
<form [formGroup]="lawPersonListForm" class="my-2">
  <label for="lawPersonSelectableList" class="sr-only">
    {{ 'LAW_PERSON_LIST.SELECTED_LAW_PERSONS_LABEL' | translate }}
  </label>
  <select
    [multiple]="false"
    class="my-1 form-select"
    name="lawPersonSelectableList"
    id="lawPersonSelectableList"
    [formControlName]="lawPersonListControlName.SelectedLawPersons"
    >
    <option [ngValue]="null" hidden selected>
      {{ 'LAW_PERSON_LIST.DEFAULT_OPTION' | translate }}
    </option>
    @for (lawPerson of lawPersons; track lawPerson) {
      <option [ngValue]="lawPerson">
        {{
        getLawPersonDescription(
        lawPerson,
        'LAW_PERSON_LIST.PERMISSION_NUMBER_LABEL' | translate
        )
        }}
      </option>
    }
  </select>

  <div>
    <button
      type="button"
      class="btn btn-sm btn-danger me-1"
      (click)="emitSelectedLawPersons()"
      [disabled]="!isChosenLawPersons()"
      >
      {{ 'LAW_PERSON_LIST.ADD_SELECTED' | translate }}
    </button>
    @if (legalPersonAddModeEnabled || naturalPersonChosen) {
      <button
        type="button"
        class="btn btn-sm btn-primary"
        (click)="enableNewPersonMode()"
        >
        @if (!legalPersonOnly) {
          <span>{{
            'LAW_PERSON_LIST.ADD_NEW' | translate
          }}</span>
        }
        @if (legalPersonOnly) {
          <span>{{
            'LAW_PERSON_LIST.ADD_NEW_LEGAL_PERSON' | translate
          }}</span>
        }
      </button>
    }
  </div>
</form>

<ng-template #personIsAlreadyDefinedInfoModal let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">{{ 'MODAL_DEFINED_PERSON.HEADER' | translate }}</h4>
  </div>
  <div class="modal-body">
    {{ 'MODAL_DEFINED_PERSON.BODY' | translate }}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="close()">
      {{ 'MODAL_DEFINED_PERSON.OK' | translate }}
    </button>
  </div>
</ng-template>
