import { Injectable } from '@angular/core';
import { PortalId } from '@gk/gk-modules';
import * as _ from 'lodash';
import { map, ReplaySubject } from 'rxjs';
import { DocType } from '../../doc-type/doc-type.model';
import { DocTypeService } from '../../doc-type/doc-type.service';
import { DocFile } from '../../new-designer-request/new-designer-request.model';
import {
  AgriculturalLandUseDocTypeId,
  getAgriculturalLandUseDocTypLabelKeyById,
} from './agricultural-land-use-doc-type.model';

@Injectable()
export class ExcludingLandUseFromAgriculturalProductionDocTypeService {
  docTypes = new ReplaySubject<DocType[]>();
  obligatoryDocTypes: DocType[];
  obligatoryDocTypesFromApi: DocType[];
  private investorDetailsFormControlChecked: boolean;
  constructor(private docTypeService: DocTypeService) {
    this.fetchDocTypes();
  }

  private fetchDocTypes(): void {
    this.docTypeService
      .getDocTypeForPortal(
        PortalId.ExcludingLandFromAgriculturalProductionStageFirst,
      )
      .pipe(
        map((data) =>
          data
            .filter(
              (element) =>
                element.id !==
                AgriculturalLandUseDocTypeId.DecisionOfBuildingConditions,
            )
            .map((element) => ({
              ...element,
              labelKey: getAgriculturalLandUseDocTypLabelKeyById(element.id),
            })),
        ),
      )
      .subscribe((data) => this.docTypes.next(data));
    this.setObligatoryDocTypesFromApi();
    this.setObligatoryDocTypes();
  }

  getOrUpdateObligatoryDocTypes(
    investorDetailsFormControlChecked = false,
  ): void {
    this.investorDetailsFormControlChecked = investorDetailsFormControlChecked;
    if (investorDetailsFormControlChecked) {
      this.setObligatoryDocTypesWhenInvestorDetailsFormControlChecked();
    } else {
      this.setObligatoryDocTypes();
    }
  }

  private setObligatoryDocTypesFromApi(): void {
    this.docTypes.subscribe(
      (docTypes) =>
        (this.obligatoryDocTypesFromApi = docTypes.filter(
          (docType) => docType.isObligatory,
        )),
    );
  }

  private setObligatoryDocTypes(): void {
    this.docTypes.subscribe((docTypes) => {
      this.obligatoryDocTypes = [
        ...docTypes.filter(
          (docType) =>
            docType.id ===
              AgriculturalLandUseDocTypeId.LegalTitleToRealEstate ||
            docType.id ===
              AgriculturalLandUseDocTypeId.DecisionOfBuildingConditionsOrExcerptAndExcerptFromTheSpatialDevelopmentPlan,
        ),
        ...this.obligatoryDocTypesFromApi,
      ];
    });
  }

  private setObligatoryDocTypesWhenInvestorDetailsFormControlChecked(): void {
    this.docTypes.subscribe(
      (docTypes) =>
        (this.obligatoryDocTypes = [
          ...docTypes.filter(
            (docType) =>
              docType.id ===
                AgriculturalLandUseDocTypeId.DecisionOfBuildingConditionsOrExcerptAndExcerptFromTheSpatialDevelopmentPlan ||
              docType.id ===
                AgriculturalLandUseDocTypeId.LegalTitleToRealEstate ||
              docType.id === AgriculturalLandUseDocTypeId.StampDuty ||
              docType.id === AgriculturalLandUseDocTypeId.PowerOfAttorney,
          ),
          ...this.obligatoryDocTypesFromApi,
        ]),
    );
  }

  areObligatoryDocFilesAttached(attachedDocFiles: DocFile[]): boolean {
    if (this.investorDetailsFormControlChecked) {
      return (
        _.every(this.obligatoryDocTypesFromApi, (oblDocType) =>
          _.find(
            attachedDocFiles,
            (attachedDocFile) => attachedDocFile.docTypeId === oblDocType.id,
          ),
        ) &&
        attachedDocFiles.some(
          (docFile) =>
            docFile.docTypeId ===
            AgriculturalLandUseDocTypeId.DecisionOfBuildingConditionsOrExcerptAndExcerptFromTheSpatialDevelopmentPlan,
        ) &&
        attachedDocFiles.some(
          (docFile) =>
            docFile.docTypeId === AgriculturalLandUseDocTypeId.StampDuty,
        ) &&
        attachedDocFiles.some(
          (docFile) =>
            docFile.docTypeId === AgriculturalLandUseDocTypeId.PowerOfAttorney,
        )
      );
    } else {
      return (
        _.every(this.obligatoryDocTypesFromApi, (oblDocType) =>
          _.find(
            attachedDocFiles,
            (attachedDocFile) => attachedDocFile.docTypeId === oblDocType.id,
          ),
        ) &&
        attachedDocFiles.some(
          (docFile) =>
            docFile.docTypeId ===
              AgriculturalLandUseDocTypeId.LegalTitleToRealEstate ||
            docFile.docTypeId ===
              AgriculturalLandUseDocTypeId.DecisionOfBuildingConditionsOrExcerptAndExcerptFromTheSpatialDevelopmentPlan,
        )
      );
    }
  }
}
