import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TfaReset, TfaResetApi } from './tfa-reset.model';

@Injectable()
export class TfaResetService {
  constructor(private httpClient: HttpClient) {}

  reset(data: TfaReset): Observable<void> {
    return this.httpClient.post<void>(
      '/api/system/login/2fa/reset',
      TfaResetApi.fromApp(data)
    );
  }
}
