import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { samePasswordsValidator } from '../../validators/check-passwords/check-passwords-validator';
import { NewPasswordControlName } from './new-password-form.model';

@Injectable()
export class NewPasswordFormService {
  constructor(private formBuilder: UntypedFormBuilder) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group(
      {
        [NewPasswordControlName.CurrentPassword]: this.formBuilder.control('', [
          Validators.required
        ]),
        [NewPasswordControlName.NewPassword]: this.formBuilder.control('', [
          Validators.required,
          Validators.pattern(
            '^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[!_()*-@#$~,{}/.%^&+=0-9]).*$'
          )
        ]),
        [NewPasswordControlName.PasswordConfirmation]: this.formBuilder.control('', [
          Validators.required
        ])
      },
      { validator: samePasswordsValidator() }
    );
  }
}
