<form [formGroup]="form" novalidate>
  @if (typeVisible) {
    <div class="form-group col-3 my-1">
      <label [attr.for]="legalPersonControlName.TypeId">{{
        'LEGAL_PERSON_FORM.LEGAL_PERSON_TYPE' | translate
      }}</label>
      <select
        class="form-select form-select-sm"
        [ngClass]="{ required: isRequired(getTypeIdFormControl()) }"
        [id]="legalPersonControlName.TypeId"
        [required]="isRequired(getTypeIdFormControl())"
        [attr.aria-required]="isRequired(getTypeIdFormControl())"
        [formControlName]="legalPersonControlName.TypeId"
        [name]="legalPersonControlName.TypeId"
        >
        @for (legalPersonType of legalPersonTypes; track legalPersonType) {
          <option
            [ngValue]="legalPersonType.id"
            >
            {{ legalPersonType.name }}
          </option>
        }
      </select>
    </div>
  }
  <div class="form-group col-9 my-1">
    <label [attr.for]="legalPersonControlName.Name">{{
      'LEGAL_PERSON_FORM.PRINCIPAL.NAME' | translate
    }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      [id]="legalPersonControlName.Name"
      [formControlName]="legalPersonControlName.Name"
      [ngClass]="{ required: isRequired(getNameFormControl()) }"
      [attr.aria-required]="isRequired(getNameFormControl())"
      [name]="legalPersonControlName.Name"
      [autocomplete]="undefined"
      />
    @if (shouldShowRequiredAlert(getNameFormControl())) {
      <div
        class="alert alert-danger"
        >
        {{ 'FIELD_REQUIRED' | translate }}
      </div>
    }
  </div>
</form>
