<div
  class="map-tool-container bg-white"
  [ngClass]="mapState.toolbarState.mapToolContainerHorizontalPosition"
>
  <div class="map-tool-container-title">{{ getFormHeader() | translate }}</div>
  <div class="map-tool-container-body">
    @switch (sourceType) {
      @case (sourceTypeEnum.MapSheetForm) {
        <gk-map-sheet-form
          [mapState]="mapState"
          [toolType]="toolType"
          (dispatch)="dispatch.emit($event)"
        ></gk-map-sheet-form>
      }
    }
  </div>
  <div class="map-tool-container-footer">
    <div class="text-center">
      <button
        type="button"
        class="btn btn-outline-color-from-db btn-sm me-2"
        [attr.aria-label]="'DISMISS' | translate"
        (click)="handleDismiss()"
      >
        {{ 'GK.MAP.DISMISS' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-outline-color-from-db btn-sm"
        [attr.aria-label]="'SUBMIT' | translate"
        (click)="handleSubmit()"
      >
        {{ getSourceState().submitButtonText || 'GK.MAP.SUBMIT' | translate }}
      </button>
    </div>
  </div>
</div>
