import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlSegment } from '@angular/router';
import { LoginService } from '@gk/gk-modules';
import * as _ from 'lodash';
import { catchError, combineLatestWith, map, Observable, of } from 'rxjs';
import { PortalConfigService } from '../../services/portal-config/portal-config.service';
import { SessionService } from '../../services/session/session.service';
import { MainRoutes, PzInitRoutes } from '../guard.models';

@Injectable()
export class SessionGuard {
  constructor(
    private sessionService: SessionService,
    private router: Router,
    private loginService: LoginService,
    private portalConfigService: PortalConfigService,
  ) {}

  authenticate(url: UrlSegment[]): void {
    const path = _.get(url, '[0].path', '') as MainRoutes;
    let redirected = false;
    let currentPzInitUrl: PzInitRoutes;

    if (!redirected && path === MainRoutes.Designer) {
      redirected = true;
      currentPzInitUrl = PzInitRoutes.Designer;
    }

    if (!redirected && path === MainRoutes.Przp) {
      redirected = true;
      currentPzInitUrl = PzInitRoutes.Przp;
    }

    if (!redirected && path === MainRoutes.GrantingRights) {
      redirected = true;
      currentPzInitUrl = PzInitRoutes.GrantingRights;
    }

    if (!redirected && path === MainRoutes.NetworkServiceOrder) {
      redirected = true;
      currentPzInitUrl = PzInitRoutes.NetworkServiceOrder;
    }

    if (!redirected && path === MainRoutes.RoadExitPortal) {
      redirected = true;
      currentPzInitUrl = PzInitRoutes.RoadExit;
    }

    if (
      !redirected &&
      path ===
        MainRoutes.ExcludingLandFromAgriculturalProductionPortalAgriculturalLandUse
    ) {
      redirected = true;
      currentPzInitUrl =
        PzInitRoutes.ExcludingLandFromAgriculturalProductionPortalAgriculturalLandUse;
    }

    if (
      !redirected &&
      path ===
        MainRoutes.ExcludingLandFromAgriculturalProductionPortalNonAgriculturalLandUse
    ) {
      redirected = true;
      currentPzInitUrl =
        PzInitRoutes.ExcludingLandFromAgriculturalProductionPortalNonAgriculturalLandUse;
    }
    if (
      !redirected &&
      path === MainRoutes.LaneOccupancyPortalInfrastructureDevices
    ) {
      redirected = true;
      currentPzInitUrl = PzInitRoutes.LaneOccupancyInfrastructureDevices;
    }
    if (!redirected && path === MainRoutes.LaneOccupancyPortalBuildingObjects) {
      redirected = true;
      currentPzInitUrl = PzInitRoutes.LaneOccupancyBuildingObjects;
    }
    if (!redirected && path === MainRoutes.LaneOccupancyPortalBuildingWorks) {
      redirected = true;
      currentPzInitUrl = PzInitRoutes.LaneOccupancyBuildingWorks;
    }
    if (
      !redirected &&
      path === MainRoutes.LandAndBuildingRegisterDataChangesNotificationRequest
    ) {
      redirected = true;
      currentPzInitUrl =
        PzInitRoutes.LandAndBuildingRegisterDataChangesNotificationRequest;
    }
    if (!redirected && path === MainRoutes.SoilClassificationOfLandRequest) {
      redirected = true;
      currentPzInitUrl = PzInitRoutes.SoilClassificationOfLandRequest;
    }
    if (
      !redirected &&
      path === MainRoutes.PresentationCommentsLandBuildingRegistryProject
    ) {
      redirected = true;
      currentPzInitUrl =
        PzInitRoutes.PresentationCommentsLandBuildingRegistryProject;
    }
    if (
      !redirected &&
      path === MainRoutes.AccessToInformationOnEnvironmentAndItsProtection
    ) {
      redirected = true;
      currentPzInitUrl =
        PzInitRoutes.AccessToInformationOnEnvironmentAndItsProtection;
    }
    if (!redirected && path === MainRoutes.BuildingPermit) {
      redirected = true;
      currentPzInitUrl = PzInitRoutes.BuildingPermit;
    }
    if (!redirected && path === MainRoutes.DemolitionPermit) {
      redirected = true;
      currentPzInitUrl = PzInitRoutes.DemolitionPermit;
    }
    if (!redirected && path === MainRoutes.BuildingOrPartUseChange) {
      redirected = true;
      currentPzInitUrl = PzInitRoutes.BuildingOrPartUseChange;
    }
    if (!redirected && path === MainRoutes.TemporaryStructureBuildingPermit) {
      redirected = true;
      currentPzInitUrl = PzInitRoutes.TemporaryStructureBuildingPermit;
    }
    if (!redirected && path === MainRoutes.BuildingPermitModificationDecision) {
      redirected = true;
      currentPzInitUrl = PzInitRoutes.BuildingPermitModificationDecision;
    }
    if (
      !redirected &&
      path === MainRoutes.NotObjectedNotificationRightsObligationsTransfer
    ) {
      redirected = true;
      currentPzInitUrl =
        PzInitRoutes.NotObjectedNotificationRightsObligationsTransfer;
    }
    if (
      !redirected &&
      path === MainRoutes.PlotLandDevArchConstrProjSeparateDecision
    ) {
      redirected = true;
      currentPzInitUrl = PzInitRoutes.PlotLandDevArchConstrProjSeparateDecision;
    }
    if (!redirected && path === MainRoutes.AdjacentPropertyNeedEnterDecision) {
      redirected = true;
      currentPzInitUrl = PzInitRoutes.AdjacentPropertyNeedEnterDecision;
    }
    if (
      !redirected &&
      path === MainRoutes.EnvironmentalProtectionPortalFishingCardRequest
    ) {
      redirected = true;
      currentPzInitUrl =
        PzInitRoutes.EnvironmentalProtectionPortalFishingCardRequest;
    }
    if (
      !redirected &&
      path === MainRoutes.ConstructionPortalConstructionNotificationRequest
    ) {
      redirected = true;
      currentPzInitUrl =
        PzInitRoutes.ConstructionPortalConstructionNotificationRequest;
    }
    if (
      !redirected &&
      path === MainRoutes.ConstructionPortalDemolitionNotificationRequest
    ) {
      redirected = true;
      currentPzInitUrl =
        PzInitRoutes.ConstructionPortalDemolitionNotificationRequest;
    }
    if (
      !redirected &&
      path === MainRoutes.ConstructionPortalPremiseIndependenceRequest
    ) {
      redirected = true;
      currentPzInitUrl =
        PzInitRoutes.ConstructionPortalPremiseIndependenceRequest;
    }
    if (
      !redirected &&
      path === MainRoutes.ConstructionPortalConstructionLogRequest
    ) {
      redirected = true;
      currentPzInitUrl = PzInitRoutes.ConstructionPortalConstructionLogRequest;
    }
    if (
      !redirected &&
      path ===
        MainRoutes.EnvironmentalProtectionPortalSimplifiedForestManagementPlanRequest
    ) {
      redirected = true;
      currentPzInitUrl =
        PzInitRoutes.EnvironmentalProtectionPortalSimplifiedForestManagementPlanRequest;
    }
    if (
      !redirected &&
      path ===
        MainRoutes.EnvironmentalProtectionPortalTreesAndShrubsFellingRequest
    ) {
      redirected = true;
      currentPzInitUrl =
        PzInitRoutes.EnvironmentalProtectionPortalTreesAndShrubsFellingRequest;
    }

    if (
      !redirected &&
      path === MainRoutes.EnvironmentalProtectionPortalWaterEquipmentRequest
    ) {
      redirected = true;
      currentPzInitUrl =
        PzInitRoutes.EnvironmentalProtectionPortalWaterEquipmentRequest;
    }

    if (!redirected && path === MainRoutes.BuildingPermitTransfer) {
      redirected = true;
      currentPzInitUrl = PzInitRoutes.BuildingPermitTransfer;
    }

    if (
      !redirected &&
      path ===
        MainRoutes.PlanningDepartmentExtractFromLocalSpatialDevelopmentPlan
    ) {
      redirected = true;
      currentPzInitUrl =
        PzInitRoutes.PlanningDepartmentExtractFromLocalSpatialDevelopmentPlanRequest;
    }

    if (
      !redirected &&
      path ===
        MainRoutes.PlanningDepartmentCertificateFromLocalSpatialDevelopmentPlan
    ) {
      redirected = true;
      currentPzInitUrl =
        PzInitRoutes.PlanningDepartmentCertificateFromLocalSpatialDevelopmentPlanRequest;
    }

    if (!redirected && path === MainRoutes.PlanningDepartmentExtractFromStudy) {
      redirected = true;
      currentPzInitUrl = PzInitRoutes.PlanningDepartmentExtractFromStudy;
    }

    if (!redirected && path === MainRoutes.SurveyorPortal) {
      redirected = true;
      currentPzInitUrl = PzInitRoutes.PzInitSystemUrl;
    }

    if (!redirected && path === MainRoutes.AppraiserPortal) {
      redirected = true;
      currentPzInitUrl = PzInitRoutes.PzInitSystemUrl;
    }

    if (!redirected && path === MainRoutes.AddressNumberRequestPortal) {
      redirected = true;
      currentPzInitUrl = PzInitRoutes.AddressNumberRequestPortal;
    }

    if (!redirected && path === MainRoutes.OwnershipConversion) {
      redirected = true;
      currentPzInitUrl = PzInitRoutes.OwnershipConversion;
    }

    if (!redirected && path === MainRoutes.PropertyLease) {
      redirected = true;
      currentPzInitUrl = PzInitRoutes.PropertyLease;
    }

    if (!redirected && path === MainRoutes.SupplementaryParcelPurchase) {
      redirected = true;
      currentPzInitUrl = PzInitRoutes.SupplementaryParcelPurchase;
    }

    if (!redirected && path === MainRoutes.StreetNaming) {
      redirected = true;
      currentPzInitUrl = PzInitRoutes.StreetNaming;
    }

    if (!redirected && path === MainRoutes.CommunalPremisePurchase) {
      redirected = true;
      currentPzInitUrl = PzInitRoutes.CommunalPremisePurchase;
    }

    if (
      !redirected &&
      path === MainRoutes.SingleFamilyBuilding70ConstructionConversion
    ) {
      redirected = true;
      currentPzInitUrl =
        PzInitRoutes.SingleFamilyBuilding70ConstructionConversion;
    }

    if (
      !redirected &&
      path === MainRoutes.SingleFamilyBuildingConstructionConversion
    ) {
      redirected = true;
      currentPzInitUrl =
        PzInitRoutes.SingleFamilyBuildingConstructionConversion;
    }

    if (
      !redirected &&
      path === MainRoutes.GeodeticAndCartographicServiceDataRequest
    ) {
      redirected = true;
      currentPzInitUrl = PzInitRoutes.GeodeticAndCartographicServiceDataRequest;
    }

    if (!redirected) {
      redirected = true;
      this.navigateToNotFound();

      return;
    }

    this.loginService.navigateToExternalLogin(currentPzInitUrl);
  }

  navigateToNotFound(): void {
    this.router.navigateByUrl(`/${MainRoutes.NotFound}`);
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const path = _.get(route.url, '[0].path', '') as MainRoutes;
    return this.sessionService.getSystemSession().pipe(
      combineLatestWith(
        this.portalConfigService.getPortalConfByMainRoute(path),
      ),
      map(([sessionState, portalConfig]) => {
        const path = _.get(route.url, '[0].path', '') as MainRoutes;

        if (
          portalConfig?.isPerunAuthenticationAvailable &&
          !sessionState.isAuthenticated()
        ) {
          this.navigateToSignInPage(path);

          return false;
        }

        if (
          (path === MainRoutes.Designer ||
            portalConfig?.isPerunAuthenticationAvailable) &&
          sessionState.isPerunAuthenticated()
        ) {
          return true;
        }

        if (sessionState.isPzAuthenticated()) {
          return true;
        }

        this.authenticate(route.url);

        return false;
      }),
      catchError(() => {
        this.navigateToNotFound();
        return of(false);
      }),
    );
  }

  navigateToSignInPage(portalRoute: MainRoutes): void {
    this.router.navigateByUrl(`/${portalRoute}/${MainRoutes.SignIn}`);
  }
}
