<ng-template #appVersionModal let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">{{'NEW_APP_VERSION_AVAILABLE' | translate}}</h4>
  </div>
  <div class="modal-body">
    {{'CONFIRM_RELOAD_PAGE' | translate}}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="close(true)">
      {{'YES' | translate}}
    </button>
    <button type="button" class="btn btn-light" (click)="close(false)">
      {{'NO_LATER' | translate}}
    </button>
  </div>
</ng-template>
