import { Component, Input } from '@angular/core';
import { Control } from '../control';
import * as _ from 'lodash';
import { ControlTextWithButtonsControlConfig } from '../../../gk-dynamic-list.model';

@Component({
  selector: 'gk-control-text-with-buttons',
  templateUrl: './control-text-with-buttons.component.html',
})
export class ControlTextWithButtonsComponent extends Control<string> {
  @Input() override controlConfig: ControlTextWithButtonsControlConfig;
  override noData = '';

  getDictValueOrValue(): string | number {
    const rawValue = this.getValue();
    if (!this.controlConfig.dictConfig) {
      return rawValue;
    }
    const foundField = this.controlConfig.dictConfig.find(
      (field) => field.id === rawValue
    );

    return foundField && foundField.name ? foundField.name : this.noData;
  }
}
