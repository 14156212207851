import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ColumnHeader,
  DepartmentCode,
  DocSignService,
  EgibObject,
  FormAlertService,
  MapAction,
  MapId,
  MapObject,
  MapObjectApiType,
  MapObjectTableActionType,
  MapObjectTableState,
  MapPortalName,
  MapSettings,
  MapSettingsService,
  MapState,
  MapStateService,
  PetentData,
  Place,
  PortalId,
  PzService,
  ShippingFormName,
  Street,
  ToolType,
  defaultMapGeometryStyles,
  initialToolsStateWithButtonText,
} from '@gk/gk-modules';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import {
  EMPTY,
  Observable,
  Subject,
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs';
import { MainRoutes } from '../../../guards/guard.models';
import { AuthorizedPersonControlName } from '../../../services/authorized-person-form/authorized-person-form.model';
import { ApiNewDokumentPowiazanyDalDto } from '../../../services/designer-incoming-documents/designer-incoming-documents.model';
import { DesignerIncomingDocumentsService } from '../../../services/designer-incoming-documents/designer-incoming-documents.service';
import { DecisionFormType } from '../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { ZamowienieDzialkaDto } from '../../../services/excluding-land-from-agricultural-production/agricultural-land-use/agricultural-land-use.model';
import { FormsStateService } from '../../../services/forms-state/forms-state.service';
import { DocFile } from '../../../services/new-designer-request/new-designer-request.model';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { BsMessageType } from '../../../services/request-workspace-state/request-workspace-state.model';
import { SettingsService } from '../../../services/settings/settings.service';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import {
  FeeWaiverCause,
  FeeWaiverControlName,
  FeeWaiverForm,
} from '../../../shared/fee-waiver/fee-waiver.model';
import {
  Shipping,
  ShippingFormControlName,
} from '../../../shared/shipping-form/shipping-form.model';
import { PlanningDepartmentConfigService } from '../planning-department-config.service';
import { ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestService } from '../shared/extract-certificate-from-local-spatial-development-plan-or-study-request.service';
import { ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestFormControlName } from '../shared/extract-or-certificate-from-local-spatial-development-plan-request-form.model';
import { ExtractOrCertificateFromLocalSpatialDevelopmentPlanRequestFormService } from '../shared/extract-or-certificate-from-local-spatial-development-plan-request-form.service';
import {
  ExtractFromLocalSpatialDevelopmentPlanCalculatePaymentResponse,
  ExtractFromLocalSpatialDevelopmentPlanRequest,
  ExtractOrCertificateFromLocalSpatialDevelopmentPlanRequestDto,
} from '../shared/extract-or-certificate-from-local-spatial-development-plan-request.model';
import { PlanningDepartmentDocTypeId } from '../shared/planning-department-doc-type.model';
import { PlanningDepartmentRequestType } from '../shared/planning-department.model';

@Component({
  selector: 'app-extract-from-local-spatial-development-plan-request',
  templateUrl:
    './extract-certificate-from-local-spatial-development-plan-or-study-request.component.html',
  styles: [],
})
export class ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy
{
  override isAlive = true;
  override mapState = MapState.getInitialStruct();
  override initialToolsState = _.cloneDeep(initialToolsStateWithButtonText);
  override defaultMapGeometryStyles = _.cloneDeep(defaultMapGeometryStyles);
  override formGroup: UntypedFormGroup;
  override submitted = false;
  override controlName =
    ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestFormControlName;
  formNotValidTranslation: string;
  override decisionFormValue = DecisionFormType.ElectronicForm;
  requestSuccessfullySigned = true;
  planningDepartmentDocTypeId = PlanningDepartmentDocTypeId;
  chosenFiles: { [key in PlanningDepartmentDocTypeId]?: File[] } = {};
  override petentData: PetentData;
  planningDepartmentRequestTypeEnum = PlanningDepartmentRequestType;
  requestFormType: PlanningDepartmentRequestType;
  requestFormTitleTranslation: string;
  calculatedPrice = 0;
  calculatedPriceErrorOccurred = false;
  calculatedPricePending = false;
  calculatedPriceResponseStatus: string;
  calculatedPriceErrorResponseStatus: string;
  calculatePayment$: Subject<void> = new Subject();
  customApplicantPostOfficeModalHeaderInfoTranslation: string;
  customShippingFormPostOfficeModalHeaderInfoTranslation: string;
  override portalId =
    PortalId.PlanningDepartmentExtractFromLocalSpatialDevelopmentPlanRequest;
  quantityFormControlShouldBeReadonly: boolean;
  departmentCode = DepartmentCode;

  constructor(
    private toastr: ToastrService,
    private designerIncomingDocumentsService: DesignerIncomingDocumentsService,
    protected override pzService: PzService,
    protected override newRequestHelperService: NewRequestHelperService,
    protected override docSignService: DocSignService,
    protected override translateService: TranslateService,
    protected override mapSettingsService: MapSettingsService,
    protected override mapStateService: MapStateService,
    protected override formAlertService: FormAlertService,
    public route: ActivatedRoute,
    public extractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestService: ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestService,
    public formsStateService: FormsStateService,
    public override router: Router,
    public extractOrCertificateFromLocalSpatialDevelopmentPlanRequestFormService: ExtractOrCertificateFromLocalSpatialDevelopmentPlanRequestFormService,
    public settingsService: SettingsService,
    public planningDepartmentConfigService: PlanningDepartmentConfigService,
  ) {
    super(
      pzService,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService,
      formAlertService,
    );
  }

  override ngOnInit(): void {
    this.createForm();
    this.setTypeOfRequest();
    this.setFormSubjectForCertificateFromSpatialDevelopmentPlan();
    this.subscribeToShippingFormControlValueChanges();
    this.subscribeToQuantityFormControlValueChanges();
    this.subscribeToSubjectFormGroupValueChanges();
    this.subscribeToFeeWaiverFormGroupValueChanges();
    super.subscribeToDocSignTranslations();
    this.subscribeToFormNotValidTranslation();
    this.subscribeToApplicantDetailsCustomPostOfficeModalHeaderInfoTranslation();
    this.subscribeToShippingFormCustomPostOfficeModalHeaderInfoTranslation();
    this.subscribeToRequestFormTitleTranslation();
    this.subscribeToMapSettings();
    this.subscribeToPetentData();
    this.subscribeToCalculatePayment();
    this.fetchWrongFileText();
  }

  createForm(): void {
    this.formGroup =
      this.extractOrCertificateFromLocalSpatialDevelopmentPlanRequestFormService.getFormGroup();
  }

  setTypeOfRequest(): void {
    if (
      this.router.url.includes(
        MainRoutes.PlanningDepartmentExtractFromLocalSpatialDevelopmentPlan,
      )
    ) {
      this.requestFormType =
        PlanningDepartmentRequestType.ExtractFromLocalSpatialDevelopmentPlan;
    } else if (
      this.router.url.includes(MainRoutes.PlanningDepartmentExtractFromStudy)
    ) {
      this.requestFormType = PlanningDepartmentRequestType.ExtractFromStudy;
    } else {
      this.requestFormType = PlanningDepartmentRequestType.Certificate;
    }
  }

  setFormSubjectForCertificateFromSpatialDevelopmentPlan(): void {
    if (
      this.router.url.includes(
        MainRoutes.PlanningDepartmentCertificateFromLocalSpatialDevelopmentPlan,
      )
    ) {
      this.getSubjectFormGroup().patchValue({
        [ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestFormControlName.WrittenExtractCheckbox]:
          false,
        [ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestFormControlName.GraphicExtractCheckbox]:
          false,
        [ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestFormControlName.CertificateCheckbox]:
          true,
      });
    }
  }

  subscribeToShippingFormControlValueChanges(): void {
    this.getShippingFormTypeRadioFormControl()
      .valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe((value) => {
        if (value.emailRequired) {
          this.getQuantityFormControl().setValue(1);
        }
        if (value.name === ShippingFormName.SelfPickup) {
          this.quantityFormControlShouldBeReadonly = false;
          this.getAuthorizedDetailsCheckboxFormControl().enable();
        } else {
          this.quantityFormControlShouldBeReadonly = true;
          this.getQuantityFormControl().setValue(1);
          this.getAuthorizedDetailsCheckboxFormControl().disable();
          this.getAuthorizedDetailsCheckboxFormControl().reset();
        }
        this.maybeTriggerCalculatePayment();
      });
  }

  subscribeToQuantityFormControlValueChanges(): void {
    this.getQuantityFormControl()
      .valueChanges.pipe(
        takeWhile(() => this.isAlive),
        distinctUntilChanged(),
      )
      .subscribe(() => this.maybeTriggerCalculatePayment());
  }

  subscribeToSubjectFormGroupValueChanges(): void {
    this.getSubjectFormGroup()
      .valueChanges.pipe(
        takeWhile(() => this.isAlive),
        distinctUntilChanged(),
      )
      .subscribe(() => this.maybeTriggerCalculatePayment());
  }

  subscribeToFeeWaiverFormGroupValueChanges(): void {
    this.getFeeWaiverFormGroup()
      .valueChanges.pipe(
        takeWhile(() => this.isAlive),
        distinctUntilChanged(),
      )
      .subscribe(() => {
        this.maybeTriggerCalculatePayment();
        this.maybeDisableDismissalReasonExplanationDescriptionFormControl();
      });
  }

  maybeDisableDismissalReasonExplanationDescriptionFormControl(): void {
    const dismissalReasonExplanationDescriptionFormControl =
      this.getDismissalReasonExplanationDescriptionFormControl();
    if (this.isDismissalReasonExplanationVisible()) {
      dismissalReasonExplanationDescriptionFormControl.enable();
    } else {
      dismissalReasonExplanationDescriptionFormControl.disable();
    }
  }

  subscribeToFormNotValidTranslation(): void {
    this.translateService
      .get('PLANNING_DEPARTMENT.VALIDATION.FORM_NOT_VALID_ERROR')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (formNotValidTranslation) =>
          (this.formNotValidTranslation = formNotValidTranslation),
      );
  }

  subscribeToApplicantDetailsCustomPostOfficeModalHeaderInfoTranslation(): void {
    this.translateService
      .get(
        'PLANNING_DEPARTMENT.EXTRACT_CERTIFICATE_FROM_LOCAL_SPATIAL_DEVELOPMENT_PLAN_OR_STUDY_REQUEST.SECTIONS.APPLICANT_DETAILS.CUSTOM_POST_OFFICE_MODAL_HEADER_INFO',
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (customApplicantPostOfficeModalHeaderInfoTranslation) =>
          (this.customApplicantPostOfficeModalHeaderInfoTranslation =
            customApplicantPostOfficeModalHeaderInfoTranslation),
      );
  }

  subscribeToShippingFormCustomPostOfficeModalHeaderInfoTranslation(): void {
    this.translateService
      .get('SHIPPING_CUSTOM_POST_OFFICE_MODAL_HEADER_INFO')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (customShippingFormPostOfficeModalHeaderInfoTranslation) =>
          (this.customShippingFormPostOfficeModalHeaderInfoTranslation =
            customShippingFormPostOfficeModalHeaderInfoTranslation),
      );
  }

  subscribeToRequestFormTitleTranslation(): void {
    this.translateService
      .get(this.getFormTitleTranslationKeyByRequestFromType())
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (requestFormTitleTranslation) =>
          (this.requestFormTitleTranslation = requestFormTitleTranslation),
      );
  }

  getFormTitleTranslationKeyByRequestFromType(): string {
    switch (this.requestFormType) {
      case PlanningDepartmentRequestType.ExtractFromLocalSpatialDevelopmentPlan:
        return 'PLANNING_DEPARTMENT.EXTRACT_CERTIFICATE_FROM_LOCAL_SPATIAL_DEVELOPMENT_PLAN_OR_STUDY_REQUEST.EXTRACT_FROM_LOCAL_SPATIAL_DEVELOPMENT_PLAN_TITLE';
      case PlanningDepartmentRequestType.Certificate:
        return 'PLANNING_DEPARTMENT.EXTRACT_CERTIFICATE_FROM_LOCAL_SPATIAL_DEVELOPMENT_PLAN_OR_STUDY_REQUEST.CERTIFICATE_TITLE';
      case PlanningDepartmentRequestType.ExtractFromStudy:
        return 'PLANNING_DEPARTMENT.EXTRACT_CERTIFICATE_FROM_LOCAL_SPATIAL_DEVELOPMENT_PLAN_OR_STUDY_REQUEST.EXTRACT_FROM_STUDY';
    }
  }

  subscribeToMapSettings(): void {
    this.mapSettingsService
      .getMapSettings(MapPortalName.Planning)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((planningMapSettings) =>
        this.setMapState(planningMapSettings),
      );
  }

  override subscribeToPetentData(): void {
    this.pzService
      .getPetentData()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((petentData) => (this.petentData = petentData));
  }

  subscribeToCalculatePayment(): void {
    this.calculatePayment$
      .pipe(
        tap(() => this.resetPaymentStatusViewData()),
        debounceTime(500),
        filter(() => this.isRequestForCalculatePaymentValid()),
        tap(() => (this.calculatedPricePending = true)),
        switchMap(() =>
          this.extractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestService
            .calculatePayment(this.getFormValue())
            .pipe(
              catchError((err) =>
                this.handleCalculatePaymentErrorResponse(err),
              ),
            ),
        ),
      )
      .subscribe((response) =>
        this.handleCalculatePaymentSuccessResponse(
          response as ExtractFromLocalSpatialDevelopmentPlanCalculatePaymentResponse,
        ),
      );
  }

  resetPaymentStatusViewData(): void {
    this.calculatedPrice = 0;
    this.calculatedPriceErrorOccurred = false;
    this.calculatedPriceResponseStatus = '';
  }

  handleCalculatePaymentErrorResponse(err: HttpErrorResponse): Observable<any> {
    this.calculatedPriceErrorResponseStatus =
      this.getResponseStatusMessage(err);
    this.calculatedPrice = 0;
    this.calculatedPriceErrorOccurred = true;
    this.calculatedPricePending = false;

    return EMPTY;
  }

  handleCalculatePaymentSuccessResponse(
    response: ExtractFromLocalSpatialDevelopmentPlanCalculatePaymentResponse,
  ): void {
    this.calculatedPrice = response.amount;
    this.calculatedPriceResponseStatus = response.status;
    this.calculatedPriceErrorOccurred = false;
    this.calculatedPricePending = false;
  }

  override setMapState(mapSettings: MapSettings): void {
    this.mapState = new MapState(
      MapId.PlanningDepartmentExtractFromLocalSpatialDevelopmentPlan,
      this.mapStateService.getViewState(
        MapId.PlanningDepartmentExtractFromLocalSpatialDevelopmentPlan,
        mapSettings,
      ),
      this.mapStateService.getToolbarState(
        [ToolType.LandParcel],
        mapSettings.papers,
        undefined,
        undefined,
        true,
      ),
      this.initialToolsState,
      this.mapStateService.getLayersState(
        MapId.PlanningDepartmentExtractFromLocalSpatialDevelopmentPlan,
        mapSettings,
        MapPortalName.Planning,
      ),
      [
        new MapObjectTableState(
          [
            new ColumnHeader(
              'mapObjectNumber',
              'PARCEL_SEARCH_FORM.PARCEL_NUMBER',
            ),
            new ColumnHeader('districtName', 'PARCEL_SEARCH_FORM.DISTRICT'),
            new ColumnHeader('mapSheet', 'PARCEL_SEARCH_FORM.SHEET'),
          ],
          undefined,
          this.defaultMapGeometryStyles,
          true,
          false,
          true,
          '25',
          this.formsStateService.newDesignerRequestChosenParcels,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          [MapObjectApiType.LandParcel],
          true,
        ),
      ],
    );
  }

  override handleMapAction(mapAction: MapAction): void {
    this.mapState = this.mapStateService.getUpdatedMapState(
      mapAction,
      this.mapState,
    );
    if (
      mapAction.type === MapObjectTableActionType.AddToExisting ||
      mapAction.type === MapObjectTableActionType.Remove ||
      mapAction.type === MapObjectTableActionType.RemoveAll
    ) {
      this.maybeTriggerCalculatePayment();
    }
  }

  maybeTriggerCalculatePayment(): void {
    this.calculatePayment$.next();
  }

  getResponseStatusMessage(error: HttpErrorResponse): string {
    return _.get(error, 'error.ResponseStatus.Message', '');
  }

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    if (!this.isRequestValid()) {
      this.toastr.error(this.formNotValidTranslation);
      return;
    }

    const apiFiles = await Promise.all(this.getConvertedFiles()).catch(
      () => new Error(),
    );
    if (
      (Array.isArray(apiFiles) &&
        apiFiles.some((file) => file instanceof Error)) ||
      apiFiles instanceof Error
    ) {
      this.toastr.error(this.wrongFilesErrorText);

      return;
    }

    this.sendRequest(apiFiles as ApiNewDokumentPowiazanyDalDto[]);
  }

  getConvertedFiles(): Promise<ApiNewDokumentPowiazanyDalDto | Error>[] {
    return this.getChosenFiles().map((docFile) =>
      this.designerIncomingDocumentsService.docFileToDtoDocument(docFile),
    );
  }

  getChosenFiles(): DocFile[] {
    return [].concat(...Object.values(this.chosenFiles));
  }

  isRequestForCalculatePaymentValid(): boolean {
    return (
      this.getSubjectFormGroup().valid &&
      this.areParcelsChosen() &&
      this.getQuantityFormControl().valid
    );
  }

  isRequestValid(): boolean {
    return (
      this.formGroup.valid &&
      this.areParcelsChosen() &&
      this.checkAttachmentsShouldNotBeValidated() &&
      this.isCalculatedPaymentValid()
    );
  }

  checkParcelsNotChosenAndFormSubmitted(): boolean {
    return !this.areParcelsChosen() && this.submitted;
  }

  areParcelsChosen(): boolean {
    return this.mapState.mapObjectTablesState[0].mapObjects.some(
      (mapObject) => mapObject.type === MapObjectApiType.LandParcel,
    );
  }

  checkAttachmentsShouldNotBeValidated(): boolean {
    const feeWaiverFormGroupValue = this.getFeeWaiverFormGroupValue();

    if (!feeWaiverFormGroupValue.feeWaiverCheckbox) {
      return true;
    }

    switch (feeWaiverFormGroupValue.feeWaiverCause) {
      case FeeWaiverCause.SocialAssistanceBecauseOfPoverty:
        return !!this.chosenFiles[
          PlanningDepartmentDocTypeId.SocialWelfareCertificate
        ];
      case FeeWaiverCause.Other:
        return !!this.chosenFiles[
          PlanningDepartmentDocTypeId.DismissalReasonExplanation
        ];
      default:
        return true;
    }
  }

  isCalculatedPaymentInValidAndFormSubmitted(): boolean {
    return !this.isCalculatedPaymentValid() && this.submitted;
  }

  isCalculatedPaymentValid(): boolean {
    return !this.calculatedPriceErrorOccurred;
  }

  isSocialWelfareCertificateRequiredAndFormSubmitted(): boolean {
    const feeWaiverFormGroupValue = this.getFeeWaiverFormGroupValue();

    return (
      feeWaiverFormGroupValue.feeWaiverCheckbox &&
      !this.chosenFiles[PlanningDepartmentDocTypeId.SocialWelfareCertificate] &&
      this.submitted
    );
  }

  isDismissalReasonExplanationRequiredAndFormSubmitted(): boolean {
    const feeWaiverFormGroupValue = this.getFeeWaiverFormGroupValue();
    const dismissalReasonExplanationDescriptionFormControl =
      this.getDismissalReasonExplanationDescriptionFormControl();

    return (
      this.submitted &&
      ((feeWaiverFormGroupValue.feeWaiverCheckbox &&
        !this.chosenFiles[
          PlanningDepartmentDocTypeId.DismissalReasonExplanation
        ]) ||
        dismissalReasonExplanationDescriptionFormControl.invalid)
    );
  }

  sendRequest(apiDocuments: ApiNewDokumentPowiazanyDalDto[]): void {
    this.docSignPending = true;
    this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');

    this.docSignService
      .addToSign(
        ExtractOrCertificateFromLocalSpatialDevelopmentPlanRequestDto.fromAppToApi(
          this.getFormValue(),
          apiDocuments,
        ),
        this.getAddToSignUrlByRequestFromType(),
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleSendAndValidateSuccess(addedDocToSignResponse);
        },
        error: (err) => {
          console.error(err);
          this.handleSendAndValidateFailure();
        },
      });
  }

  getAddToSignUrlByRequestFromType(): string {
    switch (this.requestFormType) {
      case PlanningDepartmentRequestType.ExtractFromLocalSpatialDevelopmentPlan:
        return '/api/interesant/wniosek/wypisWyrysZPlanuMiejscowego/addToSign';
      case PlanningDepartmentRequestType.Certificate:
        return '/api/interesant/wniosek/zaswiadczenieZPlanuMiejscowego/addToSign';
      case PlanningDepartmentRequestType.ExtractFromStudy:
        return '/api/interesant/wniosek/wypisWyrysZeStudiumUwarunkowan/addToSign';
    }
  }

  getFormValue(): ExtractFromLocalSpatialDevelopmentPlanRequest {
    return {
      ...this.formGroup.value,
      landParcels: this.getParcelsMapObjects(),
      requestFormType: this.requestFormType,
    };
  }

  getParcelsMapObjects(): ZamowienieDzialkaDto[] {
    return this.mapState.mapObjectTablesState[0].mapObjects
      .filter(
        <(mapObject: MapObject) => mapObject is EgibObject>(
          ((mapObject) => mapObject.type === MapObjectApiType.LandParcel)
        ),
      )
      .map((data) => ZamowienieDzialkaDto.fromAppToApi(data));
  }

  override handleDocSignSuccess(): void {
    this.requestSuccessfullySigned = true;
    this.docSignUrl = '';
    this.router.navigate([`../requests-list`], { relativeTo: this.route });
  }

  isShippingFormGroupInvalidAndFormSubmitted(): boolean {
    return this.getShippingFormGroup().invalid && this.submitted;
  }

  isQuantityFormControlInvalidAndFormSubmitted(): boolean {
    return this.getQuantityFormControl().invalid && this.submitted;
  }

  getShippingFormGroupValue(): Shipping {
    return this.getShippingFormGroup().value;
  }

  getAuthorizedDetailsFormGroup(): UntypedFormGroup {
    return this.getFormControlByName(
      AuthorizedPersonControlName.AuthorizedDetailsForm,
    ) as UntypedFormGroup;
  }

  getAuthorizedDetailsCheckboxFormControl(): AbstractControl {
    return this.getAuthorizedDetailsFormGroup().get(
      AuthorizedPersonControlName.AuthorizedDetailsCheckbox,
    );
  }

  getRodoClauseAgreeCheckboxFormControl(): UntypedFormControl {
    return this.getFormControlByName(
      this.controlName.RodoClauseAgreeCheckbox,
    ) as UntypedFormControl;
  }

  getFeeWaiverFormGroup(): UntypedFormGroup {
    return this.getFormControlByName(
      FeeWaiverControlName.FeeWaiverForm,
    ) as UntypedFormGroup;
  }

  isInvestorDetailsFormGroupInvalidAndFormSubmitted(): boolean {
    return this.getInvestorDetailsFormGroup().invalid && this.submitted;
  }

  getInvestorDetailsFormGroup(): UntypedFormGroup {
    return this.getFormControlByName(
      this.controlName.InvestorDetails,
    ) as UntypedFormGroup;
  }

  getFeeWaiverFormGroupValue(): FeeWaiverForm {
    return this.getFeeWaiverFormGroup().value;
  }

  isSubjectFormGroupInvalidAndFormSubmitted(): boolean {
    return this.getSubjectFormGroup().invalid && this.submitted;
  }

  getSubjectFormGroup(): UntypedFormGroup {
    return this.getFormControlByName(
      this.controlName.Subject,
    ) as UntypedFormGroup;
  }

  isDescriptionFormControlInvalidAndFormSubmitted(): boolean {
    return this.getDescriptionFormControl().invalid && this.submitted;
  }

  getDismissalReasonExplanationDescriptionFormControl(): UntypedFormControl {
    return this.getFormControlByName(
      this.controlName.DismissalReasonExplanationDescription,
    ) as UntypedFormControl;
  }

  getDescriptionFormControl(): UntypedFormControl {
    return this.getFormControlByName(
      this.controlName.Comments,
    ) as UntypedFormControl;
  }

  getQuantityFormControl(): UntypedFormControl {
    return this.getFormControlByName(
      this.controlName.Quantity,
    ) as UntypedFormControl;
  }

  getShippingFormTypeRadioFormControl(): AbstractControl {
    return this.getShippingFormGroup().get(
      ShippingFormControlName.ShippingForm,
    );
  }

  getFormControlByName(formControlName: string): AbstractControl {
    return this.formGroup.get(formControlName);
  }

  shouldShowAtLeastOneRequiredAlert(formControl: AbstractControl): boolean {
    return this.formAlertService.shouldShowErrorAlert(
      formControl,
      'atLeastOneRequired',
      this.submitted,
    );
  }

  isSocialWelfareCertificateVisible(): boolean {
    const feeWaiverFormGroupValue = this.getFeeWaiverFormGroupValue();

    return (
      feeWaiverFormGroupValue.feeWaiverCheckbox &&
      feeWaiverFormGroupValue.feeWaiverCause ===
        FeeWaiverCause.SocialAssistanceBecauseOfPoverty
    );
  }

  isDismissalReasonExplanationVisible(): boolean {
    const feeWaiverFormGroupValue = this.getFeeWaiverFormGroupValue();

    return (
      feeWaiverFormGroupValue.feeWaiverCheckbox &&
      feeWaiverFormGroupValue.feeWaiverCause === FeeWaiverCause.Other
    );
  }

  handleFileInputAction(
    id: PlanningDepartmentDocTypeId,
    files: DocFile[],
  ): void {
    this.chosenFiles[id] = this.getFiles(id, files);
  }

  getFiles(id: PlanningDepartmentDocTypeId, files: DocFile[]): File[] {
    return files.map((file) => {
      file.docTypeId = id;

      return file;
    });
  }

  copyShippingDataFromPz($event: MouseEvent): void {
    $event.preventDefault();

    this.getShippingFormGroup().patchValue({
      nameSurname: `${this.petentData.firstname} ${this.petentData.lastname}`,
      address: {
        place: new Place(this.petentData.placeId, this.petentData.place, true),
        street: new Street(this.petentData.streetId, this.petentData.street),
        postalCode: this.petentData.postalCode,
        buildingNumber: this.petentData.buildingNumber,
        localNumber: this.petentData.localNumber,
        streetAbsence: !this.petentData.street,
      },
    });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
