import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {
  DefaultConfigGuest,
  DefaultConfigGuestFromApi,
} from './default-config-guest.model';

@Injectable()
export class DefaultConfigGuestService {
  private defaultConfigGuest: Observable<DefaultConfigGuest>;
  private httpClient: HttpClient;
  constructor(handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  getConfig(): Observable<DefaultConfigGuest> {
    if (!this.defaultConfigGuest) {
      this.defaultConfigGuest = this.httpClient
        .get<DefaultConfigGuestFromApi>('/api/system/web/default/konfig/guest')
        .pipe(
          map((data) => DefaultConfigGuest.fromApiToApp(data)),
          shareReplay(1)
        );
    }

    return this.defaultConfigGuest;
  }
}
