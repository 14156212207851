@if (doesUserHavePermissionToControl() | async) {
  @switch (fieldConfig.type) {
    @case (fieldConfigType.SectionTitle) {
      <gk-form-section-title
        [config]="$any(fieldConfig)"
        [group]="formGroup"
        [submitted]="submitted"
      ></gk-form-section-title>
    }
    @case (fieldConfigType.NestedGroup) {
      <gk-form-nested-group
        [config]="$any(fieldConfig)"
        [group]="formGroup"
        [submitted]="submitted"
      ></gk-form-nested-group>
    }
    @case (fieldConfigType.CheckboxGroupRepresentative) {
      <gk-form-checkbox-group-representative
        [fullFormConfig]="fullFormConfig"
        [config]="$any(fieldConfig)"
        [group]="formGroup"
        [submitted]="submitted"
      ></gk-form-checkbox-group-representative>
    }
    @case (fieldConfigType.Checkbox) {
      <gk-form-checkbox
        [config]="$any(fieldConfig)"
        [group]="formGroup"
        [submitted]="submitted"
      ></gk-form-checkbox>
    }
    @case (fieldConfigType.Datepicker) {
      <gk-form-datepicker
        [config]="$any(fieldConfig)"
        [group]="formGroup"
        [submitted]="submitted"
      ></gk-form-datepicker>
    }
    @case (fieldConfigType.Input) {
      <gk-form-input
        [config]="$any(fieldConfig)"
        [group]="formGroup"
        [submitted]="submitted"
      ></gk-form-input>
    }
    @case (fieldConfigType.LandParcelNumber) {
      <gk-form-land-parcel-number
        [config]="$any(fieldConfig)"
        [group]="formGroup"
        [submitted]="submitted"
      ></gk-form-land-parcel-number>
    }
    @case (fieldConfigType.RadioOption) {
      <gk-form-radio-option
        [config]="$any(fieldConfig)"
        [group]="formGroup"
        [submitted]="submitted"
      ></gk-form-radio-option>
    }
    @case (fieldConfigType.CommunitySelect) {
      <gk-form-community-select
        [config]="$any(fieldConfig)"
        [group]="formGroup"
        [submitted]="submitted"
      ></gk-form-community-select>
    }
    @case (fieldConfigType.DistrictSelect) {
      <gk-form-district-select
        [config]="$any(fieldConfig)"
        [group]="formGroup"
        [submitted]="submitted"
      ></gk-form-district-select>
    }
    @case (fieldConfigType.SheetSelect) {
      <gk-form-sheet-select
        [config]="$any(fieldConfig)"
        [group]="formGroup"
        [submitted]="submitted"
      ></gk-form-sheet-select>
    }
    @case (fieldConfigType.OrthophotomapSelect) {
      <gk-form-orthophotomap-select
        [config]="$any(fieldConfig)"
        [group]="formGroup"
        [submitted]="submitted"
      ></gk-form-orthophotomap-select>
    }
    @case (fieldConfigType.BasicSelect) {
      <gk-form-basic-select
        [config]="$any(fieldConfig)"
        [group]="formGroup"
        [submitted]="submitted"
      ></gk-form-basic-select>
    }
    @case (fieldConfigType.Section) {
      <gk-form-section
        [config]="$any(fieldConfig)"
        [group]="formGroup"
        [submitted]="submitted"
      ></gk-form-section>
    }
    @case (fieldConfigType.ControlNetwork) {
      <gk-form-control-network
        [config]="$any(fieldConfig)"
        [group]="formGroup"
        [submitted]="submitted"
      ></gk-form-control-network>
    }
    @case (fieldConfigType.CoordinateSystem) {
      <gk-form-coordinate-system
        [config]="$any(fieldConfig)"
        [group]="formGroup"
        [submitted]="submitted"
      ></gk-form-coordinate-system>
    }
    @case (fieldConfigType.LandAndMortgageRegisterDepartmentSelect) {
      <gk-land-and-mortgage-register-department-select
        [config]="$any(fieldConfig)"
        [group]="formGroup"
        [submitted]="submitted"
      >
      </gk-land-and-mortgage-register-department-select>
    }
    @case (fieldConfigType.PlacesTypeahead) {
      <gk-form-places-typeahead
        [config]="$any(fieldConfig)"
        [group]="formGroup"
        [submitted]="submitted"
      ></gk-form-places-typeahead>
    }
    @case (fieldConfigType.StreetsTypeahead) {
      <gk-form-streets-typeahead
        [config]="$any(fieldConfig)"
        [group]="formGroup"
        [submitted]="submitted"
      ></gk-form-streets-typeahead>
    }
    @case (fieldConfigType.Separator) {
      <gk-form-separator
        [config]="fieldConfig"
        [group]="formGroup"
        [submitted]="submitted"
      ></gk-form-separator>
    }
  }
}
