import { containsExtent, Extent } from 'ol/extent';
import {
  ProjectionCode,
  StorageSuffix,
  TreeNode,
  TreeNodeLiteral,
} from '../../models';
import { StorageUtils } from '../storage/storage.utils';

export class MapDataStorageUtils {
  static getProjectionCode(epsg: number): ProjectionCode | void {
    const projectionCode = Object.values(ProjectionCode).find((projection) =>
      projection.includes(epsg.toString())
    ) as ProjectionCode;

    if (projectionCode) {
      return projectionCode;
    } else {
      console.error('Provided projection has not specified definition.');
    }
  }

  static getMapExtent(mapId: string, fullMapExtent: Extent): Extent {
    const previousMapExtent = StorageUtils.getItem(
      this.getStorageKeyName(mapId, StorageSuffix.MapExtent)
    ) as Extent;

    return this.isPreviousMapExtentContainedInFullMapExtent(
      fullMapExtent,
      previousMapExtent
    )
      ? previousMapExtent
      : fullMapExtent;
  }

  static isPreviousMapExtentContainedInFullMapExtent(
    fullMapExtent: Extent,
    previousMapExtent: Extent
  ) {
    return (
      !!previousMapExtent && containsExtent(fullMapExtent, previousMapExtent)
    );
  }

  static getResolution(mapId: string): number {
    const previousResolution = StorageUtils.getItem(
      this.getStorageKeyName(mapId, StorageSuffix.Resolution)
    );

    return previousResolution || undefined;
  }

  static getLayersTree(mapId: string, layersGroups: TreeNode[]): TreeNode {
    const initialLayersTree = TreeNode.buildRootNode(layersGroups);
    const currentLayersTreeLiteral = StorageUtils.getItem(
      this.getStorageKeyName(mapId, StorageSuffix.LayersTree)
    ) as TreeNodeLiteral;

    if (!currentLayersTreeLiteral) {
      return initialLayersTree;
    }

    const currentLayersTreeInstance = TreeNode.literalToInstance(
      currentLayersTreeLiteral
    );

    return TreeNode.getUpdatedProperTreeNode(
      currentLayersTreeInstance,
      initialLayersTree
    );
  }

  static getStorageKeyName(id: string, subject: StorageSuffix): string {
    return `${id}-${subject}`;
  }

  static setValue(id: string, subject: StorageSuffix, value: any): void {
    StorageUtils.setItem(this.getStorageKeyName(id, subject), value);
  }
}
