@if (logoImageTextNavItemConfig.imgSrc) {
  <img
    [src]="logoImageTextNavItemConfig.imgSrc"
    [alt]="logoImageTextNavItemConfig.imgAlt | translate"
    />
}
<h1
  [ngClass]="[getBgClass(), getTextColor()]"
  class="d-flex flex-column"
  [ngClass]="getTextColor()"
  >
  <span>{{ logoImageTextNavItemConfig.textPart1 | translate }}</span>
  @if (logoImageTextNavItemConfig.textPart2) {
    <span>{{
      logoImageTextNavItemConfig.textPart2 | translate
    }}</span>
  }
</h1>
