import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainRoutes } from '../../guards/guard.models';
import { SessionGuard } from '../../guards/session/session.guard';
import { MainRequestsListComponent } from '../../shared/main-requests-list/main-requests-list.component';
import { EnvironmentalProtectionPortalComponent } from './environmental-protection-portal.component';
import { FishingCardRequestComponent } from './fishing-card-request/fishing-card-request.component';
import { SimplifiedForestManagementPlanRequestComponent } from './simplified-forest-management-plan-request/simplified-forest-management-plan-request.component';
import { TreesAndShrubsFellingRequestComponent } from './trees-and-shrubs-felling-request/trees-and-shrubs-felling-request.component';
import { WaterEquipmentRequestComponent } from './water-equipmernt-request/water-equipment-request.component';

const environmentalProtectionPortalRoutes: Routes = [
  {
    path: MainRoutes.EnvironmentalProtectionPortalFishingCardRequest,
    component: EnvironmentalProtectionPortalComponent,
    canActivate: [SessionGuard],
    children: [
      {
        path: '',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
      {
        path: 'new-request',
        component: FishingCardRequestComponent,
      },
      {
        path: 'requests-list',
        component: MainRequestsListComponent,
      },
      {
        path: '**',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: MainRoutes.EnvironmentalProtectionPortalSimplifiedForestManagementPlanRequest,
    component: EnvironmentalProtectionPortalComponent,
    canActivate: [SessionGuard],
    children: [
      {
        path: '',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
      {
        path: 'new-request',
        component: SimplifiedForestManagementPlanRequestComponent,
      },
      {
        path: 'requests-list',
        component: MainRequestsListComponent,
      },
      {
        path: '**',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: MainRoutes.EnvironmentalProtectionPortalWaterEquipmentRequest,
    component: EnvironmentalProtectionPortalComponent,
    canActivate: [SessionGuard],
    children: [
      {
        path: '',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
      {
        path: 'new-request',
        component: WaterEquipmentRequestComponent,
      },
      {
        path: 'requests-list',
        component: MainRequestsListComponent,
      },
      {
        path: '**',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: MainRoutes.EnvironmentalProtectionPortalTreesAndShrubsFellingRequest,
    component: EnvironmentalProtectionPortalComponent,
    canActivate: [SessionGuard],
    children: [
      {
        path: '',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
      {
        path: 'new-request',
        component: TreesAndShrubsFellingRequestComponent,
      },
      {
        path: 'requests-list',
        component: MainRequestsListComponent,
      },
      {
        path: '**',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(environmentalProtectionPortalRoutes)],
  exports: [RouterModule],
})
export class EnvironmentalProtectionPortalRoutingModule {}
