import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, forkJoin, map } from 'rxjs';
import { ApiResponse } from '../../../services';
import { DictionaryConfig } from '../../gk-dynamic-list.model';
import { Dictionary, DictionaryField, FieldId } from './dictionary.model';

@Injectable()
export class DictionaryService {
  dictionaries = new BehaviorSubject<Dictionary[]>([]);

  constructor(private httpClient: HttpClient) {}

  private maybeSetOptionalRequestParams(lang: string, requestParams: any) {
    return requestParams ? requestParams : { lang };
  }

  fetchDictsForConfigs(configs: DictionaryConfig[], lang = 'pl'): void {
    const dictsGetRequests = configs.map((config) =>
      this.httpClient.get<ApiResponse<DictionaryField[]>>(config.url, {
        params: this.maybeSetOptionalRequestParams(lang, config.requestParams),
      })
    );
    forkJoin(dictsGetRequests)
      .pipe(
        map((results) =>
          results.map((result, i) =>
            _.get(result, configs[i].pathToDataFromResponse, result)
          )
        ),
        map((apiDicts) =>
          apiDicts.map((dictFromApi, i) =>
            Dictionary.fromApiToApp(configs[i], dictFromApi as any)
          )
        )
      )
      .subscribe((dictionaries) => this.dictionaries.next(dictionaries));
  }

  getDictionary(dictType: string): Dictionary {
    return (
      _.find(this.dictionaries.getValue(), { dictType }) || ({} as Dictionary)
    );
  }

  getFieldNameById(dictType: string, fieldId: FieldId): string {
    const dict = this.getDictionary(dictType);
    const fields = _.get(dict, 'fields', []) as DictionaryField[];
    const field = _.find(fields, (fld) => fld.id === fieldId);
    const fieldName = _.get(field, 'name', '');

    return dict && !_.isEmpty(fields) ? fieldName : '';
  }

  getDictName(dictType: string): string {
    return this.getDictionary(dictType).dictName;
  }
}
