import { AbstractControl } from '@angular/forms';

const isPhoneValid = (maybePhone: string): boolean => {
  const regExp = new RegExp(/[ 0-9-+()]{7,18}/);

  return regExp.test(maybePhone);
};

export function ValidatePhone(control: AbstractControl) {
  return isPhoneValid(control.value) ? null : { validPhone: true };
}
