import { FormControl } from '@angular/forms';
import { FieldId, LawPerson } from '@gk/gk-modules';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ECSControlName } from '../../../../components/electronic-correspondence-statement/models/form/form.model';
import { DecisionFormType } from '../../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';

export enum SFBCCNewRequestControlName {
  LawPerson = 'lawPerson',
  DecisionForm = 'decisionForm',
  ShippingForm = 'shippingForm',
  ConstructionIntentionType = 'constructionIntentionType',
  PlannedStartDate = 'plannedStartDate',
}

export interface SFBCCNewRequestFormGroup {
  [SFBCCNewRequestControlName.LawPerson]: FormControl<LawPerson>;
  [SFBCCNewRequestControlName.DecisionForm]: FormControl<DecisionFormType>;
  [SFBCCNewRequestControlName.ShippingForm]: FormControl<Shipping>;
  [SFBCCNewRequestControlName.ConstructionIntentionType]: FormControl<
    FieldId[]
  >;
  [SFBCCNewRequestControlName.PlannedStartDate]: FormControl<NgbDate>;
  [ECSControlName.ElectronicCorrespondenceStatement]: FormControl<boolean>;
}
