import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GkComponentsModule } from '@gk/gk-modules';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OfficeDepartmentsAccessGuard } from '../../guards/office-departments-access-guard/office-departments-access.guard';
import { SharedModule } from '../../shared/shared.module';
import { DepartmentsComponent } from './departments/departments.component';
import { GeodesyComponent } from './departments/geodesy/geodesy.component';
import { DocumentVerificationFormWrapperComponent } from './departments/geodesy/modal-document-verification/forms/document-verification-form-wrapper/document-verification-form-wrapper.component';
import { DocumentVerificationFormComponent } from './departments/geodesy/modal-document-verification/forms/document-verification-form/document-verification-form.component';
import { DocumentVerificationFormService } from './departments/geodesy/modal-document-verification/forms/document-verification-form/document-verification.form.service';
import { ModalDocumentVerificationComponent } from './departments/geodesy/modal-document-verification/modal-document-verification.component';
import { ModalRequestsListComponent } from './departments/geodesy/modal-requests-list/modal-requests-list.component';
import { OfficeDepartmentsPortalRoutingModule } from './office-departments-portal-routing.module';
import { OfficeDepartmentsPortalComponent } from './office-departments-portal.component';
import { RequestsListComponent } from './requests-list/requests-list.component';

@NgModule({
  imports: [
    CommonModule,
    OfficeDepartmentsPortalRoutingModule,
    NgbModule,
    SharedModule,
    GkComponentsModule,
  ],
  providers: [DocumentVerificationFormService, OfficeDepartmentsAccessGuard],
  declarations: [
    OfficeDepartmentsPortalComponent,
    DepartmentsComponent,
    RequestsListComponent,
    GeodesyComponent,
    ModalRequestsListComponent,
    ModalDocumentVerificationComponent,
    DocumentVerificationFormComponent,
    DocumentVerificationFormWrapperComponent,
  ],
})
export class OfficeDepartmentsPortalModule {}
