export enum PremiseIndependenceNotificationControlName {
  ObjectNameAndType = 'objectNameAndType',
  ProxyDetailsCheckbox = 'proxyDetailsCheckbox',
  ProxyDetails = 'proxyDetails',
  InvestorDetails = 'investorDetails',
  DecisionForm = 'decisionForm',
  ShippingForm = 'shippingForm',
  Place = 'place',
  Street = 'street',
  StreetAbsence = 'streetAbsence',
  BuildingNumber = 'buildingNumber',
  LocalNumber = 'localNumber',
  PostalCode = 'postalCode',
  PostOffice = 'postOffice'
}
