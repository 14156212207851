import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { HubSettings, HubSettingsFromApi } from './settings.model';

@Injectable()
export class SettingsService {
  private settings: Observable<HubSettings>;

  constructor(private httpClient: HttpClient) {}

  getHubSettings(): Observable<HubSettings> {
    if (!this.settings) {
      this.settings = this.httpClient
        .get<HubSettingsFromApi>('/api/interesant/hub/settings')
        .pipe(
          map(hubSettingsFromApi => HubSettings.fromApiToApp(hubSettingsFromApi)),
          shareReplay(1)
        );
    }

    return this.settings;
  }
}
