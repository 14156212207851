import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ColumnHeader,
  defaultMapGeometryStyles,
  initialToolsState,
  MapAction,
  MapId,
  MapObjectTableActionType,
  MapObjectTableState,
  MapPortalName,
  MapSettings,
  MapState,
  MapStateService,
  MapViewActionType,
  Wkt,
} from '@gk/gk-modules';
import * as _ from 'lodash';
import { WKT } from 'ol/format';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  Observable,
  takeWhile,
} from 'rxjs';
import { EcoMapSettingsService } from '../../../services/eco-map-settings/eco-map-settings.service';

@Component({
  selector: 'app-eco-map',
  templateUrl: 'eco-map.component.html',
})
export class EcoMapComponent implements OnInit, OnDestroy {
  private isAlive = true;
  ecoMapState = MapState.getInitialStruct();
  initialToolsState = _.cloneDeep(initialToolsState);
  defaultMapGeometryStyles = _.cloneDeep(defaultMapGeometryStyles);
  geom$ = new BehaviorSubject<Wkt>(null);
  @Input() set geom(geom: Wkt) {
    this.geom$.next(geom);
  }

  constructor(
    private ecoMapSettingsService: EcoMapSettingsService,
    private mapStateService: MapStateService
  ) {}

  ngOnInit(): void {
    this.combineGeomAndMapStateSubscriptionsAndShowOnMap();
  }

  combineGeomAndMapStateSubscriptionsAndShowOnMap(): void {
    combineLatest([this.geom$, this.getMapSettingsSubscription()])
      .pipe(
        takeWhile(() => this.isAlive),
        filter(([geom, mapState]) => !!(geom && mapState))
      )
      .subscribe(([geom, mapState]) => {
        this.setMapState(mapState);
        this.showOnMap(geom);
      });
  }

  getMapSettingsSubscription(): Observable<MapSettings> {
    return this.ecoMapSettingsService.ecoPortalMapSettings;
  }

  setMapState(mapSettings: MapSettings): void {
    this.ecoMapState = new MapState(
      MapId.EcoPortalDetails,
      this.mapStateService.getViewState(MapId.EcoPortalDetails, mapSettings),
      this.mapStateService.getToolbarState([], mapSettings.papers),
      this.initialToolsState,
      this.mapStateService.getLayersState(
        MapId.EcoPortalDetails,
        mapSettings,
        MapPortalName.Applicant
      ),
      [
        new MapObjectTableState(
          [new ColumnHeader('typeName', 'GK.MAP.TYPE', true)],
          undefined,
          this.defaultMapGeometryStyles,
          true,
          true,
          true
        ),
      ]
    );
  }

  showOnMap(geom: Wkt): void {
    const mapObject = { geom };
    const extent = new WKT().readGeometry(mapObject.geom).getExtent();
    this.handleMapAction(
      new MapAction(MapObjectTableActionType.Select, mapObject)
    );
    this.handleMapAction(
      new MapAction(MapViewActionType.ExtentToFitToChange, extent)
    );
  }

  handleMapAction(mapAction: MapAction): void {
    this.ecoMapState = this.mapStateService.getUpdatedMapState(
      mapAction,
      this.ecoMapState
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
