import { Inject, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { filter, interval, Subject } from 'rxjs';

@Injectable()
export class SwManagerService {
  public newAppVersionAvailable = new Subject<void>();

  constructor(
    @Inject('window') private window: Window,
    private swUpdate: SwUpdate
  ) {}

  private isSWEnabled(): boolean {
    if (!this.swUpdate.isEnabled) {
      console.warn(
        'Service worker is not available in development mode! Use production mode instead.'
      );
    }

    return this.swUpdate.isEnabled;
  }

  private subscribeToSwAvailable(): void {
    this.swUpdate.versionUpdates
      .pipe(filter((evt) => evt.type === 'VERSION_READY'))
      .subscribe(() => {
        this.newAppVersionAvailable.next();
      });
  }

  private setCheckInterval(): void {
    const second = 1000;
    const fiveMinutes = second * 60 * 5;
    interval(fiveMinutes).subscribe(() => this.swUpdate.checkForUpdate());
  }

  public init(): void {
    if (!this.isSWEnabled()) {
      return;
    }

    this.subscribeToSwAvailable();
    this.setCheckInterval();
  }

  public updateAppVersion(): void {
    if (!this.isSWEnabled()) {
      return;
    }

    this.swUpdate
      .activateUpdate()
      .then(() => this.window.document.location.reload());
  }
}
