import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  filter,
  first,
  map,
  Observable,
  ReplaySubject,
  Subject,
  switchMap,
} from 'rxjs';
import {
  ExtendedResponse,
  ExtendedResponseFromApi,
} from '../login/login.model';
import { TfaConfirmation, TfaConfirmationMode } from './tfa-confirmation.model';

@Injectable()
export class TfaConfirmationService {
  canceledConfirmation = new Subject<void>();
  verifiedConfirmation = new Subject<void>();
  tfaConfirmationInOperation = new ReplaySubject<boolean>(1);
  tfaConfirmationMode = new ReplaySubject<TfaConfirmationMode>(1);
  constructor(private httpClient: HttpClient) {}

  verify(data: TfaConfirmation): Observable<ExtendedResponse> {
    return this.getVerifyUrl().pipe(
      switchMap((verifyUrl) =>
        this.httpClient.post<ExtendedResponseFromApi>(
          verifyUrl,
          TfaConfirmation.fromAppToApi(data)
        )
      ),
      map((data) => ExtendedResponse.fromApiToApp(data))
    );
  }

  getVerifyUrl(): Observable<string> {
    return this.tfaConfirmationMode.pipe(
      first(),
      map((confirmationMode) => {
        switch (confirmationMode) {
          case TfaConfirmationMode.Init:
            return '/api/system/login/2fa/initialization/verify';
          case TfaConfirmationMode.Login:
            return '/api/system/login/2fa/verify';
          default:
            throw Error('Unhandled Tfa confirmation url');
        }
      })
    );
  }

  emitInitTfaConfirmationMode(): void {
    this.tfaConfirmationMode.next(TfaConfirmationMode.Init);
  }

  emitLoginTfaConfirmationMode(): void {
    this.tfaConfirmationMode.next(TfaConfirmationMode.Login);
  }

  isInitTfaConfirmationMode(): Observable<boolean> {
    return this.tfaConfirmationMode.pipe(
      filter(
        (confirmationMode) => confirmationMode === TfaConfirmationMode.Init
      ),
      map(Boolean)
    );
  }

  isLoginTfaConfirmationMode(): Observable<boolean> {
    return this.tfaConfirmationMode.pipe(
      filter(
        (confirmationMode) => confirmationMode === TfaConfirmationMode.Login
      ),
      map(Boolean)
    );
  }
}
