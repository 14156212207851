import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PortalId, getFileNameFromResponseHeaders } from '@gk/gk-modules';
import { FileSaverService } from 'ngx-filesaver';
import { takeWhile } from 'rxjs';
import { ApiNewDokumentPowiazanyDalDto } from '../../services/designer-incoming-documents/designer-incoming-documents.model';
import { DesignerIncomingDocumentsService } from '../../services/designer-incoming-documents/designer-incoming-documents.service';
import {
  DocType,
  getEServiceI18nPrefix,
} from '../../services/doc-type/doc-type.model';
import { DocFile } from '../../services/new-designer-request/new-designer-request.model';
import { RequestFileService } from '../../services/request-file/request-file.service';
import { BaseStyleClassesDirective } from '../../shared/base-style-classes/base-style-classes.directive';
import {
  EServiceChosenFiles,
  EServiceDocTypeId,
} from './services/e-service-doc-type/e-service-doc-type.model';
import { EServiceDocTypeService } from './services/e-service-doc-type/e-service-doc-type.service';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
})
export class AttachmentsComponent
  extends BaseStyleClassesDirective
  implements OnDestroy, OnInit
{
  private isAlive = true;
  chosenProofOfEnablementPaymentFiles: DocFile[] = [];
  docTypes: DocType[];
  chosenFiles: EServiceChosenFiles = {};
  @Input() submitted: boolean;
  @Input() portalId: PortalId;

  constructor(
    public eServiceDocTypeService: EServiceDocTypeService,
    private requestFileService: RequestFileService,
    private fileSaverService: FileSaverService,
    private incomingDocumentsService: DesignerIncomingDocumentsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.fetchDocTypes();
  }

  fetchDocTypes(): void {
    this.eServiceDocTypeService
      .getDocTypes(this.portalId)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => (this.docTypes = data));
  }

  shouldShowFileInputInvalidMessage(
    docType: DocType<EServiceDocTypeId>,
  ): boolean {
    return this.submitted && !this.areChosenObligatoryDocuments(docType);
  }

  getChosenFilesGroupedByIds(): {
    [key in EServiceDocTypeId]?: DocFile[];
  } {
    return {
      ...this.chosenFiles,
      [EServiceDocTypeId.RequestStampDuty]: [
        ...(this.chosenFiles?.[EServiceDocTypeId.RequestStampDuty] || []),
        ...this.chosenProofOfEnablementPaymentFiles,
      ],
    };
  }

  handleProofOfEnablementPaymentFileInputAction(files: DocFile[]): void {
    this.chosenProofOfEnablementPaymentFiles =
      this.getFilesProofOfEnablementPayment(files);
  }

  handleFileInputAction(id: EServiceDocTypeId, files: DocFile[]): void {
    this.chosenFiles[id] = this.getFiles(id, files);
  }

  getFilesProofOfEnablementPayment(files: DocFile[]): DocFile[] {
    return files.map((file) => {
      file.docTypeId = EServiceDocTypeId.RequestStampDuty;

      return file;
    });
  }

  getFiles(id: EServiceDocTypeId, files: DocFile[]): DocFile[] {
    return files.map((file) => {
      file.docTypeId = id;

      return file;
    });
  }

  downloadRequestFilePattern(fileId: string): void {
    this.requestFileService
      .downloadPattern(fileId)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => {
        const fileName = getFileNameFromResponseHeaders(data.headers);
        this.fileSaverService.save(data.body, fileName);
      });
  }

  isEnablementDocType(id: EServiceDocTypeId): boolean {
    return id === EServiceDocTypeId.Enablement;
  }

  areChosenDocuments(): boolean {
    return this.docTypes.some(
      (docType: DocType<EServiceDocTypeId>) =>
        !!(this.chosenFiles[docType.id] && this.chosenFiles[docType.id].length),
    );
  }

  getConvertedFiles(): Promise<ApiNewDokumentPowiazanyDalDto | Error>[] {
    return this.getChosenFiles().map((docFile) =>
      this.incomingDocumentsService.docFileToDtoDocument(docFile),
    );
  }

  getChosenFiles(): DocFile[] {
    return [].concat(...Object.values(this.getChosenFilesGroupedByIds()));
  }

  areDocumentsValid(): boolean {
    return this.docTypes.every((docType) =>
      this.areChosenObligatoryDocuments(docType),
    );
  }

  areChosenObligatoryDocuments(docType: DocType<EServiceDocTypeId>): boolean {
    const files = this.getChosenFilesGroupedByIds()[docType.id];

    return !docType.isObligatory || !!(files && files.length);
  }

  geti18nPrefix(): string {
    return getEServiceI18nPrefix(this.portalId);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
