import {
  Community,
  District,
  Sheet,
} from '../../../gk-dynamic-form/services/cached/cached.model';
import { ApiFilter } from '../../../gk-dynamic-list/services/services.model';
import {
  FormFieldOrientation,
  FormFieldType,
  FormFieldWrappers,
} from '../custom-types/model';

export enum LandParcelSearchFormFieldGroupControlName {
  ParcelNumerator = 'parcelNumerator',
  ParcelDenumerator = 'parcelDenumerator',
  ParcelAddNumber = 'parcelAddNumber',
}

export class LandParcelSearchFormFieldGroupFormModel {
  constructor(
    public community: Community,
    public district: District,
    public sheet: Sheet,
    public parcelNumerator: number,
    public parcelDenumerator: number,
    public parcelAddNumber: number,
  ) {}

  static getApiFilters(
    model: LandParcelSearchFormFieldGroupFormModel,
  ): ApiFilter[] {
    return [
      new ApiFilter('GminaId', model?.community?.id),
      new ApiFilter('ObrebId', model?.district?.id),
      new ApiFilter('NrL', model?.parcelNumerator),
      new ApiFilter('NrM', model?.parcelDenumerator),
      new ApiFilter('NrAdd', model?.parcelAddNumber),
    ];
  }
}

export const landParcelSearchFormFieldGroup = {
  key: 'landParcelSearch',
  wrappers: [FormFieldWrappers.KendoPanel],
  props: {
    label: 'LAND_PARCELS.PARCELS',
  },
  fieldGroup: [
    {
      type: '#communities',
    },
    {
      type: '#districts',
    },
    {
      type: '#sheets',
    },
    {
      wrappers: [FormFieldWrappers.FormField],
      props: {
        label: 'LAND_PARCELS.NR_PARCELS',
        orientation: FormFieldOrientation.Horizontal,
      },
      fieldGroupClassName: 'd-flex flex-row column-gap-1',
      fieldGroup: [
        {
          key: LandParcelSearchFormFieldGroupControlName.ParcelNumerator,
          type: FormFieldType.KendoInput,
          props: {
            placeholder: '',
          },
        },
        {
          template: `<span>/</span>`,
        },
        {
          key: LandParcelSearchFormFieldGroupControlName.ParcelDenumerator,
          type: FormFieldType.KendoInput,
          props: {
            placeholder: '',
          },
        },
        {
          template: `<span>-</span>`,
        },
        {
          key: LandParcelSearchFormFieldGroupControlName.ParcelAddNumber,
          type: FormFieldType.KendoInput,
          props: {
            placeholder: '',
          },
        },
      ],
    },
  ],
};
