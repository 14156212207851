import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  Applicant,
  ColumnHeader,
  DocSignService,
  LawPersonSearch,
  LegalPersonControlName,
  MapAction,
  MapId,
  MapObjectTableActionType,
  MapObjectTableState,
  MapPortalName,
  MapSettings,
  MapSettingsService,
  MapState,
  MapStateService,
  PzService,
  ToolType,
  ValidateEmail,
  ValidateIp,
  ValidatePhone,
  isLegalPerson,
} from '@gk/gk-modules';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { takeWhile } from 'rxjs';
import { DistrictSelectionService } from '../../../services/district-selection/district-selection.service';
import {
  AccessTime,
  AccessTimeControl,
  EmailControl,
  IpAddressControl,
  MapType,
  MapTypeControl,
  NameSurnameControl,
  NewNetworkServiceRequestModel,
  PayerDetailsData,
  PhoneControl,
  SharingPurposeControl,
  WmsMapScale,
  WmsMapScaleControl,
} from '../../../services/new-network-service-request/new-network-service-request.model';
import { NewNetworkServiceRequestService } from '../../../services/new-network-service-request/new-network-service-request.service';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { RangeType } from '../../../services/parcel/parcel.model';
import { BsMessageType } from '../../../services/request-workspace-state/request-workspace-state.model';
import {
  PetitionerSharingPurpose,
  SharingPurposeEnum,
} from '../../../services/sharing-purpose/sharing-purpose.model';
import { SharingPurposeService } from '../../../services/sharing-purpose/sharing-purpose.service';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';

@Component({
  selector: 'app-new-network-service-order',
  templateUrl: './new-network-service-order.component.html',
  styleUrls: ['./new-network-service-order.component.scss'],
})
export class NewNetworkServiceOrderComponent
  extends BaseNewRequestComponent
  implements OnInit
{
  mapTypeControl = MapTypeControl;
  sharingPurposeControl = SharingPurposeControl;
  wmsMapScaleControl = WmsMapScaleControl;
  ipAddressControl = IpAddressControl;
  nameSurnameControl = NameSurnameControl;
  emailControl = EmailControl;
  phoneControl = PhoneControl;
  mapType = MapType;
  accessTimeControl = AccessTimeControl;
  accessTime = AccessTime;
  override model = NewNetworkServiceRequestModel.getInitialModel();
  petitionerSharingPurposes: PetitionerSharingPurpose[] = [];
  wmsMapScales: WmsMapScale[] = [500, 1000, 2000, 5000];
  selectedLawPersons: LawPersonSearch[] = [];
  rangeTypeEnum = RangeType;
  calculatedPrice: number;
  ipAddresses: string[] = [];
  polygonMapState = MapState.getInitialStruct();

  polygonMapObjectTableState = new MapObjectTableState(
    [
      new ColumnHeader('typeName', 'GK.MAP.TYPE', true),
      new ColumnHeader('area', 'GK.MAP.AREA_IN_HA'),
    ],
    undefined,
    this.defaultMapGeometryStyles,
    true,
    true,
    true
  );
  selectedSharingPurpose: PetitionerSharingPurpose;
  isSubmitted = false;
  legalPersonControlName = LegalPersonControlName;

  constructor(
    private newNetworkRequestService: NewNetworkServiceRequestService,
    private sharingPurposesService: SharingPurposeService,
    private changeDetectorRef: ChangeDetectorRef,
    private districtSelectionService: DistrictSelectionService,
    @Inject('window') public window: Window,
    private formBuilder: UntypedFormBuilder,
    protected override pzService?: PzService,
    protected override newRequestHelperService?: NewRequestHelperService,
    protected override docSignService?: DocSignService,
    protected override router?: Router,
    protected override translateService?: TranslateService,
    protected override mapSettingsService?: MapSettingsService,
    protected override mapStateService?: MapStateService
  ) {
    super(
      pzService,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService
    );
  }

  override ngOnInit(): void {
    this.createForm();
    this.subscribeToSharingPurposes();
    this.subscribeToSelectedDistrictsList();
    this.subscribeToMapSettings();
    this.subscribeFormControlsChanges();
    this.setValidators();
    super.ngOnInit();
  }

  subscribeFormControlsChanges(): void {
    this.getSharingPurposesControl()
      .valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe((value) => (this.selectedSharingPurpose = value));
    this.getMapTypeControl()
      .valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe(() => this.triggerCalculatePaymentForMap());
    this.getWmsMapScaleControl()
      .valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe(() => this.triggerCalculatePaymentForMap());
  }

  createForm(): void {
    this.formGroup = this.formBuilder.group(
      NewNetworkServiceRequestModel.getInitialModel()
    );
  }

  getSharingPurposesControl(): UntypedFormControl {
    return this.formGroup.get(SharingPurposeControl.Name) as UntypedFormControl;
  }

  getMapTypeControl(): UntypedFormControl {
    return this.formGroup.get(MapTypeControl.GroupName) as UntypedFormControl;
  }

  getWmsMapScaleControl(): UntypedFormControl {
    return this.formGroup.get(WmsMapScaleControl.Name) as UntypedFormControl;
  }

  getIpAddressControl(): UntypedFormControl {
    return this.formGroup.get(IpAddressControl.Name) as UntypedFormControl;
  }

  getNameSurnameControl(): UntypedFormControl {
    return this.formGroup.get(NameSurnameControl.Name) as UntypedFormControl;
  }

  getEmailControl(): UntypedFormControl {
    return this.formGroup.get(EmailControl.Name) as UntypedFormControl;
  }

  getPhoneControl(): UntypedFormControl {
    return this.formGroup.get(PhoneControl.Name) as UntypedFormControl;
  }

  setValidators(): void {
    this.getIpAddressControl().setValidators(ValidateIp);
    this.getEmailControl().setValidators(ValidateEmail);
    this.getPhoneControl().setValidators(ValidatePhone);
  }

  setInitialSharingPurpose(sharingPurpose: PetitionerSharingPurpose): void {
    this.getSharingPurposesControl().patchValue(sharingPurpose);
  }

  subscribeToSharingPurposes(): void {
    this.sharingPurposesService.petitionerSharingPurposes
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((petitionerSharingPurposes: PetitionerSharingPurpose[]) => {
        this.petitionerSharingPurposes = petitionerSharingPurposes.filter(
          (purpose) => purpose.id === SharingPurposeEnum.OwnNeeds
        );
        this.setInitialSharingPurpose(this.petitionerSharingPurposes[0]);
      });
  }

  subscribeToSelectedDistrictsList(): void {
    this.districtSelectionService.selectedDistricts
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((districts) => (this.model.districts = districts));
  }

  hasError(formControl: UntypedFormControl, errorName: string): boolean {
    return !!(
      formControl &&
      formControl.errors &&
      formControl.errors[errorName]
    );
  }

  isFieldReadyToShowAlert(formControl: UntypedFormControl): boolean {
    return !!(
      formControl &&
      (formControl.dirty || formControl.touched || this.isSubmitted)
    );
  }

  shouldShowErrorAlert(
    formControl: UntypedFormControl,
    errorName: string
  ): boolean {
    return (
      this.isFieldReadyToShowAlert(formControl) &&
      this.hasError(formControl, errorName)
    );
  }

  onFormSubmit(): void {
    this.isSubmitted = true;

    if (!this.isFormValid(this.formGroup)) {
      return;
    }

    this.sendAndValidateDto();
  }

  sendAndValidateDto(): void {
    this.docSignPending = true;
    this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');

    this.newNetworkRequestService
      .validateDtoAndAddToSign(this.getUpdatedModelValue())
      .pipe(takeWhile(() => this.isAlive))
      .subscribe({
        next: (addedDocToSignResponse) =>
          this.handleSendAndValidateSuccess(addedDocToSignResponse),
        error: () => this.handleSendAndValidateFailure(),
      });
  }

  isFormValid(form: UntypedFormGroup): boolean {
    return form.valid && this.isRangeValid();
  }

  addIpAddressToTable(): void {
    this.ipAddresses = [...this.ipAddresses, this.getIpAddressControl().value];
  }

  isRangeTabChosen(rangeType: RangeType): boolean {
    return this.model.rangeType === rangeType;
  }

  updateRangeType(tabChangeEvent: NgbNavChangeEvent): void {
    this.model.rangeType = tabChangeEvent.nextId as RangeType;
    this.changeDetectorRef.detectChanges();
  }

  removeIpAddressFromTable(ip: string): void {
    this.ipAddresses = this.ipAddresses.filter((ipAddress) => ipAddress !== ip);
  }

  removeAllDistricts(event: Event): void {
    event.preventDefault();
    this.districtSelectionService.removeAll();
  }

  handlePolygonMapAction(mapAction: MapAction): void {
    this.polygonMapState = this.mapStateService.getUpdatedMapState(
      mapAction,
      this.polygonMapState
    );
    if (mapAction.type === MapObjectTableActionType.AddToExisting) {
      this.triggerCalculatePaymentForMap();
    }
  }

  triggerCalculatePaymentForMap(): void {
    if (this.isRangeValid()) {
      this.newNetworkRequestService
        .calculatePaymentForMap(this.getUpdatedModelValue())
        .pipe(takeWhile(() => this.isAlive))
        .subscribe(
          (calculatedPrice) => (this.calculatedPrice = calculatedPrice)
        );
    }
  }

  isPolygonValid(): boolean {
    return this.polygonMapState.mapObjectTablesState[0].mapObjects.length === 1;
  }

  isDistrictsValid(): boolean {
    return !_.isEmpty(this.model.districts);
  }

  isRangeValid(): boolean {
    switch (this.model.rangeType) {
      case RangeType.Districts:
        return this.isDistrictsValid();
      case RangeType.Wkt:
        return this.isPolygonValid();
      case RangeType.Parcels:
        return true;
    }
  }

  subscribeToMapSettings(): void {
    this.mapSettingsService
      .getMapSettings(MapPortalName.FreeServices)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((freeServicesMapSettings) => {
        this.setPolygonMapState(freeServicesMapSettings);
      });
  }

  setPolygonMapState(mapSettings: MapSettings): void {
    this.polygonMapState = new MapState(
      MapId.NewFreeServicesRequestPolygon,
      this.mapStateService.getViewState(
        MapId.NewFreeServicesRequestPolygon,
        mapSettings
      ),
      this.mapStateService.getToolbarState(
        [ToolType.AnyPolygon],
        mapSettings.papers,
        undefined,
        undefined,
        true
      ),
      this.initialToolsState,
      this.mapStateService.getLayersState(
        MapId.NewFreeServicesRequestPolygon,
        mapSettings,
        MapPortalName.FreeServices
      ),
      [this.polygonMapObjectTableState]
    );
  }

  getPayerDetailsModel(): PayerDetailsData {
    const lawPersonSearch = this.selectedLawPersons[0];

    if (
      !(lawPersonSearch instanceof Applicant) &&
      this.selectedLawPersons[0].dataToSend
    ) {
      const legalPersonData = lawPersonSearch.data.legalPersonData;
      const naturalPersonData = lawPersonSearch.data.naturalPersonData;
      const address = lawPersonSearch.data.address;

      return PayerDetailsData.fromApiToApp({
        companyName: legalPersonData.name,
        firstName: naturalPersonData.firstName,
        lastName: naturalPersonData.lastName,
        email: null,
        phone: null,
        address: {
          buildingNumber: address.buildingNumber,
          localNumber: address.localNumber,
          place: address.place,
          street: address.street,
          postalCode: address.postalCode,
        },
        pesel: naturalPersonData.pesel,
        nip: legalPersonData.nip,
        regon: legalPersonData.regon,
        rodzPetId: isLegalPerson(lawPersonSearch) ? 1 : null,
      });
    } else {
      const address = lawPersonSearch.address;
      const addressArray = address.split(',');
      const streetWithNumber = addressArray[0];
      const postalCodeWithPlace = addressArray[1];
      const streetWithNumberArray = streetWithNumber.split(' ');
      const postalCodeWithPlaceArray = postalCodeWithPlace.split(' ');

      return PayerDetailsData.fromApiToApp({
        companyName: lawPersonSearch.name,
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        address: {
          buildingNumber: streetWithNumberArray[3],
          localNumber: null,
          place: postalCodeWithPlaceArray[2],
          street: `${streetWithNumberArray[0]} ${streetWithNumberArray[1]} ${streetWithNumberArray[2]}`,
          postalCode: postalCodeWithPlaceArray[1],
        },
        regon: isLegalPerson(lawPersonSearch) ? lawPersonSearch.regon : null,
        nip: isLegalPerson(lawPersonSearch) ? lawPersonSearch.nip : null,
        rodzPetId: isLegalPerson(lawPersonSearch) ? 1 : null,
      });
    }
  }

  getUpdatedModelValue(): NewNetworkServiceRequestModel {
    return {
      ...this.formGroup.value,
      ipAddresses: this.ipAddresses,
      districts: this.model.districts,
      rangeType: this.model.rangeType,
      wkt:
        this.polygonMapState.mapObjectTablesState[0].mapObjects[0] &&
        this.polygonMapState.mapObjectTablesState[0].mapObjects[0].geom,
      payerDetails: this.selectedLawPersons.length
        ? this.getPayerDetailsModel()
        : null,
    };
  }
}
