<div class="d-flex flex-column text-center align-items-center">
  <h1 class="mb-2">{{ 'GK.COMPONENTS.PAYMENT_SUCCESS.HEADER' | translate }}</h1>

  <span class="mb-2"
    >{{ 'GK.COMPONENTS.PAYMENT_SUCCESS.PAYMENT_COMPLETED' | translate }}
  </span>

  <strong class="mb-1">{{
    'GK.COMPONENTS.PAYMENT_SUCCESS.CHECK_MAIL' | translate
  }}</strong>

  <img class="my-3" [src]="imgSrc" alt="" />

  <button
    class="btn btn-color-from-db"
    type="button"
    (click)="emitRedirectTrigger()"
  >
    {{ 'GK.COMPONENTS.PAYMENT_SUCCESS.BACK_BTN_LABEL' | translate }}
  </button>

  <small
    >{{ 'GK.COMPONENTS.PAYMENT_SUCCESS.REDIRECTING' | translate }}:
    {{ counter }}s</small
  >
</div>
