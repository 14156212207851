export interface ExtendedResponseFromApi {
  ErrorCode: number;
  ErrorMessage: string;
}

export class ExtendedResponse {
  constructor(public errorCode: number, public errorMessage: string) {}

  static fromApiToApp(data: ExtendedResponseFromApi): ExtendedResponse {
    return new this(data.ErrorCode, data.ErrorMessage);
  }
}

export interface LoginApi {
  UserName: string;
  Password: string;
}

export interface LoginResponseFromApi extends ExtendedResponseFromApi {
  Payload: any;
  Success: boolean;
}

export class Login {
  constructor(public login: string, public password: string) {}

  static fromAppToApi(data: Login): LoginApi {
    return {
      UserName: data.login,
      Password: data.password,
    };
  }
}

export class LoginResponse extends ExtendedResponse {
  constructor(
    public override errorCode: number,
    public override errorMessage: string,
    public payload: any,
    public success: boolean
  ) {
    super(errorCode, errorMessage);
  }

  static override fromApiToApp(data: LoginResponseFromApi): LoginResponse {
    return new this(
      data.ErrorCode,
      data.ErrorMessage,
      data.Payload,
      data.Success
    );
  }
}

export class PetentForLoggedUserData {
  constructor(
    public name: string,
    public nip: string,
    public pesel: string,
    public regon: string,
    public type: string
  ) {}
  static fromApiToApp(
    data: PetentForLoggedUserResponseDto
  ): PetentForLoggedUserData {
    return new this(data.Nazwa, data.Nip, data.Pesel, data.Regon, data.Typ);
  }
}

interface PetentForLoggedUserResponseDto {
  Nazwa: string;
  Typ: string;
  Nip: string;
  Pesel: string;
  Regon: string;
}

export interface LoggedUserDataResponseDto {
  Uuid: string;
  OwnerId: number;
  Nazwa: string;
  NazwaDruk: string;
  NazwaWyswietlana: string;
  Imie: string;
  Nazwisko: string;
  Email: string;
  OstatniLogin: string;
  GesutOsobaId: number;
  Petent: PetentForLoggedUserResponseDto;
}

const guestUserName = 'GOSC';

export class LoggedUserData {
  constructor(
    public uuid: string,
    public ownerId: number,
    public name: string,
    public printName: string,
    public displayName: string,
    public firstName: string,
    public secondName: string,
    public email: string,
    public lastLogin: string,
    public gesutPersonId: number,
    public client: PetentForLoggedUserData,
    public isLogged: boolean,
    public isGuest: boolean
  ) {}

  static fromApiToApp(data: LoggedUserDataResponseDto): LoggedUserData {
    return new this(
      data.Uuid,
      data.OwnerId,
      data.Nazwa,
      data.NazwaDruk,
      data.NazwaWyswietlana,
      data.Imie,
      data.Nazwisko,
      data.Email,
      data.OstatniLogin,
      data.GesutOsobaId,
      PetentForLoggedUserData.fromApiToApp(data.Petent),
      data.Uuid && data.Nazwa !== guestUserName,
      data.Nazwa === guestUserName
    );
  }
}
