import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, map, timer } from 'rxjs';
import { getScaleByResolution } from '../../../gk-map/configs';
import { MapControl } from '../../../gk-map/controls';
import { MapStateService } from '../../../gk-map/services';
import { SelectItem } from '../../../services';
import {
  GMPPrintAttributesControlName,
  GMPPrintAttributesFormGroup,
} from '../../services/print-attributes-form/print-attributes-form.model';

@Component({
  selector: 'gk-gmp-print-preview',
  templateUrl: './print-preview.component.html',
  styleUrl: './print-preview.component.scss',
})
export class GMPPrintPreviewComponent extends MapControl implements OnInit {
  controlName = GMPPrintAttributesControlName;
  currentDateTimeString: Observable<string>;
  @Input() formGroup: FormGroup<GMPPrintAttributesFormGroup>;
  @ViewChild('printContainer') printContainer: ElementRef;

  constructor(public mapStateService: MapStateService) {
    super();
  }

  ngOnInit(): void {
    this.setCurrentDateTimeStringTimer();
  }

  setCurrentDateTimeStringTimer(): void {
    this.currentDateTimeString = timer(0, 1000).pipe(
      map(() => new Date().toLocaleString()),
    );
  }

  getSubtitle(): string {
    return this.getSubtitleFormControl().value;
  }

  getSubtitleFormControl(): FormControl<string> {
    return this.formGroup.get(
      GMPPrintAttributesControlName.Subtitle,
    ) as FormControl<string>;
  }

  getScaleName(): string {
    return getScaleByResolution(this.mapState.viewState.resolution).name;
  }

  getRightTopCoordinates(): string {
    const { viewState } = this.mapState;
    const { currentNativeExtent } = viewState;

    return this.formatCoordinates(
      currentNativeExtent[2],
      currentNativeExtent[3],
    );
  }

  getLeftBottomCoordinates(): string {
    const { viewState } = this.mapState;
    const { currentNativeExtent } = viewState;

    return this.formatCoordinates(
      currentNativeExtent[0],
      currentNativeExtent[1],
    );
  }

  formatCoordinates(x: number, y: number): string {
    return `x = ${x.toFixed(2)}, y = ${y.toFixed(2)}`;
  }

  getPrintFooter(): string {
    const selectItem = this.getPrintFooterFormControl().value;

    return selectItem.value;
  }

  getPrintFooterFormControl(): FormControl<SelectItem<string | null>> {
    return this.formGroup.get(
      GMPPrintAttributesControlName.PrintFooter,
    ) as FormControl<SelectItem<string | null>>;
  }

  getBooleanFormControlValue(
    controlName: GMPPrintAttributesControlName,
  ): boolean {
    return this.formGroup.get(controlName).value as boolean;
  }
}
