<div class="form-check" [formGroup]="group">
  <input
    class="form-check-input"
    type="radio"
    [attr.name]="config.name"
    [formControlName]="config.name"
    [attr.id]="config.label"
    [value]="config.value"
  />
  <label class="form-check-label" [attr.for]="config.label">
    <span class="ms-3">{{ config.label }}</span>
  </label>
</div>
