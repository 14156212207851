import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  OfficeDepartment,
  OfficeDepartmentResponseDto,
} from './office-department.model';

@Injectable()
export class OfficeDepartmentsService {
  officeDepartmentsUrl = '/api/system/web/portal/wydzialy';
  officeDepartments = new ReplaySubject<OfficeDepartment[]>();

  constructor(private httpClient: HttpClient) {
    this.fetchOfficeDepartments();
  }

  private fetchOfficeDepartments(): void {
    this.getOfficeDepartments().subscribe((officeDepartments) =>
      this.officeDepartments.next(officeDepartments)
    );
  }

  private getOfficeDepartments(): Observable<OfficeDepartment[]> {
    return this.httpClient
      .get<OfficeDepartmentResponseDto[]>(this.officeDepartmentsUrl)
      .pipe(
        map((apiFields) =>
          apiFields.map((apiField) => OfficeDepartment.fromApiToApp(apiField))
        )
      );
  }
}
