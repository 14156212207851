import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { getDefaultControlValue, peselValidator } from '@gk/gk-modules';
import { AuthorizedPersonToRegistryViewControlName } from './authorized-person-to-registry-view-form.model';

@Injectable()
export class AuthorizedPersonToRegistryViewFormService {
  constructor(private formBuilder: UntypedFormBuilder) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [AuthorizedPersonToRegistryViewControlName.NameSurname]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [AuthorizedPersonToRegistryViewControlName.Pesel]: [
        getDefaultControlValue(),
        [Validators.required, peselValidator()],
      ],
    });
  }
}
