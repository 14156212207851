import { Injectable } from '@angular/core';
import { Observable, of, shareReplay } from 'rxjs';
import { SelectItem } from '../../../services';
import { gmpPrintQualityOptions } from './print-quality.model';

@Injectable()
export class GMPPrintQualityService {
  private printQualityCache: Observable<SelectItem<number>[]>;

  get(): Observable<SelectItem<number>[]> {
    if (!this.printQualityCache) {
      this.printQualityCache = of(gmpPrintQualityOptions).pipe(shareReplay(1));
    }

    return this.printQualityCache;
  }
}
