@if (forceApplicantType === applicantType.FromLoggedInUserOrFromRegonDb) {
  <div class="col-12">
    <gk-law-person-type-form
      [form]="lawPersonFormGroup"
    ></gk-law-person-type-form>
  </div>
}

@if (isNaturalPersonChosen()) {
  <gk-natural-person-simple-form
    [form]="getNaturalPersonFormGroup()"
  ></gk-natural-person-simple-form>
  <gk-address-form [form]="getAddressFormGroup()"></gk-address-form>
}

@if (isLegalPersonChosen()) {
  <gk-law-person-search
    [multiplePersonSelect]="false"
    [addingPersonsDirectlyToDb]="false"
    [legalPersonOnly]="true"
    [legalPersonSearchFieldsToHide]="legalPersonSearchFieldsToHide"
    [(selectedLawPersons)]="chosenLawPersons"
    [searchLegalPersonCustomUrl]="searchLegalPersonCustomUrl"
    (selectedLawPersonsChange)="emitPersonChange()"
  ></gk-law-person-search>
}
@if (chosenLawPersons?.length && isLegalPersonChosen()) {
  <div class="row">
    @if (isLegalPersonNipRequired()) {
      <div
        class="col-xl-auto col-12 my-1"
        [formGroup]="getLegalPersonFormGroup()"
      >
        <label [attr.for]="idPrefix + legalPersonControlName.Nip">{{
          'LEGAL_PERSON_FORM.PRINCIPAL.NIP' | translate
        }}</label>
        <input
          type="text"
          class="form-control form-control-sm required"
          [attr.aria-required]="true"
          [id]="idPrefix + legalPersonControlName.Nip"
          [formControlName]="legalPersonControlName.Nip"
          [name]="idPrefix + legalPersonControlName.Nip"
          [autocomplete]="undefined"
        />
        @if (shouldShowRequiredAlert(getNipFormControl())) {
          <div class="alert alert-danger" tabindex="0">
            {{ 'FIELD_REQUIRED' | translate }}
          </div>
        }
        @if (shouldShowNipAlert(getNipFormControl())) {
          <div class="alert alert-danger" tabindex="0">
            {{ 'LEGAL_PERSON_FORM.PRINCIPAL.NIP_ERROR' | translate }}
          </div>
        }
      </div>
    }
    <gk-address-form
      class="col-xl col-12"
      [form]="getApplicantFromRegonAddressFormGroup()"
      [fieldsToHide]="getFieldsToHideArray()"
      [isRequired]="true"
      [wasFormValidated]="wasFormValidated"
    ></gk-address-form>
  </div>
}
<gk-post-office-modal
  [addressFormGroup]="getApplicantFromRegonAddressFormGroup()"
  [idPrefix]="idPrefix"
>
</gk-post-office-modal>
