<div class="container">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <h1 class="mt-5 mb-3">
      {{
        'GEODETIC_AND_CARTOGRAPHIC_SERVICE_DATA_REQUEST.NEW_REQUEST.TITLE'
          | translate
      }}
    </h1>

    <section>
      <div class="card border-dark mb-4">
        <div class="card-header text-white bg-dark">
          <label class="mb-0" for="client-sgk-info">
            {{
              'GEODETIC_AND_CARTOGRAPHIC_SERVICE_DATA_REQUEST.NEW_REQUEST.CLIENT_SGK_INFO'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <input
            type="text"
            id="client-sgk-info"
            class="form-control"
            readonly
            disabled
            [value]="(sessionService.loggedUserData | async).clientSgk || ''"
          />
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLawPersonFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'GEODETIC_AND_CARTOGRAPHIC_SERVICE_DATA_REQUEST.NEW_REQUEST.CLIENT_DATA'
              | translate
          }}
        </div>
        <div class="card-body">
          <gk-law-person-simple-form
            #clientDataFormComponent
            [lawPersonFormGroup]="getLawPersonFormGroup()"
            [naturalPersonOnly]="true"
            [submitted]="submitted"
          >
          </gk-law-person-simple-form>
        </div>
      </div>
    </section>
    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isContactDataFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'GEODETIC_AND_CARTOGRAPHIC_SERVICE_DATA_REQUEST.NEW_REQUEST.CONTACT_DATA'
              | translate
          }}
        </div>
        <div class="card-body">
          <gk-law-person-simple-form
            #contactDataFormComponent
            [lawPersonFormGroup]="getContactDataFormGroup()"
            [naturalPersonOnly]="true"
            [submitted]="submitted"
          >
          </gk-law-person-simple-form>
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          submitted && !areWorkRangesChosen()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'GEODETIC_AND_CARTOGRAPHIC_SERVICE_DATA_REQUEST.NEW_REQUEST.WORK_RANGE'
              | translate
          }}
        </div>

        <div class="card-body">
          <gk-map
            [mapState]="mapState"
            (dispatch)="handleMapAction($event)"
          ></gk-map>

          <div class="my-2">
            @if (mapState.mapObjectTablesState[0].mapObjects.length) {
              <gk-map-object-table
                [mapState]="mapState"
                [mapObjectTableStateIndex]="0"
                (dispatch)="handleMapAction($event)"
              ></gk-map-object-table>
            }
          </div>
        </div>
        @if (submitted && !areWorkRangesChosen()) {
          <div class="card-footer">
            <div class="alert alert-danger mt-2">
              {{
                'GEODETIC_AND_CARTOGRAPHIC_SERVICE_DATA_REQUEST.NEW_REQUEST.WORK_RANGE_REQUIRED'
                  | translate
              }}
            </div>
          </div>
        }
      </div>
    </section>

    <section>
      <div class="card border-dark mb-4">
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.WorkSignature">
            {{
              'GEODETIC_AND_CARTOGRAPHIC_SERVICE_DATA_REQUEST.NEW_REQUEST.WORK_SIGNATURE'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <input
            type="text"
            [name]="controlName.WorkSignature"
            [id]="controlName.WorkSignature"
            class="form-control"
            [formControlName]="controlName.WorkSignature"
          />
        </div>
      </div>
    </section>

    <section>
      <div class="card border-dark mb-4">
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.WorkPurpose">
            {{
              'GEODETIC_AND_CARTOGRAPHIC_SERVICE_DATA_REQUEST.NEW_REQUEST.WORK_PURPOSE'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <div class="d-flex row-select mb-2">
            <mat-checkbox
              class="p-0"
              [(ngModel)]="isArchivalWorkPurposeVisible"
              [ngModelOptions]="{ standalone: true }"
            >
              {{
                'GEODETIC_AND_CARTOGRAPHIC_SERVICE_DATA_REQUEST.NEW_REQUEST.ARCHIVAL_WORK_PURPOSES'
                  | translate
              }}</mat-checkbox
            >
          </div>
          <div class="d-flex row-select">
            <mat-select
              class="form-select form-select-sm"
              [id]="controlName.WorkPurpose"
              [attr.name]="controlName.WorkPurpose"
              [formControlName]="controlName.WorkPurpose"
              [placeholder]="
                'GEODETIC_AND_CARTOGRAPHIC_SERVICE_DATA_REQUEST.NEW_REQUEST.SELECT_WORK_PURPOSE'
                  | translate
              "
              multiple
            >
              @for (
                field of workPurposeDictionaryService.get() | async;
                track field.id
              ) {
                @if (!field.isArchival || isArchivalWorkPurposeVisible) {
                  <mat-option [value]="field.id">
                    {{ field.name }}
                  </mat-option>
                }
              }
            </mat-select>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="card border-dark mb-4">
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.Materials">
            {{
              'GEODETIC_AND_CARTOGRAPHIC_SERVICE_DATA_REQUEST.NEW_REQUEST.MATERIALS'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <input
            type="text"
            [name]="controlName.Materials"
            [id]="controlName.Materials"
            class="form-control"
            [formControlName]="controlName.Materials"
          />
        </div>
      </div>
    </section>

    <section>
      <div class="card border-dark mb-4">
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.DataRange">
            {{
              'GEODETIC_AND_CARTOGRAPHIC_SERVICE_DATA_REQUEST.NEW_REQUEST.DATA_RANGE'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <input
            type="text"
            [name]="controlName.DataRange"
            [id]="controlName.DataRange"
            class="form-control"
            [formControlName]="controlName.DataRange"
          />
        </div>
      </div>
    </section>

    <section>
      <div class="card border-dark mb-4">
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.AdditionalInfo">
            {{
              'GEODETIC_AND_CARTOGRAPHIC_SERVICE_DATA_REQUEST.NEW_REQUEST.ADDITIONAL_INFO'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <textarea
            [name]="controlName.AdditionalInfo"
            [id]="controlName.AdditionalInfo"
            class="form-control form-control-sm"
            [formControlName]="controlName.AdditionalInfo"
          ></textarea>
        </div>
      </div>
    </section>

    @if (portalId) {
      <section
        class="card mb-4"
        [ngClass]="
          checkShippingFormGroupValidAndFormSubmitted()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'GEODETIC_AND_CARTOGRAPHIC_SERVICE_DATA_REQUEST.NEW_REQUEST.SHIPPING_FORM'
              | translate
          }}
        </div>
        <div class="card-body">
          <app-shipping-form
            [shippingFormGroup]="getShippingFormGroup()"
            [decisionForm]="decisionFormValue"
            [portalId]="portalId"
          ></app-shipping-form>
        </div>
        @if (checkShippingFormGroupValidAndFormSubmitted()) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{
                'GEODETIC_AND_CARTOGRAPHIC_SERVICE_DATA_REQUEST.NEW_REQUEST.SHIPPING_FORM_REQUIRED'
                  | translate
              }}
            </div>
          </div>
        }
      </section>
    }

    <section class="my-2">
      <app-cautions
        [portalId]="portalId"
        [formGroup]="formGroup"
        [submitted]="submitted"
      ></app-cautions>
    </section>

    <section class="my-2">
      @if (submitted && !isRequestValid()) {
        <div class="alert alert-danger mt-1">
          <span>
            {{
              'GEODETIC_AND_CARTOGRAPHIC_SERVICE_DATA_REQUEST.NEW_REQUEST.PROVIDE_REQUIRED_FIELDS'
                | translate
            }}
          </span>
        </div>
      }
      @if (errorSubmitMessage) {
        <div class="alert alert-danger mt-2">
          <span>{{ errorSubmitMessage }}</span>
        </div>
      }
    </section>

    <section>
      <button type="submit" class="btn btn-primary me-2">
        {{
          'GEODETIC_AND_CARTOGRAPHIC_SERVICE_DATA_REQUEST.NEW_REQUEST.SUBMIT'
            | translate
        }}
      </button>

      <button
        type="button"
        [routerLink]="['/office-departments-portal/departments']"
        class="btn btn-outline-primary"
      >
        {{
          'GEODETIC_AND_CARTOGRAPHIC_SERVICE_DATA_REQUEST.NEW_REQUEST.CANCEL'
            | translate
        }}
      </button>

      <div class="d-flex flex-column">
        @if (docSignMsgTxt) {
          <span [ngClass]="'text-' + docSignMsgType">
            {{ docSignMsgTxt }}
          </span>
        }
        @if (docSignUrl) {
          <span class="my-2 text-info">
            {{ docSignTranslations['SIGN_INFO'] }}
          </span>
        }
        @if (docSignUrl) {
          <a class="link" [href]="docSignUrl" target="_blank">
            {{ docSignTranslations['SIGN'] }}
          </a>
        }
        @if (docSignPending) {
          <div class="d-flex justify-content-center my-3">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
            </div>
          </div>
        }
      </div>

      <div class="mt-2"><app-form-legend></app-form-legend></div>
    </section>
  </form>
</div>

<ng-template #canDeactivateModal let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'MODAL_CAN_DEACTIVATE.ALERT_TITLE' | translate }}
    </h4>
  </div>

  <div class="modal-body">
    {{ 'MODAL_CAN_DEACTIVATE.DESCRIPTION' | translate }}
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="close(false)">
      {{ 'MODAL_CAN_DEACTIVATE.CANCEL' | translate }}
    </button>

    <button type="button" class="btn btn-primary" (click)="close(true)">
      {{ 'MODAL_CAN_DEACTIVATE.CONFIRM' | translate }}
    </button>
  </div>
</ng-template>
