import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';

export function atLeastOneValidator(
  ignoredControlsKeys?: string[]
): ValidatorFn {
  return (controlGroup: AbstractControl): { [key: string]: boolean } | null => {
    if (!(<FormGroup>controlGroup).controls) {
      return null;
    }
    const controlsKeys = Object.keys((<FormGroup>controlGroup).controls).filter(
      (controlKey) =>
        !(ignoredControlsKeys && ignoredControlsKeys.length) ||
        !ignoredControlsKeys.includes(controlKey)
    );
    const isValidControl = controlsKeys.some(
      (key) => !!(<FormGroup>controlGroup).controls[key].value
    );

    return isValidControl ? null : { atLeastOneRequired: true };
  };
}
