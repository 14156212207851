import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TfaResetControlName, TfaResetFormGroup } from './tfa-reset-form.model';

@Injectable()
export class TfaResetFormService {
  constructor(private formBuilder: FormBuilder) {}

  getFormGroup(): FormGroup<TfaResetFormGroup> {
    return this.formBuilder.group<TfaResetFormGroup>({
      [TfaResetControlName.ResetToken]: this.formBuilder.control('', [
        Validators.required,
      ]),
    });
  }
}
