import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { getDefaultControlValue } from '@gk/gk-modules';
import { DecisionFormType } from '../../../excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { InvestorDetailsFormService } from '../../../investor-details-form/investor-details-form.service';
import { ShippingFormService } from '../../../shipping-form/shipping-form.service';
import { WaterEquipmentControlName } from './water-equipment-form.model';

@Injectable()
export class WaterEquipmentFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private investorDetailsFormService: InvestorDetailsFormService,
    public shippingFormService: ShippingFormService
  ) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [WaterEquipmentControlName.InvestorDetails]:
        this.investorDetailsFormService.getFormGroup(),
      [WaterEquipmentControlName.ProxyDetailsCheckbox]: [
        getDefaultControlValue(),
      ],
      [WaterEquipmentControlName.BirthDate]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [WaterEquipmentControlName.BirthPlace]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [WaterEquipmentControlName.WatercraftType]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [WaterEquipmentControlName.StagingPoint]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [WaterEquipmentControlName.DriveType]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [WaterEquipmentControlName.TotalLength]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [WaterEquipmentControlName.SideHeight]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [WaterEquipmentControlName.LargestWidth]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [WaterEquipmentControlName.SeatsNumber]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [WaterEquipmentControlName.ConstructionMaterialType]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [WaterEquipmentControlName.TechnicalConditionStatementCheckbox]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [WaterEquipmentControlName.CommitmentStatementCheckbox]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [WaterEquipmentControlName.DecisionForm]: [
        DecisionFormType.ElectronicForm,
        [Validators.required],
      ],
      [WaterEquipmentControlName.ShippingForm]:
        this.shippingFormService.getFormGroup(),
    });
  }
}
