<kendo-dialog-actions
  class="border-0"
  [layout]="buttonsConfigs.actionsLayout || 'end'"
>
  @for (buttonConfig of buttonsConfigs.buttons; track $index) {
    @if (buttonConfig.isVisible ? buttonConfig.isVisible() : true) {
      <button
        [type]="buttonConfig.type || 'button'"
        kendoButton
        [title]="buttonConfig?.title || '' | translate"
        [iconClass]="buttonConfig?.iconClass!"
        [icon]="buttonConfig?.icon!"
        [svgIcon]="buttonConfig?.svgIcon!"
        (click)="onGridActionButtonClick(buttonConfig, $event)"
        [themeColor]="buttonConfig.themeColor || 'base'"
        [disabled]="getButtonDisabledState(buttonConfig)"
        [className]="buttonConfig?.className"
      >
        {{ buttonConfig.text || '' | translate }}
      </button>
    }
  }
</kendo-dialog-actions>
