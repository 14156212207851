@if (shouldShowControl()) {
  <div gkControlRow>
    <div gkControlLabel [showLabel]="controlConfig.showLabel">
      <gk-translated-label
        [configLabel]="controlConfig.label"
        ></gk-translated-label
        >:
      </div>
      <div gkControlValue [showLabel]="controlConfig.showLabel">
        <div class="table-responsive">
          <table class="table m-0">
            <thead>
              <tr>
                @for (config of controlConfig.controlConfigs; track config) {
                  <th
                    class="ps-2"
                    scope="col"
                    >
                    <gk-translated-label
                      [configLabel]="config.label"
                    ></gk-translated-label>
                  </th>
                }
              </tr>
            </thead>
            <tbody>
              @for (rowData of getDataArray(); track rowData) {
                <tr>
                  @for (config of controlConfig.controlConfigs; track config) {
                    <th
                      class="p-0 fw-normal"
                      >
                      <gk-control-renderer
                        [data]="rowData"
                        [controlConfig]="config"
                      ></gk-control-renderer>
                    </th>
                  }
                </tr>
              }
              @if (!getDataArray().length) {
                <span class="my-2">{{
                  'GK.DYNAMIC_LIST.LACK' | translate
                }}</span>
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  }
