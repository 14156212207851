import { Injectable } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DictionaryField, atLeastOneArrayValidator } from '@gk/gk-modules';
import { EcoNewsletterControlName } from './eco-newsletter-form.model';

@Injectable()
export class EcoNewsletterFormService {
  constructor(private formBuilder: UntypedFormBuilder) {}

  getFormGroup(
    subjects: DictionaryField[],
    types: DictionaryField[]
  ): UntypedFormGroup {
    return this.formBuilder.group({
      [EcoNewsletterControlName.Subjects]:
        this.getFormArrayFromDictionaryFields(subjects),
      [EcoNewsletterControlName.Types]:
        this.getFormArrayFromDictionaryFields(types),
      [EcoNewsletterControlName.Email]: this.formBuilder.control(
        null,
        Validators.required
      ),
    });
  }

  getFormArrayFromDictionaryFields(
    fields: DictionaryField[]
  ): UntypedFormArray {
    return this.formBuilder.array(
      fields.map(() => this.getFormControl()),
      atLeastOneArrayValidator()
    );
  }

  getFormControl(): UntypedFormControl {
    return this.formBuilder.control(false);
  }
}
