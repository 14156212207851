<ul ngbNav #nav="ngbNav" class="nav nav-tabs">
  @for (lang of langTerms; track lang) {
    <li [ngbNavItem]="lang" class="nav-item">
      <a ngbNavLink> {{ lang | uppercase }} </a>
      <ng-template ngbNavContent>
        <div class="modal-header">
          <h4 class="modal-title">
            {{ getTranslationByLanguage(lang, 'WARNING') }}
          </h4>
        </div>
        <div class="modal-body">
          {{ getTranslationByLanguage(lang, 'PORTAL_USAGE') }}
          <div class="text-center mt-3">
            <button
              type="button"
              class="btn btn-info"
              (click)="openTermsOfUse(lang)"
              >
              {{ getTranslationByLanguage(lang, 'OPEN_TERMS_OF_USE') }}
            </button>
          </div>
          <div class="form-check mt-3">
            <input
              class="form-check-input"
              type="checkbox"
              id="terms-of-use-declaration-portal"
              [(ngModel)]="areTermsOfUseRead"
              aria-required="true"
              />
            <label
              class="form-check-label control-required-label"
              for="terms-of-use-declaration-portal"
              >
              {{ getTranslationByLanguage(lang, 'DECLARATION') }}
            </label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            (click)="closeModal()"
            [disabled]="!areTermsOfUseRead"
            >
            {{ getTranslationByLanguage(lang, 'TO_PORTAL') }}
          </button>
        </div>
      </ng-template>
    </li>
  }
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
