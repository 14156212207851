import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { UUID } from 'angular2-uuid';
import { NgbDateRange } from '../../services/new-request/new-request.model';
import { DateHelperUtil } from '../../utils/date-helper/date-helper.util';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent {
  @Output() dateRangeChanged = new EventEmitter<NgbDateRange>();
  @Input() required = false;
  @Input() id: string = UUID.UUID();
  @Input() currentDateIsMinDate = false;
  @Input() minimumNumberOfDaysFromTodayInToDate = 0;
  currentDate = DateHelperUtil.getCurrentNgbDate();
  fromDate: NgbDate;
  toDate: NgbDate;

  getMinToDate(): undefined | NgbDate {
    const fromDate = this.fromDate || this.getMinFromDate();
    return this.minimumNumberOfDaysFromTodayInToDate &&
      fromDate &&
      DateHelperUtil.areDatesEqual(fromDate, this.currentDate)
      ? DateHelperUtil.addDaysToNgbDate(
          fromDate,
          this.minimumNumberOfDaysFromTodayInToDate,
        )
      : fromDate;
  }

  getMinFromDate(): undefined | NgbDate {
    return this.currentDateIsMinDate ? this.currentDate : undefined;
  }

  setPeriod(period: NgbDateRange): void {
    this.fromDate = period.from;
    this.toDate = period.to;

    this.handleDateChanged();
  }

  resetPeriod(): void {
    this.fromDate = undefined;
    this.toDate = undefined;

    this.handleDateChanged();
  }

  handleDateChanged(): void {
    this.dateRangeChanged.emit(new NgbDateRange(this.fromDate, this.toDate));
  }
}
