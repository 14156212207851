import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { RequestInProgressGuard } from "../../guards/request-in-progress/request-in-progress.guard";
import { SessionGuard } from "../../guards/session/session.guard";
import { MainRoutes } from "./../../guards/guard.models";
import { MainRequestsListComponent } from "./../../shared/main-requests-list/main-requests-list.component";
import { SFB70CCNewRequestComponent } from "./new-request/new-request.component";

const singleFamilyBuilding70ConstructionConversionRoutes: Routes = [
  {
    path: MainRoutes.SingleFamilyBuilding70ConstructionConversion,
    canActivate: [SessionGuard],
    children: [
      {
        path: "",
        redirectTo: "new-request",
        pathMatch: "full",
      },
      {
        path: "new-request",
        component: SFB70CCNewRequestComponent,
        canDeactivate: [RequestInProgressGuard],
      },
      {
        path: "requests-list",
        component: MainRequestsListComponent,
      },
      {
        path: "**",
        redirectTo: "new-request",
        pathMatch: "full",
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(singleFamilyBuilding70ConstructionConversionRoutes),
  ],
  providers: [SessionGuard, RequestInProgressGuard],
  exports: [RouterModule],
})
export class SingleFamilyBuilding70ConstructionConversionRoutingModule {}
