import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SystemConfigBlobGuestService } from '@gk/gk-modules';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  private isAlive = true;
  webFooterLogoBase64Data: string;

  constructor(
    public systemConfigBlobGuestService: SystemConfigBlobGuestService,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.subscribeToWebFooterLogo();
  }

  subscribeToWebFooterLogo(): void {
    this.systemConfigBlobGuestService
      .getSysConfigWebFooterLogoData()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(webFooterLogoBase64Data => {
        if (webFooterLogoBase64Data) {
          this.webFooterLogoBase64Data = this.domSanitizer.bypassSecurityTrustResourceUrl(
            `data:image/png;base64,${webFooterLogoBase64Data}`
          ) as string;
        }
      });
  }
}
