import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  DocSignService,
  FormAlertService,
  MapSettingsService,
  MapStateService,
  PortalId,
  PzService,
} from '@gk/gk-modules';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, from, switchMap, takeWhile, tap } from 'rxjs';
import { MainRoutes } from '../../../guards/guard.models';
import { RequestInProgress } from '../../../guards/request-in-progress/request-in-progress.guard';
import { AuthorizedPersonControlName } from '../../../services/authorized-person-form/authorized-person-form.model';
import { ConstructionLogDocTypeId } from '../../../services/construction-log/construction-log-doc-type/construction-log-doc-type.model';
import { ConstructionLogDocTypeService } from '../../../services/construction-log/construction-log-doc-type/construction-log-doc-type.service';
import { ConstructionLogControlName } from '../../../services/construction-log/construction-log-form/construction-log-form.model';
import { ConstructionLogFormService } from '../../../services/construction-log/construction-log-form/construction-log-form.service';
import {
  ConstructionLog,
  ConstructionLogRequestDto,
  ConstructionLogType,
} from '../../../services/construction-log/construction-log/construction-log.model';
import { ApiNewDokumentPowiazanyDalDto } from '../../../services/designer-incoming-documents/designer-incoming-documents.model';
import { DesignerIncomingDocumentsService } from '../../../services/designer-incoming-documents/designer-incoming-documents.service';
import { DocType } from '../../../services/doc-type/doc-type.model';
import { DecisionFormType } from '../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { InvestorDetailsFormService } from '../../../services/investor-details-form/investor-details-form.service';
import { DocFile } from '../../../services/new-designer-request/new-designer-request.model';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { BsMessageType } from '../../../services/request-workspace-state/request-workspace-state.model';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import { InvestorDetailsComponent } from '../../../shared/investor-details/investor-details.component';

@Component({
  selector: 'app-construction-log-request',
  templateUrl: './construction-log-request.component.html',
})
export class ConstructionLogRequestComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy, RequestInProgress
{
  @ViewChild(InvestorDetailsComponent)
  investorDetailsComponent: InvestorDetailsComponent;
  @ViewChild('canDeactivateModal')
  canDeactivateModal: NgbModalRef;
  override controlName = ConstructionLogControlName;
  proxyDetailsVisible = false;
  formValue: ConstructionLogRequestDto;
  override decisionFormValue = DecisionFormType.ElectronicForm;
  formNotValidTranslation: string;
  chosenFiles: { [key in ConstructionLogDocTypeId]?: File[] } = {};
  requestSuccessfullySigned = true;
  constructionLogType = ConstructionLogType;
  override portalId = PortalId.ConstructionPortalConstructionLogRequest;

  constructor(
    public constructionLogFormService: ConstructionLogFormService,
    public constructionLogDocTypeService: ConstructionLogDocTypeService,
    private designerIncomingDocumentsService: DesignerIncomingDocumentsService,
    private investorDetailsFormService: InvestorDetailsFormService,
    private toastr: ToastrService,
    public override translateService: TranslateService,
    public override docSignService: DocSignService,
    public override newRequestHelperService: NewRequestHelperService,
    public override router: Router,
    private modalService: NgbModal,
    public override formAlertService: FormAlertService,
    public override pzService: PzService,
    public override mapSettingsService: MapSettingsService,
    public override mapStateService: MapStateService,
  ) {
    super(
      pzService,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService,
      formAlertService,
    );
  }

  override ngOnInit(): void {
    this.createForm();
    this.subscribeToFormNotValidTranslation();
    this.subscribeToProxyDetailsCheckboxFormControl();
    this.fetchConstructionLogDocType();
    super.subscribeToDocSignTranslations();
  }

  createForm(): void {
    this.formGroup = this.constructionLogFormService.getFormGroup();
  }

  subscribeToFormNotValidTranslation(): void {
    this.translateService
      .get(
        'CONSTRUCTION_PORTAL.CONSTRUCTION_LOG.VALIDATION.FORM_NOT_VALID_ERROR',
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (formNotValidTranslation) =>
          (this.formNotValidTranslation = formNotValidTranslation),
      );
  }

  subscribeToProxyDetailsCheckboxFormControl(): void {
    this.getProxyDetailsCheckboxFormControl()
      .valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe((proxyDetailsChecked) => {
        this.constructionLogDocTypeService.getOrUpdateObligatoryDocTypes(
          proxyDetailsChecked,
        );
        this.toggleFormVisibilityByProxyDetailsChecked(proxyDetailsChecked);
      });
  }

  fetchConstructionLogDocType(): void {
    this.constructionLogDocTypeService.docTypes
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => (this.docTypes = data));
  }

  toggleFormVisibilityByProxyDetailsChecked(
    proxyDetailsChecked: boolean,
  ): void {
    if (proxyDetailsChecked) {
      this.formGroup.addControl(
        ConstructionLogControlName.ProxyDetails,
        this.investorDetailsFormService.getFormGroup(),
      );
    } else {
      this.formGroup.removeControl(ConstructionLogControlName.ProxyDetails);
    }
    this.toggleProxyDetails();
  }

  toggleProxyDetails(): void {
    this.proxyDetailsVisible = !this.proxyDetailsVisible;
  }

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    if (!this.isRequestValid()) {
      this.toastr.error(this.formNotValidTranslation);
      return;
    }
    const apiFiles = await Promise.all(this.getConvertedFiles()).catch(
      () => new Error(),
    );
    if (
      (Array.isArray(apiFiles) &&
        apiFiles.some((file) => file instanceof Error)) ||
      apiFiles instanceof Error
    ) {
      this.toastr.error(this.wrongFilesErrorText);

      return;
    }

    this.sendRequest(apiFiles as ApiNewDokumentPowiazanyDalDto[]);
  }

  private sendRequest(apiDocuments: ApiNewDokumentPowiazanyDalDto[]): void {
    this.investorDetailsComponent
      .askForPostOfficeWhenPostalCodeIsNotFromDictionary()
      .pipe(
        tap(() => {
          this.docSignPending = true;
          this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');
        }),
        switchMap(() =>
          this.docSignService.addToSign(
            ConstructionLogRequestDto.fromAppToApi(
              this.getFormValue(),
              apiDocuments,
            ),
            '/api/interesant/wniosek/rejestracjaDziennikaBudowy/addToSign',
          ),
        ),
        takeWhile(() => this.isAlive),
      )
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleSendAndValidateSuccess(addedDocToSignResponse);
        },
        error: () => this.handleSendAndValidateFailure(),
      });
  }

  override handleDocSignSuccess(): void {
    this.requestSuccessfullySigned = true;
    this.docSignUrl = '';
    this.router.navigateByUrl(
      `/${MainRoutes.ConstructionPortalConstructionLogRequest}/requests-list`,
    );
  }

  isRequestValid(): boolean {
    return this.formGroup.valid && this.areDocumentsValid();
  }

  getFormValue(): ConstructionLog {
    return {
      ...this.formGroup.value,
    };
  }

  getConvertedFiles(): Promise<ApiNewDokumentPowiazanyDalDto | Error>[] {
    return this.getChosenFiles().map((docFile) =>
      this.designerIncomingDocumentsService.docFileToDtoDocument(docFile),
    );
  }

  getChosenFiles(): DocFile[] {
    return [].concat(...Object.values(this.chosenFiles));
  }

  getInvestorDetailsFormGroup(): UntypedFormGroup {
    return this.formGroup.get(
      ConstructionLogControlName.InvestorDetails,
    ) as UntypedFormGroup;
  }

  getAuthorizedDetailsFormGroup(): UntypedFormGroup {
    return this.formGroup.get(
      AuthorizedPersonControlName.AuthorizedDetailsForm,
    ) as UntypedFormGroup;
  }

  getProxyDetailsFormGroup(): UntypedFormGroup {
    return this.formGroup.get(
      ConstructionLogControlName.ProxyDetails,
    ) as UntypedFormGroup;
  }

  getProxyDetailsCheckboxFormControl(): UntypedFormControl {
    return this.formGroup.get(
      ConstructionLogControlName.ProxyDetailsCheckbox,
    ) as UntypedFormControl;
  }

  areDocumentsValid(): boolean {
    return this.constructionLogDocTypeService.areObligatoryDocFilesAttached(
      this.getChosenFiles(),
    );
  }

  checkInvestorDetailsFormGroupValidAndFormValidated(): boolean {
    return !this.getInvestorDetailsFormGroup().valid && this.submitted;
  }

  checkProxyDetailsFormGroupValidAndFormValidated(): boolean {
    return (
      this.getProxyDetailsFormGroup() &&
      !this.getProxyDetailsFormGroup().valid &&
      this.proxyDetailsVisible &&
      this.submitted
    );
  }

  shouldShowFileInputInvalidMessage(
    docType: DocType<ConstructionLogDocTypeId>,
  ): boolean {
    return this.submitted && !this.areChosenObligatoryDocuments(docType);
  }

  areChosenObligatoryDocuments(
    docType: DocType<ConstructionLogDocTypeId>,
  ): boolean {
    const files = this.chosenFiles[docType.id];

    return (
      !this.constructionLogDocTypeService.obligatoryDocTypes.find(
        (obligatoryDocType) => obligatoryDocType.id === docType.id,
      ) || !!(files && files.length)
    );
  }

  handleFileInputAction(id: ConstructionLogDocTypeId, files: DocFile[]): void {
    this.chosenFiles[id] = this.getFiles(id, files);
  }

  getFiles(id: ConstructionLogDocTypeId, files: DocFile[]): File[] {
    return files.map((file) => {
      file.docTypeId = id;

      return file;
    });
  }

  checkDocTypeIsRequired(id: number): DocType {
    return this.constructionLogDocTypeService.obligatoryDocTypes.find(
      (obligatoryDocType) => obligatoryDocType.id === id,
    );
  }

  shouldShowErrorAlert(
    formControl: UntypedFormControl,
    errorName: string,
  ): boolean {
    return (
      this.isFieldReadyToShowAlert(formControl) &&
      this.hasError(formControl, errorName)
    );
  }

  isFieldReadyToShowAlert(formControl: UntypedFormControl): boolean {
    return !!(formControl && (formControl.dirty || formControl.touched));
  }

  hasError(formControl: UntypedFormControl, errorName: string): boolean {
    return !!(
      formControl &&
      formControl.errors &&
      formControl.errors[errorName]
    );
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.requestSuccessfullySigned) {
      return true;
    }
    return this.isRequestDirty()
      ? from(
          this.modalService.open(this.canDeactivateModal, {
            beforeDismiss: () => false,
          }).result,
        )
      : true;
  }

  isRequestDirty(): boolean {
    return this.formGroup.dirty || this.areChosenDocuments();
  }

  areChosenDocuments(): boolean {
    return this.docTypes.some(
      (docType: DocType<ConstructionLogDocTypeId>) =>
        !!(this.chosenFiles[docType.id] && this.chosenFiles[docType.id].length),
    );
  }

  checkDecisionFormControlsValidAndFormValidated(): boolean {
    return (
      (!this.getDecisionNumberFormControl().valid && this.submitted) ||
      (!this.getDecisionDateFormControl().valid && this.submitted) ||
      (!this.getDecisionPublishedByFormControl().valid && this.submitted)
    );
  }

  getDecisionNumberFormControl(): UntypedFormControl {
    return this.formGroup.get(
      ConstructionLogControlName.DecisionNumber,
    ) as UntypedFormControl;
  }

  getDecisionDateFormControl(): UntypedFormControl {
    return this.formGroup.get(
      ConstructionLogControlName.DecisionDate,
    ) as UntypedFormControl;
  }

  getDecisionPublishedByFormControl(): UntypedFormControl {
    return this.formGroup.get(
      ConstructionLogControlName.DecisionPublishedBy,
    ) as UntypedFormControl;
  }

  checkConstructionLogTypeFormControlValidAndFormValidated(): boolean {
    return !this.getConstructionLogTypeFormControl().valid && this.submitted;
  }

  getConstructionLogTypeFormControl(): UntypedFormControl {
    return this.formGroup.get(
      ConstructionLogControlName.ConstructionLogType,
    ) as UntypedFormControl;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
