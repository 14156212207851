import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { filter, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { StorageUtils } from '../../gk-map/utils';
import { SystemConfig } from '../system-config-guest/system-config-guest.model';
import { SystemConfigGuestService } from '../system-config-guest/system-config-guest.service';

@Injectable()
export class PlannedApplicationMessageService {
  serviceMessageInfoAcceptedLocalStorageDataKey = 'serviceMessageInfoAccepted';
  private weServiceInfoKeyOnOff: Observable<number>;

  constructor(
    private toastr: ToastrService,
    public systemConfigGuestService: SystemConfigGuestService
  ) {}

  toasterClickedHandler() {
    StorageUtils.setItem(
      this.serviceMessageInfoAcceptedLocalStorageDataKey,
      true
    );
  }

  showServiceMessage(weServiceInfoKeyText: string): void {
    this.toastr
      .info(weServiceInfoKeyText, null, {
        timeOut: 0,
        extendedTimeOut: 0,
        enableHtml: true,
        closeButton: true,
        messageClass: 'scroll-overflow-y-service-message'
      })
      .onTap.pipe(take(1))
      .subscribe({ complete: () => this.toasterClickedHandler() });
  }

  setServiceMessageInfoAcceptedLocalStorageDataKey(value: boolean) {
    StorageUtils.setItem(
      this.serviceMessageInfoAcceptedLocalStorageDataKey,
      value
    );
  }

  getWeServiceInfoKeyOnOff(): Observable<number> {
    if (!this.weServiceInfoKeyOnOff) {
      this.weServiceInfoKeyOnOff = this.systemConfigGuestService
        .getSysConfigGuestValueByKey(SystemConfig.WeServiceInfoKeyOnOff)
        .pipe(
          map((data) => parseInt(data, 10)),
          shareReplay(1)
        );
    }

    return this.weServiceInfoKeyOnOff;
  }

  maybeShowMessage(): void {
    this.getWeServiceInfoKeyOnOff()
      .pipe(
        filter((data) => data === 1),
        switchMap(() =>
          of(
            StorageUtils.getItem(
              this.serviceMessageInfoAcceptedLocalStorageDataKey
            )
          )
        ),
        filter((serviceMessageInfoAccepted) => !serviceMessageInfoAccepted),
        switchMap(() =>
          this.systemConfigGuestService.getSysConfigGuestValueByKey(
            SystemConfig.WeServiceInfoKeyText
          )
        )
      )
      .subscribe((weServiceInfoKeyText) =>
        this.showServiceMessage(weServiceInfoKeyText)
      );
  }
}
