import {
  EgibObject,
  FieldId,
  LawPerson,
  LawPersonType,
  NewDesignerRequestDto,
  NewLegalPersonRequestDto,
  NewNaturalPersonRequestDto,
} from '@gk/gk-modules';
import { ApiNewDokumentPowiazanyDalDto } from '../../../../services/designer-incoming-documents/designer-incoming-documents.model';
import { DecisionFormType } from '../../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { ZamowienieDzialkaDto } from '../../../../services/excluding-land-from-agricultural-production/agricultural-land-use/agricultural-land-use.model';
import {
  Shipping,
  ShippingDto,
} from '../../../../shared/shipping-form/shipping-form.model';
import {
  AuthorizedPersonToRegistryView,
  AuthorizedPersonToRegistryViewDto,
} from './../../../../services/authorized-person-to-registry-view/authorized-person-to-registry-view.model';

export interface SCOLNewCarryOutRequestDto {
  Wnioskodawca:
    | NewNaturalPersonRequestDto
    | NewLegalPersonRequestDto
    | NewDesignerRequestDto;
  Uzasadnienie: string;
  Zalaczniki: ApiNewDokumentPowiazanyDalDto[];
  FormaDecyzji: number;
  Odbior: ShippingDto;
  WladanieId: FieldId;
  Dzialki: ZamowienieDzialkaDto[];
  UpowaznieniInfo: AuthorizedPersonToRegistryViewDto[];
}

export class SCOLNewCarryOutRequest {
  constructor(
    public lawPerson: LawPerson,
    public reason: string,
    public decisionForm: DecisionFormType,
    public shippingForm: Shipping,
    public demonstrationOfApplicantRight: FieldId,
    public enableWorkByPerson: string
  ) {}

  static fromAppToApi(
    data: SCOLNewCarryOutRequest,
    parcels: EgibObject[],
    files: ApiNewDokumentPowiazanyDalDto[]
  ): SCOLNewCarryOutRequestDto {
    return {
      Wnioskodawca:
        data.lawPerson.type === LawPersonType.Natural
          ? NewNaturalPersonRequestDto.fromAppToApi(data.lawPerson)
          : NewLegalPersonRequestDto.fromAppToApi(data.lawPerson),
      Uzasadnienie: data.reason,
      WladanieId: data.demonstrationOfApplicantRight,
      Zalaczniki: files,
      FormaDecyzji: data.decisionForm,
      Dzialki: parcels.map((parcel) =>
        ZamowienieDzialkaDto.fromAppToApi(parcel)
      ),
      UpowaznieniInfo: [
        AuthorizedPersonToRegistryView.fromStringDataToApi(
          data.enableWorkByPerson
        ),
      ],
      Odbior: ShippingDto.fromAppToApi(data.shippingForm),
    };
  }
}
