import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PokPortalRoutingModule } from './pok-portal-routing.module';
import { PokPortalComponent } from './pok-portal.component';

@NgModule({
  imports: [CommonModule, PokPortalRoutingModule],
  declarations: [PokPortalComponent]
})
export class PokPortalModule {}
