import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'gk-panel-wrapper-kendo-form-field',
  template: `
    <div class="card text-center my-2">
      <p class="card-header p-1">{{ props.label | translate }}</p>
      <div class="card-body p-1">
        <ng-container #fieldComponent></ng-container>
      </div>
    </div>
  `,
})
export class PanelWrapperFieldComponent extends FieldWrapper {
  @ViewChild('fieldComponent', { read: ViewContainerRef })
  override fieldComponent: ViewContainerRef;
  constructor() {
    super();
  }
}
