import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import {
  GkKendoWindowActionButtons,
  GkKendoWindowSettings,
  KendoWindowInstanceNames,
} from '../kendo-window.model';
import { WindowRef } from '@progress/kendo-angular-dialog/window/models/window-ref';
import { WindowService } from '@progress/kendo-angular-dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class GkKendoWindowService {
  private renderer: Renderer2;
  private windowInstancesMap = new Map<KendoWindowInstanceNames, WindowRef>();
  constructor(
    private deviceService: DeviceDetectorService,
    private translateService: TranslateService,
    private rendererFactory: RendererFactory2,
    private windowService: WindowService,
    @Inject('window') public window: Window,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  open(gkKendoWindowSettings: GkKendoWindowSettings): WindowRef {
    let windowReference: WindowRef;
    if (
      !this.windowInstancesMap.has(gkKendoWindowSettings.windowInstanceName)
    ) {
      windowReference = this.windowService.open({
        title: gkKendoWindowSettings.title
          ? this.translateService.instant(gkKendoWindowSettings.title)
          : '',
        content: gkKendoWindowSettings.content,
        height: gkKendoWindowSettings.height,
        width: gkKendoWindowSettings.width,
        resizable: !this.deviceService.isMobile(),
        state: this.deviceService.isMobile()
          ? 'maximized'
          : gkKendoWindowSettings.state || 'default',
        cssClass: `${gkKendoWindowSettings.cssClass} mh-100`,
        htmlAttributes: {
          windowInstanceName: gkKendoWindowSettings.windowInstanceName,
        },
      });
      if (windowReference.content) {
        windowReference.content.instance.parent = gkKendoWindowSettings.parent;
        windowReference.content.instance.windowRef = windowReference;
      }
      // if (gkKendoWindowSettings.windowFocus === false) {
      //   this.windowReference.window.instance.onComponentFocus = (): void =>
      //     undefined;
      // }

      // this.windowReference.window.instance.onComponentFocus = (): void => {
      //   this.windowReference.window.instance['renderer'].addClass(
      //     this.windowReference.window.instance['el'].nativeElement,
      //     'k-focus',
      //   );
      // };
      windowReference.window.instance.dragEnd.subscribe(() => {
        this.onWindowDragEnd(windowReference);
      });

      this.windowInstancesMap.set(
        gkKendoWindowSettings.windowInstanceName,
        windowReference,
      );
      windowReference.result.subscribe(() => {
        this.windowInstancesMap.delete(
          gkKendoWindowSettings.windowInstanceName,
        );
      });

      windowReference.window.instance.themeColor = 'primary';
      if (this.deviceService.isMobile()) {
        this.hideKendoWindowActions(windowReference, [
          GkKendoWindowActionButtons.restore,
        ]);
      }
      if (gkKendoWindowSettings.hiddenActionButtons?.length) {
        this.hideKendoWindowActions(
          windowReference,
          gkKendoWindowSettings.hiddenActionButtons,
        );
      }
    }

    return windowReference;
  }

  public hideKendoWindowActions(
    gkWindowRef: WindowRef,
    windowActions: GkKendoWindowActionButtons[],
  ): void {
    setTimeout(() => {
      windowActions.forEach((windowAction) => {
        const actionButton =
          gkWindowRef.window.location.nativeElement.querySelector(
            `button[${windowAction}]`,
          );
        if (actionButton) {
          this.renderer.addClass(actionButton, 'd-none');
        }
      });
    }, 1);
  }

  getWindowInstanceByName(
    name: KendoWindowInstanceNames,
  ): WindowRef | undefined {
    return this.windowInstancesMap.get(name);
  }

  private onWindowDragEnd(windowReference: WindowRef): void {
    const windowInstance = windowReference.window.instance;
    const offset = 0;

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const positionTop =
      windowHeight -
      (windowInstance.height
        ? windowInstance.height
        : windowReference.window.instance['el'].nativeElement.clientHeight) -
      offset;
    const positionLeft = windowWidth - windowInstance.width - offset;

    if (windowInstance.top < offset) {
      windowInstance.setOffset('top', offset);
    }

    if (windowInstance.top > positionTop) {
      windowInstance.setOffset('top', positionTop);
    }

    if (windowInstance.left < offset) {
      windowInstance.setOffset('left', offset);
    }

    if (windowInstance.left > positionLeft) {
      windowInstance.setOffset('left', positionLeft);
    }
  }
}
