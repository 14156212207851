export enum PprfBuildingFormControlName {
  BuildingType = 'buildingType',
  ConstructionYearFrom = 'constructionYearFrom',
  ConstructionYearTo = 'constructionYearTo',
  FloorsAboveFrom = 'floorsAboveFrom',
  FloorsAboveTo = 'floorsAboveTo',
  FloorsBelowFrom = 'floorsBelowFrom',
  FloorsBelowTo = 'floorsBelowTo',
  UsableAreaFrom = 'usableAreaFrom',
  UsableAreaTo = 'usableAreaTo',
}

export class PprfBuildingFormModel {
  constructor(
    public buildingType: number[],
    public constructionYearFrom: number,
    public constructionYearTo: number,
    public floorsAboveFrom: number,
    public floorsAboveTo: number,
    public floorsBelowFrom: number,
    public floorsBelowTo: number,
    public usableAreaFrom: number,
    public usableAreaTo: number,
  ) {}

  static getEmptyModel(): PprfBuildingFormModel {
    return new this(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
  }
}

export const pprfBuildingFormModel = PprfBuildingFormModel.getEmptyModel();
