import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiFilter,
  ApiResponse,
  FilterLogic,
  FilterOperatorType,
  FilterWrapper,
  RequestBodyForList,
} from '@gk/gk-modules';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { handleApiError } from '../services.utils';
import { Place, PlaceFromApi } from './place.model';

@Injectable()
export class PlaceService {
  constructor(private httpClient: HttpClient) {}

  getPlacesByTerm(term = ''): Observable<Place[]> {
    return this.getPlacesByScope(term, 'global');
  }

  getLocalPlacesByTerm(term = ''): Observable<Place[]> {
    return this.getPlacesByScope(term, 'local');
  }

  private getPlacesByScope(term: string, scope: string): Observable<Place[]> {
    const filters: ApiFilter[] = [
      new ApiFilter('Nazwa', term, FilterOperatorType.StartsWith),
    ];
    const postBody = new RequestBodyForList(
      new FilterWrapper(FilterLogic.And, filters),
    );

    return this.httpClient
      .post<
        PlaceFromApi[]
      >(`/api/interesant/slo/miejscowosci/${scope}/cachedautocomplete`, postBody)
      .pipe(
        map((placesFromApi) =>
          placesFromApi.map((placeFromApi) => Place.fromApiToApp(placeFromApi)),
        ),
        catchError(handleApiError),
      );
  }

  getPlacesOfDistrict(districtId: string | number): Observable<Place[]> {
    return this.httpClient
      .get<
        ApiResponse<PlaceFromApi[]>
      >(`/api/interesant/slo/obreb/${districtId}/miejscowosci`)
      .pipe(
        map((placesFromApi) =>
          placesFromApi.Response.map((placeFromApi) =>
            Place.fromApiToApp(placeFromApi),
          ),
        ),
      );
  }
}
