import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResetPassword } from './reset-password.model';

@Injectable()
export class ResetPasswordService {
  constructor(private httpClient: HttpClient) {}

  resetPassword(data: ResetPassword): Observable<string> {
    return this.httpClient.post(
      '/api/system/login/passwordreset/sendEmail',
      ResetPassword.fromAppToApi(data),
      {
        responseType: 'text'
      }
    );
  }
}
