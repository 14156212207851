import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BasicResponse } from '../../utils/basic-response/basic-response.model';
import { map } from 'rxjs/operators';

@Injectable()
export class SystemConfigGuestService {
  constructor(private httpClient: HttpClient) {}

  getSysConfigGuestValueByKey(key: string): Observable<string> {
    return this.httpClient
      .get<BasicResponse>(`/api/system/konfig/guest/${key}`)
      .pipe(map(data => data.Result));
  }
}
