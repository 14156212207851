import { NgModule } from '@angular/core';
import { GkDynamicListModule, GkMapModule } from '@gk/gk-modules';
import { NewNetworkServiceRequestService } from '../../services/new-network-service-request/new-network-service-request.service';
import { SharedModule } from '../../shared/shared.module';
import { NetworkServiceOrderPortalRoutingModule } from './network-service-order-portal-routing.module';
import { NetworkServiceOrderPortalComponent } from './network-service-order-portal.component';
import { NewNetworkServiceOrderComponent } from './new-network-service-order/new-network-service-order.component';
import { UserRequestsDynamicComponent } from './user-requests-dynamic/user-requests-dynamic.component';

@NgModule({
  imports: [
    SharedModule,
    GkDynamicListModule,
    NetworkServiceOrderPortalRoutingModule,
    GkMapModule,
  ],
  declarations: [
    NetworkServiceOrderPortalComponent,
    NewNetworkServiceOrderComponent,
    UserRequestsDynamicComponent,
  ],
  providers: [NewNetworkServiceRequestService],
})
export class NetworkServiceOrderPortalModule {}
