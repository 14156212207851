@if (shouldShowControl()) {
  <div gkControlRow>
    <span gkControlLabel [showLabel]="controlConfig.showLabel">
      <gk-translated-label
        [configLabel]="controlConfig.label"
      ></gk-translated-label
      >:</span
    >
    <span class="w-sm-25 w-100 d-flex justify-content-center">
      <button (click)="downloadFile()" class="btn btn-outline-primary">
        <i [className]="controlConfig.printConfig.buttonIconClass"></i>
      </button>
    </span>
  </div>
}
