import {
  FieldId,
  LawPerson,
  NewDesignerRequestDto,
  NewLegalPersonRequestDto,
  NewNaturalPersonRequestDto,
} from '@gk/gk-modules';
import {
  Shipping,
  ShippingDto,
} from '../../../../shared/shipping-form/shipping-form.model';

export interface GACSDRNewRequestDto {
  Wnioskodawca:
    | NewNaturalPersonRequestDto
    | NewLegalPersonRequestDto
    | NewDesignerRequestDto;
  SygnaturaPracy: string;
  CelPracyId: FieldId[];
  Materialy: string;
  ZakresDanych: string;
  DodatkoweInfo: string;
  ZakresPracy: string[];
  Odbior: ShippingDto;
}

export class GACSDRNewRequest {
  constructor(
    public lawPerson: LawPerson,
    public contactData: LawPerson,
    public workSignature: string,
    public workPurpose: FieldId[],
    public materials: string,
    public dataRange: string,
    public additionalInfo: string,
    public shippingForm: Shipping,
  ) {}

  static fromAppToApi(
    data: GACSDRNewRequest,
    workRanges: string[],
  ): GACSDRNewRequestDto {
    return {
      Wnioskodawca: NewNaturalPersonRequestDto.fromAppToApi({
        ...data.lawPerson,
        naturalPersonData: {
          ...data.lawPerson.naturalPersonData,
          email: data.contactData.naturalPersonData.email,
          phoneNumber: data.contactData.naturalPersonData.phoneNumber,
        },
      }),
      SygnaturaPracy: data.workSignature,
      CelPracyId: data.workPurpose,
      Materialy: data.materials,
      ZakresDanych: data.dataRange,
      DodatkoweInfo: data.additionalInfo,
      ZakresPracy: workRanges,
      Odbior: ShippingDto.fromAppToApi(data.shippingForm),
    };
  }
}
