import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnDestroy,
  Optional,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  GridComponent,
  RowClassFn,
  ScrollMode,
  SelectableSettings,
} from '@progress/kendo-angular-grid';
import { FilterableSettings } from '@progress/kendo-angular-grid/filtering/filterable';
import { PagerSettings } from '@progress/kendo-angular-grid/pager/pager-settings';
import * as _ from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import {
  combineLatest,
  filter,
  map,
  Observable,
  of,
  takeWhile,
  tap,
} from 'rxjs';
import { GkDocumentPreviewComponent } from '../../gk-document-preview/gk-document-preview.component';
import { RequestType } from '../../gk-dynamic-list/gk-dynamic-list.model';
import {
  EgibObject,
  MapAction,
  MapObjectTableActionType,
  MapPreviewModeState,
  MapViewActionType,
  SourceActionType,
  SourceType,
  ToolActionType,
  ToolsState,
  ToolState,
  ToolType,
  Wkt,
} from '../../gk-map/models';
import { MapExtentUtils } from '../../gk-map/utils';
import { DownloadService } from '../../services';
import { MapSettingsService } from '../../services/map-settings/map-settings.service';
import { BasicResponse } from '../../utils/basic-response/basic-response.model';
import {
  allowedExtensionsToPreview,
  FileExtension,
} from '../../utils/files/files.model';
import { BaseDocumentationGridService } from '../base-documentation-grid/base-documentation-grid.service';
import { FileNamingPatternsComponent } from '../components/file-naming-patterns/file-naming-patterns.component';
import { ConfirmationConfigWithKeys } from '../components/gk-confirm-window/gk-confirm-window.model';
import { GkKendoDataBindingDirective } from '../gk-kendo-data-binding.directive';
import { GkKendoUploadService } from '../gk-kendo-upload';
import { DialogManagerService } from '../services/gk-dialog-manager.service';
import { GridDataService } from '../services/grid-data.service';
import { GkKendoWindowService } from '../services/kendo-window/gk-kendo-window/gk-kendo-window.service';
import { KendoWindowInstanceNames } from '../services/kendo-window/kendo-window.model';
import { GkKendoGridFooterConfig } from './gk-kendo-grid-footer/gk-kendo-grid-footer.model';
import { GkKendoGridMapService } from './gk-kendo-grid-map.service';
import {
  AbortRequestConfig,
  ColumnSetting,
  ColumnType,
  DEFAULT_MOBILE_COLUMN_WIDTH,
  GenericGridToolbarItemAddFilePayload,
  GenericGridToolbarItemDetailsPayload,
  GenericGridToolbarItemDownloadFilePayload,
  GenericGridToolbarItemPreviewFilePayload,
  GenericGridToolbarItemRemoveFilePayload,
  GenericGridToolbarItemShowGeometryPayload,
  GkKendoGridDataResult,
  GkKendoGridFormControlValue,
  GkKendoGridItemKey,
  GridToolBarItem,
  GridToolbarItemMapDrawingPayload,
  MIN_WIDTH_FOR_DATE_COLUMN,
} from './gk-kendo-grid.model';
import { takeUntil } from 'rxjs/operators';
import { BaseDetailsComponent } from '../base-details/base-details.component';

@Component({
  selector: 'gk-kendo-grid',
  templateUrl: './gk-kendo-grid.component.html',
  styleUrls: ['./gk-kendo-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GkKendoGridComponent<T extends object, U = void>
  implements OnDestroy, AfterViewInit, ControlValueAccessor
{
  @ViewChild(GridComponent) public gridComponent: GridComponent;
  @Input() public selectable: SelectableSettings | boolean = {
    enabled: true,
    mode: 'single',
  };
  @Input() public columns: ColumnSetting[];
  public isAlive = true;
  public toastr = inject(ToastrService);

  protected selectedKeys: any[] = [];
  protected gkKendoUploadService = inject(GkKendoUploadService);
  protected dialogManagerService = inject(DialogManagerService);
  protected baseDocumentationGridService = inject(BaseDocumentationGridService);
  protected gkKendoWindowService = inject(GkKendoWindowService);
  protected downloadService = inject(DownloadService);
  protected mapSettingsService = inject(MapSettingsService);
  protected translateService = inject(TranslateService);
  protected gkKendoGridMapService = inject(GkKendoGridMapService);
  protected changeDetectorRef = inject(ChangeDetectorRef);
  protected renderer = inject(Renderer2);
  protected deviceDetectorService = inject(DeviceDetectorService);
  protected columnType = ColumnType;
  protected firstRowSelected = false;
  protected unionResponseWithGridData = false;
  protected navigable = true;
  protected sortable = false;
  protected resizable = true;
  protected rowIndexColumn = false;
  protected pageable: boolean | PagerSettings = false;
  protected scrollable: ScrollMode;
  protected autoBind = false;
  protected pageSize: number;
  protected serverPagingSorting: boolean;
  protected topGridToolbarItems: GridToolBarItem[] = [];
  protected bottomGridToolbarItems: GridToolBarItem[] = [];
  protected selectionKey: GkKendoGridItemKey<T, U>;
  protected footerConfig: GkKendoGridFooterConfig;
  protected height = '100%';
  protected width = '100%';
  protected filterable: FilterableSettings = false;
  protected abortConfig: AbortRequestConfig;

  @ViewChild(GkKendoDataBindingDirective)
  private dataBindingDirective: GkKendoDataBindingDirective<T, U>;
  private gridToolbarItemMapDrawingPayload: GridToolbarItemMapDrawingPayload;
  private isFormControlTouched = false;

  constructor(@Optional() protected gridDataService: GridDataService<T, U>) {
    if (this.gridDataService) {
      this.gridDataService.componentInstance = this;
    }
  }

  protected get gridDataResult(): GkKendoGridDataResult<T, U> {
    return this.dataBindingDirective.gridDataResult;
  }

  public rebind(): void {
    this.dataBindingDirective.refreshGrid();
  }

  public removeSelectedGridItem(): void {
    this.dataBindingDirective.maybeEmitRemoveEvent();
  }

  public removeAllGridItems(): void {
    this.dataBindingDirective.emitRemoveAllGridItems();
  }

  public showMessageIfNoRowsSelected(): boolean {
    return this.dataBindingDirective.showMessageIfNoRowsSelected();
  }

  public ngOnDestroy(): void {
    this.isAlive = false;
  }

  public addFile(payload: GenericGridToolbarItemAddFilePayload): void {
    this.gkKendoUploadService.openUploadWindow({
      uploadSaveUrl: payload.uploadSaveUrl,
      uploadDocTypeDict: payload.uploadDocTypeDict,
      uploadData: {
        Owner: payload?.uploadData?.Owner,
        OwnerId: payload?.uploadData?.OwnerId,
        FaktUuid: payload?.uploadData?.FaktUuid,
      },
      onUploadButtonClick: payload.onUploadButtonClick,
      onUploadSuccess: (uploadEvent) => {
        this.rebind();
        payload.onUploadSuccess?.(uploadEvent);
      },
    });
  }

  public removeFile(payload: GenericGridToolbarItemRemoveFilePayload): void {
    if (!this.showMessageIfNoRowsSelected()) {
      return;
    }

    const url = this.matchUrlParamsWithGridSelectedItem(payload.removeUrl);
    if (!url) {
      console.error('No match url params with grid selected item');
      return;
    }

    this.dialogManagerService.showConfirmation({
      contentKey: 'Wybrane elementy zostaną usunięte. Czy chcesz kontynuować?',
      confirmSuccessCallback: (): void => {
        this.baseDocumentationGridService
          .deleteDocumentation(url)
          .pipe(takeWhile(() => this.isAlive))
          .subscribe(() => {
            this.rebind();
          });
      },
    });
  }

  public downloadFile(
    payload: GenericGridToolbarItemDownloadFilePayload,
  ): void {
    if (!this.showMessageIfNoRowsSelected()) {
      return;
    }

    const confirmConfig: ConfirmationConfigWithKeys = {
      titleKey: 'CONFIRM_DOWNLOAD.CONFIRM',
      contentKey: 'CONFIRM_DOWNLOAD.CONTENT',
      width: 300,
      height: 150,
      confirmSuccessCallback: () => {
        this.downloadService
          .downloadAndSaveFile(payload.downloadUrl, {
            FileUuids: this.getSelectedFileUuids(),
            KergId: payload.kergId,
            Origin: 'blob',
          })
          .add(() => {
            payload.successCallback?.();
          });
      },
    };
    this.dialogManagerService.showConfirmation(confirmConfig);
  }

  public showNamePatternFilesWindow(): void {
    const windowRef = this.gkKendoWindowService.open({
      parent: this,
      windowInstanceName: KendoWindowInstanceNames.NamePatternsFile,
      title: 'NAME_PATTERN_FILES.TITLE',
      content: FileNamingPatternsComponent,
      height: 360,
      width: 420,
    });
    windowRef.content.instance.namingPatternsUrlKey =
      '/api/geodeta/slo/operat/kdokrodz';
  }

  public showDocumentPreviewWindow(
    payload: GenericGridToolbarItemPreviewFilePayload,
  ): void {
    if (!this.showMessageIfNoRowsSelected()) {
      return;
    }

    const selectedFileUuids = this.getSelectedFileUuids();

    if (selectedFileUuids.length > 1) {
      this.showWarnPreviewAvailableForOnlyOneFile();

      return;
    }

    const selectedFileItem = this.gridDataService.$selection.value
      .selectedItems[0] as any;
    const selectedFileItemName = selectedFileItem.Nazwa;

    const fileExtension =
      this.getFileExtensionFromFileName(selectedFileItemName);
    if (!this.isAllowedFileExtension(fileExtension)) {
      this.showWarnUnsupportedExtensionToastr();

      return;
    }

    this.dialogManagerService.showConfirmation({
      titleKey: 'PREVIEW_DOCUMENT.CONFIRMATION',
      contentKey: 'PREVIEW_DOCUMENT.PREVIEW_IS_EQUAL_DOWNLOAD',
      confirmSuccessCallback: (): void => {
        const windowRef = this.gkKendoWindowService.open({
          parent: this,
          windowInstanceName: KendoWindowInstanceNames.DocumentPreview,
          title: selectedFileItemName,
          content: GkDocumentPreviewComponent,
          height: 600,
          width: 800,
          cssClass: 'gk-document-preview-window',
        });

        const documentPreviewComponent = windowRef.content
          .instance as GkDocumentPreviewComponent;
        const requestBody = {
          FileUuids: selectedFileUuids[0],
          KergId: payload.kergId,
          Podglad: true,
          // Origin: 'blob',
        };

        switch (fileExtension) {
          case FileExtension.Pdf: {
            if (payload.requestType === RequestType.Post) {
              this.downloadService
                .downloadBlob(payload.url, requestBody, payload.requestType)
                .subscribe((file) => {
                  documentPreviewComponent.pdfSrc = file.body;
                });
            } else {
              documentPreviewComponent.pdfSrc = encodeURIComponent(
                `${payload.url}?${new URLSearchParams(requestBody as never)}`,
              );
            }
            break;
          }
          case FileExtension.Jpg:
          case FileExtension.Jpeg:
          case FileExtension.Png:
          case FileExtension.Gif:
          case FileExtension.Bmp:
          case FileExtension.Tif:
          case FileExtension.Tiff: {
            this.downloadService
              .downloadBlob(payload.url, requestBody, payload.requestType)
              .subscribe((file) => {
                documentPreviewComponent.imgSrc = URL.createObjectURL(
                  file.body,
                );
              });
            break;
          }
          case FileExtension.Txt: {
            this.downloadService
              .downloadBlob(payload.url, requestBody, payload.requestType)
              .subscribe((file) => {
                const fileReader = new FileReader();
                fileReader.onload = (event): void => {
                  documentPreviewComponent.textSrc = event.target
                    .result as string;
                };
                fileReader.readAsText(file.body);
              });
            break;
          }
        }
      },
    });
  }

  public onShowGeometryToolbarButtonClick(
    payload: GenericGridToolbarItemShowGeometryPayload,
  ): void {
    if (!this.showMessageIfNoRowsSelected()) {
      return;
    }

    if (payload.gridGeometryFieldName) {
      const selectedItem =
        this.gridDataService.$selection.value.selectedItems[0];
      const wktGeom =
        selectedItem[
          payload.gridGeometryFieldName as keyof typeof selectedItem
        ];

      if (!wktGeom) {
        this.toastr.error(this.translateService.instant('GK.MAP.NO_GEOMETRY'));
        return;
      }

      this.showGeomOnMap(wktGeom as string);
    } else if (payload.url && !payload.ajaxPayload) {
      const url = this.matchUrlParamsWithGridSelectedItem(payload.url);

      this.getWktFromApi(url)
        .pipe(takeWhile(() => this.isAlive))
        .subscribe((geom) => {
          this.showGeomOnMap(geom);
        });
    } else if (payload.url && payload.ajaxPayload) {
      this.gridDataService
        .getPostRequest<Wkt[]>(payload.url, payload.ajaxPayload)
        .pipe(takeWhile(() => this.isAlive))
        .subscribe((response) => {
          if (response) {
            this.showGeomOnMap(response);
          } else {
            this.showNoGeometryToastr();
          }
        });
    }
  }

  public onDrawGeometryToolbarButtonClick(
    payload: GenericGridToolbarItemShowGeometryPayload,
  ): void {
    if (!this.showMessageIfNoRowsSelected()) {
      return;
    }
    if (payload.gridGeometryFieldName) {
      const selectedItem =
        this.gridDataService.$selection.value.selectedItems[0];
      const wktGeom =
        selectedItem[
          payload.gridGeometryFieldName as keyof typeof selectedItem
        ];

      if (!wktGeom) {
        this.toastr.error(this.translateService.instant('GK.MAP.NO_GEOMETRY'));
        return;
      }

      this.drawGeomOnMap(wktGeom as string);
    } else {
      const url = this.matchUrlParamsWithGridSelectedItem(payload.url);

      this.getWktFromApi(url)
        .pipe(takeWhile(() => this.isAlive))
        .subscribe((geom) => {
          this.drawGeomOnMap(geom);
        });
    }
  }

  public onGetPolygonGeometryFromMapToolbarButtonClick(
    payload: GridToolbarItemMapDrawingPayload,
  ): void {
    this.dispatchPreviewModeChange(true);
    this.dispatchToolAndSourceActivationChange(
      ToolType.AnyPolygon,
      SourceType.Polygon,
      true,
    );

    this.gridToolbarItemMapDrawingPayload = payload;
  }

  public onGetLandParcelFromMapToolbarButtonClick(
    payload: GridToolbarItemMapDrawingPayload,
    sourceType: SourceType,
  ): void {
    this.dispatchToolAndSourceActivationChange(
      ToolType.LandParcel,
      sourceType,
      true,
    );
    this.dispatchPreviewModeChange(true);

    this.gridToolbarItemMapDrawingPayload = payload;
  }

  public registerOnChange(onChange: () => void): void {
    this.onFormControlValueChange = onChange;
  }

  public registerOnTouched(onTouched: () => void): void {
    this.onFormControlValueTouched = onTouched;
  }

  public writeValue(value: GkKendoGridFormControlValue<T, U>): void {
    if (value?.data) {
      const shouldUnionResponse = this.handleUnionResponseWithGridData();
      this.handleGridFormControlValue(value);
      this.subscribeToGridDataService(shouldUnionResponse); //@todo  fix multiple subscriptions
    }
  }

  public ngAfterViewInit(): void {
    this.subscribeToMapActions(); //@todo fix multiple subscriptions in grid instances
    this.subscribeToGridDataBoundAndSelection();
  }

  protected abortRequest = (): void => {
    this.gridDataService.cancelRequest();
  };

  protected getColumnWidth(
    width: number | undefined,
    type: ColumnType,
  ): number {
    switch (type) {
      case ColumnType.Date:
        return !width || width < MIN_WIDTH_FOR_DATE_COLUMN
          ? MIN_WIDTH_FOR_DATE_COLUMN
          : width;
      default:
        return width || this.getDefaultMobileColumnWidth();
    }
  }

  protected isColumnHidden(columnField: string): Observable<boolean> {
    if (this.gridDataService) {
      return this.gridDataService?.$hiddenColumns.pipe(
        map((hiddenColumns) => hiddenColumns.indexOf(columnField) > -1),
      );
    } else {
      return of(false);
    }
  }

  protected showGeomOnMap(
    wktGeom: string | string[],
    doneCallback?: () => any,
  ): void {
    const mapObjectsArray = (Array.isArray(wktGeom) ? wktGeom : [wktGeom]).map(
      (wktGeomItem) => ({ geom: wktGeomItem }),
    );
    const geometryExtent =
      MapExtentUtils.getMapExtentFromMapObjects(mapObjectsArray);

    this.gkKendoGridMapService.$pendingMapActions.next([
      new MapAction(
        MapViewActionType.IsPreviewModeChange,
        new MapPreviewModeState(true, doneCallback),
      ),
      // new MapAction(
      //   MapObjectTableActionType.MapGeometryStyleConfigChange,
      //   new MapGeometryStyleConfig(
      //     new MapFeatureStyle(
      //       Color.Transparent,
      //       Color.GeometryPreview,
      //       4,
      //       [6, 6],
      //     ),
      //   ),
      // ),

      new MapAction(MapObjectTableActionType.Select, mapObjectsArray),
      new MapAction(MapViewActionType.ExtentToFitToChange, geometryExtent),
    ]);
  }

  protected drawGeomOnMap(wktGeom: string | string[]): void {
    const mapObjectsArray = (Array.isArray(wktGeom) ? wktGeom : [wktGeom]).map(
      (wktGeomItem) => ({ geom: wktGeomItem }),
    );
    const geometryExtent =
      MapExtentUtils.getMapExtentFromMapObjects(mapObjectsArray);

    this.gkKendoGridMapService.$pendingMapActions.next([
      new MapAction(
        MapViewActionType.IsPreviewModeChange,
        new MapPreviewModeState(true),
      ),
      // new MapAction(
      //   MapObjectTableActionType.MapGeometryStyleConfigChange,
      //   new MapGeometryStyleConfig(
      //     new MapFeatureStyle(Color.Transparent, Color.GeometryDraw, 2),
      //   ),
      // ),
      new MapAction(MapObjectTableActionType.Select, mapObjectsArray),
      new MapAction(MapViewActionType.ExtentToFitToChange, geometryExtent),
    ]);
  }

  protected markFormControlAsTouched(): void {
    if (!this.isFormControlTouched) {
      if (
        this.onFormControlValueTouched &&
        typeof this.onFormControlValueTouched === 'function'
      ) {
        this.onFormControlValueTouched();
      }
      this.isFormControlTouched = true;
    }
  }

  protected deactivateAllTools(): void {
    const toolTypes = this.getToolTypes(
      this.gkKendoGridMapService.$mapState.value.toolsState,
    );

    toolTypes.forEach((toolType) => {
      this.dispatchToolActivationChange(toolType, false);
      this.deactivateAllSources(toolType);
    });
  }

  protected handleGridToolbarPosition(): 'top' | 'bottom' | 'both' | undefined {
    if (
      this.topGridToolbarItems.length &&
      !this.bottomGridToolbarItems.length
    ) {
      return 'top';
    } else if (
      !this.topGridToolbarItems.length &&
      this.bottomGridToolbarItems.length
    ) {
      return 'bottom';
    } else if (
      this.topGridToolbarItems.length &&
      this.bottomGridToolbarItems.length
    ) {
      return 'both';
    } else {
      return undefined;
    }
  }

  protected checkIfMultipleSelectionMode(): boolean {
    if (this.selectable && typeof this.selectable !== 'boolean') {
      return this.selectable.mode === 'multiple';
    } else {
      return false;
    }
  }

  protected getRowHeight(): number | undefined {
    return this.scrollable === 'virtual' ? 20 : undefined;
  }

  protected rowCallback: RowClassFn = () => undefined;

  protected dispatchToolAndSourceActivationChange(
    toolType: ToolType,
    sourceType: SourceType,
    isActive: boolean,
  ): void {
    this.gkKendoGridMapService.$pendingMapActions.next([
      new MapAction(ToolActionType.IsActiveToolChange, {
        value: isActive,
        options: { toolType },
      }),
      new MapAction(SourceActionType.IsActiveSourceChange, {
        value: isActive,
        options: {
          toolType,
          sourceType,
        },
      }),
    ]);
  }

  protected dispatchPreviewModeChange(isActive: boolean): void {
    this.gkKendoGridMapService.$pendingMapActions.next([
      new MapAction(
        MapViewActionType.IsPreviewModeChange,
        new MapPreviewModeState(isActive),
      ),
    ]);
  }

  private getDefaultMobileColumnWidth(): number {
    if (this.deviceDetectorService.isMobile()) {
      return DEFAULT_MOBILE_COLUMN_WIDTH;
    }

    return undefined;
  }

  private isAllowedFileExtension(fileExtension: string): boolean {
    return allowedExtensionsToPreview.includes(fileExtension as FileExtension);
  }

  private showWarnUnsupportedExtensionToastr(): void {
    this.translateService
      .get('PREVIEW_DOCUMENT.INVALID_EXTENSION_INFO')
      .subscribe((text) => this.toastr.warning(text));
  }

  private getSelectedFileUuids(): string[] {
    return this.gridDataService.$selection.value.selectedItems.map(
      (selectedItem: { Uuid?: string }) => selectedItem.Uuid,
    );
  }

  private deactivateAllSources(toolType: ToolType): void {
    this.getSourceTypes(
      this.gkKendoGridMapService.$mapState.value?.toolsState[toolType],
    ).forEach((sourceType) =>
      this.dispatchSourceActivationChange(sourceType, false, toolType),
    );
  }

  private dispatchSourceActivationChange(
    sourceType: SourceType,
    isActive: boolean,
    toolType: ToolType,
  ): void {
    this.gkKendoGridMapService.$pendingMapActions.next([
      new MapAction(
        MapViewActionType.IsPreviewModeChange,
        new MapPreviewModeState(isActive),
      ),
      new MapAction(SourceActionType.IsActiveSourceChange, {
        value: isActive,
        options: { toolType, sourceType },
      }),
    ]);
  }

  private getSourceTypes(toolState: ToolState): SourceType[] {
    return _.intersection(
      Object.keys(toolState) as SourceType[],
      Object.keys(SourceType) as SourceType[],
    );
  }

  private matchUrlParamsWithGridSelectedItem(url: string): string | undefined {
    const idFieldPattern = /\{([^{}]+)\}/; // Regular expression to match and extract the value inside {}

    const selectedGridItemKey = url.match(idFieldPattern)[1];
    if (!this.checkIfKeyExistInSelectedGridItem(selectedGridItemKey)) {
      return undefined;
    } else {
      const selectedItem =
        this.gridDataService.$selection.value.selectedItems[0];
      return this.interpolateTemplateString(url, {
        [selectedGridItemKey]:
          selectedItem[selectedGridItemKey as keyof typeof selectedItem],
      });
    }
  }

  private interpolateTemplateString(template: string, args: any): string {
    return Object.entries(args).reduce(
      (result, [arg, val]) => result.replace(`{${arg}}`, `${val}`),
      template,
    );
  }

  private getWktFromApi(url: string): Observable<Wkt | Wkt[]> {
    return this.gridDataService
      .getGetRequest<BasicResponse<Wkt> | Wkt | Wkt[]>(url)
      .pipe(
        map((response) => {
          if (_.isArray(response)) {
            return response;
          } else if (_.isString(response)) {
            return response;
          } else {
            return response?.Result;
          }
        }),
        tap((wkt) => {
          if (!wkt) {
            this.showNoGeometryToastr();
          }
        }),
        filter(Boolean),
      );
  }

  private showNoGeometryToastr(): void {
    this.toastr.warning(
      this.translateService.instant('GK.MAP.NO_GEOM_EXTENT_INFO'),
    );
  }

  private onFormControlValueChange = (
    _data: GkKendoGridFormControlValue<T, U>,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  ): void => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onFormControlValueTouched = (): void => {};

  private handleGridFormControlValue(
    value: GkKendoGridFormControlValue<T, U>,
  ): void {
    const dataAmount = value.data.length;
    const gridData = { data: value.data, total: dataAmount };
    this.gridDataService.next(gridData);
    this.gridDataService.$count.next(dataAmount);
  }

  private showWarnPreviewAvailableForOnlyOneFile(): void {
    this.translateService
      .get('PREVIEW_DOCUMENT.PREVIEW_FOR_ONLY_ONE')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((text) => this.toastr.warning(text));
  }

  private dispatchToolActivationChange(
    toolType: ToolType,
    isActive: boolean,
  ): void {
    this.gkKendoGridMapService.$pendingMapActions.next([
      // new MapAction(MapViewActionType.IsPreviewModeChange, isActive),
      new MapAction(ToolActionType.IsActiveToolChange, {
        value: isActive,
        options: { toolType },
      }),
    ]);
  }

  private subscribeToGridDataBoundAndSelection(): void {
    combineLatest([
      this.gridDataService.$gridDataBound,
      this.gridDataService.$selection,
    ])
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(([gridItems, selection]) => {
        this.onFormControlValueChange({
          data: gridItems,
          selection: selection?.selectedItems,
        });
      });
  }

  private getFileExtensionFromFileName(fileName: string): string {
    const parts = fileName.split('.');
    return parts[parts.length - 1].toLowerCase();
  }

  private checkIfKeyExistInSelectedGridItem(
    selectedGridItemKey: string,
  ): boolean {
    if (
      !(
        selectedGridItemKey in
        (this.gridDataService.$selection.value.selectedItems[0] as object)
      )
    ) {
      console.error(
        `No ${selectedGridItemKey} key in `,
        this.gridDataService.$selection.value.selectedItems[0],
      );
      return false;
    } else {
      return true;
    }
  }

  private handleUnionResponseWithGridData(): boolean {
    const shouldUnionResponse =
      this.dataBindingDirective?.unionResponseWithGridData;
    if (shouldUnionResponse) {
      this.dataBindingDirective.unionResponseWithGridData = false;
    }

    return shouldUnionResponse;
  }

  private subscribeToGridDataService(shouldUnionResponse: boolean): void {
    this.gridDataService.pipe(takeWhile(() => this.isAlive)).subscribe(() => {
      if (shouldUnionResponse) {
        this.dataBindingDirective.unionResponseWithGridData =
          shouldUnionResponse;
      }
    });
  }

  private subscribeToMapActions(): void {
    this.gkKendoGridMapService.$pendingMapActions
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((mapActions) => {
        mapActions.forEach((mapAction) => {
          if (mapAction.type === ToolActionType.MapObjectsToolChange) {
            this.handleMapObjectsToolChange(mapAction);
          }
          if (mapAction.type === MapViewActionType.IsPreviewModeChange) {
            this.handlePreviewModeChange(mapAction);
          }
        });
      });
  }

  private handleMapObjectsToolChange(mapAction: MapAction): void {
    const toolType = mapAction.payload.options.toolType as ToolType;

    switch (toolType) {
      case ToolType.LandParcel:
        this.handleLandParcelToolChange(mapAction);
        break;
      case ToolType.AnyPolygon:
        this.handleAnyPolygonToolChange(mapAction);
        break;
    }

    if (toolType) {
      this.dispatchToolActivationChange(toolType, false);
      this.deactivateAllSources(toolType);
    }
    this.dispatchPreviewModeChange(false);
  }

  private getToolTypes(toolsState: ToolsState): ToolType[] {
    return Object.keys(toolsState) as ToolType[];
  }

  private handleLandParcelToolChange(mapAction: MapAction): void {
    const landParcels = mapAction.payload.value as EgibObject[];
    this.gridToolbarItemMapDrawingPayload?.doneCallback(landParcels);
  }

  private handleAnyPolygonToolChange(mapAction: MapAction): void {
    const polygon = mapAction.payload.value;
    this.gridToolbarItemMapDrawingPayload?.doneCallback(polygon);
  }

  private handlePreviewModeChange(mapAction: MapAction): void {
    if (!mapAction.payload) {
      this.gridToolbarItemMapDrawingPayload?.doneCallback(mapAction);
    }
  }

  onDetailsToolbarButtonClick(
    payload: GenericGridToolbarItemDetailsPayload,
  ): void {
    if (!this.showMessageIfNoRowsSelected()) {
      return;
    }

    const gkKendoGridItemDetailsWindowRef = this.gkKendoWindowService.open({
      parent: this,
      windowInstanceName: KendoWindowInstanceNames.GkKendoGridItemDetails,
      content: BaseDetailsComponent,
      width: 520,
    });

    const gkKendoGridItemDetailsWindowRefInstance =
      gkKendoGridItemDetailsWindowRef.content
        .instance as BaseDetailsComponent<any>;

    gkKendoGridItemDetailsWindowRefInstance.parseCallback =
      payload.parseCallback;

    this.gridDataService.$selection
      .pipe(takeUntil(gkKendoGridItemDetailsWindowRef.result))
      .subscribe((selection) => {
        if (selection?.selectedItems?.length) {
          gkKendoGridItemDetailsWindowRefInstance.loadDetailsFromAPI(
            this.matchUrlParamsWithGridSelectedItem(payload.url),
          );
        } else {
          gkKendoGridItemDetailsWindowRef.close();
        }
      });
  }
}
