import { Component, Input } from '@angular/core';
import {
  LinkNavItemConfig,
  NavbarAction,
  NavbarActionType,
} from '../../gk-dynamic-navbar.model';
import { NavBaseComponent } from '../nav-base/nav-base.component';

@Component({
  selector: 'gk-nav-item-link',
  templateUrl: './nav-item-link.component.html',
  styleUrls: ['./nav-item-link.component.scss'],
})
export class NavItemLinkComponent extends NavBaseComponent {
  @Input()
  linkNavItemConfig: LinkNavItemConfig;

  getIconClassSet(): string {
    return `fa fa-${this.linkNavItemConfig.faIconName} fa-lg`;
  }

  getCustomButtonClass(): string {
    return `${this.linkNavItemConfig.customBtnClass} ${this.getButtonClass()}`;
  }

  handleClick(): void {
    this.dispatch(
      new NavbarAction(NavbarActionType.Click, {
        navItemId: this.linkNavItemConfig.navItemId,
      })
    );
  }
}
