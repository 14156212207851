import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AddedDocToSignResponse,
  AddedDocToSignResponseFromApi,
  LawPersonType,
} from '@gk/gk-modules';
import * as _ from 'lodash';
import { Observable, map } from 'rxjs';
import { RangeType } from '../parcel/parcel.model';
import { Place } from '../place/place.model';
import {
  DictNullableIdDto,
  NewNetworkServiceRequestApiModel,
  NewNetworkServiceRequestModel,
  OrderRangeDto,
  PayerDetailsAddress,
  PayerDetailsAddressDto,
  PayerDetailsData,
  PayerDetailsDataDto,
  PayerDetailsDto,
  WmsMapCalculatePaymentRequestApiModel,
  WmsMapCalculatePaymentRequestModel,
} from './new-network-service-request.model';

@Injectable()
export class NewNetworkServiceRequestService {
  constructor(private httpClient: HttpClient) {}

  private getRangeForApi(
    formValue:
      | NewNetworkServiceRequestModel
      | WmsMapCalculatePaymentRequestModel
  ): OrderRangeDto {
    const districtIds = (formValue.districts || []).map(
      (district) => district.id
    );

    return new OrderRangeDto(
      formValue.rangeType === RangeType.Districts ? districtIds : null,
      formValue.rangeType === RangeType.Wkt ? formValue.wkt : null
    );
  }

  private getApiDictNullableIdDto = (
    obj: { id: number | string; name: string } | string | null
  ): DictNullableIdDto => ({
    Id: _.get<{ id: number | string; name: string } | string | null, string>(
      obj,
      'id',
      null
    ),
    Nazwa: _.get(obj, 'name', obj) as string,
  });

  private getApiPlace = (
    place: Place | string
  ): DictNullableIdDto & { IsLocal: boolean } => ({
    Id: _.get(place, 'id', null),
    Nazwa: _.get(place, 'name', place) as string,
    IsLocal: _.get(place, 'isLocal', false),
  });

  private convertPayerAddress = (
    address: PayerDetailsAddress
  ): PayerDetailsAddressDto => ({
    Kraj: _.get(address, 'country', null),
    Wojewodztwo: _.get(address, 'voivodeship', null),
    Powiat: _.get(address, 'county', null),
    Gmina: _.get(address, 'community', null),
    NumerBudynku: _.get(address, 'buildingNumber', null),
    NumerLokalu: _.get(address, 'localNumber', null),
    KodPocztowy: this.getApiDictNullableIdDto(address.postalCode),
    Ulica: this.getApiDictNullableIdDto(address.street),
    Miejscowosc: this.getApiPlace(address.place),
  });

  private convertPayerDetails = (
    payerDetails: PayerDetailsData
  ): PayerDetailsDataDto => ({
    Nazwa: _.get(payerDetails, 'companyName', null),
    Imie: _.get(payerDetails, 'firstName', null),
    Nazwisko: _.get(payerDetails, 'lastName', null),
    Telefon: _.get(payerDetails, 'phone', null),
    Email: _.get(payerDetails, 'email', null),
    Pesel: _.get(payerDetails, 'pesel', null),
    Regon: _.get(payerDetails, 'regon', null),
    Nip: _.get(payerDetails, 'nip', null),
    Skrot: _.get(payerDetails, 'companyShortcut', null),
    NrKonta: _.get(payerDetails, 'bankAccountNumber', null),
    RodzPetId: payerDetails.rodzPetId,
  });

  private getPayerDetailsForApi = (
    payerData: PayerDetailsData
  ): PayerDetailsDto => ({
    Dane: this.convertPayerDetails(payerData),
    Adres: this.convertPayerAddress(payerData.address),
    OsobaTyp: _.get(payerData, 'regon')
      ? LawPersonType.Legal
      : LawPersonType.Natural,
  });

  private newRequestFormModelToApiModel = (
    formValue: NewNetworkServiceRequestModel
  ): NewNetworkServiceRequestApiModel => ({
    CelId: formValue.purpose && formValue.purpose.id,
    KontaktOsoba: formValue.nameSurname,
    KontaktEmail: formValue.email,
    KontaktTelefon: formValue.phone,
    AdresyIp: formValue.ipAddresses,
    Pelnomocnictwo: undefined,
    PlatnikDane: formValue.payerDetails
      ? this.getPayerDetailsForApi(formValue.payerDetails)
      : null,
    CzasDostepu: formValue.accessTime,
    RodzajMapyId: formValue.mapTypeId,
    SkalaMapy: formValue.wmsMapScale,
    Zakres: this.getRangeForApi(formValue),
  });

  private wmsMapCalculatePaymentFormModelToApimodel = (
    formValue: WmsMapCalculatePaymentRequestModel
  ): WmsMapCalculatePaymentRequestApiModel => ({
    CelId: formValue.purpose && formValue.purpose.id,
    CzasDostepu: formValue.accessTime,
    RodzajMapyId: formValue.mapTypeId,
    SkalaMapy: formValue.wmsMapScale,
    Zakres: this.getRangeForApi(formValue),
  });

  validateDtoAndAddToSign(
    formValue: NewNetworkServiceRequestModel
  ): Observable<AddedDocToSignResponse> {
    return this.httpClient
      .post<AddedDocToSignResponseFromApi>(
        '/api/interesant/mapawms/wniosek/addToSign',
        this.newRequestFormModelToApiModel(formValue)
      )
      .pipe(
        map((addedDocToSignResponseFromApi) =>
          AddedDocToSignResponse.fromApiToApp(addedDocToSignResponseFromApi)
        )
      );
  }

  calculatePaymentForMap(
    formValue: WmsMapCalculatePaymentRequestModel
  ): Observable<number> {
    return this.httpClient.post<number>(
      '/api/interesant/mapawms/pozycja/calculate/oplata',
      this.wmsMapCalculatePaymentFormModelToApimodel(formValue)
    );
  }
}
