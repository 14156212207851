import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EcoNewsletterApi } from './eco-newsletter.model';

@Injectable()
export class EcoNewsletterService {
  constructor(private httpClient: HttpClient) {}

  subscribeToNewsletter(body: EcoNewsletterApi): Observable<any> {
    return this.httpClient.post('/api/srodowisko/mailing/newsletter/subscribe', body);
  }
}
