import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  ColumnHeader,
  DemonstrationOfApplicantRightService,
  DocSignService,
  FormAlertService,
  LawPersonSimpleFormComponent,
  MapId,
  MapObjectApiType,
  MapObjectTableState,
  MapPortalName,
  MapSettings,
  MapSettingsService,
  MapState,
  MapStateService,
  PortalId,
  PzService,
  StreetService,
  ToolType,
  markFormGroupsTouched,
} from '@gk/gk-modules';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, from, mergeMap, switchMap, takeWhile, tap } from 'rxjs';
import { AttachmentsComponent } from '../../../components/attachments/attachments.component';
import { MainRoutes } from '../../../guards/guard.models';
import { RequestInProgress } from '../../../guards/request-in-progress/request-in-progress.guard';
import { ApiNewDokumentPowiazanyDalDto } from '../../../services/designer-incoming-documents/designer-incoming-documents.model';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { PlaceService } from '../../../services/place/place.service';
import { BsMessageType } from '../../../services/request-workspace-state/request-workspace-state.model';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import { ShippingFormComponent } from '../../../shared/shipping-form/shipping-form.component';
import {
  ATIOEAIPNewRequestControlName,
  ATIOEAIPNewRequestFormGroup,
} from '../services/new-request-form/new-request-form.model';
import { ATIOEAIPNewRequestFormService } from '../services/new-request-form/new-request-form.service';
import { ATIOEAIPNewRequest } from '../services/new-request/new-request.model';

@Component({
  selector: 'app-atioeaip-new-request',
  templateUrl: './new-request.component.html',
  styleUrls: ['./new-request.component.scss'],
})
export class ATIOEAIPNewRequestComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy, RequestInProgress
{
  override controlName = ATIOEAIPNewRequestControlName;
  override formGroup: FormGroup<ATIOEAIPNewRequestFormGroup>;
  override portalId = PortalId.AccessToInformationOnEnvironmentAndItsProtection;
  mapObjectTableState = new MapObjectTableState(
    [new ColumnHeader('area', 'GK.MAP.AREA_IN_HA')],
    undefined,
    this.defaultMapGeometryStyles,
    true,
    true,
    true,
    '25',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    [MapObjectApiType.ExtentOrPolygon],
    true,
  );
  mapSettings: MapSettings;
  @ViewChild(LawPersonSimpleFormComponent)
  lawPersonSimpleFormComponent: LawPersonSimpleFormComponent;
  @ViewChild('canDeactivateModal') canDeactivateModal: NgbModalRef;
  @ViewChild(ShippingFormComponent)
  shippingFormComponent: ShippingFormComponent;
  @ViewChild(AttachmentsComponent) attachmentsComponent: AttachmentsComponent;
  successSigned = false;

  constructor(
    public override mapStateService: MapStateService,
    public override mapSettingsService: MapSettingsService,
    public override formAlertService: FormAlertService,
    public override placeService: PlaceService,
    public override translateService: TranslateService,
    public override docSignService: DocSignService,
    public override newRequestHelperService: NewRequestHelperService,
    private atioeaipNewRequestFormService: ATIOEAIPNewRequestFormService,
    public override streetService: StreetService,
    private toastr: ToastrService,
    public override router: Router,
    private modalService: NgbModal,
    public override pzService: PzService,
    public demonstrationOfApplicantRightService: DemonstrationOfApplicantRightService,
  ) {
    super(
      pzService,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService,
      formAlertService,
      placeService,
      streetService,
    );
  }

  override ngOnInit(): void {
    this.fetchWrongFileText();
    this.subscribeToDocSignTranslations();
    this.createForm();
    this.fetchMapSettings();
  }

  createForm(): void {
    this.formGroup = this.atioeaipNewRequestFormService.getFormGroup();
    this.subscribeToDecisionFormRadioFormControl();
  }

  fetchMapSettings(): void {
    this.mapSettingsService
      .getMapSettings(MapPortalName.Applicant)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((mapSettings) => {
        this.mapSettings = mapSettings;
        this.setMapState();
      });
  }

  override setMapState(): void {
    this.mapState = new MapState(
      MapId.AccessToInformationOnEnvironmentAndItsProtection,
      this.mapStateService.getViewState(
        MapId.AccessToInformationOnEnvironmentAndItsProtection,
        this.mapSettings,
      ),
      this.mapStateService.getToolbarState(
        [ToolType.AnyPolygon],
        this.mapSettings.papers,
        undefined,
        undefined,
        true,
      ),
      this.initialToolsState,
      this.mapStateService.getLayersState(
        MapId.AccessToInformationOnEnvironmentAndItsProtection,
        this.mapSettings,
        MapPortalName.Applicant,
      ),
      [this.mapObjectTableState],
    );
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.isRequestDirty() && !this.successSigned
      ? from(
          this.modalService.open(this.canDeactivateModal, {
            beforeDismiss: () => false,
          }).result,
        )
      : true;
  }

  isRequestDirty(): boolean {
    return (
      this.formGroup.dirty || this.attachmentsComponent.areChosenDocuments()
    );
  }

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    markFormGroupsTouched(this.getLawPersonFormGroup());
    if (!this.isRequestValid()) {
      return;
    }
    const apiFiles = await Promise.all(
      this.attachmentsComponent.getConvertedFiles(),
    ).catch(() => new Error());
    if (
      (Array.isArray(apiFiles) &&
        apiFiles.some((file) => file instanceof Error)) ||
      apiFiles instanceof Error
    ) {
      this.errorSubmitMessage = this.wrongFilesErrorText;
      this.toastr.error(this.wrongFilesErrorText);

      return;
    }

    this.sendRequest(apiFiles as ApiNewDokumentPowiazanyDalDto[]);
  }

  isRequestValid(): boolean {
    return (
      this.formGroup.valid && this.attachmentsComponent.areDocumentsValid()
    );
  }

  isLawPersonFormGroupValid(): boolean {
    return this.getLawPersonFormGroup().valid;
  }

  getLawPersonFormGroup(): UntypedFormGroup {
    return this.formGroup.get(this.controlName.LawPerson) as UntypedFormGroup;
  }

  sendRequest(apiDocuments: ApiNewDokumentPowiazanyDalDto[]): void {
    this.lawPersonSimpleFormComponent
      .askForPostOfficeWhenPostalCodeIsNotFromDictionary()
      .pipe(
        mergeMap(() =>
          this.shippingFormComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
        ),
        tap(() => {
          this.docSignPending = true;
          this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');
        }),
        switchMap(() =>
          this.docSignService.addToSign(
            ATIOEAIPNewRequest.fromAppToApi(
              this.getFormValue(),
              this.getIssueRanges(),
              apiDocuments,
            ),
            '/api/interesant/wniosek/informacjaStanSrodowiska/addToSign',
          ),
        ),
        takeWhile(() => this.isAlive),
      )
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleSendAndValidateSuccess(addedDocToSignResponse);
        },
        error: (error) => this.handleSendAndValidateFailure(error),
      });
  }

  getIssueRanges(): string[] {
    return (this.mapState.mapObjectTablesState[0].mapObjects || [])
      .filter(
        (mapObject) => mapObject.type === MapObjectApiType.ExtentOrPolygon,
      )
      .map((issueRange) => issueRange.geom);
  }

  getFormValue(): ATIOEAIPNewRequest {
    return this.formGroup.getRawValue();
  }

  override handleDocSignSuccess(): void {
    this.successSigned = true;
    this.docSignUrl = '';
    this.router.navigateByUrl(
      `/${MainRoutes.AccessToInformationOnEnvironmentAndItsProtection}/requests-list`,
    );
  }

  isDetailedScopeFormControlValid(): boolean {
    return this.getDetailedScopeFormControl().valid;
  }

  getDetailedScopeFormControl(): AbstractControl {
    return this.formGroup.get(this.controlName.DetailedScope);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
