import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormAlertService } from '../../../services/form-alert/form-alert.service';
import { NaturalPersonControlName } from '../../services/law-person-form/law-person-form.model';

@Component({
  selector: 'gk-natural-person-simple-form',
  templateUrl: './natural-person-simple-form.component.html',
})
export class NaturalPersonSimpleFormComponent {
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;
  naturalPersonControlName = NaturalPersonControlName;

  constructor(private formAlertService: FormAlertService) {}

  getFirstNameFormControl(): UntypedFormControl {
    return this.form.get(
      this.naturalPersonControlName.FirstName
    ) as UntypedFormControl;
  }

  getLastNameFormControl(): UntypedFormControl {
    return this.form.get(
      this.naturalPersonControlName.LastName
    ) as UntypedFormControl;
  }

  getPhoneNumberFormControl(): UntypedFormControl {
    return this.form.get(
      this.naturalPersonControlName.PhoneNumber
    ) as UntypedFormControl;
  }

  getEmailFormControl(): UntypedFormControl {
    return this.form.get(
      this.naturalPersonControlName.Email
    ) as UntypedFormControl;
  }

  shouldShowErrorAlert(
    formControl: UntypedFormControl,
    errorName = 'required'
  ): boolean {
    return this.formAlertService.shouldShowErrorAlert(
      formControl,
      errorName,
      this.submitted
    );
  }

  isRequired(formControl: UntypedFormControl): boolean {
    return this.formAlertService.isRequired(formControl);
  }
}
