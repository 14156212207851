import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PortalId } from '@gk/gk-modules';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { RequestFileFromApi } from '../request-file/request-file.model';
import { RequestFile } from './../request-file/request-file.model';

@Injectable()
export class RequestFileService {
  constructor(private httpClient: HttpClient) {}

  getRequestFilesForPortal(portalId: PortalId): Observable<RequestFile[]> {
    return this.httpClient
      .get<RequestFileFromApi[]>(`/api/system/web/portal/${portalId}/pliki`)
      .pipe(
        map((requestFiles) =>
          requestFiles.map((requestFile) =>
            RequestFile.fromApiToApp(requestFile)
          )
        ),
        shareReplay(1)
      );
  }

  downloadPattern(docId: string): Observable<HttpResponse<Blob>> {
    const body = { Id: docId };
    return this.httpClient.post('/api/system/web/plik/download', body, {
      responseType: 'blob',
      observe: 'response',
    });
  }
}
