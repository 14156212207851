<div
  class="dynamic-field form-checkbox"
  [formGroup]="group"
  [hidden]="!config.visible"
>
  <label>
    <input
      type="checkbox"
      [formControlName]="config.name"
      [value]="config.value"
      [readonly]="config.readonly"
      [attr.disabled]="config.disabled || config.readonly"
    />
    <span class="ms-3">{{ config.label }}</span>
  </label>
</div>
<gk-form-control-validation
  [formGroup]="group"
  [fieldConfig]="config"
  [submitted]="submitted"
></gk-form-control-validation>
