import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { GkComponentsModule, GkMapModule } from '@gk/gk-modules';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { createTranslateLoader } from '../address-number-request-portal/address-number-request-portal.module';
import { TSBPNewRequestComponent } from './new-request/new-request.component';
import { TSBPNewRequestFormService } from './services/new-request-form/new-request-form.service';
import { TemporaryStructureBuildingPermitRoutingModule } from './temporary-structure-building-permit-routing.module';

@NgModule({
  declarations: [TSBPNewRequestComponent],
  imports: [
    SharedModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    GkMapModule,
    GkComponentsModule,
    TemporaryStructureBuildingPermitRoutingModule,
  ],
  providers: [TSBPNewRequestFormService],
})
export class TemporaryStructureBuildingPermitModule {}
