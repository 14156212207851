import {
  EgibObject,
  LawPerson,
  LawPersonType,
  NewDesignerRequestDto,
  NewLegalPersonRequestDto,
  NewNaturalPersonRequestDto,
} from '@gk/gk-modules';
import {
  Shipping,
  ShippingDto,
} from '../../shared/shipping-form/shipping-form.model';
import { ApiNewDokumentPowiazanyDalDto } from '../designer-incoming-documents/designer-incoming-documents.model';
import { DecisionFormType } from '../excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { ZamowienieDzialkaDto } from '../excluding-land-from-agricultural-production/agricultural-land-use/agricultural-land-use.model';
import { Place } from '../place/place.model';
import { RoadExitRequestType } from '../road-exit/road-exit.model';

export interface BuildRoadExitPermissionDto {
  Wnioskodawca:
    | NewNaturalPersonRequestDto
    | NewLegalPersonRequestDto
    | NewDesignerRequestDto;
  DrogaNumer: string | number;
  Dzialki: ZamowienieDzialkaDto[];
  MiejscId: string | number;
  PrzedmiotWnioskuZjazd: RoadExitRequestType;
  CelWykorzystaniaNieruchomosci: string;
  CzySposobWykorzystaniaNieruchomosciUlegnieZmianie: boolean;
  ZmianaSposobuWykorzystaniaNieruchomosci: string;
  Zalaczniki: ApiNewDokumentPowiazanyDalDto[];
  Geom: string[];
  FormaDecyzji: number;
  Odbior: ShippingDto;
}

export interface PurposeOfPropertyUseChanged {
  willUseOfPropertyChange: boolean;
  changedPurposeOfPropertyUse: string;
}

export class BuildRoadExitPermission {
  constructor(
    public lawPerson: LawPerson,
    public requestType: RoadExitRequestType,
    public roadNumber: string,
    public roadExitLocationPlace: Place,
    public purposeOfPropertyUse: string,
    public purposeOfPropertyUseChanged: PurposeOfPropertyUseChanged,
    public decisionForm: DecisionFormType,
    public shippingForm: Shipping
  ) {}

  static fromAppToApi(
    data: BuildRoadExitPermission,
    parcels: EgibObject[],
    issueRanges: string[],
    files: ApiNewDokumentPowiazanyDalDto[]
  ): BuildRoadExitPermissionDto {
    return {
      Wnioskodawca:
        data.lawPerson.type === LawPersonType.Natural
          ? NewNaturalPersonRequestDto.fromAppToApi(data.lawPerson)
          : NewLegalPersonRequestDto.fromAppToApi(data.lawPerson),
      DrogaNumer: data.roadNumber,
      Dzialki: parcels.map((parcel) =>
        ZamowienieDzialkaDto.fromAppToApi(parcel)
      ),
      MiejscId: data.roadExitLocationPlace.id,
      PrzedmiotWnioskuZjazd: data.requestType,
      CelWykorzystaniaNieruchomosci: data.purposeOfPropertyUse,
      CzySposobWykorzystaniaNieruchomosciUlegnieZmianie:
        data.purposeOfPropertyUseChanged.willUseOfPropertyChange,
      ZmianaSposobuWykorzystaniaNieruchomosci:
        data.purposeOfPropertyUseChanged.changedPurposeOfPropertyUse,
      Zalaczniki: files,
      Geom: issueRanges,
      FormaDecyzji: data.decisionForm,
      Odbior: ShippingDto.fromAppToApi(data.shippingForm),
    };
  }
}
