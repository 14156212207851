import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';
import { Paper, PaperOptionProperty } from '../../../../models';
import * as _ from 'lodash';

@Component({
  selector: 'gk-paper-dropdown',
  templateUrl: './paper-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaperDropdownComponent {
  @Input()
  papers: Paper[];
  @Input()
  allowedPaper: Paper;
  @Input()
  restriction: boolean;
  @Input()
  optionProperty: PaperOptionProperty;
  @Output()
  orientationChange = new EventEmitter<string>();
  @Output()
  nameChange = new EventEmitter<string>();
  paperOptionPropertyEnum = PaperOptionProperty;

  getUniqueOrientations(): string[] {
    return _.uniq(_.map(this.papers, 'orientation'));
  }

  getNamesByOrientation(): string[] {
    return _.map(
      _.filter(this.papers, { orientation: this.allowedPaper.orientation }),
      'name'
    );
  }

  emitOrientationChange(orientation: string): void {
    this.orientationChange.emit(orientation);
  }

  emitNameChange(name: string): void {
    this.nameChange.emit(name);
  }
}
