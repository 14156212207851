import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PortalAccessGuard } from '../../guards/portal-access/portal-access.guard';
import { SessionGuard } from '../../guards/session/session.guard';
import { FreeServicesMapComponent } from './free-services-map/free-services-map.component';
import { FreeServicesPortalComponent } from './free-services-portal.component';
import { NewRequestSummaryComponent } from './new-request-summary/new-request-summary.component';
import { NewRequestComponent } from './new-request/new-request.component';
import { UserRequestsDynamicComponent } from './user-requests-dynamic/user-requests-dynamic.component';

const freeServicesPortalRoutes: Routes = [
  {
    path: 'free-services-portal',
    component: FreeServicesPortalComponent,
    canActivate: [SessionGuard, PortalAccessGuard],
    children: [
      {
        path: '',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
      {
        path: 'new-request',
        component: NewRequestComponent,
      },
      {
        path: 'new-request-summary',
        component: NewRequestSummaryComponent,
      },
      {
        path: 'user-requests-dynamic',
        component: UserRequestsDynamicComponent,
      },
      {
        path: 'map',
        component: FreeServicesMapComponent,
      },
      {
        path: '**',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(freeServicesPortalRoutes)],
  providers: [SessionGuard, PortalAccessGuard],
  exports: [RouterModule],
})
export class FreeServicesPortalRoutingModule {}
