<div
  class="position-relative reset-password-form d-flex flex-column justify-content-center overflow-visible"
  >
  <div class="static-height-container">
    <div class="row main-content bg-success text-center">
      <div class="col action-window">
        <div class="position-relative">
          <div class="row mt-4">
            <div class="col-12">
              <h1>{{ 'SESSION_TOOLS.RESET_PASSWORD.HEADER' | translate }}</h1>
            </div>
          </div>
          <div class="row">
            <form
              id="password-reset-form"
              class="form-group col-12"
              [formGroup]="formGroup"
              (ngSubmit)="resetPasswordSubmitHandle()"
              >
              <div class="row">
                <label [attr.for]="controlNameEnum.Login" class="sr-only">{{
                  'SESSION_TOOLS.USER_NAME' | translate
                }}</label>
                <input
                  [formControlName]="controlNameEnum.Login"
                  [attr.name]="controlNameEnum.Login"
                  [attr.id]="controlNameEnum.Login"
                  type="text"
                  class="form__input"
                  [attr.placeholder]="
                    'SESSION_TOOLS.ENTER_USER_NAME' | translate
                  "
                  />
              </div>
              <div class="row">
                <div class="col-sm-6 col-12 order-1 order-sm-0">
                  <button
                    (click)="switchToLoginMode()"
                    id="cancel-password-reset"
                    class="btn btn-danger"
                    type="button"
                    >
                    {{ 'SESSION_TOOLS.RESET_PASSWORD.CANCEL' | translate }}
                  </button>
                </div>
                <div class="col-sm-6 col-12">
                  @if (!actionRequestInProgress) {
                    <input
                      type="submit"
                      class="btn btn-normal password-reset-ready-info rememberPass"
                    [attr.value]="
                      'SESSION_TOOLS.RESET_PASSWORD.RESET_PASSWORD' | translate
                    "
                      />
                  } @else {
                    <button
                      type="button"
                      class="btn btn-normal password-reset-pending-info"
                      [attr.title]="
                        'SESSION_TOOLS.RESET_PASSWORD.PENDING_ACTION'
                          | translate
                      "
                      >
                      <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                    </button>
                  }
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      @if (!messageText && !shouldShowLoginControlRequiredMessage()) {
        <div
          class="col-12 text-center d-flex justify-content-center py-3 bg-normal"
          >
          <p class="mb-0 fw-bold text-white">
            {{
            'SESSION_TOOLS.RESET_PASSWORD.WE_WILL_SEND_YOU_EMAIL_WITH_PASSWORD_RESET_LINK'
            | translate
            }}
          </p>
        </div>
      }
      @if (messageText) {
        <div
          class="col-12 text-center d-flex justify-content-center py-3 bg-normal text-white"
          >
          <i class="fa fa-check text-white"></i>
          <p class="mb-0">{{ messageText }}</p>
        </div>
      }

      @if (shouldShowLoginControlRequiredMessage()) {
        <div
          class="col-12 text-center d-flex justify-content-center py-3 bg-warning text-dark"
          >
          <p class="mb-0 fw-bold">
            <i class="fa fa-exclamation-triangle"></i>
            {{ 'SESSION_TOOLS.LOGIN_REQUIRED' | translate }}
          </p>
        </div>
      }
    </div>
  </div>
</div>
