import { LawPersonType } from '@gk/gk-modules';

export enum AuthorizedPersonControlName {
  AuthorizedDetailsForm = 'authorizedDetailsForm',
  AuthorizedDetailsCheckbox = 'authorizedDetailsCheckbox',
  Name = 'name',
  Surname = 'surname',
  IdNumber = 'idNumber',
}

export interface AuthorizedPersonData {
  authorizedDetailsCheckbox: boolean;
  name: string;
  surname: string;
  idNumber: string;
}

export class AuthorizedPersonDataRequestDto {
  constructor(
    public Imie: string,
    public Nazwisko: string,
    public NrDowodu: string,
    public Typ = LawPersonType.Natural
  ) {}
  static fromAppToApi(
    data: AuthorizedPersonData
  ): AuthorizedPersonDataRequestDto {
    return data && data.authorizedDetailsCheckbox
      ? new this(data.name, data.surname, data.idNumber)
      : null;
  }
}
