import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import {
  BehaviorSubject,
  combineLatest,
  combineLatestWith,
  delay,
  filter,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { TfaConfirmationService } from '../../../gk-components/session-tools/services/tfa-confirmation/tfa-confirmation.service';
import { TfaResetCodeComponent } from '../../../gk-components/session-tools/tfa-reset-code/tfa-reset-code.component';
import { DefaultConfigGuestService } from '../../../services';
import { BasicResponse } from '../../../utils/basic-response/basic-response.model';
import { GkUserSettingsComponent } from '../../gk-user-settings.component';
import { TfaType } from './tfa.model';

@Injectable({
  providedIn: 'root',
})
export class TfaService {
  tfaTypeInOperation = new BehaviorSubject<TfaType>(undefined);
  private currentTfaType = new BehaviorSubject(undefined);
  forcedTfaActivationInProgress = new BehaviorSubject<boolean>(false);
  tfaResetCodeModalIsAlive = new BehaviorSubject<boolean>(false);
  private isTfaRequiredObservable: Observable<boolean>;

  constructor(
    private httpClient: HttpClient,
    private ngbModal: NgbModal,
    private defaultConfigGuestService: DefaultConfigGuestService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private tfaConfirmationService: TfaConfirmationService
  ) {}

  fetchCurrentTfaType(): Observable<TfaType> {
    return this.httpClient
      .get<BasicResponse<TfaType>>('/api/system/login/2fa/required')
      .pipe(
        map((reponse) => reponse.Result),
        shareReplay(1),
        tap((tfaType) => this.currentTfaType.next(tfaType))
      );
  }

  executeEmailOrTotpTfa(tfaType: TfaType): Observable<TfaType> {
    switch (tfaType) {
      case TfaType.Email:
      case TfaType.Totp: {
        return this.executeTfa(tfaType);
      }
      case TfaType.WebAuthn:
      default:
        return of(tfaType);
    }
  }

  openTfaInitializationWindowIfRequired(): Observable<void> {
    return this.isTfaRequired().pipe(
      switchMap((isTfaRequired) => {
        return isTfaRequired
          ? this.openForcedTfaInitializationWindow()
          : of(undefined);
      })
    );
  }

  isTfaRequired(): Observable<boolean> {
    if (!this.isTfaRequiredObservable) {
      this.isTfaRequiredObservable = this.defaultConfigGuestService
        .getConfig()
        .pipe(
          map((config) => config.isTfaRequired),
          shareReplay(1)
        );
    }
    return this.isTfaRequiredObservable;
  }

  openForcedTfaInitializationWindow(): Observable<void> {
    this.forcedTfaActivationInProgress.next(true);
    this.ngbModal.open(GkUserSettingsComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });

    return combineLatest([
      this.tfaTypeInOperation,
      this.tfaResetCodeModalIsAlive,
      this.tfaConfirmationService.verifiedConfirmation,
    ]).pipe(
      filter(
        ([tfaTypeInOperation, tfaResetCodeModalIsAlive]) =>
          tfaTypeInOperation === undefined && !tfaResetCodeModalIsAlive
      ),
      map(() => undefined),
      tap(() => {
        this.showReloadAfterWhileToastr();
      }),
      delay(5000)
    );
  }

  showReloadAfterWhileToastr(): void {
    this.translateService
      .get(
        'SESSION_TOOLS.TFA_BASE_CONFIRMATION.RELOAD_TO_LOGIN_PAGE_AFTER_INITIALISATION'
      )
      .subscribe((text) => this.toastr.success(text));
  }

  private executeTfa(tfaType: TfaType): Observable<TfaType> {
    return this.httpClient
      .post('/api/system/login/2fa/execute', undefined)
      .pipe(map(() => tfaType));
  }

  initializeTfa(): Observable<string> {
    return this.tfaTypeInOperation.pipe(
      switchMap((tfaType) =>
        this.httpClient.post(
          '/api/system/login/2fa/initialize',
          {
            SecondFaType: tfaType,
          },
          { responseType: 'text' }
        )
      )
    );
  }

  disableTfa(): Observable<string> {
    return this.httpClient.post('/api/system/login/2fa/turnoff', undefined, {
      responseType: 'text',
    });
  }

  isEmailEnabled(): Observable<boolean> {
    return this.currentTfaType.pipe(
      map((tfaType) => tfaType === TfaType.Email)
    );
  }

  isTotpEnabled(): Observable<boolean> {
    return this.currentTfaType.pipe(map((tfaType) => tfaType === TfaType.Totp));
  }

  isWebAuthnEnabled(): Observable<boolean> {
    return this.currentTfaType.pipe(
      map((tfaType) => tfaType === TfaType.WebAuthn)
    );
  }

  currentTfaTypeNotFetchedYet(): Observable<boolean> {
    return this.currentTfaType
      .asObservable()
      .pipe(map((data) => data === undefined));
  }

  isTfaEnabled(): Observable<boolean> {
    return this.currentTfaType.pipe(
      filter((data) => data !== undefined),
      map(Boolean)
    );
  }

  isTfaTotpInOperation(): Observable<boolean> {
    return this.tfaTypeInOperation.pipe(
      map((tfaType) => tfaType === TfaType.Totp)
    );
  }

  isTfaWebAuthnInOperation(): Observable<boolean> {
    return this.tfaTypeInOperation.pipe(
      map((tfaType) => tfaType === TfaType.WebAuthn)
    );
  }

  showTfaResetCodeModalIfNecessary(): Observable<boolean> {
    return this.isTfaTotpInOperation().pipe(
      combineLatestWith(this.isTfaWebAuthnInOperation()),
      map(
        ([isTfaTotpInOperation, isTfaWebAuthnInOperation]) =>
          isTfaTotpInOperation || isTfaWebAuthnInOperation
      ),
      take(1),
      tap((shouldShowResetCodeModal) => {
        if (shouldShowResetCodeModal) {
          this.tfaResetCodeModalIsAlive.next(true);
          this.ngbModal
            .open(TfaResetCodeComponent, {
              backdrop: 'static',
              keyboard: false,
            })
            .result.then(() => {
              this.tfaResetCodeModalIsAlive.next(false);
            });
        }
      })
    );
  }
}
