import { ApplicantType } from '@gk/gk-modules';

export interface HubSettingsFromApi {
  ZapisBezposredniOsobEnabled: boolean;
  PlanowanieAutoDoo: boolean;
  PrzpWyborWnioskodawcyZRegonTryb: ApplicantType;
}

export class HubSettings {
  constructor(
    public addingPersonsDirectlyToDb: boolean,
    public isPlanningDepartmentPriceShown: boolean,
    public przpApplicantType: ApplicantType
  ) {}

  static fromApiToApp(hubSettingsFromApi: HubSettingsFromApi): HubSettings {
    return new this(
      hubSettingsFromApi.ZapisBezposredniOsobEnabled,
      hubSettingsFromApi.PlanowanieAutoDoo,
      hubSettingsFromApi.PrzpWyborWnioskodawcyZRegonTryb
    );
  }
}
