import { EgibObject, LawPersonAddress, MapObject } from '@gk/gk-modules';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {
  InvestorDetails,
  InvestorDetailsRequestDto,
} from '../../../shared/investor-details/investor-details.model';
import {
  Shipping,
  ShippingDto,
} from '../../../shared/shipping-form/shipping-form.model';
import { DateHelperUtil } from '../../../utils/date-helper/date-helper.util';
import { ApiNewDokumentPowiazanyDalDto } from '../../designer-incoming-documents/designer-incoming-documents.model';
import { DecisionFormType } from '../agricultural-land-use-form/agricultural-land-use-form.model';

export interface ExcludingLandFromAgriculturalProductionNonAgriculturalLandUse {
  investorDetails: InvestorDetails;
  proxyDetailsCheckbox: boolean;
  proxyDetails: InvestorDetails;
  idNumber: number;
  phoneNumber: number;
  email: string;
  address: LawPersonAddress;
  dateOff: NgbDateStruct;
  decisionForm: DecisionFormType;
  shippingForm: Shipping;
  landParcels: ZamowienieDzialkaDto[];
  attachments: ApiNewDokumentPowiazanyDalDto;
}

export class ZamowienieDzialkaDto {
  constructor(
    public Uuid: string,
    public DzialkaId: number,
    public Numer: string,
    public Gmina: string,
    public Obreb: string,
    public Arkusz: string
  ) {}
  static fromAppToApi(data: EgibObject): ZamowienieDzialkaDto {
    return new this(
      data.uuid,
      data.parcelId,
      data.mapObjectNumber,
      data.cadastralUnit,
      data.districtName,
      data.mapSheet
    );
  }
}

export class ZamowienieGeomDto {
  constructor(public Geom: string) {}
  static fromAppToApi(data: MapObject): ZamowienieGeomDto {
    return new this(data.geom);
  }
}

export class WniosekWylFaktyczneGrZProdRolnejRequestDto {
  constructor(
    public DaneInwestora: InvestorDetailsRequestDto,
    public DanePelnomocnika: InvestorDetailsRequestDto,
    public DataWylaczenia: string,
    public FormaDecyzji: number,
    public Odbior: ShippingDto,
    public Dzialki: ZamowienieDzialkaDto[],
    public Zalaczniki: ApiNewDokumentPowiazanyDalDto[]
  ) {}
  static fromAppToApi(
    data: ExcludingLandFromAgriculturalProductionNonAgriculturalLandUse,
    attachments: ApiNewDokumentPowiazanyDalDto[]
  ): WniosekWylFaktyczneGrZProdRolnejRequestDto {
    return new this(
      InvestorDetailsRequestDto.fromAppToApi(data.investorDetails),
      data.proxyDetailsCheckbox
        ? InvestorDetailsRequestDto.fromAppToApi(data.proxyDetails)
        : null,
      DateHelperUtil.ngbDateToApiDateTime(data.dateOff),
      data.decisionForm,
      ShippingDto.fromAppToApi(data.shippingForm),
      data.landParcels,
      attachments
    );
  }
}
