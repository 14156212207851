<form [formGroup]="form" novalidate class="row">
  <div class="form-group col-12 mb-0">
    <div class="row">
      <div class="col-sm-6 col-xs-12">
        <label
          [attr.for]="
            createIdWithCompName(
              authorizedPersonToRegistryViewControlName.NameSurname
            )
          "
          >{{ 'NEW_REQUEST.NAME_SURNAME' | translate }}:</label
          >
          <input
            type="text"
            class="form-control form-control-sm"
            [ngClass]="{ required: shouldBeMarkedAsRequired }"
            aria-required="true"
          [attr.id]="
            createIdWithCompName(
              authorizedPersonToRegistryViewControlName.NameSurname
            )
          "
          [formControlName]="
            authorizedPersonToRegistryViewControlName.NameSurname
          "
            />
          @if (shouldShowErrorAlert(getNameSurnameFormControl(), 'required')) {
            <div
              class="alert alert-danger"
              >
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          }
        </div>
        <div class="col-sm-6 col-xs-12">
          <label
          [attr.for]="
            createIdWithCompName(
              authorizedPersonToRegistryViewControlName.Pesel
            )
          "
            >{{ 'NEW_REQUEST.PESEL' | translate }}:</label
            >
            <input
              type="tel"
              class="form-control form-control-sm"
              [ngClass]="{ required: shouldBeMarkedAsRequired }"
              aria-required="true"
          [attr.id]="
            createIdWithCompName(
              authorizedPersonToRegistryViewControlName.Pesel
            )
          "
              [formControlName]="authorizedPersonToRegistryViewControlName.Pesel"
              />
            @if (shouldShowErrorAlert(getPeselFormControl(), 'required')) {
              <div
                class="alert alert-danger"
                >
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
            @if (shouldShowErrorAlert(getPeselFormControl(), 'pesel')) {
              <div
                class="alert alert-danger"
                >
                {{ 'NATURAL_PERSON_FORM.PESEL_ERROR' | translate }}
              </div>
            }
          </div>
        </div>
        <div class="d-flex justify-content-end mt-3">
          <button
            type="button"
            class="btn btn-color-from-db btn-sm"
            (click)="addContactPersonToList()"
            >
            {{ 'NEW_REQUEST.ADD' | translate }}
          </button>
        </div>
      </div>
    </form>
