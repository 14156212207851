import { Component, EventEmitter, Output } from '@angular/core';
import { LandParcelSearchFormService } from './land-parcel-search-form.service';
import { FormGroup } from '@angular/forms';
import {
  getLandParcelSearchFormModel,
  landParcelSearchFormActionButtonsConfig,
  LandParcelSearchFormModel,
} from './land-parcel-search-form.model';
import { GkWindowRef } from '../../../index';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'gk-land-parcel-search-form',
  templateUrl: './land-parcel-search-form.component.html',
})
export class LandParcelSearchFormComponent {
  @Output() submitForm = new EventEmitter<LandParcelSearchFormModel>();
  isLoading: BehaviorSubject<boolean>;
  windowRef: GkWindowRef;
  form = new FormGroup({});
  formlyFormConfig = this.landParcelSearchFormService.getFormConfig();
  formModel = getLandParcelSearchFormModel();
  landParcelSearchFormActionButtonsConfig =
    landParcelSearchFormActionButtonsConfig;
  constructor(
    private landParcelSearchFormService: LandParcelSearchFormService,
  ) {}

  clearForm(): void {
    this.form.reset();
  }

  onFormSubmit(formModel: LandParcelSearchFormModel): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.windowRef.close();
      this.submitForm.emit(formModel);
    }
  }
  onCancel(): void {
    this.windowRef.close();
  }
}
