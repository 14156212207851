import { Coordinate } from 'ol/coordinate';
import { Geometry } from 'ol/geom';
import { SourceType } from './map-state.model';

export class MapMeasurementStaticOverlay {
  constructor(
    public sketch: Geometry,
    public sourceType: SourceType,
    public movingPointerCoordinate: Coordinate
  ) {}
}
