export interface DesignerSettingsFromApi {
  ZakresWymagany: boolean;
  DodaniePetentaEnabled: boolean;
  PodpisPzRequired: boolean;
}

export class DesignerSettings {
  constructor(
    public rangeRequired: boolean,
    public canAddNewPersonEvenIfDefinedInDb: boolean,
    public pzSignRequired: boolean
  ) {}

  static fromApiToApp(
    designerSettingsFromApi: DesignerSettingsFromApi
  ): DesignerSettings {
    return new this(
      designerSettingsFromApi.ZakresWymagany,
      designerSettingsFromApi.DodaniePetentaEnabled,
      designerSettingsFromApi.PodpisPzRequired
    );
  }
}
