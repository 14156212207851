export enum WaterEquipmentDocTypeId {
  Other = 8,
  StampDuty = 10040,
  RequestStampDuty = 10040,
  PowerOfAttorney = 10035
}

export enum WaterEquipmentDocTypeName {
  ProxyStampDuty = 'proxy stamp duty',
  RequestStampDuty = 'request stamp duty'
}

export enum WaterEquipmentDocTypeLabelKey {
  Other = 'ENVIRONMENTAL_PROTECTION_PORTAL.WATER_EQUIPMENT.DOC_TYPE_DESCRIPTION.OTHER',
  StampDuty = 'ENVIRONMENTAL_PROTECTION_PORTAL.WATER_EQUIPMENT.DOC_TYPE_DESCRIPTION.PROXY_STAMP_DUTY',
  RequestStampDuty = 'ENVIRONMENTAL_PROTECTION_PORTAL.WATER_EQUIPMENT.DOC_TYPE_DESCRIPTION.REQUEST_STAMP_DUTY',
  PowerOfAttorney = 'ENVIRONMENTAL_PROTECTION_PORTAL.WATER_EQUIPMENT.DOC_TYPE_DESCRIPTION.POWER_OF_ATTORNEY'
}

export function getWaterEquipmentTypeLabelKeyById(id: number): string {
  switch (id) {
    case WaterEquipmentDocTypeId.Other: {
      return WaterEquipmentDocTypeLabelKey.Other;
    }
    case WaterEquipmentDocTypeId.RequestStampDuty: {
      return WaterEquipmentDocTypeLabelKey.RequestStampDuty;
    }
    case WaterEquipmentDocTypeId.PowerOfAttorney: {
      return WaterEquipmentDocTypeLabelKey.PowerOfAttorney;
    }
    default: {
      return '';
    }
  }
}
