export enum ConstructionNotificationDocTypeId {
  Other = 8,
  StampDuty = 10040,
  PowerOfAttorney = 10035,
  LegalTitleToRealEstate = 10039,
  ConstructionWorksDescription = 10051,
  LandParcelDevelopmentProject = 10047,
  Testimonial = 113
}

export enum ConstructionNotificationDocTypeLabelKey {
  Other = 'CONSTRUCTION_PORTAL.CONSTRUCTION_NOTIFICATION.DOC_TYPE_DESCRIPTION.OTHER',
  StampDuty = 'CONSTRUCTION_PORTAL.CONSTRUCTION_NOTIFICATION.DOC_TYPE_DESCRIPTION.STAMP_DUTY',
  PowerOfAttorney = 'CONSTRUCTION_PORTAL.CONSTRUCTION_NOTIFICATION.DOC_TYPE_DESCRIPTION.POWER_OF_ATTORNEY',
  LegalTitleToRealEstate = 'CONSTRUCTION_PORTAL.CONSTRUCTION_NOTIFICATION.DOC_TYPE_DESCRIPTION.LEGAL_TITLE_TO_REAL_ESTATE',
  ConstructionWorksDescription = 'CONSTRUCTION_PORTAL.CONSTRUCTION_NOTIFICATION.DOC_TYPE_DESCRIPTION.CONSTRUCTION_WORKS_DESCRIPTION',
  LandParcelDevelopmentProject = 'CONSTRUCTION_PORTAL.CONSTRUCTION_NOTIFICATION.DOC_TYPE_DESCRIPTION.LAND_PARCEL_DEVELOPMENT_PROJECT',
  Testimonial = 'CONSTRUCTION_PORTAL.CONSTRUCTION_NOTIFICATION.DOC_TYPE_DESCRIPTION.TESTIMONIAL'
}

export function getConstructionNotificationDocTypeLabelKeyById(id: number): string {
  switch (id) {
    case ConstructionNotificationDocTypeId.Other: {
      return ConstructionNotificationDocTypeLabelKey.Other;
    }
    case ConstructionNotificationDocTypeId.StampDuty: {
      return ConstructionNotificationDocTypeLabelKey.StampDuty;
    }
    case ConstructionNotificationDocTypeId.PowerOfAttorney: {
      return ConstructionNotificationDocTypeLabelKey.PowerOfAttorney;
    }
    case ConstructionNotificationDocTypeId.LegalTitleToRealEstate: {
      return ConstructionNotificationDocTypeLabelKey.LegalTitleToRealEstate;
    }
    case ConstructionNotificationDocTypeId.ConstructionWorksDescription: {
      return ConstructionNotificationDocTypeLabelKey.ConstructionWorksDescription;
    }
    case ConstructionNotificationDocTypeId.LandParcelDevelopmentProject: {
      return ConstructionNotificationDocTypeLabelKey.LandParcelDevelopmentProject;
    }
    case ConstructionNotificationDocTypeId.Testimonial: {
      return ConstructionNotificationDocTypeLabelKey.Testimonial;
    }
    default: {
      return '';
    }
  }
}
