import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RequestConfig, RequestType } from '../../gk-dynamic-list.model';
import { handleApiError } from '../services.utils';

@Injectable()
export class DataService {
  constructor(private httpClient: HttpClient) {}

  private buildParams(requestConfig: RequestConfig, data: any): any {
    return requestConfig.params.reduce((params, nextParam) => {
      const paramValue = this.getParamValue(
        data,
        nextParam.pathToValue,
        nextParam.value
      );
      return {
        ...params,
        [nextParam.paramName]: nextParam.wrapToArray
          ? [paramValue]
          : paramValue,
      };
    }, {});
  }

  private getParamValue(data: any, pathToValue: string, value: any): any {
    return pathToValue ? _.get(data, pathToValue) : value;
  }

  private getRequest(requestConfig: RequestConfig, data: any): Observable<any> {
    const responseType = requestConfig.responseType
      ? requestConfig.responseType
      : ('json' as any);
    const observe = requestConfig.observe || ('body' as any);
    const paramsToSend = this.buildParams(requestConfig, data);
    const url = requestConfig.evaluateUrl
      ? (eval(requestConfig.url) as string)
      : requestConfig.url;

    switch (requestConfig.type) {
      case RequestType.Post:
        return this.httpClient.post(url, paramsToSend, {
          responseType,
          observe,
        });
      case RequestType.Delete:
        return this.httpClient.delete(url);
      case RequestType.Get:
        return this.httpClient.get(url, {
          params: paramsToSend,
          responseType,
          observe,
        });
    }
  }

  getData<Type>(requestConfig: RequestConfig, data: Type): Observable<Type> {
    const dataToReturn = this.getRequest(requestConfig, data).pipe(
      map((res) =>
        requestConfig.pathToDataFromResponse
          ? _.get(res, requestConfig.pathToDataFromResponse)
          : res
      ),
      catchError(handleApiError)
    );

    return requestConfig.propertyToWrapWith
      ? dataToReturn.pipe(
          map((d) => ({ [requestConfig.propertyToWrapWith]: d }))
        )
      : dataToReturn;
  }
}
