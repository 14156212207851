import { Place } from '../../../gk-dynamic-form/services/place/place.model';
import { GkPostalCode } from '../../../gk-dynamic-form/services/postal-code/postal-code.model';
import { Street } from '../../../gk-dynamic-form/services/street/street.model';
import { PersonType } from '../person-type/person-type.model';
import {
  AddressRequestDto,
  LawPerson,
  LawPersonAddress,
  LawPersonType,
  LegalPersonData,
  NaturalPersonData,
  NewLegalPersonRequestDto,
  NewNaturalPersonOrDesignerRequestDto,
} from './law-person.model';

const createAddressString = (address: LawPersonAddress): string =>
  `${
    address.street
      ? `ul. ${address.street.name || address.street} `
      : `${address.place ? address.place.name || address.place : ''} `
  }${address.buildingNumber ? `${address.buildingNumber},` : ''}${
    address.localNumber ? `/${address.localNumber},` : ''
  } ${
    address.postalCode ? address.postalCode.name || address.postalCode : ''
  } ${address.place ? address.place.name || address.place : ''}`.trim();

export interface LawPersonSearchFromApi {
  AdresNazwa: string;
  Adres: AddressRequestDto;
  Archiwalny: boolean;
  Id: number;
  Imie: string;
  Nazwa: string;
  Nazwisko: string;
  Nip: string;
  Pesel: string;
  Regon: string;
  Krs: string;
  Skrot: string;
  NumerUprawnien?: string;
  RodzPet?: number;
}

export type LawPersonSearch =
  | NaturalPersonSearch
  | LegalPersonSearch
  | Applicant;

export const isLegalPerson = (
  item: LawPersonSearch,
): item is LegalPersonSearch => 'regon' in item;

export const isNaturalPerson = (
  item: LawPersonSearch,
): item is NaturalPersonSearch => 'pesel' in item;

export const isApplicant = (item: LawPersonSearch): item is Applicant =>
  !('type' in item);

export const isApplicantByAuthorizedPerson = (
  item: LawPersonSearch,
): item is Applicant => 'authorizedPerson' in item;

export const shouldShowPermissionNumber = (
  lawPerson: LawPersonSearch,
): lawPerson is NaturalPersonSearch =>
  isNaturalPerson(lawPerson) && !!lawPerson.permissionNumber;

export class NaturalPersonSearch {
  constructor(
    public id: string,
    public address: string,
    public name: string,
    public pesel: string,
    public type: LawPersonType = LawPersonType.Natural,
    public personType?: PersonType,
    public permissionNumber?: string,
    public dataToSend?: NewNaturalPersonOrDesignerRequestDto,
    public data?: LawPerson,
    public availablePersonTypes?: PersonType[],
    public canBeDeleted = true,
    public mainPersonOfDesginerPortal?: boolean,
  ) {}

  static fromApiToApp(data: LawPersonSearchFromApi): NaturalPersonSearch {
    return new this(
      data.Id ? `${data.Id}` : undefined,
      data.AdresNazwa,
      data.Nazwa,
      data.Pesel,
      undefined,
      undefined,
      data.NumerUprawnien,
      undefined,
      new LawPerson(
        undefined,
        new NaturalPersonData(
          data.Imie,
          data.Nazwisko,
          data.Pesel,
          data.NumerUprawnien,
        ),
        data.Adres
          ? new LawPersonAddress(
              new Place(data.Adres.MiejscId, data.Adres.Miejsc, undefined),
              new Street(data.Adres.UlicaId, data.Adres.Ulica),
              data.Adres.NumerBud,
              data.Adres.NumerLok,
              new GkPostalCode(data.Adres.KodPocztId, data.Adres.KodPoczt),
              data.Adres.Poczta,
            )
          : undefined,
        LawPersonType.Natural,
      ),
    );
  }

  static fromFormAfterAdding(
    id: number,
    data: LawPerson,
    dataToSend?: NewNaturalPersonOrDesignerRequestDto,
  ): NaturalPersonSearch {
    const address = data.address;
    const naturalPerson = data.naturalPersonData;

    return new this(
      id ? `${id}` : undefined,
      createAddressString(address),
      `${naturalPerson.lastName} ${naturalPerson.firstName}`,
      naturalPerson.pesel,
      undefined,
      undefined,
      naturalPerson.permissionNumber,
      dataToSend,
      data,
    );
  }
}

export class LegalPersonSearch {
  constructor(
    public id: string,
    public address: string,
    public name: string,
    public regon: string,
    public nip: string,
    public type: LawPersonType = LawPersonType.Legal,
    public personType?: PersonType,
    public dataToSend?: NewLegalPersonRequestDto,
    public data?: LawPerson,
    public availablePersonTypes?: PersonType[],
    public canBeDeleted = true,
    public mainPersonOfDesginerPortal?: boolean,
  ) {}

  static fromApiToApp(data: LawPersonSearchFromApi): LegalPersonSearch {
    return new this(
      data.Id ? `${data.Id}` : undefined,
      data.AdresNazwa,
      data.Nazwa,
      data.Regon,
      data.Nip,
      undefined,
      undefined,
      undefined,
      new LawPerson(
        new LegalPersonData(
          data.Nazwa,
          data.Nip,
          data.Regon,
          data.Krs,
          data.RodzPet,
        ),
        undefined,
        data.Adres
          ? new LawPersonAddress(
              new Place(data.Adres.MiejscId, data.Adres.Miejsc, undefined),
              new Street(data.Adres.UlicaId, data.Adres.Ulica),
              data.Adres.NumerBud,
              data.Adres.NumerLok,
              new GkPostalCode(data.Adres.KodPocztId, data.Adres.KodPoczt),
              data.Adres.Poczta,
            )
          : undefined,
        LawPersonType.Legal,
      ),
    );
  }

  static fromFormAfterAdding(
    id: number,
    data: LawPerson,
    dataToSend?: NewLegalPersonRequestDto,
  ): LegalPersonSearch {
    const address = data.address;
    const legalPerson = data.legalPersonData;

    return new this(
      id ? `${id}` : undefined,
      createAddressString(address),
      legalPerson.name,
      legalPerson.regon,
      legalPerson.nip,
      undefined,
      undefined,
      dataToSend,
      data,
    );
  }
}

export class Applicant {
  public canBeDeleted?: boolean;

  constructor(
    public name: string,
    public personType?: PersonType,
    public id?: string,
    public address?: string,
    public pesel?: string,
    public type?: LawPersonType,
    public permissionNumber?: string,
    public dataToSend?: NewNaturalPersonOrDesignerRequestDto,
    public availablePersonTypes?: PersonType[],
    public mainPersonOfDesginerPortal?: boolean,
    canBeDeleted = true,
    public authorizedPerson?: NaturalPersonSearch,
  ) {
    this.canBeDeleted = canBeDeleted;
  }

  static createFromObjectParams(params: Applicant): Applicant {
    return new this(
      params.name,
      params.personType,
      params.id,
      params.address,
      params.pesel,
      params.type,
      params.permissionNumber,
      params.dataToSend,
      params.availablePersonTypes,
      params.mainPersonOfDesginerPortal,
      params.canBeDeleted,
      params.authorizedPerson,
    );
  }
}
