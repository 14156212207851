import {
  LawPerson,
  LawPersonType,
  MapObjectApiType,
  MapState,
  NewDesignerRequestDto,
  NewLegalPersonRequestDto,
  NewNaturalPersonRequestDto,
  Place,
  Street,
} from '@gk/gk-modules';
import {
  Shipping,
  ShippingDto,
} from '../../../shared/shipping-form/shipping-form.model';
import { DateHelperUtil } from '../../../utils/date-helper/date-helper.util';
import { ApiNewDokumentPowiazanyDalDto } from '../../designer-incoming-documents/designer-incoming-documents.model';
import { DecisionFormType } from '../../excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { NgbDateRange } from '../../new-request/new-request.model';
import { LaneOccupancyAreaWidth } from '../building-works-form/building-works-form.model';

export enum LaneOccupancyDtoType {
  LessThan20PercentWidth = 'JezdniaDo20Powierzchnia',
  Between20And50PercentWidth = 'JezdniaOd20Do50Powierzchnia',
  MoreThan50PercentWidth = 'JezdniaOd50Do100Powierzchnia',
}

function getProperLaneOccupancyTypeLabel(
  value: LaneOccupancyAreaWidth
): LaneOccupancyDtoType {
  switch (value) {
    case LaneOccupancyAreaWidth.LessThan20PercentWidth: {
      return LaneOccupancyDtoType.LessThan20PercentWidth;
    }
    case LaneOccupancyAreaWidth.Between20And50PercentWidth: {
      return LaneOccupancyDtoType.Between20And50PercentWidth;
    }
    case LaneOccupancyAreaWidth.MoreThan50PercentWidth: {
      return LaneOccupancyDtoType.MoreThan50PercentWidth;
    }
  }
}

export interface LaneOccupancyBuildingWorksDto {
  Wnioskodawca:
    | NewNaturalPersonRequestDto
    | NewLegalPersonRequestDto
    | NewDesignerRequestDto;
  DrogaNumer: string | number;
  MiejscId: string | number;
  Miejsc: string;
  UlicaId: string | number;
  Ulica: string;
  Cel: string;
  Etapy: LaneOccupancyBuildingWorksStageDto[];
  Zalaczniki: ApiNewDokumentPowiazanyDalDto[];
  FormaDecyzji: number;
  Odbior: ShippingDto;
}

export interface LaneOccupancyBuildingWorksStageDto {
  Geom: string[];
  JezdniaDo20Powierzchnia?: number;
  JezdniaOd20Do50Powierzchnia?: number;
  JezdniaOd50Do100Powierzchnia?: number;
  ChodnikiPowierzchnia?: number;
  PozostalePowierzchnia?: number;
  TerminZajeciaOd: string;
  TerminZajeciaDo: string;
}

export class LaneOccupancyBuildingWorksArea {
  constructor(
    public roadWidth: LaneOccupancyAreaWidth,
    public road: number,
    public roadsideComponents: number,
    public roadLaneOtherElements: number,
    public mapState: MapState,
    public dateRange: NgbDateRange
  ) {}

  static fromAppToApi(
    data: LaneOccupancyBuildingWorksArea
  ): LaneOccupancyBuildingWorksStageDto {
    return {
      Geom: data.mapState.mapObjectTablesState[0].mapObjects
        .filter(
          (mapObject) => mapObject.type === MapObjectApiType.ExtentOrPolygon
        )
        .map((mapObject) => mapObject.geom),
      [getProperLaneOccupancyTypeLabel(data.roadWidth)]: data.road,
      ChodnikiPowierzchnia: data.roadsideComponents,
      PozostalePowierzchnia: data.roadLaneOtherElements,
      TerminZajeciaOd: DateHelperUtil.ngbDateToApiDateTime(data.dateRange.from),
      TerminZajeciaDo: DateHelperUtil.ngbDateToApiDateTime(data.dateRange.to),
    };
  }
}

export class LaneOccupancyBuildingWorks {
  constructor(
    public lawPerson: LawPerson,
    public laneOccupancyPurpose: string,
    public laneOccupancyArea: LaneOccupancyBuildingWorksArea[],
    public roadNumber: string,
    public locationPlace: Place,
    public locationStreet: Street,
    public decisionForm: DecisionFormType,
    public shippingForm: Shipping
  ) {}

  static fromAppToApi(
    data: LaneOccupancyBuildingWorks,
    files: ApiNewDokumentPowiazanyDalDto[]
  ): LaneOccupancyBuildingWorksDto {
    return {
      Wnioskodawca:
        data.lawPerson.type === LawPersonType.Natural
          ? NewNaturalPersonRequestDto.fromAppToApi(data.lawPerson)
          : NewLegalPersonRequestDto.fromAppToApi(data.lawPerson),
      DrogaNumer: data.roadNumber,
      MiejscId: data.locationPlace.id,
      Miejsc: data.locationPlace.name,
      UlicaId: data.locationStreet && data.locationStreet.id,
      Ulica: data.locationStreet && data.locationStreet.name,
      Cel: data.laneOccupancyPurpose,
      Zalaczniki: files,
      FormaDecyzji: data.decisionForm,
      Odbior: ShippingDto.fromAppToApi(data.shippingForm),
      Etapy: data.laneOccupancyArea.map((element) =>
        LaneOccupancyBuildingWorksArea.fromAppToApi(element)
      ),
    };
  }
}
