@if (!webPortalData) {
  <div class="d-flex justify-content-center my-3">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
    </div>
  </div>
} @else {
  <div
    [style.background-image]="backgroundUrlFromDb"
    [ngClass]="{
      background: backgroundUrlFromDb,
      'content-footer': webFooterLogoExists,
      'content-no-footer': !webFooterLogoExists
    }"
    class="d-flex flex-column justify-content-center content"
  >
    <div
      class="d-flex flex-column justify-content-center content"
      [ngClass]="{
        background: backgroundUrlFromDb,
        'content-footer': webFooterLogoExists,
        'content-no-footer': !webFooterLogoExists
      }"
    >
      <div class="text-center pt-1 mb-3">
        <div
          [innerHTML]="webDepartmentsHeaderText"
          [ngClass]="{
            'col-xs-12 col-sm-10 col-md-8 mx-auto mask p-1': backgroundUrlFromDb
          }"
        ></div>
        @if (mainWebPortalData?.logoUrl) {
          <img
            src="{{ mainWebPortalData?.logoUrl }}"
            class="main-portal-logo-max-width"
            alt="{{ mainWebPortalData?.name }}-logo"
          />
        }
      </div>
      <div class="container mb-5 pb-5">
        <div class="d-flex flex-wrap justify-content-center">
          @for (
            department of officeDepartmentsService.officeDepartments | async;
            track department.id
          ) {
            @if (department.code === departmentCode.Planning) {
              <button
                routerLink="/{{
                  mainRoutes.PlanningDepartmentExtractFromLocalSpatialDevelopmentPlan
                }}/requests-list"
                type="button"
                class="btn btn-color-from-db btn-lg ms-2 mt-2"
              >
                {{ department.name }}
              </button>
            }
            @if (department.code !== departmentCode.Planning) {
              <button
                [routerLink]="[
                  '/office-departments-portal/department',
                  department.code
                ]"
                type="button"
                class="btn btn-color-from-db btn-lg ms-2 mt-2"
              >
                {{ department.name }}
              </button>
            }
          }
        </div>
      </div>
    </div>
  </div>
}
