export enum BuildingObjectsControlName {
  LawPerson = 'lawPerson',
  LaneOccupancyPurpose = 'laneOccupancyPurpose',
  RoadNumber = 'roadNumber',
  Place = 'locationPlace',
  Street = 'locationStreet',
  AreaWithoutRoadEngineeringFacilities = 'areaWithoutRoadEngineeringFacilities',
  LaneOccupancyDate = 'laneOccupancyDate',
  DecisionForm = 'decisionForm',
  ShippingForm = 'shippingForm'
}
