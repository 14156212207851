import { DistrictFromApi, SheetFromApi } from '@gk/gk-modules';

export class Sheet {
  constructor(
    public id: number,
    public name: string,
  ) {}

  static fromApiToApp(sheetFromApi: SheetFromApi): Sheet {
    return new this(sheetFromApi.Id, sheetFromApi.Nazwa);
  }
}

export class District {
  constructor(
    public id: number,
    public name: string,
    public sheets: Sheet[],
  ) {}

  static fromApiToApp(districtFromApi: DistrictFromApi): District {
    return new this(
      districtFromApi.Id,
      districtFromApi.Nazwa,
      districtFromApi.Arkusze.map((sheetFromApi) =>
        Sheet.fromApiToApp(sheetFromApi),
      ),
    );
  }
}

export interface CommunityFromApi {
  Id: number;
  Nazwa: string;
  Urzad: string;
  Gus: string;
  MiastoId: number;
  Miasto: string;
  MiastoGus: string;
  Obreby: DistrictFromApi[];
}

export class Community {
  constructor(
    public id: number,
    public name: string,
    public districts: District[],
  ) {}

  static fromApiToApp(communityFromApi: CommunityFromApi): Community {
    return new this(
      communityFromApi.Id,
      communityFromApi.Nazwa,
      communityFromApi.Obreby.map((districtFromApi) =>
        District.fromApiToApp(districtFromApi),
      ),
    );
  }
}

export interface ResponseCommunityFromApi {
  UpdateNeeded: boolean;
  Count: number;
  Timestamp: string;
  Response: CommunityFromApi[];
}

export class SelectOption {
  constructor(
    public id: number,
    public name: string,
  ) {}
}
