export interface TfaWebAuthnVerificationCredentialsApi {
  Challenge: string;
  CredentialIds: VerifiedCredentialApi[];
}

export interface VerifiedCredentialApi {
  CredentialId: string;
  DataD: string;
}

export abstract class TfaWebAuthnVerificationCredentialsBase {
  public authenticatorSelection = {
    authenticatorAttachment: 'cross-platform',
    userVerification: 'discouraged',
  };
  public timeout = 60000;

  constructor(public challenge: BufferSource) {}
}

export class TfaWebAuthnVerificationCredentials extends TfaWebAuthnVerificationCredentialsBase {
  constructor(
    public override challenge: BufferSource,
    public credentialIds: VerifiedCredential[]
  ) {
    super(challenge);
  }

  static fromApiToApp(
    data: TfaWebAuthnVerificationCredentialsApi
  ): TfaWebAuthnVerificationCredentials {
    return new this(
      Uint8Array.from(data.Challenge, (c) => c.charCodeAt(0)),
      data.CredentialIds.map((credentialId) =>
        VerifiedCredential.fromApiToApp(credentialId)
      )
    );
  }
}

export class TfaWebAuthnVerificationCredentialsPublicKeyOptions extends TfaWebAuthnVerificationCredentialsBase {
  constructor(
    public override challenge: BufferSource,
    public allowCredentials: AllowedCredential[]
  ) {
    super(challenge);
  }

  static fromVerificationCredentials(
    data: TfaWebAuthnVerificationCredentials
  ): TfaWebAuthnVerificationCredentialsPublicKeyOptions {
    return new this(
      data.challenge,
      data.credentialIds.map((credentialId) =>
        AllowedCredential.fromVerifiedCredential(credentialId)
      )
    );
  }
}

export class VerifiedCredential {
  constructor(public id: string, public additionDate: string) {}

  static fromApiToApp(data: VerifiedCredentialApi): VerifiedCredential {
    return new this(data.CredentialId, data.DataD);
  }
}

export class AllowedCredential {
  public type = 'public-key' as const;
  public transports: AuthenticatorTransport[] = ['usb', 'ble', 'nfc'];
  constructor(public id: Uint8Array) {}

  static fromVerifiedCredential(data: VerifiedCredential): AllowedCredential {
    return new this(
      Uint8Array.from(window.atob(data.id), (c) => c.charCodeAt(0))
    );
  }
}

export class VerificationCredentialsApi {
  constructor(
    public CredentialId: string,
    public ClientDataJson: string,
    public ClientData: string,
    public AuthenticatorData: string,
    public Signature: string
  ) {}

  static fromApp(data: VerificationCredentials): VerificationCredentialsApi {
    return new this(
      data.id,
      data.clientDataObjWithChallengeStringJSON,
      data.clientData,
      data.authenticatorData,
      data.signature
    );
  }
}

export class VerificationCredentials {
  constructor(
    public id: string,
    public clientDataObjWithChallengeStringJSON: string,
    public clientData: string,
    public authenticatorData: string,
    public signature: string
  ) {}

  static fromNavigatorCredentials(data: Credential): VerificationCredentials {
    const clientDataObj = JSON.parse(
      new TextDecoder('utf-8').decode((data as any).response.clientDataJSON)
    );
    return new this(
      data.id,
      JSON.stringify({
        ...clientDataObj,
        challenge: window.atob(clientDataObj.challenge),
      }),
      window.btoa(
        String.fromCharCode.apply(
          null,
          Array.from(new Uint8Array((data as any).response.clientDataJSON))
        )
      ),
      window.btoa(
        String.fromCharCode.apply(
          null,
          Array.from(new Uint8Array((data as any).response.authenticatorData))
        )
      ),

      window.btoa(
        String.fromCharCode.apply(
          null,
          Array.from(new Uint8Array((data as any).response.signature))
        )
      )
    );
  }
}
