import { AngularOpenlayersModule } from 'ng-openlayers';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GkMapModule } from '@gk/gk-modules';
import { TranslateModule } from '@ngx-translate/core';
import { SpatialInformationPortalRoutingModule } from './spatial-information-portal-routing.module';
import { SpatialInformationPortalComponent } from './spatial-information-portal.component';

@NgModule({
  imports: [
    CommonModule,
    SpatialInformationPortalRoutingModule,
    AngularOpenlayersModule,
    GkMapModule,
    TranslateModule,
  ],
  declarations: [SpatialInformationPortalComponent],
})
export class SpatialInformationPortalModule {}
