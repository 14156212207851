import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AddedDocToSignResponse,
  AddedDocToSignResponseFromApi,
} from '@gk/gk-modules';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { fileToBase64 } from '../../utils/files/files.util';
import { ApiNewDokumentPowiazanyDalDto } from '../designer-incoming-documents/designer-incoming-documents.model';
import {
  ApiNewGrantsRequest,
  ApiRightToGrant,
  apiRightToGrantDocTypeIds,
  apiRightToGrantEnumValues,
  NewGrantFormValue,
  rightToGrantKeys,
  RightToGrantType,
} from './rights-to-grant.model';

@Injectable()
export class RightsToGrantService {
  constructor(private httpClient: HttpClient) {}

  private async getApiRightsToGrant(
    formValue: NewGrantFormValue
  ): Promise<ApiRightToGrant[]> {
    const grantsWithBase64Files = await Promise.all(
      rightToGrantKeys
        .filter(
          (rightToGrant: unknown) =>
            formValue[rightToGrant as RightToGrantType].checked
        )
        .map(async (rightToGrant: unknown) => {
          const base64File = formValue[rightToGrant as RightToGrantType]
            .attachment
            ? await fileToBase64(
                formValue[rightToGrant as RightToGrantType].attachment
              )
            : undefined;

          return {
            Typ: this.getApiRightToGrantEnumValue(
              rightToGrant as RightToGrantType
            ),
            NrUpraw: formValue[rightToGrant as RightToGrantType].number,
            Dokument: base64File
              ? new ApiNewDokumentPowiazanyDalDto(
                  formValue[rightToGrant as RightToGrantType].attachment.name,
                  base64File,
                  apiRightToGrantDocTypeIds[rightToGrant as RightToGrantType],
                  ''
                )
              : undefined,
          };
        })
    );

    return grantsWithBase64Files;
  }

  private async getApiNewGrantsRequest(
    formValue: NewGrantFormValue
  ): Promise<ApiNewGrantsRequest> {
    return {
      WniosekMiejsc: formValue.currentPlace,
      Organizacja: formValue.companyName,
      Uprawnienia: await this.getApiRightsToGrant(formValue),
    };
  }

  async applyForNewRights(
    formValue: NewGrantFormValue
  ): Promise<Observable<AddedDocToSignResponse>> {
    const body = await this.getApiNewGrantsRequest(formValue).catch(() => {
      return;
    });

    if (!body) {
      return throwError(() => 'Failed parsing formValue to api value.');
    }

    return this.httpClient
      .post<AddedDocToSignResponseFromApi>(
        '/api/interesant/wniosek/nadanieUprawnienSystemowych/addToSign',
        body
      )
      .pipe(
        map((addedDocToSignResponseFromApi) =>
          AddedDocToSignResponse.fromApiToApp(addedDocToSignResponseFromApi)
        )
      );
  }

  getApiRightToGrantEnumValue(rightToGrantType: RightToGrantType): number {
    return apiRightToGrantEnumValues[rightToGrantType];
  }
}
