import { FormControl, FormGroup } from '@angular/forms';
import {
  LawPerson,
  LawPersonAddressControlName,
  LawPersonControlName,
} from '@gk/gk-modules';
import { NgbDateRange } from '../../../../services/new-request/new-request.model';
import { SharingPurpose } from '../../../../services/sharing-purpose/sharing-purpose.model';

export enum FSPNewRequestControlName {
  LawPerson = 'lawPerson',
  Period = 'period',
  PublicRegister = 'publicRegister',
  PersonalData = 'personalData',
  Purpose = 'purpose',
  Par1 = 'par1',
  Par2 = 'par2',
  Remarks = 'remarks',
}

export interface FSPNewRequestFormGroup {
  [FSPNewRequestControlName.LawPerson]: FormControl<LawPerson>;
  [FSPNewRequestControlName.Period]: FormControl<NgbDateRange>;
  [FSPNewRequestControlName.PublicRegister]: FormControl<string>;
  [FSPNewRequestControlName.Purpose]: FormControl<SharingPurpose>;
  [FSPNewRequestControlName.PersonalData]: FormControl<boolean>;
  [FSPNewRequestControlName.Par1]: FormControl<string>;
  [FSPNewRequestControlName.Par2]: FormControl<string>;
  [FSPNewRequestControlName.Remarks]: FormControl<string>;
  [LawPersonControlName.ApplicantFromRegonAddress]: FormGroup<{
    [LawPersonAddressControlName.PostalCode]: FormControl<string>;
    [LawPersonAddressControlName.BuildingNumber]: FormControl<string>;
    [LawPersonAddressControlName.LocalNumber]: FormControl<string>;
  }>;
}

export interface FSPNewRequestFormValue {
  [FSPNewRequestControlName.LawPerson]: LawPerson;
  [FSPNewRequestControlName.Period]: NgbDateRange;
  [FSPNewRequestControlName.PublicRegister]: string;
  [FSPNewRequestControlName.Purpose]: SharingPurpose;
  [FSPNewRequestControlName.PersonalData]: boolean;
  [FSPNewRequestControlName.Par1]: string;
  [FSPNewRequestControlName.Par2]: string;
  [FSPNewRequestControlName.Remarks]: string;
  [LawPersonControlName.ApplicantFromRegonAddress]: {
    [LawPersonAddressControlName.PostalCode]: string;
    [LawPersonAddressControlName.BuildingNumber]: string;
    [LawPersonAddressControlName.LocalNumber]: string;
  };
}
