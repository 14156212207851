<div class="container">
  <section class="mt-4">
    <div class="card mb-4 border-dark">
      <div class="card-header text-white bg-dark">
        {{ workspaceStateTranslations['HEADER'] }}
      </div>

      <div class="card-body">
        <button
          [disabled]="workspaceStateLoading"
          type="button"
          class="btn btn-sm btn-outline-primary me-1"
          (click)="saveWorkspaceState()"
        >
          <span class="fa fa-paperclip"></span>
          {{ workspaceStateTranslations['SAVE'] }}
        </button>

        <button
          [disabled]="workspaceStateLoading"
          type="button"
          class="btn btn-sm btn-outline-primary"
          (click)="loadWorkspaceState()"
        >
          <span class="fa fa-inbox"></span>
          {{ workspaceStateTranslations['RESTORE'] }}
        </button>
      </div>

      @if (workspaceMsgTxt) {
        <div class="card-footer">
          <div class="d-flex flex-column">
            <span [ngClass]="'text-' + workspaceMsgType">{{
              workspaceMsgTxt
            }}</span>
            @if (workspaceStateLoading) {
              <div class="d-flex justify-content-center my-3">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">{{
                    'LOADING' | translate
                  }}</span>
                </div>
              </div>
            }
          </div>
        </div>
      }
    </div>
  </section>

  <form
    (ngSubmit)="handleSubmit()"
    [formGroup]="formGroup"
    class="row"
    novalidate
  >
    <section>
      <div
        class="card mb-4"
        [ngClass]="
          submitted && !combinedPersonFormComponent?.isValid()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{ 'NEW_REQUEST.CLIENT_DATA' | translate }}
        </div>

        <div class="card-body">
          @if (applicantType !== undefined) {
            <gk-combined-person-form
              [lawPersonFormGroup]="getLawPersonFormGroup()"
              [forceApplicantType]="applicantType"
              [wasFormValidated]="submitted"
              [legalPersonSearchFieldsToHide]="[
                legalPersonControlName.TypeId,
                legalPersonControlName.Name,
                legalPersonControlName.KrsNumber
              ]"
              (selectedPersonChange)="onApplicantChange($event)"
            >
            </gk-combined-person-form>
          }
        </div>

        @if (submitted && !combinedPersonFormComponent?.isPersonChosen()) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'NEW_REQUEST.CLIENT_DATA_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <section class="col-12">
      <div class="card mb-4 border-dark">
        <div class="card-header text-white bg-dark">
          {{ 'NEW_REQUEST.TASK_DETAILS' | translate }}
        </div>

        <div class="card-body row">
          @if (shouldShowControl('Par1')) {
            <div class="mb-2">
              <label class="text-nowrap" [attr.for]="formControlName.Par1">
                {{ 'NEW_REQUEST.TASK_DESCRIPTION' | translate }}
              </label>
              <textarea
                [id]="formControlName.Par1"
                [name]="formControlName.Par1"
                [formControlName]="formControlName.Par1"
                aria-required="true"
                class="form-control form-control-sm required"
              ></textarea>
              @if (shouldShowErrorAlert(formControlName.Par1, 'required')) {
                <div class="alert alert-danger">
                  {{ 'FIELD_REQUIRED' | translate }}
                </div>
              }
            </div>
          }

          @if (shouldShowControl('Par2')) {
            <div class="mb-2">
              <label [attr.for]="formControlName.Par2">{{
                'NEW_REQUEST.LEGAL_PROVISION' | translate
              }}</label>
              <textarea
                [id]="formControlName.Par2"
                [name]="formControlName.Par2"
                [formControlName]="formControlName.Par2"
                class="form-control form-control-sm required"
                aria-required="true"
              ></textarea>
              @if (shouldShowErrorAlert(formControlName.Par2, 'required')) {
                <div class="alert alert-danger">
                  {{ 'FIELD_REQUIRED' | translate }}
                </div>
              }
            </div>
          }

          <div class="col-lg-2 col-md-6 col-sm-12">
            <label>{{ 'NEW_REQUEST.PERIOD' | translate }}</label>

            <div class="form-group">
              <app-date-range-picker
                [currentDateIsMinDate]="true"
                [minimumNumberOfDaysFromTodayInToDate]="1"
                (dateRangeChanged)="handleDateRangeChanged($event)"
                [required]="true"
              ></app-date-range-picker>

              @if (shoulShowDateRangeAlert()) {
                <div class="alert alert-danger mt-1">
                  {{ 'PERIOD_INVALID' | translate }}
                </div>
              }

              @if (shoulShowDatePastAlert()) {
                <div class="alert alert-danger mt-1">
                  {{ 'PAST_DATE' | translate }}
                </div>
              }

              @if (shoulShowDateToSameAsTodayAlert()) {
                <div class="alert alert-danger mt-1">
                  {{ 'DATA_TO_SAME_AS_TODAY' | translate }}
                </div>
              }
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-12">
            <label for="sharingPurposeDataSet" class="mb-0">
              {{ 'NEW_REQUEST.SHARING_PURPOSE_REGISTRY' | translate }}
            </label>

            <div class="form-group">
              <select
                [id]="formControlName.PublicRegister"
                [name]="formControlName.PublicRegister"
                [formControlName]="formControlName.PublicRegister"
                class="form-select form-select-sm required"
                aria-required="true"
              >
                @for (
                  publicRegister of publicRegistersDictionary;
                  track publicRegister.id
                ) {
                  <option [value]="publicRegister.id">
                    {{ publicRegister.name }}
                  </option>
                }
              </select>

              @if (
                shouldShowErrorAlert(formControlName.PublicRegister, 'required')
              ) {
                <div class="alert alert-danger">
                  {{ 'FIELD_REQUIRED' | translate }}
                </div>
              }
            </div>

            @if (isPersonalDataControlShouldBeVisible()) {
              <div class="form-group">
                <div class="form-check">
                  <input
                    [id]="formControlName.PersonalData"
                    [name]="formControlName.PersonalData"
                    [formControlName]="formControlName.PersonalData"
                    type="checkbox"
                    class="form-check-input"
                  />
                  <label
                    class="form-check-label"
                    [attr.for]="formControlName.PersonalData"
                  >
                    {{ 'NEW_REQUEST.PERSONAL_DATA' | translate }}
                  </label>
                </div>
              </div>
            }
          </div>

          <div class="form-group col-lg-7 col-md-12">
            <label [attr.for]="formControlName.Remarks">{{
              'NEW_REQUEST.REMARKS' | translate
            }}</label>

            <textarea
              class="form-control form-control-sm"
              [id]="formControlName.Remarks"
              [name]="formControlName.Remarks"
              [formControlName]="formControlName.Remarks"
            ></textarea>
          </div>
        </div>
      </div>
    </section>

    <section class="col-12">
      <div
        class="card mb-4"
        [ngClass]="
          submitted && getInvalidToolType()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{ 'NEW_REQUEST.CHOOSE_RANGE_TYPE' | translate }}
        </div>

        <div class="card-body">
          <gk-map
            [mapState]="mapState"
            (dispatch)="handleMapAction($event)"
          ></gk-map>

          <div class="mt-3">
            @if (
              mapState.toolsState[toolType.LandParcel]?.isActive &&
              mapState.mapObjectTablesState[mapParcelsObjectTableStateIndex]
                ?.mapObjects?.length
            ) {
              <h4>{{ 'NEW_REQUEST.CHOSEN_PARCELS' | translate }}</h4>
              <gk-map-object-table
                [attr.aria-required]="true"
                [mapState]="mapState"
                [mapObjectTableStateIndex]="mapParcelsObjectTableStateIndex"
                (dispatch)="handleMapAction($event)"
              ></gk-map-object-table>
            }

            @if (
              mapState.toolsState[toolType.District]?.isActive &&
              mapState.mapObjectTablesState[mapDistrictsObjectTableStateIndex]
                ?.mapObjects?.length
            ) {
              <h4>{{ 'NEW_REQUEST.CHOSEN_DISTRICTS' | translate }}</h4>
              <gk-map-object-table
                [attr.aria-required]="true"
                [mapState]="mapState"
                [mapObjectTableStateIndex]="mapDistrictsObjectTableStateIndex"
                (dispatch)="handleMapAction($event)"
              ></gk-map-object-table>
            }

            @if (
              mapState.toolsState[toolType.AnyPolygon]?.isActive &&
              mapState.mapObjectTablesState[mapRangesObjectTableStateIndex]
                ?.mapObjects?.length
            ) {
              <h4>{{ 'NEW_REQUEST.DRAWN_POLYGON' | translate }}</h4>
              <gk-map-object-table
                [attr.aria-required]="true"
                [mapState]="mapState"
                [mapObjectTableStateIndex]="mapRangesObjectTableStateIndex"
                (dispatch)="handleMapAction($event)"
              ></gk-map-object-table>
            }
          </div>
        </div>

        @if (getInvalidToolType(); as invalidToolType) {
          @if (submitted) {
            @switch (invalidToolType) {
              @case (toolType.AnyPolygon) {
                <div class="card-footer">
                  <div class="alert alert-danger">
                    {{ 'NEW_REQUEST.CHOOSE_ONE_POLYGON' | translate }}
                  </div>
                </div>
              }
              @case (
                [
                  toolType.District,
                  toolType.LandParcel,
                  emptyToolType
                ].includes(invalidToolType)
                  ? invalidToolType
                  : false
              ) {
                <div class="card-footer">
                  <div class="alert alert-danger">
                    {{ 'NEW_REQUEST.CHOOSE_RANGE' | translate }}
                  </div>
                </div>
              }
            }
          }
        }
      </div>
    </section>

    <section class="col-12">
      <div class="card mb-4 border-dark">
        <div class="card-header text-white bg-dark">
          {{
            'NEW_REQUEST.AUTHORIZED_PERSON_TO_REGISTRY_VIEW_HEADER' | translate
          }}
        </div>

        <div class="card-body row">
          <div class="col-12">
            <app-authorized-person-to-registry-view
              [isAuthorizedPersonWithLoggedUserDuplicatePrevent]="false"
              [(authorizedPersonsToRegistryView)]="
                authorizedPersonsToRegistryView
              "
            >
            </app-authorized-person-to-registry-view>
          </div>
        </div>
        @if (submitted && !authorizedPersonsToRegistryView.length) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'NEW_REQUEST.REQUIRED_AUTHORIZED_PERSON' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <section class="col-12">
      <div class="card mb-4 border-dark">
        <div class="card-header text-white bg-dark">
          {{ 'NEW_REQUEST.CHOOSE_ENABLEMENT_TYPE' | translate }}
        </div>

        <div class="card-body row">
          <div class="col-12">
            <app-enablement
              [submitted]="submitted || validatedToPreview"
              [addingPersonsDirectlyToDb]="false"
              [enablement]="initialEnablement"
              [hideLackEnablement]="true"
              [legalPersonOnly]="true"
              [searchLegalPersonCustomUrl]="'/api/system/petenci/ospraw/search'"
              (enablementFormChange)="onEnablementFormChange($event)"
            >
            </app-enablement>
          </div>
        </div>
      </div>
    </section>

    <div class="col-12">
      @if (submitted && !isFormValid()) {
        <div class="alert alert-danger my-2">
          {{ 'NEW_REQUEST.NOT_EVERY_FIELD_IS_FILLED' | translate }}
        </div>
      }

      @if (validatedToPreview && !isFormValidToPreview()) {
        <div class="alert alert-danger mt-2">
          {{ 'NEW_REQUEST.INCORRECTLY_FILLED_FIELDS_EXISTING' | translate }}
        </div>
      }

      @if (errorPreviewMessage) {
        <div class="alert alert-danger mt-2">
          <span>{{ errorPreviewMessage | translate }}</span>
        </div>
      }

      @if (downloadingPreviewFile) {
        <div class="d-flex justify-content-center my-3">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
          </div>
        </div>
      }

      <button
        type="submit"
        [disabled]="docSignPending"
        class="btn btn-color-from-db my-3 me-2"
      >
        {{ 'NEW_REQUEST.SUBMIT_REQUEST_BUTTON' | translate }}
      </button>

      <button
        type="button"
        [disabled]="docSignPending"
        class="btn btn-color-from-db"
        (click)="handlePreviewNewRequest()"
      >
        {{ 'NEW_REQUEST.REQUEST_PREVIEW' | translate }}
      </button>

      <div class="d-flex flex-column">
        @if (docSignMsgTxt) {
          <span [ngClass]="'text-' + docSignMsgType">
            {{ docSignMsgTxt }}
          </span>
        }

        @if (docSignUrl) {
          <span class="my-2 text-info">
            {{ docSignTranslations['SIGN_INFO'] }}
          </span>
        }

        @if (docSignUrl) {
          <a class="link" [href]="docSignUrl" target="_blank">
            {{ docSignTranslations['SIGN'] }}
          </a>
        }

        @if (docSignPending) {
          <div class="d-flex justify-content-center my-3">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
            </div>
          </div>
        }
      </div>

      <div class="mt-2"><app-form-legend></app-form-legend></div>
    </div>
  </form>
</div>
