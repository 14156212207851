import { GridActionButtonsConfig } from '../../gk-kendo-grid-action-buttons/gk-kendo-action-buttons-model';
import { GkMapSheetFormComponent } from './gk-map-sheet-form.component';
import { Paper, PaperOrientation, Scale } from '../../../gk-map/models';
import {
  iwIconCheckmark,
  iwIconCross,
} from '../../../assets/css/ewid-svg/svg-icons';

export enum GkMapSheetFormControlName {
  PaperOrientation = 'paperOrientation',
  Paper = 'paper',
  Scale = 'scale',
}
export class GkMapSheetFormModel {
  constructor(
    public paperOrientation: PaperOrientation,
    public paper: Paper,
    public scale: Scale,
  ) {}
}

export const gkMapSheetFormModel = new GkMapSheetFormModel(null, null, null);

export const getGkMapSheetFormActionButtonsConfig = (
  c: GkMapSheetFormComponent,
): GridActionButtonsConfig => ({
  actionsLayout: 'end',
  buttons: [
    {
      text: 'Czyść',
      iconClass: 'fa fa-eraser',
      click: () => c.clearForm(),
    },
    {
      text: 'Wybierz',
      type: 'submit',
      svgIcon: iwIconCheckmark,
      className: 'svg-icon-color-info',
    },
    {
      text: 'Zamknij',
      svgIcon: iwIconCross,
      className: 'svg-icon-red',
      click: () => c.onCancel(),
    },
  ],
});
