import { Credentials } from '../tfa-web-authn-initialization-credentials/tfa-web-authn-initialization-credentials.model';

export class TfaWebAuthnInitializationApi {
  constructor(
    public ClientData: ClientDataApi,
    public AttestationObject: AttestationObjectApi
  ) {}

  static fromAppToApi(data: Credentials): TfaWebAuthnInitializationApi {
    return new this(
      ClientDataApi.fromAppToApi(data.clientDataObj),
      AttestationObjectApi.fromAppToApi(data)
    );
  }
}

export class ClientDataApi {
  constructor(
    public Challenge: string,
    public Origin: string,
    public Type: string
  ) {}

  static fromAppToApi(data: ClientData): ClientDataApi {
    return new this(window.atob(data.challenge), data.origin, data.type);
  }
}

export class ClientData {
  constructor(
    public challenge: string,
    public origin: string,
    public type: string
  ) {}
}

export class AttestationObjectApi {
  constructor(public AuthData: AuthDataApi) {}

  static fromAppToApi(data: Credentials): AttestationObjectApi {
    return new this(AuthDataApi.fromAppToApi(data));
  }
}

export class AuthDataApi {
  constructor(public CredentialId: number[], public PublicKey: PublicKeyApi) {}

  static fromAppToApi(data: Credentials): AuthDataApi {
    return new this(data.credentialIdBytes, PublicKeyApi.fromAppToApi(data));
  }
}

export class PublicKeyApi {
  constructor(
    public KeyType: string,
    public Algorithm: string,
    public KeyCurveType: string,
    public KeyXCoordinate: number[],
    public KeyYCoordinate: number[]
  ) {}

  static fromAppToApi(data: Credentials): PublicKeyApi {
    return new this(
      data.publicKeyObject['1'],
      data.publicKeyObject['3'],
      data.publicKeyObject['-1'],
      data.xCoordinate,
      data.yCoordinate
    );
  }
}

export class TfaWebAuthnInitialization {
  constructor(public resetToken: string, public challenge: Uint8Array) {}
}
