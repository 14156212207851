import { FieldId } from '@gk/gk-modules';

export interface PlaceFromApi {
  Nazwa: string;
  Id: number;
  IsLocal: boolean;
  HasNullStreet?: boolean;
  TerytSymbol?: string;
  GminaNazwa?: string;
  KrajNazwa?: string;
}

export class Place {
  constructor(
    public id: FieldId,
    public name: string,
    public isLocal: boolean,
    public hasNullStreet?: boolean,
  ) {}

  static fromApiToApp(placeFromApi: PlaceFromApi): Place {
    return new this(
      placeFromApi.Id,
      placeFromApi.Nazwa,
      placeFromApi.IsLocal,
      placeFromApi.HasNullStreet,
    );
  }
}
