import * as _ from 'lodash';

export class TimerUtil {
  static getSecondsPart(seconds: number): string {
    if (!_.isNumber(seconds) || _.isNaN(seconds) || seconds <= 0) {
      throw new Error('Expected number, should never happen');
    }

    const secondsPart = seconds % 60;

    return secondsPart > 9 ? `${secondsPart}` : `0${secondsPart}`;
  }

  static getMinutesPart(seconds: number): string {
    if (!_.isNumber(seconds) || _.isNaN(seconds) || seconds <= 0) {
      throw new Error('Expected number, should never happen');
    }

    const minutesPart = Math.round(Math.floor(seconds / 60));

    return minutesPart > 9 ? `${minutesPart}` : `0${minutesPart}`;
  }

  static generateString(seconds: number): string {
    if (!_.isNumber(seconds) || _.isNaN(seconds) || seconds <= 0) {
      return '00:00';
    }

    if (seconds > 0) {
      return `${this.getMinutesPart(seconds)}:${this.getSecondsPart(seconds)}`;
    }

    return '';
  }
}
