import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class ApiBaseHrefInterceptor implements HttpInterceptor {
  constructor(@Inject(DOCUMENT) private dom: Document & { API_BASE_HREF: string }) {}

  isApiCall(url: string): boolean {
    const apiPattern = new RegExp(/\/api\/.*/);
    return apiPattern.test(url);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isApiCall(req.url)) {
      const newApiUrl = `${this.dom.API_BASE_HREF}${req.url}`;
      req = req.clone({ url: newApiUrl });
    }

    return next.handle(req);
  }
}
