import { inject } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { ConfigOption, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyFieldConfigPresetProvider } from '@ngx-formly/core/lib/models';
import { distinctUntilChanged } from 'rxjs/operators';
import { CachedService } from '../../../gk-dynamic-form/services/cached/cached.service';
import { DictionaryField } from '../../../gk-dynamic-list/services/dictionary/dictionary.model';
import { FormFieldType, FormFieldWrappers } from '../custom-types/model';
import { FormlyFieldKendoSelectComponentProps } from '../custom-types/select.type';

export function dictionaryFieldValidator(
  control: AbstractControl,
): ValidationErrors {
  const controlValue = control.value as DictionaryField;

  return controlValue?.id ? null : { required: true };
}

const defaultItem = {
  id: null,
  name: 'Wszystkie',
} as DictionaryField;

export class CommunitiesPresetProvider
  implements FormlyFieldConfigPresetProvider
{
  cachedService = inject(CachedService);

  getConfiguration(): FormlyFieldConfig {
    return {
      key: 'community',
      type: FormFieldType.KendoSelect,
      wrappers: [FormFieldWrappers.FormField],
      defaultValue: defaultItem,
      props: {
        label: 'Jednostka ewidencyjna',
        options: this.cachedService.communitiesWithDistricts,
        valueProp: 'id',
        labelProp: 'name',
        valuePrimitive: false,
      } as FormlyFieldKendoSelectComponentProps,
    };
  }
}

export class DistrictsPresetProvider
  implements FormlyFieldConfigPresetProvider
{
  cachedService = inject(CachedService);

  getConfiguration(): FormlyFieldConfig {
    return {
      key: 'district',
      type: FormFieldType.KendoSelect,
      wrappers: [FormFieldWrappers.FormField],
      defaultValue: defaultItem,
      props: {
        label: 'Obręb',
        options: this.cachedService.allDistricts,
        valueProp: 'id',
        labelProp: 'name',
        valuePrimitive: false,
      } as FormlyFieldKendoSelectComponentProps,
      hooks: {
        // @todo() https://formly.dev/docs/guide/expression-properties/#3-get-notified-about-an-expression-changes
        afterViewInit: (field): void => {
          const communityControl = field.parent.get('community').formControl;
          communityControl.valueChanges
            .pipe(distinctUntilChanged())
            .subscribe((value) => {
              field.formControl.reset();
              if (value?.districts?.length) {
                field.props.options = value.districts;
              } else {
                field.props.options = this.cachedService.allDistricts;
              }
            });
        },
      },
    };
  }
}

export class SheetsPresetProvider implements FormlyFieldConfigPresetProvider {
  getConfiguration(): FormlyFieldConfig {
    return {
      key: 'sheet',
      type: FormFieldType.KendoSelect,
      wrappers: [FormFieldWrappers.FormField],
      defaultValue: defaultItem,
      props: {
        label: 'Arkusz',
        options: [],
        valueProp: 'id',
        labelProp: 'name',
        valuePrimitive: false,
        disabled: true,
      } as FormlyFieldKendoSelectComponentProps,
      hooks: {
        // @todo() https://formly.dev/docs/guide/expression-properties/#3-get-notified-about-an-expression-changes
        afterViewInit: (field): void => {
          const districtControl = field.parent.get('district').formControl;
          districtControl.valueChanges
            .pipe(distinctUntilChanged())
            .subscribe((value) => {
              field.formControl.reset();
              if (value?.id) {
                field.props.disabled = false;
                field.props.options = value.sheets;
              } else {
                field.props.disabled = true;
              }
            });
        },
      },
    };
  }
}

export function registerKendoFormlyPresets(): ConfigOption {
  return {
    presets: [
      {
        name: 'communities',
        config: new CommunitiesPresetProvider(),
      },
      {
        name: 'districts',
        config: new DistrictsPresetProvider(),
      },
      {
        name: 'sheets',
        config: new SheetsPresetProvider(),
      },
    ],
  };
}
