import { GMPPrintAttributesControlName } from '../services/print-attributes-form/print-attributes-form.model';
import { GMPPrintQuality } from '../services/print-quality/print-quality.model';
import { GMPPrintTemplate } from '../services/print-template/print-template.model';

export class GkMapPrintConfig {
  constructor(
    public attributesFormVisibleFields = [
      GMPPrintAttributesControlName.PrintTemplate,
      GMPPrintAttributesControlName.MapScaleResolution,
      GMPPrintAttributesControlName.Quality,
      GMPPrintAttributesControlName.PrintFooter,
      GMPPrintAttributesControlName.ShowScaleInfo,
      GMPPrintAttributesControlName.ShowCoordinatesInfo,
      GMPPrintAttributesControlName.ShowCornersCoordinates,
      GMPPrintAttributesControlName.ShowLegend,
      GMPPrintAttributesControlName.ShowNorthArrow,
      GMPPrintAttributesControlName.ShowDocumentWithoutLegalForceLabel,
      GMPPrintAttributesControlName.Subtitle,
    ],
    public attributesFormDisabledFields: GMPPrintAttributesControlName[] = [],
    public attributesFormDefaultValues: {
      [key in GMPPrintAttributesControlName]: any;
    } = {
      [GMPPrintAttributesControlName.PrintTemplate]:
        GMPPrintTemplate.VerticalA4,
      [GMPPrintAttributesControlName.MapScaleResolution]: null,
      [GMPPrintAttributesControlName.Quality]: GMPPrintQuality.Optimal,
      [GMPPrintAttributesControlName.PrintFooter]: null,
      [GMPPrintAttributesControlName.ShowScaleInfo]: true,
      [GMPPrintAttributesControlName.ShowCoordinatesInfo]: true,
      [GMPPrintAttributesControlName.ShowCornersCoordinates]: true,
      [GMPPrintAttributesControlName.ShowLegend]: true,
      [GMPPrintAttributesControlName.ShowNorthArrow]: true,
      [GMPPrintAttributesControlName.ShowDocumentWithoutLegalForceLabel]: true,
      [GMPPrintAttributesControlName.Subtitle]: '',
    },
  ) {}
}
