import { Component, Input } from '@angular/core';

@Component({
  selector: 'gk-list-header',
  templateUrl: './list-header.component.html'
})
export class ListHeaderComponent {
  @Input()
  title = '';
}
