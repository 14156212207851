<form [formGroup]="formGroup" novalidate (ngSubmit)="handleSubmit()" class="">
  @if (
    documentVerifyingInProgress || (documentNotFoundEvent | async)) {
    <div
      class="text-center"
      >
      <em
      [ngClass]="{
        fa: true,
        'fa-gear': documentVerifyingInProgress,
        'fa-search-minus': documentNotFoundEvent | async,
        'fa-spin': documentVerifyingInProgress
      }"
        style="font-size: 8rem"
      ></em>
      <p class="pt-2">
        @if (documentVerifyingInProgress) {
          <span>
            {{
            'OFFICE_DEPARTMENTS_PORTAL.GEODESY.MODAL_DOCUMENT_VERIFICATION.DOCUMENT_VERIFICATION_IN_PROGRESS'
            | translate
            }}</span
            >
          }
          @if (documentNotFoundEvent | async) {
            <span>
              {{
              'OFFICE_DEPARTMENTS_PORTAL.GEODESY.MODAL_DOCUMENT_VERIFICATION.DOCUMENT_GENERATION_FAILURE'
              | translate
              }}
            </span>
          }
        </p>
        @if (documentNotFoundEvent | async) {
          <button
            type="button"
            class="btn btn-secondary doc-verification-ok"
            (click)="onOk()"
            >
            {{
            'OFFICE_DEPARTMENTS_PORTAL.GEODESY.MODAL_DOCUMENT_VERIFICATION.OK'
            | translate
            }}
          </button>
        }
      </div>
    } @else {
      <div class="form-group document-verification-body d-grid">
        <div class="input-group mb-3">
          <label
            class="mx-auto text-center"
            [attr.for]="modalDocumentVerificationFormControlName.DocumentId"
            >
            {{
            'OFFICE_DEPARTMENTS_PORTAL.GEODESY.MODAL_DOCUMENT_VERIFICATION.DOCUMENT_VERIFICATION_INPUT_LABEL'
            | translate
            }}</label
            >
            <input
              type="text"
              class="form-control w-75"
          [ngClass]="{
            'is-invalid': checkFormGroupInvalidAndDirty(),
            'is-valid': checkFormGroupValidAndDirty()
          }"
              [id]="modalDocumentVerificationFormControlName.DocumentId"
          placeholder="{{
            'OFFICE_DEPARTMENTS_PORTAL.GEODESY.MODAL_DOCUMENT_VERIFICATION.DOCUMENT_VERIFICATION_INPUT_PLACEHOLDER'
              | translate
          }}"
              size="36"
              maxlength="36"
          [formControlName]="
            modalDocumentVerificationFormControlName.DocumentId
          "
              />
          </div>
          @if ((documentNotFoundEvent | async) !== true) {
            <button
              type="submit"
              class="btn btn-success submit-doc-verification btn-lg"
              [disabled]="formGroup.invalid || documentVerifyingInProgress"
              >
              {{
              'OFFICE_DEPARTMENTS_PORTAL.GEODESY.MODAL_DOCUMENT_VERIFICATION.VERIFY'
              | translate
              }}
            </button>
          }
          @if (checkFormGroupInvalidAndDirty()) {
            <div class="text-danger">
              <small>{{
                'OFFICE_DEPARTMENTS_PORTAL.GEODESY.MODAL_DOCUMENT_VERIFICATION.DOCUMENT_VERIFICATION_INVAlID_FEEDBACK'
                | translate
              }}</small>
            </div>
          }
        </div>
      }
    </form>
