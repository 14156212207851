export enum SystemConfig {
  WebEwidDepartmentsHeaderTextPl = 'web.wydzialy.header.text.pl',
  WebCmsCountiesHeaderTextPl = 'web.cms.powiaty.header.text.pl',
  WebCmsCountiesHeaderTextEn = 'web.cms.powiaty.header.text.en',
  WebCmsCountiesHeaderTextDe = 'web.cms.powiaty.header.text.de',
  WebCmsCountiesHeaderTextCz = 'web.cms.powiaty.header.text.cz',
  WebCmsVoivodeshipHeaderTextPl = 'web.cms.wojew.header.text.pl',
  WebCmsVoivodeshipHeaderTextEn = 'web.cms.wojew.header.text.en',
  WebCmsVoivodeshipHeaderTextDe = 'web.cms.wojew.header.text.de',
  WebCmsVoivodeshipHeaderTextCz = 'web.cms.wojew.header.text.cz',
  WebCmsEnabled = 'web.cms.enabled',
  WeServiceInfoKeyOnOff = 'webewid.serwis.infoserwisie.onoff',
  WeServiceInfoKeyText = 'webewid.serwis.infoserwisie.text',
}
