import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { takeWhile } from 'rxjs/operators';
import { GetDataAndRenderControlConfig } from '../../../gk-dynamic-list.model';
import { DataService } from '../../../services/data/data.service';
import { Control } from '../control';

@Component({
  selector: 'gk-control-get-data-array-and-render',
  templateUrl: './control-get-data-array-and-render.component.html',
})
export class ControlGetDataArrayAndRenderComponent
  extends Control<string[]>
  implements OnInit
{
  @Input()
  override controlConfig: GetDataAndRenderControlConfig;
  loading: boolean;
  loaded = false;
  errorMsg = '';
  fetchedData: any = {};

  constructor(
    protected override translateService: TranslateService,
    protected override dataService: DataService,
  ) {
    super(translateService, dataService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.fetchData();
  }

  getData(): any {
    return { ...this.data, ...this.fetchedData };
  }

  unsetLoadingAndSetLoaded(): void {
    this.loading = false;
    this.loaded = true;
  }

  fetchData(): void {
    this.loading = true;

    this.dataService
      .getData(this.controlConfig.requestConfig, this.data)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe({
        next: (data) => {
          this.fetchedData = { ...this.fetchedData, ...data };
          this.unsetLoadingAndSetLoaded();
        },
        error: (errorResponse: HttpErrorResponse) => {
          this.errorMsg = _.get(errorResponse, 'error.ResponseStatus.Message');
          this.unsetLoadingAndSetLoaded();
        },
      });
  }
}
