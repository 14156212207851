import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Coordinate } from 'ol/coordinate';
import { Geometry, LineString, Polygon } from 'ol/geom';
import { Observable } from 'rxjs';
import { OverlayPositioning, ProjectionCode, SourceType } from '../../models';

@Injectable()
export class MapMeasurementService {
  constructor(private translateService: TranslateService) {}

  getAreaOrLengthWithUnits(
    sketch: Geometry,
    nativeProjectionCode?: ProjectionCode,
  ): Observable<string> {
    const geometry = nativeProjectionCode
      ? sketch
          .clone()
          .transform(ProjectionCode.PseudoMercator, nativeProjectionCode)
      : sketch;
    if (geometry instanceof Polygon) {
      return this.getAreaWithUnits(geometry);
    } else if (geometry instanceof LineString) {
      return this.getLengthWithUnits(geometry);
    } else {
      return this.translateService.get('GK.MAP.GEOMETRY_TYPE_ERROR');
    }
  }

  getAreaWithUnits(polygon: Polygon): Observable<string> {
    return this.translateService.get('GK.MAP.POLYGON_AREA_IN_HECTARES', {
      area: this.getArea(polygon),
    });
  }

  getArea(polygon: Polygon): number {
    const area = polygon.getArea();

    return this.getRoundAreaInHectares(area);
  }

  getRoundAreaInHectares(sketchArea: number): number {
    return Math.round((sketchArea / 10000) * 10000) / 10000;
  }

  getLengthWithUnits(lineString: LineString): Observable<string> {
    return this.translateService.get('GK.MAP.LINE_STRING_LENGTH_IN_METERS', {
      lastLineLength: this.getLastLineLength(lineString),
      allLinesLength: this.getAllLinesLength(lineString),
    });
  }

  getLastLineLength(lineString: LineString): number {
    const coordinates = lineString.getCoordinates();
    const lastSegmentLine = new LineString(coordinates.slice(-2));
    const lastSegmentLength = lastSegmentLine.getLength();

    return this.getRoundLengthInMeters(lastSegmentLength);
  }

  getAllLinesLength(lineString: LineString): number {
    const lengthSum = lineString.getLength();

    return this.getRoundLengthInMeters(lengthSum);
  }

  getRoundLengthInMeters(sketchLength: number): number {
    return Math.round(sketchLength * 100) / 100;
  }

  getCoordinates(
    sketch: Geometry,
    movingPointerCoordinate: Coordinate,
  ): Coordinate {
    if (sketch instanceof Polygon) {
      return sketch.getInteriorPoint().getCoordinates();
    } else {
      return movingPointerCoordinate;
    }
  }

  getOverlayPositioning(sourceType: SourceType): OverlayPositioning {
    return sourceType === SourceType.Polygon
      ? OverlayPositioning.CenterCenter
      : OverlayPositioning.TopRight;
  }

  getOverlayOffset(sourceType: SourceType): number[] {
    return sourceType === SourceType.Polygon ? [0, 0] : [0, 15];
  }
}
