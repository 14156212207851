import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { EgibObject } from '@gk/gk-modules';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile } from 'rxjs/operators';
import { ParcelListMode } from '../../services/parcel/parcel.model';

@Component({
  selector: 'app-parcel-list',
  templateUrl: './parcel-list.component.html',
})
export class ParcelListComponent implements OnInit, OnDestroy {
  private isAlive = true;
  @Input() parcels: EgibObject[] = [];
  @Input() parcelListMode = ParcelListMode.Choosing;
  @Output() chosenParcels = new EventEmitter<EgibObject[]>();
  selectedParcels: EgibObject[] = [];
  parcelListModeEnum = ParcelListMode;
  sheetTranslation = '';

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.translateService
      .get('PARCEL_SEARCH_FORM.SHEET_2')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((text) => (this.sheetTranslation = text));
  }

  getParcelDescription(parcel: EgibObject): string {
    return `${parcel.mapObjectNumber}, ${parcel.cadastralUnit}
      ${
        parcel.mapSheet ? `, ${this.sheetTranslation}: ${parcel.mapSheet}` : ''
      }`;
  }

  getSelectSize(listLength: number): number {
    const maxSize = 10;
    return listLength < maxSize ? listLength : maxSize;
  }

  emitSelectedParcels(): void {
    this.chosenParcels.emit(this.selectedParcels);
  }

  emitAllParcels(): void {
    this.chosenParcels.emit(this.parcels);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
