import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { EgibObject, LawPersonSearch, MapObject } from '@gk/gk-modules';
import { Address } from '../address/address.model';
import { DocFile } from '../new-designer-request/new-designer-request.model';

@Injectable()
export class FormsStateService {
  newDesignerRequestForm = {} as UntypedFormGroup;
  newDesignerRequestChosenParcels: EgibObject[] = [];
  newDesignerRequestChosenAddresses: Address[] = [];
  newDesignerRequestDocFiles: DocFile[] = [];
  newDesignerRequestIssueRanges: MapObject[] = [];
  newDesignerRequestConnectionsAndNetworksGroups: AbstractControl[] = [];
  chosenLawPersons: LawPersonSearch[] = [];
  agriculturalLandUseRequestChosenParcels: EgibObject[] = [];
  simplifiedForestManagementPlanRequestChosenParcels: EgibObject[] = [];
  agriculturalLandUseRequestExclusionAreas: MapObject[] = [];
  nonAgriculturalLandUseRequestChosenParcels: EgibObject[] = [];
  fishingCardRequestChosenParcels: EgibObject[] = [];
  constructionNotificationRequestChosenParcels: MapObject[] = [];
  demolitionNotificationRequestChosenParcels: MapObject[] = [];
  premiseIndependenceNotificationRequestChosenParcels: MapObject[] = [];
  extractFromLocalSpatialDevelopmentPlanRequestChosenParcels: MapObject[] = [];

  reset(): void {
    this.chosenLawPersons = [];
    this.newDesignerRequestForm = {} as UntypedFormGroup;
    this.newDesignerRequestChosenParcels = [];
    this.newDesignerRequestChosenAddresses = [];
    this.newDesignerRequestDocFiles = [];
    this.newDesignerRequestIssueRanges = [];
    this.newDesignerRequestConnectionsAndNetworksGroups = [];
  }
}
