import { Component, OnDestroy, OnInit } from '@angular/core';
import { PaymentsService } from '../payments.service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'gk-payments',
  templateUrl: './payments.component.html',
  providers: [PaymentsService]
})
export class PaymentsComponent implements OnInit, OnDestroy {
  private isAlive = true;
  unpaidInvoicesTotalCount = 0;
  pendingInvoicesTotalCount = 0;

  constructor(private paymentsService: PaymentsService) {}

  ngOnInit(): void {
    this.setInitialPendingInvoicesTotalCount();
  }

  setUnpaidInvoicesTotalCount($event: number): void {
    this.unpaidInvoicesTotalCount = $event;
  }

  setPendingInvoicesTotalCount($event: number): void {
    this.pendingInvoicesTotalCount = $event;
  }

  private setInitialPendingInvoicesTotalCount(): void {
    this.paymentsService
      .getPendingInvoices()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(invoices => (this.pendingInvoicesTotalCount = invoices.length));
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
