import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ButtonStateControl,
  ListConfigs,
  ListState,
  MapAction,
  MapSettingsService,
  ModalUtils,
  CommunicatorListItem,
  ModalCommunicatorComponent,
} from '@gk/gk-modules';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeWhile } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AppTitleService } from '../../../services/app-title/app-title.service';
import { ConfigManagerService } from '../../../services/config-manager/config-manager.service';
import { DesignerSettingsService } from '../../../services/designer-settings/designer-settings.service';
import { WebPortalService } from '../../../services/web-portal/web-portal.service';
import { BaseRequestsListDirective } from '../../../shared/base-requests-list/base-requests-list.component';

@Component({
  templateUrl: './requests-list.component.html',
  styleUrls: ['./requests-list.component.scss'],
})
export class RequestsListComponent
  extends BaseRequestsListDirective
  implements OnInit, OnDestroy
{
  override listConfigs: ListConfigs;
  listState = {} as ListState;
  pendingMapActions: MapAction[] = [];
  dynamicListItem: any;
  communicatorModalRef: any;

  constructor(
    private mapSettingsService: MapSettingsService,
    private configManagerService: ConfigManagerService,
    public override appTitleService: AppTitleService,
    public override webPortalService: WebPortalService,
    private modalService: NgbModal,
    private designerSettingsService: DesignerSettingsService
  ) {
    super(appTitleService, webPortalService);
  }

  ngOnInit(): void {
    this.setListConfigs();
  }

  setListConfigs(): void {
    this.getCurrentPortalType()
      .pipe(
        takeWhile(() => this.isAlive),
        switchMap((webPortal) =>
          this.configManagerService.getConfigForDesignerDtoList(
            webPortal.portalId,
            (dynamicListItem, buttonStateControl) =>
              this.handleOpenCommunicator(dynamicListItem, buttonStateControl),
            (dynamicListItem, refreshCallback) =>
              this.handleReadNewMessage(dynamicListItem, refreshCallback)
          )
        ),
        tap((listConfigs) => {
          this.designerSettingsService.listConfigs.next(listConfigs);
        })
      )
      .subscribe((listConfigs) => {
        this.listConfigs = listConfigs;
      });
  }

  handleMapAction(mapAction: MapAction): void {
    this.pendingMapActions = [...this.pendingMapActions, mapAction];
  }

  handleOpenCommunicator(
    dynamicListItem: any,
    buttonStateControl: ButtonStateControl
  ): void {
    this.dynamicListItem = dynamicListItem;
    buttonStateControl.activeLoadingSpinner();
    this.openCommunicatorModal(dynamicListItem);
    buttonStateControl.stopLoadingSpinner();
  }

  handleReadNewMessage(dynamicListItem: any, refreshCallback: () => any): void {
    this.dynamicListItem = dynamicListItem;
    this.readNewMessage(dynamicListItem, refreshCallback);
  }

  openCommunicatorModal(dynamicListItem: any): void {
    this.communicatorModalRef = this.modalService.open(
      ModalCommunicatorComponent,
      ModalUtils.getModalOptions()
    ).componentInstance;
    this.communicatorModalRef.listItem =
      CommunicatorListItem.fromApiDesignerToApp(dynamicListItem);
    this.communicatorModalRef.controlConfigs =
      this.listConfigs.communicatorControlConfig;
  }

  readNewMessage(dynamicListItem: any, refreshCallback: () => any): void {
    this.communicatorModalRef = this.modalService.open(
      ModalCommunicatorComponent,
      ModalUtils.getModalOptions()
    ).componentInstance;
    this.communicatorModalRef.listItem = dynamicListItem[0];
    this.communicatorModalRef.controlConfigs =
      this.listConfigs.communicatorControlConfig;
    this.communicatorModalRef.refreshCallback = refreshCallback;
  }

  dispatchPendingMapActions(): void {
    this.mapSettingsService.pendingMapActions.next(this.pendingMapActions);
  }

  override ngOnDestroy(): void {
    if (this.communicatorModalRef) {
      this.communicatorModalRef.closeModal();
    }
    this.dispatchPendingMapActions();
    super.ngOnDestroy();
  }
}
