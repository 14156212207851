export enum Composition {
  RgbBw = 'RGB/BW',
  RgbCir = 'RGB+CIR'
}

export class OrthophotomapSelectOption {
  value: string;

  constructor(public composition: Composition, public releaseYear: number) {
    this.value = `${releaseYear},${composition}`;
  }
}
