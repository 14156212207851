import { Injectable } from '@angular/core';
import { PortalConfigGetter } from '../../services/portal-config/portal-config.interface';
import { WebPortalService } from '../../services/web-portal/web-portal.service';
import { map, Observable } from 'rxjs';
import { MainRoutes, PzInitRoutes } from '../../guards/guard.models';
import { PortalConfig } from '../../services/portal-config/portal-config.model';

@Injectable({
  providedIn: 'root',
})
export class SurveyorPortalProjectConfigService implements PortalConfigGetter {
  constructor(private webPortalService: WebPortalService) {}

  getPortalConfig(): Observable<PortalConfig> {
    return this.webPortalService
      .getWebPortalDataByCurrentRoute(MainRoutes.SurveyorPortal)
      .pipe(
        map((webPortal) => ({
          pzInitRoute: PzInitRoutes.PzInitSystemUrl,
          isPerunAuthenticationAvailable: webPortal.ewidLogging,
          isPzAuthenticationAvailable: webPortal.pzLogging,
          sessionToolsInfoAlertsVisible: true,
        })),
      );
  }
}
