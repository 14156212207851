<div
  class="fixed-top h-100 tfa-confirmation-form d-flex flex-column overflow-visible pt-5 alpha-background"
  >
  <div class="static-height-container">
    <div class="row main-content bg-success text-center">
      <div class="col action-window">
        <div class="container-fluid">
          <div class="row mt-4">
            <div
              class="col-12 d-flex align-items-start justify-content-between"
              >
              <h2 class="w-100">
                {{ 'SESSION_TOOLS.TFA_RESET.HEADER' | translate }}
              </h2>
            </div>
          </div>
          <div class="row">
            <form
              method="post"
              id="tfa-form"
              class="form-group col-12"
              [formGroup]="formGroup"
              (ngSubmit)="tfaResetSubmitHandle()"
              >
              <div class="row pb-2">
                <label for="tfa-code" class="sr-only">{{
                  'SESSION_TOOLS.TFA_RESET.ENTER_RESET_CODE' | translate
                }}</label>
                <input
                  type="text"
                  [formControlName]="controlNameEnum.ResetToken"
                  [attr.name]="controlNameEnum.ResetToken"
                  [attr.id]="controlNameEnum.ResetToken"
                  class="form__input p-0"
                  [attr.placeholder]="
                    'SESSION_TOOLS.TFA_RESET.ENTER_RESET_CODE' | translate
                  "
                  />
              </div>
              <div class="row">
                <div class="col-sm col-12 order-1 order-sm-0">
                  <button
                    id="cancel-tfa-code"
                    class="btn btn-danger"
                    type="button"
                    (click)="cancel()"
                    >
                    {{
                    'SESSION_TOOLS.TFA_BASE_CONFIRMATION.CANCEL' | translate
                    }}
                  </button>
                </div>
                <div class="col-sm col-12">
                  @if (!actionRequestInProgress) {
                    <input
                      type="submit"
                      id="login-button"
                      class="btn btn-normal tfa-ready-info"
                      [attr.value]="'SESSION_TOOLS.TFA_RESET.RESET' | translate"
                      [attr.disabled]="formGroup.valid ? null : true"
                      />
                  } @else {
                    <button
                      type="button"
                      class="btn btn-normal pending-info"
                      [attr.title]="
                        'SESSION_TOOLS.TFA_RESET.RESET_IN_PROGRESS' | translate
                      "
                      >
                      <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                    </button>
                  }
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      @if (messageText) {
        <div
          class="col-12 text-center d-flex justify-content-center py-3"
        [ngClass]="{
          'bg-warning': messageStatus === messageStatusEnum.Warning,
          'bg-normal':
            messageStatus === messageStatusEnum.Success ||
            messageStatus === messageStatusEnum.Pending,
          'bg-danger': messageStatus === messageStatusEnum.Error,
          'text-dark': messageStatus === messageStatusEnum.Warning,
          'text-white':
            messageStatus === messageStatusEnum.Error ||
            messageStatus === messageStatusEnum.Success ||
            messageStatus === messageStatusEnum.Pending
        }"
          >
          <p class="mb-0 fw-bold">
            <i
              class="fa"
            [ngClass]="{
              'fa-exclamation-triangle':
                messageStatus === messageStatusEnum.Warning,
              'fa-check': messageStatus === messageStatusEnum.Success,
              'fa-lock': messageStatus === messageStatusEnum.Error,
              'fa-spinner fa-spin': messageStatus === messageStatusEnum.Pending,
              'text-white':
                messageStatus === messageStatusEnum.Error ||
                messageStatus === messageStatusEnum.Success ||
                messageStatus === messageStatusEnum.Pending
            }"
              >
            </i>
            {{ messageText }}
          </p>
        </div>
      } @else {
        <div
          class="col-12 text-center d-flex justify-content-center py-3 bg-normal text-white"
          >
          <p
            class="mb-0 fw-bold"
            [innerHTML]="
              'SESSION_TOOLS.TFA_RESET.DEFAULT_ACTION_INFO' | translate
            "
          ></p>
        </div>
      }
    </div>
  </div>
</div>
