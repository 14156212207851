import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { b64DecodeUnicode } from '../../utils/files/files.util';
import { DefaultConfigGuestService } from '../default-config-guest/default-config-guest.service';

@Injectable()
export class SystemConfigBlobGuestService {
  private sysConfigWebHeaderLogoBase64Data: Observable<string>;
  private sysConfigWebFooterLogoBase64Data: Observable<string>;
  private sysConfigAccessibilityStatementHtmlContent: Observable<SafeHtml>;
  private sysConfigAccessibilityMobileStatementHtmlContent: Observable<SafeHtml>;
  private sysConfigAccessibilityStatementHtmlContentForClientPortal: Observable<SafeHtml>;

  constructor(
    private sanitizer: DomSanitizer,
    private defaultConfigGuestService: DefaultConfigGuestService
  ) {}

  getSysConfigWebHeaderLogoData(): Observable<string> {
    if (!this.sysConfigWebHeaderLogoBase64Data) {
      this.sysConfigWebHeaderLogoBase64Data = this.defaultConfigGuestService
        .getConfig()
        .pipe(
          map((response) => response && response.webHeaderLogo),
          shareReplay(1)
        );
    }

    return this.sysConfigWebHeaderLogoBase64Data;
  }

  getSysConfigWebFooterLogoData(): Observable<string> {
    if (!this.sysConfigWebFooterLogoBase64Data) {
      this.sysConfigWebFooterLogoBase64Data = this.defaultConfigGuestService
        .getConfig()
        .pipe(
          map((response) => response && response.webFooterLogo),
          shareReplay(1)
        );
    }

    return this.sysConfigWebFooterLogoBase64Data;
  }

  getAccessibilityStatementHtmlContent(): Observable<SafeHtml> {
    if (!this.sysConfigAccessibilityStatementHtmlContent) {
      this.sysConfigAccessibilityStatementHtmlContent =
        this.defaultConfigGuestService.getConfig().pipe(
          map(
            (response) =>
              response.webAccessibilityStatement.length &&
              this.sanitizer.bypassSecurityTrustHtml(
                b64DecodeUnicode(response.webAccessibilityStatement)
              )
          ),
          shareReplay(1)
        );
    }

    return this.sysConfigAccessibilityStatementHtmlContent;
  }

  getAccessibilityStatementHtmlContentForClientPortal(): Observable<SafeHtml> {
    if (!this.sysConfigAccessibilityStatementHtmlContentForClientPortal) {
      this.sysConfigAccessibilityStatementHtmlContentForClientPortal =
        this.defaultConfigGuestService.getConfig().pipe(
          map(
            (response) =>
              response.webClientAccessibilityStatement.length &&
              this.sanitizer.bypassSecurityTrustHtml(
                b64DecodeUnicode(response.webClientAccessibilityStatement)
              )
          ),
          shareReplay(1)
        );
    }

    return this.sysConfigAccessibilityStatementHtmlContentForClientPortal;
  }

  getAccessibilityMobileStatementHtmlContent(): Observable<SafeHtml> {
    if (!this.sysConfigAccessibilityMobileStatementHtmlContent) {
      this.sysConfigAccessibilityMobileStatementHtmlContent =
        this.defaultConfigGuestService.getConfig().pipe(
          map(
            (response) =>
              response.webMobileAccessibilityStatement.length &&
              this.sanitizer.bypassSecurityTrustHtml(
                b64DecodeUnicode(response.webMobileAccessibilityStatement)
              )
          ),
          shareReplay(1)
        );
    }

    return this.sysConfigAccessibilityMobileStatementHtmlContent;
  }
}
