import { Coordinate } from 'ol/coordinate';
import { Place } from '../../gk-dynamic-form/services/place/place.model';
import { Street } from '../../gk-dynamic-form/services/street/street.model';
import { ApiListResponse } from '../../services';
import { DictionaryFromApi } from '../../gk-dynamic-list/services/dictionary/dictionary.model';

export enum MapObjectRangeType {
  LandParcel = 'D',
  Building = 'B',
  Premises = 'L',
  LandUse = 'U',
  ClassificationContour = 'A',
  District = 'O',
  AnyPolygon = 'G',
  RectangularExtent = 'K',
  ControlPoint = 'X',
  BoundaryPoint = 'Z',
}

export class MapObjectsResponse {
  constructor(
    public isThatAll: boolean,
    public count: number,
    public maxCountLimit: number,
    public totalCount: number,
    public response: MapObject[],
  ) {}

  static fromApiToApp<T>(
    apiListResponse: ApiListResponse<T>,
    mapObjects: MapObject[],
  ): MapObjectsResponse {
    return new this(
      apiListResponse.IsThatAll,
      apiListResponse.Count,
      apiListResponse.MaxCountLimit,
      apiListResponse.TotalCount,
      mapObjects,
    );
  }

  static getInitialStruct(): MapObjectsResponse {
    return new this(false, 0, 0, 0, []);
  }
}

export type MapObjectFromApi = Partial<
  | EgibObjectFromApi
  | DistrictMapObjectFromApi
  | LandUseFromApi
  | ClassificationContourFromApi
>;

export type MapObject = Partial<
  | EgibObject
  | DistrictMapObject
  | LandUse
  | ClassificationContour
  | MapObjectInfo
>;

export const isEgibObject = (item: MapObject): item is EgibObject =>
  'districtId' in item;

export class BasicMapObject {
  constructor(
    public uuid: string | number,
    public type: MapObjectApiType | SubjectType,
    public typeName: MapObjectTypeNameTranslationPath | string,
    public geom: Wkt,
    public area?: string,
    public place?: Place,
    public street?: Street,
  ) {}
}

export interface EgibObjectFromApi {
  Uuid: string;
  Obreb: string;
  JednEwid: string;
  Arkusz: string;
  Geom: string;
  Numer: string;
  NazwaX?: number;
  NazwaY?: number;
  Area: string;
  Typ: MapObjectApiType;
  DzialkaId: number;
  ObrebId: number;
  NumeryDzialek: string;
  UsytuowanieBudynku: boolean;
  StatusBudynku: string | number;
  BudynekId: string;
  LokalId: string;
  GminaId: number;
}

export class EgibObject extends BasicMapObject {
  constructor(
    public override area: string,
    public mapSheet: string,
    public override geom: string,
    public cadastralUnit: string,
    public mapObjectNumber: string,
    public districtName: string,
    public override type: MapObjectApiType,
    public override typeName: MapObjectTypeNameTranslationPath,
    public override uuid: string,
    public parcelId: number,
    public districtId: number,
    public nameX?: number,
    public nameY?: number,
    public parcelsNumbers?: string,
    public placesOfDistrict?: Place[],
    public buildingAboveGround?: boolean,
    public buildingPositionTranslationKey?: string,
    public buildingStatusId?: string | number,
    public buildingId?: string,
    public communityId?: number,
  ) {
    super(uuid, type, typeName, geom, area);
  }

  static buildingAboveGroundTranslationKey = 'GK.MAP.BUILDING_ABOVE_GROUND';
  static buildingUndergroundTranslationKey = 'GK.MAP.BUILDING_UNDERGROUND';

  static fromApiToApp(egibObject: EgibObjectFromApi): EgibObject {
    return new this(
      egibObject.Area,
      egibObject.Arkusz,
      egibObject.Geom,
      egibObject.JednEwid,
      egibObject.Numer,
      egibObject.Obreb,
      egibObject.Typ,
      egibObjectTypes[egibObject.Typ],
      egibObject.Uuid,
      egibObject.DzialkaId,
      egibObject.ObrebId,
      egibObject.NazwaX,
      egibObject.NazwaY,
      egibObject.NumeryDzialek,
      undefined,
      egibObject.UsytuowanieBudynku,
      egibObject.UsytuowanieBudynku !== null &&
      egibObject.UsytuowanieBudynku !== undefined
        ? egibObject.UsytuowanieBudynku
          ? this.buildingAboveGroundTranslationKey
          : this.buildingUndergroundTranslationKey
        : null,
      egibObject.StatusBudynku,
      egibObject.BudynekId,
      egibObject.GminaId,
    );
  }
}

export class BuildingDto {
  constructor(
    public Uuid: string,
    public BudynekId: string,
    public Numer: string,
    public Gmina: string,
    public Obreb: string,
  ) {}

  static fromAppToApi(data: EgibObject): BuildingDto {
    return new this(
      data.uuid,
      data.buildingId,
      data.mapObjectNumber,
      data.cadastralUnit,
      data.districtName,
    );
  }
}

export class PremisesDto {
  constructor(
    public LokalId: string,
    public Uuid: string,
    public Numer: string,
    public Gmina: string,
    public Obreb: string,
  ) {}

  static fromAppToApi(data: EgibObject): PremisesDto {
    return new this(
      data.uuid,
      data.uuid,
      data.mapObjectNumber,
      data.cadastralUnit,
      data.districtName,
    );
  }
}

export interface DistrictMapObjectFromApi {
  Uuid: string;
  ObrebId: number;
  Nazwa: string;
  Gus: string;
  GminaId: number;
  Gmina: string;
  Geom: string;
}

export class DistrictMapObject extends BasicMapObject {
  constructor(
    public override type: MapObjectApiType,
    public override typeName: MapObjectTypeNameTranslationPath,
    public districtId: number,
    public mapObjectNumber: string,
    public districtName: string,
    public gus: string,
    public communityId: number,
    public communityName: string,
    public override geom: string,
    public override uuid: string,
  ) {
    super(uuid, type, typeName, geom);
  }

  static fromApiToApp(district: DistrictMapObjectFromApi): DistrictMapObject {
    return new this(
      MapObjectApiType.District,
      MapObjectTypeNameTranslationPath.District,
      district.ObrebId,
      district.ObrebId.toString(),
      district.Nazwa,
      district.Gus,
      district.GminaId,
      district.Gmina,
      district.Geom,
      district.Uuid,
    );
  }
}

export interface LandUseFromApi {
  Id: number;
  Nr: string;
  UzytekId: number;
  Oznaczenie: string;
  Powierzchnia: string;
  Geom: string;
}

export class LandUse extends BasicMapObject {
  constructor(
    public override type: MapObjectApiType,
    public override typeName: MapObjectTypeNameTranslationPath,
    public override uuid: number,
    public mapObjectNumber: string,
    public landUseId: number,
    public designation: string,
    public override area: string,
    public override geom: string,
  ) {
    super(uuid, type, typeName, geom, area);
  }

  static fromApiToApp(landUse: LandUseFromApi): LandUse {
    return new this(
      MapObjectApiType.LandUse,
      MapObjectTypeNameTranslationPath.LandUse,
      landUse.Id,
      landUse.Nr,
      landUse.UzytekId,
      landUse.Oznaczenie,
      getAreaInHectaresFromSquareMeters(landUse.Powierzchnia),
      landUse.Geom,
    );
  }
}

export interface ClassificationContourFromApi {
  Id: number;
  Nr: string;
  KlasaId: number;
  Oznaczenie: string;
  Powierzchnia: string;
  Geom: string;
}

export class ClassificationContour extends BasicMapObject {
  constructor(
    public override type: MapObjectApiType,
    public override typeName: MapObjectTypeNameTranslationPath,
    public override uuid: number,
    public mapObjectNumber: string,
    public classificationId: number,
    public designation: string,
    public override area: string,
    public override geom: string,
  ) {
    super(uuid, type, typeName, geom, area);
  }

  static fromApiToApp(
    classificationContour: ClassificationContourFromApi,
  ): ClassificationContour {
    return new this(
      MapObjectApiType.ClassificationContour,
      MapObjectTypeNameTranslationPath.ClassificationContour,
      classificationContour.Id,
      classificationContour.Nr,
      classificationContour.KlasaId,
      classificationContour.Oznaczenie,
      classificationContour.Powierzchnia,
      classificationContour.Geom,
    );
  }
}
export interface MapObjectInfoFromApi {
  Id: number;
  Type: SubjectType;
  Label: string;
  Wkt: Wkt;
}

export class MapObjectInfo extends BasicMapObject {
  constructor(
    public override uuid: string,
    public override type: SubjectType,
    public override typeName: string,
    public label: string,
    public override geom: Wkt,
    public attributes?: any,
    public override area?: string,
  ) {
    super(uuid, type, typeName, geom);
  }

  static fromApiToApp(
    mapObjectInfoFromApi: MapObjectInfoFromApi,
  ): MapObjectInfo {
    return new this(
      mapObjectInfoFromApi.Id.toString(),
      mapObjectInfoFromApi.Type,
      `GK.MAP.SUBJECT_TYPE.${mapObjectInfoFromApi.Type}`,
      mapObjectInfoFromApi.Label,
      mapObjectInfoFromApi.Wkt,
    );
  }
}

export class AnyGeometryObject extends BasicMapObject {
  constructor(
    public override uuid: string,
    public override geom: string,
    public override type: MapObjectApiType,
    public override typeName: MapObjectTypeNameTranslationPath,
    public override area: string,
    public scaleDenominator?: number,
    public paperName?: string,
    public areaInSquareMeters?: number,
  ) {
    super(uuid, type, typeName, geom, area);
  }
}

export interface GeomObjectControlPoint {
  Geom: string;
  IdUkladuPoziomego: number;
  NazwaUkladuPoziomego: string | null;
  WspolrzedneX: string;
  WspolrzedneY: string;
}

export interface ControlPointFromApi {
  Id: number;
  NrPunktu: string;
  OpisyTopograficzneFileIds: number[];
  StaryNrPunktu: string;
  WspXy: GeomObjectControlPoint[];
}

export class ControlPoint extends BasicMapObject {
  constructor(
    public override uuid: string,
    public override type: MapObjectApiType,
    public override typeName: MapObjectTypeNameTranslationPath,
    public mapObjectNumber: string,
    public number: string,
    public oldNumber: string,
    public topographicDescriptionFileIds: number[],
    public override geom: Wkt | null,
  ) {
    super(uuid, type, typeName, geom);
  }

  static fromApiToApp(controlPointFromApi: ControlPointFromApi): ControlPoint {
    return new this(
      controlPointFromApi.Id.toString(),
      MapObjectApiType.ControlPoint,
      MapObjectTypeNameTranslationPath.ControlPoint,
      controlPointFromApi.NrPunktu || controlPointFromApi.StaryNrPunktu,
      controlPointFromApi.NrPunktu,
      controlPointFromApi.StaryNrPunktu,
      controlPointFromApi.OpisyTopograficzneFileIds,
      controlPointFromApi.WspXy[0]?.Geom,
    );
  }
}

export enum SubjectType {
  None = 'NONE',
  LandParcel = 'DLK',
  Building = 'BUD',
  Premises = 'LOK',
  Person = 'OSOBA',
  District = 'OBREB',
  CadastralUnit = 'JEWID',
  County = 'POWIAT',
  BoundaryPoint = 'PUNKTGRANICZNY',
  ControlPoint = 'PUNKTOSNOWY',
  Report = 'OPERAT',
  MapSheetIdNumber = 'GODLO',
  TransactionAppraisal = 'RC',
  TerritorialRegistryUnit = 'JRG',
  BuildingRegistryUnit = 'JRB',
  PremisesRegistryUnit = 'JRL',
  RealEstateRegister = 'KW',
  ClassificationContour = 'KONTUR',
  LandUse = 'UZYTEK',
  BdotObject = 'OBIEKTBDOT',
  GesutObject = 'OBIEKTGESUT',
}

export enum UtilityInfrastructure {
  NotExisting = 0,
  Existing = 1,
  MayBeConnected = 2,
}

export enum MapObjectApiType {
  LandParcel = 'dzialka',
  ExtentOrPolygon = 'zakres dowolny',
  District = 'obreb',
  LandUse = 'uzytek',
  ClassificationContour = 'kontur',
  ControlPoint = 'punkt osnowy',
  BoundaryPoint = 'punkt graniczny',
  Building = 'budynek',
  Premises = 'lokal',
}

export enum MapObjectTypeNameTranslationPath {
  LandParcel = 'GK.MAP.MAP_OBJECT_TYPES.LAND_PARCEL',
  ExtentOrPolygon = 'GK.MAP.MAP_OBJECT_TYPES.EXTENT_OR_POLYGON',
  District = 'GK.MAP.MAP_OBJECT_TYPES.DISTRICT',
  LandUse = 'GK.MAP.MAP_OBJECT_TYPES.LAND_USE',
  ClassificationContour = 'GK.MAP.MAP_OBJECT_TYPES.CLASSIFICATION_CONTOUR',
  ControlPoint = 'GK.MAP.MAP_OBJECT_TYPES.CONTROL_POINT',
  BoundaryPoint = 'GK.MAP.MAP_OBJECT_TYPES.BOUNDARY_POINT',
  Building = 'GK.MAP.MAP_OBJECT_TYPES.BUILDING',
  Premises = 'GK.MAP.MAP_OBJECT_TYPES.PREMISES',
}

export const egibObjectTypes: any = {
  budynek: MapObjectTypeNameTranslationPath.Building,
  dzialka: MapObjectTypeNameTranslationPath.LandParcel,
  lokal: MapObjectTypeNameTranslationPath.Premises,
  punkt: MapObjectTypeNameTranslationPath.BoundaryPoint,
};

export type Wkt = string;

export enum WktType {
  Point = 'POINT',
  LineString = 'LINESTRING',
  Polygon = 'POLYGON',
  MultiPoint = 'MULTIPOINT',
  MultiLineString = 'MULTILINESTRING',
  MultiPolygon = 'MULTIPOLYGON',
}

export interface MapGeometryCollectionsWithWktType {
  mapGeometryCollection: Array<MapPolygon | MapPoint | MapLineString>;
  wktType: Wkt;
}

export type MapPolygon = Coordinate[];
export type MapPoint = Coordinate;
export type MapLineString = Coordinate[];

export const getAreaInHectaresFromSquareMeters = (
  area: string | number,
): string => (+area / 10000).toFixed(4);

export enum GeometryFormat {
  Wkt = 'WKT',
  Txt = 'TXT',
}

export enum OpenLayersGeometryType {
  Point = 'Point',
  LineString = 'LineString',
  LinearRing = 'LinearRing',
  Polygon = 'Polygon',
  MultiPoint = 'MultiPoint',
  MultiLineString = 'MultiLineString',
  MultiPolygon = 'MultiPolygon',
  GeometryCollection = 'GeometryCollection',
  Circle = 'Circle',
}

export interface MapObjectDynamicAttributesFromApi {
  Editable: boolean;
  Grupa: string;
  Kolejny: number;
  MzAtrybutFormat: null;
  MzAtrybutId: 15;
  MzAtrybutNazwa: string;
  MzAtrybutTyp: string;
  MzSloAtrybutNazwa: string;
  MzSloAtrybutySlo: DictionaryFromApi;
  WarstwaId: string;
  Wartosc: string;
}

export class MapObjectAttributesFromApi {
  constructor(
    public Dzialka: DzialkaRestrictedDto,
    public Budynki: BudynekRestrictedDto[],
    public Klasouzytki: KlasouzytkiZPowierzchniaResponseDto,
    public Wladajacy: WladajacyResponseDto[],
  ) {}
}

export interface DzialkaRestrictedDto {
  Identyfikator: string;
  PowierzchniaGeodezyjna: string;
  PowierzchniaMatematyczna: string;
  InneDokumenty: string;
  Polozenie: string;
  GranicaSporna: boolean;
  TerenZamkniety: boolean;
  ObjetaFormaOchronyPrzyrody?: boolean;
  ObwodSpisowy?: number;
  Wartosc?: number;
  WartoscData?: string;
  RejestrZabytkow: string;
  RejestrZabytkowData?: string;
  RentaPlanistycznaDoDnia?: string;
  RejonStatystyczny: string;
  WylaczenieZProdukcjiRolnej: boolean;
  Uwagi: string;
  UwagiGeometria: string;
  AtrybutyDodatkowe: string;
  RoszczeniaDoDzialki: string;
  PostepowanieAdminLubSadowe: string;
  Wodociag?: UtilityInfrastructure;
  WodaLokalna?: UtilityInfrastructure;
  Siec230V?: UtilityInfrastructure;
  Siec380V?: UtilityInfrastructure;
  SiecCo?: UtilityInfrastructure;
  Gazociag?: UtilityInfrastructure;
  Kanalizacja?: UtilityInfrastructure;
  KanalizacjaLokalna?: UtilityInfrastructure;
  KanalizacjaBurzowa?: UtilityInfrastructure;
  SiecTelefoniczna?: UtilityInfrastructure;
  TvKablowa?: UtilityInfrastructure;
  UserName: string;
  UserMName: string;
  Datad: string;
  Datam: string;
}

export interface BudynekRestrictedDto {
  Identyfikator: string;
  StatusNazwa: string;
  Funkcja2Nazwa: string;
  Uwagi: string;
  UwagiGeometria: string;
  WiekZakonczeniaBudowy?: number;
  StopienPewnosciUstaleniaDatyBudowy: string;
  RokZakonczeniaPrzebudowy?: number;
  WiekZakonczeniaPrzebudowy?: number;
  StopienPewnosciUstaleniaDatyPrzebudowy: string;
  ZakresPrzebudowy: string;
  ScianyZewnetrzne: string;
  PowZabudowyWDokumentach?: number;
  PowUzytkowaZProjektu?: number;
  PowUzytkowaZPomiarow?: number;
  PowUzytkowaLokali?: number;
  PowUzytkowaPomieszczenPrzynaleznych?: number;
  PowUzytkowaSuma?: number;
  LokaleOdrebne?: number;
  LokaleInne?: number;
  NumeryLokaleOdrebne: string;
  NumeryLokaleInne: string;
  StanUzytkowania: string;
  DataOddania?: string;
  DataRozbiorki?: string;
  PrzyczynaRozbiorki: string;
  LiczbaMieszkan: string;
  LiczbaWszystkichIzb?: number;
  Wartosc?: number;
  WartoscData?: string;
  RejestrZabytkow: string;
  RejestrZabytkowData?: string;
  CzyWiata?: boolean;
  CieplaWoda: string;
  Wodociag?: UtilityInfrastructure;
  WodaLokalna?: UtilityInfrastructure;
  Siec230V?: UtilityInfrastructure;
  Siec380V?: UtilityInfrastructure;
  SiecCo?: UtilityInfrastructure;
  SiecCoLokalna?: UtilityInfrastructure;
  Gazociag?: UtilityInfrastructure;
  Kanalizacja?: UtilityInfrastructure;
  KanalizacjaLokalna?: UtilityInfrastructure;
  KanalizacjaBurzowa?: UtilityInfrastructure;
  SiecTelefoniczna?: UtilityInfrastructure;
  TvKablowa?: UtilityInfrastructure;
  SiecSzerokopasmowa?: UtilityInfrastructure;
  GotowyNaSzybkiInternet?: boolean;
  Winda?: boolean;
  UserName: string;
  UserMName: string;
  Datad: string;
  Datam: string;
}

export interface KlasouzytkiZPowierzchniaResponseDto {
  Klasouzytki: KlasouzytekResponseDto[];
  Powierzchnia: string;
}

export interface KlasouzytekResponseDto {
  UzytekNazwa: string;
  KlasaNazwa: string;
  Powierzchnia: string;
  KdzialkaArowa?: boolean;
}

export interface WladajacyResponseDto {
  Id: number;
  FormaWladaniaNazwa: string;
  FormaWladaniaSkrot: string;
  GrupaRejestrowaNazwa: string;
  Udzial: string;
  DataNabycia?: string;
  DataUplywuWladania?: string;
  Uwagi: string;
  DataZmianyTworzacej: string;
  DataZmianyKasujacej?: string;
  OsobaId: number;
  OsobaNazwa: string;
  Adres: string;
  AdresDoKorespondencji: string;
}
