import { DBObjectModel } from '../models';

export interface PlaceFromApi extends DBObjectModel {
  IsLocal: boolean;
  HasNullStreet?: boolean;
  TerytSymbol?: string;
  GminaNazwa?: string;
  KrajNazwa?: string;
}

export class Place {
  constructor(
    public id: string | number,
    public name: string,
    public isLocal: boolean,
    public hasNullStreet?: boolean
  ) {}

  static fromApiToApp(placeFromApi: PlaceFromApi): Place {
    return new this(
      placeFromApi.Id,
      placeFromApi.Nazwa,
      placeFromApi.IsLocal,
      placeFromApi.HasNullStreet
    );
  }
}
