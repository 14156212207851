import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, map, of, shareReplay } from 'rxjs';
import { FilterOperatorType } from '../../../gk-dynamic-list/gk-dynamic-list.model';
import { ApiFilter } from '../../../gk-dynamic-list/services/services.model';
import { PetentData } from '../../../services/pz/pz.model';
import {
  ApiListResponse,
  FilterLogic,
  FilterWrapper,
  RequestBodyForList,
} from '../../../services/services.model';
import { BasicResponse } from '../../../utils/basic-response/basic-response.model';
import { PersonType } from '../person-type/person-type.model';
import { PersonTypeService } from '../person-type/person-type.service';
import {
  LawPersonSearch,
  LawPersonSearchFromApi,
  LegalPersonSearch,
  NaturalPersonSearch,
} from './law-person-search.model';
import {
  LawPerson,
  LawPersonType,
  LegalPersonData,
  NaturalPersonData,
  NewDesignerRequestDto,
  NewLawPersonRequestDto,
  NewLegalPersonRequestDto,
  NewNaturalPersonRequestDto,
} from './law-person.model';

@Injectable()
export class LawPersonService {
  selectedPersonValueChange = new Subject();
  addingLegalPersonCache: {
    [regonOrNip: string]: Observable<LegalPersonSearch>;
  } = {};

  constructor(
    private httpClient: HttpClient,
    private personTypeService: PersonTypeService,
  ) {}

  private searchLawPerson(
    postBody: RequestBodyForList,
    url = '/api/system/petenci/search',
  ): Observable<LawPersonSearchFromApi[]> {
    return this.httpClient
      .post<ApiListResponse<LawPersonSearchFromApi>>(url, postBody)
      .pipe(map((data) => data.Response));
  }

  searchNaturalPerson(
    naturalPersonData: NaturalPersonData,
    forDesigner = false,
  ): Observable<NaturalPersonSearch[]> {
    const filters = forDesigner
      ? this.prepareFiltersForDesignerPersonTypeSearch(naturalPersonData)
      : this.prepareFiltersForNaturalPersonSearch(naturalPersonData);
    const postBody = new RequestBodyForList(
      new FilterWrapper(FilterLogic.And, filters),
    );

    return this.searchLawPerson(
      postBody,
      forDesigner ? '/api/system/uprawnieni/search' : undefined,
    ).pipe(
      map((response) =>
        response.map((element) => ({
          ...NaturalPersonSearch.fromApiToApp(element),
          availablePersonTypes: forDesigner
            ? [this.personTypeService.getDesignerPersonType()]
            : undefined,
        })),
      ),
    );
  }

  private prepareFiltersForDesignerPersonTypeSearch(
    naturalPersonSearchFormValue: NaturalPersonData,
  ): ApiFilter[] {
    const { firstName, lastName, permissionNumber } =
      naturalPersonSearchFormValue;
    const filterArray: ApiFilter[] = [];

    filterArray.push(new ApiFilter('Imie', firstName));
    filterArray.push(new ApiFilter('Nazwisko', lastName));
    filterArray.push(new ApiFilter('NrInUpr', permissionNumber));
    filterArray.push(new ApiFilter('RodzInUpr', 1));

    return filterArray;
  }

  private prepareFiltersForNaturalPersonSearch(
    naturalPersonSearchFormValue: NaturalPersonData,
  ): ApiFilter[] {
    const { firstName, lastName, pesel } = naturalPersonSearchFormValue;
    const filterArray: ApiFilter[] = [];

    filterArray.push(new ApiFilter('Typ', LawPersonType.Natural));
    filterArray.push(new ApiFilter('Nazwa', `${lastName} ${firstName}`));
    if (pesel) {
      filterArray.push(new ApiFilter('Pesel', pesel));
    }

    return filterArray;
  }

  searchLegalPerson(
    legalPersonData: LegalPersonData,
    customUrl?: string,
  ): Observable<LegalPersonSearch[]> {
    const filters = this.prepareFiltersForLegalPersonSearch(legalPersonData);
    const postBody = new RequestBodyForList(
      new FilterWrapper(FilterLogic.And, filters),
    );

    return this.searchLawPerson(postBody, customUrl).pipe(
      map((response) =>
        response.map((element) => LegalPersonSearch.fromApiToApp(element)),
      ),
    );
  }

  private prepareFiltersForLegalPersonSearch(
    legalPersonSearchFormValue: LegalPersonData,
  ): ApiFilter[] {
    const { name, regon, nip, krsNumber } = legalPersonSearchFormValue;
    let filterArray: ApiFilter[] = [];

    filterArray = [...filterArray, new ApiFilter('Typ', LawPersonType.Legal)];
    if (regon) {
      filterArray = [...filterArray, new ApiFilter('Regon', regon)];
    }
    if (nip) {
      filterArray = [...filterArray, new ApiFilter('Nip', nip)];
    }
    if (name) {
      filterArray = [
        ...filterArray,
        new ApiFilter('Nazwa', name, FilterOperatorType.Contains),
      ];
    }
    if (krsNumber) {
      filterArray = [...filterArray, new ApiFilter('Krs', krsNumber)];
    }

    return filterArray;
  }

  addLegalPerson(
    legalPersonData: LawPerson,
    addingPersonsDirectlyToDb = true,
    url?: string,
    cache = false,
  ): Observable<LegalPersonSearch> {
    const body = NewLegalPersonRequestDto.fromAppToApi(legalPersonData);

    return addingPersonsDirectlyToDb
      ? cache && legalPersonData.legalPersonData
        ? this.getLegalPersonFromCache(legalPersonData, url, cache)
        : this.addLawPersonAndAddToCacheIfnecessary(legalPersonData, url, cache)
      : of(
          LegalPersonSearch.fromFormAfterAdding(
            undefined,
            legalPersonData,
            body,
          ),
        );
  }

  getLegalPersonFromCache(
    legalPersonData: LawPerson,
    url?: string,
    cache?: boolean,
  ): Observable<LegalPersonSearch> {
    return (
      this.addingLegalPersonCache[
        legalPersonData.legalPersonData.regon ||
          legalPersonData.legalPersonData.nip
      ] ||
      this.addLawPersonAndAddToCacheIfnecessary(legalPersonData, url, cache)
    );
  }

  addLawPersonAndAddToCacheIfnecessary(
    legalPersonData: LawPerson,
    url?: string,
    cache?: boolean,
  ): Observable<LegalPersonSearch> {
    const body = NewLegalPersonRequestDto.fromAppToApi(legalPersonData);
    const addLawPersonObservable = this.addLawPerson(body, url).pipe(
      map((id) => LegalPersonSearch.fromFormAfterAdding(id, legalPersonData)),
      shareReplay(1),
    );
    if (cache) {
      this.emitLegalPersonCache(
        legalPersonData.legalPersonData.regon ||
          legalPersonData.legalPersonData.nip,
        addLawPersonObservable,
      );
    }

    return addLawPersonObservable;
  }

  emitLegalPersonCache(
    regonOrNip: string,
    legalPerson: Observable<LegalPersonSearch>,
  ): void {
    this.addingLegalPersonCache = {
      ...this.addingLegalPersonCache,
      [regonOrNip]: legalPerson,
    };
  }

  addNaturalPerson(
    naturalPersonData: LawPerson,
    forDesigner = false,
    addingPersonsDirectlyToDb = true,
  ): Observable<NaturalPersonSearch> {
    const body = NewNaturalPersonRequestDto.fromAppToApi(
      naturalPersonData,
      forDesigner,
    );
    const availablePersonTypes = forDesigner
      ? [this.personTypeService.getDesignerPersonType()]
      : undefined;

    return addingPersonsDirectlyToDb
      ? this.addLawPerson(
          body,
          forDesigner ? '/api/system/uprawnieni' : undefined,
        ).pipe(
          map((id) => ({
            ...NaturalPersonSearch.fromFormAfterAdding(id, naturalPersonData),
            availablePersonTypes,
          })),
        )
      : of({
          ...NaturalPersonSearch.fromFormAfterAdding(
            undefined,
            naturalPersonData,
            body,
          ),
          availablePersonTypes,
        });
  }

  private addLawPerson(
    postBody: NewLawPersonRequestDto | NewDesignerRequestDto,
    url = '/api/system/petenci',
  ): Observable<number> {
    return this.httpClient
      .post<BasicResponse<number>>(url, postBody)
      .pipe(map((data) => data.Result));
  }

  getLawPersonSearchFromPetentData(
    petentData: PetentData,
    personType?: PersonType,
  ): LawPersonSearch {
    return petentData.isLegalPerson
      ? new LegalPersonSearch(
          `${petentData.id}`,
          petentData.address,
          petentData.name,
          petentData.regon,
          petentData.nip,
          LawPersonType.Legal,
          personType,
        )
      : new NaturalPersonSearch(
          `${petentData.id}`,
          petentData.address,
          `${petentData.lastname} ${petentData.firstname}`,
          petentData.pesel,
          LawPersonType.Natural,
          personType,
        );
  }

  private getProperLawPersonSearch(url: string): Observable<LawPersonSearch> {
    return this.httpClient.get<LawPersonSearchFromApi>(url).pipe(
      map((data) => {
        if (!data || (!data.Nazwa && !data.Nazwisko)) {
          return null;
        }
        if (data.Regon) {
          return LegalPersonSearch.fromApiToApp(data);
        } else {
          return NaturalPersonSearch.fromApiToApp(data);
        }
      }),
    );
  }

  getPayerFromLastRequest(): Observable<LawPersonSearch> {
    return this.getProperLawPersonSearch(
      '/api/zudp/projektant/sprawy/platnik/ostatni',
    );
  }

  getLastPrzpApplicant(): Observable<LawPersonSearch> {
    return this.getProperLawPersonSearch(
      '/api/interesant/przp/wnioskodawca/ostatni',
    );
  }

  getLastPrzpPrincipal(): Observable<LawPersonSearch> {
    return this.getProperLawPersonSearch(
      '/api/interesant/przp/mocodawca/ostatni',
    );
  }
}
