@if (mapState.viewState.currentNativeExtent) {
  <div #mapContainer class="mapContainer">
    <gk-map-toolbar
      [mapState]="mapState"
      (dispatch)="dispatch.emit($event)"
    ></gk-map-toolbar>
    <gk-map-view
      [mapState]="mapState"
      (dispatch)="dispatch.emit($event)"
      [mapFullScreenHTMLDivElement]="mapContainer"
    ></gk-map-view>
  </div>
} @else {
  <div class="text-center">
    {{ 'GK.MAP.LOADING_SETTINGS' | translate }}
    @if (!mapState.viewState.currentNativeExtent) {
      <div class="d-flex justify-content-center my-3">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
        </div>
      </div>
    }
  </div>
}
