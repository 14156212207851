import { AbstractControl, ValidationErrors } from '@angular/forms';

const isPostalCodeValid = (maybePostalCode: string): boolean => {
  const regExp = new RegExp(/^[0-9]{2}-[0-9]{3}$/g);
  return regExp.test(maybePostalCode);
};

export function ValidatePostalCode(control: AbstractControl): ValidationErrors | null {
  return !control.value || isPostalCodeValid(control.value)
    ? null
    : { postalCodePattern: true };
}
