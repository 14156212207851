import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlSegment } from '@angular/router';
import { PortalsPermissionsService } from '@gk/gk-modules';
import * as _ from 'lodash';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { SessionService } from '../../services/session/session.service';
import { PortalSettingsAccess } from '../../services/web-portal/web-portal.model';
import { WebPortalService } from '../../services/web-portal/web-portal.service';
import { MainRoutes } from '../guard.models';

@Injectable()
export class PortalAccessGuard {
  constructor(
    private router: Router,
    private webPortalService: WebPortalService,
    private portalsPermissionsService: PortalsPermissionsService,
    private sessionService: SessionService
  ) {}

  checkAccess(
    url: UrlSegment[],
    portalSettingsAccess: PortalSettingsAccess
  ): Observable<boolean> {
    const path = _.get(url, '[0].path', '') as MainRoutes;
    return this.portalsPermissionsService.getPermissions().pipe(
      map((portalsPermissions) => {
        if (
          path === MainRoutes.Designer &&
          portalSettingsAccess.designerAccess &&
          portalsPermissions.designerPortal
        ) {
          return true;
        }
        if (path === MainRoutes.Przp && portalSettingsAccess.przpAccess) {
          return true;
        }

        this.navigateToNoAccess();
        return false;
      })
    );
  }

  navigateToNoAccess(): void {
    this.router.navigateByUrl(`/${MainRoutes.NoAccess}`);
  }

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.sessionService.getSystemSession().pipe(
      switchMap((session) =>
        session.isAuthenticated()
          ? this.webPortalService.getPortalSettingsAccess()
          : of(undefined)
      ),
      switchMap((webPortal) =>
        webPortal ? this.checkAccess(route.url, webPortal) : of(undefined)
      ),
      catchError(() => {
        this.navigateToNoAccess();
        return of(false);
      })
    );
  }
}
