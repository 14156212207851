import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { interval } from 'rxjs';
import { filter, switchMap, take, takeWhile } from 'rxjs/operators';
import { Cautions } from '../../services/cautions/cautions.model';
import { CautionsService } from '../../services/cautions/cautions.service';
import { BaseStyleClassesDirective } from '../base-style-classes/base-style-classes.directive';
import { ACControlName } from './accept-cautions/models/form/form.model';
@Component({
  selector: 'app-cautions',
  templateUrl: './cautions.component.html',
  styleUrls: ['./cautions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CautionsComponent
  extends BaseStyleClassesDirective
  implements OnInit, OnDestroy
{
  @Input() portalId: number;
  @Input() formGroup: FormGroup;
  @Input() submitted: boolean;
  private isAlive = true;
  cautions: Cautions;
  cautionTranslations: { [key: string]: any };

  constructor(
    public cautionsService: CautionsService,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    this.fetchCautions();
    this.fetchCautionsTranslations();
  }

  fetchCautionsTranslations(): void {
    this.translateService
      .get('CAUTIONS')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((text) => {
        this.cautionTranslations = text;
        this.changeDetectorRef.markForCheck();
      });
  }

  fetchCautions(): void {
    interval(500)
      .pipe(
        takeWhile(() => this.isAlive),
        filter(() => !!this.portalId),
        take(1),
        switchMap(() =>
          this.cautionsService.getCautionsForPortal(this.portalId),
        ),
      )
      .subscribe((cautions) => {
        this.cautions = cautions;
        this.addRodoControlIfAnyCautionExists();
        this.changeDetectorRef.markForCheck();
      });
  }

  addRodoControlIfAnyCautionExists(): void {
    if (this.cautions.isNotEmpty()) {
      this.formGroup.addControl(
        ACControlName.RodoStatement,
        this.formBuilder.control(undefined, [Validators.requiredTrue]),
      );
    }
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
