import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { DefaultConfig, DefaultConfigFromApi } from './default-config.model';

@Injectable()
export class DefaultConfigService {
  private defaultConfig: Observable<DefaultConfig>;
  constructor(private httpClient: HttpClient) {}

  getConfig(): Observable<DefaultConfig> {
    if (!this.defaultConfig) {
      this.defaultConfig = this.httpClient
        .get<DefaultConfigFromApi>('/api/system/web/default/konfig')
        .pipe(
          map((data) => DefaultConfig.fromApiToApp(data)),
          shareReplay(1)
        );
    }

    return this.defaultConfig;
  }
}
