import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { AreaControlConfig, AreaUnit } from '../../../gk-dynamic-list.model';
import { DataService } from '../../../services/data/data.service';
import { Control } from '../control';

@Component({
  selector: 'gk-control-area',
  templateUrl: './control-area.component.html',
})
export class ControlAreaComponent extends Control<string> implements OnInit {
  @Input()
  override controlConfig: AreaControlConfig;

  constructor(
    protected override translateService: TranslateService,
    protected override dataService: DataService,
    private sanitizer: DomSanitizer
  ) {
    super(translateService, dataService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.getFormattedTemplate();
  }

  getFormattedTemplate(): SafeHtml {
    if (this.controlConfig.unit) {
      return this.sanitizer.bypassSecurityTrustHtml(this.getValueByUnit());
    } else if (this.controlConfig.areaDefinedInAres) {
      return this.sanitizer.bypassSecurityTrustHtml(
        this.getValueByDefinitionInAres()
      );
    }

    return undefined;
  }

  getValueByUnit(): string {
    const value = this.getValue();
    if (this.isNaNValue()) {
      return value;
    }
    switch (this.controlConfig.unit) {
      case AreaUnit.SquareMeter:
        return `${this.getFixedAreaValue(
          this.controlConfig.decimalDigits
        )} m${'2'.sup()}`;
      case AreaUnit.Hectare:
        return `${this.getFixedAreaValue(this.controlConfig.decimalDigits)} ha`;
      default:
        return `${value}`;
    }
  }

  getValueByDefinitionInAres(): string {
    if (this.isNaNValue()) {
      return this.getValue();
    }
    return _.get(this.data, this.controlConfig.areaDefinedInAres)
      ? `${this.getFixedAreaValue(2)} ha`
      : `${this.getFixedAreaValue(4)} ha`;
  }

  isNaNValue(): boolean {
    const value = this.getValue();
    const numberValue = Number(value);

    return Number.isNaN(numberValue);
  }

  getFixedAreaValue(decimalDigits: number): string {
    return Number(this.getValue()).toFixed(decimalDigits);
  }
}
