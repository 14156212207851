export type DocKeyId = string;

export interface AddedDocToSignResponseFromApi {
  DocKeyId: DocKeyId;
  DocSigningUrl: string;
}

export class AddedDocToSignResponse {
  constructor(
    public docKeyId: DocKeyId,
    public docSigningUrl: string,
    public directlyAddedRequest = false
  ) {}

  static fromApiToApp(
    addedDocToSignResponseFromApi: AddedDocToSignResponseFromApi
  ): AddedDocToSignResponse {
    return new this(
      addedDocToSignResponseFromApi.DocKeyId,
      addedDocToSignResponseFromApi.DocSigningUrl
    );
  }

  static directlyAddedRequest(): AddedDocToSignResponse {
    return new this(undefined, undefined, true);
  }
}

export interface SignedOrderFromApi {
  ZamowId: number;
  ZamowNr: string;
  ZamowUuid: string;
  WniosekPUuid: null | string;
}

export class SignedOrder {
  constructor(
    public orderId: number,
    public orderNumber: string,
    public orderUuid: string,
    public requestPUuid: null | string
  ) {}

  static fromApiToApp(signedOrderFromApi: SignedOrderFromApi): SignedOrder {
    return new this(
      signedOrderFromApi.ZamowId,
      signedOrderFromApi.ZamowNr,
      signedOrderFromApi.ZamowUuid,
      signedOrderFromApi.WniosekPUuid
    );
  }
}

export enum DocSignStatus {
  Pending = 0,
  Success = 1,
  Failure = 2,
  Canceled = 3,
}

export interface ApiPlacedOrder {
  Oplata: ApiPayment | null;
  Zamowienie: SignedOrderFromApi;
}

export interface ApiPayment {
  FaktUuid: string;
  FaktNr: string;
  OplataNr: string;
  OplataKwota: number;
  OplataKwotaFormatted: string;
  OplataDokwUuid: string;
  OplataXadesDokwUuid: string;
  DataWystawieniaOplaty?: string;
  StatusPlatnosci?: string;
  StatusPlatnosciPl?: string;
  StatusPlatnosciEn?: string;
  TransId?: string | number;
}

export interface DocSignFromApi {
  Status: DocSignStatus;
  FailureMessage: string | null;
  Order: ApiPlacedOrder | null;
}

export class DocSign {
  constructor(
    public status: DocSignStatus,
    public failureMessage?: string,
    public signedOrder?: SignedOrder
  ) {}

  static fromApiToApp(docSignFromApi: DocSignFromApi): DocSign {
    return new this(
      docSignFromApi.Status,
      docSignFromApi.FailureMessage,
      docSignFromApi.Order
        ? SignedOrder.fromApiToApp(docSignFromApi.Order.Zamowienie)
        : undefined
    );
  }
}
