import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WindowRefService } from '../../services/window-ref/window-ref.service';

@Component({
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss'],
})
export class PaymentSuccessComponent {
  constructor(
    private windowRefService: WindowRefService,
    private route: ActivatedRoute
  ) {}

  handleRedirectTrigger(): void {
    this.route.queryParams.subscribe((params) => {
      const queryParamReturnUrl = params['returnUrl'];

      if (queryParamReturnUrl) {
        this.windowRefService.nativeWindow.location.href =
          queryParamReturnUrl.split('?')[0];
      }
    });
  }
}
