@if (!naturalPersonOnly) {
  <div class="col-12">
    <gk-law-person-type-form
      [form]="lawPersonFormGroup"
    ></gk-law-person-type-form>
  </div>
}

@if (isNaturalPersonChosen()) {
  <gk-natural-person-simple-form
    [form]="getNaturalPersonFormGroup()"
    [submitted]="submitted"
  ></gk-natural-person-simple-form>
}

@if (isLegalPersonChosen()) {
  <gk-legal-person-simple-form
    [form]="getLegalPersonFormGroup()"
  ></gk-legal-person-simple-form>
}

@if (getAddressFormGroup()) {
  <gk-address-form
    [form]="getAddressFormGroup()"
    [isRequired]="true"
  ></gk-address-form>
}

<gk-post-office-modal [addressFormGroup]="getAddressFormGroup()">
</gk-post-office-modal>
