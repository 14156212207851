<aol-control>
  <aol-content>
    <div class="ol-unselectable ol-control help-button">
      <button
        type="button"
        class="fa fa-question"
        (click)="changeToggle()"
        title="{{ 'GK.MAP.HELP_CONTROL.HELP' | translate }}"
        [attr.aria-label]="'GK.MAP.HELP_CONTROL.HELP' | translate"
      ></button>
    </div>
  </aol-content>
</aol-control>

@if (toggle) {
  <div class="help-view bg-white">
    <h3 class="title px-2 pt-2">{{ 'GK.MAP.HELP_CONTROL.HELP' | translate }}</h3>
    <div class="content px-2">
      <p>{{ 'GK.MAP.HELP_CONTROL.NAVIGATION' | translate }}</p>
      <p>{{ 'GK.MAP.HELP_CONTROL.ZOOM_BUTTONS' | translate }}</p>
      <p>{{ 'GK.MAP.HELP_CONTROL.OTHER_ZOOM_METHODS' | translate }}</p>
      <ul>
        <li>{{ 'GK.MAP.HELP_CONTROL.DOUBLE_CLICK' | translate }}</li>
        <li>{{ 'GK.MAP.HELP_CONTROL.DRAW_RECTANGLE' | translate }}</li>
      </ul>
      <p>{{ 'GK.MAP.HELP_CONTROL.FULL_EXTENT' | translate }}</p>
      <p>{{ 'GK.MAP.HELP_CONTROL.OVERVIEW_MAP' | translate }}</p>
      <p>{{ 'GK.MAP.HELP_CONTROL.CURSOR_COORDINATES' | translate }}</p>
    </div>
  </div>
}
