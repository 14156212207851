import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class DomRefService {
  constructor(@Inject(DOCUMENT) private dom: Document) {}

  get nativeDom(): Document {
    return this.dom;
  }

  getLocationHref(): string {
    return _.get(this.nativeDom, 'location.href', '');
  }
}
