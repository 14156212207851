import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ACControlName } from './models/form/form.model';

@Component({
  selector: 'app-accept-cautions',
  templateUrl: './accept-cautions.component.html',
  styleUrls: ['./accept-cautions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcceptCautionsComponent {
  controlName = ACControlName;
  @Input() formGroup: FormGroup;
  @Input() submitted: boolean;
  @Input() rodoText: string;

  isRodoStatementFormControlValid(): boolean {
    return this.getRodoStatementFormControl().valid;
  }

  getRodoStatementFormControl(): AbstractControl {
    return this.formGroup.get(this.controlName.RodoStatement);
  }
}
