import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { createTranslateLoader } from '../address-number-request-portal/address-number-request-portal.module';
import { GrantingRightsPortalRoutingModule } from './granting-rights-portal-routing.module';
import { GrantingRightsPortalComponent } from './granting-rights-portal.component';
import { NewGrantComponent } from './new-grant/new-grant.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    GrantingRightsPortalRoutingModule,
  ],
  declarations: [GrantingRightsPortalComponent, NewGrantComponent],
})
export class GrantingRightsPortalModule {}
