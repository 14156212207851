import * as _ from 'lodash';
import { Place } from '../../../gk-dynamic-form/services/place/place.model';
import { GkPostalCode } from '../../../gk-dynamic-form/services/postal-code/postal-code.model';
import { Street } from '../../../gk-dynamic-form/services/street/street.model';
import { PetentForLoggedUserData } from '../../../services';
import { PersonType } from '../person-type/person-type.model';

export enum ApplicantType {
  FromLoggedInUser,
  FromLoggedInUserOrFromRegonDb,
  FromRegonDb,
}

export enum LawPersonType {
  Natural = 'F',
  Legal = 'P',
}
export const maxLengthValueOfNaturalPersonFormGroup = 1000;

type AddressFields = Place | Street | GkPostalCode;

export class NaturalPersonData {
  constructor(
    public firstName: string,
    public lastName: string,
    public pesel?: string,
    public permissionNumber?: string,
    public phoneNumber?: number,
    public email?: string,
  ) {}

  static getInitialStruct(): NaturalPersonData {
    return new this(undefined, undefined);
  }
}

export class LegalPersonData {
  constructor(
    public name: string,
    public nip: string,
    public regon: string,
    public krsNumber: string,
    public typeId: number | string,
  ) {}

  static getInitialStruct(): LegalPersonData {
    return new this(undefined, undefined, undefined, undefined, undefined);
  }
}

export class LawPersonAddress {
  constructor(
    public place: Place,
    public street: Street,
    public buildingNumber: string,
    public localNumber: string,
    public postalCode: GkPostalCode,
    public postOffice: string,
  ) {}

  static getInitialStruct(): LawPersonAddress {
    return new this(
      {} as Place,
      {} as Street,
      undefined,
      undefined,
      {} as GkPostalCode,
      undefined,
    );
  }

  static fromPetentData(data: PetentForLoggedUserData): LawPersonAddress {
    return new this(
      new Place(data.placeId, data.place, undefined),
      new Street(data.streetId, data.street),
      data.buildingNumber,
      data.localNumber,
      new GkPostalCode(data.postalCodeId, data.postalCode),
      undefined,
    );
  }
}

export class LawPerson {
  constructor(
    public legalPersonData: LegalPersonData,
    public naturalPersonData: NaturalPersonData,
    public address: LawPersonAddress,
    public type: LawPersonType,
    public personType?: PersonType,
  ) {}

  static getInitialStruct(): LawPerson {
    return new this(
      LegalPersonData.getInitialStruct(),
      NaturalPersonData.getInitialStruct(),
      LawPersonAddress.getInitialStruct(),
      LawPersonType.Legal,
    );
  }
}

export const getValueWhenPropertyIsAddressFields = (
  property: AddressFields | string,
): string | undefined =>
  property && !_.isString(property) && property.id
    ? `${property.id}`
    : undefined;

export const getValueWhenPropertyIsString = (
  property: AddressFields | string,
): string | undefined =>
  property && _.isString(property)
    ? property
    : property && !_.isString(property)
      ? property.name
      : undefined;

export class AddressRequestDto {
  constructor(
    public MiejscId: string | number,
    public Miejsc: string,
    public UlicaId: string | number,
    public Ulica: string,
    public KodPocztId: string | number,
    public KodPoczt: string,
    public Poczta: string,
    public NumerBud: string,
    public NumerLok: string,
  ) {}

  static fromApiToApp(data: LawPersonAddress): AddressRequestDto {
    return new this(
      getValueWhenPropertyIsAddressFields(data.place),
      getValueWhenPropertyIsString(data.place),
      getValueWhenPropertyIsAddressFields(data.street),
      getValueWhenPropertyIsString(data.street),
      getValueWhenPropertyIsAddressFields(data.postalCode),
      getValueWhenPropertyIsString(data.postalCode),
      data.postOffice,
      data.buildingNumber,
      data.localNumber,
    );
  }
}

export class NewDesignerRequestDto {
  constructor(
    public Nazwisko: string,
    public Imie: string,
    public Adres: AddressRequestDto,
    public RodzInUpr: number,
    public NrInUpr: string,
  ) {}

  static fromApiToApp(data: LawPerson): NewDesignerRequestDto {
    const naturalPersonData = data.naturalPersonData;
    const address = data.address;

    return new this(
      naturalPersonData.lastName,
      naturalPersonData.firstName,
      AddressRequestDto.fromApiToApp(address),
      1,
      naturalPersonData.permissionNumber,
    );
  }
}

export class NewNaturalPersonRequestDto {
  constructor(
    public Nazwisko: string,
    public Imie: string,
    public Pesel: string,
    public MiejscId: string | number,
    public Miejsc: string,
    public UlicaId: string | number,
    public Ulica: string,
    public KodId: string | number,
    public Kod: string,
    public Poczta: string,
    public Numer: string,
    public Numer2: string,
    public Telefon?: number,
    public Email?: string,
    public Typ: string = LawPersonType.Natural,
  ) {}

  static fromAppToApi<T extends boolean = false>(
    data: LawPerson,
    forDesigner?: T,
  ): T extends true ? NewDesignerRequestDto : NewNaturalPersonRequestDto;
  static fromAppToApi(
    data: LawPerson,
    forDesigner = false,
  ): NewNaturalPersonOrDesignerRequestDto {
    const naturalPersonData = data.naturalPersonData;
    const address = data.address;

    return forDesigner
      ? NewDesignerRequestDto.fromApiToApp(data)
      : new this(
          naturalPersonData.lastName,
          naturalPersonData.firstName,
          naturalPersonData.pesel,
          getValueWhenPropertyIsAddressFields(address?.place),
          getValueWhenPropertyIsString(address?.place),
          getValueWhenPropertyIsAddressFields(address?.street),
          getValueWhenPropertyIsString(address?.street),
          getValueWhenPropertyIsAddressFields(address?.postalCode),
          getValueWhenPropertyIsString(address?.postalCode),
          address?.postOffice,
          address?.buildingNumber,
          address?.localNumber,
          naturalPersonData.phoneNumber,
          naturalPersonData.email,
        );
  }
}

export enum NewLegalPersonApplicantType {
  PrzedsiebiorstwoLubJednostkaOrganizacyjna = 1,
  BieglySadowy = 2,
  MierniczyGorniczy = 3,
  PodmiotZagraniczny = 4,
}

export class NewLegalPersonRequestDto {
  constructor(
    public Nazwa: string,
    public Nip: string,
    public Regon: string,
    public RodzPet: string,
    public MiejscId: string | number,
    public Miejsc: string,
    public UlicaId: string | number,
    public Ulica: string,
    public KodId: string | number,
    public Kod: string,
    public Poczta: string,
    public Numer: string,
    public Numer2: string,
    public Typ: string = LawPersonType.Legal,
    public OsobaId?: string | number,
  ) {}

  static fromAppToApi(
    data: LawPerson,
    personId?: string | number,
  ): NewLegalPersonRequestDto {
    const legalPersonData = data.legalPersonData;
    const address = data.address;

    return new this(
      legalPersonData.name,
      legalPersonData.nip,
      legalPersonData.regon,
      `${legalPersonData.typeId}`,
      getValueWhenPropertyIsAddressFields(address.place),
      getValueWhenPropertyIsString(address.place),
      getValueWhenPropertyIsAddressFields(address.street),
      getValueWhenPropertyIsString(address.street),
      getValueWhenPropertyIsAddressFields(address.postalCode),
      getValueWhenPropertyIsString(address.postalCode),
      address.postOffice,
      address.buildingNumber,
      address.localNumber,
      undefined,
      personId || null,
    );
  }
}

export type NewLawPersonRequestDto =
  | NewNaturalPersonRequestDto
  | NewLegalPersonRequestDto;

export type NewNaturalPersonOrDesignerRequestDto =
  | NewNaturalPersonRequestDto
  | NewDesignerRequestDto;
