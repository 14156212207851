import { Paper, PaperOrientation } from '../models';

export const defaultPapers: Paper[] = [
  new Paper(PaperOrientation.Horizontal, 'A4', [159, 288]),
  new Paper(PaperOrientation.Horizontal, 'A3', [246, 407]),
  new Paper(PaperOrientation.Horizontal, 'A2', [381.78, 553.745]),
  new Paper(PaperOrientation.Horizontal, 'A1', [594, 841]),
  new Paper(PaperOrientation.Horizontal, 'A0', [841, 1189]),
  new Paper(PaperOrientation.Vertical, 'A4', [247, 199]),
  new Paper(PaperOrientation.Vertical, 'A3', [368, 287]),
  new Paper(PaperOrientation.Vertical, 'A2', [555.45, 382.356]),
  new Paper(PaperOrientation.Vertical, 'A1', [841, 594]),
  new Paper(PaperOrientation.Vertical, 'A0', [1189, 841]),
];
