import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { catchError, map, takeWhile } from 'rxjs/operators';
import { handleApiError } from '../services.utils';
import {
  PetitionerSharingPurpose,
  PetitionerSharingPurposeFromApi,
  SharingPurpose,
  SharingPurposeFromApi,
} from './sharing-purpose.model';

@Injectable()
export class SharingPurposeService implements OnDestroy {
  private isAlive = true;
  sharingPurposes = new BehaviorSubject<SharingPurpose[]>(
    [] as SharingPurpose[]
  );
  petitionerSharingPurposes = new BehaviorSubject<PetitionerSharingPurpose[]>(
    [] as PetitionerSharingPurpose[]
  );

  constructor(private httpClient: HttpClient) {
    this.fetchSharingPurposeList();
    this.fetchAllPetitionerSharingPurposes();
  }

  fetchSharingPurposeList(): void {
    this.httpClient
      .get<SharingPurposeFromApi[]>('/api/interesant/przp/formparams')
      .pipe(
        takeWhile(() => this.isAlive),
        map((sps) => {
          return sps.filter(
            (sp: SharingPurposeFromApi) =>
              sp.CelId ===
              'CelUdostMaterialyElektroniczneDoRealizacjaZadaniaPublicznego'
          );
        }),
        map((data: SharingPurposeFromApi[]) =>
          SharingPurpose.sharingPurposeFromApiToSharingPurpose(data)
        ),
        catchError(handleApiError)
      )
      .subscribe((data: SharingPurpose[]) => this.sharingPurposes.next(data));
  }

  private fetchAllPetitionerSharingPurposes(): void {
    this.httpClient
      .get<PetitionerSharingPurposeFromApi[]>('/api/interesant/slo/celudost')
      .pipe(
        takeWhile(() => this.isAlive),
        catchError(handleApiError),
        map((data: PetitionerSharingPurposeFromApi[]) =>
          PetitionerSharingPurpose.fromApiToApp(data)
        )
      )
      .subscribe((data: PetitionerSharingPurpose[]) =>
        this.petitionerSharingPurposes.next(data)
      );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
