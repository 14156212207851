import { Directive, Input, OnChanges } from '@angular/core';
import { GkModalTermsOfUseService } from '../gk-modal-terms-of-use/gk-modal-terms-of-use.service';
import { combineLatest } from 'rxjs';
import { GkModalTermsOfUseComponent } from '../gk-modal-terms-of-use/gk-modal-terms-of-use.component';
import { ModalUtils } from '../gk-map/utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Directive({
  selector: '[gkTermsOfUseInitializer]',
})
export class GkTermsOfUseInitializerDirective implements OnChanges {
  @Input() currentPortalId: number | undefined;
  @Input() currentPortalName: string | undefined;
  constructor(
    private gkModalTermsOfUseService: GkModalTermsOfUseService,
    private modalService: NgbModal
  ) {}

  ngOnChanges() {
    if (this.currentPortalId && this.currentPortalName) {
      this.maybeOpenTermsOfUse();
    }
  }

  maybeOpenTermsOfUse(): void {
    combineLatest([
      this.gkModalTermsOfUseService.areTermsOfUseConfirmed(
        this.currentPortalName
      ),
      this.gkModalTermsOfUseService.getLanguagesOfTermsOfUse(
        this.currentPortalId
      ),
    ]).subscribe(([isConfirmed, langsTermsOfUse]) => {
      if (!langsTermsOfUse.length || isConfirmed) {
        return;
      }

      const modalRef = this.modalService.open(
        GkModalTermsOfUseComponent,
        ModalUtils.getModalOptions()
      ).componentInstance as GkModalTermsOfUseComponent;
      modalRef.langTerms = langsTermsOfUse;
    });
  }
}
