export enum NonAgriculturalLandUseDocTypeId {
  DocumentSpecifyingPropertyValue = 10048,
  Other = 8,
  StampDuty = 10040,
  PowerOfAttorney = 10035,
}

export enum AgriculturalLandUseDocTypeLabelKey {
  DocumentSpecifyingPropertyValue = 'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.NON_AGRICULTURAL_LAND_USE_REQUEST.DOC_TYPE_DESCRIPTION.DOCUMENT_SPECIFYING_PROPERTY_VALUE',
  Other = 'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.NON_AGRICULTURAL_LAND_USE_REQUEST.DOC_TYPE_DESCRIPTION.OTHER',
  StampDuty = 'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.NON_AGRICULTURAL_LAND_USE_REQUEST.DOC_TYPE_DESCRIPTION.STAMP_DUTY',
  PowerOfAttorney = 'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.NON_AGRICULTURAL_LAND_USE_REQUEST.DOC_TYPE_DESCRIPTION.POWER_OF_ATTORNEY',
}

export function getNonAgriculturalLandUseTypLabelKeyById(id: number): string {
  switch (id) {
    case NonAgriculturalLandUseDocTypeId.DocumentSpecifyingPropertyValue: {
      return AgriculturalLandUseDocTypeLabelKey.DocumentSpecifyingPropertyValue;
    }
    case NonAgriculturalLandUseDocTypeId.Other: {
      return AgriculturalLandUseDocTypeLabelKey.Other;
    }
    case NonAgriculturalLandUseDocTypeId.StampDuty: {
      return AgriculturalLandUseDocTypeLabelKey.StampDuty;
    }
    case NonAgriculturalLandUseDocTypeId.PowerOfAttorney: {
      return AgriculturalLandUseDocTypeLabelKey.PowerOfAttorney;
    }
    default: {
      return '';
    }
  }
}
