import { AbstractControl, ValidatorFn } from '@angular/forms';

export function peselValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const pesel = control.value;
    if (!pesel) {
      return null;
    }

    if (pesel.length !== 11 || !/^\d+$/.test(pesel)) {
      return { pesel: true };
    }

    const weights = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3, 1];
    let sum = 0;

    for (let i = 0; i < 11; i++) {
      sum += Number(pesel.charAt(i)) * weights[i];
    }

    if (sum % 10 !== 0) {
      return { pesel: true };
    }

    return null;
  };
}
