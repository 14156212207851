export enum FishingCardDocTypeId {
  Other = 8,
  StampDuty = 10040,
  PowerOfAttorney = 10035,
  Photo = 10050,
  CertificateOfExamPass = 113,
  EducationConfirmDocument = 10049,
  PaymentConfirmation = 10023
}

export enum FishingCardDocTypeLabelKey {
  Other = 'ENVIRONMENTAL_PROTECTION_PORTAL.FISHING_CARD.DOC_TYPE_DESCRIPTION.OTHER',
  StampDuty = 'ENVIRONMENTAL_PROTECTION_PORTAL.FISHING_CARD.DOC_TYPE_DESCRIPTION.STAMP_DUTY',
  PowerOfAttorney = 'ENVIRONMENTAL_PROTECTION_PORTAL.FISHING_CARD.DOC_TYPE_DESCRIPTION.POWER_OF_ATTORNEY',
  Photo = 'ENVIRONMENTAL_PROTECTION_PORTAL.FISHING_CARD.DOC_TYPE_DESCRIPTION.PHOTO',
  CertificateOfExamPass = 'ENVIRONMENTAL_PROTECTION_PORTAL.FISHING_CARD.DOC_TYPE_DESCRIPTION.CERTIFICATE_OF_EXAM_PASS',
  PaymentConfirmation = 'ENVIRONMENTAL_PROTECTION_PORTAL.FISHING_CARD.DOC_TYPE_DESCRIPTION.PAYMENT_CONFIRMATION'
}

export function getFishingCardTypeLabelKeyById(id: number): string {
  switch (id) {
    case FishingCardDocTypeId.Other: {
      return FishingCardDocTypeLabelKey.Other;
    }
    case FishingCardDocTypeId.StampDuty: {
      return FishingCardDocTypeLabelKey.StampDuty;
    }
    case FishingCardDocTypeId.PowerOfAttorney: {
      return FishingCardDocTypeLabelKey.PowerOfAttorney;
    }
    case FishingCardDocTypeId.Photo: {
      return FishingCardDocTypeLabelKey.Photo;
    }
    case FishingCardDocTypeId.CertificateOfExamPass: {
      return FishingCardDocTypeLabelKey.CertificateOfExamPass;
    }
    case FishingCardDocTypeId.PaymentConfirmation: {
      return FishingCardDocTypeLabelKey.PaymentConfirmation;
    }
    default: {
      return '';
    }
  }
}
