<div class="container">
  <h3 class="mt-2 mb-2">
    {{ 'NEW_NETWORK_SERVICE_REQUEST_FORM.TITLE' | translate }}
  </h3>

  <form [formGroup]="formGroup" (ngSubmit)="onFormSubmit()" novalidate>
    <div class="form-group col-12">
      <h3 class="display-6 mt-3">
        {{ 'NEW_REQUEST.SHARING_PURPOSE' | translate }}
      </h3>

      <label [attr.for]="sharingPurposeControl.Name" class="sr-only">
        {{ 'NEW_REQUEST.CHOOSE_PURPOSE' | translate }}:
      </label>

      <select
        class="form-select form-select-sm required"
        aria-required="true"
        [attr.id]="sharingPurposeControl.Name"
        required
        [formControlName]="sharingPurposeControl.Name"
      >
        @for (
          sharingPurpose of petitionerSharingPurposes;
          track sharingPurpose.id
        ) {
          <option [ngValue]="sharingPurpose">
            {{ sharingPurpose.shortDescPl }}
          </option>
        }
      </select>

      <small class="form-text text-muted">
        {{ selectedSharingPurpose && selectedSharingPurpose.descriptionPl }}
      </small>
    </div>

    <div class="form-group col-12">
      <h3 class="display-6 mt-3">
        {{ 'NEW_NETWORK_SERVICE_REQUEST_FORM.MAP_TYPE.LEGEND' | translate }}
      </h3>

      <div class="form-check col-3">
        <label class="form-check-label">
          <input
            class="form-check-input"
            type="radio"
            [attr.name]="mapTypeControl.GroupName"
            [formControlName]="mapTypeControl.GroupName"
            [value]="mapType.Cadastral"
          />
          {{
            'NEW_NETWORK_SERVICE_REQUEST_FORM.MAP_TYPE.CADASTRAL' | translate
          }}
        </label>
      </div>

      <div class="form-check col-3">
        <label class="form-check-label">
          <input
            class="form-check-input"
            type="radio"
            [attr.name]="mapTypeControl.GroupName"
            [formControlName]="mapTypeControl.GroupName"
            [value]="mapType.Basic"
          />
          {{ 'NEW_NETWORK_SERVICE_REQUEST_FORM.MAP_TYPE.BASIC' | translate }}
        </label>
      </div>
    </div>

    <div class="form-group col-12">
      <h3 class="display-6 mt-3">
        {{ 'NEW_NETWORK_SERVICE_REQUEST_FORM.MAP_SCALE' | translate }}
      </h3>

      <label [attr.for]="wmsMapScaleControl.Name" class="sr-only">
        {{ 'NEW_REQUEST.CHOOSE_PURPOSE' | translate }}:
      </label>

      <select
        class="form-select form-select-sm"
        [attr.id]="wmsMapScaleControl.Name"
        [formControlName]="wmsMapScaleControl.Name"
      >
        @for (wmsMapScale of wmsMapScales; track wmsMapScale) {
          <option [value]="wmsMapScale">
            {{ wmsMapScale }}
          </option>
        }
      </select>
    </div>

    <div class="form-group col-12">
      <h3 class="display-6 mt-3">
        {{ 'NEW_NETWORK_SERVICE_REQUEST_FORM.ACCESS_TIME' | translate }}
      </h3>

      <div class="form-check col-3">
        <label class="form-check-label">
          <input
            class="form-check-input"
            type="radio"
            [attr.name]="accessTimeControl.GroupName"
            [formControlName]="accessTimeControl.GroupName"
            [value]="accessTime.ThreeMonths"
          />
          {{
            'NEW_NETWORK_SERVICE_REQUEST_FORM.PERIOD_TYPE.THREE_MONTHS'
              | translate
          }}
        </label>
      </div>

      <div class="form-check col-3">
        <label class="form-check-label">
          <input
            class="form-check-input"
            type="radio"
            [attr.name]="accessTimeControl.GroupName"
            [formControlName]="accessTimeControl.GroupName"
            [value]="accessTime.SixMonths"
          />
          {{
            'NEW_NETWORK_SERVICE_REQUEST_FORM.PERIOD_TYPE.SIX_MONTHS'
              | translate
          }}
        </label>
      </div>

      <div class="form-check col-3">
        <label class="form-check-label">
          <input
            class="form-check-input"
            type="radio"
            [attr.name]="accessTimeControl.GroupName"
            [formControlName]="accessTimeControl.GroupName"
            [value]="accessTime.TwelveMonths"
          />
          {{
            'NEW_NETWORK_SERVICE_REQUEST_FORM.PERIOD_TYPE.TWELVE_MONTHS'
              | translate
          }}
        </label>
      </div>
    </div>

    <div class="form-group col-lg-4 col-md-6 col-sm-12">
      <h3 class="display-6 mt-3">
        {{ 'NEW_NETWORK_SERVICE_REQUEST_FORM.IP_ADDRESSES' | translate }}
      </h3>

      <label [attr.for]="ipAddressControl.Name">
        {{ 'NEW_NETWORK_SERVICE_REQUEST_FORM.IP_ADDRESSES' | translate }}:
      </label>

      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control form-control-sm"
          [attr.id]="nameSurnameControl.Name"
          [formControlName]="ipAddressControl.Name"
        />

        <button
          type="button"
          [disabled]="getIpAddressControl().invalid"
          class="btn btn-sm btn-primary"
          (click)="addIpAddressToTable()"
        >
          Dodaj
        </button>
      </div>

      @if (ipAddresses.length > 0) {
        <table class="table table-sm table-bordered table-hover text-center">
          <tbody>
            @for (ip of ipAddresses; track ip) {
              <tr>
                <td class="w-100">{{ ip }}</td>
                <td>
                  <button
                    type="button"
                    class="btn-close"
                    (click)="removeIpAddressFromTable(ip)"
                  ></button>
                </td>
              </tr>
            }
          </tbody>
        </table>
      }

      @if (shouldShowErrorAlert(getIpAddressControl(), 'validIp')) {
        <div class="alert alert-danger">
          {{ 'FIELD_FORMAT_WRONG' | translate }}
        </div>
      }
    </div>

    <div class="form-group col-12">
      <h3 class="display-6 mt-3">
        {{ 'NEW_REQUEST.CHOOSE_RANGE_TYPE' | translate }}
      </h3>

      <ng-template #rangeTabTitle let-rangeType="rangeType" let-title="title">
        @if (isRangeTabChosen(rangeType)) {
          <i class="fa fa-check-square-o text-primary" aria-hidden="true"></i>
        }
        &nbsp;
        <span
          [ngClass]="
            isRangeTabChosen(rangeType)
              ? 'fw-bold text-primary'
              : 'text-secondary'
          "
        >
          {{ title }}
        </span>
      </ng-template>

      <ul
        ngbNav
        #nav="ngbNav"
        class="nav-tabs nav-fill"
        (navChange)="updateRangeType($event)"
        [activeId]="model.rangeType"
      >
        <li
          [id]="rangeTypeEnum.Districts"
          [ngbNavItem]="rangeTypeEnum.Districts"
        >
          <a ngbNavLink>
            <ng-container
              [ngTemplateOutlet]="rangeTabTitle"
              [ngTemplateOutletContext]="{
                rangeType: rangeTypeEnum.Districts,
                title: 'NEW_REQUEST.DISTRICTS' | translate
              }"
            ></ng-container>
          </a>

          <ng-template ngbNavContent>
            <h3 class="display-6">
              {{ 'NEW_REQUEST.DISTRICT_CHOICE' | translate }}
            </h3>

            <app-district-multi-select></app-district-multi-select>

            <h4 class="display-6">
              {{ 'NEW_REQUEST.DISTRICT_LIST' | translate }}
            </h4>

            <app-district-list
              [districts]="model.districts"
            ></app-district-list>

            <button
              type="button"
              class="btn btn-danger"
              (click)="removeAllDistricts($event)"
              [disabled]="!model.districts.length"
            >
              {{ 'NEW_REQUEST.CLEAR_DISTRICT_LIST' | translate }}
            </button>
          </ng-template>
        </li>

        <li [id]="rangeTypeEnum.Wkt" [ngbNavItem]="rangeTypeEnum.Wkt">
          <a ngbNavLink>
            <ng-container
              [ngTemplateOutlet]="rangeTabTitle"
              [ngTemplateOutletContext]="{
                rangeType: rangeTypeEnum.Wkt,
                title: 'NEW_REQUEST.WKT' | translate
              }"
            ></ng-container>
          </a>

          <ng-template ngbNavContent>
            <div class="mt-2">
              <gk-map
                [mapState]="polygonMapState"
                (dispatch)="handlePolygonMapAction($event)"
              ></gk-map>
            </div>

            <h4
              [hidden]="
                !polygonMapState.mapObjectTablesState[0].mapObjects.length
              "
            >
              {{ 'NEW_REQUEST.DRAWN_POLYGON' | translate }}
            </h4>

            @if (polygonMapState.mapObjectTablesState[0].mapObjects.length) {
              <gk-map-object-table
                [mapState]="polygonMapState"
                [mapObjectTableStateIndex]="0"
                (dispatch)="handlePolygonMapAction($event)"
              ></gk-map-object-table>
            }

            @if (isSubmitted && !isPolygonValid()) {
              <div class="alert alert-danger my-2">
                {{ 'NEW_REQUEST.CHOOSE_ONE_POLYGON' | translate }}
              </div>
            }
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav"></div>

      @if (isSubmitted && !isRangeValid()) {
        <div class="alert alert-danger my-2">
          {{ 'NEW_REQUEST.CHOOSE_RANGE' | translate }}
        </div>
      }
    </div>

    <div class="form-group col-12">
      <h3 class="display-6 mt-3">
        {{ 'NEW_REQUEST.PERSON_CONTACT_DATA' | translate }}
      </h3>

      <div class="col-12 text-center">
        <button
          type="button"
          (click)="copyPetentDataFromPz($event)"
          class="btn btn-primary"
        >
          {{ 'NEW_REQUEST.COPY_PETENT_DATA' | translate }}
        </button>
      </div>

      <div class="row">
        <div class="form-group col-lg-4 col-md-6 col-sm-12">
          <label [attr.for]="nameSurnameControl.Name">
            {{ 'NEW_REQUEST.NAME_SURNAME' | translate }}:
          </label>

          <input
            type="text"
            class="form-control form-control-sm required"
            [attr.id]="nameSurnameControl.Name"
            required
            aria-required="true"
            [(ngModel)]="model.nameSurname"
            [formControlName]="nameSurnameControl.Name"
          />

          @if (shouldShowErrorAlert(getNameSurnameControl(), 'required')) {
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          }
        </div>

        <div class="form-group col-lg-4 col-md-6 col-sm-12">
          <label [attr.for]="emailControl.Name">
            {{ 'NEW_REQUEST.EMAIL' | translate }}:
          </label>

          <input
            type="email"
            class="form-control form-control-sm required"
            [attr.id]="emailControl.Name"
            [(ngModel)]="model.email"
            required
            aria-required="true"
            [formControlName]="emailControl.Name"
          />

          @if (shouldShowErrorAlert(getEmailControl(), 'required')) {
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          }

          @if (shouldShowErrorAlert(getEmailControl(), 'validEmail')) {
            <div class="alert alert-danger">
              {{ 'FIELD_FORMAT_WRONG' | translate }}
            </div>
          }
        </div>

        <div class="form-group col-lg-4 col-md-6 col-sm-12">
          <label [attr.for]="phoneControl.Name">
            {{ 'NEW_REQUEST.PHONE' | translate }}:
          </label>

          <input
            type="tel"
            class="form-control form-control-sm required"
            [attr.id]="phoneControl.Name"
            required
            aria-required="true"
            [(ngModel)]="model.phone"
            [formControlName]="phoneControl.Name"
          />

          @if (shouldShowErrorAlert(getPhoneControl(), 'required')) {
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          }

          @if (shouldShowErrorAlert(getPhoneControl(), 'validPhone')) {
            <div class="alert alert-danger">
              {{ 'FIELD_FORMAT_WRONG' | translate }}
            </div>
          }
        </div>
      </div>
    </div>

    <div class="form-group col-12">
      <h3 class="display-6 mt-3">{{ 'NEW_REQUEST.PAYER_DATA' | translate }}</h3>

      <gk-law-person-search
        [multiplePersonSelect]="false"
        [isRequired]="false"
        [(selectedLawPersons)]="selectedLawPersons"
        [addingPersonsDirectlyToDb]="false"
        [legalPersonSearchFieldsToHide]="[
          legalPersonControlName.TypeId,
          legalPersonControlName.Name,
          legalPersonControlName.Nip,
          legalPersonControlName.KrsNumber
        ]"
      ></gk-law-person-search>
    </div>

    @if (calculatedPrice) {
      <div class="form-group col-12">
        <h3 class="display-6 mt-3">Cena</h3>
        <div>{{ calculatedPrice }} zł</div>
      </div>
    }

    <div class="form-group col-12">
      @if (isSubmitted && !isFormValid(formGroup)) {
        <div class="alert alert-danger my-2">
          {{ 'NEW_REQUEST.NOT_EVERY_FIELD_IS_FILLED' | translate }}
        </div>
      }

      <button
        type="submit"
        [disabled]="docSignPending"
        class="btn btn-color-from-db my-3"
      >
        {{ 'NEW_REQUEST.SUBMIT_REQUEST_BUTTON' | translate }}
      </button>

      <div class="d-flex flex-column">
        @if (docSignMsgTxt) {
          <span [ngClass]="'text-' + docSignMsgType">
            {{ docSignMsgTxt }}
          </span>
        }

        @if (docSignUrl) {
          <span class="my-2 text-info">
            {{ docSignTranslations['SIGN_INFO'] }}
          </span>
        }

        @if (docSignUrl) {
          <a class="link" [href]="docSignUrl" target="_blank">
            {{ docSignTranslations['SIGN'] }}
          </a>
        }

        @if (docSignPending) {
          <div class="d-flex justify-content-center my-3">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
            </div>
          </div>
        }
      </div>

      <div class="mt-2"><app-form-legend></app-form-legend></div>
    </div>
  </form>
</div>
