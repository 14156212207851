import { LawPersonAddress } from '@gk/gk-modules';
import {
  InvestorDetails,
  InvestorDetailsRequestDto,
} from '../../../shared/investor-details/investor-details.model';
import {
  Shipping,
  ShippingDto,
} from '../../../shared/shipping-form/shipping-form.model';
import { ApiNewDokumentPowiazanyDalDto } from '../../designer-incoming-documents/designer-incoming-documents.model';
import { DecisionFormType } from '../../excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { ZamowienieDzialkaDto } from '../../excluding-land-from-agricultural-production/agricultural-land-use/agricultural-land-use.model';

export interface SimplifiedForestManagementPlan {
  investorDetails: InvestorDetails;
  proxyDetails: InvestorDetails;
  idNumber: number;
  phoneNumber: number;
  email: string;
  address: LawPersonAddress;
  proxyDetailsCheckbox: boolean;
  attachments: ApiNewDokumentPowiazanyDalDto;
  decisionForm: DecisionFormType;
  landParcel: ZamowienieDzialkaDto;
  shippingForm: Shipping;
}

export class SimplifiedForestManagementPlanRequestDto {
  constructor(
    public Wnioskodawca: InvestorDetailsRequestDto,
    public Pelnomocnik: InvestorDetailsRequestDto,
    public Zalaczniki: ApiNewDokumentPowiazanyDalDto[],
    public FormaDecyzji: number,
    public Dzialka: ZamowienieDzialkaDto,
    public Odbior: ShippingDto
  ) {}
  static fromAppToApi(
    data: SimplifiedForestManagementPlan,
    attachments: ApiNewDokumentPowiazanyDalDto[]
  ): SimplifiedForestManagementPlanRequestDto {
    return new this(
      InvestorDetailsRequestDto.fromAppToApi(data.investorDetails),
      data.proxyDetailsCheckbox
        ? InvestorDetailsRequestDto.fromAppToApi(data.proxyDetails)
        : null,
      attachments,
      data.decisionForm,
      data.landParcel,
      ShippingDto.fromAppToApi(data.shippingForm)
    );
  }
}
