import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { FileSaverService } from 'ngx-filesaver';
import { Observable, tap } from 'rxjs';
import { RequestConfig } from '../../gk-dynamic-list/gk-dynamic-list.model';
import { DataService } from '../../gk-dynamic-list/services/data/data.service';
import { getFileNameFromResponseHeaders } from '../../utils/utils';

@Injectable({
  providedIn: 'root',
})
export class ControlDownloadFileService {
  constructor(
    private dataService: DataService,
    private fileSaverService: FileSaverService,
  ) {}

  public getFileFullName(
    objectContainingFullName: any,
    pathToFileFullName: string,
  ): string {
    return _.get(objectContainingFullName, pathToFileFullName);
  }

  downloadFile(
    requestConfig: RequestConfig,
    requestAttributes: any,
    objectContainingFullFileName: any,
    pathToFileFullName: string,
  ): Observable<any> {
    return this.dataService.getData(requestConfig, requestAttributes).pipe(
      tap({
        next: (response) => {
          const docFile = response?.body ? response.body : response;
          const name = response.headers
            ? getFileNameFromResponseHeaders(response.headers)
            : this.getFileFullName(
                objectContainingFullFileName,
                pathToFileFullName,
              );
          this.fileSaverService.save(docFile, name);
        },
        error: (err) => console.error(err),
      }),
    );
  }
}
