@if (
  !destroyOnForcedTfaActivation ||
  (tfaService.forcedTfaActivationInProgress | async) !== true
  ) {
  <div
    class="login-component forms-wrapper"
    >
    @if (
      (isLoginMode() | async) ||
      (keepOpenLoginWindowWithModals && (isModalWindow() | async))
      ) {
      <gk-login
        [signUpLinkVisible]="signUpLinkVisible"
        [restorePasswordLinkVisible]="restorePasswordLinkVisible"
        [externalLoginUrl]="externalLoginUrl"
        [ewidLoginUrl]="ewidLoginUrl"
        [isPzAuthenticationAvailable]="isPzAuthenticationAvailable"
        [infoAlertsVisible]="infoAlertsVisible"
      ></gk-login>
    }
    @if (isResetPasswordMode() | async) {
      <gk-reset-password></gk-reset-password>
    }
    @if (isNewPasswordMode() | async) {
      <gk-new-password> </gk-new-password>
    }
    @if (isTfaConfirmationMode() | async) {
      <gk-tfa-confirmation
      ></gk-tfa-confirmation>
    }
    @if (isTfaResetMode() | async) {
      <gk-tfa-reset></gk-tfa-reset>
    }
  </div>
}
