<div>
  <table class="table table-sm text-center">
    <tbody>
      <tr class="border-bottom-header">
        <th class="color-bg-lightgrey m-2">
          {{ 'SIZE_REPORTS_LIMIT.AREA_FORM' | translate }}
        </th>
        <th class="color-bg-lightgrey">
          {{ 'SIZE_REPORTS_LIMIT.AREA_TO' | translate }}
        </th>
        <th class="color-bg-lightgrey">
          {{ 'SIZE_REPORTS_LIMIT.MEGA_BYTES' | translate }}
        </th>
      </tr>
      @for (limit of limits; track $index) {
        <tr>
          <td class="color-bg-lightgoldenrodyellow m-2">{{ limit.HaOd }}</td>
          <td class="color-bg-lightgoldenrodyellow m-2">{{ limit.HaDo }}</td>
          <td class="color-bg-lightgoldenrodyellow m-2">
            {{ limit.MegaBytes }}
          </td>
        </tr>
      }
    </tbody>
  </table>
</div>
