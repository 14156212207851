import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiListResponse } from '../../../services';
import {
  AjaxFilterWrapper,
  ClassificationContour,
  ClassificationContourFromApi,
  ControlPoint,
  ControlPointFromApi,
  DistrictMapObject,
  DistrictMapObjectFromApi,
  EgibObject,
  EgibObjectFromApi,
  LandUse,
  LandUseFromApi,
  MapObject,
  MapObjectAttributesFromApi,
  MapObjectDynamicAttributesFromApi,
  MapObjectFromApi,
  MapObjectInfo,
  MapObjectInfoFromApi,
  MapObjectsApplicantEndpoint,
  MapObjectsResponse,
  ToolType,
  Wkt,
} from '../../models';

@Injectable()
export class MapRequestsService {
  constructor(private http: HttpClient) {}

  searchMapObjects(
    toolType: ToolType,
    ajaxFilterWrapper: AjaxFilterWrapper,
  ): Observable<MapObjectsResponse> {
    const endpoint = this.getEndpoint(toolType);

    return this.http
      .post<ApiListResponse<MapObjectFromApi>>(endpoint, ajaxFilterWrapper)
      .pipe(
        map((data) =>
          MapObjectsResponse.fromApiToApp(
            data,
            data.Response.map((mapObjectFromApi) =>
              this.convertDataFromApi(toolType, mapObjectFromApi),
            ),
          ),
        ),
        catchError(() => of(MapObjectsResponse.getInitialStruct())),
      );
  }

  getEndpoint(toolType: ToolType): MapObjectsApplicantEndpoint | never {
    switch (toolType) {
      case ToolType.LandParcel:
      case ToolType.RectangularExtent:
        return MapObjectsApplicantEndpoint.LandParcel;
      case ToolType.Building:
        return MapObjectsApplicantEndpoint.Building;
      case ToolType.Premises:
        return MapObjectsApplicantEndpoint.Premises;
      case ToolType.District:
        return MapObjectsApplicantEndpoint.District;
      case ToolType.LandUse:
        return MapObjectsApplicantEndpoint.LandUse;
      case ToolType.ClassificationContour:
        return MapObjectsApplicantEndpoint.ClassificationContour;
      case ToolType.ControlPoint:
        return MapObjectsApplicantEndpoint.ControlPoint;
      case ToolType.BoundaryPoint:
        return MapObjectsApplicantEndpoint.BoundaryPoint;
      default:
        throw new Error('No endpoint for not handled map object type.');
    }
  }

  convertDataFromApi(
    toolType: ToolType,
    mapObjectFromApi: MapObjectFromApi,
  ): MapObject | never {
    switch (toolType) {
      case ToolType.LandParcel:
      case ToolType.Building:
      case ToolType.Premises:
      case ToolType.BoundaryPoint:
      case ToolType.RectangularExtent:
        return EgibObject.fromApiToApp(mapObjectFromApi as EgibObjectFromApi);
      case ToolType.LandUse:
        return LandUse.fromApiToApp(mapObjectFromApi as LandUseFromApi);
      case ToolType.ClassificationContour:
        return ClassificationContour.fromApiToApp(
          mapObjectFromApi as ClassificationContourFromApi,
        );
      case ToolType.District:
        return DistrictMapObject.fromApiToApp(
          mapObjectFromApi as DistrictMapObjectFromApi,
        );
      case ToolType.ControlPoint:
        return ControlPoint.fromApiToApp(
          mapObjectFromApi as ControlPointFromApi,
        );
      default:
        throw new Error('Not handled map object type.');
    }
  }

  getMapObjectsInfo(wkt: Wkt): Observable<MapObjectInfo[]> {
    const postBody = {
      Wkt: wkt,
    };

    return this.http
      .post<MapObjectInfoFromApi[]>('/api/mapa/objects/byGeom', postBody)
      .pipe(
        map((mapObjectsInfo) =>
          mapObjectsInfo.map((mapObjectInfo) =>
            MapObjectInfo.fromApiToApp(mapObjectInfo),
          ),
        ),
      );
  }

  getLandParcelAttributes(
    uuid: string,
  ): Observable<MapObjectAttributesFromApi> {
    return this.http.get<MapObjectAttributesFromApi>(
      `/api/interesant/przp/dzialka/${uuid}`,
    );
  }

  getBdot500Attributes(
    uuid: string,
  ): Observable<MapObjectDynamicAttributesFromApi> {
    return this.http.get<MapObjectDynamicAttributesFromApi>(
      `/api/interesant/przp/bdot500/${uuid}`,
    );
  }

  getGesutAttributes(
    uuid: string,
  ): Observable<MapObjectDynamicAttributesFromApi> {
    return this.http.get<MapObjectDynamicAttributesFromApi>(
      `/api/interesant/przp/gesut/${uuid}`,
    );
  }
}
