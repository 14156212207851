import { DictionaryField, DictionaryFieldFromApi } from '@gk/gk-modules';

export interface CountyDictionaryFieldFromApi extends DictionaryFieldFromApi {
  Gminy: DictionaryFieldFromApi[];
}

export interface VoivodeshipDictionaryFieldFromApi
  extends DictionaryFieldFromApi {
  Powiaty: CountyDictionaryFieldFromApi[];
}

export interface AdministrativeDivisionDictionaryFromApi {
  Wojewodztwa: VoivodeshipDictionaryFieldFromApi[];
}

export class CountyDictionary extends DictionaryField {
  constructor(
    id: string | number,
    name: string,
    public communities: DictionaryField[]
  ) {
    super(id, name);
  }

  static override fromApiToApp(
    data: CountyDictionaryFieldFromApi
  ): CountyDictionary {
    return new this(
      data.Id,
      data.Nazwa,
      data.Gminy.map((community) => DictionaryField.fromApiToApp(community))
    );
  }
}

export class VoivodeshipDictionary extends DictionaryField {
  constructor(
    id: string | number,
    name: string,
    public counties: CountyDictionary[]
  ) {
    super(id, name);
  }

  static override fromApiToApp(
    data: VoivodeshipDictionaryFieldFromApi
  ): VoivodeshipDictionary {
    return new this(
      data.Id,
      data.Nazwa,
      data.Powiaty.map((county) => CountyDictionary.fromApiToApp(county))
    );
  }
}
