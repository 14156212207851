import { Component, Input } from '@angular/core';
import { LogoImageTextNavItemConfig } from '../../gk-dynamic-navbar.model';
import { NavBaseComponent } from '../nav-base/nav-base.component';

@Component({
  selector: 'gk-nav-item-logo-image-text',
  templateUrl: './nav-item-logo-image-text.component.html',
  styleUrls: ['./nav-item-logo-image-text.component.scss']
})
export class NavItemLogoImageTextComponent extends NavBaseComponent {
  @Input()
  logoImageTextNavItemConfig: LogoImageTextNavItemConfig;
}
