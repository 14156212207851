@if (!legalPersonOnly) {
  <div class="col-12">
    @if (shouldShowLawPersonTypeForm()) {
      <gk-law-person-type-form
        [form]="lawPersonForm"
        [idPrefix]="'law-person-search-'"
      ></gk-law-person-type-form>
    }
  </div>
}

@if (isNaturalPersonChosen()) {
  <gk-natural-person-form
    [isRequired]="isRequired"
    [forcePeselRequired]="forcePeselRequired"
    [form]="getNaturalPersonFormGroup()"
    [addingPersonsDirectlyToDb]="addingPersonsDirectlyToDb"
    [forDesignerPersonType]="isDesignerPersonTypeChosen()"
    (keyup.enter)="newPersonMode ? undefined : searchLawPerson()"
  ></gk-natural-person-form>
}

@if (isLegalPersonChosen()) {
  <gk-legal-person-form
    [newPersonMode]="newPersonMode"
    [isRequired]="isRequired"
    [searchFieldsToHide]="newPersonMode ? [] : legalPersonSearchFieldsToHide"
    [form]="getLegalPersonFormGroup()"
    (keyup.enter)="newPersonMode ? undefined : searchLawPerson()"
  ></gk-legal-person-form>
}

@if (newPersonMode) {
  <gk-address-form
    [isRequired]="
      isLegalPersonChosen() ||
      !isDesignerPersonTypeChosen() ||
      isDesignerAddressRequired
    "
    [primitivePostalCode]="primitivePostalCode"
    [newPersonMode]="newPersonMode"
    [form]="getAddressFormGroup()"
  ></gk-address-form>
}

<div class="d-flex justify-content-end my-3">
  <button
    type="button"
    class="btn btn-sm btn-outline-danger me-2 btn-sm"
    (click)="clearLawPersonFormData()"
    tabindex="0"
    [disabled]="buttonsDisabled"
  >
    {{ 'LAW_PERSON_SEARCH.CLEAR' | translate }}
  </button>

  @if (newPersonMode) {
    <button
      type="button"
      class="btn btn-secondary me-2 btn-sm"
      (click)="disableNewPersonMode()"
      [disabled]="buttonsDisabled"
    >
      {{ 'LAW_PERSON_SEARCH.CANCEL' | translate }}
    </button>
  }

  @if (newPersonMode) {
    <button
      type="button"
      class="btn btn-color-from-db btn-sm"
      (click)="addNewLawPerson()"
      [disabled]="buttonsDisabled"
    >
      {{ 'LAW_PERSON_SEARCH.ADD' | translate }}
    </button>
  }

  @if (!newPersonMode) {
    <button
      type="button"
      class="btn btn-color-from-db btn-sm"
      (click)="searchLawPerson()"
      [disabled]="buttonsDisabled"
    >
      @if (
        !isHiddenKrsNumber() &&
        (getLegalPersonRegonFormControl().value ||
          getLegalPersonNipFormControl().value)
      ) {
        {{ 'LAW_PERSON_SEARCH.SEARCH_IN_REGON_DB' | translate }}
      } @else if (
        !isHiddenKrsNumber() && getLegalPersonKrsNumberFormControl().value
      ) {
        {{ 'LAW_PERSON_SEARCH.SEARCH_IN_KRS_DB' | translate }}
      } @else {
        {{ 'LAW_PERSON_SEARCH.SEARCH' | translate }}
      }
    </button>
  }
</div>

<div class="d-flex flex-column">
  <span class="text-danger" [hidden]="!personSearchOrAddMsg">
    {{ personSearchOrAddMsg }}
  </span>

  @if (personLoading) {
    <div class="d-flex justify-content-center my-3">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
      </div>
    </div>
  }
</div>

<gk-law-person-list
  [lawPersons]="matchingLawPersons"
  [hidden]="!matchingLawPersons.length"
  [legalPersonOnly]="legalPersonOnly"
  [canAddNewPersonEvenIfDefinedInDb]="canAddNewPersonEvenIfDefinedInDb"
  [naturalPersonChosen]="isNaturalPersonChosen()"
  (chosenLawPersons)="updateLawPersonsTable($event)"
  (newPersonMode)="updateAddingPersonMode($event)"
></gk-law-person-list>

<gk-selected-law-persons-table-container
  [lawPersons]="selectedLawPersons"
  [staticSelectedTableValue]="staticSelectedTableValue"
  [defaultAvailablePersonTypes]="defaultAvailablePersonTypes"
  [legalPersonOnly]="legalPersonOnly"
  (removedLawPersons)="deleteLawPersonsFromSelectedLawPersons($event)"
  [hidden]="
    !selectedLawPersons ||
    (!selectedLawPersons.length &&
      (!staticSelectedTableValue || !staticSelectedTableValue.length))
  "
></gk-selected-law-persons-table-container>

<ng-template #noSearchResultModal let-confirm="close()">
  <div class="modal-header">
    <h4 class="modal-title" tabindex="0">
      {{ 'LAW_PERSON_SEARCH.NO_SEARCH_RESULT' | translate }}
    </h4>
  </div>

  <div class="modal-body" tabindex="0">
    @if (isNaturalPersonChosen() || legalPersonAddModeEnabled) {
      {{ 'LAW_PERSON_SEARCH.ADD_PERSON_REQUEST' | translate }}
    } @else {
      {{ 'LAW_PERSON_SEARCH.NO_LEGAL_PERSON_RESULT' | translate }}
    }
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="(confirm)">
      {{ 'LAW_PERSON_SEARCH.OK' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #postOfficeModal let-submit="close">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'LAW_PERSON_SEARCH.INFORMATION' | translate }}
    </h4>
  </div>

  <div class="modal-body">
    {{ 'LAW_PERSON_SEARCH.ENTER_POST_OFFICE' | translate }}
    <form [formGroup]="getAddressFormGroup()">
      <div class="mt-3">
        <label [attr.for]="lawPersonAddressControlName.PostOffice">
          {{ 'LAW_PERSON_SEARCH.POST_OFFICE' | translate }}
        </label>

        <input
          type="text"
          [name]="lawPersonAddressControlName.PostOffice"
          [id]="lawPersonAddressControlName.PostOffice"
          class="form-control form-control-sm required"
          aria-required="true"
          [formControlName]="lawPersonAddressControlName.PostOffice"
          [autocomplete]="undefined"
        />

        @if (!isPostOfficeFormControlValid()) {
          <div class="alert alert-danger" tabindex="0">
            {{ 'FIELD_REQUIRED' | translate }}
          </div>
        }
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="submit(false)">
      {{ 'LAW_PERSON_SEARCH.CANCEL' | translate }}
    </button>

    <button
      type="button"
      class="btn btn-primary"
      [disabled]="!isPostOfficeFormControlValid()"
      (click)="submit(true)"
    >
      {{ 'LAW_PERSON_SEARCH.OK' | translate }}
    </button>
  </div>
</ng-template>
