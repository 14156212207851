@if (shouldShowControl()) {
  <div gkControlRow>
    <span gkControlLabel [showLabel]="controlConfig.showLabel">
      <gk-translated-label
        [configLabel]="controlConfig.label"
      ></gk-translated-label
      >:</span
    >
    <span gkControlValue [showLabel]="controlConfig.showLabel">
      <button
        class="btn btn-sm btn-warning cancel-btn"
        (click)="tryCancel(modalTemplate)"
        [attr.aria-label]="
          'GK.DYNAMIC_LIST.CONTROL_CANCEL_REQUEST.CANCEL_REQUEST' | translate
        "
        [attr.title]="
          'GK.DYNAMIC_LIST.CONTROL_CANCEL_REQUEST.CANCEL_BUTTON_TITLE'
            | translate
        "
        [disabled]="loading || succeeded"
      >
        {{
          'GK.DYNAMIC_LIST.CONTROL_CANCEL_REQUEST.CANCEL_REQUEST' | translate
        }}
      </button>
      @if (loading) {
        <div class="d-flex justify-content-center my-3">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
          </div>
        </div>
      }
      @if (!loading && errorMsg) {
        <span class="text-danger">{{ errorMsg }}</span>
      }
      @if (succeeded) {
        <span class="text-success">{{
          'GK.DYNAMIC_LIST.CONTROL_CANCEL_REQUEST.CANCEL_SUCCEEDED' | translate
        }}</span>
      }
    </span>
  </div>
}

<ng-template
  #modalTemplate
  let-dismiss="dismiss('canceled')"
  let-close="close('accept')"
>
  <div class="modal-header">
    <h4 class="modal-title">
      {{
        'GK.DYNAMIC_LIST.CONTROL_CANCEL_REQUEST.CONFIRMATION_MODAL_TITLE'
          | translate
      }}
    </h4>
    <button
      type="button"
      class="btn-close"
      [attr.aria-label]="
        'GK.DYNAMIC_LIST.CONTROL_CANCEL_REQUEST.CONFIRMATION_MODAL_CLOSE'
          | translate
      "
      [title]="
        'GK.DYNAMIC_LIST.CONTROL_CANCEL_REQUEST.CONFIRMATION_MODAL_CLOSE'
          | translate
      "
      (click)="(dismiss)"
    ></button>
  </div>
  <div class="modal-body">
    {{ 'GK.DYNAMIC_LIST.CONTROL_CANCEL_REQUEST.CONFIRMATION_TEXT' | translate }}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-color-from-db" (click)="(close)">
      {{
        'GK.DYNAMIC_LIST.CONTROL_CANCEL_REQUEST.CONFIRMATION_ACCEPT' | translate
      }}
    </button>
    <button
      type="button"
      class="btn btn-color-from-db-outline"
      (click)="(dismiss)"
    >
      {{
        'GK.DYNAMIC_LIST.CONTROL_CANCEL_REQUEST.CONFIRMATION_DECLINE'
          | translate
      }}
    </button>
  </div>
</ng-template>
