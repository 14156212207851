import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { getDefaultControlValue } from '@gk/gk-modules';
import { EcoDocumentSearchControlName } from './eco-document-search-form.model';

@Injectable()
export class EcoDocumentSearchFormService {
  constructor(private formBuilder: UntypedFormBuilder) {}

  getFormGroup() {
    return this.formBuilder.group({
      [EcoDocumentSearchControlName.SerialNumber]: [getDefaultControlValue()],
      [EcoDocumentSearchControlName.DocumentName]: [getDefaultControlValue()],
      [EcoDocumentSearchControlName.CardIssueYear]: [getDefaultControlValue()],
      [EcoDocumentSearchControlName.DocumentCreator]: [
        getDefaultControlValue(),
      ],
      [EcoDocumentSearchControlName.DocumentApprover]: [
        getDefaultControlValue(),
      ],
      [EcoDocumentSearchControlName.CaseMark]: [getDefaultControlValue()],
      [EcoDocumentSearchControlName.DocumentType]: [getDefaultControlValue()],
      [EcoDocumentSearchControlName.DocumentSubject]: [
        getDefaultControlValue(),
      ],
      [EcoDocumentSearchControlName.OfficeName]: [getDefaultControlValue()],
      [EcoDocumentSearchControlName.Description]: [getDefaultControlValue()],
      [EcoDocumentSearchControlName.DocumentDate]: [getDefaultControlValue()],
      [EcoDocumentSearchControlName.ApprovalDate]: [getDefaultControlValue()],
      [EcoDocumentSearchControlName.PublicationDate]: [
        getDefaultControlValue(),
      ],
      [EcoDocumentSearchControlName.Voivodeship]: [getDefaultControlValue()],
      [EcoDocumentSearchControlName.County]: [getDefaultControlValue(true)],
      [EcoDocumentSearchControlName.Community]: [getDefaultControlValue(true)],
    });
  }
}
