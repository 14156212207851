import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ApiNewDokumentPowiazanyDalDto,
  FileWithCustomUploading,
} from './add-related-document.model';

@Injectable()
export class AddRelatedDocumentService {
  constructor(private httpClient: HttpClient) {}

  private fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result.split(',')[1]);
        }
      };
      reader.onerror = (err) => {
        reject(err);
      };
    });
  }

  private convertCustomFileToApiDocument(
    file: FileWithCustomUploading,
    docId: number
  ): Promise<ApiNewDokumentPowiazanyDalDto> {
    return this.fileToBase64(file).then(
      (fileBase) =>
        new ApiNewDokumentPowiazanyDalDto(
          file.name,
          fileBase,
          docId,
          file.docType,
          file.description
        )
    );
  }

  postNewDocument(
    file: FileWithCustomUploading,
    docId: number,
    url: string
  ): Promise<Observable<any>> {
    return this.convertCustomFileToApiDocument(file, docId).then(
      (apiDocModel) => {
        const req = new HttpRequest('POST', url, apiDocModel, {
          reportProgress: true,
        });

        return this.httpClient.request(req);
      }
    );
  }
}
