import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, interval, Observable, Subject, tap } from 'rxjs';
import { catchError, map, takeWhile } from 'rxjs/operators';
import {
  AddedDocToSignResponse,
  AddedDocToSignResponseFromApi,
  DocKeyId,
  DocSign,
  DocSignFromApi,
  DocSignStatus,
  SignedOrder,
} from './trusted-profile-sign.model';

@Injectable()
export class DocSignService {
  recentlySignedOrder = new BehaviorSubject<SignedOrder>({} as SignedOrder);
  constructor(private httpClient: HttpClient, private toastr?: ToastrService) {}

  getDocSignStatus(docKeyId: DocKeyId): Observable<DocSign> {
    return this.httpClient
      .get<DocSignFromApi>('/api/pz/document/signing/status', {
        params: { docKeyId },
      })
      .pipe(
        map((docSignFromApi) => DocSign.fromApiToApp(docSignFromApi)),
        tap((submittedRequest) =>
          submittedRequest.signedOrder
            ? this.recentlySignedOrder.next(submittedRequest.signedOrder)
            : _.noop()
        )
      );
  }

  setDocSignStatusCheckInterval(
    docKeyId: DocKeyId,
    intervalInSecs = 7
  ): Subject<DocSign | Error> {
    let docSignData = { status: DocSignStatus.Pending } as DocSign | Error;
    const docSignSubject = new Subject<DocSign | Error>();

    interval(intervalInSecs * 1000)
      .pipe(
        takeWhile(() => _.get(docSignData, 'status') === DocSignStatus.Pending)
      )
      .subscribe(() => {
        this.getDocSignStatus(docKeyId).subscribe({
          next: (docSign) => {
            docSignData = docSign;
            docSignSubject.next(docSign);
          },
          error: () => {
            const errorToReturn = new Error();
            docSignData = errorToReturn;
            docSignSubject.next(errorToReturn);
          },
        });
      });

    return docSignSubject;
  }

  addToSign(formValue: any, url: string): Observable<AddedDocToSignResponse> {
    return this.httpClient
      .post<AddedDocToSignResponseFromApi>(url, formValue)
      .pipe(
        map((addedDocToSignResponseFromApi) =>
          AddedDocToSignResponse.fromApiToApp(addedDocToSignResponseFromApi)
        ),
        catchError((error) => {
          const message = _.get(error, 'error.ResponseStatus.Message', '');
          if (message) {
            this.toastr.error(message);
          }

          throw error;
        })
      );
  }
}
