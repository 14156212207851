import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

export interface RequestInProgress {
  canDeactivate: () => boolean | Observable<boolean>;
}
@Injectable()
export class RequestInProgressGuard  {
  canDeactivate(component: RequestInProgress): boolean | Observable<boolean> {
    return component.canDeactivate();
  }
}
