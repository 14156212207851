export interface ColorSetFromApi {
  MainBarColor: string;
  MainBarBorderColor: string;
  MainBarFontColor: string;
  HoverColor: string;
  HoverBorderColor: string;
  HoverFontColor: string;
  ActiveHoverColor: string;
  ActiveHoverBorderColor: string;
  ActiveHoverFontColor: string;
}

export class ColorSet {
  constructor(
    public main: string,
    public mainBorder: string,
    public mainFont: string,
    public hover: string,
    public hoverBorder: string,
    public hoverFont: string,
    public active: string,
    public activeBorder: string,
    public activeFont: string
  ) {}

  static fromApi(data: ColorSetFromApi): ColorSet {
    return new this(
      data.MainBarColor,
      data.MainBarBorderColor,
      data.MainBarFontColor,
      data.HoverColor,
      data.HoverBorderColor,
      data.HoverFontColor,
      data.ActiveHoverColor,
      data.ActiveHoverBorderColor,
      data.ActiveHoverFontColor
    );
  }
}
