import { LawPersonType } from '../../gk-person-search/services/law-person/law-person.model';

export interface PetentDataFromApi {
  WebewidLogin?: string;
  WebewidHaslo?: string;
  PzLogin: string;
  PzEmail: string;
  Imie: string;
  Nazwisko: string;
  Pesel: string;
  Nip: string;
  Email: string;
  Telefon: string;
  MiejscId: number;
  Miejsc: string;
  DdoMiejsc: string;
  DdoMiejscId: number;
  UlicaId: number;
  Ulica?: any;
  UlicaPrzedrostek: string;
  DdoUlicaId: number;
  DdoUlica: string;
  KodPocztId: number;
  KodPoczt: string;
  NumerBud: string;
  NumerLok?: any;
  DdoNazwa: string;
  DdoNip: string;
  DdoKodPoczt: string;
  DdoKodPocztId: number;
  DdoMiejscowosc: string;
  DdoNumerBud: string;
  DdoNumerLok?: any;
  PetentId: number;
  AdresNazwa: string;
  UprawnionyId: string;
  Nazwa: string;
  Regon: string;
  Typ: LawPersonType;
}

export class PetentData {
  constructor(
    public firstname: string,
    public lastname: string,
    public email: string,
    public phone: string,
    public pzLogin: string,
    public street: string,
    public streetId: number,
    public streetPrefix: string,
    public fullStreet: string,
    public ddoStreet: string,
    public ddoStreetId: number,
    public postalCode: string,
    public postalCodeId: number,
    public ddoPostalCode: string,
    public ddoPostalCodeId: number,
    public place: string,
    public placeId: number,
    public ddoPlace: string,
    public ddoPlaceId: number,
    public buildingNumber: string,
    public localNumber: string,
    public ddoBuildingNumber: string,
    public ddoLocalNumber: string,
    public address: string,
    public pesel: string,
    public nip: string,
    public id: number,
    public authorizedPersonId: string,
    public name: string,
    public regon: string,
    public isLegalPerson: boolean,
    public type: LawPersonType,
  ) {}

  static fromApiToApp(fromApiPetentData: PetentDataFromApi): PetentData {
    return new this(
      fromApiPetentData.Imie,
      fromApiPetentData.Nazwisko,
      fromApiPetentData.Email,
      fromApiPetentData.Telefon,
      fromApiPetentData.PzLogin,
      fromApiPetentData.Ulica,
      fromApiPetentData.UlicaId,
      fromApiPetentData.UlicaPrzedrostek,
      this.parseFullStreetName(fromApiPetentData.UlicaPrzedrostek, fromApiPetentData.Ulica),
      fromApiPetentData.DdoUlica,
      fromApiPetentData.DdoUlicaId,
      fromApiPetentData.KodPoczt,
      fromApiPetentData.KodPocztId,
      fromApiPetentData.DdoKodPoczt,
      fromApiPetentData.DdoKodPocztId,
      fromApiPetentData.Miejsc,
      fromApiPetentData.MiejscId,
      fromApiPetentData.DdoMiejsc,
      fromApiPetentData.DdoMiejscId,
      fromApiPetentData.NumerBud,
      fromApiPetentData.NumerLok,
      fromApiPetentData.DdoNumerBud,
      fromApiPetentData.DdoNumerLok,
      fromApiPetentData.AdresNazwa,
      fromApiPetentData.Pesel,
      fromApiPetentData.Nip,
      fromApiPetentData.PetentId,
      fromApiPetentData.UprawnionyId,
      fromApiPetentData.Nazwa,
      fromApiPetentData.Regon,
      fromApiPetentData.Typ === LawPersonType.Legal,
      fromApiPetentData.Typ,
    );
  }

  static parseFullStreetName(streetPrefix: string | null, street: string | null): string {
    return street ? (streetPrefix ? `${streetPrefix} ${street}` : street) : '';
  }
}

export const petentDataMock = new PetentData(
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
);
