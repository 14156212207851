import { Component, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NewDesignerRequestControlName } from '../../services/new-designer-request-form/new-designer-request-form.model';

@Component({
  selector: 'app-connections-and-networks-table',
  templateUrl: './connections-and-networks-table.component.html',
  styleUrls: ['./connections-and-networks-table.component.scss'],
})
export class ConnectionsAndNetworksTableComponent {
  @Input() designerRequestForm: UntypedFormGroup;

  constructor(public formBuilder: UntypedFormBuilder) {}

  deleteConnectionAndNetwork(index: number): void {
    this.connectionAndNetworkGroupArray.removeAt(index);
  }

  deleteAll(): void {
    this.connectionAndNetworkGroupArray = this.formBuilder.array([]);
  }

  get connectionAndNetworkGroupArray(): UntypedFormArray {
    return this.designerRequestForm.get(
      NewDesignerRequestControlName.ConnectionAndNetworkGroupArray
    ) as UntypedFormArray;
  }

  set connectionAndNetworkGroupArray(value: UntypedFormArray) {
    this.designerRequestForm.controls[
      NewDesignerRequestControlName.ConnectionAndNetworkGroupArray
    ] = value;
  }
}
