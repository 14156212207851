@if (shouldShowControl()) {
  <div gkControlRow class="d-flex flex-column">
    <div class="w-100 py-2 d-flex align-items-center justify-content-center">
      <button
        type="button"
        class="btn btn-link p-0 m-0"
        [title]="getFullLabel()"
        (click)="switchCollapse()"
        [attr.aria-expanded]="!controlConfig.collapsed"
        >
        <span>{{ getButtonLabel() }}</span
          >&nbsp;
          <span [ngClass]="getIconClass()"></span>
        </button>
      </div>
      <div class="w-100" [ngbCollapse]="collapsed">
        @if (!collapsed) {
          <gk-dynamic-accordion-body
            [data]="data"
            [listDataConfig]="controlConfig.listDataConfig"
            [preventLoadedFirstAccordionDataEimtting]="true"
          ></gk-dynamic-accordion-body>
        }
      </div>
    </div>
  }
