<div class="d-flex flex-column attachment">
  @if (hasFilesError('accept')) {
    <div class="row">
      <div class="col-12">
        <div class="alert alert-danger" role="alert">
          {{ getGeneratedInvalidTypeText() | async }}
        </div>
      </div>
    </div>
  }

  @if (hasFilesError('fileSize')) {
    <div class="row">
      <div class="col-12">
        <div class="alert alert-danger" role="alert">
          {{ 'GK.DYNAMIC_LIST.CONTROL_INPUT_FILE.MAX_SIZE_EXCEEDED' | translate }}
          -
          {{ getFileSizeString(maxSize) }}
        </div>
      </div>
    </div>
  }

  <div
    #dragAndDrop
    ngfDrop
    selectable
    class="file-drop-zone-with"
    [ngClass]="{ 'valid-drag': dropValid, 'invalid-drag': !dropValid }"
    [(validDrag)]="dropValid"
    [accept]="getAcceptedMimeTypes()"
    [attr.multiple]="multiple ? '1' : null"
    [(files)]="files"
    [(lastInvalids)]="lastInvalids"
    [maxSize]="maxSize"
    (filesChange)="filesChangeHandler($event)"
    >
    <span>{{
      files?.length === 1 && fileNameAsDropText
      ? files[0].name
      : dropText ||
      (translateService.get('GK.DYNAMIC_LIST.CONTROL_INPUT_FILE.DROP_TEXT')
      | async)
    }}</span>
    <button type="button" class="btn btn-color-from-db ms-2">
      {{ (getInputFileTranslations() | async)['CHOOSE_FILE'] }}
    </button>
  </div>
  @if (showPreviews && files.length) {
    <div class="angular-file-input-files">
      @for (file of files; track file; let i = $index) {
        <div
          class="angular-file-input-file"
          [ngClass]="{ image: file.type?.indexOf('image') !== -1 }"
          >
          <span
            [innerHTML]="file.name"
            class="angular-file-input-file-text"
          ></span>
          @if (file.type?.indexOf('image') !== -1) {
            <img
              [src]="getObjectUrl(file)"
              />
          }
          <span
            class="angular-file-input-file-text remove"
            (click)="removeFile(i)"
            >
            <p>{{ 'REMOVE' | translate }}</p>
          </span>
        </div>
      }
    </div>
  }
</div>
