import { MessageService } from '@progress/kendo-angular-l10n';
import { Injectable } from '@angular/core';

const fileselect = {
  'kendo.fileselect.dropFilesHere': 'Przeciągnij tutaj pliki, aby je załadować',
  'kendo.fileselect.invalidFileExtension': 'Nieprawidłowy typ pliku.',
  'kendo.fileselect.invalidMaxFileSize': 'Plik jest za duży.',
  'kendo.fileselect.invalidMinFileSize': 'Plik jest za mały.',
  'kendo.fileselect.remove': 'Usuń',
  'kendo.fileselect.select': 'Wybierz...',
};

// See here for more details https://github.com/telerik/kendo-angular-messages/tree/develop/messages
const messages: { [key: string]: string } = {
  ...{
    'kendo.grid.noRecords': 'Brak danych.',
    'kendo.grid.pagerOf': 'z',
    'kendo.grid.pagerItems': 'rekordów',
    'kendo.grid.pagerItemsPerPage': 'wierszy',
    'kendo.upload.cancel': 'Anuluj',
    'kendo.upload.clearSelectedFiles': 'Wyczyść',
    'kendo.upload.dropFilesHere': 'Przeciągnij tutaj pliki, aby je załadować',
    'kendo.upload.headerStatusUploaded': 'Zrobione',
    'kendo.upload.headerStatusUploading': 'Ładowanie...',
    'kendo.upload.invalidFileExtension': 'Nieprawidłowy typ pliku.',
    'kendo.upload.invalidMaxFileSize': 'Plik jest za duży.',
    'kendo.upload.invalidMinFileSize': 'Plik jest za mały.',
    'kendo.upload.remove': 'Usuń',
    'kendo.upload.retry': 'Ponów',
    'kendo.upload.select': 'Wybierz...',
    'kendo.upload.uploadSelectedFiles': 'Załaduj pliki',
    'kendo.upload.externalDropFilesHere':
      'Przeciągnij tutaj pliki, aby je przesłać',
    'kendo.upload.filesBatchStatus': 'pliki',
    'kendo.upload.filesBatchStatusFailed': 'pliki nieudane w ładowaniu.',
    'kendo.upload.filesBatchStatusUploaded': 'pliki pomyślnie przesłane.',
    'kendo.upload.fileStatusFailed': 'Błąd wysyłania pliku.',
    'kendo.upload.fileStatusUploaded': 'Plik przesłany pomyślnie.',
    'kendo.upload.headerStatusPaused': 'Wstrzymane',
  },
  ...fileselect,
};

@Injectable()
export class GkKendoMessageService extends MessageService {
  public override get(key: string): string {
    return messages[key];
  }
}
