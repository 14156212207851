<div
  [ngClass]="{ 'ms-4': isLeaf(providedData) }"
  class="tree-node"
  [hidden]="isRoot(providedData)"
>
  @if (providedData.children.length) {
    <button
      type="button"
      class="fa fa-lg fa-fw caret-button"
      [ngClass]="getOrientedCaretClass()"
      (click)="changeExpanded()"
    ></button>
  }
  <label class="checkbox-label">
    <input
      class="checkbox-input"
      type="checkbox"
      [(ngModel)]="providedData.selected"
      [indeterminate]="isPartiallySelected(providedData)"
      [disabled]="!providedData.selectable"
      (ngModelChange)="changeSelected($event)"
    />
    {{ providedData.translationKey | translate }}
  </label>
</div>
<div [hidden]="!providedData.expanded">
  <ul class="tree-node-children" [ngClass]="{ 'ms-3': !isRoot(providedData) }">
    @for (childNode of providedData.children; track childNode.id) {
      <li>
        <gk-tree-node
          [providedData]="childNode"
          (dispatch)="handleChildChange($event)"
        >
        </gk-tree-node>
      </li>
    }
  </ul>
</div>
