import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { documentIdValidator, getDefaultControlValue } from '@gk/gk-modules';
import { ModalDocumentVerificationFormControlName } from './modal-document-verification.form.model';

@Injectable()
export class DocumentVerificationFormService {
  constructor(private formBuilder: UntypedFormBuilder) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [ModalDocumentVerificationFormControlName.DocumentId]: [
        getDefaultControlValue(),
        [Validators.required, documentIdValidator()],
      ],
    });
  }
}
