import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { FileSaverService } from 'ngx-filesaver';
import { of } from 'rxjs';
import { finalize, takeWhile } from 'rxjs/operators';
import { getFileNameFromResponseHeaders } from '../../../../utils/utils';
import { DocumentsListControlConfig } from '../../../gk-dynamic-list.model';
import { ControlCommunicationService } from '../../../services/control-communication/control-communication.service';
import { DataService } from '../../../services/data/data.service';
import { DynamicListManagerService } from '../../../services/dynamic-list-manager/dynamic-list-manager.service';
import { DateHelperUtil } from '../../../utils/date-helper.util';
import { Control } from '../control';

@Component({
  selector: 'gk-control-documents-list',
  templateUrl: './control-documents-list.component.html',
  styleUrls: ['./control-documents-list.component.scss'],
})
export class ControlDocumentsListComponent
  extends Control<any>
  implements OnInit
{
  @Input()
  override controlConfig: DocumentsListControlConfig;
  documents: any[] = [];
  loading = false;
  documentToDelete: any;
  readonly spinnerName = 'control-documents-spinner';

  constructor(
    private ngbModal: NgbModal,
    private dynamicListManagerService: DynamicListManagerService,
    protected override translateService: TranslateService,
    protected override dataService: DataService,
    private fileSaverService: FileSaverService,
    override controlCommunicationService: ControlCommunicationService,
  ) {
    super(translateService, dataService, controlCommunicationService);
  }

  override ngOnInit(): void {
    this.getDocuments();
    this.subToRefreshEvent();
  }

  subToRefreshEvent(): void {
    this.dynamicListManagerService.refreshDocumentsListControl
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(() => this.getDocuments());
  }

  getDocuments(): void {
    this.loading = true;
    (this.controlConfig.pathToDocumentsList
      ? of(_.get(this.data, this.controlConfig.pathToDocumentsList, []))
      : this.dataService.getData(
          this.controlConfig.getItemsRequestConfig,
          this.data,
        )
    )
      .pipe(
        takeWhile(() => this.isAlive),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe({
        next: (docs) => {
          this.documents = docs;
          this.updateDataArrayCountIfNeed();
        },
        error: (err) => console.error(err),
      });
  }

  updateDataArrayCountIfNeed(): void {
    if (this.controlConfig.pathToDataArrayCountUpdate) {
      this.controlCommunicationService.setDataArrayCount(
        this.controlConfig.pathToDataArrayCountUpdate,
        this.data.Uuid,
        this.documents.length,
      );
    }
  }

  getFileType(doc: any): string {
    return _.get(doc, this.controlConfig.pathToFileType);
  }

  getFileFullName(doc: any): string {
    return _.get(doc, this.controlConfig.pathToFileFullName);
  }

  getFileDescription(doc: any): string {
    return _.get(doc, this.controlConfig.pathToFileDescription);
  }

  getFileSize(doc: any): string {
    return _.get(doc, this.controlConfig.pathToFileSize);
  }

  getFileAddedDate(doc: any): string {
    return _.get(doc, this.controlConfig.pathToFileAddedDate);
  }

  getUiDate(apiDate: string): string {
    return DateHelperUtil.apiToUiDate(apiDate);
  }

  shouldDisableRemoveFile(): boolean {
    return !this.controlConfig.canDelete;
  }

  shouldHideRemoveFile(): boolean {
    return this.controlConfig.hideDeleteButton;
  }

  shouldShowBlockedDocumentsLegend(): boolean {
    return !!(
      this.controlConfig.pathToBlockProperty &&
      this.documents.some(
        (document) => document[this.controlConfig.pathToBlockProperty],
      )
    );
  }

  shouldDisableDownloadFile(document?: any): boolean {
    return (
      !this.controlConfig.canDownload ||
      !!(
        this.controlConfig.canDownload &&
        this.controlConfig.pathToBlockProperty &&
        document &&
        _.get(document, this.controlConfig.pathToBlockProperty)
      )
    );
  }

  handleRemoveFileResponse = (): void => {
    this.loading = false;
    this.getDocuments();
  };

  openAndReturnModalRef(
    modalTemplate: TemplateRef<NgbModalRef>,
  ): Promise<NgbModalRef> {
    return this.ngbModal.open(modalTemplate).result;
  }

  removeFile(doc: any, modalTemplate: TemplateRef<NgbModalRef>): void {
    this.documentToDelete = doc;

    this.openAndReturnModalRef(modalTemplate).then(
      () => {
        this.loading = true;
        this.dataService
          .getData(this.controlConfig.deleteItemRequestConfig, doc)
          .pipe(takeWhile(() => this.isAlive))
          .subscribe({
            next: this.handleRemoveFileResponse,
            error: this.handleRemoveFileResponse,
          });
      },
      (err) => {
        console.error(err);
      },
    );
  }

  downloadFile(doc: any): void {
    this.loading = true;
    this.dataService
      .getData(this.controlConfig.downloadItemRequestConfig, doc)
      .pipe(
        takeWhile(() => this.isAlive),
        finalize(() => (this.loading = false)),
      )
      .subscribe({
        next: (response) => {
          const docFile = response?.body ? response.body : response;
          const name = response.headers
            ? getFileNameFromResponseHeaders(response.headers)
            : this.getFileFullName(doc);
          this.fileSaverService.save(docFile, name);
        },
        error: (err) => console.error(err),
      });
  }
}
