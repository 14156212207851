@if (navItemConfig.show) {
  @switch (navItemConfig.type) {
    @case (navItemTypeEnum.LogoImage) {
      <gk-nav-item-logo-image
        [logoImageNavItemConfig]="$any(navItemConfig)"
        [navbarConfig]="navbarConfig"
      ></gk-nav-item-logo-image>
    }
    @case (navItemTypeEnum.LogoImageText) {
      <gk-nav-item-logo-image-text
        [logoImageTextNavItemConfig]="$any(navItemConfig)"
        [navbarConfig]="navbarConfig"
      ></gk-nav-item-logo-image-text>
    }
    @case (navItemTypeEnum.SimpleText) {
      <gk-nav-item-simple-text
        [simpleTextNavItemConfig]="$any(navItemConfig)"
        [navbarConfig]="navbarConfig"
      ></gk-nav-item-simple-text>
    }
    @case (navItemTypeEnum.Link) {
      <gk-nav-item-link
        [linkNavItemConfig]="$any(navItemConfig)"
        [navbarConfig]="navbarConfig"
      ></gk-nav-item-link>
    }
    @case (navItemTypeEnum.Dropdown) {
      <gk-nav-item-dropdown
        [dropdownNavItemConfig]="$any(navItemConfig)"
        [navbarConfig]="navbarConfig"
      ></gk-nav-item-dropdown>
    }
    @case (navItemTypeEnum.ActionButton) {
      <gk-nav-item-action-button
        [actionButtonNavItemConfig]="$any(navItemConfig)"
        [navbarConfig]="navbarConfig"
        [dropdownConfig]="dropdownConfig"
      ></gk-nav-item-action-button>
    }
    @case (navItemTypeEnum.AudioDescriptionToggleButton) {
      <gk-nav-item-audio-description-toggle-button
        [audioDescriptionToggleButtonNavItemConfig]="$any(navItemConfig)"
        [navbarConfig]="navbarConfig"
      ></gk-nav-item-audio-description-toggle-button>
    }
    @case (navItemTypeEnum.InfoServiceButton) {
      <gk-nav-item-info-service-button
        [infoServiceButtonNavItemConfig]="$any(navItemConfig)"
        [navbarConfig]="navbarConfig"
      ></gk-nav-item-info-service-button>
    }
    @default {
      <gk-nav-base [navbarConfig]="navbarConfig"></gk-nav-base>
    }
  }
}
