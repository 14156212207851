<button
  [disabled]="actionPending || requestSuccessfullySigned"
  class="btn btn-sm btn-color-from-db d-inline"
  (click)="handleButtonClickAction()"
  >
  @if (!actionPending && !requestSuccessfullySigned) {
    <span>{{
      button.label
    }}</span>
  }
  @if (actionPending && !requestSuccessfullySigned) {
    <span
      ><i class="fa fa-spinner fa-pulse fa-fw"></i>
      @if (button.trustedProfileSignRequired) {
        <span>{{
          'TRUSTED_PROFILE.WAITING_FOR_SIGN' | translate
        }}</span>
        }</span
        >
      }
      @if (requestSuccessfullySigned) {
        <span>{{
          button.successLabel || button.label
        }}</span>
      }
    </button>
