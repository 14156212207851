import { SelectItem } from '../../../services';

export enum GMPPrintTemplate {
  VerticalA4 = 'verticalA4',
  HorizontalA4 = 'horizontalA4',
  VerticalA3 = 'verticalA3',
  HorizontalA3 = 'horizontalA3',
  VerticalA2 = 'verticalA2',
  HorizontalA2 = 'horizontalA2',
}

// TODO: Handle other templates
export const gmpPrintTemplateSelectItems = [
  new SelectItem(
    GMPPrintTemplate.VerticalA4,
    'GK.MAP.PRINT.ATTRIBUTES_FORM.PRINT_TEMPLATE.VERTICAL.A4',
  ),
  // new SelectItem(
  //   GMPPrintTemplate.HorizontalA4,
  //   'GK.MAP.PRINT.ATTRIBUTES_FORM.PRINT_TEMPLATE.HORIZONTAL.A4',
  // ),
  // new SelectItem(
  //   GMPPrintTemplate.VerticalA3,
  //   'GK.MAP.PRINT.ATTRIBUTES_FORM.PRINT_TEMPLATE.VERTICAL.A3',
  // ),
  // new SelectItem(
  //   GMPPrintTemplate.HorizontalA3,
  //   'GK.MAP.PRINT.ATTRIBUTES_FORM.PRINT_TEMPLATE.HORIZONTAL.A3',
  // ),
  // new SelectItem(
  //   GMPPrintTemplate.VerticalA2,
  //   'GK.MAP.PRINT.ATTRIBUTES_FORM.PRINT_TEMPLATE.VERTICAL.A2',
  // ),
  // new SelectItem(
  //   GMPPrintTemplate.HorizontalA2,
  //   'GK.MAP.PRINT.ATTRIBUTES_FORM.PRINT_TEMPLATE.HORIZONTAL.A2',
  // ),
];
