import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { getFileNameFromResponseHeaders } from '@gk/gk-modules';
import { FileSaverService } from 'ngx-filesaver';
import { Subscription, takeWhile, tap } from 'rxjs';
import { DocumentIdVerificationService } from '../../../../../../../services/document-id-verification/document-id-verification.service';
import { DocumentVerificationFormService } from './document-verification.form.service';
import { ModalDocumentVerificationFormControlName } from './modal-document-verification.form.model';

@Component({
  selector: 'app-document-verification-form',
  templateUrl: './document-verification-form.component.html',
})
export class DocumentVerificationFormComponent implements OnInit {
  private isAlive = true;
  @Output() documentNotFoundEvent = new EventEmitter<boolean>();
  formGroup: UntypedFormGroup;
  modalDocumentVerificationFormControlName =
    ModalDocumentVerificationFormControlName;
  documentVerifyingInProgress = false;
  documentIdVerificationSubscription: Subscription;
  submitted: boolean;

  constructor(
    private documentVerificationFormService: DocumentVerificationFormService,
    private fileSaverService: FileSaverService,
    private documentIdVerificationService: DocumentIdVerificationService,
  ) {}

  ngOnInit(): void {
    this.documentVerifyingInProgress = false;
    this.documentNotFoundEvent.emit(false);
    this.createForm();
  }

  createForm(): void {
    this.formGroup = this.documentVerificationFormService.getFormGroup();
  }

  checkFormGroupInvalidAndDirty(): boolean {
    return this.formGroup.invalid && this.formGroup.dirty;
  }

  checkFormGroupValidAndDirty(): boolean {
    return this.formGroup.valid && this.formGroup.dirty;
  }

  handleSubmit(): void {
    this.submitted = true;
    this.documentVerifyingInProgress = true;
    this.documentIdVerificationSubscription = this.documentIdVerificationService
      .verifyDocumentId(this.getDocumentIdFormControl().value, false)
      .pipe(
        takeWhile(() => this.isAlive),
        tap(() => {
          this.documentVerifyingInProgress = false;
        }),
      )
      .subscribe(
        (webRequestHttpResponse) =>
          this.handleDocumentVerificationSuccess(webRequestHttpResponse),
        () => this.handleDocumentVerificationSuccessFailure(),
      );
  }

  getFormControlByName(formControlName: string): AbstractControl {
    return this.formGroup.get(formControlName);
  }

  getDocumentIdFormControl(): UntypedFormControl {
    return this.getFormControlByName(
      this.modalDocumentVerificationFormControlName.DocumentId,
    ) as UntypedFormControl;
  }

  handleDocumentVerificationSuccess(
    webRequestHttpResponse: HttpResponse<Blob>,
  ): void {
    const fileName = getFileNameFromResponseHeaders(
      webRequestHttpResponse.headers,
    );
    this.fileSaverService.save(webRequestHttpResponse.body, fileName);
  }

  handleDocumentVerificationSuccessFailure(): void {
    this.documentVerifyingInProgress = false;
    this.documentNotFoundEvent.emit(true);
  }

  onOk(): void {
    this.documentNotFoundEvent.emit(false);
  }
}
