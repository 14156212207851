<form [formGroup]="formGroup" novalidate>
  <section>
    <div class="alert alert-info">
      <div class="card-body">
        <div class="form-check form-check-inline">
          <label
            class="form-check-label"
            [attr.for]="controlName.RodoStatement"
          >
            {{ rodoText }}
          </label>

          <input
            type="checkbox"
            [id]="controlName.RodoStatement"
            [attr.name]="controlName.RodoStatement"
            [formControlName]="controlName.RodoStatement"
            [value]="true"
            class="form-check-input"
          />
        </div>
      </div>

      @if (!isRodoStatementFormControlValid() && submitted) {
        <div class="card-footer">
          <div class="alert alert-danger mt-2">
            {{ 'FIELD_REQUIRED' | translate }}
          </div>
        </div>
      }
    </div>
  </section>
</form>
