import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainRoutes } from '../../guards/guard.models';
import { SessionGuard } from '../../guards/session/session.guard';
import { MainRequestsListComponent } from '../../shared/main-requests-list/main-requests-list.component';
import { NewPropertyLeaseRequestComponent } from './new-property-lease-request/new-property-lease-request.component';
import { PropertyLeasePortalComponent } from './property-lease-portal.component';

const propertyLeasePortalRoutes: Routes = [
  {
    path: MainRoutes.PropertyLease,
    component: PropertyLeasePortalComponent,
    canActivate: [SessionGuard],
    children: [
      {
        path: '',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
      {
        path: 'new-request',
        component: NewPropertyLeaseRequestComponent,
      },
      {
        path: 'requests-list',
        component: MainRequestsListComponent,
      },
      {
        path: '**',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(propertyLeasePortalRoutes)],
  providers: [],
  exports: [RouterModule],
})
export class PropertyLeasePortalRoutingModule {}
