import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

export class ModalUtils {
  static getModalOptions(): NgbModalOptions {
    return { size: 'lg', centered: true, beforeDismiss: () => false };
  }

  static getCustomXlModalOptions(): NgbModalOptions {
    return {
      size: 'xl',
      centered: true,
      beforeDismiss: () => false,
      windowClass: 'modal-custom-size-xl',
    };
  }

  static addZIndexModalOptions(
    additionalOptions: NgbModalOptions = {},
  ): NgbModalOptions {
    return {
      ...additionalOptions,
      windowClass: `${additionalOptions.windowClass || ''} modal-custom-z-above-surveyor-window`,
      backdropClass: `${additionalOptions.backdropClass || ''} modal-custom-z-above-surveyor-window`,
    };
  }
}
