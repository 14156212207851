import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { filter, tap } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Place } from '../../../gk-dynamic-form/services/place/place.model';
import { PlaceService } from '../../../gk-dynamic-form/services/place/place.service';
import { GkPostalCode } from '../../../gk-dynamic-form/services/postal-code/postal-code.model';
import { Street } from '../../../gk-dynamic-form/services/street/street.model';
import { StreetService } from '../../../gk-dynamic-form/services/street/street.service';
import { ApiFilter } from '../../../gk-dynamic-list/services/services.model';
import {
  FormFieldType,
  FormFieldWrappers,
  InputAutocompleteProps,
} from '../custom-types/model';

export enum AddressSearchFormFieldGroupControlName {
  AddressSearch = 'addressSearch',
  Place = 'place',
  Street = 'street',
  BuildingNumber = 'buildingNumber',
  PremisesNumber = 'premisesNumber',
  PostalCode = 'postalCode',
}

export class AddressSearchFormFieldGroupFormModel {
  constructor(
    public place: Place,
    public street: Street,
    public buildingNumber: string,
    public premisesNumber: string,
    public postalCode: GkPostalCode,
  ) {}

  static getApiFilter(
    parcelAddress: AddressSearchFormFieldGroupFormModel,
  ): ApiFilter {
    return new ApiFilter('Adres', {
      MiejscowoscId: parcelAddress?.place?.id,
      UlicaId: parcelAddress?.street?.id,
      NumerBudynku: parcelAddress?.buildingNumber,
      NumerLokalu: parcelAddress?.premisesNumber,
      Kod: parcelAddress?.postalCode?.name,
    });
  }
}

export const getAddressSearchFormFieldGroup = (
  placeService: PlaceService,
  streetService: StreetService,
): FormlyFieldConfig => ({
  key: AddressSearchFormFieldGroupControlName.AddressSearch,
  wrappers: [FormFieldWrappers.KendoPanel],
  props: {
    label: 'GK.DYNAMIC_FORM.ADDRESS',
  },
  fieldGroup: [
    {
      fieldGroupClassName: 'd-flex flex-row column-gap-1',
      fieldGroup: [
        {
          key: AddressSearchFormFieldGroupControlName.Place,
          type: FormFieldType.KendoCombobox,
          className: 'flex-1',
          wrappers: [FormFieldWrappers.FormField],
          props: {
            label: 'GK.DYNAMIC_FORM.PLACE',
            textField: 'name',
            valueField: 'id',
            placeholder: 'np. Głowaczewo',
            onFilterChange: (
              filter: string,
              field: FieldTypeConfig<InputAutocompleteProps>,
            ): void => {
              field.props.loading = true;
              placeService
                .getPlacesByTerm(
                  filter,
                  '/api/emuia/miejscowosci/local/cachedautocomplete',
                )
                .subscribe((places) => {
                  field.props.options = places;
                  field.options.detectChanges(field);
                  field.props.loading = false;
                });
            },
          },
        },
        {
          key: AddressSearchFormFieldGroupControlName.Street,
          type: FormFieldType.KendoCombobox,
          className: 'flex-1',
          wrappers: [FormFieldWrappers.FormField],
          props: {
            label: 'GK.DYNAMIC_FORM.STREET',
            textField: 'name',
            valueField: 'id',
            onFilterChange: (
              filter: string,
              field: FieldTypeConfig<InputAutocompleteProps>,
            ): void => {
              if (field.parent.model?.place?.id) {
                field.props.loading = true;
                streetService
                  .getStreets(
                    filter,
                    field.parent.model?.place?.id,
                    '/api/emuia/ulice/cachedautocomplete',
                  )
                  .subscribe((streets) => {
                    console.log('streets', streets);
                    field.props.options = streets;
                    field.options.detectChanges(field);
                    field.props.loading = false;
                  });
              }
            },
          },
          expressions: {
            //https://github.com/ngx-formly/ngx-formly/issues/704#issuecomment-364661614
            'props.disabled': (field: FormlyFieldConfig): boolean => {
              return !field.parent.parent.parent.model?.addressSearch?.place;
            },
          },
          hooks: {
            onInit: (field): void => {
              const placeControl = field.parent.get('place').formControl;
              field.props.options = placeControl.valueChanges.pipe(
                tap(() => {
                  field.formControl.setValue(null);
                }),
                filter(Boolean),
                tap((val) => {
                  console.log('val', val);
                }),
                switchMap((place) => {
                  return streetService.getStreets(
                    '',
                    place?.id,
                    '/api/emuia/ulice/cachedautocomplete',
                  );
                }),
              );
            },
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'd-flex flex-row column-gap-1',
      fieldGroup: [
        {
          key: AddressSearchFormFieldGroupControlName.BuildingNumber,
          type: FormFieldType.KendoInput,
          className: 'flex-1',
          wrappers: [FormFieldWrappers.FormField],
          props: {
            label: 'GK.DYNAMIC_FORM.BUILDING_NUMBER',
          },
        },
        {
          key: AddressSearchFormFieldGroupControlName.PremisesNumber,
          type: FormFieldType.KendoInput,
          className: 'flex-1',
          wrappers: [FormFieldWrappers.FormField],
          props: {
            label: 'GK.DYNAMIC_FORM.PREMISES_NUMBER',
          },
        },
        {
          key: AddressSearchFormFieldGroupControlName.PostalCode,
          type: FormFieldType.KendoInput,
          className: 'flex-1',
          wrappers: [FormFieldWrappers.FormField],
          props: {
            label: 'GK.DYNAMIC_FORM.POSTAL_CODE',
          },
        },
      ],
    },
  ],
});
