import { Component, ChangeDetectionStrategy } from '@angular/core';
import { MapControl } from '../../../controls';
import { scales } from '../../../configs';
import {
  Scale,
  MapAction,
  MapViewActionType,
  SourceType,
  ToolType
} from '../../../models';
import * as _ from 'lodash';

@Component({
  selector: 'gk-map-toolbar',
  templateUrl: './map-toolbar.component.html',
  styleUrls: ['./map-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapToolbarComponent extends MapControl {
  generalScales: Scale[] = [...scales];

  handleGeneralResolutionChange(resolution: number): void {
    this.dispatch.emit(new MapAction(MapViewActionType.ResolutionChange, resolution));
  }

  shouldShowMapToolContainer(toolType: ToolType, sourceType: SourceType): boolean {
    return (
      _.get(this.mapState, `toolsState[${toolType}][${sourceType}].isActive`, false) &&
      _.get(
        this.mapState,
        `toolsState[${toolType}][${sourceType}].hasMapToolContainer`,
        false
      )
    );
  }
}
