<div class="modal-header">
  <h4 class="modal-title">
    {{ 'GK.USER_SETTINGS.WEB_AUTHN_SETTINGS.HEADER' | translate }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="close"
    (click)="close()"
  ></button>
</div>
<div class="modal-body">
  <button
    type="button"
    class="btn btn-color-from-db mb-2 col-lg-2 col-sm-6 col-12"
    (click)="addKey()"
    [attr.disabled]="
      (tfaService.isTfaWebAuthnInOperation() | async) ? true : null
    "
    >
    @if (tfaService.isTfaWebAuthnInOperation() | async) {
      <i
        class="fa fa-spinner fa-pulse fa-fw"
      ></i>
    } @else {
      <span class="text-nowrap">{{
        'GK.USER_SETTINGS.WEB_AUTHN_SETTINGS.ADD_KEY' | translate
      }}</span>
    }
  </button>
  <div id="credentials-table-wrapper" class="table-responsive">
    <table
      class="table table-sm table-bordered table-hover no-select text-center"
      >
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">
            <span>{{
              'GK.USER_SETTINGS.WEB_AUTHN_SETTINGS.ID' | translate
            }}</span>
          </th>
          <th scope="col">
            <span>{{
              'GK.USER_SETTINGS.WEB_AUTHN_SETTINGS.ADDED_DATE' | translate
            }}</span>
          </th>
          <th scope="col">
            <span>{{ 'REMOVE' | translate }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        @if (
          !(
          tfaWebAuthnUserCredentialsService.tfaWebAuthnUserCredentials
          | async
          )?.credentialIds
          ) {
          <tr
            aria-required="true"
            >
            <td colspan="4"><i class="fa fa-spinner fa-pulse fa-fw"></i></td>
          </tr>
        }
        @for (
          credential of (
          tfaWebAuthnUserCredentialsService.tfaWebAuthnUserCredentials
          | async
          )?.credentialIds; track
          credential; let i = $index) {
          <tr
            >
            <td>{{ i + 1 }}</td>
            <td>
              {{ credential.label }}
            </td>
            <td class="text-nowrap">
              {{ credential.addedDate }}
            </td>
            <td>
              <button
                type="button"
                class="btn btn-danger btn-sm"
                [attr.aria-label]="'REMOVE' | translate"
              [attr.disabled]="
                (isDeletionInProgress(credential.id) | async) ? true : null
              "
                (click)="deleteCredential(credential)"
                >
                <i
                [ngClass]="
                  (isDeletionInProgress(credential.id) | async)
                    ? 'fa fa-spinner fa-pulse fa-fw'
                    : 'gk-map-icon-remove'
                "
                ></i>
              </button>
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>
</div>
