import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NavBaseComponent } from '../nav-base/nav-base.component';
import { AudioDescriptionToggleButtonNavItemConfig } from '../../gk-dynamic-navbar.model';

@Component({
  selector: 'gk-nav-item-audio-description-toggle-button',
  templateUrl: './nav-item-audio-description-toggle-button.component.html',
  styleUrls: ['./nav-item-audio-description-toggle-button.component.scss']
})
export class NavItemAudioDescriptionToggleButtonComponent extends NavBaseComponent
  implements OnInit {
  @Input()
  audioDescriptionToggleButtonNavItemConfig: AudioDescriptionToggleButtonNavItemConfig;
  toggled = false;
  @ViewChild('audioElementRef') audioElementRef: ElementRef;

  ngOnInit(): void {
    if (this.audioDescriptionToggleButtonNavItemConfig.autoPlay) {
      this.toggleAudio();
    }
  }

  toggleAudio(): void {
    const audioNativeElement = this.audioElementRef.nativeElement;
    this.toggled = !this.toggled;

    this.toggled ? audioNativeElement.play() : audioNativeElement.pause();
  }
}
