import * as _ from 'lodash';
import { Paper, PaperFromApi } from './map-state.model';

export enum ProjectionCode {
  PseudoMercator = 'EPSG:3857',
  Pl2000zone5 = 'EPSG:2176',
  Pl2000zone6 = 'EPSG:2177',
  Pl2000zone7 = 'EPSG:2178',
  Pl2000zone8 = 'EPSG:2179',
}

export enum ProjectionCodeDefinition {
  Pl2000zone5 = '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.999923 +x_0=5500000 +y_0=0 +ellps=GRS80 +units=m +no_defs',
  Pl2000zone6 = '+proj=tmerc +lat_0=0 +lon_0=18 +k=0.999923 +x_0=6500000 +y_0=0 +ellps=GRS80 +units=m +no_defs',
  Pl2000zone7 = '+proj=tmerc +lat_0=0 +lon_0=21 +k=0.999923 +x_0=7500000 +y_0=0 +ellps=GRS80 +units=m +no_defs',
  Pl2000zone8 = '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.999923 +x_0=8500000 +y_0=0 +ellps=GRS80 +units=m +no_defs',
}

export enum StorageSuffix {
  MapExtent = 'map-extent',
  Resolution = 'resolution',
  ProjectionCode = 'projection-code',
  Layers = 'layers',
  LayersTree = 'layers-tree-v2',
}

export interface AdministrativeUnitReferenceFromApi {
  lowX: number;
  lowY: number;
  highX: number;
  highY: number;
  epsg: number;
  nameX?: number;
  nameY?: number;
}

export class AdministrativeUnitReference {
  constructor(
    public lowX: number,
    public lowY: number,
    public highX: number,
    public highY: number,
    public epsg: number,
    public nameX?: number,
    public nameY?: number,
  ) {}

  static fromApiToApp(
    mapExtentFromApi: AdministrativeUnitReferenceFromApi,
  ): AdministrativeUnitReference {
    return new this(
      mapExtentFromApi.lowX,
      mapExtentFromApi.lowY,
      mapExtentFromApi.highX,
      mapExtentFromApi.highY,
      mapExtentFromApi.epsg,
      mapExtentFromApi.nameX,
      mapExtentFromApi.nameY,
    );
  }
}

export interface MapSettingsFromApi {
  extent: AdministrativeUnitReferenceFromApi;
  layers: string;
  layersGroups: TreeNodeLiteral[];
  papers: PaperFromApi[];
}

export class MapSettings {
  constructor(
    public administrativeUnitReference: AdministrativeUnitReference,
    public layers: string,
    public layersGroups: TreeNode[],
    public papers?: Paper[],
  ) {}

  static fromApiToApp(mapSettingsFromApi: MapSettingsFromApi): MapSettings {
    return new this(
      AdministrativeUnitReference.fromApiToApp(mapSettingsFromApi.extent),
      mapSettingsFromApi.layers,
      mapSettingsFromApi.layersGroups.map((layersGroup) =>
        TreeNode.literalToInstance(layersGroup),
      ),
      (mapSettingsFromApi.papers || []).map((paper) =>
        Paper.fromApiToApp(paper),
      ),
    );
  }
}

export class TreeNode {
  id: string;

  constructor(
    public namespace: string,
    public label: string,
    public translationKey: string,
    public name: string,
    public children: TreeNode[] = [],
    public url: string = undefined,
    public selectable = true,
    public expanded = false,
    public selected = !TreeNode.isExternal(url),
  ) {
    this.id = `${this.namespace}-${this.name}`;
  }

  static isExternal(nodeUrl: string): boolean {
    return !!nodeUrl;
  }

  static literalToInstance(treeNodeLiteral: TreeNodeLiteral): TreeNode {
    return new this(
      treeNodeLiteral.namespace,
      treeNodeLiteral.label,
      `GK.MAP.LAYERS_TREE.${treeNodeLiteral.namespace.toUpperCase()}.${(
        treeNodeLiteral.label || treeNodeLiteral.name
      ).toUpperCase()}`,
      treeNodeLiteral.name,
      (treeNodeLiteral.children || []).map((child) =>
        this.literalToInstance(child),
      ),
      treeNodeLiteral.url,
      treeNodeLiteral.selectable,
      treeNodeLiteral.expanded,
      treeNodeLiteral.selected,
    );
  }

  static buildRootNode(layersGroups: TreeNode[]): TreeNode {
    return new this(
      'root',
      'root',
      undefined,
      'root',
      layersGroups,
      undefined,
      true,
      true,
    );
  }

  static getUpdatedProperTreeNode(
    currentTreeNode: TreeNode,
    properTreeNode: TreeNode,
  ): TreeNode {
    return this.getTreeNodeById(currentTreeNode, properTreeNode.id)
      ? new this(
          currentTreeNode.namespace,
          currentTreeNode.label,
          currentTreeNode.translationKey,
          currentTreeNode.name,
          properTreeNode.children.map((child) =>
            this.getUpdatedProperTreeNode(
              this.getTreeNodeById(currentTreeNode, child.id) || child,
              child,
            ),
          ),
          currentTreeNode.url,
          currentTreeNode.selectable,
          currentTreeNode.expanded,
          currentTreeNode.selected,
        )
      : properTreeNode;
  }

  static getTreeNodeById(treeNode: TreeNode, id: string): TreeNode {
    if (treeNode.id === id) {
      return treeNode;
    }

    return treeNode.children.find((childTreeNode) =>
      _.isEqual(childTreeNode, this.getTreeNodeById(childTreeNode, id)),
    );
  }

  static getSelectedLeafNames(treeNode: TreeNode): string[] {
    if (!treeNode.children.length) {
      return [treeNode.selected ? treeNode.name : ''];
    }

    return treeNode.children.reduce(
      (prevNodes, currentNode) => [
        ...prevNodes,
        ...(this.getSelectedLeafNames(currentNode) as string[]),
      ],
      [],
    );
  }
}

export interface TreeNodeLiteral {
  namespace: string;
  label: string;
  name: string;
  url: string;
  children?: TreeNodeLiteral[];
  selectable?: boolean;
  expanded?: boolean;
  selected?: boolean;
}

export class TreeNodeAction {
  constructor(
    public type: TreeNodeActionType,
    public payload: TreeNode,
  ) {}
}

export enum TreeNodeActionType {
  ChangeSelected = 'changeSelected',
  ChangeExpanded = 'changeExpanded',
}

export enum DescendantsSelectionState {
  NoneSelected = 0,
  PartiallySelected = 1,
  AllSelected = 2,
}
