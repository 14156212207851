import { Component, Input } from '@angular/core';
import * as _ from 'lodash';
import {
  FilterAndRenderControlConfig,
  FilterCondition,
  FilterLogicOperator,
  FilterRule,
} from '../../../gk-dynamic-list.model';
import { Control } from '../control';

@Component({
  selector: 'gk-control-filter-and-render',
  templateUrl: './control-filter-and-render.component.html',
})
export class ControlFilterAndRenderComponent extends Control<any[]> {
  @Input()
  override controlConfig: FilterAndRenderControlConfig;

  doesElementMatchFilterRule(
    apiDataArrayElement: any,
    filterRule: FilterRule
  ): boolean {
    const propertyToCheck = filterRule.pathToPropertyToCheck
      ? _.get(apiDataArrayElement, filterRule.pathToPropertyToCheck)
      : apiDataArrayElement;

    switch (filterRule.filterCondition) {
      case FilterCondition.Equals:
        return _.isEqual(propertyToCheck, filterRule.rightOperand);
    }
  }

  getElementChecks(apiDataArrayElement: any): boolean[] {
    return this.controlConfig.filterRules.map((filterRule) =>
      this.doesElementMatchFilterRule(apiDataArrayElement, filterRule)
    );
  }

  getResultOfComposedChecksWithFilterLogicOperator(checks: boolean[]): boolean {
    switch (this.controlConfig.logicBetweenRules) {
      case FilterLogicOperator.And:
        return checks.every((check) => check);
      case FilterLogicOperator.Or:
        return checks.some((check) => check);
    }
  }

  getFilteredData(): any[] {
    const dataToFilter = this.getValue();
    if (!_.isArray(dataToFilter) || _.isEmpty(dataToFilter)) {
      return [];
    }
    if (_.isEmpty(this.controlConfig.filterRules)) {
      return dataToFilter;
    }

    return dataToFilter.filter((apiDataArrayElement) =>
      this.getResultOfComposedChecksWithFilterLogicOperator(
        this.getElementChecks(apiDataArrayElement)
      )
    );
  }

  getDataWithFilteredData(): any {
    return {
      ...this.data,
      [this.controlConfig.resultPropertyPath]: this.getFilteredData(),
    };
  }
}
