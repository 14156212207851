import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiFilter,
  ApiListResponse,
  EgibObject,
  EgibObjectFromApi,
  FilterLogic,
  FilterWrapper,
  RequestBodyForList,
} from '@gk/gk-modules';
import * as _ from 'lodash';
import {
  Observable,
  Subject,
  catchError,
  map,
  of as observableOf,
  switchMap,
} from 'rxjs';
import {
  AdvancedSearchParcelFormModel,
  ParcelAddress,
  SearchParcelFormModel,
} from './parcel.model';

@Injectable()
export class ParcelService {
  parcelSearchFormRequest = new Subject<SearchParcelFormModel>();
  parcelSearchFormResponse = new Subject<EgibObject[]>();
  advancedParcelSearchFormRequest = new Subject<SearchParcelFormModel>();
  advancedParcelSearchFormResponse = new Subject<EgibObject[]>();

  constructor(private httpClient: HttpClient) {
    this.listenToParcelSearchFormValueChanged();
    this.listenToAdvancedParcelSearchFormValueChanged();
  }

  private prepareFiltersForParcelSearchForm(
    searchParcelFormValue: SearchParcelFormModel
  ): ApiFilter[] {
    const { communityId, districtId, sheetId, parcelNumber } =
      searchParcelFormValue;
    const filterArray: ApiFilter[] = [];

    if (communityId) {
      filterArray.push(new ApiFilter('GminaId', communityId));
    }

    if (districtId) {
      filterArray.push(new ApiFilter('ObrebId', districtId));
    }

    if (sheetId) {
      filterArray.push(new ApiFilter('ArkuszId', sheetId));
    }

    if (parcelNumber && !_.isEmpty(parcelNumber)) {
      const { nominator, denominator, additionalNumber } = parcelNumber;

      if (nominator) {
        filterArray.push(new ApiFilter('NrL', nominator));
      }

      if (denominator) {
        filterArray.push(new ApiFilter('NrM', denominator));
      }

      if (additionalNumber) {
        filterArray.push(new ApiFilter('NrAdd', additionalNumber));
      }
    }

    return filterArray;
  }

  private prepareFiltersForAdvancedParcelSearchForm(
    searchParcelFormValue: AdvancedSearchParcelFormModel
  ): ApiFilter[] {
    const { communityId, districtId, parcelAddress, parcelNumber } =
      searchParcelFormValue;
    const filterArray: ApiFilter[] = [];

    if (communityId) {
      filterArray.push(new ApiFilter('GminaId', communityId));
    }

    if (districtId) {
      filterArray.push(new ApiFilter('ObrebId', districtId));
    }

    if (parcelNumber && !_.isEmpty(parcelNumber)) {
      const { nominator, denominator } = parcelNumber;

      if (nominator) {
        filterArray.push(new ApiFilter('NrL', nominator));
      }

      if (denominator) {
        filterArray.push(new ApiFilter('NrM', denominator));
      }
    }

    if (parcelAddress && !_.isEmpty(parcelAddress)) {
      filterArray.push(this.getParcelAddressFilter(parcelAddress));
    }

    return filterArray;
  }

  private getParcelAddressFilter(parcelAddress: ParcelAddress): ApiFilter {
    return new ApiFilter('Adres', {
      MiejscowoscId: _.get(parcelAddress, 'place.id') || null,
      UlicaId: _.get(parcelAddress, 'street.id') || null,
      NumerBudynku: _.get(parcelAddress, 'ordinalNumber') || null,
      NumerLokalu: _.get(parcelAddress, 'localNumber') || null,
      Kod: _.get(parcelAddress, 'postalCode.name') || null,
    });
  }

  private listenToParcelSearchFormValueChanged(): void {
    this.parcelSearchFormRequest
      .pipe(switchMap((formValue) => this.getParcelsByFilter(formValue)))
      .subscribe((parcels) => this.parcelSearchFormResponse.next(parcels));
  }

  private listenToAdvancedParcelSearchFormValueChanged(): void {
    this.advancedParcelSearchFormRequest
      .pipe(
        switchMap((formValue) => this.getAdvancedParcelsByFilter(formValue))
      )
      .subscribe((parcels) =>
        this.advancedParcelSearchFormResponse.next(parcels)
      );
  }

  private getParcelsByFilter(
    searchParcelFormValue: SearchParcelFormModel
  ): Observable<EgibObject[]> {
    const filters: ApiFilter[] = this.prepareFiltersForParcelSearchForm(
      searchParcelFormValue
    );
    const postBody = new RequestBodyForList(
      new FilterWrapper(FilterLogic.And, filters)
    );

    return this.httpClient
      .post<ApiListResponse<EgibObjectFromApi>>(
        '/api/zudp/projektant/dzialki/search',
        postBody
      )
      .pipe(
        map((data) => data.Response),
        map((egibObjectsFromApi) =>
          egibObjectsFromApi.map((egibObjectFromApi) =>
            EgibObject.fromApiToApp(egibObjectFromApi)
          )
        ),
        catchError(() => observableOf([]))
      );
  }

  private getAdvancedParcelsByFilter(
    searchParcelFormValue: AdvancedSearchParcelFormModel
  ): Observable<EgibObject[]> {
    const filters: ApiFilter[] = this.prepareFiltersForAdvancedParcelSearchForm(
      searchParcelFormValue
    );
    const postBody = new RequestBodyForList(
      new FilterWrapper(FilterLogic.And, filters)
    );

    return this.httpClient
      .post<ApiListResponse<EgibObjectFromApi>>(
        '/api/interesant/dzialki/search',
        postBody
      )
      .pipe(
        map((data) => data.Response),
        map((egibObjectsFromApi) =>
          egibObjectsFromApi.map((egibObjectFromApi) =>
            EgibObject.fromApiToApp(egibObjectFromApi)
          )
        ),
        catchError(() => observableOf([]))
      );
  }

  getParcelGeom(id: string): Observable<string[]> {
    const params = new HttpParams().set('Uuids', id);

    return this.httpClient.get<string[]>('/api/mapa/dzialki/geom', { params });
  }
}
