export interface PolygonValidationDto {
  IsPolygon: boolean;
  IsValid: boolean;
  IsAreaZero: boolean;
  SelfIntersectionPoints: string[];
  DuplicatePoints: string[];
}

export class PolygonTopologyValidation {
  constructor(
    public isPolygon: boolean,
    public isValid: boolean,
    public isAreaZero: boolean,
    public selfIntersectionPoints: string[],
    public duplicatePoints: string[]
  ) {}

  static fromApiToApp(
    polygonValidationDto: PolygonValidationDto
  ): PolygonTopologyValidation {
    return new this(
      polygonValidationDto.IsPolygon,
      polygonValidationDto.IsValid,
      polygonValidationDto.IsAreaZero,
      polygonValidationDto.SelfIntersectionPoints,
      polygonValidationDto.DuplicatePoints
    );
  }
}

export interface PolygonsUnionFromApi {
  Geom: string;
  PowierzchniaM2: string;
  PowierzchniaHa: string;
}

export class PolygonsUnion {
  constructor(
    public wkt: string,
    public areaInSquareMeters: string,
    public areaInHectares: string,
  ) {}

  static fromApiToApp(data: PolygonsUnionFromApi): PolygonsUnion {
    return new this(
      data.Geom,
      data.PowierzchniaM2,
      data.PowierzchniaHa,
    );
  }
}
