import { Component, Input, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
  ControlConfig,
  ModalControlConfig,
} from '../../../gk-dynamic-list.model';
import { DataService } from '../../../services/data/data.service';
import { Control } from '../control';

@Component({
  selector: 'gk-control-modal',
  templateUrl: './control-modal.component.html',
  styleUrls: ['./control-modal.component.scss'],
})
export class ControlModalComponent extends Control<any> {
  @Input() override controlConfig: ModalControlConfig;

  constructor(
    private ngbModal: NgbModal,
    protected override translateService: TranslateService,
    protected override dataService: DataService
  ) {
    super(translateService, dataService);
  }

  getNestedControlConfig(): ControlConfig {
    return this.controlConfig.controlConfig;
  }

  openModal(template: TemplateRef<NgbModalRef>): void {
    this.ngbModal.open(template);
  }
}
