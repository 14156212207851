export type EServiceChosenFiles = {
  [key in EServiceDocTypeId]?: File[];
};

export enum EServiceDocTypeId {
  Map = 6,
  Other = 8,
  AdministrativeDecision = 112,
  Certficate = 113,
  PartyRequest = 127,
  PaymentConfirmation = 10023,
  Enablement = 10035,
  BaseMap = 10038,
  PropertyLegalType = 10039,
  RequestStampDuty = 10040,
  SituationPlan = 10041,
  MotionOrganizationProject = 10042,
  OrientationPlan = 10043,
  Statement = 10044,
  OccupancyPermitDecision = 10045,
  RequestFiles = 10059,
  BuilidngPermitDecision = 10060,
  PermissionToUse = 10061,
  HousingAllocationDecision = 10062,
  LeaseAgreement = 10063,
  MarriageCertificate = 10064,
  DeathCertificate = 10065,
  FlatValuation = 10066,
  FlatPurchase65Discount = 10067,
  FlatPurchaseWithoutDiscount = 10068,
  PerpetualUsufructConversion = 10069,
  OneOffPayment = 10070,
  ModernizationProject = 10074,
}

export const docTypeIdWithDisabledUpload = [
  EServiceDocTypeId.ModernizationProject,
];

export const eServiceDocTypeLabelKey = {
  [EServiceDocTypeId.Other]: 'DOC_TYPE_DESCRIPTION.OTHERS',
  [EServiceDocTypeId.RequestStampDuty]: 'DOC_TYPE_DESCRIPTION.STAMP_DUTY',
  [EServiceDocTypeId.Enablement]: 'DOC_TYPE_DESCRIPTION.ENABLEMENT',
  [EServiceDocTypeId.Statement]: 'DOC_TYPE_DESCRIPTION.STATEMENT',
  [EServiceDocTypeId.Map]: 'DOC_TYPE_DESCRIPTION.MAP',
  [EServiceDocTypeId.BaseMap]: 'DOC_TYPE_DESCRIPTION.BASE_MAP',
  [EServiceDocTypeId.OccupancyPermitDecision]:
    'DOC_TYPE_DESCRIPTION.OCCUPANCY_PERMIT_DECISION',
  [EServiceDocTypeId.BuilidngPermitDecision]:
    'DOC_TYPE_DESCRIPTION.BUILIDNG_PERMIT_DECISION',
  [EServiceDocTypeId.PermissionToUse]: 'DOC_TYPE_DESCRIPTION.PERMISSION_TO_USE',
  [EServiceDocTypeId.HousingAllocationDecision]:
    'DOC_TYPE_DESCRIPTION.HOUSING_ALLOCATION_DECISION',
  [EServiceDocTypeId.LeaseAgreement]: 'DOC_TYPE_DESCRIPTION.LEASE_AGREEMENT',
  [EServiceDocTypeId.MarriageCertificate]:
    'DOC_TYPE_DESCRIPTION.MARRIAGE_CERTIFICATE',
  [EServiceDocTypeId.DeathCertificate]:
    'DOC_TYPE_DESCRIPTION.DEATH_CERTIFICATE',
  [EServiceDocTypeId.FlatValuation]: 'DOC_TYPE_DESCRIPTION.FLAT_VALUATION',
  [EServiceDocTypeId.FlatPurchase65Discount]:
    'DOC_TYPE_DESCRIPTION.FLAT_PURCHASE_65_DISCOUNT',
  [EServiceDocTypeId.FlatPurchaseWithoutDiscount]:
    'DOC_TYPE_DESCRIPTION.FLAT_PURCHASE_WITHOUT_DISCOUNT',
  [EServiceDocTypeId.Certficate]: 'DOC_TYPE_DESCRIPTION.CERTIFICATE',
  [EServiceDocTypeId.PaymentConfirmation]:
    'DOC_TYPE_DESCRIPTION.PAYMENT_CONFIRMATION',
  [EServiceDocTypeId.AdministrativeDecision]:
    'DOC_TYPE_DESCRIPTION.ADMINISTRATIVE_DECISION',
  [EServiceDocTypeId.SituationPlan]: 'DOC_TYPE_DESCRIPTION.SITUATIONAL_PLAN',
  [EServiceDocTypeId.MotionOrganizationProject]:
    'DOC_TYPE_DESCRIPTION.MOTION_ORGANIZATION_PROJECT',
  [EServiceDocTypeId.OrientationPlan]: 'DOC_TYPE_DESCRIPTION.ORIENTATION_PLAN',
  [EServiceDocTypeId.RequestFiles]: 'DOC_TYPE_DESCRIPTION.REQUEST_FILES',
  [EServiceDocTypeId.OneOffPayment]: 'DOC_TYPE_DESCRIPTION.ONE_OFF_PAYMENT',
  [EServiceDocTypeId.PerpetualUsufructConversion]:
    'DOC_TYPE_DESCRIPTION.PERPETUAL_USUFRUCT_CONVERSION',
  [EServiceDocTypeId.ModernizationProject]:
    'DOC_TYPE_DESCRIPTION.MODERNIZATION_PROJECT',
  [EServiceDocTypeId.PropertyLegalType]:
    'DOC_TYPE_DESCRIPTION.PROPERTY_LEGAL_TYPE',
  [EServiceDocTypeId.PartyRequest]: 'DOC_TYPE_DESCRIPTION.PARTY_REQUEST',
};

export const eServiceDocTypeDownloadLabelKey = {
  [EServiceDocTypeId.ModernizationProject]:
    'DOC_TYPE_DESCRIPTION.MODERNIZATION_PROJECT_DOWNLOAD',
} as { [key in EServiceDocTypeId]?: string };

export function getDocTypeLabelKeyById(
  id: EServiceDocTypeId,
  options = { downloadLabel: false }
): string {
  const labelConst = options.downloadLabel
    ? eServiceDocTypeDownloadLabelKey
    : eServiceDocTypeLabelKey;
  return labelConst[id];
}
