import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { getDefaultControlValue } from '@gk/gk-modules';
import { DecisionFormType } from '../../excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { InvestorDetailsFormService } from '../../investor-details-form/investor-details-form.service';
import { ShippingFormService } from '../../shipping-form/shipping-form.service';
import {
  FellingObjectsControlName,
  TreesAndShrubsFellingControlName,
} from './trees-and-shrubs-felling-form.model';

@Injectable()
export class TreesAndShrubsFellingFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private investorDetailsFormService: InvestorDetailsFormService,
    public shippingFormService: ShippingFormService
  ) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [TreesAndShrubsFellingControlName.InvestorDetails]:
        this.investorDetailsFormService.getFormGroup(false),
      [TreesAndShrubsFellingControlName.ProxyDetailsCheckbox]: [
        getDefaultControlValue(),
      ],
      [TreesAndShrubsFellingControlName.DecisionForm]: [
        DecisionFormType.ElectronicForm,
        [Validators.required],
      ],
      [TreesAndShrubsFellingControlName.ShippingForm]:
        this.shippingFormService.getFormGroup(),
      [TreesAndShrubsFellingControlName.TreesList]: this.formBuilder.array([]),
      [TreesAndShrubsFellingControlName.ShrubsList]: this.formBuilder.array([]),
      [TreesAndShrubsFellingControlName.BusinessPurpose]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [TreesAndShrubsFellingControlName.FellingCause]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [TreesAndShrubsFellingControlName.FellingDate]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [TreesAndShrubsFellingControlName.GrowthPlace]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [TreesAndShrubsFellingControlName.InvestmentDescription]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [TreesAndShrubsFellingControlName.Place]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [TreesAndShrubsFellingControlName.Street]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [TreesAndShrubsFellingControlName.StreetAbsence]: false,
      [TreesAndShrubsFellingControlName.LandRegister]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [TreesAndShrubsFellingControlName.LandNumber]: [
        getDefaultControlValue(),
        Validators.required,
      ],
    });
  }

  getTreeFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [FellingObjectsControlName.InventoryNumber]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [FellingObjectsControlName.SpeciesName]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [FellingObjectsControlName.TrunkCircumference]: [
        getDefaultControlValue(),
        Validators.required,
      ],
    });
  }

  getShrubFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [FellingObjectsControlName.InventoryNumber]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [FellingObjectsControlName.SpeciesName]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [FellingObjectsControlName.ProjectionSurface]: [
        getDefaultControlValue(),
        Validators.required,
      ],
    });
  }
}
