import { Directive, Input } from '@angular/core';
import {
  MapGeometryStyleConfig,
  MapLineString,
  MapPoint,
  MapPolygon,
  ProjectionCode,
} from '../../models';

@Directive()
export class GeometryControl {
  @Input()
  id: string;
  @Input()
  geometry: MapPolygon | MapPoint | MapLineString;
  @Input()
  styles: MapGeometryStyleConfig;
  @Input()
  projectionCode!: ProjectionCode;
}
