export interface PostalCodeFromApi {
  KodId: number;
  Nazwa: string;
}

export class PostalCode {
  constructor(public id: number | string, public name: string) {}

  static fromApiToApp(postalCodeFromApi: PostalCodeFromApi): PostalCode {
    return new this(postalCodeFromApi.KodId, postalCodeFromApi.Nazwa);
  }
}
