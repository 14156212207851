import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { filter, shareReplay, takeUntil } from 'rxjs/operators';
import { TfaService } from '../../../../gk-user-settings/services/tfa/tfa.service';
import {
  TfaWebAuthnVerificationCredentials,
  TfaWebAuthnVerificationCredentialsApi,
} from './tfa-web-authn-verification-credentials.model';

@Injectable({
  providedIn: 'root',
})
export class TfaWebAuthnVerificationCredentialsService {
  private tfaWebAuthnVerificationCredentials: Observable<TfaWebAuthnVerificationCredentials>;
  constructor(private httpClient: HttpClient, private tfaService: TfaService) {}

  get(forceAjax?: boolean): Observable<TfaWebAuthnVerificationCredentials> {
    if (!this.tfaWebAuthnVerificationCredentials || forceAjax) {
      this.tfaWebAuthnVerificationCredentials = this.httpClient
        .get<TfaWebAuthnVerificationCredentialsApi>(
          '/api/system/login/2fa/webauthn/credentials'
        )
        .pipe(
          takeUntil(
            this.tfaService
              .isTfaWebAuthnInOperation()
              .pipe(filter((val) => !val))
          ),
          map((data) => TfaWebAuthnVerificationCredentials.fromApiToApp(data)),
          shareReplay(1)
        );
    }
    return this.tfaWebAuthnVerificationCredentials;
  }
}
