import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'gk-modal-confirm',
  templateUrl: './gk-modal-confirm.component.html'
})
export class GkModalConfirmComponent {
  @Input() title = 'Potwierdzenie';
  @Input() body: string;
  ok = new Subject<boolean>();
  cancel = new Subject<boolean>();

  constructor(public activeModal: NgbActiveModal) {}

  onCancel(): void {
    this.activeModal.dismiss();
    this.cancel.next(true);
  }

  onOk(): void {
    this.activeModal.close();
    this.ok.next(true);
  }
}
