import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ApiNewDokumentPowiazanyDalDto } from '../designer-incoming-documents/designer-incoming-documents.model';

export enum RightToGrantType {
  LicensedSurveyor = 'LicensedSurveyor',
  Appraiser = 'Appraiser',
  CountyCourtBailiff = 'CountyCourtBailiff',
  Archivist = 'Archivist',
}

export const rightToGrantPermissionNumberRequired = {
  [RightToGrantType.LicensedSurveyor]: true,
  [RightToGrantType.Appraiser]: true,
  [RightToGrantType.CountyCourtBailiff]: true,
  [RightToGrantType.Archivist]: false,
};

export const apiRightToGrantEnumValues = {
  [RightToGrantType.LicensedSurveyor]: 0,
  [RightToGrantType.Appraiser]: 1,
  [RightToGrantType.CountyCourtBailiff]: 2,
  [RightToGrantType.Archivist]: 3,
};

export const apiRightToGrantDocTypeIds: {
  [key in RightToGrantKeys]: number | undefined;
} = {
  [RightToGrantType.LicensedSurveyor]: 10071,
  [RightToGrantType.Appraiser]: 10072,
  [RightToGrantType.CountyCourtBailiff]: 10073,
  [RightToGrantType.Archivist]: undefined,
};

export const rightToGrantKeys = Object.keys(RightToGrantType);

export class RightToGrant {
  constructor(
    public name: RightToGrantType,
    public permissionNumberRequired: boolean
  ) {}
}

export interface ApiRightToGrant {
  Typ: number;
  NrUpraw: string;
  Dokument: ApiNewDokumentPowiazanyDalDto;
}

export interface ApiNewGrantsRequest {
  WniosekMiejsc: string;
  Organizacja: string;
  Uprawnienia: ApiRightToGrant[];
}

export type RightToGrantKeys = keyof typeof RightToGrantType;

export type NewGrantFormValue = {
  currentPlace: string;
  currentDate: NgbDateStruct;
  companyName: string;
} & {
  [key in RightToGrantKeys]: {
    checked: boolean;
    number?: string;
    attachment?: File;
  };
};
