<button
  type="button"
  class="btn btn-color-from-db btn-md px-4 action-button"
  [title]="audioDescriptionToggleButtonNavItemConfig.title | translate"
  [attr.aria-label]="
    audioDescriptionToggleButtonNavItemConfig.buttonLabel | translate
  "
  (click)="toggleAudio()"
>
  <i
    class="fa fa-lg audio-icon"
    [ngClass]="toggled ? 'fa-volume-up' : 'fa-volume-off'"
    aria-hidden="true"
  ></i>
</button>

<audio #audioElementRef>
  <source
    [src]="audioDescriptionToggleButtonNavItemConfig.audioAssetSource"
    type="audio/mpeg"
  />
  {{ 'AUDIO_DESCRIPTION.AUDIO_NOT_SUPPORTED_INFO' | translate }}
</audio>
