import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { decode } from 'cbor-x';
import { ToastrService } from 'ngx-toastr';
import { from, map, Observable, of, throwError } from 'rxjs';
import {
  combineLatestWith,
  filter,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { TfaService } from '../../../../gk-user-settings/services/tfa/tfa.service';
import { BasicResponse } from '../../../../utils/basic-response/basic-response.model';
import { LoginService } from '../login/login.service';
import { TfaConfirmationService } from '../tfa-confirmation/tfa-confirmation.service';
import { Credentials } from '../tfa-web-authn-initialization-credentials/tfa-web-authn-initialization-credentials.model';
import { TfaWebAuthnInitializationCredentialsService } from '../tfa-web-authn-initialization-credentials/tfa-web-authn-initialization-credentials.service';
import { TfaWebAuthnInitializationApi } from './tfa-web-authn-initialization.model';

@Injectable({
  providedIn: 'root',
})
export class TfaWebAuthnInitializationService {
  constructor(
    private httpClient: HttpClient,
    private tfaService: TfaService,
    private tfaWebAuthnInitializationCredentialsService: TfaWebAuthnInitializationCredentialsService,
    private loginService: LoginService,
    private tfaConfirmationService: TfaConfirmationService,
    private translateService: TranslateService,
    private toastr: ToastrService
  ) {}

  register(credentialsForceAjax?: boolean): Observable<boolean> {
    return this.createCredentials(credentialsForceAjax).pipe(
      switchMap((credentials) =>
        this.httpClient.post<BasicResponse<boolean>>(
          '/api/system/login/2fa/webauthn/initialization/verify',
          TfaWebAuthnInitializationApi.fromAppToApi(credentials)
        )
      ),
      takeUntil(
        this.tfaService.isTfaWebAuthnInOperation().pipe(filter((val) => !val))
      ),
      map((data) => data.Result),
      switchMap((result) =>
        result
          ? of(result)
          : throwError(() => {
              this.showAddedCredentialErrorToastr();
              const errorLog =
                '2fa initialization has not been successfully verified';
              console.error(errorLog);
              return new Error(errorLog);
            })
      ),
      tap(() => this.tfaConfirmationService.verifiedConfirmation.next())
    );
  }

  private createCredentials(
    credentialsForceAjax: boolean
  ): Observable<Credentials> {
    return this.getPublicKeyCredentialCreationOptions(
      credentialsForceAjax
    ).pipe(
      switchMap((publicKeyCredentialCreationOptions) =>
        from(
          navigator.credentials.create({
            publicKey: publicKeyCredentialCreationOptions,
          })
        )
      ),
      map((credential) => {
        const { authData } = decode(
          new Uint8Array((credential as any).response.attestationObject)
        );
        const dataView = new DataView(new ArrayBuffer(2));
        const idLenBytes = authData.slice(53, 55);
        idLenBytes.forEach((value: any, index: number) =>
          dataView.setUint8(index, value)
        );
        const credentialIdLength = dataView.getUint16(0);
        const credentialId = authData.slice(55, 55 + credentialIdLength);
        const publicKeyBytes = authData.slice(55 + credentialIdLength);
        const publicKeyObject = decode(new Uint8Array(publicKeyBytes.buffer));

        return new Credentials(
          publicKeyObject,
          JSON.parse(
            new TextDecoder('utf-8').decode(
              (credential as any).response.clientDataJSON
            )
          ),
          Array.from(new Uint8Array(credentialId)),
          Array.from(new Uint8Array(publicKeyObject['-2'])),
          Array.from(new Uint8Array(publicKeyObject['-3']))
        );
      })
    );
  }

  private getPublicKeyCredentialCreationOptions(
    credentialsForceAjax: boolean
  ): Observable<PublicKeyCredentialCreationOptions> {
    return this.tfaWebAuthnInitializationCredentialsService
      .get(credentialsForceAjax)
      .pipe(
        combineLatestWith(this.loginService.getSystemLoggedUserData()),
        map(([webAuthnInitializationCredentials, user]) => ({
          ...webAuthnInitializationCredentials,
          user: {
            ...webAuthnInitializationCredentials.user,
            name: user.name,
            displayName: user.displayName,
          },
        }))
      );
  }

  showAddedCredentialErrorToastr(): void {
    this.translateService
      .get('GK.USER_SETTINGS.WEB_AUTHN_SETTINGS.ADDED_KEY_ERROR')
      .subscribe((text) => this.toastr.error(text));
  }
}
