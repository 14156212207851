<div class="modal-header">
  <h4 class="modal-title">
    {{ 'GK.MAP.PRINT.PRINTOUT_GENERATING' | translate }}
  </h4>
  <button
    type="button"
    class="btn-close"
    [attr.aria-label]="'GK.MAP.CLOSE' | translate"
    [title]="'GK.MAP.CLOSE' | translate"
    (click)="modal.close(false)"
  ></button>
</div>

@if (printAttributesFormGroup) {
  <div class="modal-body">
    <div class="row">
      <div class="col-md-3 col-xs-12 pe-md-0">
        <gk-gmp-print-attributes-form
          [mapState]="mapState"
          [formGroup]="printAttributesFormGroup"
          (dispatch)="handleMapAction($event)"
        ></gk-gmp-print-attributes-form>
      </div>
      <div class="print-preview-max-height col overflow-auto">
        <gk-gmp-print-preview
          [mapState]="mapState"
          [formGroup]="printAttributesFormGroup"
          (dispatch)="handleMapAction($event)"
        ></gk-gmp-print-preview>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-color-from-db"
      (click)="modal.close(false)"
    >
      {{ 'GK.MAP.PRINT.CANCEL' | translate }}
    </button>

    <button
      type="button"
      class="btn btn-color-from-db col-xl-2 col-md-2 col-sm-3 col-5"
      [disabled]="gmpPrintService.printoutGenerationInProgress | async"
      (click)="generatePdf()"
    >
      @if (gmpPrintService.printoutGenerationInProgress | async) {
        <i class="fa fa-spinner fa-pulse fa-fw"></i>
        <span class="sr-only">{{ 'LOADING' | translate }}</span>
      } @else {
        <span>{{ 'GK.MAP.PRINT.GENERATE_PDF' | translate }}</span>
      }
    </button>
  </div>
} @else {
  <div class="modal-body">
    <div class="row my-5">
      <div class="col-12">
        <div class="d-flex justify-content-center my-3">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
}
