import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { distinctUntilChanged, map, takeWhile } from 'rxjs/operators';
import { ButtonControlConfig } from '../../../../gk-dynamic-list.model';
import { DataService } from '../../../../services/data/data.service';
import { DocSignService } from '../../../../services/trusted-profile-sign/doc-sign.service';
import {
  AddedDocToSignResponse,
  DocKeyId,
  DocSign,
  DocSignStatus,
} from '../../../../services/trusted-profile-sign/trusted-profile-sign.model';

@Component({
  selector: 'gk-control-button',
  templateUrl: './control-button.component.html',
})
export class ControlButtonComponent implements OnInit, OnDestroy {
  private isAlive = true;
  @Input() button: ButtonControlConfig;
  @Input() data: any;
  actionPending: boolean;
  requestSuccessfullySigned: boolean;
  docSignUrl: SafeUrl = '';
  requestSuccessTranslation = '';
  requestFailedTranslation = '';

  constructor(
    protected translateService: TranslateService,
    protected dataService: DataService,
    private domSanitizer: DomSanitizer,
    private docSignService: DocSignService,
    private toastr: ToastrService,
    private router: Router,
    @Inject('window') private window: Window,
  ) {}

  ngOnInit(): void {
    this.fetchSuccessNotificationText();
    this.fetchFailedNotificationText();
  }

  handleButtonClickAction(): void {
    this.actionPending = true;
    this.dataService
      .getData(this.button.requestConfig, this.data)
      .pipe(
        takeWhile(() => this.isAlive),
        map((data) => {
          return this.button.trustedProfileSignRequired
            ? AddedDocToSignResponse.fromApiToApp(data)
            : AddedDocToSignResponse.directlyAddedRequest();
        }),
      )
      .subscribe({
        next: (data) => {
          this.handleSendAndValidateSuccess(data);
        },
        error: () => {
          this.handleRequestFailure(false);
        },
      });
  }

  fetchSuccessNotificationText(): void {
    this.translateService
      .get(
        this.button.trustedProfileSignRequired
          ? 'TRUSTED_PROFILE.SIGN_SUCCESS'
          : 'REQUEST_SUCCESS',
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (translation) => (this.requestSuccessTranslation = translation),
      );
  }

  fetchFailedNotificationText(): void {
    this.translateService
      .get(
        this.button.trustedProfileSignRequired
          ? 'TRUSTED_PROFILE.SIGN_FAILED'
          : 'ERROR',
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (translation) => (this.requestFailedTranslation = translation),
      );
  }

  handleSendAndValidateSuccess(
    addedDocToSignResponse: AddedDocToSignResponse,
  ): void {
    if (addedDocToSignResponse.directlyAddedRequest) {
      this.handleRequestSuccess();

      return;
    }
    this.docSignUrl = this.domSanitizer.bypassSecurityTrustUrl(
      addedDocToSignResponse.docSigningUrl,
    );
    this.openSigningWindow(addedDocToSignResponse.docSigningUrl);
    this.waitForDocSign(addedDocToSignResponse.docKeyId);
  }

  openSigningWindow(windowUrl: SafeUrl): void {
    this.window.open(windowUrl as string);
  }

  waitForDocSign(docKeyId: DocKeyId): void {
    this.docSignService
      .setDocSignStatusCheckInterval(docKeyId)
      .pipe(
        takeWhile(() => this.isAlive),
        distinctUntilChanged(_.isEqual),
      )
      .subscribe({
        next: (docSign) => this.handleDocSignChange(docSign),
        error: (error) => this.handleDocSignChange(error),
      });
  }

  handleDocSignChange(docSignOrError: DocSign | Error) {
    switch (_.get(docSignOrError, 'status')) {
      case DocSignStatus.Success:
        this.handleRequestSuccess();
        break;
      case DocSignStatus.Pending:
        break;
      case DocSignStatus.Failure:
      default:
        this.handleRequestFailure();
        break;
    }
  }

  handleRequestSuccess(): void {
    this.requestSuccessfullySigned = true;
    this.actionPending = false;
    this.docSignUrl = '';
    this.toastr.success(this.requestSuccessTranslation);
    if (
      this.button.requestConfig &&
      this.button.requestConfig.successRedirectPath
    ) {
      this.router.navigateByUrl(this.button.requestConfig.successRedirectPath);
    }
  }

  handleRequestFailure(showDefaultSignError = true): void {
    this.actionPending = false;
    this.docSignUrl = '';
    if (showDefaultSignError) {
      this.toastr.error(this.requestFailedTranslation);
    }
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
