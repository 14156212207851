export enum TfaConfirmationMode {
  Init = 1,
  Login = 2,
}

export interface TfaConfirmationApi {
  Code: string;
}

export class TfaConfirmation {
  constructor(public code: string) {}

  static fromAppToApi(data: TfaConfirmation): TfaConfirmationApi {
    return {
      Code: data.code,
    };
  }
}
