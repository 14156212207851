export enum AgriculturalLandUseControlName {
  ProxyDetailsCheckbox = 'proxyDetailsCheckbox',
  ProxyDetails = 'proxyDetails',
  ExclusionPurpose = 'exclusionPurpose',
  InvestmentCommencementDate = 'investmentCommencementDate',
  InvestorDetails = 'investorDetails',
  DecisionForm = 'decisionForm',
  ShippingForm = 'shippingForm'
}

export enum DecisionFormType {
  NonElectronicForm = 0,
  ElectronicForm = 1
}
