import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MapControl } from '../../../../controls';
import {
  MapGeometryCollectionsWithWktType, MapGeometryStyleConfig, MapLineString, MapObject, MapPoint,
  MapPolygon, ProjectionCode,
  SourceType, WktType
} from '../../../../models';
import { ConversionUtils } from '../../../../utils';

@Component({
  selector: 'gk-map-geometry',
  templateUrl: './map-geometry.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapGeometryComponent extends MapControl {
  @Input()
  mapObjects: MapObject[];
  @Input()
  styles: MapGeometryStyleConfig;
  @Input()
  projectionCode: ProjectionCode;
  @Input()
  source: SourceType | string;
  wktTypeEnum = WktType;

  getGeometryCollectionWithWktType(
    mapObject: MapObject
  ): MapGeometryCollectionsWithWktType {
    const wktType = ConversionUtils.getWktType(mapObject.geom);

    return {
      mapGeometryCollection: ConversionUtils.getMapGeometryCollectionBasedOnWktType(
        mapObject.geom,
        wktType
      ),
      wktType
    };
  }

  getGeometryId(mapObject: MapObject, feat: number, part: number): string {
    return `${this.source}-${mapObject.uuid}-${mapObject.type}-${feat}-${part}`;
  }

  getPoint(geometry: MapPolygon | MapPoint | MapLineString): MapPoint {
    return <MapPoint>geometry;
  }

  getLineString(geometry: MapPolygon | MapPoint | MapLineString): MapLineString {
    return <MapLineString>geometry;
  }

  getPolygon(geometry: MapPolygon | MapPoint | MapLineString): MapPolygon {
    return <MapPolygon>geometry;
  }
}
