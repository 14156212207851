import { Component, Input, Inject, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { LangAbbreviation } from './gk-modal-terms-of-use.model';
import { GkModalTermsOfUseService } from './gk-modal-terms-of-use.service';

@Component({
  selector: 'gk-modal-terms-of-use',
  templateUrl: './gk-modal-terms-of-use.component.html',
})
export class GkModalTermsOfUseComponent implements OnInit {
  @Input() langTerms: Array<string>;
  areTermsOfUseRead = false;
  translationsPl: any;
  translationsEn: any;

  constructor(
    @Inject('window') public window: Window,
    public activeModal: NgbActiveModal,
    public translateService: TranslateService,
    public gkModalTermsOfUseService: GkModalTermsOfUseService
  ) {}

  ngOnInit(): void {
    this.subscribeToPolishTranslations();
    this.subscribeToEnglishTranslations();
  }

  subscribeToPolishTranslations(): void {
    this.translateService
      .getTranslation(LangAbbreviation.Polish)
      .subscribe((translations) => {
        this.translationsPl = translations;
      });
  }

  subscribeToEnglishTranslations(): void {
    this.translateService
      .getTranslation(LangAbbreviation.English)
      .subscribe((translations) => {
        this.translationsEn = translations;
      });
  }

  getTranslationByLanguage(lang: string, term: string): string {
    return LangAbbreviation.Polish === lang
      ? this.translationsPl?.TERMS_OF_USE_TRANSLATIONS[term]
      : this.translationsEn?.TERMS_OF_USE_TRANSLATIONS[term];
  }

  openTermsOfUse(lang: string): void {
    this.gkModalTermsOfUseService
      .getTermsOfUseByLang(lang)
      .subscribe((term: string) => {
        const windowInstance = this.window.open('');
        windowInstance.document.write(term);
      });
  }

  closeModal(): void {
    this.gkModalTermsOfUseService.handleTermsOfUseAcceptation();
    this.activeModal.close();
  }
}
