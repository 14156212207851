import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainRoutes } from '../../guards/guard.models';
import { SessionGuard } from '../../guards/session/session.guard';
import { PaymentsListComponent } from '../../shared/payments-list/payments-list.component';
import { NewRequestSummaryComponent } from '../free-services-portal/new-request-summary/new-request-summary.component';
import { NetworkServiceOrderPortalComponent } from './network-service-order-portal.component';
import { NewNetworkServiceOrderComponent } from './new-network-service-order/new-network-service-order.component';
import { UserRequestsDynamicComponent } from './user-requests-dynamic/user-requests-dynamic.component';

const routes: Routes = [
  {
    path: MainRoutes.NetworkServiceOrder,
    component: NetworkServiceOrderPortalComponent,
    canActivate: [SessionGuard],
    children: [
      {
        path: '',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
      {
        path: 'new-request',
        component: NewNetworkServiceOrderComponent,
      },
      {
        path: 'new-request-summary',
        component: NewRequestSummaryComponent,
      },
      {
        path: 'user-requests-dynamic',
        component: UserRequestsDynamicComponent,
      },
      {
        path: 'payments',
        component: PaymentsListComponent,
      },
      {
        path: '**',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NetworkServiceOrderPortalRoutingModule {}
