import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Payment, PaymentStatus } from '../../../../gk-payments/payments.model';
import { OrderPaymentControlConfig } from '../../../gk-dynamic-list.model';
import { DataService } from '../../../services/data/data.service';
import { Control } from '../control';

@Component({
  selector: 'gk-control-payment',
  templateUrl: './control-payment.component.html',
})
export class ControlPaymentComponent extends Control<Payment> {
  @Input()
  override controlConfig: OrderPaymentControlConfig;
  isPaymentInProgress = false;

  constructor(
    protected override translateService: TranslateService,
    protected override dataService: DataService
  ) {
    super(translateService, dataService);
  }

  checkIfOrderCanBePaid(): boolean {
    return (
      this.getValue().StatusPlatnosci === PaymentStatus.Canceled ||
      this.getValue().StatusPlatnosci === PaymentStatus.Unpaid
    );
  }

  override shouldShowControl(): boolean {
    return super.shouldShowControl() && this.checkIfOrderCanBePaid();
  }

  setPaymentProgressStatus(paymentInProgress: boolean): void {
    this.isPaymentInProgress = paymentInProgress;
  }
}
