@for (
  wmsOption of mapState.layersState.wmsLayersOptions;
  track wmsOption.url;
  let i = $index
) {
  <div>
    <div>
      @switch (wmsOption.wmsLayerType) {
        @case (wmsLayerTypeEnum.Tile) {
          <div>
            <aol-layer-tile>
              @if (!wmsOption.isExternal || wmsOption.externalShouldBeVisible) {
                <aol-source-tilewms
                  [url]="wmsOption.url"
                  [projection]="getProjection(i)"
                  [params]="wmsOption.params"
                  [crossOrigin]="'anonymous'"
                >
                </aol-source-tilewms>
              }
            </aol-layer-tile>
          </div>
        }
        @case (wmsLayerTypeEnum.Image) {
          <div>
            <aol-layer-image #layerImage>
              @if (!wmsOption.isExternal || wmsOption.externalShouldBeVisible) {
                <aol-source-imagewms
                  [url]="wmsOption.url"
                  [projection]="getProjection(i)"
                  [params]="wmsOption.params"
                  [crossOrigin]="'anonymous'"
                >
                </aol-source-imagewms>
              }
            </aol-layer-image>
          </div>
        }
      }
    </div>
  </div>
}
@if (layerImageComponent?.instance) {
  <aol-control-overviewmap
    [tipLabel]="'GK.MAP.OVERVIEW_MAP' | translate"
    [label]="'M'"
    [collapseLabel]="'»'"
    [layers]="getOverviewmapLayers()"
  ></aol-control-overviewmap>
}
