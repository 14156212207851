export interface CautionsDto {
  Pouczenie: string;
  Procedura: string;
  KlauzulaRodo: string;
}

export class Cautions {
  static acceptanceInformationTanslation = 'ACCEPTANCE_INFORMATION_DESCRIPTIONS';
  static acceptanceInformationTanslationsKeysInProperOrder = [
    'MAIN_CAUTION',
    'PROCEDURE',
    'RODO_CLAUSE'
  ];

  constructor(
    public mainCaution: string,
    public procedure: string,
    public rodoClause: string
  ) {}

  static fromApiToApp(data: CautionsDto): Cautions {
    return new this(data.Pouczenie, data.Procedura, data.KlauzulaRodo);
  }

  isNotEmpty(): boolean {
    return !!(this.mainCaution || this.procedure || this.rodoClause);
  }
}
