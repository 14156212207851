import { FormControl } from '@angular/forms';
import { SelectItem } from '../../../services';
import { GMPPrintQuality } from '../print-quality/print-quality.model';
import { GMPPrintTemplate } from '../print-template/print-template.model';

export enum GMPPrintAttributesControlName {
  PrintTemplate = 'printTemplate',
  MapScaleResolution = 'mapScale',
  Quality = 'quality',
  PrintFooter = 'printFooter',
  ShowScaleInfo = 'showScaleInfo',
  ShowCoordinatesInfo = 'showCoordinatesInfo',
  ShowCornersCoordinates = 'showCornersCoordinates',
  ShowLegend = 'showLegend',
  ShowNorthArrow = 'showNorthArrow',
  ShowDocumentWithoutLegalForceLabel = 'showDocumentWithoutLegalForceLabel',
  Subtitle = 'subtitle',
}

export interface GMPPrintAttributesFormGroup {
  [GMPPrintAttributesControlName.PrintTemplate]: FormControl<GMPPrintTemplate>;
  [GMPPrintAttributesControlName.MapScaleResolution]: FormControl<number>;
  [GMPPrintAttributesControlName.Quality]: FormControl<GMPPrintQuality>;
  [GMPPrintAttributesControlName.PrintFooter]: FormControl<
    SelectItem<string | null>
  >;
  [GMPPrintAttributesControlName.ShowScaleInfo]: FormControl<boolean>;
  [GMPPrintAttributesControlName.ShowCoordinatesInfo]: FormControl<boolean>;
  [GMPPrintAttributesControlName.ShowCornersCoordinates]: FormControl<boolean>;
  [GMPPrintAttributesControlName.ShowLegend]: FormControl<boolean>;
  [GMPPrintAttributesControlName.ShowNorthArrow]: FormControl<boolean>;
  [GMPPrintAttributesControlName.ShowDocumentWithoutLegalForceLabel]: FormControl<boolean>;
  [GMPPrintAttributesControlName.Subtitle]: FormControl<string>;
}
