import { Component, Input } from '@angular/core';
import { StaticDictControlConfig } from '../../../gk-dynamic-list.model';
import { Control } from '../control';

@Component({
  selector: 'gk-control-static-dict',
  templateUrl: './control-static-dict.component.html',
})
export class ControlStaticDictComponent extends Control<any> {
  @Input() override controlConfig: StaticDictControlConfig;

  getDictValue(): string {
    const fieldId = this.getValue();
    const foundField = this.controlConfig.dictConfig.find(
      (field) => field.id === fieldId
    );

    return foundField && foundField.name ? foundField.name : this.noData;
  }
}
