import { Injectable } from '@angular/core';
import { filter, throttleTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable()
export class SessionRefreshTriggerService {
  private userActionTrigger = new Subject<void>();
  private isActivatedTrigger = false;
  sessionRefreshTrigger = new Subject<void>();

  constructor() {
    this.setUserActionListener();
  }

  private setUserActionListener(): void {
    this.userActionTrigger
      .pipe(
        throttleTime(15000),
        filter(() => this.isActivatedTrigger)
      )
      .subscribe(() => {
        this.sessionRefreshTrigger.next();
      });
  }

  activateTrigger(): void {
    this.isActivatedTrigger = true;
  }

  eventActionHandler = () => this.userActionTrigger.next();
}
