<div class="col-12 d-flex flex-column">
  <span class="w-100 fw-bold py-2">
    {{ 'CLOSED_AREAS.HEADER' | translate }}
  </span>

  <div class="table-wrapper w-100 border">
    <div class="table-responsive">
      <table class="table table-sm">
        <thead class="thead-light">
          <tr>
            <th class="text-truncate" scope="col">#</th>
            <th class="text-truncate" scope="col">
              {{ 'CLOSED_AREAS.SIGNATURE' | translate }}
            </th>
            <th class="text-truncate" scope="col">
              {{ 'CLOSED_AREAS.DATE' | translate }}
            </th>
            <th class="text-truncate" scope="col">
              {{ 'CLOSED_AREAS.EMITTED_BY' | translate }}
            </th>
            <th class="text-truncate" scope="col">
              {{ 'CLOSED_AREAS.OPEN' | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          @for (
            closedArea of closedAreas;
            track closedArea.signature;
            let i = $index
          ) {
            <tr>
              <th class="text-truncate">{{ i + 1 }}</th>
              <td>{{ closedArea.signature }}</td>
              <td>{{ getUiDate(closedArea.date) }}</td>
              <td>{{ closedArea.emittedBy }}</td>
              <td>{{ getUiBoolean(closedArea.open) | async }}</td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
</div>
