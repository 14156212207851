import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { getDefaultControlValue } from '@gk/gk-modules';
import { InvestorDetailsFormService } from '../../investor-details-form/investor-details-form.service';
import { ShippingFormService } from '../../shipping-form/shipping-form.service';
import {
  AgriculturalLandUseControlName,
  DecisionFormType,
} from './agricultural-land-use-form.model';

@Injectable()
export class AgriculturalLandUseFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private investorDetailsFormService: InvestorDetailsFormService,
    public shippingFormService: ShippingFormService
  ) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [AgriculturalLandUseControlName.InvestorDetails]:
        this.investorDetailsFormService.getFormGroup(),
      [AgriculturalLandUseControlName.ProxyDetailsCheckbox]: [
        getDefaultControlValue(),
      ],
      [AgriculturalLandUseControlName.ExclusionPurpose]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [AgriculturalLandUseControlName.InvestmentCommencementDate]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [AgriculturalLandUseControlName.DecisionForm]: [
        DecisionFormType.ElectronicForm,
        [Validators.required],
      ],
      [AgriculturalLandUseControlName.ShippingForm]:
        this.shippingFormService.getFormGroup(),
    });
  }
}
