import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FeeWaiverCause, FeeWaiverControlName } from './fee-waiver.model';

@Component({
  selector: 'app-fee-waiver',
  templateUrl: './fee-waiver.component.html',
  styles: [],
})
export class FeeWaiverComponent {
  feeWaiverControlName = FeeWaiverControlName;
  feeWaiverCause = FeeWaiverCause;
  @Input() parentForm: UntypedFormGroup;

  getFeeWaiverCheckboxFormControl(): UntypedFormControl {
    return this.getFormControlByName(
      FeeWaiverControlName.FeeWaiverCheckbox
    ) as UntypedFormControl;
  }

  getFormControlByName(formControlName: string): AbstractControl {
    return this.parentForm.get(formControlName);
  }
}
