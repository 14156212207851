import { DateHelperUtil } from '../../../../gk-dynamic-list/utils/date-helper.util';

export interface TfaWebAuthnUserCredentialApi {
  CredentialId: string;
  DataD: string;
}

export interface TfaWebAuthnUserCredentialsApi {
  CredentialIds: TfaWebAuthnUserCredentialApi[];
}

export class TfaWebAuthnUserCredentials {
  constructor(public credentialIds: TfaWebAuthnUserCredential[]) {}

  static fromApiToApp(
    data: TfaWebAuthnUserCredentialsApi
  ): TfaWebAuthnUserCredentials {
    return data.CredentialIds
      ? new this(
          data.CredentialIds.map((credential) =>
            TfaWebAuthnUserCredential.fromApiToApp(credential)
          )
        )
      : undefined;
  }

  static getEmpty(): TfaWebAuthnUserCredentials {
    return new this([]);
  }
}

export class TfaWebAuthnUserCredential {
  constructor(
    public id: string,
    public label: string,
    public addedDate: string
  ) {}

  static fromApiToApp(
    data: TfaWebAuthnUserCredentialApi
  ): TfaWebAuthnUserCredential {
    return new this(
      data.CredentialId,
      `${data.CredentialId.slice(0, 19)}...`,
      data.DataD ? DateHelperUtil.apiToUiDateTime(data.DataD) : '-'
    );
  }
}
