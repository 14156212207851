import {
  BuildingDto,
  EgibObject,
  LawPerson,
  LawPersonType,
  NewDesignerRequestDto,
  NewLegalPersonRequestDto,
  NewNaturalPersonRequestDto,
  PremisesDto,
} from '@gk/gk-modules';
import { ApiNewDokumentPowiazanyDalDto } from '../../../../services/designer-incoming-documents/designer-incoming-documents.model';
import { DecisionFormType } from '../../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { ZamowienieDzialkaDto } from '../../../../services/excluding-land-from-agricultural-production/agricultural-land-use/agricultural-land-use.model';
import {
  Shipping,
  ShippingDto,
} from '../../../../shared/shipping-form/shipping-form.model';

export interface APNEDNewRequestDto {
  DaneInwestora:
    | NewNaturalPersonRequestDto
    | NewLegalPersonRequestDto
    | NewDesignerRequestDto;
  Zalaczniki: ApiNewDokumentPowiazanyDalDto[];
  FormaDecyzji: number;
  Odbior: ShippingDto;
  Dzialki: ZamowienieDzialkaDto[];
  Budynki: BuildingDto[];
  Lokale: PremisesDto[];
  OkreslenieRobotBudowlanych: string;
  PodstawaRobotBudowlanych: string;
  UzasadnienieWniosku: string;
  ZgodaNaKorespondencjeElektroniczna: boolean;
}

export class APNEDNewRequest {
  constructor(
    public lawPerson: LawPerson,
    public decisionForm: DecisionFormType,
    public shippingForm: Shipping,
    public constructionWorkDefinition: string,
    public constructionWorkBasis: string,
    public requestJustification: string,
    public electronicCorrespondenceStatement: boolean
  ) {}

  static fromAppToApi(
    data: APNEDNewRequest,
    parcels: EgibObject[],
    buildings: EgibObject[],
    premises: EgibObject[],
    files: ApiNewDokumentPowiazanyDalDto[]
  ): APNEDNewRequestDto {
    return {
      DaneInwestora:
        data.lawPerson.type === LawPersonType.Natural
          ? NewNaturalPersonRequestDto.fromAppToApi(data.lawPerson)
          : NewLegalPersonRequestDto.fromAppToApi(data.lawPerson),
      Zalaczniki: files,
      FormaDecyzji: data.decisionForm,
      Dzialki: parcels.map((parcel) =>
        ZamowienieDzialkaDto.fromAppToApi(parcel)
      ),
      Budynki: buildings.map((building) => BuildingDto.fromAppToApi(building)),
      Lokale: premises.map((premise) => PremisesDto.fromAppToApi(premise)),
      Odbior: ShippingDto.fromAppToApi(data.shippingForm),
      OkreslenieRobotBudowlanych: data.constructionWorkDefinition,
      PodstawaRobotBudowlanych: data.constructionWorkBasis,
      UzasadnienieWniosku: data.requestJustification,
      ZgodaNaKorespondencjeElektroniczna:
        data.electronicCorrespondenceStatement,
    };
  }
}
