import { Injectable } from '@angular/core';
import { SystemSession } from '@gk/gk-modules';
import { BehaviorSubject, interval, of, Subject, Subscription } from 'rxjs';
import { mergeMap, takeWhile } from 'rxjs/operators';
import { TimerUtil } from '../../utils/timer/timer.util';
import { SessionService } from '../session/session.service';

@Injectable()
export class TimerService {
  readonly modalTimeThreshold = 20;
  readonly logoutTimeThreshold = 1;
  secondsLeft: number;
  shownModal = false;
  sessionLifetimeString = new BehaviorSubject<string>('00:00');
  modalVisible = new Subject<boolean>();
  prolongSessionTrigger = new Subject<void>();
  isCounterIntervalSet = false;
  counterIntervalSubscription: Subscription;

  constructor(private sessionService: SessionService) {
    this.subscribeToApiSessionLifeTime();
    this.subscribeToProlongSessionTrigger();
  }

  private subscribeToApiSessionLifeTime(): void {
    this.sessionService.sessionLifetime
      .pipe(
        takeWhile(() =>
          this.sessionService.isSessionSet(
            this.sessionService.systemSession.getValue()
          )
        )
      )
      .subscribe((sessionLifetime) =>
        this.handleSessionLifetimeChange(sessionLifetime)
      );
  }

  private subscribeToProlongSessionTrigger(): void {
    this.prolongSessionTrigger
      .pipe(
        takeWhile(() =>
          this.sessionService.isSessionSet(
            this.sessionService.systemSession.getValue()
          )
        )
      )
      .subscribe(() => {
        this.sessionService
          .refreshSession()
          .add(() => (this.shownModal = false));
      });
  }

  private handleSessionLifetimeChange(sessionLifetime: number): void {
    this.secondsLeft = sessionLifetime;

    if (!this.isCounterIntervalSet) {
      this.setCounterInterval();
    }
  }

  private checkThresholdsAndMakeActions(): void {
    if (this.secondsLeft <= this.modalTimeThreshold && !this.shownModal) {
      this.modalVisible.next(true);
      this.shownModal = true;
    }

    if (this.secondsLeft <= this.logoutTimeThreshold) {
      this.modalVisible.next(false);
      this.sessionService
        .getSystemSession()
        .pipe(
          mergeMap((systemSession) => {
            if (systemSession.isAuthenticated()) {
              return this.sessionService.logOffSystemSession();
            } else {
              this.sessionService.systemSession.next(SystemSession.getEmpty());
              return of(true);
            }
          })
        )
        .subscribe({
          next: () => {
            this.counterIntervalSubscription.unsubscribe();
            this.sessionLifetimeString.next(TimerUtil.generateString(0));
            this.sessionService.navigateAfterSessionEnd();
          },
          error: () => {
            this.sessionService.systemSession.next(SystemSession.getEmpty());
            this.counterIntervalSubscription.unsubscribe();
            this.sessionLifetimeString.next(TimerUtil.generateString(0));
            this.sessionService.navigateAfterSessionEnd();
          },
        });
    }
  }

  private setCounterInterval(): void {
    this.isCounterIntervalSet = true;
    this.counterIntervalSubscription = interval(1000).subscribe(() => {
      --this.secondsLeft;
      this.sessionService.calculatedSessionSecondsLeft.next(this.secondsLeft);
      this.sessionLifetimeString.next(
        TimerUtil.generateString(this.secondsLeft)
      );
      this.checkThresholdsAndMakeActions();
    });
  }
}
