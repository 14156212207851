import { NgModule } from '@angular/core';
import { EmailValidatorDirective } from './email-validator/email-validator.directive';
import { IpValidatorDirective } from './ip-validator/ip-validator.directive';
import { PhoneValidatorDirective } from './phone-validator/phone-validator.directive';

@NgModule({
  imports: [],
  declarations: [
    PhoneValidatorDirective,
    EmailValidatorDirective,
    IpValidatorDirective,
  ],
  exports: [
    PhoneValidatorDirective,
    EmailValidatorDirective,
    IpValidatorDirective,
  ],
  providers: [],
})
export class GkValidatorsModule {}
