import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, ReplaySubject } from 'rxjs';
import { CityData, CityDataResponseDto } from './system-cities-data.model';

@Injectable()
export class SystemCitiesDataService {
  systemCitiesData = new ReplaySubject<CityData[]>();

  constructor(private httpClient: HttpClient) {
    this.fetchSystemCitiesData();
  }

  private fetchSystemCitiesData(): void {
    this.getSystemCitiesData().subscribe((systemCitiesData) =>
      this.systemCitiesData.next(systemCitiesData),
    );
  }

  private getSystemCitiesData(): Observable<CityData[]> {
    return this.httpClient
      .get<CityDataResponseDto[]>('/api/system/miasta/dane')
      .pipe(
        map((apiFields) =>
          apiFields.map((apiField) => CityData.fromApiToApp(apiField)),
        ),
      );
  }
}
