<div class="my-2">
  <label for="parcelSelectableList" class="sr-only">
    {{ 'PARCEL_LIST.TITLE' | translate }}
  </label>
  <select
    multiple
    [size]="getSelectSize(parcels.length)"
    class="my-1 form-select"
    name="parcelSelectableList"
    id="parcelSelectableList"
    [(ngModel)]="selectedParcels"
    #parcelSelectableList="ngModel"
  >
    @for (parcel of parcels; track parcel.uuid) {
      <option [ngValue]="parcel">
        {{ getParcelDescription(parcel) }}
      </option>
    }
  </select>

  @if (parcelListMode === parcelListModeEnum.Choosing) {
    <div>
      <button
        type="button"
        class="btn btn-sm btn-primary"
        (click)="emitSelectedParcels()"
      >
        {{ 'PARCEL_LIST.ADD_SELECTED' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-sm btn-color-from-db"
        (click)="emitAllParcels()"
      >
        {{ 'PARCEL_LIST.ADD_ALL' | translate }}
      </button>
    </div>
  }

  @if (parcelListMode === parcelListModeEnum.Removing) {
    <div>
      <button
        type="button"
        class="btn btn-sm btn-danger"
        (click)="emitSelectedParcels()"
      >
        {{ 'PARCEL_LIST.REMOVE_SELECTED' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-sm btn-danger"
        (click)="emitAllParcels()"
      >
        {{ 'PARCEL_LIST.REMOVE_ALL' | translate }}
      </button>
    </div>
  }
</div>
