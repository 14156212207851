import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiFilter,
  ApiListResponse,
  FilterLogic,
  FilterWrapper,
  RequestBodyForList,
} from '@gk/gk-modules';
import * as _ from 'lodash';
import { catchError, map, Observable } from 'rxjs';
import { handleApiError } from '../services.utils';
import { Address, AddressFromApi, SearchAddressModel } from './address.model';

@Injectable()
export class AddressService {
  constructor(private httpClient: HttpClient) {}

  prepareFiltersForAddressSearchForm(
    searchAddressFormValue: SearchAddressModel
  ): ApiFilter[] {
    const { placeId, streetId, ordinalNumber } = searchAddressFormValue;
    const filterArray: ApiFilter[] = [];

    if (placeId) {
      filterArray.push(new ApiFilter('MiejscId', placeId));
    }
    if (streetId) {
      filterArray.push(new ApiFilter('UlicaId', streetId));
    }
    if (ordinalNumber) {
      filterArray.push(new ApiFilter('NrPorzadk', ordinalNumber));
    }

    return filterArray;
  }

  getAddresses(
    searchAddressFormValue: SearchAddressModel
  ): Observable<Address[]> {
    const filters: ApiFilter[] = this.prepareFiltersForAddressSearchForm(
      searchAddressFormValue
    );
    const postBody = new RequestBodyForList(
      new FilterWrapper(FilterLogic.And, filters)
    );

    return this.httpClient
      .post<ApiListResponse<AddressFromApi>>(
        '/api/zudp/projektant/adresy/search',
        postBody
      )
      .pipe(
        map((data) => data.Response),
        map((data) =>
          _.isArray(data)
            ? data.map((addressFromApi) => Address.fromApiToApp(addressFromApi))
            : []
        ),
        catchError(handleApiError)
      );
  }
}
