<form [formGroup]="form" novalidate [autocomplete]="undefined">
  <div class="row">
    @if (getFirstNameFormControl()) {
      <div class="col-lg-6 col-md-12 my-1">
        <label [attr.for]="naturalPersonControlName.FirstName">{{
          'NATURAL_PERSON_FORM.FIRST_NAME' | translate
        }}</label>
        <input
          type="text"
          [name]="naturalPersonControlName.FirstName"
          [id]="naturalPersonControlName.FirstName"
          class="form-control form-control-sm"
          [ngClass]="{ required: isRequired(getFirstNameFormControl()) }"
          [attr.aria-required]="isRequired(getFirstNameFormControl())"
          [formControlName]="naturalPersonControlName.FirstName"
          [autocomplete]="undefined"
          />
        @if (shouldShowErrorAlert(getFirstNameFormControl())) {
          <div
            class="alert alert-danger"
            >
            {{ 'FIELD_REQUIRED' | translate }}
          </div>
        }
      </div>
    }
    @if (getLastNameFormControl()) {
      <div class="col-lg-6 col-md-12 my-1">
        <label [attr.for]="naturalPersonControlName.LastName">{{
          'NATURAL_PERSON_FORM.LAST_NAME' | translate
        }}</label>
        <input
          type="text"
          [name]="naturalPersonControlName.LastName"
          [id]="naturalPersonControlName.LastName"
          class="form-control form-control-sm"
          [ngClass]="{ required: isRequired(getLastNameFormControl()) }"
          [attr.aria-required]="isRequired(getLastNameFormControl())"
          [formControlName]="naturalPersonControlName.LastName"
          [autocomplete]="undefined"
          />
        @if (shouldShowErrorAlert(getLastNameFormControl())) {
          <div
            class="alert alert-danger"
            >
            {{ 'FIELD_REQUIRED' | translate }}
          </div>
        }
      </div>
    }
  </div>

  <div class="row">
    @if (getPhoneNumberFormControl()) {
      <div
        class="col-md col-sm-6 col-xs-12 my-1"
        >
        <label
          class="text-nowrap"
          [attr.for]="naturalPersonControlName.PhoneNumber"
          >{{
          'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.INVESTOR_DETAILS.FIELDS.PHONE_NUMBER'
          | translate
          }}</label
          >
          <input
            type="tel"
            [name]="naturalPersonControlName.PhoneNumber"
            [id]="naturalPersonControlName.PhoneNumber"
            class="form-control form-control-sm"
            [ngClass]="{ required: isRequired(getPhoneNumberFormControl()) }"
            [attr.aria-required]="isRequired(getPhoneNumberFormControl())"
            [formControlName]="naturalPersonControlName.PhoneNumber"
            />
          @if (shouldShowErrorAlert(getPhoneNumberFormControl())) {
            <div
              class="alert alert-danger"
              tabindex="0"
              >
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          }
          @if (
            shouldShowErrorAlert(getPhoneNumberFormControl(), 'phoneInvalid')
            ) {
            <div
              class="alert alert-danger"
              tabindex="0"
              >
              {{ 'FIELD_FORMAT_WRONG' | translate }}
            </div>
          }
        </div>
      }
      @if (getEmailFormControl()) {
        <div class="col-md col-sm-6 col-xs-12 my-1">
          <label class="text-nowrap" [attr.for]="naturalPersonControlName.Email">{{
            'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.INVESTOR_DETAILS.FIELDS.E_MAIL'
            | translate
          }}</label>
          <input
            type="text"
            [name]="naturalPersonControlName.Email"
            [id]="naturalPersonControlName.Email"
            class="form-control form-control-sm"
            [ngClass]="{ required: isRequired(getEmailFormControl()) }"
            [attr.aria-required]="isRequired(getEmailFormControl())"
            [formControlName]="naturalPersonControlName.Email"
            />
          @if (shouldShowErrorAlert(getEmailFormControl(), 'required')) {
            <div
              class="alert alert-danger"
              tabindex="0"
              >
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          }
          @if (shouldShowErrorAlert(getEmailFormControl(), 'emailInvalid')) {
            <div
              class="alert alert-danger"
              tabindex="0"
              >
              {{ 'FIELD_FORMAT_WRONG' | translate }}
            </div>
          }
        </div>
      }
    </div>
  </form>
