import { LawPersonAddress } from '@gk/gk-modules';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import {
  InvestorDetails,
  InvestorDetailsRequestDto,
} from '../../../../shared/investor-details/investor-details.model';
import {
  Shipping,
  ShippingDto,
} from '../../../../shared/shipping-form/shipping-form.model';
import { ApiNewDokumentPowiazanyDalDto } from '../../../designer-incoming-documents/designer-incoming-documents.model';
import { DecisionFormType } from '../../../excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';

export interface WaterEquipment {
  investorDetails: InvestorDetails;
  proxyDetails: InvestorDetails;
  birthDate: NgbDate;
  birthPlace: string;
  watercraftType: string;
  stagingPoint: string;
  driveType: string;
  totalLength: number;
  sideHeight: number;
  largestWidth: number;
  seatsNumber: number;
  constructionMaterialType: string;
  idNumber: number;
  phoneNumber: number;
  email: string;
  address: LawPersonAddress;
  proxyDetailsCheckbox: boolean;
  attachments: ApiNewDokumentPowiazanyDalDto;
  decisionForm: DecisionFormType;
  shippingForm: Shipping;
}

export class WaterEquipmentRequestDto {
  constructor(
    public Wnioskodawca: InvestorDetailsRequestDto,
    public Pelnomocnik: InvestorDetailsRequestDto,
    public RodzajJednostkiPlywajacej: string,
    public MiejscePostoju: string,
    public RodzajNapedu: string,
    public DlugoscCalkowita: number,
    public WysokoscBoczna: number,
    public NajwiekszaSzerokosc: number,
    public IloscMiejscSiedzacych: number,
    public RodzajMaterialuKonstrukcyjnego: string,
    public Zalaczniki: ApiNewDokumentPowiazanyDalDto[],
    public FormaDecyzji: number,
    public Odbior: ShippingDto
  ) {}
  static fromAppToApi(
    data: WaterEquipment,
    attachments: ApiNewDokumentPowiazanyDalDto[]
  ): WaterEquipmentRequestDto {
    return new this(
      InvestorDetailsRequestDto.fromAppToApi({
        ...data.investorDetails,
        ...{ birthPlace: data.birthPlace },
        ...{ birthDate: data.birthDate },
      }),
      data.proxyDetailsCheckbox
        ? InvestorDetailsRequestDto.fromAppToApi(data.proxyDetails)
        : null,
      data.watercraftType,
      data.stagingPoint,
      data.driveType,
      data.totalLength,
      data.sideHeight,
      data.largestWidth,
      data.seatsNumber,
      data.constructionMaterialType,
      attachments,
      data.decisionForm,
      ShippingDto.fromAppToApi(data.shippingForm)
    );
  }
}
