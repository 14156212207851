import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { filter, shareReplay, takeUntil } from 'rxjs/operators';
import { TfaService } from '../../../../gk-user-settings/services/tfa/tfa.service';
import {
  TfaTotpInitializationCredentials,
  TfaTotpInitializationCredentialsApi,
} from './tfa-totp-initialization-credentials.model';

@Injectable({
  providedIn: 'root',
})
export class TfaTotpInitializationCredentialsService {
  private tfaTotpInitializationCredentials: Observable<TfaTotpInitializationCredentials>;
  constructor(private httpClient: HttpClient, private tfaService: TfaService) {}

  get(): Observable<TfaTotpInitializationCredentials> {
    if (!this.tfaTotpInitializationCredentials) {
      return this.forceFetch();
    }
    return this.tfaTotpInitializationCredentials;
  }

  forceFetch(): Observable<TfaTotpInitializationCredentials> {
    this.tfaTotpInitializationCredentials = this.httpClient
      .get<TfaTotpInitializationCredentialsApi>(
        '/api/system/login/2fa/totp/initialization/credentials'
      )
      .pipe(
        takeUntil(
          this.tfaService.isTfaTotpInOperation().pipe(filter((val) => !val))
        ),
        map((data) => TfaTotpInitializationCredentials.fromApiToApp(data)),
        shareReplay(1)
      );

    return this.tfaTotpInitializationCredentials;
  }
}
