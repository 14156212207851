import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { GkComponentsModule, GkMapModule } from '@gk/gk-modules';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { createTranslateLoader } from '../address-number-request-portal/address-number-request-portal.module';
import { NewSupplementaryParcelPurchaseRequestComponent } from './new-supplementary-parcel-purchase-request/new-supplementary-parcel-purchase-request.component';
import { SupplementaryParcelPurchasePortalRoutingModule } from './supplementary-parcel-purchase-portal-routing.model';
import { SupplementaryParcelPurchasePortalComponent } from './supplementary-parcel-purchase-portal.component';

@NgModule({
  imports: [
    SharedModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    GkMapModule,
    GkComponentsModule,
    SupplementaryParcelPurchasePortalRoutingModule,
  ],
  declarations: [
    SupplementaryParcelPurchasePortalComponent,
    NewSupplementaryParcelPurchaseRequestComponent,
  ],
})
export class SupplementaryParcelPurchasePortalModule {}
