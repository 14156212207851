import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GkComponentsModule, GkMapModule } from '@gk/gk-modules';
import { SharedModule } from '../../shared/shared.module';
import { DesignerPortalModule } from '../designer-portal/designer-portal.module';
import { AgriculturalLandUseRequestComponent } from './agricultural-land-use-request/agricultural-land-use-request.component';
import { ExcludingLandFromAgriculturalProductionPortalRoutingModule } from './excluding-land-from-agricultural-production-portal-routing.module';
import { ExcludingLandFromAgriculturalProductionPortalComponent } from './excluding-land-from-agricultural-production-portal.component';
import { NonAgriculturalLandUseRequestComponent } from './non-agricultural-land-use-request/non-agricultural-land-use-request.component';

@NgModule({
  imports: [
    CommonModule,
    ExcludingLandFromAgriculturalProductionPortalRoutingModule,
    SharedModule,
    GkMapModule,
    GkComponentsModule,
    DesignerPortalModule,
  ],
  declarations: [
    ExcludingLandFromAgriculturalProductionPortalComponent,
    AgriculturalLandUseRequestComponent,
    NonAgriculturalLandUseRequestComponent,
  ],
})
export class ExcludingLandFromAgriculturalProductionPortalModule {}
