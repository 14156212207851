export interface AuthorizedPersonToRegistryViewDto {
  ImieNazwisko: string;
  Pesel: string;
}

export class AuthorizedPersonToRegistryView {
  constructor(
    public nameSurname: string,
    public pesel: string,
    public visible = true
  ) {}

  static fromAppToApi(
    data: AuthorizedPersonToRegistryView
  ): AuthorizedPersonToRegistryViewDto {
    return {
      ImieNazwisko: data.nameSurname,
      Pesel: data.pesel,
    };
  }

  static fromStringDataToApi(
    nameSurname?: string,
    pesel?: string
  ): AuthorizedPersonToRegistryViewDto {
    return {
      ImieNazwisko: nameSurname,
      Pesel: pesel,
    };
  }
}
