import { Injectable } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { Observable, map } from 'rxjs';
import { GkMapPrintConfig } from '../../models/gk-map-print-config.model';
import { GMPPrintFooterService } from '../print-footer/print-footer.service';
import {
  GMPPrintAttributesControlName,
  GMPPrintAttributesFormGroup,
} from './print-attributes-form.model';

@Injectable()
export class GMPPrintAttributesFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private gmpPrintFooterService: GMPPrintFooterService,
  ) {}

  getFormGroup(
    mapScaleZoom: number,
    componentConfig: GkMapPrintConfig,
  ): Observable<FormGroup<GMPPrintAttributesFormGroup>> {
    return this.gmpPrintFooterService.get().pipe(
      map((printFooters) =>
        this.formBuilder.group({
          [GMPPrintAttributesControlName.PrintTemplate]: [
            this.getControlValue(
              componentConfig,
              GMPPrintAttributesControlName.PrintTemplate,
            ),
          ],
          [GMPPrintAttributesControlName.MapScaleResolution]: [
            this.getControlValue(
              componentConfig,
              GMPPrintAttributesControlName.MapScaleResolution,
              mapScaleZoom,
            ),
          ],
          [GMPPrintAttributesControlName.Quality]: [
            this.getControlValue(
              componentConfig,
              GMPPrintAttributesControlName.Quality,
            ),
          ],
          [GMPPrintAttributesControlName.PrintFooter]: [
            this.getControlValue(
              componentConfig,
              GMPPrintAttributesControlName.PrintFooter,
              printFooters[0],
            ),
          ],
          [GMPPrintAttributesControlName.ShowScaleInfo]: [
            this.getControlValue(
              componentConfig,
              GMPPrintAttributesControlName.ShowScaleInfo,
            ),
          ],
          [GMPPrintAttributesControlName.ShowCoordinatesInfo]: [
            this.getControlValue(
              componentConfig,
              GMPPrintAttributesControlName.ShowCoordinatesInfo,
            ),
          ],
          [GMPPrintAttributesControlName.ShowCornersCoordinates]: [
            this.getControlValue(
              componentConfig,
              GMPPrintAttributesControlName.ShowCornersCoordinates,
            ),
          ],
          [GMPPrintAttributesControlName.ShowLegend]: [
            this.getControlValue(
              componentConfig,
              GMPPrintAttributesControlName.ShowLegend,
            ),
          ],
          [GMPPrintAttributesControlName.ShowNorthArrow]: [
            this.getControlValue(
              componentConfig,
              GMPPrintAttributesControlName.ShowNorthArrow,
            ),
          ],
          [GMPPrintAttributesControlName.ShowDocumentWithoutLegalForceLabel]: [
            this.getControlValue(
              componentConfig,
              GMPPrintAttributesControlName.ShowDocumentWithoutLegalForceLabel,
            ),
          ],
          [GMPPrintAttributesControlName.Subtitle]: [
            this.getControlValue(
              componentConfig,
              GMPPrintAttributesControlName.Subtitle,
            ),
          ],
        }),
      ),
    );
  }

  getControlValue(
    componentConfig: GkMapPrintConfig,
    configField: GMPPrintAttributesControlName,
    value?: any,
  ): { value: any; disabled?: boolean } {
    return {
      value: value || componentConfig.attributesFormDefaultValues[configField],
      disabled:
        componentConfig.attributesFormDisabledFields.includes(configField) ||
        !componentConfig.attributesFormVisibleFields.includes(configField),
    };
  }
}
