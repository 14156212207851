import { FormControl } from '@angular/forms';
import { FieldId, LawPerson } from '@gk/gk-modules';
import { DecisionFormType } from '../../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';

export enum LBRCNNewDataChangesNotificationControlName {
  LawPerson = 'lawPerson',
  DecisionForm = 'decisionForm',
  ShippingForm = 'shippingForm',
  ChangeScope = 'changeScope',
  DemonstrationOfApplicantRight = 'demonstrationOfApplicantRight',
  DeterminationOfLocation = 'determinationOfLocation',
}

export interface LBRCNNewDataChangesNotificationFormGroup {
  [LBRCNNewDataChangesNotificationControlName.LawPerson]: FormControl<LawPerson>;
  [LBRCNNewDataChangesNotificationControlName.DecisionForm]: FormControl<DecisionFormType>;
  [LBRCNNewDataChangesNotificationControlName.ShippingForm]: FormControl<Shipping>;
  [LBRCNNewDataChangesNotificationControlName.ChangeScope]: FormControl<string>;
  [LBRCNNewDataChangesNotificationControlName.DemonstrationOfApplicantRight]: FormControl<FieldId>;
  [LBRCNNewDataChangesNotificationControlName.DeterminationOfLocation]: FormControl<string>;
}
