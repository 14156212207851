import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, shareReplay } from 'rxjs';
import { map } from 'rxjs/operators';
import { getCacheString } from '../../utils/utils';
import { ApiResponse } from '../services.model';
import {
  ReceivingTypesItemForms,
  ShippingForm,
  ShippingFormDto,
  ShippngFormDataType,
} from './shipping-form.model';

@Injectable()
export class ShippingService {
  private shippingForms: {
    [id: string]: Observable<ShippingForm[]>;
  } = {};
  constructor(private httpClient: HttpClient) {}

  getShippingForms(
    portalId: number | string,
    receivingTypesItemForms: ReceivingTypesItemForms = ReceivingTypesItemForms.Electronic,
    personalData: ShippngFormDataType = ShippngFormDataType.NotPersonal,
    isWebService = false,
    params?: { [key: string]: unknown },
  ): Observable<ShippingForm[]> {
    const cacheString = getCacheString(
      portalId,
      receivingTypesItemForms,
      personalData,
      isWebService,
    );
    if (!this.shippingForms[cacheString]) {
      _.set(
        this.shippingForms,
        [cacheString],
        this.httpClient
          .get<ApiResponse<ShippingFormDto[]>>(
            `/api/interesant/slo/sposoby/wysylki/portal/${portalId}`,
            {
              params: {
                CzyDrukowane:
                  receivingTypesItemForms !== ReceivingTypesItemForms.Electronic
                    ? 'true'
                    : 'false',
                CzyDaneOs:
                  personalData === ShippngFormDataType.Personal
                    ? 'true'
                    : 'false',
                CzyWebSerwis: isWebService ? 'true' : 'false',
                ...params,
              },
            },
          )
          .pipe(
            map((data) =>
              data.Response.map((element) =>
                ShippingForm.fromApiToApp(element),
              ),
            ),
            shareReplay(1),
          ),
      );
    }

    return this.shippingForms[cacheString];
  }
}
