export enum WaterEquipmentControlName {
  BirthPlace = 'birthPlace',
  BirthDate = 'birthDate',
  ProxyDetailsCheckbox = 'proxyDetailsCheckbox',
  ProxyDetails = 'proxyDetails',
  InvestorDetails = 'investorDetails',
  WatercraftType = 'watercraftType',
  StagingPoint = 'stagingPoint',
  DriveType = 'driveType',
  TotalLength = 'totalLength',
  SideHeight = 'sideHeight',
  LargestWidth = 'largestWidth',
  SeatsNumber = 'seatsNumber',
  ConstructionMaterialType = 'constructionMaterialType',
  TechnicalConditionStatementCheckbox = 'technicalConditionStatement',
  CommitmentStatementCheckbox = 'commitmentStatementCheckbox',
  DecisionForm = 'decisionForm',
  ShippingForm = 'shippingForm'
}
