<td class="pb-4">
  <span
    [ngbTooltip]="tableDataCellTooltip"
    [disableTooltip]="shouldDisableTooltip()"
    tooltipClass="horizontal-tooltip"
    >{{
      record && record
        | translate
        | dateWithoutTime
        | slice: 0 : wrapCellConfig.characterLimitUntilWrap
    }}
    @if (isRecordWrapped()) {
      ...
    }
  </span>
</td>
<ng-template #tableDataCellTooltip><p [innerHTML]="record"></p></ng-template>
