import { Inject, Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Injectable()
export class NewRequestHelperService {
  constructor(
    private domSanitizer: DomSanitizer,
    @Inject('window') private window: Window
  ) {}

  sanitizeDocSignUrl(docSigningUrl: string): SafeUrl {
    return this.domSanitizer.bypassSecurityTrustUrl(docSigningUrl);
  }

  openWindowWithSafeUrl(safeUrl: SafeUrl): void {
    this.window.open(safeUrl as string);
  }
}
