import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Scale } from '../../../../models';
import * as _ from 'lodash';
@Component({
  selector: 'gk-scale-dropdown',
  templateUrl: './scale-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScaleDropdownComponent {
  @Input()
  scales: Scale[];
  @Input()
  allowedResolution: number;
  @Input()
  restriction: boolean;
  @Output()
  resolutionChange = new EventEmitter<number>();

  getScaleNameByResolution(resolution: number): string {
    return _.get(
      this.scales.find((scale) => scale.resolution === resolution),
      'name',
      ''
    );
  }

  emitResolutionChange(scaleName: string): void {
    const newResolution = _.get(
      this.scales.find((scale) => scale.name === scaleName),
      'resolution'
    );

    this.resolutionChange.emit(newResolution);
  }
}
