import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { GkComponentsModule, GkMapModule } from '@gk/gk-modules';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { createTranslateLoader } from '../address-number-request-portal/address-number-request-portal.module';
import { NewPropertyLeaseRequestComponent } from './new-property-lease-request/new-property-lease-request.component';
import { PropertyLeasePortalRoutingModule } from './property-lease-portal-routing.model';
import { PropertyLeasePortalComponent } from './property-lease-portal.component';

@NgModule({
  imports: [
    SharedModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    GkMapModule,
    GkComponentsModule,
    PropertyLeasePortalRoutingModule,
  ],
  declarations: [
    PropertyLeasePortalComponent,
    NewPropertyLeaseRequestComponent,
  ],
})
export class PropertyLeasePortalModule {}
