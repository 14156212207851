@if (shouldShowControl()) {
  <div gkControlRow>
    <span gkControlLabel [showLabel]="controlConfig.showLabel">
      <gk-translated-label
        [configLabel]="controlConfig.label"
        ></gk-translated-label
        >:
      </span>
      <span gkControlValue [showLabel]="controlConfig.showLabel">
        <gk-payment-button
          [invoices]="[getValue()]"
          [pathToAmount]="controlConfig.pathToAmount"
          (isPaymentInProgress)="setPaymentProgressStatus($event)"
          [portalName]="controlConfig.portalName"
          [invoiceIdFieldName]="'FaktUuid'"
        ></gk-payment-button>
        @if (isPaymentInProgress) {
          <span
            >{{ 'GK.DYNAMIC_LIST.PAYMENT_IN_PROGRESS' | translate }}
            @if (isPaymentInProgress) {
              <div
                class="d-flex justify-content-center my-3"
                >
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
                </div>
              </div>
            }
          </span>
        }
      </span>
    </div>
  }
