import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  DocSignService,
  PetentData,
  PortalId,
  PzService,
} from '@gk/gk-modules';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { takeWhile } from 'rxjs';
import { AttachmentEvent } from '../../../services/designer-incoming-documents/designer-incoming-documents.model';
import { DecisionFormType } from '../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { BsMessageType } from '../../../services/request-workspace-state/request-workspace-state.model';
import {
  RightToGrantType,
  rightToGrantPermissionNumberRequired,
} from '../../../services/rights-to-grant/rights-to-grant.model';
import { RightsToGrantService } from '../../../services/rights-to-grant/rights-to-grant.service';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';

@Component({
  selector: 'app-new-grant',
  templateUrl: './new-grant.component.html',
  styleUrls: ['./new-grant.component.scss'],
})
export class NewGrantComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy
{
  override isAlive = true;
  newGrantsForm = {} as UntypedFormGroup;
  override petentData = {} as PetentData;
  waitingForData = true;
  formCreated = false;
  override submitted = false;
  rightToGrantTypeEnum = RightToGrantType;
  error = false;
  rightToGrantPermissionNumberRequired = rightToGrantPermissionNumberRequired;
  override portalId = PortalId.GrantingRights;
  override decisionFormType = DecisionFormType;
  override decisionFormValue = DecisionFormType.ElectronicForm;
  successSigned = false;

  constructor(
    private fb: UntypedFormBuilder,
    private rightsToGrantService: RightsToGrantService,
    public override router: Router,
    public override newRequestHelperService: NewRequestHelperService,
    public override docSignService: DocSignService,
    public override translateService: TranslateService,
    public override pzService: PzService,
  ) {
    super(
      pzService,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
    );
  }

  override ngOnInit(): void {
    this.subscribeToPetentData();
    super.subscribeToDocSignTranslations();
  }

  override subscribeToPetentData(): void {
    this.pzService
      .getPetentData()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((petentData) => {
        if (_.isEmpty(petentData)) {
          this.error = true;
          return;
        }

        this.error = false;
        this.petentData = petentData;
        this.createForm();
        this.waitingForData = false;
      });
  }

  getEmptyRightGroup(): UntypedFormGroup {
    return this.fb.group({
      checked: false,
      number: null,
      attachment: null,
    });
  }

  createForm(): void {
    this.newGrantsForm = this.fb.group({
      pzLogin: this.fb.control({
        value: this.petentData.pzLogin,
        disabled: true,
      }),
      firstname: this.fb.control({
        value: this.petentData.firstname,
        disabled: true,
      }),
      lastname: this.fb.control({
        value: this.petentData.lastname,
        disabled: true,
      }),
      address: this.fb.control({
        value: this.petentData.address,
        disabled: true,
      }),
      postalCode: this.fb.control({
        value: this.petentData.postalCode,
        disabled: true,
      }),
      place: this.fb.control({ value: this.petentData.place, disabled: true }),
      phone: this.fb.control({ value: this.petentData.phone, disabled: true }),
      currentPlace: [null, Validators.required],
      companyName: [null, Validators.required],
      ...this.getRightToGrantGroups(),
    });

    this.formCreated = true;
  }

  getRightToGrantGroups(): UntypedFormGroup {
    return this.getRights().reduce(
      (formGroups, rightToGrant) => ({
        ...formGroups,
        [rightToGrant]: this.getEmptyRightGroup(),
      }),
      {} as any,
    );
  }

  handleAttachmentChangeEvent(attachmentEvent: AttachmentEvent): void {
    this.newGrantsForm
      .get(attachmentEvent.fileInputId)
      .get('attachment')
      .setValue(attachmentEvent.payload);
  }

  isPlaceValid(): boolean {
    return this.newGrantsForm.get('currentPlace').valid;
  }

  getRights(): RightToGrantType[] {
    return Object.values(RightToGrantType);
  }

  isAnyRightChosen(): boolean {
    return _.some(
      this.getRights().map(
        (rightToGrant) =>
          this.newGrantsForm.get(rightToGrant).get('checked').value,
      ),
      Boolean,
    );
  }

  getRightNumber(rightToGrantType: RightToGrantType): number {
    return this.newGrantsForm.get(rightToGrantType).get('number').value;
  }

  isRightCorrectlyFilledOrEmpty(rightToGrantType: RightToGrantType): boolean {
    const checkingRightToGrant = this.newGrantsForm.get(rightToGrantType);
    const checked = checkingRightToGrant.get('checked').value;
    const number = checkingRightToGrant.get('number').value;
    const attachment = checkingRightToGrant.get('attachment').value;

    return checked && rightToGrantPermissionNumberRequired[rightToGrantType]
      ? number && !!_.get(attachment, 'size', 0)
      : true;
  }

  isEveryRightValid(): boolean {
    return _.every(
      this.getRights().map((rightToGrant) =>
        this.isRightCorrectlyFilledOrEmpty(rightToGrant),
      ),
      (correctlyFilledOrEmpty) => !!correctlyFilledOrEmpty,
    );
  }

  isEverythingValid(): boolean {
    return (
      this.newGrantsForm.valid &&
      this.isPlaceValid() &&
      this.isAnyRightChosen() &&
      this.isEveryRightValid()
    );
  }

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    if (!this.isEverythingValid()) {
      return;
    }
    this.docSignPending = true;
    this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');
    (
      await this.rightsToGrantService.applyForNewRights(
        this.newGrantsForm.value,
      )
    )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleSendAndValidateSuccess(addedDocToSignResponse);
        },
        error: () => this.handleSendAndValidateFailure(),
      });
  }

  override handleDocSignSuccess(): void {
    this.successSigned = true;
    this.docSignUrl = '';
    this.router.navigateByUrl('/granting-rights-portal/requests-list');
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
