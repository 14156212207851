import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DepartmentCode } from '@gk/gk-modules';
import { OfficeDepartmentsAccessGuard } from '../../guards/office-departments-access-guard/office-departments-access.guard';
import { SessionGuard } from '../../guards/session/session.guard';
import { MainRequestsListComponent } from '../../shared/main-requests-list/main-requests-list.component';
import { DepartmentsComponent } from './departments/departments.component';
import { GeodesyComponent } from './departments/geodesy/geodesy.component';
import { OfficeDepartmentsPortalComponent } from './office-departments-portal.component';
import { RequestsListComponent } from './requests-list/requests-list.component';

const routes: Routes = [
  {
    path: 'office-departments-portal',
    component: OfficeDepartmentsPortalComponent,
    children: [
      {
        path: '',
        redirectTo: 'departments',
        pathMatch: 'full',
      },
      {
        path: `department/${DepartmentCode.Geodesy}`,
        component: GeodesyComponent,
      },
      {
        path: `department/:id/:masterId`,
        component: RequestsListComponent,
      },
      { path: `department/:id`, component: RequestsListComponent },
      {
        path: 'departments',
        component: DepartmentsComponent,
        canActivate: [OfficeDepartmentsAccessGuard],
      },
      {
        path: 'requests-list',
        component: MainRequestsListComponent,
        canActivate: [SessionGuard],
      },
      {
        path: '**',
        redirectTo: 'departments',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OfficeDepartmentsPortalRoutingModule {}
