export enum TreesAndShrubsFellingDocTypeId {
  Other = 8,
  LegalTitleToRealEstate = 10039,
  StampDuty = 10040,
  SituationPlan = 10041,
  PowerOfAttorney = 10035
}

export enum TreesAndShrubsFellingDocTypeName {
  ProxyStampDuty = 'proxy stamp duty',
  RequestStampDuty = 'request stamp duty'
}

export enum TreesAndShrubsFellingDocTypeLabelKey {
  Other = 'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.DOC_TYPE_DESCRIPTION.OTHER',
  RequestStampDuty = 'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.DOC_TYPE_DESCRIPTION.REQUEST_STAMP_DUTY',
  PowerOfAttorney = 'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.DOC_TYPE_DESCRIPTION.POWER_OF_ATTORNEY',
  StampDuty = 'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.DOC_TYPE_DESCRIPTION.STAMP_DUTY',
  LegalTitleToRealEstate = 'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.DOC_TYPE_DESCRIPTION.LEGAL_TITLE_TO_REAL_ESTATE',
  SituationPlan = 'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.DOC_TYPE_DESCRIPTION.SITUATIONAL_PLAN'
}

export function getTreesAndShrubsFellingDocTypeLabelKeyById(id: number): string {
  switch (id) {
    case TreesAndShrubsFellingDocTypeId.Other: {
      return TreesAndShrubsFellingDocTypeLabelKey.Other;
    }
    case TreesAndShrubsFellingDocTypeId.PowerOfAttorney: {
      return TreesAndShrubsFellingDocTypeLabelKey.PowerOfAttorney;
    }
    case TreesAndShrubsFellingDocTypeId.StampDuty: {
      return TreesAndShrubsFellingDocTypeLabelKey.StampDuty;
    }
    case TreesAndShrubsFellingDocTypeId.LegalTitleToRealEstate: {
      return TreesAndShrubsFellingDocTypeLabelKey.LegalTitleToRealEstate;
    }
    case TreesAndShrubsFellingDocTypeId.SituationPlan: {
      return TreesAndShrubsFellingDocTypeLabelKey.SituationPlan;
    }
    default: {
      return '';
    }
  }
}
