import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RequestInProgressGuard } from '../../guards/request-in-progress/request-in-progress.guard';
import { SessionGuard } from '../../guards/session/session.guard';
import { MainRequestsListComponent } from '../../shared/main-requests-list/main-requests-list.component';
import { BuildingObjectsComponent } from './building-objects/building-objects.component';
import { BuildingWorksComponent } from './building-works/building-works.component';
import { InfrastructureDevicesComponent } from './infrastructure-devices/infrastructure-devices.component';
import { LaneOccupancyPortalComponent } from './lane-occupancy-portal.component';

const laneOccupancyPortalRoutes: Routes = [
  {
    path: 'lane-occupancy-infrastructure-devices',
    component: LaneOccupancyPortalComponent,
    canActivate: [SessionGuard],
    children: [
      {
        path: '',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
      {
        path: 'new-request',
        component: InfrastructureDevicesComponent,
        canDeactivate: [RequestInProgressGuard],
      },
      {
        path: 'requests-list',
        component: MainRequestsListComponent,
      },
      {
        path: 'requests-list',
        component: MainRequestsListComponent,
      },
      {
        path: '**',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'lane-occupancy-building-objects',
    component: LaneOccupancyPortalComponent,
    canActivate: [SessionGuard],
    children: [
      {
        path: '',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
      {
        path: 'new-request',
        component: BuildingObjectsComponent,
        canDeactivate: [RequestInProgressGuard],
      },
      {
        path: 'requests-list',
        component: MainRequestsListComponent,
      },
      {
        path: '**',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'lane-occupancy-building-works',
    component: LaneOccupancyPortalComponent,
    canActivate: [SessionGuard],
    children: [
      {
        path: '',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
      {
        path: 'new-request',
        component: BuildingWorksComponent,
        canDeactivate: [RequestInProgressGuard],
      },
      {
        path: 'requests-list',
        component: MainRequestsListComponent,
      },
      {
        path: '**',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(laneOccupancyPortalRoutes)],
  providers: [SessionGuard, RequestInProgressGuard],
  exports: [RouterModule],
})
export class LaneOccupancyPortalRoutingModule {}
