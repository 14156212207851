import {
  LawPerson,
  LawPersonAddress,
  LawPersonType,
  NewDesignerRequestDto,
  NewLegalPersonRequestDto,
  NewNaturalPersonRequestDto,
  getValueWhenPropertyIsAddressFields,
  getValueWhenPropertyIsString,
} from '@gk/gk-modules';
import { ApiNewDokumentPowiazanyDalDto } from '../../../services/designer-incoming-documents/designer-incoming-documents.model';
import { DecisionFormType } from '../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { ZamowienieDzialkaDto } from '../../../services/excluding-land-from-agricultural-production/agricultural-land-use/agricultural-land-use.model';
import {
  Shipping,
  ShippingDto,
} from '../../../shared/shipping-form/shipping-form.model';

export interface CommunalPremisePurchaseDto {
  Wnioskodawca:
    | NewNaturalPersonRequestDto
    | NewLegalPersonRequestDto
    | NewDesignerRequestDto;
  Dzialki: ZamowienieDzialkaDto[];
  DaneLokalu: PremisesAddressRequestDto;
  Zalaczniki: ApiNewDokumentPowiazanyDalDto[];
  FormaDecyzji: number;
  Odbior: ShippingDto;
  InfoDodatkowe: string;
}

export class CommunalPremisePurchase {
  constructor(
    public lawPerson: LawPerson,
    public premisesAddress: LawPersonAddress,
    public decisionForm: DecisionFormType,
    public shippingForm: Shipping,
    public comments: string
  ) {}

  static fromAppToApi(
    data: CommunalPremisePurchase,
    parcels: ZamowienieDzialkaDto[],
    files: ApiNewDokumentPowiazanyDalDto[]
  ): CommunalPremisePurchaseDto {
    return {
      Wnioskodawca:
        data.lawPerson.type === LawPersonType.Natural
          ? NewNaturalPersonRequestDto.fromAppToApi(data.lawPerson)
          : NewLegalPersonRequestDto.fromAppToApi(data.lawPerson),
      Dzialki: parcels,
      DaneLokalu: PremisesAddressRequestDto.fromApiToApp(data.premisesAddress),
      Zalaczniki: files,
      FormaDecyzji: data.decisionForm,
      Odbior: ShippingDto.fromAppToApi(data.shippingForm),
      InfoDodatkowe: data.comments,
    };
  }
}

export class PremisesAddressRequestDto {
  constructor(
    public MiejscId: string | number,
    public Miejsc: string,
    public UlicaId: string | number,
    public Ulica: string,
    public KodId: string | number,
    public Kod: string,
    public Numer: string,
    public Numer2: string
  ) {}

  static fromApiToApp(data: LawPersonAddress): PremisesAddressRequestDto {
    return new this(
      getValueWhenPropertyIsAddressFields(data.place),
      getValueWhenPropertyIsString(data.place),
      getValueWhenPropertyIsAddressFields(data.street),
      getValueWhenPropertyIsString(data.street),
      getValueWhenPropertyIsAddressFields(data.postalCode),
      getValueWhenPropertyIsString(data.postalCode),
      data.buildingNumber,
      data.localNumber
    );
  }
}
