import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DepartmentCode } from '@gk/gk-modules';
import { map, Observable } from 'rxjs';
import { OfficeDepartment } from '../../services/office-departments/office-department.model';
import { OfficeDepartmentsService } from '../../services/office-departments/office-departments.service';
import { MainRoutes } from '../guard.models';

@Injectable()
export class OfficeDepartmentsAccessGuard  {
  constructor(
    private router: Router,
    private officeDepartmentsService: OfficeDepartmentsService
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.officeDepartmentsService.officeDepartments.pipe(
      map((officeDepartments) => this.checkAccess(officeDepartments))
    );
  }

  navigateToGeodesyDepartment(): void {
    this.router.navigate([`/${MainRoutes.Department}`, DepartmentCode.Geodesy]);
  }

  checkAccess(officeDepartments: OfficeDepartment[]): boolean {
    if (officeDepartments.length > 1) {
      return true;
    } else {
      this.navigateToGeodesyDepartment();
      return false;
    }
  }
}
