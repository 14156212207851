import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { filter, shareReplay, takeUntil } from 'rxjs/operators';
import { TfaService } from '../../../../gk-user-settings/services/tfa/tfa.service';
import {
  TfaWebAuthnInitializationCredentials,
  TfaWebAuthnInitializationCredentialsApi,
} from './tfa-web-authn-initialization-credentials.model';

@Injectable({
  providedIn: 'root',
})
export class TfaWebAuthnInitializationCredentialsService {
  private tfaWebAuthnInitializationCredentials: Observable<TfaWebAuthnInitializationCredentials>;
  constructor(private httpClient: HttpClient, private tfaService: TfaService) {}

  get(forceAjax?: boolean): Observable<TfaWebAuthnInitializationCredentials> {
    if (!this.tfaWebAuthnInitializationCredentials || forceAjax) {
      this.tfaWebAuthnInitializationCredentials = this.httpClient
        .get<TfaWebAuthnInitializationCredentialsApi>(
          '/api/system/login/2fa/webauthn/initialization/credentials'
        )
        .pipe(
          takeUntil(
            this.tfaService
              .isTfaWebAuthnInOperation()
              .pipe(filter((val) => !val))
          ),
          map((data) =>
            TfaWebAuthnInitializationCredentials.fromApiToApp(data)
          ),
          shareReplay(1)
        );
    }
    return this.tfaWebAuthnInitializationCredentials;
  }
}
