import { Component, inject, OnDestroy } from '@angular/core';
import { BaseDetailsService } from './base-details.service';
import { map, Observable, takeWhile } from 'rxjs';
import { BaseDetailSettings, DetailType } from './base-details.model';
import { GkWindowRef } from '../services/kendo-window/kendo-window.model';

@Component({
  selector: 'gk-base-details',
  templateUrl: './base-details.component.html',
})
export class BaseDetailsComponent<T> implements OnDestroy {
  protected readonly DetailType = DetailType;
  public isAlive = true;
  windowRef: GkWindowRef;

  protected details: BaseDetailSettings[] = [];
  parseCallback: (dto: T) => BaseDetailSettings[];
  baseDetailsService = inject(BaseDetailsService);

  getDetailsByUrl(url: string): Observable<T> {
    return this.baseDetailsService.getDetailsByUrl<T>(url);
  }

  loadDetailsFromAPI(url: string): void {
    this.getDetailsByUrl(url)
      .pipe(
        takeWhile(() => this.isAlive),
        map((settingsFromApi) => {
          if (typeof this.parseCallback === 'function') {
            return this.parseCallback(settingsFromApi);
          } else {
            return this.generateDefaultDetails(settingsFromApi);
          }
        })
      )
      .subscribe((details) => (this.details = details));
  }

  setDetails(details: T): void {
    if (typeof this.parseCallback === 'function') {
      this.details = this.parseCallback(details);
    } else {
      this.details = this.generateDefaultDetails(details);
    }
  }

  loadDetailsWithData(data: T): void {
    if (typeof this.parseCallback === 'function') {
      this.details = this.parseCallback(data);
    } else {
      this.details = this.generateDefaultDetails(data);
    }
  }

  generateDefaultDetails(dto: T): BaseDetailSettings[] {
    return Object.entries(dto).map(([key, value]) => ({
      title: key,
      value: value,
      type: DetailType.Text,
    }));
  }

  isString(value: any): value is string {
    return typeof value === 'string';
  }

  convertToString(value: any): string {
    if (!value) {
      return '';
    }

    return this.isString(value) ? value : value.toString();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
