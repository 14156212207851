import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { takeWhile } from 'rxjs';
import { SizeReportsLimitFromApi } from './size-reports-limit-model';
import { SizeReportsLimitService } from './size-reports-limit.service';

@Component({
  selector: 'gk-size-reports-limit-component',
  templateUrl: './size-reports-limit.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class SizeReportsLimitComponent implements OnInit, OnDestroy {
  private isAlive = true;
  constructor(private sizeReportsLimitService: SizeReportsLimitService) {}

  limits: SizeReportsLimitFromApi[];

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData(): void {
    this.sizeReportsLimitService
      .subscribeToSizeReportsLimitData()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((limits) => (this.limits = limits));
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
