import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ListConfigs } from '@gk/gk-modules';
import { Observable, ReplaySubject, shareReplay } from 'rxjs';
import { combineLatestWith, map } from 'rxjs/operators';
import { SessionService } from '../session/session.service';
import {
  DesignerSettings,
  DesignerSettingsFromApi,
} from './designer-settings.model';

@Injectable()
export class DesignerSettingsService {
  private settings: Observable<DesignerSettings>;
  listConfigs = new ReplaySubject<ListConfigs>();

  constructor(
    private httpClient: HttpClient,
    private sessionService: SessionService
  ) {}

  getDesignerSettings(): Observable<DesignerSettings> {
    if (!this.settings) {
      this.settings = this.httpClient
        .get<DesignerSettingsFromApi>('/api/zudp/projektant/konfig')
        .pipe(
          map((designerSettingsFromApi) =>
            DesignerSettings.fromApiToApp(designerSettingsFromApi)
          ),
          shareReplay(1)
        );
    }

    return this.settings;
  }

  isPzSessionOrPzSignRequired(): Observable<boolean> {
    return this.getDesignerSettings().pipe(
      map((settings) => settings.pzSignRequired),
      combineLatestWith(this.sessionService.systemSession),
      map(
        ([pzSignRequired, systemSession]) =>
          systemSession.isPzAuthenticated() || pzSignRequired
      )
    );
  }
}
