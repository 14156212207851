import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { getDefaultControlValue } from '@gk/gk-modules';
import { DecisionFormType } from '../../excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { InvestorDetailsFormService } from '../../investor-details-form/investor-details-form.service';
import { ShippingFormService } from '../../shipping-form/shipping-form.service';
import { FishingCardControlName } from './fishing-card-form.model';

@Injectable()
export class FishingCardFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private investorDetailsFormService: InvestorDetailsFormService,
    public shippingFormService: ShippingFormService
  ) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [FishingCardControlName.InvestorDetails]:
        this.investorDetailsFormService.getFormGroup(),
      [FishingCardControlName.BirthDate]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [FishingCardControlName.BirthPlace]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [FishingCardControlName.ProxyDetailsCheckbox]: [getDefaultControlValue()],
      [FishingCardControlName.DecisionForm]: [
        DecisionFormType.ElectronicForm,
        [Validators.required],
      ],
      [FishingCardControlName.ShippingForm]:
        this.shippingFormService.getFormGroup(),
    });
  }
}
