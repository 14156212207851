import { EventEmitter, Injectable } from '@angular/core';
import { GkKendoUploadComponent } from './gk-kendo-upload.component';
import { GkKendoWindowService } from '../services/kendo-window/gk-kendo-window/gk-kendo-window.service';
import { KendoWindowInstanceNames } from '../services/kendo-window/kendo-window.model';
import {
  GkKendoUploadEvent,
  UploadConfig,
  UploadDocTypeName,
} from './gk-kendo-upload.model';
import { GkKendoUploadDocTypeDictionaryService } from './gk-kendo-upload-doc-type-dictionary/gk-kendo-upload-doc-type-dictionary.service';

@Injectable()
export class GkKendoUploadService {
  onUploadButtonClick: (
    e: EventEmitter<GkKendoUploadEvent>,
  ) => Promise<void> | undefined;
  constructor(
    private gkKendoWindowService: GkKendoWindowService,
    private gkKendoUploadDocTypeDictionaryService: GkKendoUploadDocTypeDictionaryService,
  ) {}

  openUploadWindow(uploadConfig: UploadConfig): void {
    const windowRef = this.gkKendoWindowService.open({
      parent: this,
      windowInstanceName: KendoWindowInstanceNames.GkKendoUpload,
      title: `UPLOAD_WINDOW.SPECIFY_FILE_ATTR_TITLE`,
      content: GkKendoUploadComponent,
      width: 400,
    });

    const uploadComponent = windowRef.content
      .instance as GkKendoUploadComponent;

    uploadComponent.uploadSaveUrl = uploadConfig.uploadSaveUrl;
    uploadComponent.uploadData = uploadConfig.uploadData;
    uploadComponent.onUploadSuccess = uploadConfig.onUploadSuccess;
    uploadComponent.onUploadError = uploadConfig.onUploadError;

    if (uploadConfig.onUploadButtonClick) {
      this.onUploadButtonClick = uploadConfig.onUploadButtonClick;
    } else {
      this.onUploadButtonClick = undefined;
    }

    switch (uploadConfig.uploadDocTypeDict) {
      case UploadDocTypeName.WorkReports:
        uploadComponent.typeOfDocs =
          this.gkKendoUploadDocTypeDictionaryService[
            UploadDocTypeName.WorkReports
          ].value;
        break;
      case UploadDocTypeName.Work:
        uploadComponent.typeOfDocs =
          this.gkKendoUploadDocTypeDictionaryService[
            UploadDocTypeName.Work
          ].value;
        break;
      case UploadDocTypeName.FeeConfirmation:
        uploadComponent.typeOfDocs =
          this.gkKendoUploadDocTypeDictionaryService[
            UploadDocTypeName.FeeConfirmation
          ].value;
        break;
      default:
        uploadComponent.typeOfDocs =
          this.gkKendoUploadDocTypeDictionaryService[
            UploadDocTypeName.Default
          ].value;
    }
  }
}
