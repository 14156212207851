<div class="container mb-4">
  @if (loading) {
    <div class="d-flex justify-content-center my-3">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
      </div>
    </div>
  }

  @if (fetchedDetails && !loading) {
    <section class="col-12">
      <div class="pb-2 mt-4 mb-2 border-bottom">
        <h4>
          {{ 'ECO_PORTAL.DOCUMENT_DETAILS.DOCUMENT_DETAILS' | translate }}
        </h4>
      </div>
      @for (detailsConfig of detailsConfigs; track detailsConfig.label) {
        <gk-control-renderer
          [hidden]="loading"
          [data]="fetchedDetails"
          [controlConfig]="detailsConfig"
        ></gk-control-renderer>
      }
    </section>
    @if (fetchedHistory && fetchedHistory.length) {
      <section class="col-12 mt-5">
        <div class="pb-2 mt-4 mb-2">
          <h4>
            {{ 'ECO_PORTAL.DOCUMENT_DETAILS.PUBLISHED_VERSIONS' | translate }}
          </h4>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">
                {{ 'ECO_PORTAL.DOCUMENT_DETAILS.FIELDS.NUMBER' | translate }}
              </th>
              <th scope="col">
                {{
                  'ECO_PORTAL.DOCUMENT_DETAILS.FIELDS.DOCUMENT_NAME' | translate
                }}
              </th>
              <th scope="col">
                {{ 'ECO_PORTAL.DOCUMENT_DETAILS.FIELDS.PUBLISHER' | translate }}
              </th>
              <th scope="col">
                {{
                  'ECO_PORTAL.DOCUMENT_DETAILS.FIELDS.CREATION_DATE' | translate
                }}
              </th>
              <th scope="col">
                {{
                  'ECO_PORTAL.DOCUMENT_DETAILS.FIELDS.MODIFICATION_DATE'
                    | translate
                }}
              </th>
              <th scope="col">
                {{
                  'ECO_PORTAL.DOCUMENT_DETAILS.FIELDS.PUBLICATION_DATE'
                    | translate
                }}
              </th>
              <th scope="col">
                {{ 'ECO_PORTAL.DOCUMENT_DETAILS.FIELDS.ACTION' | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            @for (documentHistory of fetchedHistory; track documentHistory.id) {
              <tr>
                <th scope="row">{{ documentHistory.number }}</th>
                <td>{{ documentHistory.name }}</td>
                <td>{{ documentHistory.publisher }}</td>
                <td>{{ getUiDate(documentHistory.creationDate) }}</td>
                <td>{{ getUiDate(documentHistory.modificationDate) }}</td>
                <td>{{ getUiDate(documentHistory.publicationDate) }}</td>
                <td>
                  <button
                    class="btn btne-color-from-db btn-sm my-1"
                    [routerLink]="[
                      '/eco-portal/document-details',
                      documentHistory.id
                    ]"
                    [queryParams]="{ version: documentHistory.version }"
                  >
                    {{
                      'ECO_PORTAL.DOCUMENT_DETAILS.FIELDS.PREVIEW' | translate
                    }}
                  </button>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </section>
    }
    @if (fetchedDetails.Geom) {
      <section class="col-12 mt-5">
        <div class="pb-2 mt-4 mb-2 border-bottom">
          <h4>{{ 'ECO_PORTAL.DOCUMENT_DETAILS.GEOM_RANGE' | translate }}</h4>
        </div>
        <app-eco-map [geom]="fetchedDetails.Geom"></app-eco-map>
      </section>
    }
  }
</div>
