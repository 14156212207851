export interface SurveyorPendingWorkDto {
  KergWwwId: number;
  DataZgloszenia: string;
  DataPrzewidywanaZakonczenia: string;
  CelPracy: string;
  NazwaObiektu: string;
  OznaczenieNieruchomosci: string;
  NrUWykonawcy: string;
  IsZakres: boolean;
  InformacjaDlaGeodety: string;
  StatusCzatu: string;
  Status: string;
}

export class SurveyorPendingWork {
  constructor(
    public id: number, //kergWwwId
    public declarationDate: string,
    public expectedEndDate: string,
    public workPurpose: string,
    public objectNameOrPropertyDesignation: string,
    public numberAtContractor: string,
    public isRange: string,
    public informationForSurveyor: string,
    public chatStatus: string,
    public status: string,
  ) {}

  static get trueChatStatusValue(): string {
    return '1';
  }

  static get trueStringValue(): string {
    return 'Tak';
  }

  static getChatStatusString(chatStatus: string): string {
    return chatStatus === this.trueChatStatusValue ? this.trueStringValue : '';
  }

  static getIsRangeString(isRange: boolean): string {
    return isRange ? this.trueStringValue : '';
  }

  static fromApi(data: SurveyorPendingWorkDto): SurveyorPendingWork {
    return new this(
      data.KergWwwId,
      data.DataZgloszenia,
      data.DataPrzewidywanaZakonczenia,
      data.CelPracy,
      data.NazwaObiektu || data.OznaczenieNieruchomosci,
      data.NrUWykonawcy,
      SurveyorPendingWork.getIsRangeString(data.IsZakres),
      data.InformacjaDlaGeodety,
      SurveyorPendingWork.getChatStatusString(data.StatusCzatu),
      data.Status,
    );
  }
}
