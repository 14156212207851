import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { DropdownNavItemId, NavbarAction } from '../gk-dynamic-navbar.model';
import {
  IconSrc,
  initialNavbarUiState,
  NavbarUiState,
} from './gk-dynamic-navbar-ui-state-store.model';
import { map, switchMap, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GkDynamicNavbarUiStateStoreService {
  private navbarState: BehaviorSubject<NavbarUiState> =
    new BehaviorSubject<NavbarUiState>(initialNavbarUiState);

  get navbarUiState(): Observable<NavbarUiState> {
    return this.navbarState.asObservable();
  }

  private _customIconSrc = new ReplaySubject<IconSrc>();

  getCustomIconSrc(groupKey: DropdownNavItemId): Observable<IconSrc> {
    return this.navbarUiState.pipe(
      map((navbarUiState) => navbarUiState.navAction.payload),
      tap((payload) => {
        if (!payload.dropdownItemId) {
          return;
        }
        if (payload.customIconSrc) {
          this._customIconSrc.next({
            [groupKey]: payload.customIconSrc,
          });

          return;
        }
      }),
      switchMap(() => this._customIconSrc)
    );
  }

  dispatchNavbarAction(navAction: NavbarAction): void {
    this.navbarState.next({ navAction });
  }
}
