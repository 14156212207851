import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TypeOfAccessibilityStatement } from '@gk/gk-modules';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-accesibility-statement-component-wrapper',
  templateUrl: './accessibility-statement-component-wrapper.html',
})
export class AccessibilityStatementWrapperComponent
  implements OnInit, OnDestroy
{
  public statementType: TypeOfAccessibilityStatement;
  private isAlive = true;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.setStatementType();
  }

  setStatementType(): void {
    this.route.url
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((routeParams) => {
        this.statementType = routeParams[0].path as TypeOfAccessibilityStatement;
      });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
