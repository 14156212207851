import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, delay, throwError } from 'rxjs';
import { switchMap, takeWhile, tap } from 'rxjs/operators';
import { TfaType } from '../../gk-user-settings/services/tfa/tfa.model';
import { TfaService } from '../../gk-user-settings/services/tfa/tfa.service';
import { LoginResponse } from './services/login/login.model';
import { LoginService } from './services/login/login.service';
import { MessageStatus } from './services/message-status/message-status.model';
import { NewPasswordService } from './services/new-password/new-password.service';
import { SessionToolMode } from './services/session-tools/session-tools.model';
import { SessionToolsService } from './services/session-tools/session-tools.service';
import { TfaConfirmationService } from './services/tfa-confirmation/tfa-confirmation.service';
import { TfaResetService } from './services/tfa-reset/tfa-reset.service';
import { TfaWebAuthnVerificationService } from './services/tfa-web-authn-verification/tfa-web-authn-verification.service';

@Directive()
export abstract class SessionToolsBaseComponent implements OnInit, OnDestroy {
  abstract isAlive: boolean;
  abstract successDefaultMessage: string;
  externalLoginUrl: string;
  messageText: string;
  actionRequestInProgress = false;
  submitted = false;
  messageStatus: MessageStatus;
  messageStatusEnum = MessageStatus;
  errorDefaultMessage: string;

  constructor(
    public translateService: TranslateService,
    public router?: Router,
    public newPasswordService?: NewPasswordService,
    public tfaService?: TfaService,
    public tfaConfirmationService?: TfaConfirmationService,
    public tfaResetService?: TfaResetService,
    public sessionToolsService?: SessionToolsService,
    public tfaWebAuthnVerificationService?: TfaWebAuthnVerificationService,
    public loginService?: LoginService
  ) {}

  ngOnInit() {
    this.fetchDefaultErrorMessage();
  }

  fetchDefaultErrorMessage(): void {
    this.translateService
      .get('SESSION_TOOLS.ERROR')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((message) => {
        this.errorDefaultMessage = message;
      });
  }

  handleSuccessLoginResponse(
    response: LoginResponse,
    successMessage = this.successDefaultMessage
  ): void {
    switch (response.errorCode) {
      case 0: {
        this.checkWhetherTfaIsEnabled()
          .pipe(
            takeWhile(() => this.isAlive),
            switchMap((tfaType) => {
              this.actionRequestInProgress = false;
              if (!tfaType) {
                return this.handleSuccessLoginWhenTfaDisabled();
              }
              this.tfaService.tfaTypeInOperation.next(tfaType);
              switch (tfaType) {
                case TfaType.Email:
                case TfaType.Totp: {
                  return this.handleTfaCodeConfirmationAction();
                }
                case TfaType.WebAuthn: {
                  return this.handleTfaWebAuthnConfirmationAction();
                }
              }
            }),
            tap(() => {
              this.switchToLoginMode();
              this.setMessageStatus(MessageStatus.Success);
              this.messageText = successMessage;
            }),
            delay(2000)
          )
          .subscribe(() => {
            this.refreshApp();
          });

        break;
      }
      case 100:
      case 101: {
        this.actionRequestInProgress = false;
        this.setMessageStatus(MessageStatus.Warning);
        this.messageText = response.errorMessage;
        this.newPasswordService.resetPasswordToken.next(response.payload);
        setTimeout(() => this.switchToNewPasswordMode(), 2000);

        break;
      }
      case 110: {
        this.actionRequestInProgress = false;
        this.setMessageStatus(MessageStatus.Warning);
        this.messageText = response.errorMessage;
        this.loginService.redirectToExternalLoginPageWithToastr(
          this.externalLoginUrl
        );

        break;
      }
      default: {
        this.actionRequestInProgress = false;
        this.setMessageStatus(MessageStatus.Warning);
        this.messageText = response.errorMessage;

        break;
      }
    }
  }

  handleTfaCodeConfirmationAction(): Observable<void> {
    this.tfaConfirmationService.tfaConfirmationInOperation.next(true);
    this.switchToTfaConfirmationMode();
    this.tfaConfirmationService.emitLoginTfaConfirmationMode();
    return this.tfaConfirmationService.verifiedConfirmation;
  }

  handleTfaWebAuthnConfirmationAction(): Observable<void> {
    return this.tfaWebAuthnVerificationService.verify().pipe(
      catchError((error) => {
        this.setMessageStatus(MessageStatus.Error);
        this.messageText = this.errorDefaultMessage;

        return throwError(() => error);
      })
    );
  }

  handleSuccessLoginWhenTfaDisabled(): Observable<void> {
    return this.tfaService.openTfaInitializationWindowIfRequired();
  }

  refreshApp(): void {
    window.location.reload();
  }

  checkWhetherTfaIsEnabled(): Observable<TfaType> {
    return this.tfaService
      .fetchCurrentTfaType()
      .pipe(
        switchMap((tfaType) => this.tfaService.executeEmailOrTotpTfa(tfaType))
      );
  }

  openResetTfaCodeWindow(event: Event): void {
    event.preventDefault();
    this.switchToTfaResetMode();
  }

  openResetPasswordWindow(event: Event): void {
    event.preventDefault();
    this.switchToResetPasswordMode();
  }

  switchToResetPasswordMode(): void {
    this.sessionToolsService.currentToolMode.next(
      SessionToolMode.ResetPassword
    );
  }

  switchToNewPasswordMode(): void {
    this.sessionToolsService.currentToolMode.next(SessionToolMode.NewPassword);
  }

  switchToLoginMode(): void {
    this.sessionToolsService.currentToolMode.next(SessionToolMode.Login);
  }

  switchToTfaConfirmationMode(): void {
    this.sessionToolsService.currentToolMode.next(
      SessionToolMode.TfaConfirmation
    );
  }

  switchToTfaResetMode(): void {
    this.sessionToolsService.currentToolMode.next(SessionToolMode.TfaReset);
  }

  setMessageStatus(status: MessageStatus): void {
    this.messageStatus = status;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
