<div class="p-4">
  <ul ngbNav #payments="ngbNav" class="nav-tabs">
    <li [ngbNavItem]="1">
      <a ngbNavLink
        >{{ 'GK.PAYMENTS.UNPAID_INVOICES' | translate }} ({{
          unpaidInvoicesTotalCount
        }})
      </a>
      <ng-template ngbNavContent>
        <gk-unpaid
          (invoicesTotalCount)="setUnpaidInvoicesTotalCount($event)"
        ></gk-unpaid>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink
        >{{ 'GK.PAYMENTS.PENDING_INVOICES' | translate }} ({{
          pendingInvoicesTotalCount
        }})
      </a>
      <ng-template ngbNavContent>
        <gk-pending
          (invoicesTotalCount)="setPendingInvoicesTotalCount($event)"
        ></gk-pending>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="payments" class="mt-2"></div>
</div>
