export interface FileEdgeState {
  lastModified: number;
  name: string;
  size: number;
  type: string;
  base64: string;
}

export interface BlobEdgeState {
  size: number;
  type: string;
  base64: string;
}

export enum MemoryUnit {
  Byte = 'B',
  KiloByte = 'KB',
}

export enum FileExtension {
  Dxf = 'dxf',
  Txt = 'txt',
  Pdf = 'pdf',
  Jpg = 'jpg',
  Jpeg = 'jpeg',
  Png = 'png',
  Gif = 'gif',
  Bmp = 'bmp',
  Tif = 'tif',
  Tiff = 'tiff',
}

export const allowedExtensionsToPreview: FileExtension[] = [
  FileExtension.Txt,
  FileExtension.Pdf,
  FileExtension.Jpg,
  FileExtension.Jpeg,
  FileExtension.Png,
  FileExtension.Gif,
  FileExtension.Bmp,
  FileExtension.Tif,
  FileExtension.Tiff,
];

export enum ExtensionMimeType {
  Pdf = 'application/pdf',
}
