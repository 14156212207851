<ng-template #buttonTemplate>
  <button
    type="button"
    class="btn btn-outline-color-from-db btn-sm bg-white text-secondary"
    [ngClass]="[buttonClass]"
    [title]="'GK.MAP.INCREASE_RANGE_BY_BUFFER' | translate"
    [attr.aria-label]="'GK.MAP.INCREASE_RANGE_BY_BUFFER' | translate"
    [disabled]="increasingRangeInProgress"
    (click)="validateAndIncreaseRangeByBuffer()"
    >
    @if (!increasingRangeInProgress) {
      <form>
        <div class="form-group d-flex mb-0">
          <label for="buffer-value" class="d-flex mb-0"
            >{{ buttonText | translate }}:</label
            >
            <div class="input-group mb-0">
              <input
                class="form-control ms-1"
                [(ngModel)]="bufferValue"
                type="number"
                name="bufferValue"
                id="buffer-value"
                (click)="$event.stopPropagation()"
                />
              <span class="input-group-text">m</span>
            </div>
          </div>
        </form>
      } @else {
        <i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i>
      }
    </button>
  </ng-template>
