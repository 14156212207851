import { AdministrativeUnitReference, MapObject } from '../../models';
import { ConversionUtils } from '../conversion/conversion.utils';
import { createEmpty, extend, Extent, getCenter } from 'ol/extent';
import { Coordinate } from 'ol/coordinate';

export class MapExtentUtils {
  static getAdministrativeUnitExtent(
    administrativeUnitReference: AdministrativeUnitReference
  ): Extent {
    return [
      administrativeUnitReference.lowX,
      administrativeUnitReference.lowY,
      administrativeUnitReference.highX,
      administrativeUnitReference.highY
    ];
  }

  static getMapCenter(mapExtent: Extent): Coordinate {
    return getCenter(mapExtent);
  }

  static getMapExtentFromMapObjects(mapObjects: MapObject[]): Extent {
    let globalExtent = createEmpty();
    mapObjects.forEach(mapObject => {
      if (mapObject.geom) {
        globalExtent = extend(
          globalExtent,
          ConversionUtils.getGeometryFromWkt(mapObject.geom).getExtent()
        );
      }
    });

    return globalExtent;
  }
}
