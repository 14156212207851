import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { PetentData, PetentDataFromApi } from './pz.model';

@Injectable()
export class PzService {
  private petentData: Observable<PetentData>;

  constructor(private httpClient: HttpClient) {}

  getPetentData(forceFetch?: boolean): Observable<PetentData> {
    if (!this.petentData || forceFetch) {
      this.petentData = this.httpClient
        .get<PetentDataFromApi>('/api/pz/wwwuser/sysuser/petent')
        .pipe(
          map((petentDataFromApi) =>
            PetentData.fromApiToApp(petentDataFromApi),
          ),
          shareReplay(1),
          catchError((err) => {
            console.error(err);

            return of(null);
          }),
        );
    }
    return this.petentData;
  }
}
