<div class="row">
  <div class="col-12 d-flex flex-wrap justify-content-between">
    <button type="button" class="btn btn-link px-0" (click)="toggle()">
      {{ 'GK.DYNAMIC_LIST.COLUMN_MANAGER.TOGGLE_COLUMNS' | translate }}
    </button>
    @if (messagesListConfig) {
      <gk-new-messages [messagesListConfig]="messagesListConfig"> </gk-new-messages>
    }
  </div>

  <div class="col-12">
    @if (showManager) {
      <ul class="list-unstyled">
        @for (column of columnConfigs; track column; let i = $index) {
          @if (!column.visibleOnlyAtFilter) {
            <li>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="{{ i }}"
                  [(ngModel)]="column.show"
                  />
                <label class="form-check-label" for="{{ i }}">
                  <gk-translated-label
                    [configLabel]="column.controlConfig.label"
                  ></gk-translated-label>
                </label>
              </div>
            </li>
          }
        }
      </ul>
    }
  </div>
</div>
