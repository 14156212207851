import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  GkComponentsModule,
  GkDynamicListModule,
  GkMapModule,
} from '@gk/gk-modules';
import { SharedModule } from '../../shared/shared.module';
import { ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestComponent } from './extract-certificate-from-local-spatial-development-plan-or-study-request/extract-certificate-from-local-spatial-development-plan-or-study-request.component';
import { PlanningDepartmentRoutingModule } from './planning-department-routing.module';
import { PlanningDepartmentComponent } from './planning-department.component';
import { ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestService } from './shared/extract-certificate-from-local-spatial-development-plan-or-study-request.service';
import { ExtractOrCertificateFromLocalSpatialDevelopmentPlanRequestFormService } from './shared/extract-or-certificate-from-local-spatial-development-plan-request-form.service';

@NgModule({
  imports: [
    CommonModule,
    PlanningDepartmentRoutingModule,
    SharedModule,
    GkMapModule,
    GkComponentsModule,
    GkDynamicListModule,
  ],
  declarations: [
    PlanningDepartmentComponent,
    ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestComponent,
  ],
  providers: [
    ExtractOrCertificateFromLocalSpatialDevelopmentPlanRequestFormService,
    ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestService,
  ],
})
export class PlanningDepartmentModule {}
