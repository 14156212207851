export interface LandAndMortgageRegisterDepartmentFromApi {
  Id: string;
  Nazwa: string;
  Symbol: string;
}

export class LandAndMortgageRegisterDepartment {
  constructor(public id: string, public name: string, public symbol: string) {}

  static fromApiToApp(
    data: LandAndMortgageRegisterDepartmentFromApi
  ): LandAndMortgageRegisterDepartment {
    return new this(data.Id, data.Nazwa, data.Symbol);
  }
}
