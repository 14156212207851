import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as _ from 'lodash';
import {
  LawPersonSearch,
  isApplicantByAuthorizedPerson,
  isLegalPerson,
  shouldShowPermissionNumber,
} from '../../services/law-person/law-person-search.model';
import { LawPersonService } from '../../services/law-person/law-person.service';
import { PersonType } from '../../services/person-type/person-type.model';
import { PersonTypeService } from '../../services/person-type/person-type.service';

@Component({
  selector: 'gk-selected-law-persons-table-container',
  templateUrl: './selected-law-persons-table-container.component.html',
})
export class SelectedLawPersonsTableContainerComponent {
  @Input() lawPersons: LawPersonSearch[];
  @Input() staticSelectedTableValue: LawPersonSearch[];
  @Input() defaultAvailablePersonTypes: PersonType[];
  @Input() legalPersonOnly: boolean;
  @Output() removedLawPersons = new EventEmitter<LawPersonSearch[]>();

  constructor(
    public lawPersonService: LawPersonService,
    private personTypeService: PersonTypeService
  ) {}

  getLawPersonRegonOrPesel(lawPerson: LawPersonSearch): string {
    return isLegalPerson(lawPerson) ? lawPerson.regon : lawPerson.pesel;
  }

  removeLawPerson(lawPersonToDelete: LawPersonSearch, event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.removedLawPersons.emit([lawPersonToDelete]);
  }

  comparePerson(
    person1: LawPersonSearch,
    person2: LawPersonSearch
  ): boolean | undefined {
    return person1 && person2 && person1.id === person2.id;
  }

  getPersonTypeName(lawPerson: LawPersonSearch): string {
    return _.get(lawPerson, 'personType.name');
  }

  isPersonTypeRequired(lawPerson: LawPersonSearch): boolean {
    return _.get(lawPerson, 'personType.isRequired');
  }

  shouldShowPersonTypeColumn(): boolean {
    return !!_.find(
      this.lawPersons,
      (lp) =>
        this.getPersonTypeName(lp) ||
        (lp.mainPersonOfDesginerPortal && lp.availablePersonTypes)
    );
  }

  getLawPersonName(
    lawPerson: LawPersonSearch,
    permissionNumberLabel: string
  ): string {
    const personData = this.getProperPersonData(lawPerson);
    return shouldShowPermissionNumber(personData)
      ? `${personData.name} (${permissionNumberLabel}: ${personData.permissionNumber})`
      : personData.name;
  }

  getAddress(lawPerson: LawPersonSearch): string {
    const personData = this.getProperPersonData(lawPerson);
    return personData.address;
  }

  getProperPersonData(lawPerson: LawPersonSearch): LawPersonSearch {
    return lawPerson.mainPersonOfDesginerPortal &&
      this.personTypeService.isDesignerPersonType(lawPerson.personType) &&
      isApplicantByAuthorizedPerson(lawPerson) &&
      lawPerson.authorizedPerson
      ? lawPerson.authorizedPerson
      : lawPerson;
  }
}
