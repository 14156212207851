import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { SessionToolMode } from './session-tools.model';

@Injectable({
  providedIn: 'root',
})
export class SessionToolsService {
  currentToolMode = new ReplaySubject<SessionToolMode>(1);
}
