import { Feature } from 'ol';
import { GeoJSON, WKT } from 'ol/format';
import { MapObject } from '../../models';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import GeoJSONReader from 'jsts/org/locationtech/jts/io/GeoJSONReader';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import WKTReader from 'jsts/org/locationtech/jts/io/WKTReader';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import JstsGeometry from 'jsts/org/locationtech/jts/geom/Geometry';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import BufferParameters from 'jsts/org/locationtech/jts/operation/buffer/BufferParameters.js';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import BufferOp from 'jsts/org/locationtech/jts/operation/buffer/BufferOp.js';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import RelateOp from 'jsts/org/locationtech/jts/operation/relate/RelateOp.js';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import UnionOp from 'jsts/org/locationtech/jts/operation/union/UnionOp.js';

import OlGeometry from 'ol/geom/Geometry';
import * as olExtent from 'ol/extent';
import { fromExtent } from 'ol/geom/Polygon';
import { StorageUtils } from '../storage/storage.utils';
import { Extent } from 'ol/extent';
import { ConversionUtils } from '../conversion/conversion.utils';

export abstract class GeomUtils {
  static readonly multiPolygonType = 'MultiPolygon';
  static areLandParcelsAdjacent(parcelsGeoms: string[]): boolean {
    const jstsGeomFromLandParcelsUnion =
      this.getUnionFromWktGeoms(parcelsGeoms);

    return !this.isMultiPolygon(jstsGeomFromLandParcelsUnion);
  }

  static getUnionFromWktGeoms(wktgeomsArr: string[]): JstsGeometry {
    const jstsWktReader = new WKTReader();
    return wktgeomsArr
      .map((wktGeom) => jstsWktReader.read(wktGeom))
      .reduce((previousJstsGeom, currentJstsGeom) =>
        UnionOp.union(previousJstsGeom, currentJstsGeom),
      );
  }

  static isMultiPolygon(jstsGeometry: JstsGeometry): boolean {
    return this.getJstsGeometryType(jstsGeometry) === this.multiPolygonType;
  }

  static getJstsGeometryType(jstsGeometry: JstsGeometry): string {
    return jstsGeometry.getGeometryType();
  }

  static validateCoverageOfRanges(
    initialMapObjects: MapObject[],
    initialEditedMapObject: MapObject,
    editedMapObject: MapObject,
  ): boolean {
    const initialGeometry = this.getGeometryOfOriginalMapObject(
      initialMapObjects,
      initialEditedMapObject,
    );
    const editedGeometry = this.getEditedObjectGeometry(editedMapObject);

    return RelateOp.covers(
      this.getGeomWithAuxiliaryBufferForCorrectValidationOfSnapPoints(
        editedGeometry,
      ),
      initialGeometry,
    );
  }

  private static getGeometryOfOriginalMapObject(
    initialMapObjects: MapObject[],
    initialEditedMapObject: MapObject,
  ): JstsGeometry {
    const geoJsonReader = new GeoJSONReader();
    const geoJson = new GeoJSON();
    const parserWKT = new WKT();
    const initialMapObject = initialMapObjects.find(
      (originalMapObject) =>
        originalMapObject.uuid === initialEditedMapObject.uuid,
    );
    const initialFeature = new Feature(
      parserWKT.readGeometry(initialMapObject.geom),
    );
    const initialFeatureObject = geoJson.writeFeatureObject(initialFeature);
    return (geoJsonReader.read(initialFeatureObject) as any).geometry;
  }

  private static getEditedObjectGeometry(
    editedMapObject: MapObject,
  ): JstsGeometry {
    const wktReader = new WKTReader();
    const editedGeom = editedMapObject.geom;

    return wktReader.read(editedGeom);
  }

  private static getGeomWithAuxiliaryBufferForCorrectValidationOfSnapPoints(
    jstsGeom: JstsGeometry,
  ): JstsGeometry {
    return BufferOp.bufferOp(
      jstsGeom,
      0.001,
      BufferParameters.DEFAULT_QUADRANT_SEGMENTS,
      BufferParameters.CAP_ROUND,
    );
  }

  static checkEditedGeometryCoverInitialGeometry(
    editedGeometryWkt: string,
    initialGeometryWkt: string,
  ): boolean {
    const wktReader = new WKTReader();
    const editedGeometry = wktReader.read(editedGeometryWkt);
    const initialGeometry = wktReader.read(initialGeometryWkt);
    return RelateOp.covers(
      this.getGeomWithAuxiliaryBufferForCorrectValidationOfSnapPoints(
        editedGeometry,
      ),
      initialGeometry,
    );
  }

  static getPolygonGeometryCoordinatesCount(
    geom: JstsGeometry | OlGeometry,
  ): number {
    if (this.maybeConvertJstsGeomToOlGeom(geom).getType() === 'MultiPolygon') {
      return this.maybeConvertJstsGeomToOlGeom(geom).getCoordinates().flat(2)
        .length;
    }

    return this.maybeConvertJstsGeomToOlGeom(geom)
      .getCoordinates()
      .flatMap((item: string[]) => item.length - 1)
      .reduce((acc: string, val: string) => acc + val);
  }

  private static maybeConvertJstsGeomToOlGeom(
    geom: JstsGeometry,
  ): JstsGeometry {
    const parserWKT = new WKT();

    if (this.checkIfJstsPolygonGeomType(geom)) {
      return parserWKT.readGeometry(geom.toText());
    }

    return geom;
  }

  private static checkIfJstsPolygonGeomType(geom: JstsGeometry): boolean {
    return (
      typeof geom.getGeometryType === 'function' &&
      (geom.getGeometryType() === 'Polygon' ||
        geom.getGeometryType() === 'MultiPolygon')
    );
  }

  static createBufferFromWkt(wkt: string, bufferValue: number): string {
    const wktParser = new WKT();
    const pointFeature = new Feature({
      geometry: wktParser.readGeometry(wkt),
    });
    const pointExtent = pointFeature.getGeometry().getExtent();
    const bufferFromExtent = olExtent.buffer(pointExtent, bufferValue);
    const bufferedPolygon = fromExtent(bufferFromExtent);

    return wktParser.writeGeometry(bufferedPolygon);
  }

  static getMapExtentWktFromLocalStorage(key: string): string {
    const mapExtent = StorageUtils.getItem(key) as Extent;

    const mapExtentGeom = fromExtent(mapExtent);
    return ConversionUtils.getWktFromGeometry(mapExtentGeom);
  }
}
