export interface GkApiPostalCode {
  Nazwa: string;
  KodId: number;
}

export class GkPostalCode {
  constructor(public id: string | number, public name: string) {}

  static fromApiToApp(apiPostalCode: GkApiPostalCode): GkPostalCode {
    return new this(apiPostalCode.KodId, apiPostalCode.Nazwa);
  }
}
