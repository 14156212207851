import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  EcoDocumentHistory,
  EcoDocumentHistoryApi
} from '../eco-document/eco-document.model';

@Injectable()
export class EcoDocumentSearchService {
  constructor(private httpClient: HttpClient) {}

  getEcoDocumentDetails(id: string, version?: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('DataArch', version)
    };
    return this.httpClient.get(
      `${this.getDetailsUrl(!!version)}${id}`,
      version ? httpOptions : undefined
    );
  }

  private getDetailsUrl(isArch: boolean): string {
    return isArch
      ? `/api/srodowisko/dokument/arch/szczegoly/`
      : `/api/srodowisko/dokument/szczegoly/`;
  }

  getEcoDocumentHistory(id: string): Observable<EcoDocumentHistory[]> {
    return this.httpClient
      .get<EcoDocumentHistoryApi[]>(`/api/srodowisko/dokument/historia/${id}`)
      .pipe(
        map((data) => data.map((element) => EcoDocumentHistory.fromApiToApp(element)))
      );
  }
}
