@if (isLoading) {
  <div class="k-loading-panel">
    <div class="k-loading-panel-mask"></div>
    <div class="k-loading-panel-wrapper">
      <kendo-loader type="infinite-spinner" themeColor="primary" size="large">
      </kendo-loader>
      <div class="d-flex justify-content-center">
        @if (abortConfig?.isAbortRequest) {
          <button
            type="button"
            class="btn btn-secondary btn-sm"
            (click)="handleAbortRequest()"
          >
            {{ 'DISMISS' | translate }}
          </button>
        }
      </div>
    </div>
  </div>
}
