import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of } from 'rxjs';
import { shareReplay, switchMap, tap } from 'rxjs/operators';
import { TfaType } from '../../../../gk-user-settings/services/tfa/tfa.model';
import { TfaService } from './../../../../gk-user-settings/services/tfa/tfa.service';
import {
  TfaWebAuthnUserCredentials,
  TfaWebAuthnUserCredentialsApi,
} from './tfa-web-authn-user-credentials.model';

@Injectable({
  providedIn: 'root',
})
export class TfaWebAuthnUserCredentialsService {
  tfaWebAuthnUserCredentials = new BehaviorSubject(undefined);
  constructor(private httpClient: HttpClient, private tfaService: TfaService) {}

  fetchCurrentCredentials(): Observable<TfaWebAuthnUserCredentials> {
    return this.tfaService
      .fetchCurrentTfaType()
      .pipe(
        switchMap((currentTfaType) => {
          return currentTfaType === TfaType.WebAuthn
            ? this.httpClient
                .get<TfaWebAuthnUserCredentialsApi>(
                  '/api/system/login/2fa/webauthn/user/credentials'
                )
                .pipe(
                  map((data) => TfaWebAuthnUserCredentials.fromApiToApp(data)),
                  shareReplay(1)
                )
            : of(TfaWebAuthnUserCredentials.getEmpty());
        })
      )

      .pipe(tap((tfaType) => this.tfaWebAuthnUserCredentials.next(tfaType)));
  }

  delete(credentialId: string): Observable<void> {
    return this.httpClient.delete<void>(
      '/api/system/login/2fa/webauthn/credentials',
      {
        params: new HttpParams().set('CredentialId', credentialId),
      }
    );
  }
}
