import { Injectable } from '@angular/core';
import { Observable, of, shareReplay } from 'rxjs';
import { SelectItem } from '../../../services';
import { gmpPrintTemplateSelectItems } from './print-template.model';

@Injectable()
export class GMPPrintTemplateService {
  private printTemplateCache: Observable<SelectItem<string>[]>;

  public get(): Observable<SelectItem<string>[]> {
    if (!this.printTemplateCache) {
      this.printTemplateCache = of(gmpPrintTemplateSelectItems).pipe(
        shareReplay(1),
      );
    }

    return this.printTemplateCache;
  }
}
