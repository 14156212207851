import {
  LawPerson,
  LawPersonType,
  NewDesignerRequestDto,
  NewLegalPersonRequestDto,
  NewNaturalPersonRequestDto,
} from '@gk/gk-modules';
import { ApiNewDokumentPowiazanyDalDto } from '../../../services/designer-incoming-documents/designer-incoming-documents.model';
import { DecisionFormType } from '../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { ZamowienieDzialkaDto } from '../../../services/excluding-land-from-agricultural-production/agricultural-land-use/agricultural-land-use.model';
import {
  Shipping,
  ShippingDto,
} from '../../../shared/shipping-form/shipping-form.model';

export interface StreetNamingDto {
  Wnioskodawca:
    | NewNaturalPersonRequestDto
    | NewLegalPersonRequestDto
    | NewDesignerRequestDto;
  KategoriaUlicy: string;
  NowaNazwa: string;
  LokalizacjaUlicy: string;
  Dzialki: ZamowienieDzialkaDto[];
  Uzasadnienie: string;
  Zalaczniki: ApiNewDokumentPowiazanyDalDto[];
  FormaDecyzji: number;
  Odbior: ShippingDto;
  InfoDodatkowe: string;
}

export class StreetNaming {
  constructor(
    public lawPerson: LawPerson,
    public objectCategoryOrDescription: string,
    public objectProposedNewName: string,
    public objectLocation: string,
    public objectNamingJustification: string,
    public decisionForm: DecisionFormType,
    public shippingForm: Shipping,
    public comments: string
  ) {}

  static fromAppToApi(
    data: StreetNaming,
    parcels: ZamowienieDzialkaDto[],
    files: ApiNewDokumentPowiazanyDalDto[]
  ): StreetNamingDto {
    return {
      Wnioskodawca:
        data.lawPerson.type === LawPersonType.Natural
          ? NewNaturalPersonRequestDto.fromAppToApi(data.lawPerson)
          : NewLegalPersonRequestDto.fromAppToApi(data.lawPerson),
      KategoriaUlicy: data.objectCategoryOrDescription,
      NowaNazwa: data.objectProposedNewName,
      LokalizacjaUlicy: data.objectLocation,
      Uzasadnienie: data.objectNamingJustification,
      Dzialki: parcels,
      Zalaczniki: files,
      FormaDecyzji: data.decisionForm,
      Odbior: ShippingDto.fromAppToApi(data.shippingForm),
      InfoDodatkowe: data.comments,
    };
  }
}
