@if (shouldShowControl()) {
  <div gkControlRow>
    <div gkControlLabel [showLabel]="controlConfig.showLabel">
      <gk-translated-label
        [configLabel]="controlConfig.label"
        ></gk-translated-label
        >:
      </div>
      <div gkControlValue [showLabel]="controlConfig.showLabel">
        <div class="d-flex flex-column attachment">
          @if (lastInvalids?.length) {
            <div
              class="row alert alert-danger"
              role="alert"
              >
              {{ getGeneratedInvalidTypeText() }}
            </div>
          }
          <div
            ngfDrop
            selectable
            class="file-drop-zone-with"
            [accept]="getAcceptedMimeTypes()"
            [(file)]="file"
            [(lastInvalids)]="lastInvalids"
            (fileChange)="handleFileInputChangeAction()"
            >
            <span>{{ dropText }}</span>
            <button class="btn btn-color-from-db ms-2">
              {{ inputFileTranslations['CHOOSE_FILE'] }}
            </button>
          </div>
          @if (file) {
            <div class="angular-file-input-files">
              <div
                class="angular-file-input-file"
                [ngClass]="{ image: file.type?.indexOf('image') !== -1 }"
                >
                <span
                  [innerHTML]="file.name"
                  class="angular-file-input-file-text"
                ></span>
                @if (file.type?.indexOf('image') !== -1) {
                  <img
                    [src]="getObjectUrl(file)"
                    />
                }
                <span
                  class="angular-file-input-file-text remove"
                  (click)="removeFile()"
                  >
                  <p>Usuń</p>
                </span>
              </div>
            </div>
          }
        </div>
        <div class="actions">
          @if (isUploading) {
            <div class="d-flex justify-content-center my-3">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
              </div>
            </div>
          }
          <button
            class="btn btn-sm btn-success"
            [disabled]="isUploading"
            (click)="uploadFile()"
            >
            {{ inputFileTranslations['SAVE'] }}
          </button>
          @if (!isUploading) {
            @if (resSucceeded) {
              <span class="text-success">{{
                inputFileTranslations['SUCCEED']
              }}</span>
            }
            @if (resFailed) {
              <span class="text-danger">{{
                inputFileTranslations['FAILED']
              }}</span>
            }
            @if (noFileChosenInfo) {
              <span class="text-danger">{{
                inputFileTranslations['NO_FILE_CHOSEN']
              }}</span>
            }
          }
        </div>
      </div>
    </div>
  }
