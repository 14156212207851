import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, map, Observable, switchMap } from 'rxjs';
import {
  TfaWebAuthnVerificationCredentialsPublicKeyOptions,
  VerificationCredentials,
  VerificationCredentialsApi,
} from '../tfa-web-authn-verification-credentials/tfa-web-authn-verification-credentials.model';
import { TfaWebAuthnVerificationCredentialsService } from '../tfa-web-authn-verification-credentials/tfa-web-authn-verification-credentials.service';

@Injectable({
  providedIn: 'root',
})
export class TfaWebAuthnVerificationService {
  constructor(
    private httpClient: HttpClient,
    private tfaWebAuthnVerificationCredentialsService: TfaWebAuthnVerificationCredentialsService
  ) {}

  verify(): Observable<void> {
    return this.getCredentials().pipe(
      switchMap((credentials) =>
        this.httpClient.post<void>(
          '/api/system/login/2fa/webauth/verify',
          VerificationCredentialsApi.fromApp(credentials)
        )
      )
    );
  }

  private getCredentials(): Observable<VerificationCredentials> {
    return this.getPublicKeyCredentialVerificationOptions().pipe(
      switchMap((publicKeyCredentialVerificationOptions) =>
        from(
          navigator.credentials.get({
            publicKey: publicKeyCredentialVerificationOptions,
          })
        )
      ),
      map((credentials) => {
        return VerificationCredentials.fromNavigatorCredentials(credentials);
      })
    );
  }

  private getPublicKeyCredentialVerificationOptions(): Observable<TfaWebAuthnVerificationCredentialsPublicKeyOptions> {
    return this.tfaWebAuthnVerificationCredentialsService
      .get()
      .pipe(
        map((webAuthnVerificationCredentials) =>
          TfaWebAuthnVerificationCredentialsPublicKeyOptions.fromVerificationCredentials(
            webAuthnVerificationCredentials
          )
        )
      );
  }
}
