import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MapControl } from '../../../../controls';
import {
  MapAction,
  MapObjectTableActionType,
  MapSheetFormState,
  SourceActionType,
  SourceType,
  ToolActionType,
} from '../../../../models';

@Component({
  selector: 'gk-map-tool-container',
  templateUrl: './map-tool-container.component.html',
  styleUrls: ['./map-tool-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapToolContainerComponent extends MapControl {
  sourceTypeEnum = SourceType;

  handleDismiss(): void {
    this.dispatchMapObjectsReset();
    this.dispatchSourceDeactivation();
  }

  handleSubmit(): void {
    this.dispatchMapObjectsToTable();
    this.dispatchSourceDeactivation();
  }

  dispatchMapObjectsReset(): void {
    this.dispatch.emit(
      new MapAction(ToolActionType.MapObjectsToolChange, {
        value: [],
        options: { toolType: this.toolType, newSet: true },
      }),
    );
  }

  dispatchSourceDeactivation(): void {
    this.dispatch.emit(
      new MapAction(SourceActionType.IsActiveSourceChange, {
        value: false,
        options: { toolType: this.toolType, sourceType: this.sourceType },
      }),
    );
  }

  dispatchMapObjectsToTable(): void {
    this.dispatch.emit(
      new MapAction(
        MapObjectTableActionType.AddToExisting,
        this.mapState.toolsState[this.toolType].mapObjects,
      ),
    );
  }

  getFormHeader(): string {
    return this.getSourceState().formHeader;
  }

  getSourceState(): MapSheetFormState {
    return this.mapState.toolsState[this.toolType][
      this.sourceType
    ] as MapSheetFormState;
  }
}
