import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiResponse,
  DictionaryField,
  DictionaryFieldFromApi,
} from '@gk/gk-modules';
import { BehaviorSubject, catchError, map } from 'rxjs';
import { handleApiError } from '../services.utils';

@Injectable()
export class ConnectionAndNetworkService {
  connectionsAndNetworks = new BehaviorSubject<DictionaryField[]>([]);

  constructor(private httpClient: HttpClient) {
    this.fetchConnectionsAndNetworks();
  }

  private fetchConnectionsAndNetworks(): void {
    this.httpClient
      .get<ApiResponse<DictionaryFieldFromApi[]>>('/api/zudp/slo/oprac')
      .pipe(
        map((data) => data.Response),
        map((dictFieldsFromApi) =>
          dictFieldsFromApi.map((dictFieldFromApi) =>
            DictionaryField.fromApiToApp(dictFieldFromApi)
          )
        ),
        catchError(handleApiError)
      )
      .subscribe((dictFields: DictionaryField[]) =>
        this.connectionsAndNetworks.next(dictFields)
      );
  }
}
