import { FormControl } from '@angular/forms';
import { FieldId, LawPerson } from '@gk/gk-modules';
import { DecisionFormType } from '../../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';

export enum SCOLNewCarryOutRequestControlName {
  LawPerson = 'lawPerson',
  DecisionForm = 'decisionForm',
  ShippingForm = 'shippingForm',
  Reason = 'reason',
  DemonstrationOfApplicantRight = 'demonstrationOfApplicantRight',
  EnableWorkByPerson = 'enableWorkByPerson',
}

export interface SCOLNewCarryOutRequestFormGroup {
  [SCOLNewCarryOutRequestControlName.LawPerson]: FormControl<LawPerson>;
  [SCOLNewCarryOutRequestControlName.DecisionForm]: FormControl<DecisionFormType>;
  [SCOLNewCarryOutRequestControlName.ShippingForm]: FormControl<Shipping>;
  [SCOLNewCarryOutRequestControlName.Reason]: FormControl<string>;
  [SCOLNewCarryOutRequestControlName.DemonstrationOfApplicantRight]: FormControl<FieldId>;
  [SCOLNewCarryOutRequestControlName.EnableWorkByPerson]: FormControl<string>;
}
