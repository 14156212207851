import { Observable, of as observableOf } from 'rxjs';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { catchError, map } from 'rxjs/operators';
import { SessionService } from '../../services/session/session.service';
import { MainRoutes } from '../guard.models';

@Injectable()
export class ClientSgkGuard {
  constructor(
    private sessionService: SessionService,
    private router: Router,
    private toastr: ToastrService,
    private translateService: TranslateService,
  ) {}

  canActivate(): Observable<boolean> {
    return this.sessionService.loggedUserData.pipe(
      map((loggedUser) => {
        if (loggedUser.clientSgk) {
          return true;
        }
        this.showNoClientSgkInfo();
        this.sessionService.logOffSystemSession().subscribe();
        this.router.navigate([MainRoutes.OfficeDepartmentsPortal]);

        return false;
      }),
      catchError(() => observableOf(false)),
    );
  }

  showNoClientSgkInfo(): void {
    this.translateService.get('NO_CLIENT_SGK_INFO').subscribe((text) =>
      this.toastr.warning(text, null, {
        timeOut: 0,
        extendedTimeOut: 0,
        closeButton: true,
      }),
    );
  }
}
