import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap, takeWhile } from 'rxjs/operators';
import { FormBaseComponent } from '../../../form-base/form-base.component';
import { DistrictSelectFieldConfig } from '../../../gk-dynamic-form.model';
import { District, Sheet } from '../../../services/cached/cached.model';
import { CachedService } from '../../../services/cached/cached.service';

@Component({
  selector: 'gk-form-district-select',
  templateUrl: './district-select.component.html',
})
export class DistrictSelectComponent
  extends FormBaseComponent
  implements OnInit, OnDestroy
{
  private isAlive = true;
  override config: DistrictSelectFieldConfig;
  districtsForCommunity: District[];
  districtGusFirst = false;
  sheetsForDistrict: Sheet[];
  selectedDistrictId: number;

  constructor(private cachedService: CachedService) {
    super();
  }

  ngOnInit(): void {
    this.patchEmptyValue();
    this.subscribeToDistrictSelectFormControlValueChanges();
    this.subscribeToDistrictsForCommunity();
  }

  subscribeToDistrictSelectFormControlValueChanges(): void {
    this.getDistrictSelectFormControl()
      .valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe((districtId) => {
        this.selectedDistrictId = parseInt(districtId, 10);
        this.populateSheetsForSelectedDistrict(this.selectedDistrictId);
      });
  }

  getDistrictSelectFormControl(): UntypedFormControl {
    return this.getFormControlByName(this.config.name) as UntypedFormControl;
  }

  getFormControlByName(formControlName: string): AbstractControl {
    return this.group.get(formControlName);
  }

  subscribeToDistrictsForCommunity(): void {
    this.cachedService.districtsForCommunity
      .pipe(
        takeWhile(() => this.isAlive),
        switchMap((districtsForCommunity) =>
          this.maybeTrimDistrictGusNumberWhenOneCommunityProvidedAndNotAllCommunitiesSelected(
            districtsForCommunity
          )
        )
      )
      .subscribe((districts) => (this.districtsForCommunity = districts));
  }

  toggleDistrictView(): void {
    this.districtGusFirst = !this.districtGusFirst;
  }

  trimAndSortAscDistrictGusNumber(districts: District[]): District[] {
    return districts
      .map((district) => ({ ...district, gus: district.gus.substring(9) }))
      .sort((a, b) => +a.gus - +b.gus);
  }

  maybeTrimDistrictGusNumberWhenOneCommunityProvidedAndNotAllCommunitiesSelected(
    districts: District[]
  ): Observable<District[]> {
    return combineLatest([
      this.cachedService.communitiesWithDistricts,
      this.cachedService.allCommunitiesSelected,
    ]).pipe(
      switchMap(([communitiesWithDistricts, allCommunitiesSelected]) => {
        if (communitiesWithDistricts.length === 1) {
          return of(this.trimAndSortAscDistrictGusNumber(districts));
        }
        if (communitiesWithDistricts.length > 1 && !allCommunitiesSelected) {
          return of(this.trimAndSortAscDistrictGusNumber(districts));
        } else {
          return of(districts);
        }
      })
    );
  }

  populateSheetsForSelectedDistrict(selectedDistrictId: number): void {
    if (selectedDistrictId) {
      this.sheetsForDistrict = this.districtsForCommunity.find(
        (district) => district.id === selectedDistrictId
      ).sheets;
      this.cachedService.sheetsForDistrict.next(this.sheetsForDistrict);
    } else {
      this.cachedService.sheetsForDistrict.next([]);
    }
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
