import {
  Component,
  inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FileRestrictions, SelectEvent } from '@progress/kendo-angular-upload';
import { ConversionUtils } from '../../../gk-map/utils';
import { GeometryFormat } from '../../../gk-map/models';
import { Type } from 'ol/geom/Geometry';
import { GkKendoGridMapService } from '../gk-kendo-grid-map.service';
import { WindowRef } from '@progress/kendo-angular-dialog';
import { getGkKendoGeomFileSelectActionButtonConfig } from './gk-kendo-geom-file-select.model';
import { FileLoaderFormComponent } from '../../../gk-map/components';
import { takeWhile } from 'rxjs';

@Component({
  selector: 'gk-get-kendo-geom-file-select',
  templateUrl: './gk-kendo-geom-file-select.component.html',
  styleUrls: ['./gk-kendo-geom-file-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GkKendoGeomFileSelectComponent
  extends FileLoaderFormComponent
  implements OnDestroy, OnInit
{
  windowRef: WindowRef;
  gkKendoGeomFileSelectActionButtonConfig =
    getGkKendoGeomFileSelectActionButtonConfig(this);
  fileRestrictions: FileRestrictions = {
    allowedExtensions: ['.wkt', '.txt'],
  };
  selectedFiles: Array<File>;
  geometryFormatList: Array<GeometryFormat> = [
    GeometryFormat.Wkt,
    GeometryFormat.Txt,
  ];
  selectedGeomFormat = GeometryFormat.Wkt;
  gkKendoGridMapService = inject(GkKendoGridMapService);

  ngOnInit(): void {
    this.gkKendoGridMapService.$mapState
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((mapState) => {
        this.mapState = mapState;
      });
  }

  public geomFormatValueChange(value: GeometryFormat): void {
    this.showFormatTxt = value === GeometryFormat.Txt;
  }

  public select(e: SelectEvent): void {
    e.files.forEach((file) => {
      if (!file.validationErrors) {
        const reader = new FileReader();

        reader.onload = (ev): void => {
          const result = ev.target.result as string;
          this.selectedGeomFormat = ConversionUtils.getGeometryFormat(
            result,
          ) as GeometryFormat;

          this.handleGeometryValidation(result, this.selectedGeomFormat);
        };

        reader.readAsText(file.rawFile);
      }
    });
  }

  override geometryConvertSuccessCallback(): void {
    this.convertingGeometryInProgress = false;
    this.isValidFormat = true;
    const geometryType = this.convertedGeometry.getType();
    this.handleGeometryTypeValidation(geometryType);
    this.handleRangeValidation();
    this.handleTopologyValidation(geometryType);
  }

  override handleGeometryTypeValidation(geometryType: Type): void {
    if (this.isPolygonOrMultiPolygon(geometryType)) {
      this.isValidGeometryType = true;
    }
  }

  isPolygonOrMultiPolygon(geometryType: Type): boolean {
    return geometryType === 'Polygon' || geometryType === 'MultiPolygon';
  }

  override setFormValueAndSubscribeToChanges(): void {}

  onLoadClick(): void {
    this.windowRef.close(this.wkt);
  }
}
