<ng-template #permissionNumberModal let-submit="close">
  <div class="modal-header">
    <h4 class="modal-title">{{ 'PERMISSION.INFORMATION' | translate }}</h4>
  </div>
  <div class="modal-body">
    {{ 'PERMISSION.ENTER_PERMISSION_NUMBER' | translate }}
    <form [formGroup]="permissionFormGroup">
      <div class="mt-3">
        <label [attr.for]="permissionControlName.Number">{{
          'PERMISSION.PERMISSION_NUMBER' | translate
        }}</label>
        <input
          type="text"
          [name]="permissionControlName.Number"
          [id]="permissionControlName.Number"
          class="form-control form-control-sm required"
          aria-required="true"
          [formControlName]="permissionControlName.Number"
          [autocomplete]="undefined"
          />
        @if (shouldShowNumberFormControlAlert()) {
          <div class="alert alert-danger">
            {{ 'FIELD_REQUIRED' | translate }}
          </div>
        }
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="submit(false)">
      {{ 'PERMISSION.CANCEL' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-primary"
      [disabled]="!isNumberFormControlValid()"
      (click)="submit(true)"
      >
      {{ 'PERMISSION.OK' | translate }}
    </button>
  </div>
</ng-template>
