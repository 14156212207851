export enum DemolitionNotificationDocTypeId {
  Other = 8,
  StampDuty = 10040,
  PowerOfAttorney = 10035,
  LegalTitleToRealEstate = 10039,
  DemolitionWorksDescription = 10051
}

export enum DemolitionNotificationDocTypeLabelKey {
  Other = 'CONSTRUCTION_PORTAL.DEMOLITION_NOTIFICATION.DOC_TYPE_DESCRIPTION.OTHER',
  StampDuty = 'CONSTRUCTION_PORTAL.DEMOLITION_NOTIFICATION.DOC_TYPE_DESCRIPTION.STAMP_DUTY',
  PowerOfAttorney = 'CONSTRUCTION_PORTAL.DEMOLITION_NOTIFICATION.DOC_TYPE_DESCRIPTION.POWER_OF_ATTORNEY',
  LegalTitleToRealEstate = 'CONSTRUCTION_PORTAL.DEMOLITION_NOTIFICATION.DOC_TYPE_DESCRIPTION.LEGAL_TITLE_TO_REAL_ESTATE',
  DemolitionWorksDescription = 'CONSTRUCTION_PORTAL.DEMOLITION_NOTIFICATION.DOC_TYPE_DESCRIPTION.DEMOLITION_WORKS_DESCRIPTION'
}

export function getDemolitionNotificationDocTypeLabelKeyById(id: number): string {
  switch (id) {
    case DemolitionNotificationDocTypeId.Other: {
      return DemolitionNotificationDocTypeLabelKey.Other;
    }
    case DemolitionNotificationDocTypeId.StampDuty: {
      return DemolitionNotificationDocTypeLabelKey.StampDuty;
    }
    case DemolitionNotificationDocTypeId.PowerOfAttorney: {
      return DemolitionNotificationDocTypeLabelKey.PowerOfAttorney;
    }
    case DemolitionNotificationDocTypeId.LegalTitleToRealEstate: {
      return DemolitionNotificationDocTypeLabelKey.LegalTitleToRealEstate;
    }
    case DemolitionNotificationDocTypeId.DemolitionWorksDescription: {
      return DemolitionNotificationDocTypeLabelKey.DemolitionWorksDescription;
    }
    default: {
      return '';
    }
  }
}
