import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { takeWhile } from 'rxjs/operators';
import {
  ControlConfig,
  isControlConfigWithCount,
} from '../../gk-dynamic-list.model';
import { ControlCommunicationService } from '../../services/control-communication/control-communication.service';
import { DataService } from '../../services/data/data.service';

@Directive()
export class Control<T> implements OnInit, OnDestroy {
  protected isAlive = true;
  noData = '-';
  @Input()
  data: any;
  @Input()
  controlConfig: ControlConfig;
  translatedLabel?: string;

  constructor(
    protected translateService: TranslateService,
    protected dataService: DataService,
    protected controlCommunicationService?: ControlCommunicationService
  ) {}

  ngOnInit(): void {
    this.translateLabel();
  }

  getConfigLabel(): string {
    if (!this.controlConfig.label) {
      throw new Error(
        "Property label for control was not properly set in configuration! Label can't be an empty string." +
          JSON.stringify(this.controlConfig)
      );
    }

    return this.controlConfig.label;
  }

  translateLabel(): void {
    this.translateService
      .get(this.getConfigLabel())
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((text) => (this.translatedLabel = text));
  }

  getLabel(): string {
    return this.translatedLabel || this.controlConfig.label;
  }

  getLabelWithCountWhenIsDefined(label: string): string {
    const count = this.getDataArrayCount();
    const labelWithCountWhenIsDefined = `${label}${
      !isNaN(count) && count >= 0 && count !== null ? ` (${count})` : ''
    }`;

    return labelWithCountWhenIsDefined;
  }

  getDataArrayCount(): number | undefined {
    if (isControlConfigWithCount(this.controlConfig)) {
      const arrayCountFromControlCommunicationService =
        this.controlCommunicationService?.getDataArrayCount(
          this.controlConfig.pathToDataArrayCount,
          this.data.Uuid
        );
      const arrayCountFromData = _.get(
        this.data,
        this.controlConfig.pathToDataArrayCount,
        undefined
      );

      return arrayCountFromControlCommunicationService &&
        arrayCountFromControlCommunicationService >= 0
        ? arrayCountFromControlCommunicationService
        : arrayCountFromData;
    }

    return undefined;
  }

  getValue(): T {
    return this.controlConfig.pathToValue
      ? _.get(this.data, this.controlConfig.pathToValue, this.noData)
      : this.data;
  }

  isEmptyValue(): boolean {
    const value = _.get(this.data, this.controlConfig.pathToValue);

    return _.isNumber(value)
      ? false
      : _.isEmpty(this.controlConfig.pathToValue ? value : this.data);
  }

  shouldShowControl(): boolean {
    return (
      this.showIfPaths() &&
      !this.hideIfPathsHasOrContainsSpecificValuesOfProperties()
    );
  }

  showIfPaths(): boolean {
    if (_.isEmpty(this.controlConfig.showIfPaths)) {
      return true;
    } else {
      const notEmptyValuesFromPaths: boolean[] =
        this.controlConfig.showIfPaths.map((path) => {
          const value = _.get(this.data, path);
          if (_.isBoolean(value)) {
            throw new Error(
              "You shouldn't include path in showIfPaths which evaluates to boolean." +
                JSON.stringify(value)
            );
          }
          return _.isNumber(value) ? true : !_.isEmpty(value);
        });
      return notEmptyValuesFromPaths.every((val) => val);
    }
  }

  hideIfPathsHasOrContainsSpecificValuesOfProperties(): boolean {
    if (
      _.isEmpty(
        this.controlConfig.hideIfPathsHasOrContainsSpecificValuesOfProperties
      )
    ) {
      return false;
    } else {
      return this.controlConfig.hideIfPathsHasOrContainsSpecificValuesOfProperties.every(
        (item) => {
          const value = _.get(this.data, item.pathToValue);

          if (_.isNumber(value) || !_.isEmpty(value)) {
            return typeof item.value === 'string' && typeof value === 'string'
              ? value.includes(item.value)
              : item.value === value;
          }
          return undefined;
        }
      );
    }
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
