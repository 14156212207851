import { Injectable } from '@angular/core';
import { PortalId } from '@gk/gk-modules';
import { combineLatestWith, map, Observable, shareReplay } from 'rxjs';
import {
  DocType,
  getEServiceI18nPrefix,
} from '../../../../services/doc-type/doc-type.model';
import { DocTypeService } from '../../../../services/doc-type/doc-type.service';
import { RequestFile } from '../../../../services/request-file/request-file.model';
import { RequestFileService } from '../../../../services/request-file/request-file.service';
import {
  docTypeIdWithDisabledUpload,
  EServiceDocTypeId,
  getDocTypeLabelKeyById,
} from './e-service-doc-type.model';

@Injectable()
export class EServiceDocTypeService {
  private docTypes: Observable<DocType<EServiceDocTypeId>[]>;
  private requestFile: Observable<RequestFile[]>;

  constructor(
    private docTypeService: DocTypeService,
    private requestFileService: RequestFileService,
  ) {}

  getDocTypes(portalId: PortalId): Observable<DocType<EServiceDocTypeId>[]> {
    if (!this.docTypes) {
      const i18nPrefix = getEServiceI18nPrefix(portalId);
      this.docTypes = this.docTypeService.getDocTypeForPortal(portalId).pipe(
        combineLatestWith(this.getRequestFiles(portalId)),
        map(([docTypes, requestFiles]) =>
          docTypes.map((docType) => {
            const matchingRequestFile = requestFiles.find(
              (requestFile) => requestFile.docTypeId === docType.id,
            );
            const labelKey = getDocTypeLabelKeyById(docType.id);
            const downloadLabelKey = getDocTypeLabelKeyById(docType.id, {
              downloadLabel: true,
            });

            return {
              ...docType,
              labelKey: labelKey ? `${i18nPrefix}.${labelKey}` : undefined,
              downloadLabelKey: downloadLabelKey
                ? `${i18nPrefix}.${downloadLabelKey}`
                : undefined,
              uploadAvailable: !docTypeIdWithDisabledUpload.includes(
                docType.id,
              ),
              matchingRequestFileId: matchingRequestFile
                ? matchingRequestFile.fileId
                : undefined,
            };
          }),
        ),
        shareReplay(1),
      );
    }
    return this.docTypes;
  }

  getRequestFiles(portalId: PortalId): Observable<RequestFile[]> {
    if (!this.requestFile) {
      this.requestFile = this.requestFileService
        .getRequestFilesForPortal(portalId)
        .pipe(shareReplay(1));
    }

    return this.requestFile;
  }
}
