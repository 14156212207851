@if (loading) {
  <div class="d-flex justify-content-center my-3">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
    </div>
  </div>
} @else if (shouldShowControl() && loaded) {
  @if (errorMsg) {
    <span class="text-danger">
      {{ errorMsg || ('GK.DYNAMIC_LIST.ERROR_OCCURRED' | translate) }}
    </span>
  } @else {
    <div gkControlRow>
      <div gkControlLabel [showLabel]="true">{{ controlConfig.label }}:</div>
      <div gkControlValue>
        @for (config of controlConfig.controlConfigsToRender; track config) {
          <gk-control-renderer
            [data]="getData()"
            [controlConfig]="config"
          ></gk-control-renderer>
        }
      </div>
    </div>
  }
}
