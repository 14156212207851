@if (!closeButton || !isRead()) {
  <div
    class="alert alert-light alert-dismissible fade show"
    role="alert"
    >
    <i class="fa fa-arrow-circle-down me-1" aria-hidden="true"></i>
    <span [innerHTML]="infoText"></span>
    @if (closeButton) {
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
        (click)="setInLocalStorageAsRead()"
      ></button>
    }
  </div>
}
