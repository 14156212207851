import { b64DecodeUnicode } from '@gk/gk-modules';

export class DownloadableApplication {
  constructor(public fileName: string) {}
  static fromApiToApp(
    getSystemInstalatoryDownloadRequestDto: GetSystemInstalatoryDownloadRequestDto
  ): DownloadableApplication {
    return new this(
      b64DecodeUnicode(getSystemInstalatoryDownloadRequestDto.FileNameBase64)
    );
  }
}

export interface GetSystemInstalatoryDownloadRequestDto {
  FileNameBase64: string;
}
