import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, shareReplay } from 'rxjs';
import { ApiResponseList } from '../../../../gk-dynamic-list/services/services.model';
import {
  PprfDictionaryContainer,
  PprfDictionaryContainerDto,
  PprfDictionaryField,
  PprfDictionaryKey,
} from './pprf-form-dictionary.model';

@Injectable({
  providedIn: 'root',
})
export class PprfFormDictionaryService {
  private dictionaryCache: Observable<PprfDictionaryContainer[]>;
  constructor(private httpClient: HttpClient) {}

  getDictionaryByKey(
    key: PprfDictionaryKey,
  ): Observable<PprfDictionaryField[]> {
    return this.getDictionary().pipe(
      map((dicts) => dicts.find((dict) => dict.key === key)?.dictionary || []),
    );
  }

  getDictionary(): Observable<PprfDictionaryContainer[]> {
    if (!this.dictionaryCache) {
      this.dictionaryCache = this.httpClient
        .get<
          ApiResponseList<PprfDictionaryContainerDto>
        >('/api/rciwn/searchformdict')
        .pipe(
          map((dicts) =>
            dicts.Response.map((dict) =>
              PprfDictionaryContainer.fromApiToApp(dict),
            ),
          ),
          shareReplay(1),
        );
    }

    return this.dictionaryCache;
  }
}
