<div class="row pb-2">
  <div class="col-12">
    @if (!hideLackEnablement) {
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="enablementType"
          id="enablementTypeLack"
          [(ngModel)]="enablement.type"
          [value]="enablementTypeEnum.Lack"
        />
        <label class="form-check-label" for="enablementTypeLack">
          {{ 'ENABLEMENT_FORM.LACK' | translate }}
        </label>
      </div>
    }
    <div class="form-check form-check-inline">
      <input
        class="form-check-input"
        type="radio"
        name="enablementType"
        id="enablementTypeStatutory"
        [(ngModel)]="enablement.type"
        [value]="enablementTypeEnum.Statutory"
      />
      <label class="form-check-label" for="enablementTypeStatutory">
        {{ 'ENABLEMENT_FORM.STATUTORY' | translate }}
      </label>
    </div>
    <div class="form-check form-check-inline">
      <input
        class="form-check-input"
        type="radio"
        name="enablementType"
        id="enablementTypeWritten"
        [(ngModel)]="enablement.type"
        [value]="enablementTypeEnum.Written"
      />
      <label class="form-check-label" for="enablementTypeWritten">
        {{ 'ENABLEMENT_FORM.WRITTEN' | translate }}
      </label>
    </div>
  </div>
</div>

@if (isEnablementTypeChosen(enablementTypeEnum.Lack)) {
  @if (submitted && !allowedNoEnablement) {
    <div class="alert alert-danger my-2">
      {{ 'ENABLEMENT_FORM.CHOOSE_PLENIPOTENTIARY' | translate }}
    </div>
  }
}

@if (!isEnablementTypeChosen(enablementTypeEnum.Lack)) {
  <gk-law-person-search
    [multiplePersonSelect]="false"
    [addingPersonsDirectlyToDb]="addingPersonsDirectlyToDb"
    [isRequired]="isEnablementTypeChosen(enablementTypeEnum.Statutory)"
    [legalPersonOnly]="legalPersonOnly"
    [forcePeselRequired]="true"
    [staticSelectedTableValue]="staticSelectedTableValue"
    [primitivePostalCode]="primitivePostalCode"
    [searchLegalPersonCustomUrl]="searchLegalPersonCustomUrl"
    [legalPersonSearchFieldsToHide]="[
      legalPersonControlName.TypeId,
      legalPersonControlName.Name,
      legalPersonControlName.KrsNumber
    ]"
    [(selectedLawPersons)]="chosenLawPersons"
    (selectedLawPersonsChange)="emitEnablementChange()"
  ></gk-law-person-search>
  @if (submitted && !isChosenPersonValid()) {
    <div class="alert alert-danger my-2">
      {{ 'ENABLEMENT_FORM.CHOOSE_PLENIPOTENTIARY' | translate }}
    </div>
  }
  <div class="row">
    <div class="form-group col-12">
      <label>{{ 'ENABLEMENT_FORM.CHOOSE_DOCUMENT' | translate }}:</label>
      <app-attachment-input
        [isRequired]="isEnablementTypeChosen(enablementTypeEnum.Written)"
        [wasValidated]="submitted"
        (fileChanged)="handleEnablementDocumentInput($event)"
        [stringifiedFile]="stringifiedEnablementDocumentFile"
      ></app-attachment-input>
      @if (
        submitted &&
        isEnablementTypeChosen(enablementTypeEnum.Written) &&
        !base64Document
      ) {
        <div class="alert alert-danger mt-2">
          {{
            'NEW_DESIGNER_REQUEST_FORM.PROVIDE_REQUIRED_ATTACHMENTS' | translate
          }}
        </div>
      }
      @if (documentConvertLoading) {
        <div class="d-flex justify-content-center my-3">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
          </div>
        </div>
      }
    </div>
  </div>
}
