<aol-overlay
  [offset]="mapMeasurementService.getOverlayOffset(sourceType)"
  [positioning]="mapMeasurementService.getOverlayPositioning(sourceType)"
>
  <aol-coordinate
    [x]="
      mapMeasurementService.getCoordinates(sketch, movingPointerCoordinate)?.[0]
    "
    [y]="
      mapMeasurementService.getCoordinates(sketch, movingPointerCoordinate)?.[1]
    "
    [srid]="defaultProjectionCode"
  ></aol-coordinate>
  <aol-content>
    @if (sketch) {
      <div class="map-tooltip text-white">
        <p
          class="map-tooltip-message fw-bold"
          [innerHTML]="
            mapMeasurementService.getAreaOrLengthWithUnits(
              sketch,
              mapState.viewState.nativeProjectionCode
            ) | async
          "
        ></p>
      </div>
    }
  </aol-content>
</aol-overlay>
