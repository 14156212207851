export interface ApiClosedArea {
  Sygnatura: string;
  WydanyPrzez: string;
  DataDok: string;
  Jawny: boolean | null;
}

export class ClosedArea {
  constructor(
    public signature: string,
    public emittedBy: string | null,
    public open: boolean,
    public date: string | null
  ) {}

  static fromApiToApp(apiClosedArea: ApiClosedArea): ClosedArea {
    return new this(
      apiClosedArea.Sygnatura,
      apiClosedArea.WydanyPrzez,
      apiClosedArea.Jawny,
      apiClosedArea.DataDok
    );
  }
}
