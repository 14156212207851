import {
  LawPerson,
  LawPersonType,
  NewDesignerRequestDto,
  NewLegalPersonRequestDto,
  NewNaturalPersonRequestDto,
  Street,
} from '@gk/gk-modules';
import {
  Shipping,
  ShippingDto,
} from '../../../shared/shipping-form/shipping-form.model';
import { DateHelperUtil } from '../../../utils/date-helper/date-helper.util';
import { ApiNewDokumentPowiazanyDalDto } from '../../designer-incoming-documents/designer-incoming-documents.model';
import { DecisionFormType } from '../../excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { NgbDateRange } from '../../new-request/new-request.model';
import { Place } from '../../place/place.model';

export interface LaneOccupancyInfrastructureDevicesDto {
  Wnioskodawca:
    | NewNaturalPersonRequestDto
    | NewLegalPersonRequestDto
    | NewDesignerRequestDto;
  DrogaNumer: string | number;
  MiejscId: string | number;
  Miejsc: string;
  UlicaId: string | number;
  Ulica: string;
  Cel: string;
  PowierzchniaPas: string;
  PowierzchniaObiektyInz: string;
  TerminZajeciaOd: string;
  TerminZajeciaDo: string;
  Zalaczniki: ApiNewDokumentPowiazanyDalDto[];
  Geom: string[];
  FormaDecyzji: number;
  Odbior: ShippingDto;
}

export class LaneOccupancyInfrastructureDevices {
  constructor(
    public lawPerson: LawPerson,
    public laneOccupancyPurpose: string,
    public laneOccupancyDate: NgbDateRange,
    public roadNumber: string,
    public locationPlace: Place,
    public locationStreet: Street,
    public areaWithoutRoadEngineeringFacilities: string,
    public areaOfRoadEngineeringFacilities: string,
    public decisionForm: DecisionFormType,
    public shippingForm: Shipping
  ) {}

  static fromAppToApi(
    data: LaneOccupancyInfrastructureDevices,
    issueRanges: string[],
    files: ApiNewDokumentPowiazanyDalDto[]
  ): LaneOccupancyInfrastructureDevicesDto {
    return {
      Wnioskodawca:
        data.lawPerson.type === LawPersonType.Natural
          ? NewNaturalPersonRequestDto.fromAppToApi(data.lawPerson)
          : NewLegalPersonRequestDto.fromAppToApi(data.lawPerson),
      DrogaNumer: data.roadNumber,
      MiejscId: data.locationPlace.id,
      Miejsc: data.locationPlace.name,
      UlicaId: data.locationStreet && data.locationStreet.id,
      Ulica: data.locationStreet && data.locationStreet.name,
      Cel: data.laneOccupancyPurpose,
      PowierzchniaPas: data.areaWithoutRoadEngineeringFacilities,
      PowierzchniaObiektyInz: data.areaOfRoadEngineeringFacilities,
      TerminZajeciaOd: DateHelperUtil.ngbDateToApiDateTime(
        data.laneOccupancyDate.from
      ),
      TerminZajeciaDo: DateHelperUtil.ngbDateToApiDateTime(
        data.laneOccupancyDate.to
      ),
      Zalaczniki: files,
      Geom: issueRanges,
      FormaDecyzji: data.decisionForm,
      Odbior: ShippingDto.fromAppToApi(data.shippingForm),
    };
  }
}
