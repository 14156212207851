import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MapSheetFormComponent } from '../../../gk-map/components';
import { GkWindowRef } from '../../services/kendo-window/kendo-window.model';
import {
  getGkMapSheetFormActionButtonsConfig,
  gkMapSheetFormModel,
} from './gk-map-sheet-form.model';
import {
  MapAction,
  MapObjectTableActionType,
  SourceType,
  ToolType,
} from '../../../gk-map/models';
import { GkKendoGridMapService } from '../gk-kendo-grid-map.service';
import { GkMapSheetFormService } from './gk-map-sheet-form.service';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import * as _ from 'lodash';

@Component({
  selector: 'gk-kendo-map-sheet-form',
  templateUrl: './gk-map-sheet-form.component.html',
})
export class GkMapSheetFormComponent
  extends MapSheetFormComponent
  implements OnInit, OnDestroy
{
  windowRef: GkWindowRef;
  form = new FormGroup({});
  formlyFormConfig: {
    fields: FormlyFieldConfig[];
    options?: FormlyFormOptions;
  };
  formModel = _.cloneDeep(gkMapSheetFormModel);
  gkMapSheetFormActionButtonsConfig =
    getGkMapSheetFormActionButtonsConfig(this);
  constructor(
    private gkMapSheetFormService: GkMapSheetFormService,
    private gkKendoGridMapService: GkKendoGridMapService, // private mapSettingsService: MapSettingsService
  ) {
    super();
  }

  override ngOnInit(): void {
    console.log(this.formModel);
    this.mapState = this.gkKendoGridMapService.$mapState.getValue();
    this.toolType = ToolType.RectangularExtent;
    this.sourceType = SourceType.MapSheetForm;
    this.dispatch.subscribe((action: MapAction) => {
      this.gkKendoGridMapService.$pendingMapActions.next([action]);
    });
    this.formlyFormConfig = this.gkMapSheetFormService.getFormConfig(this);
    super.ngOnInit();
    this.formModel.paper = this.formValue.paper;
    this.formModel.scale = this.formValue.scale;
    this.formModel.paperOrientation = this.formValue.paper.orientation;
  }

  clearForm(): void {
    this.form.reset();
  }

  onFormSubmit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const mapSheetGeomObject =
        this.gkKendoGridMapService.$mapState.getValue().toolsState[
          this.toolType
        ].mapObjects[0];

      this.windowRef.close(mapSheetGeomObject.geom);
    }
  }

  onCancel(): void {
    this.windowRef.close();
  }

  ngOnDestroy(): void {
    this.gkKendoGridMapService.$pendingMapActions.next([
      new MapAction(
        MapObjectTableActionType.DeselectAll,
        undefined,
        this.mapState.mapObjectTableStateCurrentIndex,
      ),
    ]);
  }
}
