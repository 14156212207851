import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { forkJoin, mergeMap, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { LangAbbreviation, TermsOfUse } from './gk-modal-terms-of-use.model';

@Injectable()
export class GkModalTermsOfUseService {
  private portalTermsOfUse: Observable<TermsOfUse>;
  private currentPortalId: number;
  private currentPortalName: string;
  readonly termsAcceptedSuffix = '_terms_of_use';
  readonly termsOfUseAcceptedValue = 'accepted';

  constructor(private httpClient: HttpClient) {}

  subscribeToTermsOfUse(): Observable<TermsOfUse> {
    if (this.portalTermsOfUse) {
      return this.portalTermsOfUse;
    }

    const headers = new HttpHeaders().set('Accept', 'text/html');
    const requests = Object.values(LangAbbreviation).map((language) =>
      this.httpClient.get(
        `/api/system/web/portal/${this.currentPortalId}/regulamin/lang/${language}`,
        {
          responseType: 'text',
          headers: headers,
        }
      )
    );

    this.portalTermsOfUse = forkJoin(requests).pipe(
      mergeMap((responses) =>
        of(
          responses.reduce((acc, curr, index) => {
            const language = Object.values(LangAbbreviation)[index];
            if (curr) {
              acc[language as keyof TermsOfUse] = curr;
            }
            return acc;
          }, {} as TermsOfUse)
        )
      )
    );

    return this.portalTermsOfUse;
  }

  getLanguagesOfTermsOfUse(
    currentPortalId: number
  ): Observable<(keyof TermsOfUse)[]> {
    this.currentPortalId = currentPortalId;

    return this.subscribeToTermsOfUse().pipe(
      map((langsTermsOfUse) => {
        const keys = Object.keys(langsTermsOfUse);
        if (!keys.length) {
          return [] as (keyof TermsOfUse)[];
        }
        return keys as (keyof TermsOfUse)[];
      })
    );
  }

  getTermsOfUseByLang(lang: string): Observable<string> {
    return new Observable((observer) => {
      this.subscribeToTermsOfUse().subscribe((data: TermsOfUse) => {
        const termsOfUse = data[lang as keyof TermsOfUse];
        observer.next(termsOfUse);
        observer.complete();
      });
    });
  }

  getSessionTermsKeyForCurrentPortal(): Observable<string> {
    return of(
      `${this.currentPortalName.toLowerCase().replace(/\s/g, '_')}${
        this.termsAcceptedSuffix
      }`
    );
  }

  handleTermsOfUseAcceptation(): void {
    this.getSessionTermsKeyForCurrentPortal().subscribe((sessionKey) => {
      sessionStorage.setItem(sessionKey, this.termsOfUseAcceptedValue);
    });
  }

  areTermsOfUseConfirmed(currentPortalName: string): Observable<boolean> {
    this.currentPortalName = currentPortalName;

    return this.getSessionTermsKeyForCurrentPortal().pipe(
      switchMap((sessionKey) =>
        sessionKey
          ? of(
              sessionStorage.getItem(sessionKey) ===
                this.termsOfUseAcceptedValue
            )
          : of(false)
      )
    );
  }
}
