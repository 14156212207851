import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/kendo/form-field';
import { InputAutocompleteProps } from './model';
import { isObjectOfTypeObservable } from '../../../utils/utils';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  takeWhile,
  tap,
} from 'rxjs';

@Component({
  selector: 'gk-formly-field-kendo-input-autocomplete',
  template: `
    <kendo-autocomplete
      #autocomplete
      [formControl]="formControl"
      [ngClass]="{ required: props.required }"
      [data]="
        isObjectOfTypeObservable(field.props.options)
          ? (field.props.options | async)
          : field.props.options
      "
      [filterable]="true"
      [valueField]="field.props.valueField"
      [loading]="props.loading"
    >
    </kendo-autocomplete>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldKendoInputAutocompleteComponent
  extends FieldType<FieldTypeConfig<InputAutocompleteProps>>
  implements AfterViewInit, OnDestroy
{
  private isAlive = true;
  @ViewChild('autocomplete', { static: false })
  public autocomplete: AutoCompleteComponent;
  protected readonly isObjectOfTypeObservable = isObjectOfTypeObservable;

  ngAfterViewInit(): void {
    this.autocomplete.filterChange
      .asObservable()
      .pipe(
        takeWhile(() => this.isAlive),
        tap(() => this.autocomplete.toggle(false)),
        filter((filter) => filter.length >= 3),
        tap(() => {
          // this.autocomplete.loading = true;
          // this.props.loading = true;
          this.autocomplete.toggle(true);
        }),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((filter) => {
        if (typeof this.props.onFilterChange === 'function') {
          this.props.onFilterChange(filter, this.field);
        }
        // this.autocomplete.loading = false;
      });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
