import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { FormAlertService } from '@gk/gk-modules';
import { takeWhile } from 'rxjs/operators';
import { AuthorizedPersonControlName } from '../../services/authorized-person-form/authorized-person-form.model';

@Component({
  selector: 'app-authorized-person',
  templateUrl: './authorized-person.component.html',
})
export class AuthorizedPersonComponent implements OnInit {
  private isAlive = true;
  @Input() submitted: boolean;
  @Input() parentForm: UntypedFormGroup;
  authorizedPersonControlName = AuthorizedPersonControlName;

  constructor(private formAlertService: FormAlertService) {}

  ngOnInit(): void {
    this.disableAuthorizedPersonDetailsFormControls();
    this.subscribeToAuthorizedPersonDetailsCheckboxFormControl();
  }

  subscribeToAuthorizedPersonDetailsCheckboxFormControl(): void {
    this.getAuthorizedDetailsCheckboxFormControl()
      .valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe((authorizedPersonDetailsChecked) => {
        if (authorizedPersonDetailsChecked) {
          this.enableAuthorizedPersonDetailsFormControls();
        } else {
          this.disableAuthorizedPersonDetailsFormControls();
        }
      });
  }

  enableAuthorizedPersonDetailsFormControls(): void {
    this.getAuthorizedPersonNameFormControl().enable();
    this.getAuthorizedPersonSurnameFormControl().enable();
    this.getAuthorizedPersonIdNumberFormControl().enable();
  }

  disableAuthorizedPersonDetailsFormControls(): void {
    this.getAuthorizedPersonNameFormControl().disable();
    this.getAuthorizedPersonSurnameFormControl().disable();
    this.getAuthorizedPersonIdNumberFormControl().disable();
  }

  checkAuthorizedPersonDetailsCheckedFormControlsInvalidAndFormValidated(): boolean {
    return this.getAuthorizedDetailsCheckboxFormControl().value
      ? this.submitted &&
          (this.getAuthorizedPersonNameFormControl().invalid ||
            this.getAuthorizedPersonSurnameFormControl().invalid ||
            this.getAuthorizedPersonIdNumberFormControl().invalid)
      : false;
  }

  getAuthorizedDetailsCheckboxFormControl(): UntypedFormControl {
    return this.getFormControlByName(
      AuthorizedPersonControlName.AuthorizedDetailsCheckbox
    ) as UntypedFormControl;
  }

  getAuthorizedPersonNameFormControl(): UntypedFormControl {
    return this.getFormControlByName(
      AuthorizedPersonControlName.Name
    ) as UntypedFormControl;
  }

  getAuthorizedPersonSurnameFormControl(): UntypedFormControl {
    return this.getFormControlByName(
      AuthorizedPersonControlName.Surname
    ) as UntypedFormControl;
  }

  getAuthorizedPersonIdNumberFormControl(): UntypedFormControl {
    return this.getFormControlByName(
      AuthorizedPersonControlName.IdNumber
    ) as UntypedFormControl;
  }

  getFormControlByName(formControlName: string): AbstractControl {
    return this.parentForm.get(formControlName);
  }

  shouldShowRequiredAlert(formControl: UntypedFormControl): boolean {
    return this.formAlertService.shouldShowErrorAlert(
      formControl,
      'required',
      undefined
    );
  }
}
