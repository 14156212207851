import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  TfaConfirmationControlName,
  TfaConfirmationFormGroup,
} from './tfa-confirmation-form.model';

@Injectable()
export class TfaConfirmationFormService {
  constructor(private formBuilder: FormBuilder) {}

  getFormGroup(): FormGroup<TfaConfirmationFormGroup> {
    return this.formBuilder.group<TfaConfirmationFormGroup>({
      [TfaConfirmationControlName.Code]: this.formBuilder.control('', [
        Validators.required,
        Validators.minLength(6),
      ]),
    });
  }
}
