export enum PprfParcelFormControlName {
  DevelopmentPlanDesignation = 'developmentPlanDesignation',
  UseMethod = 'useMethod',
  NumberNumerator = 'numberNumerator',
  NumberDenominator = 'numberDenominator',
  NumberAdditional = 'numberAdditional',
  AreaFrom = 'areaFrom',
  AreaTo = 'areaTo',
}

export class PprfParcelFormModel {
  constructor(
    public developmentPlanDesignation: number[],
    public useMethod: number[],
    public numberNumerator: number,
    public numberDenominator: number,
    public numberAdditional: number,
    public areaFrom: number,
    public areaTo: number,
  ) {}

  static getEmptyModel(): PprfParcelFormModel {
    return new this(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
  }
}

export const pprfParcelFormModel = PprfParcelFormModel.getEmptyModel();
