import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Cautions, CautionsDto } from './cautions.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CautionsService {
  readonly defaultCautionInfoString = '';
  private cautionsForPortal: { [key: number]: Observable<Cautions> } = {};
  private cautionsTranslations: { [key: string]: any };

  constructor(
    private httpClient: HttpClient,
    private translateService: TranslateService
  ) {}

  getCautionsForPortal(portalId: number): Observable<Cautions> {
    if (!this.cautionsForPortal[portalId]) {
      this.cautionsForPortal[portalId] = this.httpClient
        .get<CautionsDto>(`/api/system/web/portal/${portalId}/infoFields`)
        .pipe(
          map(response => Cautions.fromApiToApp(response)),
          shareReplay(1)
        );
    }

    return this.cautionsForPortal[portalId];
  }

  getCautionInfoText(portalId: number): Observable<string> {
    return this.translateService.get('CAUTIONS').pipe(
      tap(translations => {
        this.cautionsTranslations = translations;
      }),
      switchMap(() => this.getCautionsForPortal(portalId)),
      map(cautions => {
        return Object.values(cautions).reduce(
          (previousValue, currentValue, currentIndex, array) => {
            return !currentValue
              ? previousValue
              : this.getAcceptanceInfoStrings(previousValue, currentIndex, array);
          },
          this.defaultCautionInfoString
        );
      })
    );
  }

  private getAcceptanceInfoStrings(
    previousValue: string,
    currentIndex: number,
    array: string[]
  ): string {
    const currentStringPart = this.getCurrentAcceptanceStringPart(currentIndex);
    const isFirstArrayElement = currentIndex === 0;

    return `${previousValue}${
      isFirstArrayElement || previousValue === this.defaultCautionInfoString
        ? currentStringPart
        : this.getAcceptanceInfoAfterFirstElement(previousValue, currentIndex, array)
    }`;
  }

  private getAcceptanceInfoAfterFirstElement(
    previousValue: string,
    currentIndex: number,
    array: string[]
  ): string {
    const currentStringPart = this.getCurrentAcceptanceStringPart(currentIndex);
    const lastElementConjuction = this.getLastElementConjunction(previousValue);
    const isLastDefinedArrayElement = array.filter(Boolean).pop() === array[currentIndex];

    return `${
      isLastDefinedArrayElement ? lastElementConjuction : ','
    } ${currentStringPart}`;
  }

  private getCurrentAcceptanceStringPart(currentIndex: number): string {
    return this.cautionsTranslations[Cautions.acceptanceInformationTanslation][
      Cautions.acceptanceInformationTanslationsKeysInProperOrder[currentIndex]
    ];
  }

  private getLastElementConjunction(previousValue: string): string {
    const lastElementConjunction = this.cautionsTranslations['LAST_ELEMENT_JOIN'];
    const isDefinedPreviousValue = previousValue !== this.defaultCautionInfoString;

    return isDefinedPreviousValue ? ` ${lastElementConjunction}` : '';
  }
}
