import { Component } from '@angular/core';
import { landParcelNumberPattern } from '../../../utils/utils';
import { FormBaseComponent } from '../../form-base/form-base.component';
import { InputFieldConfig } from '../../gk-dynamic-form.model';

@Component({
  selector: 'gk-form-land-parcel-number',
  templateUrl: './form-land-parcel-number.component.html',
  styleUrls: ['./form-land-parcel-number.component.scss'],
})
export class FormLandParcelNumberComponent extends FormBaseComponent {
  override config: InputFieldConfig;
  landParcelNumberPattern = landParcelNumberPattern;
}
