<div class="modal-header">
  <h4 class="modal-title">{{ getFormHeader() | translate }}</h4>
  <button
    type="button"
    class="btn-close"
    [attr.aria-label]="'GK.MAP.CLOSE' | translate"
    [title]="'GK.MAP.CLOSE' | translate"
    (click)="closeModal(false, true)"
  ></button>
</div>
<div class="modal-body">
  <gk-dynamic-form
    #formContainer
    [fieldConfigs]="getFieldConfigs()"
  ></gk-dynamic-form>
  <div class="mt-2 text-center">
    <button
      type="button"
      class="btn btn-outline-color-from-db me-1"
      [attr.aria-label]="'GK.MAP.CLEAR_FILTER' | translate"
      (click)="clearFilter()"
    >
      {{ 'GK.MAP.CLEAR_FILTER' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-outline-color-from-db"
      [attr.aria-label]="'GK.MAP.APPLY_FILTER' | translate"
      (click)="applyFilter()"
    >
      {{ 'GK.MAP.APPLY_FILTER' | translate }}
    </button>
  </div>
  <div class="text-center">
    <span>
      @switch (requestStatus) {
        @case (requestStatusEnum.Pending) {
          <span
            >{{ 'GK.MAP.REQUEST_PROCESSING' | translate }}
            <div class="d-flex justify-content-center my-3">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
              </div>
            </div></span
          >
        }
        @case (requestStatusEnum.DataFound) {
          <span>{{ 'GK.MAP.DATA_FOUND' | translate }}</span>
        }
        @case (requestStatusEnum.DataNotFound) {
          <span>{{ 'GK.MAP.DATA_NOT_FOUND' | translate }}</span>
        }
        @case (requestStatusEnum.NoFilterApplied) {
          <span>{{ 'GK.MAP.FILL_AT_LEAST_ONE_FIELD' | translate }}</span>
        }
        @case (requestStatusEnum.NotAllDataPreviewed) {
          <span
            >{{ 'GK.MAP.MAXIMUM_QUERY_EXCEEDED_1' | translate }} {{ count }}
            {{ 'GK.MAP.MAXIMUM_QUERY_EXCEEDED_2' | translate }}
            {{ totalCount }}
            {{ 'GK.MAP.MAXIMUM_QUERY_EXCEEDED_3' | translate }}</span
          >
        }
        @case (requestStatusEnum.NoPlaceId) {
          <span class="text-danger">
            {{ 'GK.MAP.SELECT_PLACE_FROM_LIST' | translate }}
          </span>
        }
        @case (requestStatusEnum.NoStreetId) {
          <span class="text-danger">
            {{ 'GK.MAP.SELECT_STREET_FROM_LIST' | translate }}
          </span>
        }
      }
    </span>
    <gk-map-object-table
      [specifiedMapObjectTableState]="mapObjectTableState"
      [singleObjectMode]="isSingleObjectMode()"
      [attributesFormMode]="true"
      (dispatch)="handleMapObjectTableState($event)"
      (parentDispatch)="dispatch.emit($event)"
      (dispatchZoomToSelected)="closeModal($event, false)"
    ></gk-map-object-table>
  </div>
</div>
