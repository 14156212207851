import { Injectable } from '@angular/core';
import { MapPortalName, MapSettings, MapSettingsService } from '@gk/gk-modules';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class EcoMapSettingsService {
  ecoPortalMapSettings = new ReplaySubject<MapSettings>();

  constructor(private mapSettingsService: MapSettingsService) {
    this.getMapSettings();
  }

  private getMapSettings(): void {
    this.mapSettingsService
      .getMapSettings(MapPortalName.Applicant)
      .subscribe((mapSettings) => this.ecoPortalMapSettings.next(mapSettings));
  }
}
