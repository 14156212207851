import { FormControl } from '@angular/forms';
import { FieldId, LawPerson } from '@gk/gk-modules';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';

export enum GACSDRNewRequestControlName {
  LawPerson = 'lawPerson',
  ContactData = 'contactData',
  WorkSignature = 'workSignature',
  WorkPurpose = 'workPurpose',
  Materials = 'materials',
  DataRange = 'dataRange',
  AdditionalInfo = 'additionalInfo',
  ShippingForm = 'shippingForm',
}

export interface GACSDRNewRequestFormGroup {
  [GACSDRNewRequestControlName.LawPerson]: FormControl<LawPerson>;
  [GACSDRNewRequestControlName.ContactData]: FormControl<LawPerson>;
  [GACSDRNewRequestControlName.WorkSignature]: FormControl<string>;
  [GACSDRNewRequestControlName.WorkPurpose]: FormControl<FieldId[]>;
  [GACSDRNewRequestControlName.Materials]: FormControl<string>;
  [GACSDRNewRequestControlName.DataRange]: FormControl<string>;
  [GACSDRNewRequestControlName.AdditionalInfo]: FormControl<string>;
  [GACSDRNewRequestControlName.ShippingForm]: FormControl<Shipping>;
}
