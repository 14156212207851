export enum PremiseIndependenceNotificationDocTypeId {
  Other = 8,
  StampDuty = 10040,
  PowerOfAttorney = 10035,
  Testimonial = 113,
  TechnicalDocumentation = 10054,
  DrawingFromCadastralMap = 10055,
  LegalTitleToRealEstate = 10039,
}

export enum PremiseIndependenceNotificationDocTypeLabelKey {
  Other = 'CONSTRUCTION_PORTAL.PREMISE_INDEPENDENCE_NOTIFICATION.DOC_TYPE_DESCRIPTION.OTHER',
  StampDuty = 'CONSTRUCTION_PORTAL.PREMISE_INDEPENDENCE_NOTIFICATION.DOC_TYPE_DESCRIPTION.STAMP_DUTY',
  PowerOfAttorney = 'CONSTRUCTION_PORTAL.PREMISE_INDEPENDENCE_NOTIFICATION.DOC_TYPE_DESCRIPTION.POWER_OF_ATTORNEY',
  LegalTitleToRealEstate = 'CONSTRUCTION_PORTAL.PREMISE_INDEPENDENCE_NOTIFICATION.DOC_TYPE_DESCRIPTION.LEGAL_TITLE_TO_REAL_ESTATE',
  Testimonial = 'CONSTRUCTION_PORTAL.PREMISE_INDEPENDENCE_NOTIFICATION.DOC_TYPE_DESCRIPTION.TESTIMONIAL',
  TechnicalDocumentation = 'CONSTRUCTION_PORTAL.PREMISE_INDEPENDENCE_NOTIFICATION.DOC_TYPE_DESCRIPTION.TECHNICAL_DOCUMENTATION',
  DrawingFromCadastralMap = 'CONSTRUCTION_PORTAL.PREMISE_INDEPENDENCE_NOTIFICATION.DOC_TYPE_DESCRIPTION.DRAWING_FROM_CADASTRAL_MAP',
}

export function getPremiseIndependenceNotificationDocTypeLabelKeyById(
  id: number
): string {
  switch (id) {
    case PremiseIndependenceNotificationDocTypeId.Other: {
      return PremiseIndependenceNotificationDocTypeLabelKey.Other;
    }
    case PremiseIndependenceNotificationDocTypeId.StampDuty: {
      return PremiseIndependenceNotificationDocTypeLabelKey.StampDuty;
    }
    case PremiseIndependenceNotificationDocTypeId.PowerOfAttorney: {
      return PremiseIndependenceNotificationDocTypeLabelKey.PowerOfAttorney;
    }
    case PremiseIndependenceNotificationDocTypeId.LegalTitleToRealEstate: {
      return PremiseIndependenceNotificationDocTypeLabelKey.LegalTitleToRealEstate;
    }
    case PremiseIndependenceNotificationDocTypeId.Testimonial: {
      return PremiseIndependenceNotificationDocTypeLabelKey.Testimonial;
    }
    case PremiseIndependenceNotificationDocTypeId.TechnicalDocumentation: {
      return PremiseIndependenceNotificationDocTypeLabelKey.TechnicalDocumentation;
    }
    case PremiseIndependenceNotificationDocTypeId.DrawingFromCadastralMap: {
      return PremiseIndependenceNotificationDocTypeLabelKey.DrawingFromCadastralMap;
    }
    default: {
      return '';
    }
  }
}
