import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SessionGuard } from '../../guards/session/session.guard';
import { MainRequestsListComponent } from '../../shared/main-requests-list/main-requests-list.component';
import { BuildingPermitTransferPortalComponent } from './building-permit-transfer-portal.component';
import { NewRequestComponent } from './new-request/new-request.component';

const buildingPermitTransferPortalRoutes: Routes = [
  {
    path: 'building-permit-transfer',
    component: BuildingPermitTransferPortalComponent,
    canActivate: [SessionGuard],
    children: [
      {
        path: '',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
      {
        path: 'new-request',
        component: NewRequestComponent,
      },
      {
        path: 'requests-list',
        component: MainRequestsListComponent,
      },
      {
        path: '**',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(buildingPermitTransferPortalRoutes)],
  providers: [],
  exports: [RouterModule],
})
export class BuildingPermitTransferPortalRoutingModule {}
