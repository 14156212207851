import { Component } from '@angular/core';

@Component({
  selector: 'app-land-and-building-register-data-changes-notification',
  templateUrl:
    './land-and-building-register-data-changes-notification.component.html',
  styleUrls: [
    './land-and-building-register-data-changes-notification.component.scss',
  ],
})
export class LandAndBuildingRegisterDataChangesNotificationComponent {}
