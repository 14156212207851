import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { defaultPapers, scales } from '../../configs';
import {
  HorizontalPosition,
  LayersState,
  MapAction,
  MapLayersActionType,
  MapObject,
  MapObjectTableActionType,
  MapObjectTableState,
  MapSettings,
  MapState,
  MapViewActionType,
  Paper,
  ProjectionCode,
  ProjectionSource,
  SourceActionType,
  SourceState,
  SourceType,
  StorageSuffix,
  ToolActionType,
  ToolbarState,
  ToolsState,
  ToolState,
  ToolType,
  TreeNode,
  ViewState,
  WmsLayerOptions,
  WmsLayerParams,
  WmsLayerType,
} from '../../models';
import { MapDataStorageUtils, MapExtentUtils } from '../../utils';

@Injectable()
export class MapStateService {
  resolutions: number[] = scales.map((scale) => scale.resolution);

  getViewState(
    mapId: string,
    mapSettings: MapSettings,
    isSidebarExpanded?: boolean,
  ): ViewState {
    const projectionCode = MapDataStorageUtils.getProjectionCode(
      mapSettings.administrativeUnitReference.epsg,
    );

    if (!projectionCode) {
      return undefined;
    }

    MapDataStorageUtils.setValue(
      mapId,
      StorageSuffix.ProjectionCode,
      projectionCode,
    );

    const administrativeUnitExtent = MapExtentUtils.getAdministrativeUnitExtent(
      mapSettings.administrativeUnitReference,
    );

    const mapExtent = MapDataStorageUtils.getMapExtent(
      mapId,
      administrativeUnitExtent,
    );
    MapDataStorageUtils.setValue(mapId, StorageSuffix.MapExtent, mapExtent);

    const resolution = MapDataStorageUtils.getResolution(mapId);
    if (resolution) {
      MapDataStorageUtils.setValue(mapId, StorageSuffix.Resolution, resolution);
    }

    return new ViewState(
      administrativeUnitExtent,
      mapExtent,
      projectionCode as ProjectionCode,
      resolution,
      undefined,
      undefined,
      isSidebarExpanded,
      {
        enabled: false,
      },
    );
  }

  getLayersState(
    mapId: string,
    mapSettings: MapSettings,
    portalName: string,
  ): LayersState {
    const layersTree = MapDataStorageUtils.getLayersTree(
      mapId,
      mapSettings.layersGroups,
    );
    MapDataStorageUtils.setValue(mapId, StorageSuffix.LayersTree, layersTree);

    return new LayersState(
      [
        ...this.getExternalLayers(mapSettings.layersGroups).map(
          (externalLayer) =>
            new WmsLayerOptions(
              externalLayer.url,
              externalLayer.name,
              new WmsLayerParams('1.1.1', externalLayer.name, '0xFFFFFF'),
              WmsLayerType.Tile,
              ProjectionSource.Native,
              true,
            ),
        ),
        new WmsLayerOptions(
          `/map/${portalName}?`,
          mapSettings.layers,
          new WmsLayerParams('1.1.1', mapSettings.layers, '0xFFFFFF'),
          WmsLayerType.Image,
          ProjectionSource.Native,
        ),
      ],
      layersTree,
    );
  }

  getExternalLayers(layersGroups: TreeNode[]): TreeNode[] {
    return (layersGroups || []).reduce((acc, layersGroup) => {
      return [
        ...acc,
        ...(layersGroup?.children?.length
          ? this.getExternalLayers(layersGroup.children)
          : layersGroup.url
            ? [layersGroup]
            : []),
      ];
    }, []);
  }

  getToolbarState(
    toolTypes: ToolType[],
    papers: Paper[],
    mapSheetRestriction?: string,
    mapToolContainerHorizontalPosition?: HorizontalPosition,
    toolsExpandedMode?: boolean,
  ): ToolbarState {
    return new ToolbarState(
      toolTypes,
      _.isEmpty(papers) ? [...defaultPapers] : papers,
      mapSheetRestriction,
      mapToolContainerHorizontalPosition,
      toolsExpandedMode,
    );
  }

  getUpdatedMapState(
    mapAction: MapAction,
    mapState: MapState,
  ): MapState | never {
    if (_.includes(MapViewActionType, mapAction.type)) {
      return {
        ...mapState,
        viewState: this.getUpdatedViewState(mapAction, mapState.viewState),
      };
    }

    if (_.includes(ToolActionType, mapAction.type) && mapAction.payload) {
      return {
        ...mapState,
        toolsState: {
          ...mapState.toolsState,
          [mapAction.payload.options.toolType]: this.getUpdatedToolState(
            mapAction,
            mapState.toolsState[mapAction.payload.options.toolType as ToolType],
          ),
        },
      };
    }

    if (_.includes(ToolActionType, mapAction.type) && !mapAction.payload) {
      return {
        ...mapState,
        toolsState: Object.keys(mapState.toolsState).reduce(
          (newObject, key) => {
            (newObject as ToolsState)[key as ToolType] =
              this.getUpdatedToolState(
                mapAction,
                (mapState.toolsState as ToolsState)[key as ToolType],
              );

            return newObject;
          },
          {},
        ) as ToolsState,
      };
    }

    if (_.includes(SourceActionType, mapAction.type)) {
      return {
        ...mapState,
        toolsState: {
          ...mapState.toolsState,
          [mapAction.payload.options.toolType]: {
            ...(mapState.toolsState as ToolsState)[
              mapAction.payload.options.toolType as ToolType
            ],
            [mapAction.payload.options.sourceType]: this.getUpdatedSourceState(
              mapAction,
              mapState.toolsState[
                mapAction.payload.options.toolType as ToolType
              ][mapAction.payload.options.sourceType as SourceType],
            ),
          },
        },
      };
    }

    if (_.includes(MapLayersActionType, mapAction.type)) {
      return {
        ...mapState,
        layersState: this.getUpdatedLayersState(
          mapAction,
          mapState.layersState,
        ),
      };
    }

    if (_.includes(MapObjectTableActionType, mapAction.type)) {
      const newIndex =
        mapAction.mapObjectTableStateIndex !== undefined
          ? mapAction.mapObjectTableStateIndex
          : this.getIndexByPayloadType(mapState, mapAction.payload);
      if (newIndex === -1) {
        return mapState;
      }
      const mapObjectTableStateIndex =
        newIndex !== undefined && newIndex >= 0
          ? newIndex
          : mapState.mapObjectTableStateCurrentIndex;
      return {
        ...mapState,
        mapObjectTableStateCurrentIndex: mapObjectTableStateIndex,
        mapObjectTablesState: mapState.mapObjectTablesState.map(
          (mapObjectTableState, index) =>
            index === mapObjectTableStateIndex
              ? this.getUpdatedMapObjectTableState(
                  mapAction,
                  mapObjectTableState,
                )
              : mapObjectTableState,
        ),
      };
    }

    throw new Error('Not handled action.');
  }

  getIndexByPayloadType(mapState: MapState, payload: any): number {
    return mapState.mapObjectTablesState.length === 1 &&
      !mapState.ignoreObjectsWithDifferentType
      ? 0
      : mapState.mapObjectTablesState.findIndex((mapObjectTableState) =>
          Array.isArray(payload)
            ? payload.some((mapObject) =>
                mapObjectTableState.mapObjectTypes.includes(mapObject.type),
              )
            : payload && payload.type
              ? mapObjectTableState.mapObjectTypes.includes(payload.type)
              : undefined,
        );
  }

  getUpdatedViewState(
    mapAction: MapAction,
    viewState: ViewState,
  ): ViewState | never {
    switch (mapAction.type) {
      case MapViewActionType.MapExtentChange:
        return {
          ...viewState,
          currentNativeExtent: mapAction.payload,
        };
      case MapViewActionType.ResolutionChange:
        return {
          ...viewState,
          resolution: mapAction.payload,
        };
      case MapViewActionType.ExtentToFitToChange:
        return {
          ...viewState,
          extentToFitTo: mapAction.payload,
        };
      case MapViewActionType.IsSidebarExpandedChange:
        return {
          ...viewState,
          isSidebarExpanded: mapAction.payload,
        };
      case MapViewActionType.BackScrollIntoViewRefChange:
        return {
          ...viewState,
          backScrollIntoViewRef: mapAction.payload,
        };
      case MapViewActionType.IsPreviewModeChange:
        return {
          ...viewState,
          previewModeState: mapAction.payload,
        };
      default:
        throw new Error('Not handled action from MapViewActionType.');
    }
  }

  getUpdatedLayersState(
    mapAction: MapAction,
    layersState: LayersState,
  ): LayersState | never {
    switch (mapAction.type) {
      case MapLayersActionType.TreeNodeChange:
        return {
          ...layersState,
          treeNode: mapAction.payload,
        };
      default:
        throw new Error('Not handled action from MapLayersActionType.');
    }
  }

  getUpdatedToolState(
    mapAction: MapAction,
    toolState: ToolState,
  ): ToolState | never {
    switch (mapAction.type) {
      case ToolActionType.IsActiveToolChange:
        return {
          ...toolState,
          isActive: mapAction.payload.value,
        };
      case ToolActionType.MapObjectsToolChange:
        return {
          ...toolState,
          mapObjects: mapAction.payload.options.newSet
            ? mapAction.payload.value
            : _.uniqWith(
                [
                  ...toolState.mapObjects,
                  ...(Array.isArray(mapAction.payload.value)
                    ? mapAction.payload.value
                    : [mapAction.payload.value]),
                ],
                _.isEqual,
              ),
        };
      case ToolActionType.IsVisiblePopupChange:
        return {
          ...toolState,
          isPopupVisible: mapAction.payload.value,
        };
      case ToolActionType.IsVisiblePopupLoaderChange:
        return {
          ...toolState,
          isPopupLoaderVisible: mapAction.payload.value,
        };
      case ToolActionType.MapObjectsWithAttributesChange:
        return {
          ...toolState,
          requestedMapObjectsWithAttributes: _.uniqWith(
            [
              ...toolState.requestedMapObjectsWithAttributes,
              ...(_.isArray(mapAction.payload.value)
                ? mapAction.payload.value
                : [mapAction.payload.value]),
            ],
            _.isEqual,
          ),
        };
      case ToolActionType.PreviewedMapObjectsChange:
        return {
          ...toolState,
          previewedMapObjects: mapAction.payload.value,
        };
      case ToolActionType.Clear:
        return {
          ...toolState,
          mapObjects: _.differenceWith(
            toolState.mapObjects,
            [mapAction.payload.value],
            _.isEqual,
          ),
        };
      case ToolActionType.ClearAllMapObjects:
        return {
          ...toolState,
          mapObjects: [],
        };
      case ToolActionType.AddMeasurementStaticOverlay:
        return {
          ...toolState,
          measurementStaticOverlays: [
            ...(toolState.measurementStaticOverlays || []),
            mapAction.payload.value,
          ],
        };
      case ToolActionType.ClearMeasurements:
        return {
          ...toolState,
          measurementStaticOverlays: [],
        };
      default:
        throw new Error('Not handled action from ToolActionType.');
    }
  }

  getUpdatedSourceState(
    mapAction: MapAction,
    sourceState: SourceState,
  ): SourceState | never {
    switch (mapAction.type) {
      case SourceActionType.IsActiveSourceChange:
        return {
          ...sourceState,
          isActive: mapAction.payload.value,
        };
      case SourceActionType.LastWktChange:
        return {
          ...sourceState,
          lastWkt: mapAction.payload.value,
        };
      case SourceActionType.FormValueChange:
        return {
          ...sourceState,
          formValue: mapAction.payload.value,
        };
      case SourceActionType.MapObjectsSourceChange:
        return {
          ...sourceState,
          mapObjects: mapAction.payload.value,
        };
      case SourceActionType.MapObjectTableStateChange:
        return {
          ...sourceState,
          mapObjectTableState: mapAction.payload.value,
        };
      case SourceActionType.IsVisibleTooltipLoaderChange:
        return {
          ...sourceState,
          isTooltipLoaderVisible: mapAction.payload.value,
        };
      case SourceActionType.IsVisibleAlertChange:
        return {
          ...sourceState,
          isAlertVisible: mapAction.payload.value,
        };
      case SourceActionType.IsVisibleTooltipChange:
        return {
          ...sourceState,
          isTooltipVisible: mapAction.payload.value,
        };
      default:
        throw new Error('Not handled action from SourceActionType.');
    }
  }

  getUpdatedMapObjectTableState(
    mapAction: MapAction,
    mapObjectTableState: MapObjectTableState,
  ): MapObjectTableState | never {
    switch (mapAction.type) {
      case MapObjectTableActionType.SelectAll:
        return {
          ...mapObjectTableState,
          selectedMapObjects: mapObjectTableState.mapObjects,
        };
      case MapObjectTableActionType.SelectMultiple:
      case MapObjectTableActionType.SelectRange:
        return {
          ...mapObjectTableState,
          selectedMapObjects: _.uniqWith(
            [...mapObjectTableState.selectedMapObjects, ...mapAction.payload],
            (a, b) => _.isEqual(Object.assign({}, a), Object.assign({}, b)),
          ),
        };
      case MapObjectTableActionType.Select:
        return {
          ...mapObjectTableState,
          selectedMapObjects: _.isArray(mapAction.payload)
            ? [...mapAction.payload]
            : [mapAction.payload],
        };
      case MapObjectTableActionType.DeselectAll:
        return {
          ...mapObjectTableState,
          selectedMapObjects: [],
        };
      case MapObjectTableActionType.DeselectMultiple:
      case MapObjectTableActionType.Deselect:
        return {
          ...mapObjectTableState,
          selectedMapObjects: _.differenceWith(
            mapObjectTableState.selectedMapObjects,
            [mapAction.payload],
            _.isEqual,
          ),
        };
      case MapObjectTableActionType.AddNew:
        return {
          ...mapObjectTableState,
          mapObjects: _.isArray(mapAction.payload)
            ? [...mapAction.payload]
            : [mapAction.payload],
          selectedMapObjects: [],
        };
      case MapObjectTableActionType.AddToExisting:
        return {
          ...mapObjectTableState,
          mapObjects: _.uniqWith(
            [...mapObjectTableState.mapObjects, ...mapAction.payload],
            (a, b) => _.isEqual(Object.assign({}, a), Object.assign({}, b)),
          ),
        };
      case MapObjectTableActionType.RemoveAll:
        return {
          ...mapObjectTableState,
          mapObjects: [],
        };
      case MapObjectTableActionType.RemoveSelected:
        return {
          ...mapObjectTableState,
          mapObjects: _.differenceWith(
            mapObjectTableState.mapObjects,
            mapObjectTableState.selectedMapObjects,
            _.isEqual,
          ),
        };
      case MapObjectTableActionType.Remove:
        return {
          ...mapObjectTableState,
          mapObjects: _.differenceWith(
            mapObjectTableState.mapObjects,
            [mapAction.payload],
            _.isEqual,
          ),
        };
      case MapObjectTableActionType.Edit:
        return {
          ...mapObjectTableState,
          editedMapObject: mapAction.payload,
          initialEditedMapObject: mapAction.payload,
        };
      case MapObjectTableActionType.RemoveFromEdition: {
        const isEditedMapObjectSelected =
          !!mapObjectTableState.selectedMapObjects.find((selectedMapObject) =>
            this.isEqualMapObjectBasedOnUuidAndType(
              selectedMapObject,
              mapObjectTableState.editedMapObject,
            ),
          );

        return {
          ...mapObjectTableState,
          editedMapObject: isEditedMapObjectSelected
            ? undefined
            : mapObjectTableState.editedMapObject,
          initialEditedMapObject: isEditedMapObjectSelected
            ? undefined
            : mapObjectTableState.editedMapObject,
        };
      }
      case MapObjectTableActionType.EditedMapObjectGeometryUpdate:
        return {
          ...mapObjectTableState,
          editedMapObject: {
            ...mapObjectTableState.editedMapObject,
            geom: mapAction.payload,
          },
        };
      case MapObjectTableActionType.EditedMapObjectAreaUpdate:
        return {
          ...mapObjectTableState,
          editedMapObject: {
            ...mapObjectTableState.editedMapObject,
            area: mapAction.payload,
          },
        };
      case MapObjectTableActionType.MapObjectsUpdate:
        return {
          ...mapObjectTableState,
          selectedMapObjects: mapObjectTableState.selectedMapObjects.map(
            (selectedMapObject) =>
              this.getUpdatedMapObject(
                selectedMapObject,
                mapObjectTableState.editedMapObject,
              ),
          ),
          mapObjects: mapObjectTableState.mapObjects.map((mapObject) =>
            this.getUpdatedMapObject(
              mapObject,
              mapObjectTableState.editedMapObject,
            ),
          ),
        };
      case MapObjectTableActionType.RestoreInitialEditedMapObject:
        return {
          ...mapObjectTableState,
          editedMapObject: mapObjectTableState.initialEditedMapObject,
        };
      case MapObjectTableActionType.IsVisibleTopologyValidationLoaderChange:
        return {
          ...mapObjectTableState,
          isVisibleTopologyValidationLoader: mapAction.payload,
        };
      case MapObjectTableActionType.IsVisibleTopologyValidationErrorChange:
        return {
          ...mapObjectTableState,
          isVisibleTopologyValidationError: mapAction.payload,
        };
      case MapObjectTableActionType.MapGeometryStyleConfigChange:
        return {
          ...mapObjectTableState,
          mapGeometryStyleConfig: mapAction.payload,
        };
      default:
        throw new Error('Not handled action from MapObjectTableActionType.');
    }
  }

  isEqualMapObjectBasedOnUuidAndType(
    baseMapObject: MapObject,
    comparedMapObject: MapObject,
  ): boolean {
    return (
      !!comparedMapObject &&
      baseMapObject.uuid === comparedMapObject.uuid &&
      baseMapObject.type === comparedMapObject.type
    );
  }

  getUpdatedMapObject(
    baseMapObject: MapObject,
    comparedMapObject: MapObject,
  ): MapObject {
    return this.isEqualMapObjectBasedOnUuidAndType(
      baseMapObject,
      comparedMapObject,
    )
      ? comparedMapObject
      : baseMapObject;
  }

  getCoordinateSystemDescription(nativeProjectionCode: ProjectionCode): string {
    return `GK.MAP.PROJECTION_CODES.${nativeProjectionCode}`;
  }
}
