import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { getDefaultControlValue } from '@gk/gk-modules';
import { AuthorizedPersonControlName } from './authorized-person-form.model';

@Injectable()
export class AuthorizedPersonFormService {
  constructor(private formBuilder: UntypedFormBuilder) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [AuthorizedPersonControlName.AuthorizedDetailsCheckbox]: [false],
      [AuthorizedPersonControlName.Name]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [AuthorizedPersonControlName.Surname]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [AuthorizedPersonControlName.IdNumber]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
    });
  }
}
