@if (shouldShowControl()) {
  <div class="d-flex">
    @for (button of controlConfig.buttons; track button) {
      @if (shouldShowButton(button, actionTypeEnum.Route)) {
        <button
          class="btn btn-sm my-1"
          ngClass="btn-{{ button.color }}"
          [routerLink]="[button.routePath, paramValue]"
          >
          {{ button.label }}
        </button>
      }
      @if (shouldShowButton(button, actionTypeEnum.ShowOnMap)) {
        <button
          type="button"
          (click)="showGeometry(button)"
          [attr.aria-label]="'GK.MAP.ZOOM_TO_OBJECT' | translate"
          [title]="'GK.MAP.ZOOM_TO_OBJECT' | translate"
          class="btn btn-outline-color-from-db btn-sm gk-map-icon-zoom-to-extent bg-white text-secondary me-1"
        ></button>
      }
      @if (shouldShowButton(button, actionTypeEnum.ShowParcelAttributes)) {
        <button
          type="button"
          (click)="openAttributesModal()"
          [attr.aria-label]="'GK.MAP.SHOW_ATTRIBUTES' | translate"
          [title]="'GK.MAP.SHOW_ATTRIBUTES' | translate"
          aria-haspopup="true"
          class="btn btn-outline-color-from-db btn-sm gk-map-icon-show-attributes bg-white text-secondary me-1"
        ></button>
      }
    }
  </div>
}
