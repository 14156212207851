
@switch (optionProperty) {
  @case (paperOptionPropertyEnum.Orientation) {
    <select
      [id]="paperOptionPropertyEnum.Orientation"
      [ngModel]="allowedPaper.orientation"
      (ngModelChange)="emitOrientationChange($event)"
      class="btn btn-outline-color-from-db btn-sm bg-white text-secondary"
      >
      @for (orientation of getUniqueOrientations(); track orientation) {
        <option
          [ngValue]="orientation"
          >
          {{ orientation | translate }}
        </option>
      }
    </select>
  }
  @case (paperOptionPropertyEnum.Name) {
    <select
      [id]="paperOptionPropertyEnum.Name"
      [ngModel]="allowedPaper.name"
      (ngModelChange)="emitNameChange($event)"
      class="btn btn-outline-color-from-db btn-sm bg-white text-secondary form-select"
      [disabled]="restriction"
      >
      @for (name of getNamesByOrientation(); track name) {
        <option [ngValue]="name">
          {{ name }}
        </option>
      }
    </select>
  }
}
