import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnChanges,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import * as _ from 'lodash';
import { MapControl } from '../../../../controls';
import { MapAction, MapViewActionType } from '../../../../models';

@Component({
  selector: 'gk-layers-tree-button',
  templateUrl: './layers-tree-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayersTreeButtonComponent extends MapControl implements OnChanges {
  id: string;
  elementFocusTrigger = new EventEmitter<string>();

  ngOnChanges(simpleChanges: SimpleChanges): void {
    this.triggerFocus(simpleChanges);
  }

  triggerFocus(simpleChanges: SimpleChanges): void {
    this.id = `${this.mapState.id}-layers-tree`;
    if (this.shouldTriggerFocus(simpleChanges)) {
      this.elementFocusTrigger.emit(this.id);
    }
  }

  shouldTriggerFocus(simpleChanges: SimpleChanges): boolean {
    const previousMapState = (simpleChanges['mapState'] as SimpleChange)
      .previousValue;
    const currentMapState = (simpleChanges['mapState'] as SimpleChange)
      .currentValue;

    return (
      !simpleChanges['mapState'].firstChange &&
      !currentMapState.viewState.isSidebarExpanded &&
      !_.isEqual(
        previousMapState.viewState.isSidebarExpanded,
        currentMapState.viewState.isSidebarExpanded
      )
    );
  }

  handleToggleSidebar(): void {
    this.dispatch.emit(
      new MapAction(
        MapViewActionType.IsSidebarExpandedChange,
        !this.mapState.viewState.isSidebarExpanded
      )
    );
  }
}
