import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { takeWhile } from 'rxjs/operators';
import { TableSelectionMode } from '../../../../../gk-components/table/table.model';
import { MapControl } from '../../../../controls';
import {
  MapAction,
  MapObject,
  MapObjectApiType,
  MapObjectTableActionType,
  PolygonsUnion,
  SourceActionType,
  SourceType,
  ToolType,
  Wkt,
} from '../../../../models';
import { PolygonTopologyService } from '../../../../services';
import { ShapeUtils } from '../../../../utils';

@Directive()
export abstract class RangeFromParcelsButtonBaseComponent
  extends MapControl
  implements OnDestroy
{
  @Output()
  parentDispatch = new EventEmitter<MapAction>();
  @Input() attributesFormMode: boolean;
  @Input()
  selectionMode: TableSelectionMode = TableSelectionMode.Multiple;
  isAlive = true;
  polygonsUnion: PolygonsUnion;
  abstract successRangeAdditionTranslation: string;
  rangeGenerationInProgress: boolean;
  abstract noParcelsTranslation: string;
  constructor(
    public polygonTopologyService: PolygonTopologyService,
    public toastr: ToastrService,
    public changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  createRangeFromParcels(): void {
    this.rangeGenerationInProgress = true;
    this.fetchMultiPolygonWktFromParcelsGeom();
  }

  fetchMultiPolygonWktFromParcelsGeom(): void {
    const parcelsRanges = this.getRangesOfParcels();
    if (!parcelsRanges.length) {
      this.rangeGenerationInProgress = false;
      this.showNoParcelsNotification();

      return;
    }
    this.polygonTopologyService
      .getPolygonsUnion(parcelsRanges)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe({
        next: (polygonsUnion) => {
          this.polygonsUnion = polygonsUnion;
          this.emitPolygonsUnionWkt();
        },
        error: () => {
          this.rangeGenerationInProgress = false;
          this.changeDetectorRef.markForCheck();
        },
      });
  }

  showNoParcelsNotification(): void {
    this.toastr.warning(this.noParcelsTranslation);
  }

  emitPolygonsUnionWkt(): void {
    const properDispatchAction = !this.attributesFormMode
      ? this.dispatch
      : this.parentDispatch;
    properDispatchAction.emit(
      new MapAction(SourceActionType.LastWktChange, {
        value: this.polygonsUnion.wkt,
        options: {
          toolType: ToolType.AnyPolygon,
          sourceType: SourceType.Polygon,
        },
      })
    );
    const newMapObject = ShapeUtils.getAnyGeometryMapObject(
      this.polygonsUnion.wkt,
      MapObjectApiType.ExtentOrPolygon
    );
    properDispatchAction.emit(
      new MapAction(
        this.selectionMode === TableSelectionMode.Multiple
          ? MapObjectTableActionType.AddToExisting
          : MapObjectTableActionType.AddNew,
        [newMapObject]
      )
    );
    this.showSuccessNotificationOfRangeAddition();
  }

  showSuccessNotificationOfRangeAddition(): void {
    this.rangeGenerationInProgress = false;
    this.toastr.success(this.successRangeAdditionTranslation);
  }

  getRangesOfParcels(): Wkt[] {
    return this.getParcels().map((object) => object.geom);
  }

  abstract getParcels(): MapObject[];

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
