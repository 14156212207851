import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { PaymentsService } from '../payments.service';
import { Invoice, Payment } from '../payments.model';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'gk-payment-button',
  templateUrl: './payment-button.component.html',
  styleUrls: ['./payment-button.component.scss'],
})
export class PaymentButtonComponent implements OnDestroy, OnInit {
  private isAlive = true;
  @Input()
  invoices: Payment[] | Invoice[] = [];
  @Input()
  pathToAmount: string;
  @Input()
  portalName: string;
  @Input()
  invoiceIdFieldName = 'id';
  @Input()
  multiplePaymentProviders = false;
  @Input()
  parent: any;
  @Input()
  openSelectedProviderWindow: (providers: string[]) => Promise<string>;
  @Output()
  isPaymentInProgress: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public paymentService: PaymentsService) {}

  ngOnInit(): void {
    this.paymentService.isPaymentInProgress
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((isPaymentInProgress) => {
        this.isPaymentInProgress.emit(isPaymentInProgress);
      });
  }

  pay(): void {
    this.paymentService.pay({
      invoices: this.invoices,
      pathToAmount: this.pathToAmount,
      portalName: this.portalName,
      invoiceIdFieldName: this.invoiceIdFieldName,
      multiplePaymentProviders: this.multiplePaymentProviders,
      parent: this.parent,
      openSelectedProviderWindow: this.openSelectedProviderWindow,
    });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
