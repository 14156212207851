import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { GkDynamicListModule, GkMapModule } from '@gk/gk-modules';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { createTranslateLoader } from '../address-number-request-portal/address-number-request-portal.module';
import { EcoDocumentDetailsComponent } from './eco-document-details/eco-document-details.component';
import { EcoDocumentsListComponent } from './eco-documents-list/eco-documents-list.component';
import { EcoMapComponent } from './eco-map/eco-map.component';
import { EcoNewsletterComponent } from './eco-newsletter/eco-newsletter.component';
import { EcoPortalRoutingModule } from './eco-portal-routing.module';
import { EcoPortalComponent } from './eco-portal.component';
import { SearchEcoDocumentComponent } from './search-eco-document/search-eco-document.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    EcoPortalRoutingModule,
    GkDynamicListModule,
    GkMapModule,
  ],
  declarations: [
    EcoPortalComponent,
    SearchEcoDocumentComponent,
    EcoDocumentsListComponent,
    EcoDocumentDetailsComponent,
    EcoMapComponent,
    EcoNewsletterComponent,
  ],
})
export class EcoPortalModule {}
