export enum ShippingFormName {
  SelfPickup = 'odbiór osobisty',
  PostOffice = 'poczta',
  EServicesInbox = 'skrzynka odbiorcza e-usługi',
}

export interface ShippingFormDto {
  Id: number;
  Nazwa: string;
  IsPostalDataRequired: boolean;
  IsFtpRequired: boolean;
  IsEmailRequired: boolean;
}

export class ShippingForm {
  constructor(
    public id: number,
    public name: string,
    public postalDataRequired: boolean,
    public emailRequired: boolean,
    public ftpAddressRequired?: boolean,
    public electronicPosition?: boolean,
  ) {}

  static fromApiToApp(data: ShippingFormDto): ShippingForm {
    return new this(
      data.Id,
      data.Nazwa,
      data.IsPostalDataRequired,
      data.IsEmailRequired,
      data.IsFtpRequired,
      electronicReceivingMethods.includes(data.Id),
    );
  }
}

export enum ShippngFormDataType {
  Personal = 1,
  NotPersonal,
}

export enum ReceivingMethod {
  Personally = 1,
  Post = 3,
  EServiceInbox = 6,
  SharingNetworkService = 7,
  SharingFTPServerOfAuthority = 8,
  ShipmentToIndicatedEmail = 9,
}

export const electronicReceivingMethods = [
  ReceivingMethod.EServiceInbox,
  ReceivingMethod.SharingNetworkService,
  ReceivingMethod.SharingFTPServerOfAuthority,
  ReceivingMethod.ShipmentToIndicatedEmail,
];

export enum ReceivingTypesItemForms {
  Electronic = 1,
  Printed = 2,
  Mixed = 3,
}
