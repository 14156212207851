<div [ngClass]="[getCollapseClasses(), getCollapseTargetClassName()]">
  <ul class="navbar-nav me-auto mt-2 mt-lg-0 d-flex">
    @for (navItemConfig of navItemsSectionConfig.navItemConfigs; track navItemConfig) {
      <li
        class="nav-item"
        >
        <gk-nav-item-renderer
          [navItemConfig]="navItemConfig"
          [navbarConfig]="navbarConfig"
        ></gk-nav-item-renderer>
      </li>
    }
  </ul>
</div>
