import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicNavbarComponent } from './components/dynamic-navbar/dynamic-navbar.component';
import { NavBaseComponent } from './components/nav-base/nav-base.component';
import { NavItemActionButtonComponent } from './components/nav-item-action-button/nav-item-action-button.component';
import { NavItemAudioDescriptionToggleButtonComponent } from './components/nav-item-audio-description-toggle-button/nav-item-audio-description-toggle-button.component';
import { NavItemDropdownComponent } from './components/nav-item-dropdown/nav-item-dropdown.component';
import { NavItemInfoServiceButtonComponent } from './components/nav-item-info-service-button/nav-item-info-service-button.component';
import { NavItemLinkComponent } from './components/nav-item-link/nav-item-link.component';
import { NavItemLogoImageTextComponent } from './components/nav-item-logo-image-text/nav-item-logo-image-text.component';
import { NavItemLogoImageComponent } from './components/nav-item-logo-image/nav-item-logo-image.component';
import { NavItemRendererComponent } from './components/nav-item-renderer/nav-item-renderer.component';
import { NavItemSimpleTextComponent } from './components/nav-item-simple-text/nav-item-simple-text.component';
import { NavItemsSectionComponent } from './components/nav-items-section/nav-items-section.component';
import { NavSectionRendererComponent } from './components/nav-section-renderer/nav-section-renderer.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    TranslateModule,
    RouterModule.forChild([]),
  ],
  declarations: [
    DynamicNavbarComponent,
    NavItemRendererComponent,
    NavItemLinkComponent,
    NavItemDropdownComponent,
    NavItemLogoImageTextComponent,
    NavItemLogoImageComponent,
    NavItemActionButtonComponent,
    NavItemsSectionComponent,
    NavSectionRendererComponent,
    NavItemSimpleTextComponent,
    NavItemAudioDescriptionToggleButtonComponent,
    NavItemInfoServiceButtonComponent,
    NavBaseComponent,
  ],
  exports: [DynamicNavbarComponent],
})
export class GkDynamicNavbarModule {}
