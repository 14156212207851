import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile } from 'rxjs';
import { LawPersonListControlName } from '../../services/law-person-list-form/law-person-list-form.model';
import {
  LawPersonSearch,
  isLegalPerson,
} from '../../services/law-person/law-person-search.model';

@Component({
  selector: 'gk-law-person-list',
  templateUrl: './law-person-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LawPersonListComponent implements OnInit, OnChanges {
  private isAlive = true;
  @Input() lawPersons: LawPersonSearch[] = [];
  @Input() legalPersonOnly: boolean;
  @Input() canAddNewPersonEvenIfDefinedInDb = true;
  @Input() naturalPersonChosen: boolean;
  @Input() legalPersonAddModeEnabled: boolean;
  @Output() chosenLawPersons = new EventEmitter<LawPersonSearch[]>();
  @Output() newPersonMode = new EventEmitter<boolean>();
  isNewPersonMode: boolean;
  lawPersonListForm: UntypedFormGroup;
  lawPersonListControlName = LawPersonListControlName;
  lawPersonResultsHeaderTranslation = '';
  selectFromListTranslation = '';
  @ViewChild('personIsAlreadyDefinedInfoModal')
  personIsAlreadyDefinedInfoModal: NgbModalRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.subscribeToLawPersonResultsHeaderTranslation();
    this.subscribeToSelectFromListTranslation();
  }

  createForm(): void {
    this.lawPersonListForm = this.formBuilder.group({
      [this.lawPersonListControlName.SelectedLawPersons]: '',
    });
  }

  subscribeToLawPersonResultsHeaderTranslation(): void {
    this.translateService
      .get('LAW_PERSON_LIST.HEADER')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (translations) =>
          (this.lawPersonResultsHeaderTranslation = translations)
      );
  }

  subscribeToSelectFromListTranslation(): void {
    this.translateService
      .get('LAW_PERSON_LIST.SELECT_FROM_LIST')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (translations) => (this.selectFromListTranslation = translations)
      );
  }

  ngOnChanges(): void {
    if (this.lawPersonListForm) {
      this.resetChosenLawPerson();
    }
    this.setInitialChosenLawPersonWhenIsOnlyOne();
  }

  setInitialChosenLawPersonWhenIsOnlyOne(): void {
    if (this.lawPersons.length !== 1) {
      return;
    }
    this.getSelectedLawPersons().patchValue(this.lawPersons);
    if (!this.legalPersonAddModeEnabled && !this.naturalPersonChosen) {
      this.emitSelectedLawPersons();
    }
  }

  resetChosenLawPerson(): void {
    this.getSelectedLawPersons().reset();
  }

  getSelectSize(): number {
    const listLength = this.lawPersons.length;
    const maxSize = 10;
    return listLength < maxSize ? listLength : maxSize;
  }

  emitSelectedLawPersons(): void {
    if (this.isNewPersonMode) {
      this.disableNewPersonMode();
    }
    this.chosenLawPersons.emit(this.getSelectedLawPersons().value || []);
  }

  getSelectedLawPersons(): UntypedFormControl {
    return (
      this.lawPersonListForm &&
      (this.lawPersonListForm.get(
        this.lawPersonListControlName.SelectedLawPersons
      ) as UntypedFormControl)
    );
  }

  getLawPersonDescription(
    lawPerson: LawPersonSearch,
    permissionNumberLabel: string
  ): string {
    return `${
      isLegalPerson(lawPerson)
        ? lawPerson.regon
          ? `REGON: ${lawPerson.regon} | `
          : ''
        : lawPerson.permissionNumber
        ? `${permissionNumberLabel}: ${lawPerson.permissionNumber} | `
        : lawPerson.pesel
        ? `PESEL: ${lawPerson.pesel} | `
        : ''
    }${lawPerson.name} | ${lawPerson.address}`;
  }

  enableNewPersonMode(): void {
    if (!this.validateIfCanAddNewPersonWhenIsDefinedInDb()) {
      return;
    }
    this.isNewPersonMode = true;
    this.newPersonMode.emit(true);
  }

  validateIfCanAddNewPersonWhenIsDefinedInDb(): boolean {
    if (this.canAddNewPersonEvenIfDefinedInDb || this.naturalPersonChosen) {
      return true;
    }
    this.modalService.open(this.personIsAlreadyDefinedInfoModal);

    return false;
  }

  disableNewPersonMode(): void {
    this.isNewPersonMode = false;
    this.newPersonMode.emit(false);
  }

  isChosenLawPersons(): boolean {
    const selectedLawPersons = this.getSelectedLawPersons();

    return !!(
      selectedLawPersons &&
      selectedLawPersons.value &&
      selectedLawPersons.value.length
    );
  }

  getHtmlResultsHeaderWithCount(): string {
    return `${this.lawPersonResultsHeaderTranslation}: ${
      this.lawPersons.length > 1
        ? `<span class="text-danger">${this.lawPersons.length}, ${this.selectFromListTranslation}</span>`
        : `${this.lawPersons.length}`
    }`;
  }
}
