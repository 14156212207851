import * as _ from 'lodash';

import { DictionaryConfig } from '../../gk-dynamic-list.model';

export type DictionaryFromApi = any[];

export class Dictionary {
  constructor(
    public dictType: string,
    public dictName: string,
    public fields: DictionaryField[],
  ) {}

  static fromApiToApp(
    config: DictionaryConfig,
    dictFromApi: DictionaryFromApi,
  ): Dictionary {
    return new Dictionary(
      config.dictType,
      config.dictName,
      dictFromApi.map((dictFieldFromApi) =>
        DictionaryField.fromApiToAppWithConfig(dictFieldFromApi, config),
      ),
    );
  }
}

export type FieldId = number | string;

export interface DictionaryFieldFromApi {
  Id: FieldId;
  Nazwa: string;
  IsArchival?: boolean;
}

export class DictionaryField {
  constructor(
    public id: FieldId,
    public name: string,
    public isArchival?: boolean,
  ) {}

  static fromApiToApp(fieldFromApi: DictionaryFieldFromApi): DictionaryField {
    return new this(
      fieldFromApi.Id,
      fieldFromApi.Nazwa,
      fieldFromApi.IsArchival,
    );
  }

  static fromApiToAppWithConfig(
    fieldFromApi: any,
    config: DictionaryConfig,
  ): DictionaryField {
    return new this(
      _.get(fieldFromApi, config.fieldIdPath),
      _.get(fieldFromApi, config.fieldNamePath),
      _.get(fieldFromApi, config.fieldIsArchivalPath),
    );
  }
}

export const isDictionaryField = (item: any): item is DictionaryField =>
  !_.isUndefined(item) &&
  !_.isNumber(item) &&
  !_.isString(item) &&
  'id' in item &&
  'name' in item;

export enum LangType {
  Polish = 'pl',
}

export const dictionaryDefaultValue = new DictionaryField(null, 'Wybierz...');
