import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { AttributesFormValue } from '../../../gk-dynamic-form/gk-dynamic-form.model';
import {
  FilterOperatorType,
  ValueType,
} from '../../../gk-dynamic-list/gk-dynamic-list.model';
import { createLandParcelDtoObjectFromString } from '../../../utils/utils';
import {
  AddressSearchFieldPath,
  AjaxFilter,
  AjaxFilterWrapper,
  BasicFieldContainsTypeSearch,
  BasicSearchFieldPath,
  BuildingSearchFieldPath,
  LandAndMortgageRegisterSearchFieldPath,
  Wkt,
} from '../../models';

@Injectable()
export class AttributesFilterService {
  getAttributesFilter(
    formValue: AttributesFormValue,
    take?: number,
  ): AjaxFilterWrapper {
    const addressFilter = new AjaxFilter(
      'Adres',
      this.getExtendedAjaxFilterValue(AddressSearchFieldPath, formValue),
    );
    const buildingFilter = new AjaxFilter(
      'NumerBudEwidencyjny',
      this.getExtendedAjaxFilterValue(BuildingSearchFieldPath, formValue),
    );
    const landAndMortgageRegisterFilter = new AjaxFilter(
      'Kw',
      this.getExtendedAjaxFilterValue(
        LandAndMortgageRegisterSearchFieldPath,
        formValue,
      ),
      FilterOperatorType.Contains,
    );

    return this.getWrappedAjaxFilter(
      [
        ...this.getBasicAjaxFilters(BasicSearchFieldPath, formValue),
        ...this.getFiltersWithNotEmptyValue(
          addressFilter,
          buildingFilter,
          landAndMortgageRegisterFilter,
        ),
        ...this.maybeGetFiltersForLandParcelNumber(
          _.get(formValue, ValueType.LandParcelNumber),
        ),
      ],
      take,
    );
  }

  getFiltersWithNotEmptyValue(...filters: AjaxFilter[]): AjaxFilter[] {
    return filters.filter((filter) => !_.isEmpty(filter.value));
  }

  getBasicAjaxFilters(
    basicSearchFieldPaths: typeof BasicSearchFieldPath,
    formValue: AttributesFormValue,
  ): AjaxFilter[] {
    return Object.keys(basicSearchFieldPaths)
      .map((basicSearchFieldPath) => {
        const fieldValue = _.get<AttributesFormValue, string>(
          formValue,
          basicSearchFieldPaths[
            basicSearchFieldPath as keyof typeof BasicSearchFieldPath
          ],
        );
        if (fieldValue) {
          const operator = BasicFieldContainsTypeSearch.includes(
            basicSearchFieldPath,
          )
            ? FilterOperatorType.Contains
            : FilterOperatorType.Equals;
          return new AjaxFilter(basicSearchFieldPath, fieldValue, operator);
        }

        return undefined;
      })
      .filter((definedValue) => definedValue);
  }

  getExtendedAjaxFilterValue(
    searchFieldPaths:
      | typeof AddressSearchFieldPath
      | typeof BuildingSearchFieldPath
      | typeof LandAndMortgageRegisterSearchFieldPath,
    formValue: AttributesFormValue,
  ): { [key: string]: string } {
    return Object.assign(
      {},
      ...Object.keys(searchFieldPaths)
        .map((searchFieldPath) => {
          const fieldValue = _.get(
            formValue,
            searchFieldPaths[
              searchFieldPath as keyof typeof AddressSearchFieldPath &
                BuildingSearchFieldPath &
                LandAndMortgageRegisterSearchFieldPath
            ],
            null,
          );
          if (fieldValue) {
            return { [searchFieldPath]: fieldValue };
          }

          return undefined;
        })
        .filter(Boolean),
    );
  }

  getWrappedAjaxFilter(
    filters: AjaxFilter[],
    take?: number,
  ): AjaxFilterWrapper {
    return {
      filter: {
        logic: 'and',
        filters,
      },
      take: take || null,
    };
  }

  getGeometryFilter(wkt: Wkt): AjaxFilterWrapper {
    return this.getWrappedAjaxFilter([
      new AjaxFilter('Geom', wkt, FilterOperatorType.Intersects),
    ]);
  }

  maybeGetFiltersForLandParcelNumber(landParcelNumber: string): AjaxFilter[] {
    const filterArray = [];

    if (landParcelNumber) {
      const { NrL, NrM, NrAdd } =
        createLandParcelDtoObjectFromString(landParcelNumber);

      if (NrL) {
        filterArray.push(new AjaxFilter('NrL', NrL.toString()));
      }

      if (NrM) {
        filterArray.push(new AjaxFilter('NrM', NrM.toString()));
      }

      if (NrAdd) {
        filterArray.push(new AjaxFilter('NrAdd', NrAdd));
      }
    }

    return filterArray;
  }
}
