export enum BuildingWorksControlName {
  LawPerson = 'lawPerson',
  LaneOccupancyPurpose = 'laneOccupancyPurpose',
  RoadNumber = 'roadNumber',
  Place = 'locationPlace',
  Street = 'locationStreet',
  LaneOccupancyDate = 'laneOccupancyDate',
  LaneOccupancyArea = 'laneOccupancyArea',
  DecisionForm = 'decisionForm',
  ShippingForm = 'shippingForm'
}

export enum LaneOccupancyAreaControlName {
  RoadWidth = 'roadWidth',
  Road = 'road',
  RoadsideComponents = 'roadsideComponents',
  RoadLaneOtherElements = 'roadLaneOtherElements',
  MapState = 'mapState',
  DateRange = 'dateRange',
  CalculatedArea = 'calculatedArea'
}

export enum LaneOccupancyAreaWidth {
  LessThan20PercentWidth = 1,
  Between20And50PercentWidth = 2,
  MoreThan50PercentWidth = 3
}
