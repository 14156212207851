export class WebRequest {
  constructor(public uuid: string, public name: string) {}
  static fromApiToApp(webRequestDto: WebRequestDto): WebRequest {
    return new this(webRequestDto.Uuid, webRequestDto.Nazwa);
  }
}

export interface WebRequestDto {
  Uuid: string;
  Nazwa: string;
}
