import {
  LawPerson,
  LawPersonType,
  NewDesignerRequestDto,
  NewLegalPersonRequestDto,
  NewNaturalPersonRequestDto,
  Street,
} from '@gk/gk-modules';
import {
  Shipping,
  ShippingDto,
} from '../../shared/shipping-form/shipping-form.model';
import { DateHelperUtil } from '../../utils/date-helper/date-helper.util';
import { ApiNewDokumentPowiazanyDalDto } from '../designer-incoming-documents/designer-incoming-documents.model';
import { DecisionFormType } from '../excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { ZamowienieDzialkaDto } from '../excluding-land-from-agricultural-production/agricultural-land-use/agricultural-land-use.model';
import { NgbDateRange } from '../new-request/new-request.model';
import { Place } from '../place/place.model';

export interface PropertyLeaseDto {
  Wnioskodawca:
    | NewNaturalPersonRequestDto
    | NewLegalPersonRequestDto
    | NewDesignerRequestDto;
  MiejscId: string | number;
  Miejsc: string;
  UlicaId: string | number;
  Ulica: string;
  CelDzierzawy: string;
  Powierzchnia: string;
  DzierzawaOd: string;
  DzierzawaDo: string;
  Zalaczniki: ApiNewDokumentPowiazanyDalDto[];
  Geom: string[];
  Dzialki: ZamowienieDzialkaDto[];
  FormaDecyzji: number;
  Odbior: ShippingDto;
  InfoDodatkowe: string;
}

export class PropertyLease {
  constructor(
    public lawPerson: LawPerson,
    public purpose: string,
    public leaseDate: NgbDateRange,
    public place: Place,
    public street: Street,
    public areaToLease: string,
    public decisionForm: DecisionFormType,
    public shippingForm: Shipping,
    public comments: string
  ) {}

  static fromAppToApi(
    data: PropertyLease,
    issueRanges: string[],
    parcels: ZamowienieDzialkaDto[],
    files: ApiNewDokumentPowiazanyDalDto[]
  ): PropertyLeaseDto {
    return {
      Wnioskodawca:
        data.lawPerson.type === LawPersonType.Natural
          ? NewNaturalPersonRequestDto.fromAppToApi(data.lawPerson)
          : NewLegalPersonRequestDto.fromAppToApi(data.lawPerson),
      MiejscId: data.place.id,
      Miejsc: data.place.name,
      UlicaId: data.street && data.street.id,
      Ulica: data.street && data.street.name,
      CelDzierzawy: data.purpose,
      Powierzchnia: data.areaToLease,
      DzierzawaOd: DateHelperUtil.ngbDateToApiDateTime(data.leaseDate.from),
      DzierzawaDo: DateHelperUtil.ngbDateToApiDateTime(data.leaseDate.to),
      Zalaczniki: files,
      Geom: issueRanges,
      FormaDecyzji: data.decisionForm,
      Odbior: ShippingDto.fromAppToApi(data.shippingForm),
      Dzialki: parcels,
      InfoDodatkowe: data.comments,
    };
  }
}
