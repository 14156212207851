export interface TfaReset {
  resetToken: string;
}

export class TfaResetApi {
  constructor(public ResetToken: string) {}

  static fromApp(data: TfaReset): TfaResetApi {
    return new this(data.resetToken);
  }
}
