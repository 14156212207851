@if (shouldShowControl()) {
  <div gkControlRow class="flex-column">
    @if (controlConfig.showLabel) {
      <span class="w-100 fw-bold py-2">
        <gk-translated-label
          [configLabel]="controlConfig.label"
        ></gk-translated-label
        >:</span
      >
    }
    <div class="w-100">
      @if (files.length) {
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">
                {{
                  'GK.DYNAMIC_LIST.CONTROL_ADD_RELATED_DOCUMENT.FILENAME'
                    | translate
                }}
              </th>
              <th scope="col">
                {{
                  'GK.DYNAMIC_LIST.CONTROL_ADD_RELATED_DOCUMENT.SIZE'
                    | translate
                }}
              </th>
              <th scope="col">
                {{
                  'GK.DYNAMIC_LIST.CONTROL_ADD_RELATED_DOCUMENT.PROGRESS'
                    | translate
                }}
              </th>
              <th scope="col">
                {{
                  'GK.DYNAMIC_LIST.CONTROL_ADD_RELATED_DOCUMENT.DOCUMENT_TYPE'
                    | translate
                }}
              </th>
              <th scope="col">
                {{
                  'GK.DYNAMIC_LIST.CONTROL_ADD_RELATED_DOCUMENT.DESCRIPTION'
                    | translate
                }}
              </th>
              <th scope="col">
                {{
                  'GK.DYNAMIC_LIST.CONTROL_ADD_RELATED_DOCUMENT.REMOVE'
                    | translate
                }}
              </th>
            </tr>
          </thead>
          <tbody>
            @for (file of files; track file.uuid; let i = $index) {
              <tr>
                <th scope="row">{{ i + 1 }}</th>
                <td class="text-truncate">{{ file.name }}</td>
                <td class="text-truncate">
                  {{ getFileSizeString(file.size) }}
                </td>
                <td>
                  <ngb-progressbar
                    [type]="
                      file.uploadingState === uploadingStateEnum.Failed
                        ? 'danger'
                        : file.uploadingState === uploadingStateEnum.Waiting
                          ? 'light'
                          : 'success'
                    "
                    [value]="file.progress"
                  >
                  </ngb-progressbar>
                </td>
                <td>
                  <select
                    [disabled]="isFileUploadingOrSucceed(file)"
                    name="fileType"
                    id="fileType"
                    class="form-select form-select-sm"
                    [(ngModel)]="file.docType"
                  >
                    @for (field of documentTypeDict.fields; track field) {
                      <option [ngValue]="field.id">
                        {{ field.name }}
                      </option>
                    }
                  </select>
                </td>
                <td>
                  <input
                    [disabled]="isFileUploadingOrSucceed(file)"
                    type="text"
                    class="form-control form-control-sm"
                    [(ngModel)]="file.description"
                  />
                </td>
                <td>
                  <button
                    (click)="removeFile(file.uuid)"
                    [disabled]="isFileUploadingOrSucceed(file)"
                    type="button"
                    class="btn btn-sm btn-danger fa fa-close"
                    attr.aria-label="{{
                      'GK.DYNAMIC_LIST.CONTROL_ADD_RELATED_DOCUMENT.REMOVE'
                        | translate
                    }}"
                    attr.title="{{
                      'GK.DYNAMIC_LIST.CONTROL_ADD_RELATED_DOCUMENT.REMOVE'
                        | translate
                    }}"
                  ></button>
                </td>
              </tr>
            }
          </tbody>
        </table>
      }
      @if (showInvalidMsg) {
        <div class="alert alert-danger">
          {{
            'GK.DYNAMIC_LIST.CONTROL_ADD_RELATED_DOCUMENT.DOCUMENT_TYPE_REQUIRED'
              | translate
          }}
        </div>
      }
      @if (files.length) {
        <button
          (click)="upload()"
          type="button"
          class="mb-3 btn btn-success"
          [disabled]="isUploading || !getNumberOfFilesToUpload()"
        >
          {{
            'GK.DYNAMIC_LIST.CONTROL_ADD_RELATED_DOCUMENT.UPLOAD' | translate
          }}
        </button>
      }
      @if (resSuccess) {
        <p class="text-success">
          {{
            'GK.DYNAMIC_LIST.CONTROL_ADD_RELATED_DOCUMENT.UPLOAD_COMPLETED_NUMBER'
              | translate
          }}&nbsp;{{ resSuccess }}.
        </p>
      }
      @if (resFailed) {
        <p class="text-danger">
          {{
            'GK.DYNAMIC_LIST.CONTROL_ADD_RELATED_DOCUMENT.UPLOAD_NOT_COMPLETED_NUMBER'
              | translate
          }}&nbsp;{{ resFailed }}.
        </p>
      }
      <div
        ngfDrop
        multiple="1"
        selectable
        class="file-drop-zone-with"
        [(files)]="files"
        (filesChange)="handleAction()"
      >
        <span>{{
          'GK.DYNAMIC_LIST.CONTROL_ADD_RELATED_DOCUMENT.DROP_FILES' | translate
        }}</span>
        <button class="btn btn-color-from-db ms-2">
          {{
            'GK.DYNAMIC_LIST.CONTROL_ADD_RELATED_DOCUMENT.CHOOSE_FILES'
              | translate
          }}
        </button>
      </div>
    </div>
  </div>
}
