import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { GkComponentsModule, GkMapModule } from '@gk/gk-modules';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { createTranslateLoader } from '../address-number-request-portal/address-number-request-portal.module';
import { BuildPermissionRequestComponent } from './build-permission-request/build-permission-request.component';
import { RoadExitPortalRoutingModule } from './road-exit-portal-routing.module';
import { RoadExitPortalComponent } from './road-exit-portal.component';

@NgModule({
  declarations: [RoadExitPortalComponent, BuildPermissionRequestComponent],
  imports: [
    SharedModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    RoadExitPortalRoutingModule,
    GkComponentsModule,
    GkMapModule,
  ],
})
export class RoadExitPortalModule {}
