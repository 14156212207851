<div [hidden]="true" class="dynamic-field form-checkbox" [formGroup]="group">
  <label>
    <input type="checkbox" [formControlName]="config.name" />
    <span class="ms-3">{{ config.name }}</span>
  </label>
</div>
<gk-form-control-validation
  [formGroup]="group"
  [fieldConfig]="config"
  [submitted]="submitted"
></gk-form-control-validation>
