export interface NewMessageFromApi {
  CzatId: number;
  DataD: string;
  SygnaturaSprawy: string;
  WiadomoscOd: string;
  TematSprawy: string;
  ZudUuid: string;
}

export interface NewMessageFromSurveyorApi {
  CzatId: number;
  DataD: string;
  NazwaSprawy: string;
  WiadomoscUserOd: string;
  Sprawa: string;
  SprawaId: number;
}

export class NewMessage {
  constructor(
    public chatId: number,
    public addDate: string,
    public caseSignature: string,
    public author: string,
    public caseName: string,
    public id: string
  ) {}

  static fromApiDesignerToApp(
    newMessageFromApi: NewMessageFromApi
  ): NewMessage {
    return new this(
      newMessageFromApi.CzatId,
      newMessageFromApi.DataD,
      newMessageFromApi.SygnaturaSprawy,
      newMessageFromApi.WiadomoscOd,
      newMessageFromApi.TematSprawy,
      newMessageFromApi.ZudUuid
    );
  }

  static fromApiSurveyorToApp(
    newMessageFromApi: NewMessageFromSurveyorApi
  ): NewMessage {
    return new this(
      newMessageFromApi.CzatId,
      newMessageFromApi.DataD,
      newMessageFromApi.NazwaSprawy,
      newMessageFromApi.WiadomoscUserOd,
      newMessageFromApi.Sprawa,
      newMessageFromApi.SprawaId.toString()
    );
  }
}
