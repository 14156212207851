import { Injectable } from '@angular/core';
import { PortalId } from '@gk/gk-modules';
import * as _ from 'lodash';
import { map, ReplaySubject } from 'rxjs';
import { DocType } from '../../doc-type/doc-type.model';
import { DocTypeService } from '../../doc-type/doc-type.service';
import { DocFile } from '../../new-designer-request/new-designer-request.model';
import {
  BuildingPermitTransferDocTypeId,
  getBuildingPermitTransferDocTypeLabelKeyById,
} from './building-permit-transfer-doc-type.model';

@Injectable()
export class BuildingPermitTransferDocTypeService {
  docTypes = new ReplaySubject<DocType[]>();
  obligatoryDocTypes: DocType[];
  obligatoryDocTypesFromApi: DocType[];
  private investorDetailsFormControlChecked: boolean;
  constructor(private docTypeService: DocTypeService) {
    this.fetchDocTypes();
  }

  private fetchDocTypes(): void {
    this.docTypeService
      .getDocTypeForPortal(PortalId.BuildingPermitTransfer)
      .pipe(
        map((data) =>
          data.map((element) => ({
            ...element,
            labelKey: getBuildingPermitTransferDocTypeLabelKeyById(element.id),
          })),
        ),
      )
      .subscribe((data) => this.docTypes.next(data));
    this.setObligatoryDocTypesFromApi();
    this.setObligatoryDocTypes();
  }

  getOrUpdateObligatoryDocTypes(
    investorDetailsFormControlChecked = false,
  ): void {
    this.investorDetailsFormControlChecked = investorDetailsFormControlChecked;
    if (investorDetailsFormControlChecked) {
      this.setObligatoryDocTypesWhenInvestorDetailsFormControlChecked();
    } else {
      this.setObligatoryDocTypes();
    }
  }

  private setObligatoryDocTypesFromApi(): void {
    this.docTypes.subscribe(
      (docTypes) =>
        (this.obligatoryDocTypesFromApi = docTypes.filter(
          (docType) => docType.isObligatory,
        )),
    );
  }

  private setObligatoryDocTypes(): void {
    this.docTypes.subscribe((docTypes) => {
      this.obligatoryDocTypes = docTypes.filter(
        (docType) =>
          docType.id !== BuildingPermitTransferDocTypeId.PowerOfAttorney &&
          docType.id !== BuildingPermitTransferDocTypeId.Other,
      );
    });
  }

  private setObligatoryDocTypesWhenInvestorDetailsFormControlChecked(): void {
    this.docTypes.subscribe(
      (docTypes) =>
        (this.obligatoryDocTypes = [
          ...docTypes.filter(
            (docType) =>
              docType.id === BuildingPermitTransferDocTypeId.StampDuty ||
              docType.id === BuildingPermitTransferDocTypeId.PowerOfAttorney,
          ),
          ...this.obligatoryDocTypesFromApi,
        ]),
    );
  }

  areObligatoryDocFilesAttached(attachedDocFiles: DocFile[]): boolean {
    if (this.investorDetailsFormControlChecked) {
      return (
        _.every(this.obligatoryDocTypesFromApi, (oblDocType) =>
          _.find(
            attachedDocFiles,
            (attachedDocFile) => attachedDocFile.docTypeId === oblDocType.id,
          ),
        ) &&
        attachedDocFiles.some(
          (docFile) =>
            docFile.docTypeId ===
            BuildingPermitTransferDocTypeId.RequestStampDuty,
        ) &&
        attachedDocFiles.some(
          (docFile) =>
            docFile.docTypeId ===
            BuildingPermitTransferDocTypeId.PowerOfAttorney,
        )
      );
    } else {
      return _.every(this.obligatoryDocTypesFromApi, (oblDocType) =>
        _.find(
          attachedDocFiles,
          (attachedDocFile) => attachedDocFile.docTypeId === oblDocType.id,
        ),
      );
    }
  }
}
