import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiListResponse } from '@gk/gk-modules';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, first, forkJoin, map, of } from 'rxjs';
import { getFileSizeString } from '../../utils/files/files.util';
import { PortalType } from '../file-type-matcher/file-type-matcher.model';
import { DocFile } from '../new-designer-request/new-designer-request.model';
import {
  ApiDocType,
  DocType,
  powerOfAttorneyDocTypeId,
} from './doc-type.model';

@Injectable()
export class DocTypeService {
  docTypes = new BehaviorSubject<DocType[]>([]);

  constructor(private httpClient: HttpClient) {
    this.fetchDocTypeDict();
  }

  changeDocTypes(newDocTypes: DocType[]): void {
    this.docTypes.next(newDocTypes);
  }

  changeDutifulnessOfPowerOfAttorney(required: boolean): void {
    const newDocTypes = this.docTypes.getValue().map((dt) => {
      if (dt.id !== powerOfAttorneyDocTypeId) {
        return dt;
      }

      dt.isObligatory = required;
      return dt;
    });
    this.docTypes.next(newDocTypes);
  }

  private getDocTypeDict(): Observable<DocType[]> {
    return this.httpClient
      .get<ApiListResponse<ApiDocType>>('/api/zudp/projektant/slo/kdokrodz')
      .pipe(
        map((res) => res.Response),
        map((apiFields) =>
          apiFields.map((apiField) => DocType.fromApiToApp(apiField)),
        ),
      );
  }

  private fetchDocTypeDict(): void {
    this.getDocTypeDict().subscribe((docTypeFields) =>
      this.changeDocTypes(docTypeFields),
    );
  }

  getObligatoryDocTypes(
    portalType: PortalType,
    attachedDocFiles: DocFile[] = [],
  ): DocType[] {
    switch (portalType) {
      case PortalType.Designer:
        return this.docTypes
          .getValue()
          .filter(
            (docType) =>
              docType.isObligatory &&
              !attachedDocFiles.some(
                (attachedDocFile) => attachedDocFile.docTypeId === docType.id,
              ),
          );
      default:
        return [];
    }
  }

  areObligatoryDocFilesAttached(
    attachedDocFiles: DocFile[],
    portalType: PortalType,
  ): boolean {
    const obligatoryDocTypes = this.getObligatoryDocTypes(portalType);

    return _.every(obligatoryDocTypes, (oblDocType) =>
      _.find(
        attachedDocFiles,
        (attachedDocFile) => attachedDocFile.docTypeId === oblDocType.id,
      ),
    );
  }

  public getDocTypeForPortal(portalId: number): Observable<DocType[]> {
    const params = new HttpParams().set('PortalId', `${portalId}`);

    return this.httpClient
      .get<ApiListResponse<ApiDocType>>(`/api/eod/slo/kdokrodz/portal`, {
        params,
      })
      .pipe(
        map((res) => res.Response),
        map((apiFields) =>
          apiFields.map((apiField) => DocType.fromApiToApp(apiField)),
        ),
      );
  }

  isCorrectEveryFileSize(files: DocFile[]): Observable<boolean> {
    return !files.length
      ? of(true)
      : forkJoin(
          files.map((file) => this.isCorrectFileSize(file).pipe(first())),
        ).pipe(
          map((outcomeOfFilesSizeValidation) =>
            outcomeOfFilesSizeValidation.every(Boolean),
          ),
        );
  }

  isCorrectFileSize(file: DocFile): Observable<boolean> {
    return this.getMaxSizeOfDocType(file.docTypeId).pipe(
      map(
        (docTypeMaxSize) =>
          !file.docTypeId || !docTypeMaxSize || file.size <= docTypeMaxSize,
      ),
    );
  }

  getParsedMaxSizeOfDocType(docTypeId: number): Observable<string> {
    return this.getMaxSizeOfDocType(docTypeId).pipe(
      map((maxSize) => (maxSize ? getFileSizeString(maxSize) : '')),
    );
  }

  getMaxSizeOfDocType(docTypeId?: number): Observable<number | null> {
    return docTypeId
      ? this.docTypes.pipe(
          map((docTypes) =>
            docTypes.find((docType) => docType.id === docTypeId),
          ),
          map((docType) => docType.maxSize),
        )
      : of(null);
  }

  getDocTypeMaxSize(): Observable<number | null> {
    return this.docTypes.pipe(
      map((docTypes) => this.getDocTypesMaxSize(docTypes)),
    );
  }

  getDocTypesMaxSize(docTypes: DocType[]): number | null {
    return docTypes.every((docType) => docType.maxSize)
      ? Math.max(...docTypes.map((docType) => docType.maxSize))
      : null;
  }
}
