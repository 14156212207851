export enum SessionPzStatus {
  Unauthorized = 0,
  PersonalDataConfirmationNeeded = 2,
  Authorized = 3,
}

export enum SessionType {
  Unauthorized = 0,
  EwidAuth = 2,
  TokenAuth = 3,
  PzAuth = 4,
  PerunAuth = 5,
}

export interface ApiSystemSession {
  SessionType: SessionType;
  DisplayName: string;
  SessionPzStatus: SessionPzStatus;
  PzLogin: string;
  IsGuest: boolean;
  IsEmployee?: boolean;
}

export class SystemSession {
  constructor(
    public sessionType: SessionType,
    public displayName: string,
    public sessionPzStatus: SessionPzStatus,
    public pzLogin: string,
    public isGuest: boolean,
    public isEmployee?: boolean,
  ) {}

  static fromApiToApp(sessionStateFromApi: ApiSystemSession): SystemSession {
    return new this(
      sessionStateFromApi.SessionType,
      sessionStateFromApi.DisplayName,
      sessionStateFromApi.SessionPzStatus,
      sessionStateFromApi.PzLogin,
      sessionStateFromApi.IsGuest,
      sessionStateFromApi.IsEmployee,
    );
  }

  static getEmpty(): SystemSession {
    return new this(
      SessionType.Unauthorized,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
  }

  isAuthenticated(): boolean {
    if (this.isGuest) {
      return false;
    }
    switch (this.sessionType) {
      case SessionType.EwidAuth:
      case SessionType.TokenAuth:
      case SessionType.PzAuth:
      case SessionType.PerunAuth:
        return true;
      case SessionType.Unauthorized:
      default:
        return false;
    }
  }

  isPerunAuthenticated(): boolean {
    return this.sessionType === SessionType.PerunAuth && !this.isGuest;
  }

  isPzAuthenticated(): boolean {
    return !!this.pzLogin && !this.isGuest;
  }
}

export class ContactData {
  constructor(
    public email?: string,
    public postalCode?: string,
    public postalCodeId?: number | string,
    public place?: string,
    public placeId?: number | string,
    public street?: string,
    public streetId?: number | string,
    public buildingNumber?: string,
    public localNumber?: string,
    public phone?: string,
  ) {}
}

export class PetentForLoggedUserData extends ContactData {
  constructor(
    public name: string,
    public nip: string,
    public pesel: string,
    public regon: string,
    public type: string,
    public override email: string,
    public override postalCode: string,
    public override postalCodeId: number,
    public override place: string,
    public override placeId: number,
    public override street: string,
    public override streetId: number,
    public override buildingNumber: string,
    public override localNumber: string,
    public override phone: string,
    public fullName: string,
  ) {
    super(
      email,
      postalCode,
      postalCodeId,
      place,
      placeId,
      street,
      streetId,
      buildingNumber,
      localNumber,
      phone,
    );
  }
  static fromApiToApp(
    data: PetentForLoggedUserResponseDto,
  ): PetentForLoggedUserData {
    return new this(
      data.Nazwa,
      data.Nip,
      data.Pesel,
      data.Regon,
      data.Typ,
      data.Email,
      data.KodPoczt,
      data.KodPocztId,
      data.Miejsc,
      data.MiejscId,
      data.Ulica,
      data.UlicaId,
      data.NumerBud,
      data.NumerLok,
      data.Telefon,
      data.PetentFull,
    );
  }
}

interface PetentForLoggedUserResponseDto {
  Nazwa: string;
  Typ: string;
  Nip: string;
  Pesel: string;
  Regon: string;
  Email: string;
  KodPoczt: string;
  KodPocztId: number;
  Miejsc: string;
  MiejscId: number;
  Ulica: string;
  UlicaId: number;
  NumerBud: string;
  NumerLok?: string;
  Telefon: string;
  PetentFull: string;
}

export interface LoggedUserDataResponseDto {
  Uuid: string;
  OwnerId: number;
  Nazwa: string;
  NazwaDruk: string;
  NazwaWyswietlana: string;
  Imie: string;
  Nazwisko: string;
  Email: string;
  OstatniLogin: string;
  GesutOsobaId: number;
  Petent: PetentForLoggedUserResponseDto;
  PetentSgk: string;
}

const guestUserName = 'GOSC';

export class LoggedUserData {
  constructor(
    public uuid: string,
    public ownerId: number,
    public name: string,
    public printName: string,
    public displayName: string,
    public firstName: string,
    public secondName: string,
    public email: string,
    public lastLogin: string,
    public gesutPersonId: number,
    public client: PetentForLoggedUserData,
    public clientSgk: string,
    public isLogged: boolean,
    public isGuest: boolean,
  ) {}

  static fromApiToApp(data: LoggedUserDataResponseDto): LoggedUserData {
    return new this(
      data.Uuid,
      data.OwnerId,
      data.Nazwa,
      data.NazwaDruk,
      data.NazwaWyswietlana,
      data.Imie,
      data.Nazwisko,
      data.Email,
      data.OstatniLogin,
      data.GesutOsobaId,
      PetentForLoggedUserData.fromApiToApp(data.Petent),
      data.PetentSgk,
      data.Uuid && data.Nazwa !== guestUserName,
      data.Nazwa === guestUserName,
    );
  }
}
