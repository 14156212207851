import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  DownloadableApplication,
  GetSystemInstalatoryDownloadRequestDto,
} from './downloadable-applications.model';
import { shareReplay } from 'rxjs/operators';

@Injectable()
export class DownloadableApplicationsService {
  readonly requestUrl = '/api/system/instalatory';
  private downloadableApplications: Observable<DownloadableApplication[]>;

  constructor(private httpClient: HttpClient) {}

  public getApplicationsData(): Observable<DownloadableApplication[]> {
    if (!this.downloadableApplications) {
      this.downloadableApplications = this.httpClient
        .get<GetSystemInstalatoryDownloadRequestDto[]>(this.requestUrl)
        .pipe(
          map((apiFields) =>
            apiFields.map((apiField) =>
              DownloadableApplication.fromApiToApp(apiField),
            ),
          ),
          shareReplay(1),
        );
    }

    return this.downloadableApplications;
  }
}
