import { EnablementDto, Street } from '@gk/gk-modules';
import { District } from '../community/community.model';
import { RangeType } from '../parcel/parcel.model';
import { Place } from '../place/place.model';
import { PostalCode } from '../postal-code/postal-code.model';
import { PetitionerSharingPurpose } from '../sharing-purpose/sharing-purpose.model';

export enum SharingPurposeControl {
  Name = 'purpose',
}

export enum MapTypeControl {
  GroupName = 'mapTypeId',
}

export enum WmsMapScaleControl {
  Name = 'wmsMapScale',
}

export enum IpAddressControl {
  Name = 'ipAddress',
}

export enum AccessTimeControl {
  GroupName = 'accessTime',
}

export enum NameSurnameControl {
  Name = 'nameSurname',
}

export enum EmailControl {
  Name = 'email',
}

export enum PhoneControl {
  Name = 'phone',
}

export enum AccessTime {
  ThreeMonths = 'Msc3',
  SixMonths = 'Msc6',
  TwelveMonths = 'Msc12',
}

export enum MapType {
  Cadastral = 1,
  Basic = 2,
}

export enum WmsMapScale {
  Scale500 = 500,
  Scale1000 = 1000,
  Scale2000 = 2000,
  Scale5000 = 5000,
}

export interface DictNullableIdDto {
  Id?: number;
  Nazwa: string;
}

export interface PayerDetailsDataDto {
  Nazwa: string;
  Skrot?: string;
  Imie: string;
  Nazwisko: string;
  Pesel?: string;
  Nip?: string;
  Regon?: string;
  Email: string;
  Telefon: string;
  NrKonta?: string;
  RodzPetId?: number;
}

export class PayerDetailsData {
  constructor(
    public companyName: string,
    public firstName: string,
    public lastName: string,
    public email: string,
    public phone: string,
    public companyShortcut?: string,
    public pesel?: string,
    public nip?: string,
    public regon?: string,
    public bankAccountNumber?: string,
    public rodzPetId?: number,
    public address?: PayerDetailsAddress
  ) {}

  static fromApiToApp(event: PayerDetailsData) {
    return new this(
      event.companyName,
      event.firstName,
      event.lastName,
      event.email,
      event.phone,
      event.companyShortcut,
      event.pesel,
      event.nip,
      event.regon,
      event.bankAccountNumber,
      event.rodzPetId,
      event.address
    );
  }
}

export interface PayerDetailsAddressDto {
  Kraj?: string;
  Wojewodztwo?: string;
  Powiat?: string;
  Gmina?: string;
  NumerBudynku: string;
  NumerLokalu?: string;
  Miejscowosc: DictNullableIdDto;
  Ulica: DictNullableIdDto;
  KodPocztowy: DictNullableIdDto;
}

export interface PayerDetailsAddress {
  country?: string;
  voivodeship?: string;
  county?: string;
  community?: string;
  buildingNumber: string;
  localNumber?: string;
  place: Place | string;
  street: Street | string;
  postalCode: PostalCode | string;
}

export interface PayerDetailsDto {
  Dane: PayerDetailsDataDto;
  Adres: PayerDetailsAddressDto;
  OsobaTyp: string;
}

export class OrderRangeDto {
  constructor(public ObrebIds: null | number[], public Wkt: null | string) {}
}

export interface NewNetworkServiceRequestApiModel {
  CelId: number;
  KontaktOsoba: string;
  KontaktEmail: string;
  KontaktTelefon: string;
  AdresyIp: string[];
  Pelnomocnictwo: EnablementDto;
  PlatnikDane: PayerDetailsDto;
  CzasDostepu: AccessTime;
  RodzajMapyId: MapType;
  SkalaMapy: WmsMapScale;
  Zakres: OrderRangeDto;
}

export class NewNetworkServiceRequestModel {
  constructor(
    public purpose: PetitionerSharingPurpose,
    public nameSurname: string,
    public email: string,
    public phone: string,
    public ipAddress: string,
    public ipAddresses: string[],
    public districts: District[],
    public wkt: string,
    public rangeType: RangeType,
    public mapTypeId: MapType,
    public wmsMapScale: WmsMapScale,
    public accessTime: AccessTime,
    public payerDetails: PayerDetailsData
  ) {}

  static getInitialModel(): NewNetworkServiceRequestModel {
    return {
      [SharingPurposeControl.Name]: null,
      rangeType: RangeType.Wkt,
      [MapTypeControl.GroupName]: MapType.Cadastral,
      wmsMapScale: WmsMapScale.Scale2000,
      [AccessTimeControl.GroupName]: AccessTime.SixMonths,
      [IpAddressControl.Name]: '',
      phone: '',
      email: '',
      nameSurname: '',
    } as NewNetworkServiceRequestModel;
  }
}

export interface WmsMapCalculatePaymentRequestApiModel {
  CelId: number;
  CzasDostepu: AccessTime;
  RodzajMapyId: MapType;
  SkalaMapy: WmsMapScale;
  Zakres: OrderRangeDto;
}

export class WmsMapCalculatePaymentRequestModel {
  constructor(
    public purpose: PetitionerSharingPurpose,
    public rangeType: RangeType,
    public mapTypeId: MapType,
    public wmsMapScale: WmsMapScale,
    public accessTime: AccessTime,
    public districts: District[],
    public wkt: string
  ) {}
}
