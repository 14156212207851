<div class="mt-2">
  <kendo-label
    [text]="'GK.MAP.SELECT_FILE_WITH_PARCEL_IDS_IN_FORMAT' | translate"
  >
    <kendo-fileselect
      [multiple]="false"
      [restrictions]="fileRestrictions"
      [(ngModel)]="selectedFiles"
      (select)="select($event)"
    ></kendo-fileselect>
  </kendo-label>
</div>

@if (selectedFiles) {
  <div class="mt-2">
    @if (!isValidFormat) {
      <p>
        <i [ngClass]="'fa fa-times text-danger'" aria-hidden="true"></i>
        {{ 'GK.MAP.WRONG_FORMAT' | translate }}
      </p>
    }
    @if (!isValidMaxNumberLimit) {
      <p>
        <i [ngClass]="'fa fa-times text-danger'" aria-hidden="true"></i>
        {{ tooMuchDataMessage }}
      </p>
    }
    @if (validationResult?.isValid === IdentifiersValidateStatuses.Invalid) {
      <p>
        <i [ngClass]="'fa fa-times text-danger'" aria-hidden="true"></i>
        {{ 'GK.MAP.NO_VALID_IDENTIFIER' | translate }}
      </p>
    }
  </div>
}
<div class="flex-column my-2">
  <span
    ><i>{{ 'GK.MAP.EXAMPLE_WORD' | translate }}: </i>"{{
      'GK.MAP.EXAMPLE_PARCEL_IDS' | translate
    }}"</span
  >
</div>

<gk-kendo-action-buttons
  [buttonsConfigs]="gkKendoGeomFileSelectActionButtonConfig"
></gk-kendo-action-buttons>

<ng-template #dialogErrorLogContent>
  <div class="d-flex flex-column h-100">
    <div>
      {{ 'GK.MAP.LOAD_ONLY_VALID_NUMBERS' | translate }}
    </div>
    <div class="py-3 flex-grow-1 overflow-auto">
      <p>{{ 'GK.MAP.ERROR_IDS_LIST' | translate }}</p>
      <div
        class="text-danger overflow-auto"
        [innerHTML]="getInvalidIdentifiers()"
      ></div>
    </div>
  </div>
</ng-template>
