@if (shouldShowControl()) {
  <div gkControlRow>
    <div gkControlLabel [showLabel]="controlConfig.showLabel">
      <gk-translated-label
        [configLabel]="controlConfig.label"
        ></gk-translated-label
        >:
      </div>
      <div gkControlValue [showLabel]="controlConfig.showLabel">
        @if (loading) {
          <div class="d-flex justify-content-center my-3">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
            </div>
          </div>
        }
        @if (!loading) {
          <button
            type="button"
            class="btn btn-link p-0"
            (click)="getFile()"
            >
            {{
            controlConfig.staticFileName
            ? (controlConfig.staticFileName | translate)
            : getFileNameAndExtension()
            }}
          </button>
        }
        @if (errorMsg) {
          <span class="text-danger"
            >{{ 'GK.DYNAMIC_LIST.ERROR_OCCURRED' | translate }}: {{ errorMsg }}</span
            >
          }
        </div>
      </div>
    }
