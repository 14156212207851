import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { PetentData, PzService } from '@gk/gk-modules';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { PermissionControlName } from './permission-form/permission-form.model';
import { PermissionFormService } from './permission-form/permission-form.service';

@Component({
  selector: 'app-permission-number-modal',
  templateUrl: './permission-number-modal.component.html',
  styleUrls: ['./permission-number-modal.component.scss'],
})
export class PermissionNumberModalComponent implements OnInit, OnDestroy {
  private isAlive = true;
  permissionFormGroup: UntypedFormGroup;
  petentData: PetentData;
  permissionControlName = PermissionControlName;
  @ViewChild('permissionNumberModal') permissionNumberModal: NgbModalRef;

  constructor(
    private pzService: PzService,
    private permissionFormService: PermissionFormService,
    private ngbModal: NgbModal,
  ) {}

  ngOnInit() {
    this.fetchPetentData();
    this.createFormGroup();
  }

  fetchPetentData(): void {
    this.pzService
      .getPetentData(true)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(async (petentData) => {
        if (_.isEmpty(petentData)) {
          return;
        }

        this.petentData = petentData;
      });
  }

  createFormGroup(): void {
    this.permissionFormGroup = this.permissionFormService.getFormGroup();
  }

  askForPermissionNumberWhenPetentDataHasNotIt(
    shouldNotAskForce?: boolean,
  ): Observable<string | undefined> {
    const numberFormControl = this.getNumberFormControl();
    numberFormControl.clearValidators();
    return new Observable<string | undefined>((observer) => {
      if (
        !shouldNotAskForce &&
        (!this.petentData || !this.petentData.isLegalPerson) &&
        (!this.petentData || !this.petentData.authorizedPersonId)
      ) {
        this.setNumberValidatorsAndPatchDefaultValue();
        const modal = this.openAndReturnNumberModal();
        modal.result.then((result) => {
          if (result) {
            observer.next(numberFormControl.value);
          }
          observer.complete();
        });
      } else {
        observer.next();
        observer.complete();
      }
    });
  }

  shouldShowNumberFormControlAlert(): boolean {
    const numberFormControl = this.getNumberFormControl();

    return !!(
      numberFormControl &&
      !numberFormControl.valid &&
      (numberFormControl.dirty || numberFormControl.touched)
    );
  }

  isNumberFormControlValid(): boolean {
    return this.getNumberFormControl().valid;
  }

  getNumberFormControl(): UntypedFormControl {
    return this.permissionFormGroup.get(
      this.permissionControlName.Number,
    ) as UntypedFormControl;
  }

  setNumberValidatorsAndPatchDefaultValue(): void {
    const getNumberFormControl = this.getNumberFormControl();
    getNumberFormControl.setValidators(Validators.required);
  }

  openAndReturnNumberModal(): NgbModalRef {
    return this.ngbModal.open(this.permissionNumberModal, {
      backdrop: 'static',
      keyboard: false,
    });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
