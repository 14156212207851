<ng-template #modal let-close="close(true)">
  <div class="modal-header">
    <h4 class="modal-title">{{'MODAL_PROLONG_SESSION.ALERT_TITLE' | translate}}</h4>
  </div>
  <div class="modal-body">
    {{'MODAL_PROLONG_SESSION.DESCRIPTION' | translate}}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="close">
      {{'MODAL_PROLONG_SESSION.CONFIRM' | translate}}
    </button>
  </div>
</ng-template>
