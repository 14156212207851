export enum PropertyLeaseControlName {
  LawPerson = 'lawPerson',
  Place = 'place',
  Street = 'street',
  AreaToLease = 'areaToLease',
  DecisionForm = 'decisionForm',
  ShippingForm = 'shippingForm',
  Comments = 'comments',
  LeaseDate = 'leaseDate',
  Purpose = 'purpose'
}
