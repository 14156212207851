import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

const locale = 'pl-PL';

export class DateHelperUtil {
  static ngbDateToApiDateTime(ngbDate: NgbDateStruct): string {
    if (!ngbDate) {
      throw new Error('Wrong ngbDate struct!');
    }

    const apiDateTime = `${ngbDate.year}-${
      ngbDate.month > 9 ? ngbDate.month : '0' + ngbDate.month
    }-${ngbDate.day > 9 ? ngbDate.day : '0' + ngbDate.day}T00:00:00.0000000`;
    return apiDateTime;
  }

  static ngbDateToUiDate(ngbDate: NgbDateStruct): string {
    const uiDate = `${ngbDate.day > 9 ? ngbDate.day : '0' + ngbDate.day}/${
      ngbDate.month > 9 ? ngbDate.month : '0' + ngbDate.month
    }/${ngbDate.year}`;
    return uiDate;
  }

  static apiToUiDate = (dateTimeStringFromApi: string): string => {
    if (!dateTimeStringFromApi) {
      return '';
    }
    if (!DateHelperUtil.isApiDateTimeFormat(dateTimeStringFromApi)) {
      throw new Error('Wrong datetime format from API!');
    }

    const parts = new Date(dateTimeStringFromApi)
      .toLocaleDateString(locale)
      .split(/\.|\//);

    const day = parts[0];
    const month = parts[1];
    const year = parts[2];

    const uiDateString = `${day.length > 1 ? day : '0' + day}/${
      month.length > 1 ? month : '0' + month
    }/${year}`;

    return uiDateString;
  };

  static apiToUiTime = (dateTimeStringFromApi: string): string => {
    if (!DateHelperUtil.isApiDateTimeFormat(dateTimeStringFromApi)) {
      throw new Error('Wrong datetime format from API!');
    }
    const newString = new Date(dateTimeStringFromApi).toLocaleTimeString(locale);

    return newString.slice(0, 5);
  };

  static apiToUiDateTime = (dateTimeStringFromApi: string): string => {
    return `${DateHelperUtil.apiToUiTime(
      dateTimeStringFromApi
    )} ${DateHelperUtil.apiToUiDate(dateTimeStringFromApi)}`;
  };

  static isUiDateFormat = (maybeUiDateString: string): boolean => {
    const regExp = new RegExp(/[0-3][0-9]\/[0-1][0-9]\/[0-9]{4}/);

    return regExp.test(maybeUiDateString);
  };

  static isApiDateTimeFormat = (maybeApiDateTimeString: string): boolean => {
    if (maybeApiDateTimeString && maybeApiDateTimeString.length !== 27) {
      return false;
    }
    const regExp = new RegExp(
      /[0-9]{4}-[0-9]{2}-[0-9]{2}[A-Z]{1}[0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]{7}/
    );

    return regExp.test(maybeApiDateTimeString);
  };
}
