export class AppStorageService {
  set(key: string, data: any): void {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      console.error('Error saving to localStorage', e);
    }
  }

  get(key: string): any {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      console.error('Error getting data from localStorage', e);
      return null;
    }
  }

  set cookiesAccepted(cookiesAccepted: boolean) {
    localStorage.setItem('cookiesAccepted', JSON.stringify(cookiesAccepted));
  }

  get cookiesAccepted(): boolean {
    const valueFromLocalStorage = localStorage.getItem('cookiesAccepted');

    return !!this.parsedValue(valueFromLocalStorage);
  }

  parsedValue(valueFromLocalStorage: string | null): any {
    if (valueFromLocalStorage) {
      return JSON.parse(valueFromLocalStorage);
    }
  }
}
