<aol-overlay
  [autoPan]="true"
  [autoPanAnimation]="autoPanAnimationOptions"
  [positioning]="overlayPositioning"
  >
  <aol-coordinate
    [x]="getPoint()[0]"
    [y]="getPoint()[1]"
    [srid]="mapState.viewState.nativeProjectionCode"
  ></aol-coordinate>
  <aol-content>
    <div
      class="ol-popup"
      [hidden]="!mapState.toolsState[toolType].isPopupVisible"
      >
      <div class="container">
        <div class="row justify-content-end">
          <button
            type="button"
            class="ol-popup-closer-btn fa fa-times"
            (click)="closePopup()"
          ></button>
        </div>
        @switch (toolType) {
          @case (toolTypeEnum.Info) {
            <gk-info-content
              [sourceType]="sourceType"
              [mapState]="mapState"
              (dispatch)="dispatch.emit($event)"
            ></gk-info-content>
          }
        }
      </div>
    </div>
  </aol-content>
</aol-overlay>
