import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MapControl } from '../../../../../controls';
import {
  AttributesDynamicListResult,
  MapAction,
  MapObject,
  ToolActionType,
  ToolType,
} from '../../../../../models';
import { AttributesDynamicListComponent } from '../../../map-toolbar';
import { ModalUtils } from '../../../../../utils';

@Component({
  selector: 'gk-info-content',
  templateUrl: './info-content.component.html',
  styleUrls: ['./info-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoContentComponent extends MapControl {
  selectedMapObject: MapObject;
  override toolType = ToolType.Info;

  constructor(private modalService: NgbModal) {
    super();
  }

  openAttributesModal(mapObject: MapObject): void {
    this.selectedMapObject = mapObject;
    this.getModalRefWithTransferredProperties(
      AttributesDynamicListComponent
    ).result.then((result) => this.dispatchAttributesDynamicListResult(result));
  }

  showMapObjectWithZoom(mapObject: MapObject): void {
    this.dispatchExtentToFitTo([mapObject]);
    this.showMapObject(mapObject);
  }

  showMapObject(mapObject: MapObject): void {
    this.dispatch.emit(
      new MapAction(ToolActionType.MapObjectsToolChange, {
        value: [mapObject],
        options: { toolType: this.toolType, newSet: true },
      })
    );
  }

  getModalRefWithTransferredProperties(entryComponent: any): NgbModalRef {
    const modalRef = this.modalService.open(
      entryComponent,
      ModalUtils.getCustomXlModalOptions()
    );
    modalRef.componentInstance.mapState = this.mapState;
    modalRef.componentInstance.toolType = this.toolType;
    modalRef.componentInstance.mapObject = this.selectedMapObject;

    return modalRef;
  }

  dispatchAttributesDynamicListResult(
    result: AttributesDynamicListResult
  ): void {
    this.dispatch.emit(
      new MapAction(ToolActionType.MapObjectsWithAttributesChange, {
        value: result.requestedMapObjectWithAttributes,
        options: { toolType: this.toolType },
      })
    );
  }

  getMapObjectLabel(mapObject: MapObject): string {
    return 'label' in mapObject ? mapObject.label : undefined;
  }
}
