import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { RodoClauseService } from '../../services/rodo-clause/rodo-clause.service';

@Component({
  selector: 'gk-rodo-clause',
  templateUrl: './rodo-clause.component.html',
  styleUrls: ['./rodo-clause.component.scss'],
})
export class RodoClauseComponent implements OnInit, OnDestroy {
  @Input() departmentId: number;
  rodoClause: string;
  private isAlive = true;

  constructor(private rodoClauseService: RodoClauseService) {}

  ngOnInit(): void {
    this.fetchRodoClause();
  }

  fetchRodoClause(): void {
    this.rodoClauseService
      .getRodoClause(this.departmentId)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((rodoClause) => (this.rodoClause = rodoClause));
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
