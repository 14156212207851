export enum BuildRoadExitPermissionControlName {
  LawPerson = 'lawPerson',
  RequestType = 'requestType',
  RoadNumber = 'roadNumber',
  Place = 'roadExitLocationPlace',
  PurposeOfPropertyUse = 'purposeOfPropertyUse',
  PurposeOfPropertyUseChanged = 'purposeOfPropertyUseChanged',
  WillUseOfPropertyChange = 'willUseOfPropertyChange',
  ChangedPurposeOfPropertyUse = 'changedPurposeOfPropertyUse',
  DecisionForm = 'decisionForm',
  ShippingForm = 'shippingForm'
}
