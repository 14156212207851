export enum EcoDocumentSearchControlName {
  SerialNumber = 'serialNumber',
  DocumentName = 'documentName',
  CardIssueYear = 'cardIssueYear',
  DocumentCreator = 'documentCreator',
  DocumentApprover = 'documentApprover',
  CaseMark = 'caseMark',
  DocumentType = 'documentType',
  DocumentSubject = 'documentSubject',
  OfficeName = 'officeName',
  Description = 'description',
  DocumentDate = 'documentDate',
  ApprovalDate = 'approvalDate',
  PublicationDate = 'publicationDate',
  Voivodeship = 'voivodeship',
  County = 'county',
  Community = 'community'
}
