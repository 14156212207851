<aol-overlay [offset]="overlayOffset" [positioning]="overlayPositioning">
  <aol-coordinate
    [x]="movingPointerCoordinate[0]"
    [y]="movingPointerCoordinate[1]"
    [srid]="defaultProjectionCode"
  ></aol-coordinate>
  <aol-content>
    <div class="map-tooltip text-white">
      <p class="map-tooltip-message">
        {{
        mapState.toolsState[toolType][sourceType].tooltipMessage | translate
        }}
      </p>
      @if (mapState.toolsState[toolType][sourceType].isAlertVisible) {
        <p
          class="map-tooltip-message fw-bold"
          >
          {{ mapState.toolsState[toolType][sourceType].alert | translate }}
        </p>
      }
    </div>
    @if (mapState.toolsState[toolType][sourceType].isTooltipLoaderVisible) {
      <div
        class="d-flex justify-content-center my-3"
        >
        <div class="spinner-border" role="status">
          <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
        </div>
      </div>
    }
  </aol-content>
</aol-overlay>
