<ng-template #postOfficeModal let-submit="close">
  <div class="modal-header">
    @if (customPostOfficeModalHeaderInfo) {
      <h4
        class="modal-title"
        >
        {{ customPostOfficeModalHeaderInfo }}
      </h4>
    } @else {
      <h4 class="modal-title">{{ 'LAW_PERSON_SEARCH.INFORMATION' | translate }}</h4>
    }

  </div>
  <div class="modal-body">
    {{ 'LAW_PERSON_SEARCH.ENTER_POST_OFFICE' | translate }}
    <form [formGroup]="addressFormGroup">
      <div class="mt-3">
        <label [attr.for]="idPrefix + formControlNameEnum.PostOffice">{{
          'LAW_PERSON_SEARCH.POST_OFFICE' | translate
        }}</label>
        <input
          type="text"
          [name]="idPrefix + formControlNameEnum.PostOffice"
          [id]="idPrefix + formControlNameEnum.PostOffice"
          class="form-control form-control-sm required"
          aria-required="true"
          [formControlName]="formControlNameEnum.PostOffice"
          [autocomplete]="undefined"
          />
        @if (!isPostOfficeFormControlValid()) {
          <div
            class="alert alert-danger"
            tabindex="0"
            >
            {{ 'FIELD_REQUIRED' | translate }}
          </div>
        }
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="submit(false)">
      {{ 'LAW_PERSON_SEARCH.CANCEL' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-primary"
      [disabled]="!isPostOfficeFormControlValid()"
      (click)="submit(true)"
      >
      {{ 'LAW_PERSON_SEARCH.OK' | translate }}
    </button>
  </div>
</ng-template>
