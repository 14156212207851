<div class="d-flex flex-column justify-content-between h-100">
  <kendo-upload
    #kendoUploadComponent
    [saveUrl]="uploadSaveUrl"
    [removeUrl]="uploadRemoveUrl"
    [autoUpload]="false"
    (upload)="uploadEventHandler($any($event))"
    (success)="success($any($event))"
    (error)="error($event)"
    (select)="onSelect($event)"
    >
    <ng-template kendoUploadFileInfoTemplate let-files let-state="state">
      <div class="k-file-name" [title]="files[0].name">{{ files[0].name }}</div>
      <span
        [ngClass]="{
          'k-file-validation-message': files[0].validationErrors,
          'k-file-size': !files[0].validationErrors && isNotYetUploaded,
          'k-text-success': isUploadSuccessful(state),
          'k-text-error': files[0].validationErrors || isUploadFailed(state),
          'k-file-information':
            isUploadSuccessful(state) || isUploadFailed(state)
        }"
        >{{ fileStatusText(files[0], state) }}</span
        >
        <div>
          <kendo-label
            [for]="typeOfDoc"
            text="Rodzaj dokumentu (szczegółowy):"
          ></kendo-label>
          <kendo-dropdownlist
            #typeOfDoc
            [data]="typeOfDocs"
            textField="name"
            valueField="id"
            [size]="'small'"
            class="required"
            [ngModel]="matchFileToTypeOfDoc(files[0])"
            (ngModelChange)="files[0].typeOfDoc = $event"
          ></kendo-dropdownlist>
          <kendo-label [for]="fileDescription" text="Opis"></kendo-label>
          <kendo-textbox
            #fileDescription
            [ngModel]="files[0].description || ''"
            (ngModelChange)="files[0].description = $event"
          ></kendo-textbox>
        </div>
      </ng-template>
      <ng-template kendoUploadFileInfoTemplate let-files>
        <div>Name: {{ files[0].name }}</div>
      </ng-template>
    </kendo-upload>
    @if (kendoUploadComponent.showActionButtons) {
      <gk-upload-action-buttons-component
        [uploadComponent]="kendoUploadComponent"
      ></gk-upload-action-buttons-component>
    }
  </div>
