import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MapObject } from '@gk/gk-modules';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { handleApiError } from '../services.utils';
import { ApiClosedArea, ClosedArea } from './closed-areas.model';

@Injectable()
export class ClosedAreasService {
  waitingForResponse = new BehaviorSubject(false);
  closedAreasResponse = new BehaviorSubject<ClosedArea[]>([]);
  closedAreasRequest = new Subject<MapObject[]>();

  constructor(private httpClient: HttpClient) {
    this.listenForCrossingClosedAreasRequest();
  }

  private listenForCrossingClosedAreasRequest(): void {
    this.closedAreasRequest
      .pipe(
        tap(() => this.waitingForResponse.next(true)),
        debounceTime(300),
        switchMap((mapObjects) => this.getCrossingClosedAreas(mapObjects))
      )
      .subscribe((closedAreas) => {
        this.closedAreasResponse.next(closedAreas);
        this.waitingForResponse.next(false);
      });
  }

  private getCrossingClosedAreas(
    mapObjects: MapObject[]
  ): Observable<ClosedArea[]> {
    const areaWkts = mapObjects.map((o) => o.geom);

    return this.httpClient
      .post<ApiClosedArea[]>('/api/zudp/projektant/wniosek/zakresy/check', {
        Zakresy: areaWkts,
      })
      .pipe(
        map((apiClosedAreas) =>
          apiClosedAreas.map((apiClosedArea) =>
            ClosedArea.fromApiToApp(apiClosedArea)
          )
        ),
        catchError(handleApiError)
      );
  }
}
