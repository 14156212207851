<div class="container py-3">
  @if (freeServicesMapState.mapObjectTablesState[0].mapObjects.length) {
    <div
      class="row mb-3"
      >
      <div class="col-12">
        <gk-map-object-table
          [mapState]="freeServicesMapState"
          [mapObjectTableStateIndex]="0"
          (dispatch)="handleMapAction($event)"
        ></gk-map-object-table>
      </div>
    </div>
  }
  <div class="row">
    <div class="col-12">
      <gk-map
        [mapState]="freeServicesMapState"
        (dispatch)="handleMapAction($event)"
      ></gk-map>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12">
      @if (availableParcelsListConfigs) {
        <gk-dynamic-list
          [listConfigs]="availableParcelsListConfigs"
          [mapState]="freeServicesMapState"
          (dispatchMapAction)="handleMapAction($event)"
        ></gk-dynamic-list>
      }
    </div>
  </div>
</div>
