<section class="container py-4">
  <h1>
    <div class="alert alert-success py-2">
      {{ 'NEW_REQUEST.REQUEST_SENT_POSITIVE' | translate }}
    </div>
  </h1>
  <div class="py-2 info">
    <span class="py-2">
      {{ 'NEW_REQUEST.REQUEST_SIGNATURE' | translate }}:
      {{ recentlySignedOrder.orderNumber }}
    </span>
    <button
      type="button"
      class="btn btn-color-from-db mt-2 py-2"
      (click)="getIncomingDocument()"
    >
      {{ 'NEW_REQUEST.DOWNLOAD_REQUEST' | translate }}
    </button>
  </div>
</section>
