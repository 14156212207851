import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PlaceAndStreetControlName } from './place-and-street-form.model';

@Injectable()
export class PlaceAndStreetFormService {
  constructor(private formBuilder: UntypedFormBuilder) {}

  getPlaceAndStreetFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [PlaceAndStreetControlName.MapObjectsArray]: this.formBuilder.array([]),
    });
  }

  getMapObjectFormGroup(uuid: string | number): UntypedFormGroup {
    return this.formBuilder.group({
      [PlaceAndStreetControlName.Uuid]: this.formBuilder.control(uuid),
      [PlaceAndStreetControlName.Place]: this.formBuilder.group({
        [PlaceAndStreetControlName.SelectedPlace]: this.formBuilder.control(null),
        [PlaceAndStreetControlName.StreetsOptions]: this.formBuilder.control([]),
      }),
      [PlaceAndStreetControlName.Street]: this.formBuilder.control(null),
    });
  }
}
