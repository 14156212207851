import { Injectable } from '@angular/core';
import { DocType, SqlPattern } from '../doc-type/doc-type.model';
import { DocTypeService } from '../doc-type/doc-type.service';

@Injectable()
export class FileTypeMatcherService {
  constructor(public docTypeService: DocTypeService) {}

  private sqlPatternToJsRegExp(sqlPattern: SqlPattern): RegExp | never {
    if (sqlPattern === null) {
      throw new Error('There is no sqlPattern!');
    }

    let pattern = sqlPattern;
    pattern = pattern[0] !== '%' ? `^${pattern}` : pattern;
    pattern = pattern[pattern.length - 1] !== '%' ? `${pattern}$` : pattern;
    pattern = pattern.replace(/%/g, '.*');

    return new RegExp(pattern, 'gi');
  }

  private doesFileNameMatchPattern(
    name: string,
    sqlPattern: SqlPattern,
  ): boolean {
    return sqlPattern !== null
      ? this.sqlPatternToJsRegExp(sqlPattern).test(name)
      : false;
  }

  getDocType(fileName: string): DocType | undefined {
    return this.docTypeService.docTypes
      .getValue()
      .find((docType) =>
        this.doesFileNameMatchPattern(fileName, docType.sqlPattern),
      );
  }
}
