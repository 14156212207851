import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { GkComponentsModule, GkMapModule } from '@gk/gk-modules';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { createTranslateLoader } from '../address-number-request-portal/address-number-request-portal.module';
import { BuildingPermitTransferPortalRoutingModule } from './building-permit-transfer-portal-routing.module';
import { BuildingPermitTransferPortalComponent } from './building-permit-transfer-portal.component';
import { NewRequestComponent } from './new-request/new-request.component';

@NgModule({
  imports: [
    SharedModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    GkMapModule,
    GkComponentsModule,
    BuildingPermitTransferPortalRoutingModule,
  ],
  declarations: [BuildingPermitTransferPortalComponent, NewRequestComponent],
})
export class BuildingPermitTransferPortalModule {}
