<div class="d-flex flex-column">
  <div class="d-flex attachment align-items-end">
    <gk-file-input
      class="bg-color-from-db-for-button-child"
      [ngClass]="{
        'is-required': isRequired,
        'was-validated': wasValidated,
        'is-not-chosen': !files?.[0]
      }"
      [showPreviews]="true"
      [multiple]="false"
      [files]="files"
      (filesChange)="handleFileInputChangeAction($event)"
    >
    </gk-file-input>

    <button
      type="button"
      class="mx-1 btn btn-sm btn-danger fa fa-times remove-file"
      [attr.aria-label]="'ATTACHMENT_INPUT.RESET_FILE' | translate"
      (click)="resetFile()"
    ></button>
  </div>

  <span
    class="text-muted file-annotation"
    [hidden]="!getCurrentFileSizeString()"
  >
    {{ getCurrentFileSizeString() }}
  </span>

  <span class="text-danger file-annotation" [hidden]="!errorText">
    {{ errorText }}
  </span>
</div>
