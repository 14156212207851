<div class="table-wrapper-vh-70">
  <gk-table
    [records]="invoices"
    [settings]="columnSetting"
    (select)="onInvoicesSelect($event)"
    [loading]="isInvoiceLoading"
    [selectedRows]="selectedInvoices"
    >
  </gk-table>
</div>
<div class="d-flex flex-column pt-4">
  <div class="d-flex flex-row">
    <div>
      <button
        (click)="onRefreshInvoices()"
        [disabled]="isInvoiceLoading"
        class="btn btn-sm btn-success me-2"
        >
        <i class="fa fa-refresh"></i>
      </button>
      <button
        (click)="downloadSelectedUnpaidInvoices()"
        [disabled]="isInvoiceLoading && !selectedInvoices.length"
        class="btn btn-sm btn-success me-2"
        >
        <i class="fa fa-download"></i>
      </button>
    </div>
    <div>
      <gk-payment-button
        [invoices]="selectedInvoices"
        [pathToAmount]="'feeDue'"
      ></gk-payment-button>
    </div>
  </div>
  <div class="row">
    @if (selectedInvoicesAmount) {
      <span
        >{{ 'GK.PAYMENTS.PAYMENTS_SUM' | translate }}
        {{ selectedInvoicesAmount | currency : 'PLN' }}</span
        >
      }
    </div>
  </div>
