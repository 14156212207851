import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Coordinate } from 'ol/coordinate';
import { getCenter } from 'ol/extent';
import { mapSheetScales, scales } from '../../../../../configs';
import { MapControl } from '../../../../../controls';
import {
  MapAction,
  MapSheetFormState,
  MapSheetFormValue,
  Paper,
  PaperOptionProperty,
  Scale,
  SourceActionType,
  SourceType,
} from '../../../../../models';

@Component({
  selector: 'gk-map-sheet-form',
  templateUrl: './map-sheet-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapSheetFormComponent extends MapControl implements OnInit {
  formValue: MapSheetFormValue;
  allAvailableScales: Scale[] = [...mapSheetScales];
  mapScales: Scale[] = [...scales];
  paperOptionPropertyEnum = PaperOptionProperty;
  scaleRestriction = false;
  paperRestriction = false;
  override sourceType = SourceType.MapSheetForm;

  ngOnInit(): void {
    this.updateMapScalesBasedOnPassedScalesDenominators();
    this.loadFormValue();
  }

  loadFormValue(): void {
    const formValueFromState = (
      this.mapState.toolsState[this.toolType][
        this.sourceType
      ] as MapSheetFormState
    ).formValue;

    this.formValue = formValueFromState.center
      ? { ...formValueFromState, center: this.getCenter() }
      : this.getNewFormValue();

    this.dispatchFormValueChange();
    this.dispatchMapSheetMapObjects(this.toolType, this.formValue);
  }

  updateMapScalesBasedOnPassedScalesDenominators(): void {
    const scalesDenominators = (
      this.mapState.toolsState[this.toolType][
        this.sourceType
      ] as MapSheetFormState
    ).availableMapScalesDenominators;
    if (!scalesDenominators || !scalesDenominators.length) {
      return;
    }

    this.allAvailableScales = this.mapScales.filter((mapScale) =>
      scalesDenominators.includes(mapScale.denominator),
    );
  }

  getNewFormValue(): MapSheetFormValue {
    return {
      scale: this.getScale(),
      paper: this.getPaper(),
      center: this.getCenter(),
    };
  }

  getScale(): Scale {
    const scaleFromMapSheetRestriction = this.getScaleFromMapSheetRestriction();
    this.scaleRestriction = !!scaleFromMapSheetRestriction;

    return this.scaleRestriction
      ? scaleFromMapSheetRestriction
      : this.getScaleBasedOnMapResolution();
  }

  getScaleFromMapSheetRestriction(): Scale | undefined {
    const restriction = this.getCleanMapSheetRestriction();

    return this.allAvailableScales.find(
      (scale) =>
        restriction && restriction.includes(scale.name.replace(/\s/g, '')),
    );
  }

  getScaleBasedOnMapResolution(): Scale {
    const mapScale = this.mapScales.find(
      (scale) => scale.resolution === this.mapState.viewState.resolution,
    );
    const scaleIndex = this.allAvailableScales.findIndex((scale) => {
      return mapScale?.resolution === scale.resolution;
    });

    if (scaleIndex === -1) {
      return mapScale?.resolution < this.allAvailableScales[0].resolution
        ? this.allAvailableScales[0]
        : this.allAvailableScales[this.allAvailableScales.length - 1];
    }

    return mapScale.resolution === this.allAvailableScales[0].resolution
      ? this.allAvailableScales[0]
      : this.allAvailableScales[scaleIndex - 1];
  }

  getCleanMapSheetRestriction(): string {
    const mapSheetRestriction = this.mapState.toolbarState.mapSheetRestriction;

    return mapSheetRestriction ? mapSheetRestriction.replace(/\s/g, '') : '';
  }

  getPaper(): Paper {
    const mapSheetPaperFromMapSheetRestriction =
      this.getPaperFromMapSheetRestriction();
    this.paperRestriction = !!mapSheetPaperFromMapSheetRestriction;

    return this.paperRestriction
      ? mapSheetPaperFromMapSheetRestriction
      : this.mapState.toolbarState.papers[0];
  }

  getPaperFromMapSheetRestriction(): Paper | undefined {
    const restriction = this.getCleanMapSheetRestriction();

    return this.mapState.toolbarState.papers.find(
      (paper) => restriction && restriction.includes(paper.name),
    );
  }

  getCenter(): Coordinate {
    return getCenter(this.mapState.viewState.currentNativeExtent);
  }

  dispatchFormValueChange(): void {
    this.dispatch.emit(
      new MapAction(SourceActionType.FormValueChange, {
        value: this.formValue,
        options: { toolType: this.toolType, sourceType: this.sourceType },
      }),
    );
  }

  handleMapSheetPaperOrientationChange(orientation: string): void {
    this.formValue = {
      ...this.formValue,
      paper: this.mapState.toolbarState.papers.find(
        (paper) =>
          paper.orientation === orientation &&
          paper.name === this.formValue.paper.name,
      ),
    };
    this.dispatchFormValueChange();
    this.dispatchMapSheetMapObjects(this.toolType, this.formValue);
  }

  handleMapSheetPaperNameChange(name: string): void {
    this.formValue = {
      ...this.formValue,
      paper: this.mapState.toolbarState.papers.find(
        (paper) =>
          paper.name === name &&
          paper.orientation === this.formValue.paper.orientation,
      ),
    };
    this.dispatchFormValueChange();
    this.dispatchMapSheetMapObjects(this.toolType, this.formValue);
  }

  handleMapSheetResolutionChange(resolution: number): void {
    this.formValue = {
      ...this.formValue,
      scale: this.allAvailableScales.find(
        (scale) => scale.resolution === resolution,
      ),
    };
    this.dispatchFormValueChange();
    this.dispatchMapSheetMapObjects(this.toolType, this.formValue);
  }
}
