import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { GkComponentsModule } from '../gk-components/gk-components.module';
import { GkUserSettingsComponent } from './gk-user-settings.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    TranslateModule,
    RouterModule.forChild([]),
    GkComponentsModule,
  ],
  declarations: [GkUserSettingsComponent],
  exports: [GkUserSettingsComponent],
})
export class GkUserSettingsModule {}
