import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ContextMenuService } from '@perfectmemory/ngx-contextmenu';
import { GkMapModule } from '../gk-map/gk-map.module';
import { MapSettingsService } from '../services/map-settings/map-settings.service';
import { GMPPrintAttributesFormComponent } from './components/print-attributes-form/print-attributes-form.component';
import { GMPPrintPreviewComponent } from './components/print-preview/print-preview.component';
import { GkMapPrintComponent } from './gk-map-print.component';
import { GMPPrintAttributesFormService } from './services/print-attributes-form/print-attributes-form.service';
import { GMPPrintFooterService } from './services/print-footer/print-footer.service';
import { GMPPrintQualityService } from './services/print-quality/print-quality.service';
import { GMPPrintTemplateService } from './services/print-template/print-template.service';
import { GMPPrintService } from './services/print/print.service';

const components = [
  GkMapPrintComponent,
  GMPPrintAttributesFormComponent,
  GMPPrintPreviewComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    TranslateModule,
    MatCheckboxModule,
    GkMapModule,
  ],
  declarations: [...components],
  exports: [...components],
  providers: [
    ContextMenuService,
    MapSettingsService,
    GMPPrintService,
    GMPPrintAttributesFormService,
    GMPPrintFooterService,
    GMPPrintQualityService,
    GMPPrintTemplateService,
  ],
})
export class GkMapPrintModule {}
