import { EventEmitter, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { from, Observable, of, shareReplay } from 'rxjs';
import {
  FileUuids,
  Invoice,
  Payment,
  PaymentLimitEndpoint,
  PaymentParams,
  PaymentResponse,
  PendingInvoice,
  PendingInvoiceFromApi,
  UnpaidInvoice,
  UnpaidInvoiceFromApi,
} from './payments.model';
import { filter, map, switchMap } from 'rxjs/operators';
import { BasicResponse } from '../utils/basic-response/basic-response.model';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { WindowRefService } from '../gk-dynamic-list/services/window-ref.service';

@Injectable()
export class PaymentsService {
  private amountLimit: Observable<number>;
  private amountLimitMessage: Observable<string>;
  isPaymentInProgress: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    private httpClient: HttpClient,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private windowRefService: WindowRefService,
  ) {}

  pay(paymentParams: PaymentParams): void {
    this.isPaymentInProgress.emit(true);
    this.isPaymentAmountOverLimit(
      paymentParams.invoices,
      paymentParams.pathToAmount,
    )
      .pipe(
        filter((isPaymentAmountOverLimit) => !isPaymentAmountOverLimit),
        switchMap(() =>
          this.payOrder(
            this.getInvoicesIds(
              paymentParams.invoices,
              paymentParams.invoiceIdFieldName,
            ),
            this.getReturnUrl(),
            paymentParams.multiplePaymentProviders,
            paymentParams.parent,
            paymentParams.openSelectedProviderWindow,
          ),
        ),
      )
      .subscribe({
        next: (res) => this.handlePayOrderSuccessResponse(res),
        error: (err) => this.handlePayOrderFailResponse(err),
      });
  }

  getUnpaidInvoices(): Observable<UnpaidInvoice[]> {
    return this.httpClient
      .get<UnpaidInvoiceFromApi[]>('/api/system/payment/unpaid/invoices')
      .pipe(
        map((res) =>
          res.map((unpaidInvoiceFromApi) =>
            UnpaidInvoice.fromApiToApp(unpaidInvoiceFromApi),
          ),
        ),
      );
  }

  getPendingInvoices(): Observable<PendingInvoice[]> {
    return this.httpClient
      .get<
        PendingInvoiceFromApi[]
      >('/api/system/payment/unpaid/invoices/pending')
      .pipe(
        map((res) =>
          res.map((pendingInvoiceFromApi) =>
            PendingInvoice.fromApiToApp(pendingInvoiceFromApi),
          ),
        ),
      );
  }

  downloadInvoices(fileIds: FileUuids): Observable<HttpResponse<Blob>> {
    return this.httpClient.post('/api/system/edok/download', fileIds, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  cancelPayment(transId: number): Observable<number> {
    return this.httpClient.post<number>('/api/payments/brokerpayments/cancel', {
      TransId: transId,
    });
  }

  payOrder(
    invoicesId: string[],
    returnUrl: string,
    multiplePaymentProviders = false,
    parent: any,
    openSelectedProviderWindow: (providers: string[]) => Promise<string>,
  ): Observable<PaymentResponse> {
    return this.httpClient
      .get<string[]>('/api/payments/available/methods')
      .pipe(
        switchMap((paymentProviders) => {
          if (multiplePaymentProviders) {
            return from(
              openSelectedProviderWindow.call(parent, paymentProviders),
            );
          } else {
            return of(paymentProviders[0]);
          }
        }),
        switchMap((paymentProvider) => {
          if (!paymentProvider) {
            return of(undefined);
          }
          const url = multiplePaymentProviders
            ? '/api/payments/webewidpayments/begin'
            : '/api/payments/brokerpayments/begin';

          return this.httpClient.post<PaymentResponse>(url, {
            FaktUuids: invoicesId,
            ReturnUrl: returnUrl,
            PaymentProvider: paymentProvider,
          });
        }),
      );
  }

  getAmountLimit(): Observable<number> {
    if (!this.amountLimit) {
      this.amountLimit = this.httpClient
        .get<BasicResponse<number>>(PaymentLimitEndpoint.Amount)
        .pipe(
          map((response) => response.Result),
          shareReplay(1),
        );
    }

    return this.amountLimit;
  }

  getAmountLimitMessage(): Observable<string> {
    if (!this.amountLimitMessage) {
      this.amountLimitMessage = this.httpClient
        .get<BasicResponse>(PaymentLimitEndpoint.Message)
        .pipe(
          map((response) => response.Result),
          shareReplay(1),
        );
    }

    return this.amountLimitMessage;
  }

  getInvoicesIds(
    invoices: Invoice[] | Payment[],
    invoiceIdFieldName: string,
  ): string[] {
    return invoices.map((item) => item[invoiceIdFieldName]);
  }

  getReturnUrl(): string {
    return `${this.windowRefService.nativeWindow.location.href.split('?')[0]}`;
  }

  handlePayOrderSuccessResponse(res: { RedirectUrl: string }): void {
    if (!res) {
      this.isPaymentInProgress.emit(false);

      return;
    }
    this.windowRefService.nativeWindow.location.href = res.RedirectUrl;
  }

  handlePayOrderFailResponse(err: HttpErrorResponse): void {
    this.toastr.error(
      _.get(err, 'error.ResponseStatus.Message', 'ERROR_OCCURRED'),
    );
    this.isPaymentInProgress.emit(false);
  }

  maybeShowAmountLimitMessage(
    limitAmount: number,
    invoices: Payment[] | Invoice[],
    pathToAmount: string,
  ): Observable<boolean> {
    const amounts = invoices.map((invoice) =>
      parseFloat(invoice[pathToAmount]),
    );
    const totalAmount = amounts.reduce((sum, amount) => sum + amount);
    if (totalAmount <= limitAmount || !limitAmount) {
      return of(false);
    }
    return this.getAmountLimitMessage().pipe(
      map((message) => {
        if (message) {
          this.setAmountLimitMessageAndStopPaymentProgress(message);
        } else {
          this.setDefaultAmountLimitMessage(limitAmount);
        }

        return true;
      }),
    );
  }

  setDefaultAmountLimitMessage(limitAmount: number): void {
    this.translateService
      .get('GK.PAYMENTS.PAYMENT_OVER_LIMIT')
      .subscribe((value) =>
        this.setAmountLimitMessageAndStopPaymentProgress(
          value.replace('${limit}', limitAmount.toFixed(2)),
        ),
      );
  }

  setAmountLimitMessageAndStopPaymentProgress(message: string): void {
    this.toastr.warning(message);
    this.isPaymentInProgress.emit(false);
  }

  isPaymentAmountOverLimit(
    invoices: Payment[] | Invoice[],
    pathToAmount: string,
  ): Observable<boolean> {
    return this.getAmountLimit().pipe(
      switchMap((limitAmount) =>
        this.maybeShowAmountLimitMessage(limitAmount, invoices, pathToAmount),
      ),
    );
  }
}
