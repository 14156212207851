import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SessionGuard } from '../../guards/session/session.guard';
import { MainRequestsListComponent } from '../../shared/main-requests-list/main-requests-list.component';
import { GrantingRightsPortalComponent } from './granting-rights-portal.component';
import { NewGrantComponent } from './new-grant/new-grant.component';

const grantingRightsPortalRoutes: Routes = [
  {
    path: 'granting-rights-portal',
    component: GrantingRightsPortalComponent,
    canActivate: [SessionGuard],
    children: [
      {
        path: '',
        redirectTo: 'new-grant',
        pathMatch: 'full',
      },
      {
        path: 'new-grant',
        component: NewGrantComponent,
      },
      {
        path: 'requests-list',
        component: MainRequestsListComponent,
      },
      {
        path: '**',
        redirectTo: 'new-grant',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(grantingRightsPortalRoutes)],
  providers: [],
  exports: [RouterModule],
})
export class GrantingRightsPortalRoutingModule {}
