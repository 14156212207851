import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay, finalize, map, shareReplay, tap } from 'rxjs/operators';
import {
  LoggedUserData,
  LoggedUserDataResponseDto,
  Login,
  LoginResponse,
  LoginResponseFromApi,
} from './login.model';

@Injectable()
export class LoginService {
  private loggedUserData: Observable<LoggedUserData>;
  private navigationToExternalLoginInProcess = new BehaviorSubject(false);
  navigationToExternalLoginInProcess$ =
    this.navigationToExternalLoginInProcess.asObservable();
  constructor(
    private httpClient: HttpClient,
    private translateService: TranslateService,
    private toastr: ToastrService,
    @Inject('window') public window: Window
  ) {}

  login(
    data: Login,
    loginUrl = '/api/system/login'
  ): Observable<LoginResponse> {
    return this.httpClient
      .post<LoginResponseFromApi>(loginUrl, Login.fromAppToApi(data))
      .pipe(map((data) => LoginResponse.fromApiToApp(data)));
  }

  redirectToExternalLoginPageWithToastr(externalLoginUrl: string): void {
    this.translateService
      .get('SESSION_TOOLS.REDIRECT_TO_EXTERNAL_LOGIN')
      .pipe(
        tap((message) => {
          this.toastr.success(message);
        }),
        delay(3000)
      )
      .subscribe(() => {
        this.navigateToExternalLogin(externalLoginUrl);
      });
  }

  navigateToExternalLogin(externalLoginUrl: string): void {
    this.navigationToExternalLoginInProcess.next(true);
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'text/html');
    this.httpClient
      .get(externalLoginUrl, {
        responseType: 'text',
        headers,
      })
      .pipe(finalize(() => this.navigationToExternalLoginInProcess.next(false)))
      .subscribe((response) => {
        if (response) {
          const windowInstance = this.window.open('', '_self');
          windowInstance.document.write(response);
        }
      });
  }

  getSystemLoggedUserData(): Observable<LoggedUserData> {
    if (!this.loggedUserData) {
      this.loggedUserData = this.httpClient
        .get<LoggedUserDataResponseDto>('/api/system/logged/user')
        .pipe(
          map((loggedUserFromApi) =>
            LoggedUserData.fromApiToApp(loggedUserFromApi)
          ),
          shareReplay(1)
        );
    }
    return this.loggedUserData;
  }
}
