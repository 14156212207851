import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GkComponentsModule, GkMapModule } from '@gk/gk-modules';
import { SharedModule } from '../../shared/shared.module';
import { EnvironmentalProtectionPortalRoutingModule } from './environmental-protection-portal-routing.module';
import { EnvironmentalProtectionPortalComponent } from './environmental-protection-portal.component';
import { FishingCardRequestComponent } from './fishing-card-request/fishing-card-request.component';
import { SimplifiedForestManagementPlanRequestComponent } from './simplified-forest-management-plan-request/simplified-forest-management-plan-request.component';
import { TreesAndShrubsFellingRequestComponent } from './trees-and-shrubs-felling-request/trees-and-shrubs-felling-request.component';
import { WaterEquipmentRequestComponent } from './water-equipmernt-request/water-equipment-request.component';

@NgModule({
  imports: [
    CommonModule,
    EnvironmentalProtectionPortalRoutingModule,
    SharedModule,
    GkMapModule,
    GkComponentsModule,
  ],
  declarations: [
    FishingCardRequestComponent,
    EnvironmentalProtectionPortalComponent,
    SimplifiedForestManagementPlanRequestComponent,
    WaterEquipmentRequestComponent,
    TreesAndShrubsFellingRequestComponent,
  ],
})
export class EnvironmentalProtectionPortalModule {}
