<form [formGroup]="parcelSearchForm" class="row py-2">
  <div class="w-100">
    <div class="col-12">
      <div class="row">
        <div class="col-sm-6 col-xs-12 my-1">
          <label for="communityId">
            {{ 'ADVANCED_PARCEL_SEARCH_FORM.CADASTRAL_UNIT' | translate }}
          </label>

          <select
            name="communityId"
            id="communityId"
            formControlName="communityId"
            class="form-select form-select-sm"
            (change)="handleCommunityChange()"
          >
            @for (community of communitySelectOptions; track community.id) {
              <option [ngValue]="community.id">
                {{ community.name }}
              </option>
            }
          </select>
        </div>

        <div class="col-sm-6 col-xs-12 my-1">
          <label for="districtId">
            {{ 'ADVANCED_PARCEL_SEARCH_FORM.DISTRICT' | translate }}
          </label>

          <select
            name="districtId"
            id="districtId"
            formControlName="districtId"
            class="form-select form-select-sm"
          >
            @for (district of districtSelectOptions; track district.id) {
              <option [ngValue]="district.id">
                {{ district.name }}
              </option>
            }
          </select>
        </div>
      </div>
    </div>

    <div class="col-12" [formGroupName]="'parcelNumber'">
      <div class="row">
        <h5 class="col-12">
          {{ 'ADVANCED_PARCEL_SEARCH_FORM.PARCEL_NUMBER' | translate }}
        </h5>

        <div class="col">
          <label for="nominator">
            {{ 'ADVANCED_PARCEL_SEARCH_FORM.NOMINATOR' | translate }}
          </label>

          <input
            type="number"
            id="nominator"
            class="form-control form-control-sm required"
            formControlName="nominator"
            aria-required="true"
          />
        </div>

        <div class="col-auto d-flex px-0">
          <span class="align-self-end h3 mb-0">/</span>
        </div>

        <div class="col">
          <label for="denominator">
            {{ 'ADVANCED_PARCEL_SEARCH_FORM.DENOMINATOR' | translate }}
          </label>

          <input
            type="number"
            id="denominator"
            class="form-control form-control-sm"
            formControlName="denominator"
          />
        </div>
      </div>
    </div>

    <div class="col-12" [formGroupName]="'parcelAddress'">
      <div class="row">
        <h5 class="col-12">{{ 'ADDRESS_SEARCH_FORM.ADDRESS' | translate }}</h5>

        <div class="col-md-4 col-sm-6 col-xs-12 my-1">
          <label for="place">{{
            'ADDRESS_SEARCH_FORM.PLACE' | translate
          }}</label>

          <input
            type="text"
            name="place"
            id="place"
            class="form-control form-control-sm"
            [ngbTypeahead]="searchPlace"
            [resultFormatter]="formatter"
            [inputFormatter]="formatter"
            formControlName="place"
          />
        </div>

        <div class="col-md-4 col-sm-6 col-xs-12 my-1">
          <label for="street">{{
            'ADDRESS_SEARCH_FORM.STREET' | translate
          }}</label>

          <input
            type="text"
            name="street"
            id="street"
            class="form-control form-control-sm"
            [ngbTypeahead]="searchStreet"
            [resultFormatter]="formatter"
            [inputFormatter]="formatter"
            formControlName="street"
          />
        </div>

        <div class="col-md-4 col-sm-6 col-xs-12 my-1">
          <label for="ordinalNumber">
            {{ 'ADDRESS_SEARCH_FORM.ORDINAL_NUMBER' | translate }}
          </label>

          <input
            type="text"
            id="ordinalNumber"
            class="form-control form-control-sm"
            formControlName="ordinalNumber"
          />
        </div>

        <div class="col-md-4 col-sm-6 col-xs-12 my-1">
          <label for="localNumber">
            {{ 'ADDRESS_SEARCH_FORM.LOCAL_NUMBER' | translate }}
          </label>

          <input
            type="text"
            id="localNumber"
            class="form-control form-control-sm"
            formControlName="localNumber"
          />
        </div>

        <div class="col-md-4 col-sm-6 col-xs-12 my-1">
          <label for="postalCode">
            {{ 'ADDRESS_SEARCH_FORM.POSTAL_CODE' | translate }}
          </label>

          <input
            type="text"
            id="postalCode"
            class="form-control form-control-sm"
            [ngbTypeahead]="searchPostalCode"
            [resultFormatter]="formatter"
            [inputFormatter]="formatter"
            formControlName="postalCode"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="col-12">
    <h5>{{ 'ADVANCED_PARCEL_SEARCH_FORM.MATCHED_PARCEL_LIST' | translate }}</h5>

    @if (loading) {
      <div class="d-flex justify-content-center my-3">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
        </div>
      </div>
    }

    <app-parcel-list
      [parcelListMode]="parcelListMode"
      [parcels]="matchingParcels"
      [hidden]="loading || !matchingParcels.length"
      (chosenParcels)="handleChosenParcels($event)"
    ></app-parcel-list>

    <span [hidden]="loading || matchingParcels.length">
      {{ 'NEW_DESIGNER_REQUEST_FORM.NO_MATCHED_RESULTS' | translate }}
    </span>
  </div>
</form>
