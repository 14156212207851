<form
  class="k-form k-form-sm"
  [formGroup]="form"
  (ngSubmit)="onFormSubmit(formModel)"
>
  <formly-form
    [form]="form"
    [fields]="formlyFormConfig.fields"
    [model]="formModel"
  ></formly-form>
  @if (!!landParcelSearchFormActionButtonsConfig) {
    <gk-kendo-action-buttons
      [buttonsConfigs]="landParcelSearchFormActionButtonsConfig"
    ></gk-kendo-action-buttons>
  }
</form>
<gk-loading-panel [isLoading]="isLoading | async"></gk-loading-panel>
