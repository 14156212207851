import { FilterOperatorType } from '../../../../gk-dynamic-list/gk-dynamic-list.model';
import { ApiFilter } from '../../../../gk-dynamic-list/services/services.model';
import { isNotEmptyObject } from '../../../../utils/utils';
import {
  PprfBuildingFormModel,
  pprfBuildingFormModel,
} from '../../pprf-building-form/pprf-building-form.model';
import {
  PprfParcelFormModel,
  pprfParcelFormModel,
} from '../../pprf-parcel-form/pprf-parcel-form.model';
import {
  PprfPremisesFormModel,
  pprfPremisesFormModel,
} from '../../pprf-premises-form/pprf-premises-form.model';
import {
  PprfPropertyFormModel,
  pprfPropertyFormModel,
} from '../../pprf-property-form/pprf-property-form.model';
import {
  PprfTransactionAndPricingFormModel,
  pprfTransactionAndPricingFormModel,
} from '../../pprf-transaction-and-pricing-form/pprf-transaction-and-pricing-form.model';

export class RtParcelFiltersDto {
  constructor(
    public SloPrzeznMpzp: number[],
    public SposUzytId: number[],
    public PowDlkOd: number,
    public PowDlkDo: number,
  ) {}

  static fromAppToApi(
    data: PprfParcelFormModel,
  ): RtParcelFiltersDto | undefined {
    return new this(
      data.developmentPlanDesignation,
      data.useMethod,
      data.areaFrom,
      data.areaTo,
    );
  }
}

export class RtBuildingFiltersDto {
  constructor(
    public FunBudId: number[],
    public RokBudowyOd: number,
    public RokBudowyDo: number,
    public KondNadOd: number,
    public KondNadDo: number,
    public KondPodOd: number,
    public KondPodDo: number,
    public PowUzOd: number,
    public PowUzDo: number,
  ) {}

  static fromAppToApi(
    data: PprfBuildingFormModel,
  ): RtBuildingFiltersDto | undefined {
    return new this(
      data.buildingType,
      data.constructionYearFrom,
      data.constructionYearTo,
      data.floorsAboveFrom,
      data.floorsAboveTo,
      data.floorsBelowFrom,
      data.floorsBelowTo,
      data.usableAreaFrom,
      data.usableAreaTo,
    );
  }
}

export class RtPremisesFiltersDto {
  constructor(
    public IdFunLok: number[],
    public IdSloKondygn: number[],
    public IlIzbOd: number,
    public IlIzbDo: number,
    public PowUzLokOd: number,
    public PowUzLokDo: number,
    public PowPomieszczenPrzynaleznychLokOd: number,
    public PowPomieszczenPrzynaleznychLokDo: number,
  ) {}

  static fromAppToApi(
    data: PprfPremisesFormModel,
  ): RtPremisesFiltersDto | undefined {
    return new this(
      data.premisesFunction,
      data.floor,
      data.roomsNumberFrom,
      data.roomsNumberTo,
      data.premisesUsableAreaFrom,
      data.premisesUsableAreaTo,
      data.associatedPremisesAreaFrom,
      data.associatedPremisesAreaTo,
    );
  }
}

export class ParcelFiltersDto {
  constructor(
    public NrL: number,
    public NrM: number,
    public NrAdd: number,
  ) {}

  static fromAppToApi(data: PprfParcelFormModel): ParcelFiltersDto | undefined {
    return new this(
      data.numberNumerator,
      data.numberDenominator,
      data.numberAdditional,
    );
  }
}

export class RtRelationsFiltersDto {
  constructor(
    public RtDzialka: RtParcelFiltersDto,
    public RtBudynek: RtBuildingFiltersDto,
    public RtLokal: RtPremisesFiltersDto,
    public Dzialka: ParcelFiltersDto,
    public ObrebId: number[],
    public GminaId: number[],
    public Geom: string,
  ) {}

  static fromAppToApi(
    data: PprfAttributesSearchFormModel,
  ): RtRelationsFiltersDto {
    const instance = new this(
      RtParcelFiltersDto.fromAppToApi(data.parcel),
      RtBuildingFiltersDto.fromAppToApi(data.building),
      RtPremisesFiltersDto.fromAppToApi(data.premises),
      ParcelFiltersDto.fromAppToApi(data.parcel),
      data.transactionAndPricing.cadastralUnit,
      data.transactionAndPricing.district,
      undefined,
    );

    return isNotEmptyObject(instance) ? instance : undefined;
  }
}

export enum PprfAttributesSearchFilterName {
  Type = 'Typ',
  TransactionAndPricingDateFrom = 'DataTranOd',
  TransactionAndPricingDateTo = 'DataTranDo',
  RegistrationDateFrom = 'DataDOd',
  RegistrationDateTo = 'DataDdo',
  ValuationPurpose = 'CelSzacowania',
  MarketType = 'RodzajRynku',
  PropertyType = 'TypNieruch',
  Seller = 'Zbywajacy',
  Buyer = 'Nabywca',
  TransactionType = 'FormaObrotu',
  GrossTransactionPrice = 'Cena',
  RtRelations = 'RtRelations',
  Address = 'Adres',
  HasDigitalDocumentation = 'Zbior',
  Valued = 'Wyceniona',
  Share = 'Udzial',
  RightType = 'RodzajPrawNieruch',
  LandRegistryAreaFrom = 'PowNieruchGrunOd',
  LandRegistryAreaTo = 'PowNieruchGrunDo',
}

export class PprfAttributesSearchFormModelDto {
  constructor(
    public Typ: number[],
    public DataTransOd: string,
    public DataTransDo: string,
    public DataDOd: string,
    public DataDdo: string,
    public CelSzacowania: number[],
    public FormaObrotu: number[],
    public TypNieruch: number[],
    public Zbywajacy: number[],
    public Nabywca: number[],
    public RodzajRynku: number[],
    public Cena: number[],
    public RtRelations: ApiFilter,
    public Adres: any, // TODO: Define Adres type
    public Zbior: boolean,
    public Wyceniona: boolean,
    public UdzialL: number,
    public Udzial: number,
    public UdzialM: number,
    public RodzajPrawNieruch: number[],
    public PowNieruchGrunOd: number,
    public PowNieruchGrunDo: number,
  ) {}

  static fromAppToApi(data: PprfAttributesSearchFormModel): ApiFilter[] {
    const getShareByRangesControl = data.property.getShareByRangesControl();

    return [
      new ApiFilter(
        PprfAttributesSearchFilterName.Type,
        data.transactionAndPricing.type,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.TransactionAndPricingDateFrom,
        data.transactionAndPricing.transactionAndPricingDateFrom,
        FilterOperatorType.GreaterThanOrEquals,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.TransactionAndPricingDateTo,
        data.transactionAndPricing.transactionAndPricingDateTo,
        FilterOperatorType.LessThanOrEquals,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.RegistrationDateFrom,
        data.transactionAndPricing.registrationDateFrom,
        FilterOperatorType.GreaterThanOrEquals,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.RegistrationDateTo,
        data.transactionAndPricing.registrationDateTo,
        FilterOperatorType.LessThanOrEquals,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.ValuationPurpose,
        data.transactionAndPricing.valuationPurpose,
        FilterOperatorType.InList,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.MarketType,
        data.transactionAndPricing.marketType,
        FilterOperatorType.InList,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.PropertyType,
        data.property.propertyType,
        FilterOperatorType.InList,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.Seller,
        data.transactionAndPricing.seller,
        FilterOperatorType.InList,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.Buyer,
        data.transactionAndPricing.buyer,
        FilterOperatorType.InList,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.TransactionType,
        data.transactionAndPricing.transactionType,
        FilterOperatorType.InList,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.GrossTransactionPrice,
        data.transactionAndPricing.grossTransactionPrice,
        FilterOperatorType.InList,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.RtRelations,
        RtRelationsFiltersDto.fromAppToApi(data),
      ),
      // TODO: Define Adres
      // new ApiFilter(
      //   PprfAttributesSearchFilterName.Address,
      //   undefined
      // ),
      new ApiFilter(
        PprfAttributesSearchFilterName.HasDigitalDocumentation,
        data.transactionAndPricing.hasDigitalDocumentation,
        FilterOperatorType.Equals,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.Valued,
        data.transactionAndPricing.hasPrice,
        FilterOperatorType.Equals,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.Share,
        getShareByRangesControl.from,
        FilterOperatorType.GreaterThanOrEquals,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.Share,
        getShareByRangesControl.to,
        FilterOperatorType.LessThanOrEquals,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.RightType,
        data.property.rightType,
        FilterOperatorType.InList,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.LandRegistryAreaFrom,
        data.property.landRegistryAreaFrom,
        FilterOperatorType.GreaterThanOrEquals,
      ),
      new ApiFilter(
        PprfAttributesSearchFilterName.LandRegistryAreaTo,
        data.property.landRegistryAreaTo,
        FilterOperatorType.LessThanOrEquals,
      ),
    ].filter((filter) => filter.value !== undefined);
  }
}

export class PprfAttributesSearchFormModel {
  constructor(
    public transactionAndPricing: PprfTransactionAndPricingFormModel,
    public property: PprfPropertyFormModel,
    public parcel: PprfParcelFormModel,
    public building: PprfBuildingFormModel,
    public premises: PprfPremisesFormModel,
  ) {}
}

export const pprfAttributesSearchFormModel = new PprfAttributesSearchFormModel(
  pprfTransactionAndPricingFormModel,
  pprfPropertyFormModel,
  pprfParcelFormModel,
  pprfBuildingFormModel,
  pprfPremisesFormModel,
);

export enum PprfFormNavId {
  TransactionAndPricing = 'transactionAndPricing',
  Property = 'property',
  Parcel = 'parcel',
  Building = 'building',
  Premises = 'premises',
}
