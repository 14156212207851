import {
  LawPerson,
  LawPersonType,
  NewDesignerRequestDto,
  NewLegalPersonRequestDto,
  NewNaturalPersonRequestDto,
} from '@gk/gk-modules';
import { ApiNewDokumentPowiazanyDalDto } from '../../../../services/designer-incoming-documents/designer-incoming-documents.model';
import { DecisionFormType } from '../../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import {
  Shipping,
  ShippingDto,
} from '../../../../shared/shipping-form/shipping-form.model';

export interface ATIOEAIPNewRequestDto {
  Wnioskodawca:
    | NewNaturalPersonRequestDto
    | NewLegalPersonRequestDto
    | NewDesignerRequestDto;
  Zalaczniki: ApiNewDokumentPowiazanyDalDto[];
  FormaDecyzji: number;
  Odbior: ShippingDto;
  Geom: string[];
  SzczegolowyZakres: string;
  DodatkoweWskazania: string;
}

export class ATIOEAIPNewRequest {
  constructor(
    public lawPerson: LawPerson,
    public decisionForm: DecisionFormType,
    public shippingForm: Shipping,
    public detailedScope: string,
    public additionalIndications: string
  ) {}

  static fromAppToApi(
    data: ATIOEAIPNewRequest,
    issueRanges: string[],
    files: ApiNewDokumentPowiazanyDalDto[]
  ): ATIOEAIPNewRequestDto {
    return {
      Wnioskodawca:
        data.lawPerson.type === LawPersonType.Natural
          ? NewNaturalPersonRequestDto.fromAppToApi(data.lawPerson)
          : NewLegalPersonRequestDto.fromAppToApi(data.lawPerson),
      Zalaczniki: files,
      FormaDecyzji: data.decisionForm,
      Geom: issueRanges,
      Odbior: ShippingDto.fromAppToApi(data.shippingForm),
      SzczegolowyZakres: data.detailedScope,
      DodatkoweWskazania: data.additionalIndications,
    };
  }
}
