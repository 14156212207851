export enum LawPersonControlName {
  Type = 'type',
  LegalPersonData = 'legalPersonData',
  NaturalPersonData = 'naturalPersonData',
  Address = 'address',
  ApplicantFromRegonAddress = 'applicantFromRegonAddress',
}

export enum LegalPersonControlName {
  Name = 'name',
  Nip = 'nip',
  Regon = 'regon',
  TypeId = 'typeId',
  KrsNumber = 'krsNumber',
}

export enum NaturalPersonControlName {
  FirstName = 'firstName',
  LastName = 'lastName',
  Pesel = 'pesel',
  PermissionNumber = 'permissionNumber',
  PhoneNumber = 'phoneNumber',
  Email = 'email',
}

export enum LawPersonAddressControlName {
  Place = 'place',
  Street = 'street',
  StreetAbsence = 'streetAbsence',
  BuildingNumber = 'buildingNumber',
  LocalNumber = 'localNumber',
  PostalCode = 'postalCode',
  PostOffice = 'postOffice',
}

export enum MaxLengthAddressField {
  Place = 105,
  Street = 200,
  BuildingNumber = 50,
  LocalNumber = 15,
  PostalCode = 20,
}
