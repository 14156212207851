export enum BuildingPermitTransferDocTypeId {
  Other = 8,
  RequestStampDuty = 10040,
  StampDuty = 10040,
  ConditionsAcceptance = 10044,
  PowerOfAttorney = 10035,
  LegalTitleToRealEstate = 10039,
  LitigantAgreement = 10052,
  BuildingLog = 10053
}

export enum BuildingPermitTransferDocTypeName {
  ProxyStampDuty = 'proxy stamp duty',
  RequestStampDuty = 'request stamp duty'
}

export enum BuildingPermitTransferDocTypeLabelKey {
  Other = 'BUILDING_PERMIT_TRANSFER.NEW_REQUEST.DOC_TYPE_DESCRIPTION.OTHER',
  StampDuty = 'BUILDING_PERMIT_TRANSFER.NEW_REQUEST.DOC_TYPE_DESCRIPTION.STAMP_DUTY',
  PowerOfAttorney = 'BUILDING_PERMIT_TRANSFER.NEW_REQUEST.DOC_TYPE_DESCRIPTION.POWER_OF_ATTORNEY',
  LegalTitleToRealEstate = 'BUILDING_PERMIT_TRANSFER.NEW_REQUEST.DOC_TYPE_DESCRIPTION.LEGAL_TITLE_TO_REAL_ESTATE',
  LitigantAgreement = 'BUILDING_PERMIT_TRANSFER.NEW_REQUEST.DOC_TYPE_DESCRIPTION.LITIGANT_AGREEMENT',
  BuildingLog = 'BUILDING_PERMIT_TRANSFER.NEW_REQUEST.DOC_TYPE_DESCRIPTION.BUILDING_LOG',
  ConditionsAcceptance = 'BUILDING_PERMIT_TRANSFER.NEW_REQUEST.DOC_TYPE_DESCRIPTION.CONDITIONS_ACCEPTANCE'
}

export function getBuildingPermitTransferDocTypeLabelKeyById(id: number): string {
  switch (id) {
    case BuildingPermitTransferDocTypeId.Other: {
      return BuildingPermitTransferDocTypeLabelKey.Other;
    }
    case BuildingPermitTransferDocTypeId.RequestStampDuty: {
      return BuildingPermitTransferDocTypeLabelKey.StampDuty;
    }
    case BuildingPermitTransferDocTypeId.PowerOfAttorney: {
      return BuildingPermitTransferDocTypeLabelKey.PowerOfAttorney;
    }
    case BuildingPermitTransferDocTypeId.LegalTitleToRealEstate: {
      return BuildingPermitTransferDocTypeLabelKey.LegalTitleToRealEstate;
    }
    case BuildingPermitTransferDocTypeId.BuildingLog: {
      return BuildingPermitTransferDocTypeLabelKey.BuildingLog;
    }
    case BuildingPermitTransferDocTypeId.ConditionsAcceptance: {
      return BuildingPermitTransferDocTypeLabelKey.ConditionsAcceptance;
    }
    case BuildingPermitTransferDocTypeId.LitigantAgreement: {
      return BuildingPermitTransferDocTypeLabelKey.LitigantAgreement;
    }
    default: {
      return '';
    }
  }
}
