import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class BaseDocumentationGridService {
  constructor(private http: HttpClient) {}

  deleteDocumentation(url: string): Observable<void> {
    return this.http.delete<void>(url, {
      body: {
        Owner: 'operat',
      },
    });
  }
}
