<button
  [id]="id"
  type="button"
  class="btn btn-bg-color-from-db btn-sm gk-map-icon-layers bg-white text-secondary"
  [title]="'GK.MAP.LAYERS' | translate"
  [attr.aria-label]="'GK.MAP.LAYERS' | translate"
  aria-haspopup="true"
  (click)="handleToggleSidebar()"
  [elementFocus]="elementFocusTrigger"
></button>
