export interface ApiUploadDocType {
  Id: number;
  Nazwa: string;
  Prefix: string;
  NazdokId?: number;
  IsObligatory?: boolean;
  PortalProjektanta: 1 | 2;
  MaxSize?: number;
}

export class UploadDocType<T = number> {
  public labelKey?: string;
  public downloadLabelKey?: string;
  public uploadAvailable = true;
  constructor(
    public id: T,
    public name: string,
    public sqlPattern?: string,
    public regexPattern?: string,
    public isObligatory?: boolean,
    public matchingRequestFileId?: string,
    public maxSize?: number,
  ) {}

  static fromApiToApp(apiDocType: ApiUploadDocType): UploadDocType {
    return new this(
      apiDocType.Id,
      apiDocType.Nazwa,
      apiDocType.Prefix,
      apiDocType.Prefix?.replace('.', '[.]')
        .replace(/%/g, '.*')
        .replace(/_/g, '.'),
      apiDocType.IsObligatory,
      undefined,
      apiDocType.MaxSize,
    );
  }
}
