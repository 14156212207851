import {
  LawPersonAddress,
  Street,
  getValueWhenPropertyIsAddressFields,
  getValueWhenPropertyIsString,
} from '@gk/gk-modules';
import {
  InvestorDetails,
  InvestorDetailsRequestDto,
} from '../../../shared/investor-details/investor-details.model';
import {
  Shipping,
  ShippingDto,
} from '../../../shared/shipping-form/shipping-form.model';
import { ApiNewDokumentPowiazanyDalDto } from '../../designer-incoming-documents/designer-incoming-documents.model';
import { DecisionFormType } from '../../excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { ZamowienieDzialkaDto } from '../../excluding-land-from-agricultural-production/agricultural-land-use/agricultural-land-use.model';
import { Place } from '../../place/place.model';
import { PostalCode } from '../../postal-code/postal-code.model';

export interface PremiseIndependenceNotification {
  demolitionIntention: number;
  investorDetails: InvestorDetails;
  proxyDetails: InvestorDetails;
  objectNameAndType: string;
  place: Place;
  street: Street;
  buildingNumber: string;
  localNumber: string;
  postalCode: PostalCode;
  postOffice: string;
  description: string;
  idNumber: number;
  phoneNumber: number;
  email: string;
  address: LawPersonAddress;
  proxyDetailsCheckbox: boolean;
  attachments: ApiNewDokumentPowiazanyDalDto;
  decisionForm: DecisionFormType;
  landParcels: ZamowienieDzialkaDto[];
  shippingForm: Shipping;
}

export class PremiseIndependenceNotificationRequestDto {
  constructor(
    public Wnioskodawca: InvestorDetailsRequestDto,
    public Pelnomocnik: InvestorDetailsRequestDto,
    public NazwaIRodzajObiektu: string,
    public MiejscId: string,
    public Miejsc: string,
    public UlicaId: string,
    public Ulica: string,
    public KodId: string,
    public Kod: string,
    public Poczta: string,
    public Numer: string,
    public Numer2: string,
    public Opis: string,
    public Zalaczniki: ApiNewDokumentPowiazanyDalDto[],
    public FormaDecyzji: number,
    public Dzialki: ZamowienieDzialkaDto[],
    public Odbior: ShippingDto
  ) {}
  static fromAppToApi(
    data: PremiseIndependenceNotification,
    attachments: ApiNewDokumentPowiazanyDalDto[]
  ): PremiseIndependenceNotificationRequestDto {
    return new this(
      InvestorDetailsRequestDto.fromAppToApi(data.investorDetails),
      data.proxyDetailsCheckbox
        ? InvestorDetailsRequestDto.fromAppToApi(data.proxyDetails)
        : null,
      data.objectNameAndType,
      getValueWhenPropertyIsAddressFields(data.place),
      getValueWhenPropertyIsString(data.place),
      getValueWhenPropertyIsAddressFields(data.street),
      getValueWhenPropertyIsString(data.street),
      getValueWhenPropertyIsAddressFields(data.postalCode),
      getValueWhenPropertyIsString(data.postalCode),
      data.postOffice,
      data.buildingNumber,
      data.localNumber,
      data.description,
      attachments,
      data.decisionForm,
      data.landParcels,
      ShippingDto.fromAppToApi(data.shippingForm)
    );
  }
}
