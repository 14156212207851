import { Directive, OnDestroy } from '@angular/core';
import { ListConfigs } from '@gk/gk-modules';
import { Observable } from 'rxjs';
import { filter, switchMap, takeWhile } from 'rxjs/operators';
import { AppTitleService } from '../../services/app-title/app-title.service';
import { WebPortal } from '../../services/web-portal/web-portal.model';
import { WebPortalService } from '../../services/web-portal/web-portal.service';

@Directive()
export abstract class BaseRequestsListDirective implements OnDestroy {
  isAlive = true;
  listConfigs: ListConfigs;

  constructor(
    public appTitleService: AppTitleService,
    public webPortalService: WebPortalService
  ) {}

  getCurrentPortalType(): Observable<WebPortal> {
    return this.appTitleService.currentPortal.pipe(
      takeWhile(() => this.isAlive),
      filter((portalRoute) => !!portalRoute),
      switchMap((portalRoute) =>
        this.webPortalService.getWebPortalDataByCurrentRoute(portalRoute)
      )
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
