@if (config.name) {
  <div class="dynamic-field form-input" [formGroup]="group">
    <label [attr.for]="config.name">{{ config.label | translate }}</label>
    <input
      [id]="config.name"
      type="{{ config.inputType }}"
      [name]="config.name"
      placeholder="{{ config.placeholder | translate }}"
      [formControlName]="config.name"
      class="form-control"
      />
    <gk-form-control-validation
      [formGroup]="group"
      [fieldConfig]="config"
      [submitted]="submitted"
    ></gk-form-control-validation>
  </div>
}
