@if (isSomeVisiblePerson()) {
  <div
    class="table-responsive table-bordered table-hover map-object-table text-center mt-3"
    >
    <table class="table table-sm">
      <thead class="thead-color-from-db">
        <tr>
          <th scope="col">#</th>
          <th scope="col">
            <span>{{ 'NEW_REQUEST.NAME_SURNAME' | translate }}</span>
          </th>
          <th scope="col">
            <span>{{ 'NEW_REQUEST.PESEL' | translate }}</span>
          </th>
          <th scope="col">
            <span> {{ 'NEW_REQUEST.DELETE' | translate }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        @if (shouldShowEmptyRequiredRow()) {
          <tr
            class="required empty-row"
            aria-required="true"
            >
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        }
        @for (
          authorizedPerson of authorizedPersonsToRegistryView; track identify(i,
          authorizedPerson); let i = $index) {
          <tr
            >
            @if (authorizedPerson.visible) {
              <td>{{ i + 1 }}</td>
              <td>
                {{ authorizedPerson.nameSurname }}
              </td>
              <td>
                {{ authorizedPerson.pesel }}
              </td>
              <td>
                <button
                  type="button"
                  class="btn btn-danger btn-sm gk-map-icon-remove"
                  [attr.aria-label]="'NEW_REQUEST.DELETE' | translate"
                  (click)="deletePerson(authorizedPerson, $event)"
                ></button>
              </td>
            }
          </tr>
        }
      </tbody>
    </table>
  </div>
}
