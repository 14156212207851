import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { GkComponentsModule, GkMapModule } from '@gk/gk-modules';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { createTranslateLoader } from '../address-number-request-portal/address-number-request-portal.module';
import { PLDACPSDNewRequestComponent } from './new-request/new-request.component';
import { PlotLandDevArchConstrProjSeparateDecisionRoutingModule } from './plot-land-dev-arch-constr-proj-separate-decision-routing.module';
import { PLDACPSDNewRequestFormService } from './services/new-request-form/new-request-form.service';

@NgModule({
  declarations: [PLDACPSDNewRequestComponent],
  imports: [
    SharedModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    GkMapModule,
    GkComponentsModule,
    PlotLandDevArchConstrProjSeparateDecisionRoutingModule,
    MatSelectModule,
  ],
  providers: [PLDACPSDNewRequestFormService],
})
export class PlotLandDevArchConstrProjSeparateDecisionModule {}
