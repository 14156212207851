import { throwError } from 'rxjs';
import { ApiResponseError } from '../../services';
import { SortType } from '../gk-dynamic-list.model';
import { ApiSortDir } from './services.model';

export const jsSortTypeToApiDir = (sortType: SortType): ApiSortDir =>
  sortType === SortType.Asc ? 'asc' : 'desc';

export const handleApiError = (err: ApiResponseError): any => {
  console.error(err);
  return throwError(() => err);
};
