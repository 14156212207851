import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class MapDrawingActionService {
  private breakDrawingSubject = new Subject<boolean>();
  private resetDrawing = new Subject<boolean>();
  breakDrawing$ = this.breakDrawingSubject.asObservable();
  resetDrawing$ = this.resetDrawing.asObservable();

  emitBreakSignal(): void {
    this.breakDrawingSubject.next(true);
  }

  emitResetSignal(): void {
    this.resetDrawing.next(true);
  }
}
