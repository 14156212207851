<div class="dynamic-field form-input" [formGroup]="group">
  <label for="{{ config.name }}">{{ config.label | translate }}</label>
  <input
    class="form-control"
    id="{{ config.name }}"
    type="text"
    [(ngModel)]="model"
    [ngbTypeahead]="search"
    [resultFormatter]="formatter"
    [inputFormatter]="formatter"
    [formControlName]="config.name"
  />
</div>
