import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  LawPersonAddressControlName,
  LawPersonControlName,
  LawPersonType,
  LegalPersonControlName,
  NaturalPersonControlName,
  getDefaultControlValue,
} from '@gk/gk-modules';
import { DecisionFormType } from '../excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { ShippingFormService } from '../shipping-form/shipping-form.service';
import { BuildRoadExitPermissionControlName } from './build-road-exit-permission-form.model';

@Injectable()
export class BuildRoadExitPermissionFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private shippingFormService: ShippingFormService
  ) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [BuildRoadExitPermissionControlName.LawPerson]:
        this.getLawPersonFormGroup(),
      [BuildRoadExitPermissionControlName.RequestType]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [BuildRoadExitPermissionControlName.RoadNumber]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [BuildRoadExitPermissionControlName.Place]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [BuildRoadExitPermissionControlName.PurposeOfPropertyUse]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [BuildRoadExitPermissionControlName.PurposeOfPropertyUseChanged]:
        this.formBuilder.group({
          [BuildRoadExitPermissionControlName.WillUseOfPropertyChange]: false,
          [BuildRoadExitPermissionControlName.ChangedPurposeOfPropertyUse]: [
            getDefaultControlValue(true),
            Validators.required,
          ],
        }),
      [BuildRoadExitPermissionControlName.DecisionForm]: [
        DecisionFormType.NonElectronicForm,
        [Validators.required],
      ],
      [BuildRoadExitPermissionControlName.ShippingForm]:
        this.shippingFormService.getFormGroup(),
    });
  }

  getLawPersonFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [LawPersonControlName.Type]: LawPersonType.Natural,
      [LawPersonControlName.LegalPersonData]: this.formBuilder.group({
        [LegalPersonControlName.TypeId]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [LegalPersonControlName.Name]: [
          getDefaultControlValue(),
          Validators.required,
        ],
      }),
      [LawPersonControlName.NaturalPersonData]: this.formBuilder.group({
        [NaturalPersonControlName.FirstName]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [NaturalPersonControlName.LastName]: [
          getDefaultControlValue(),
          Validators.required,
        ],
      }),
      [LawPersonControlName.Address]: this.formBuilder.group({
        [LawPersonAddressControlName.Place]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [LawPersonAddressControlName.Street]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [LawPersonAddressControlName.StreetAbsence]: false,
        [LawPersonAddressControlName.BuildingNumber]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [LawPersonAddressControlName.LocalNumber]: [getDefaultControlValue()],
        [LawPersonAddressControlName.PostalCode]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [LawPersonAddressControlName.PostOffice]: [getDefaultControlValue()],
      }),
    });
  }
}
