import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { GkComponentsModule, GkMapModule } from '@gk/gk-modules';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { createTranslateLoader } from '../address-number-request-portal/address-number-request-portal.module';
import { SFB70CCNewRequestComponent } from './new-request/new-request.component';
import { SFB70CCNewRequestFormService } from './services/new-request-form/new-request-form.service';
import { SingleFamilyBuilding70ConstructionConversionRoutingModule } from './single-family-building-70-construction-conversion-routing.module';

@NgModule({
  declarations: [SFB70CCNewRequestComponent],
  imports: [
    SharedModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    GkMapModule,
    GkComponentsModule,
    SingleFamilyBuilding70ConstructionConversionRoutingModule,
    MatSelectModule,
  ],
  providers: [SFB70CCNewRequestFormService],
})
export class SingleFamilyBuilding70ConstructionConversionModule {}
