import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { GkComponentsModule } from '../gk-components/gk-components.module';
import { CombinedPersonFormComponent } from './combined-person-form/combined-person-form.component';
import { AddressFormComponent } from './law-person-search/address-form/address-form.component';
import { LawPersonListComponent } from './law-person-search/law-person-list/law-person-list.component';
import { LawPersonSearchComponent } from './law-person-search/law-person-search.component';
import { LawPersonTypeFormComponent } from './law-person-search/law-person-type-form/law-person-type-form.component';
import { LegalPersonFormComponent } from './law-person-search/legal-person-form/legal-person-form.component';
import { NaturalPersonFormComponent } from './law-person-search/natural-person-form/natural-person-form.component';
import { SelectedLawPersonsTableContainerComponent } from './law-person-search/selected-law-persons-table-container/selected-law-persons-table-container.component';
import { LawPersonSimpleFormComponent } from './law-person-simple-form/law-person-simple-form.component';
import { LegalPersonSimpleFormComponent } from './law-person-simple-form/legal-person-simple-form/legal-person-simple-form.component';
import { NaturalPersonSimpleFormComponent } from './law-person-simple-form/natural-person-simple-form/natural-person-simple-form.component';
import { PostOfficeModalComponent } from './post-office-modal/post-office-modal.component';
import { LawPersonFormService } from './services/law-person-form/law-person-form.service';
import { LawPersonService } from './services/law-person/law-person.service';
import { LegalPersonTypeService } from './services/legal-person-type/legal-person-type.service';
import { PersonTypeService } from './services/person-type/person-type.service';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    GkComponentsModule,
    ToastrModule.forRoot(),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    LawPersonService,
    LawPersonFormService,
    LegalPersonTypeService,
    PersonTypeService,
  ],
  exports: [
    CombinedPersonFormComponent,
    AddressFormComponent,
    LawPersonListComponent,
    LawPersonTypeFormComponent,
    LegalPersonFormComponent,
    NaturalPersonFormComponent,
    LegalPersonFormComponent,
    SelectedLawPersonsTableContainerComponent,
    LawPersonSimpleFormComponent,
    PostOfficeModalComponent,
    NaturalPersonSimpleFormComponent,
    LegalPersonSimpleFormComponent,
    LawPersonSearchComponent,
  ],
  declarations: [
    CombinedPersonFormComponent,
    AddressFormComponent,
    LawPersonListComponent,
    LawPersonTypeFormComponent,
    LegalPersonFormComponent,
    NaturalPersonFormComponent,
    LegalPersonFormComponent,
    SelectedLawPersonsTableContainerComponent,
    LawPersonSimpleFormComponent,
    PostOfficeModalComponent,
    NaturalPersonSimpleFormComponent,
    LegalPersonSimpleFormComponent,
    LawPersonSearchComponent,
  ],
})
export class GkPersonSearchModule {}
