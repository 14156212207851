<div
  class="fixed-top h-100 tfa-confirmation-form d-flex flex-column overflow-visible pt-5 alpha-background"
  >
  <div class="static-height-container">
    <div class="row main-content bg-success text-center">
      <div class="col action-window">
        <div class="container-fluid">
          <div class="row mt-4">
            <div
              class="col-12 d-flex align-items-start justify-content-between"
              >
              <h2 class="w-100">
                {{ 'SESSION_TOOLS.TFA_BASE_CONFIRMATION.TFA' | translate }}
              </h2>
            </div>
          </div>
          <div class="row">
            <form
              method="post"
              id="tfa-form"
              class="form-group col-12"
              [formGroup]="formGroup"
              (ngSubmit)="tfaConfirmationSubmitHandle()"
              >
              @if (
                (tfaConfirmationService.isInitTfaConfirmationMode()
                | async) &&
                (tfaService.isTfaTotpInOperation() | async) &&
                (tfaTotpInitializationCredentialsService.get() | async)
                ?.url; as qrCodeUrl
                ) {
                <div
                  id="totp-qrcode-wrapper"
                  class="row justify-content-center"
                  >
                  <div id="totp-qrcode"></div>
                  <qrcode
                    [qrdata]="qrCodeUrl"
                    [width]="200"
                    [errorCorrectionLevel]="'M'"
                  ></qrcode>
                </div>
              }
              <div class="row pb-2">
                <label for="tfa-code" class="sr-only">{{
                  'SESSION_TOOLS.TFA_BASE_CONFIRMATION.VERIFICATION_CODE'
                  | translate
                }}</label>
                <input
                  type="text"
                  [formControlName]="controlNameEnum.Code"
                  [attr.name]="controlNameEnum.Code"
                  [attr.id]="controlNameEnum.Code"
                  class="form__input large-input p-0"
                  maxlength="6"
                  [attr.placeholder]="
                    ((tfaService.isTfaTotpInOperation() | async)
                      ? 'SESSION_TOOLS.TFA_TOTP_CONFIRMATION.ENTER_VERIFICATION_CODE'
                      : 'SESSION_TOOLS.TFA_BASE_CONFIRMATION.ENTER_VERIFICATION_CODE'
                    ) | translate
                  "
                  />
              </div>
              <div class="row">
                <div class="col-sm col-12 order-1 order-sm-0">
                  <button
                    id="cancel-tfa-code"
                    class="btn btn-danger"
                    type="button"
                    (click)="cancel()"
                    >
                    {{
                    'SESSION_TOOLS.TFA_BASE_CONFIRMATION.CANCEL' | translate
                    }}
                  </button>
                </div>
                <div class="col-sm col-12">
                  @if (!actionRequestInProgress) {
                    <input
                      type="submit"
                      id="login-button"
                      class="btn btn-normal tfa-ready-info"
                    [attr.value]="
                      'SESSION_TOOLS.TFA_BASE_CONFIRMATION.VERIFY_CODE'
                        | translate
                    "
                      [attr.disabled]="formGroup.valid ? null : true"
                      />
                  } @else {
                    <button
                      type="button"
                      class="btn btn-normal pending-info"
                      [attr.title]="
                        'SESSION_TOOLS.TFA_BASE_CONFIRMATION.AUTHENTICATION_IN_PROGRESS'
                          | translate
                      "
                      >
                      <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                    </button>
                  }
                </div>
                @if (
                  (tfaService.isTfaTotpInOperation() | async) &&
                  (tfaConfirmationService.isLoginTfaConfirmationMode()
                  | async)
                  ) {
                  <div
                    class="row"
                    >
                    <div class="col-12">
                      <a href="" (click)="openResetTfaCodeWindow($event)">{{
                        'SESSION_TOOLS.TFA_BASE_CONFIRMATION.RESTORE_ACCESS_WITH_RESET_CODE'
                        | translate
                      }}</a>
                    </div>
                  </div>
                }
              </div>
            </form>
          </div>
        </div>
      </div>
      @if (messageText) {
        <div
          class="col-12 text-center d-flex justify-content-center py-3"
        [ngClass]="{
          'bg-warning': messageStatus === messageStatusEnum.Warning,
          'bg-normal':
            messageStatus === messageStatusEnum.Success ||
            messageStatus === messageStatusEnum.Pending,
          'bg-danger': messageStatus === messageStatusEnum.Error,
          'text-dark': messageStatus === messageStatusEnum.Warning,
          'text-white':
            messageStatus === messageStatusEnum.Error ||
            messageStatus === messageStatusEnum.Success ||
            messageStatus === messageStatusEnum.Pending
        }"
          >
          <p class="mb-0 fw-bold">
            <i
              class="fa"
            [ngClass]="{
              'fa-exclamation-triangle':
                messageStatus === messageStatusEnum.Warning,
              'fa-check': messageStatus === messageStatusEnum.Success,
              'fa-lock': messageStatus === messageStatusEnum.Error,
              'fa-spinner fa-spin': messageStatus === messageStatusEnum.Pending,
              'text-white':
                messageStatus === messageStatusEnum.Error ||
                messageStatus === messageStatusEnum.Success ||
                messageStatus === messageStatusEnum.Pending
            }"
              >
            </i>
            {{ messageText }}
          </p>
        </div>
      } @else {
        <div
          class="col-12 text-center d-flex justify-content-center py-3 bg-normal text-white"
          >
          <p
            class="mb-0 fw-bold"
            [innerHTML]="
              ((tfaService.isTfaTotpInOperation() | async)
                ? (tfaConfirmationService.isInitTfaConfirmationMode() | async)
                  ? 'SESSION_TOOLS.TFA_TOTP_CONFIRMATION.DEFAULT_INIT_ACTION_INFO'
                  : 'SESSION_TOOLS.TFA_TOTP_CONFIRMATION.DEFAULT_LOGIN_ACTION_INFO'
                : 'SESSION_TOOLS.TFA_EMAIL_CONFIRMATION.DEFAULT_ACTION_INFO'
              ) | translate
            "
          ></p>
        </div>
      }
    </div>
  </div>
</div>
