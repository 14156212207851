@if (loading) {
  <div class="d-flex justify-content-center my-3">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
    </div>
  </div>
}

@if (shouldShowControl() && loaded) {
  @if (errorMsg) {
    <span class="text-danger">
      {{ errorMsg || ('GK.DYNAMIC_LIST.ERROR_OCCURRED' | translate) }}
    </span>
  }
  @if (!errorMsg) {
    @for (config of controlConfig.controlConfigsToRender; track config) {
      <gk-control-renderer
        [data]="getData()"
        [controlConfig]="config"
      ></gk-control-renderer>
    }
  }
}
