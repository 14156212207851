<form [formGroup]="form" novalidate class="row" [autocomplete]="undefined">
  <div class="col-lg col-md-12 my-1">
    <label [attr.for]="idPrefix + naturalPersonControlName.FirstName">{{
      'NATURAL_PERSON_FORM.FIRST_NAME' | translate
    }}</label>
    <input
      type="text"
      [name]="idPrefix + naturalPersonControlName.FirstName"
      [id]="idPrefix + naturalPersonControlName.FirstName"
      class="form-control form-control-sm"
      [ngClass]="{ required: isRequired }"
      [attr.aria-required]="isRequired"
      [formControlName]="naturalPersonControlName.FirstName"
      [autocomplete]="undefined"
      />
    @if (shouldShowRequiredAlert(getFirstNameFormControl())) {
      <div
        class="alert alert-danger"
        tabindex="0"
        >
        {{ 'FIELD_REQUIRED' | translate }}
      </div>
    }
  </div>
  <div class="col-lg col-md-12 my-1">
    <label [attr.for]="idPrefix + naturalPersonControlName.LastName">{{
      'NATURAL_PERSON_FORM.LAST_NAME' | translate
    }}</label>
    <input
      type="text"
      [name]="idPrefix + naturalPersonControlName.LastName"
      [id]="idPrefix + naturalPersonControlName.LastName"
      class="form-control form-control-sm"
      [ngClass]="{ required: isRequired }"
      [attr.aria-required]="isRequired"
      [formControlName]="naturalPersonControlName.LastName"
      [autocomplete]="undefined"
      />
    @if (shouldShowRequiredAlert(getLastNameFormControl())) {
      <div
        class="alert alert-danger"
        tabindex="0"
        >
        {{ 'FIELD_REQUIRED' | translate }}
      </div>
    }
  </div>

  @if (isPeselVisible && !forDesignerPersonType) {
    <div class="col-lg-2 col-md-12 my-1">
      <label [attr.for]="idPrefix + naturalPersonControlName.Pesel">{{
        'NATURAL_PERSON_FORM.PESEL' | translate
      }}</label>
      <input
        type="text"
        [name]="idPrefix + naturalPersonControlName.Pesel"
        [id]="idPrefix + naturalPersonControlName.Pesel"
        class="form-control form-control-sm"
        [ngClass]="{ required: isPeselRequired() }"
        [attr.aria-required]="isPeselRequired()"
        [formControlName]="naturalPersonControlName.Pesel"
        [autocomplete]="undefined"
        />
      @if (shouldShowRequiredAlert(getPeselFormControl())) {
        <div
          class="alert alert-danger"
          tabindex="0"
          >
          {{ 'FIELD_REQUIRED' | translate }}
        </div>
      }
      @if (shouldShowPeselAlert(getPeselFormControl())) {
        <div
          class="alert alert-danger"
          tabindex="0"
          >
          {{ 'NATURAL_PERSON_FORM.PESEL_ERROR' | translate }}
        </div>
      }
    </div>
  }

  @if (forDesignerPersonType) {
    <div class="col-lg-2 col-md-12 my-1">
      <label
        [attr.for]="idPrefix + naturalPersonControlName.PermissionNumber"
        [title]="'NATURAL_PERSON_FORM.PERMISSION_NUMBER' | translate"
        >{{ 'NATURAL_PERSON_FORM.PERMISSION_NUMBER_SHORT' | translate }}</label
        >
        <input
          type="text"
          [name]="idPrefix + naturalPersonControlName.PermissionNumber"
          [id]="idPrefix + naturalPersonControlName.PermissionNumber"
          class="form-control form-control-sm"
          [ngClass]="{ required: isRequired }"
          [attr.aria-required]="isRequired"
          [formControlName]="naturalPersonControlName.PermissionNumber"
          [autocomplete]="undefined"
          />
        @if (shouldShowRequiredAlert(getPermissionNumberFormControl())) {
          <div
            class="alert alert-danger"
            tabindex="0"
            >
            {{ 'FIELD_REQUIRED' | translate }}
          </div>
        }
      </div>
    }
  </form>
