export interface CityDataResponseDto {
  Nazwa: string;
  NrGus: string;
  UkladId?: number;
  Uklad: string;
  UkladWysId?: number;
  UkladWys: string;
  Epsg?: number;
}

export class CityData {
  constructor(
    public name: string,
    public gusNumber: string,
    public spatialRefId: number,
    public spatialRef: string,
    public altitudeSpatialRefId: number,
    public altitudeSpatialRef: string,
    public epsg: number
  ) {}
  static fromApiToApp(cityDataResponseDto: CityDataResponseDto): CityData {
    return new this(
      cityDataResponseDto.Nazwa,
      cityDataResponseDto.NrGus,
      cityDataResponseDto.UkladId,
      cityDataResponseDto.Uklad,
      cityDataResponseDto.UkladWysId,
      cityDataResponseDto.UkladWys,
      cityDataResponseDto.Epsg
    );
  }
}
