import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ApiResponse } from '../../../../../../services';
import {
  LandAndMortgageRegisterDepartment,
  LandAndMortgageRegisterDepartmentFromApi,
} from './land-and-mortgage-register-department.model';

@Injectable()
export class LandAndMortgageRegisterDepartmentService {
  private landAndMortgageRegisterDepartments: Observable<
    LandAndMortgageRegisterDepartment[]
  >;

  constructor(private http: HttpClient) {}

  getLandAndMortgageRegisterDepartments(): Observable<
    LandAndMortgageRegisterDepartment[]
  > {
    if (!this.landAndMortgageRegisterDepartments) {
      this.landAndMortgageRegisterDepartments = this.http
        .get<ApiResponse<LandAndMortgageRegisterDepartmentFromApi[]>>(
          '/api/egib/slo/wydzialykw'
        )
        .pipe(
          map(
            (res) =>
              res.Response.map((register) =>
                LandAndMortgageRegisterDepartment.fromApiToApp(register)
              ),
            shareReplay(1)
          )
        );
    }

    return this.landAndMortgageRegisterDepartments;
  }
}
