import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import {
  InvestorDetails,
  InvestorDetailsRequestDto,
} from '../../../shared/investor-details/investor-details.model';
import { DateHelperUtil } from '../../../utils/date-helper/date-helper.util';
import {
  AuthorizedPersonControlName,
  AuthorizedPersonData,
  AuthorizedPersonDataRequestDto,
} from '../../authorized-person-form/authorized-person-form.model';
import { ApiNewDokumentPowiazanyDalDto } from '../../designer-incoming-documents/designer-incoming-documents.model';

export enum ConstructionLogType {
  DB1 = 1,
  DB2 = 2,
  DB3 = 3,
  DB2_A = 4,
}

export interface ConstructionLog {
  investorDetails: InvestorDetails;
  proxyDetailsCheckbox: boolean;
  proxyDetails: InvestorDetails;
  authorizedDetailsCheckbox: boolean;
  [AuthorizedPersonControlName.AuthorizedDetailsForm]: AuthorizedPersonData;
  decisionNumber: string;
  decisionDate: NgbDate;
  decisionPublishedBy: string;
  constructionLogType: ConstructionLogType;
  attachments: ApiNewDokumentPowiazanyDalDto;
}

export class ConstructionLogRequestDto {
  constructor(
    public DaneInwestora: InvestorDetailsRequestDto,
    public DanePelnomocnika: InvestorDetailsRequestDto,
    public DaneUpowaznionego: AuthorizedPersonDataRequestDto,
    public NumerDecyzji: string,
    public DataDecyzji: string,
    public WydanaPrzez: string,
    public RodzajDziennikaBudowy: ConstructionLogType,
    public Zalaczniki: ApiNewDokumentPowiazanyDalDto[]
  ) {}
  static fromAppToApi(
    data: ConstructionLog,
    attachments: ApiNewDokumentPowiazanyDalDto[]
  ): ConstructionLogRequestDto {
    return new this(
      InvestorDetailsRequestDto.fromAppToApi(data.investorDetails),
      data.proxyDetailsCheckbox
        ? InvestorDetailsRequestDto.fromAppToApi(data.proxyDetails)
        : null,
      AuthorizedPersonDataRequestDto.fromAppToApi(
        data[AuthorizedPersonControlName.AuthorizedDetailsForm]
      ),
      data.decisionNumber,
      DateHelperUtil.ngbDateToApiDateTime(data.decisionDate),
      data.decisionPublishedBy,
      data.constructionLogType,
      attachments
    );
  }
}
