<div class="table-responsive">
  <table class="table table-sm">
    <tbody>
      @for (detail of details; track $index) {
        <tr>
          <th scope="row" class="color-bg-lightgrey text-end m-2">
            {{ detail.title | translate }}:
          </th>
          <td class="color-bg-lightgoldenrodyellow">
            @switch (detail.type) {
              @case (DetailType.Date) {
                <span>{{ detail.value?.toString() | dateWithoutTime }}</span>
              }
              @case (DetailType.Text) {
                <span>{{ convertToString(detail.value) | translate }}</span>
              }
              @case (DetailType.Numeric) {
                <span>{{ detail.value }}</span>
              }
              @case (DetailType.Boolean) {
                <span>{{ detail.value ? 'Tak' : 'Nie' }}</span>
              }
              <!--              @default {-->
              <!--                <span>{{ detail.value }}</span>-->
              <!--              }-->
            }
          </td>
        </tr>
      }
    </tbody>
  </table>
</div>

<gk-loading-panel
  [isLoading]="baseDetailsService.$loading | async"
></gk-loading-panel>
