import { AbstractControl, ValidatorFn } from '@angular/forms';
import { LawPersonAddressControlName } from '../../gk-person-search/services/law-person-form/law-person-form.model';

export function streetFromDictionaryValidator(): ValidatorFn {
  return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
    const streetFormControlValue = formGroup.get(
      LawPersonAddressControlName.Street
    ).value;
    const placeFormControlValue = formGroup.get(
      LawPersonAddressControlName.Place
    ).value;

    return placeFormControlValue &&
      placeFormControlValue.isLocal &&
      streetFormControlValue &&
      !streetFormControlValue.id
      ? { streetFromDictionary: true }
      : null;
  };
}
