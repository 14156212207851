import {
  Street,
  getValueWhenPropertyIsAddressFields,
  getValueWhenPropertyIsString,
} from '@gk/gk-modules';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import {
  InvestorDetails,
  InvestorDetailsRequestDto,
} from '../../../shared/investor-details/investor-details.model';
import {
  Shipping,
  ShippingDto,
} from '../../../shared/shipping-form/shipping-form.model';
import { DateHelperUtil } from '../../../utils/date-helper/date-helper.util';
import { ApiNewDokumentPowiazanyDalDto } from '../../designer-incoming-documents/designer-incoming-documents.model';
import { DecisionFormType } from '../../excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { ZamowienieDzialkaDto } from '../../excluding-land-from-agricultural-production/agricultural-land-use/agricultural-land-use.model';
import { Place } from '../../place/place.model';
import { PostalCode } from '../../postal-code/postal-code.model';

export interface BuildingPermitTransfer {
  investorDetails: InvestorDetails;
  currentInvestorDetails: InvestorDetails;
  proxyDetails: InvestorDetails;
  buildingNameAndType: string;
  place: Place;
  street: Street;
  buildingNumber: string;
  localNumber: string;
  postalCode: PostalCode;
  postOffice: string;
  permitTransferDate: NgbDate;
  permitTransferNumber: string;
  proxyDetailsCheckbox: boolean;
  decisionForm: DecisionFormType;
  landParcels: ZamowienieDzialkaDto[];
  shippingForm: Shipping;
}

export class BuildingPermitTransferRequestDto {
  constructor(
    public DaneInwestora: InvestorDetailsRequestDto,
    public DaneDotychczasowegoInwestora: InvestorDetailsRequestDto,
    public DanePelnomocnika: InvestorDetailsRequestDto,
    public ZDnia: string,
    public NumerPozwolenia: string,
    public NazwaIRodzajBudowy: string,
    public MiejscId: string,
    public Miejsc: string,
    public UlicaId: string,
    public Ulica: string,
    public KodId: string,
    public Kod: string,
    public Poczta: string,
    public Numer: string,
    public Numer2: string,
    public Dzialki: ZamowienieDzialkaDto[],
    public Zalaczniki: ApiNewDokumentPowiazanyDalDto[],
    public FormaDecyzji: number,
    public Odbior: ShippingDto
  ) {}
  static fromAppToApi(
    data: BuildingPermitTransfer,
    attachments: ApiNewDokumentPowiazanyDalDto[]
  ): BuildingPermitTransferRequestDto {
    return new this(
      InvestorDetailsRequestDto.fromAppToApi(data.investorDetails),
      InvestorDetailsRequestDto.fromAppToApi(data.currentInvestorDetails),
      data.proxyDetailsCheckbox
        ? InvestorDetailsRequestDto.fromAppToApi(data.proxyDetails)
        : null,
      DateHelperUtil.ngbDateToApiDateTime(data.permitTransferDate),
      data.permitTransferNumber,
      data.buildingNameAndType,
      getValueWhenPropertyIsAddressFields(data.place),
      getValueWhenPropertyIsString(data.place),
      getValueWhenPropertyIsAddressFields(data.street),
      getValueWhenPropertyIsString(data.street),
      getValueWhenPropertyIsAddressFields(data.postalCode),
      getValueWhenPropertyIsString(data.postalCode),
      data.postOffice,
      data.buildingNumber,
      data.localNumber,
      data.landParcels,
      attachments,
      data.decisionForm,
      ShippingDto.fromAppToApi(data.shippingForm)
    );
  }
}
