import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FileNamingPatternFromApi } from './file-naming-patterns.model';
import { map } from 'rxjs/operators';
import { ApiResponse } from '../../../services';

@Injectable({
  providedIn: 'root',
})
export class FileNamingPatternsService {
  constructor(private httpClient: HttpClient) {}

  subscribeToFileNamingPatternsData(
    url: string
  ): Observable<FileNamingPatternFromApi[]> {
    return this.httpClient
      .get<ApiResponse<FileNamingPatternFromApi[]>>(url)
      .pipe(map((response) => response.Response));
  }
}
