import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { MapAction, MapState } from '../../gk-map/models';
import { MapStateService } from '../../gk-map/services';

@Injectable()
export class GkKendoGridMapService {
  $pendingMapActions = new ReplaySubject<MapAction[]>(1);
  $mapState = new BehaviorSubject(MapState.getInitialStruct());

  constructor(private mapStateService: MapStateService) {
    this.$pendingMapActions.subscribe((mapActions) =>
      mapActions.forEach((mapAction) => this.handleMapAction(mapAction)),
    );
  }

  handleMapAction(mapAction: MapAction): void {
    this.$mapState.next(
      this.mapStateService.getUpdatedMapState(
        mapAction,
        this.$mapState.getValue(),
      ),
    );
  }
}
