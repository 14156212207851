import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TriggerId } from './control-communication.model';

@Injectable()
export class ControlCommunicationService {
  private triggers: { [key: string]: Subject<void> } = {};
  private dataArraysCounts: { [key: string]: number } = {};

  private setTrigger(triggerId: TriggerId): void {
    this.triggers[triggerId] = new Subject<void>();
  }

  getTrigger(triggerId: TriggerId): Subject<void> {
    if (!this.triggers[triggerId]) {
      this.setTrigger(triggerId);
    }

    return this.triggers[triggerId];
  }

  setDataArrayCount(
    pathToDataArrayCountUpdate: string,
    dataUuid: string,
    count: number
  ): void {
    this.dataArraysCounts[
      this.getDataArrayCountIdentifier(pathToDataArrayCountUpdate, dataUuid)
    ] = count;
  }

  getDataArrayCount(
    pathToDataArrayCountUpdate: string,
    dataUuid: string
  ): number {
    return this.dataArraysCounts[
      this.getDataArrayCountIdentifier(pathToDataArrayCountUpdate, dataUuid)
    ];
  }

  private getDataArrayCountIdentifier(
    pathToDataArrayCountUpdate: string,
    dataUuid: string
  ): string {
    return `${pathToDataArrayCountUpdate}_${dataUuid}`;
  }
}
