import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import * as _ from 'lodash';
import { map, Observable } from 'rxjs';
import { DocTypeService } from '../../../../services/doc-type/doc-type.service';
import {
  DocFile,
  MaxLengthDesignerRequest,
} from '../../../../services/new-designer-request/new-designer-request.model';
import {
  getFileSizeString,
  maxLenghtFileName,
} from '../../../../utils/files/files.util';

@Component({
  selector: 'app-docs-table-editable',
  templateUrl: './docs-table-editable.component.html',
  styleUrls: ['./docs-table-editable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocsTableEditableComponent {
  @Input() files: DocFile[] = [];
  @Input() submitted: boolean;
  @Output() filesChange = new EventEmitter<DocFile[]>();
  maxLengthDesignerRequest = MaxLengthDesignerRequest;

  constructor(public docTypeService: DocTypeService) {}

  getShortText(text: string, maxLen = 30): string {
    return text.length < maxLen ? text : `${text.substring(0, maxLen - 3)}...`;
  }

  getFileSizeString(file: File): string {
    return getFileSizeString(file.size);
  }

  removeFile(fileIndex: number): void {
    this.files = this.files.filter((_file, index) => fileIndex !== index);
    this.emitFilesChange();
  }

  areValidDocTypes(): boolean {
    return !_.find(this.files, (file) => !_.get(file, 'docTypeId'));
  }

  areFilesNotEmpty(): boolean {
    return !_.find(this.files, (file) => !file.size);
  }

  isCorrectFileNameLength(fileName: string): boolean {
    return fileName.length <= maxLenghtFileName;
  }

  isCorrectEveryFileName(): boolean {
    return this.files.every((file) => this.isCorrectFileNameLength(file.name));
  }

  areFilesValid(): Observable<boolean> {
    return this.docTypeService
      .isCorrectEveryFileSize(this.files)
      .pipe(
        map(
          (isCorrectEveryFileSize) =>
            isCorrectEveryFileSize &&
            this.areValidDocTypes() &&
            this.areFilesNotEmpty() &&
            this.isCorrectEveryFileName()
        )
      );
  }

  removeAll(): void {
    this.files = [];
    this.emitFilesChange();
  }

  emitFilesChange(): void {
    this.filesChange.emit(this.files);
  }
}
