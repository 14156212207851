import { ClientData } from '../tfa-web-authn-initialization/tfa-web-authn-initialization.model';

export interface TfaWebAuthnInitializationCredentialsApi {
  ResetToken: string;
  Challenge: string;
}

export class TfaWebAuthnInitializationCredentials {
  public rp = { name: 'webEWID' };
  public user = { id: new Uint8Array(16) };
  public pubKeyCredParams: PublicKeyCredentialParameters[] = [
    {
      type: 'public-key',
      alg: -7, // "ES256" as registered in the IANA COSE Algorithms registry
    },
    {
      type: 'public-key',
      alg: -257, // Value registered by this specification for "RS256"
    },
  ];
  public authenticatorSelection: AuthenticatorSelectionCriteria = {
    authenticatorAttachment: 'cross-platform',
    userVerification: 'discouraged',
  };
  public timeout = 360000;

  constructor(public resetToken: string, public challenge: Uint8Array) {}

  static fromApiToApp(
    data: TfaWebAuthnInitializationCredentialsApi
  ): TfaWebAuthnInitializationCredentials {
    return new this(
      data.ResetToken,
      Uint8Array.from(data.Challenge, (c) => c.charCodeAt(0))
    );
  }
}

export class Credentials {
  constructor(
    public publicKeyObject: any,
    public clientDataObj: ClientData,
    public credentialIdBytes: number[],
    public xCoordinate: number[],
    public yCoordinate: number[]
  ) {}
}
