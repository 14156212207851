@if (shouldShowControl()) {
  <div gkControlRow class="flex-column">
    @if (controlConfig.showLabel) {
      <span class="w-100 fw-bold py-2">
        <gk-translated-label
          [configLabel]="controlConfig.label"
          ></gk-translated-label
          >:</span
          >
        }
        @if (loading) {
          <div class="d-flex justify-content-center my-3">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
            </div>
          </div>
        }
        <div class="w-100">
          <div class="table-responsive">
            @if (!documents.length) {
              <span>{{
                'GK.DYNAMIC_LIST.LACK_OF_FILES' | translate
              }}</span>
            }
            @if (documents.length) {
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th class="text-truncate" scope="col">
                      {{
                      'GK.DYNAMIC_LIST.CONTROL_ADD_RELATED_DOCUMENT.FILENAME'
                      | translate
                      }}
                    </th>
                    <th class="text-truncate" scope="col">
                      {{
                      'GK.DYNAMIC_LIST.CONTROL_ADD_RELATED_DOCUMENT.DOCUMENT_TYPE'
                      | translate
                      }}
                    </th>
                    <th class="text-truncate" scope="col">
                      {{
                      'GK.DYNAMIC_LIST.CONTROL_ADD_RELATED_DOCUMENT.SIZE' | translate
                      }}
                    </th>
                    <th class="text-truncate" scope="col">
                      {{
                      'GK.DYNAMIC_LIST.CONTROL_ADD_RELATED_DOCUMENT.ADDED_DATE'
                      | translate
                      }}
                    </th>
                    <th class="text-truncate" scope="col">
                      {{
                      'GK.DYNAMIC_LIST.CONTROL_ADD_RELATED_DOCUMENT.DESCRIPTION'
                      | translate
                      }}
                    </th>
                    @if (!shouldHideRemoveFile()) {
                      <th
                        class="text-truncate"
                        scope="col"
                        >
                        {{
                        'GK.DYNAMIC_LIST.CONTROL_ADD_RELATED_DOCUMENT.REMOVE'
                        | translate
                        }}
                      </th>
                    }
                  </tr>
                </thead>
                <tbody>
                  @for (doc of documents; track doc) {
                    <tr>
                      <th class="text-truncate">
                        <div
                          [ngbTooltip]="blockedDocumentTooltip"
                [disableTooltip]="
                  !shouldDisableDownloadFile(doc) ||
                  !controlConfig.blockedDocumentTooltip
                "
                          >
                          <button
                            [disabled]="shouldDisableDownloadFile(doc)"
                            (click)="downloadFile(doc)"
                            type="button"
                            class="btn btn-link m-0 p-0"
                  [title]="
                    'GK.DYNAMIC_LIST.CONTROL_ADD_RELATED_DOCUMENT.CLICK_TO_DOWNLOAD'
                      | translate
                  "
                            >
                            {{ getFileFullName(doc) }}
                          </button>
                        </div>
                      </th>
                      <td class="text-truncate">{{ getFileType(doc) }}</td>
                      <td class="text-truncate">{{ getFileSize(doc) }}</td>
                      <td class="text-truncate">
                        {{ getUiDate(getFileAddedDate(doc)) }}
                      </td>
                      <td class="text-truncate">{{ getFileDescription(doc) }}</td>
                      @if (!shouldHideRemoveFile()) {
                        <td>
                          <button
                            [disabled]="shouldDisableRemoveFile()"
                            (click)="removeFile(doc, confirmationModal)"
                            type="button"
                            class="btn btn-sm btn-danger fa fa-close"
                attr.aria-label="{{
                  'GK.DYNAMIC_LIST.CONTROL_ADD_RELATED_DOCUMENT.REMOVE'
                    | translate
                }}"
                attr.title="{{
                  'GK.DYNAMIC_LIST.CONTROL_ADD_RELATED_DOCUMENT.REMOVE'
                    | translate
                }}"
                          ></button>
                        </td>
                      }
                    </tr>
                  }
                </tbody>
              </table>
            }
          </div>
          @if (shouldShowBlockedDocumentsLegend()) {
            <div class="m-1">
              <i class="fa fa-square text-secondary opacity-30" aria-hidden="true"></i>
              -
              {{ 'DOCUMENTS_BLOCKED_FOR_DOWNLOADING' | translate }}
            </div>
          }
        </div>
      </div>
    }

    <ng-template
      #confirmationModal
      let-c="close('accept')"
      let-d="dismiss('reject')"
      >
      <div class="modal-header">
        <h4 class="modal-title">
          {{ 'GK.DYNAMIC_LIST.CONFIRMATION_OPERATION_TITLE' | translate }}
        </h4>
      </div>
      <div class="modal-body">
        <span
          >{{ 'GK.DYNAMIC_LIST.ASSURED_TO_DELETE' | translate }}
          <span class="fw-bold">{{ getFileFullName(documentToDelete) }}</span
            >?</span
            >
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-success" (click)="(c)">
              {{ 'GK.DYNAMIC_LIST.YES' | translate }}
            </button>
            <button type="button" class="btn btn-outline-danger" (click)="(d)">
              {{ 'GK.DYNAMIC_LIST.NO' | translate }}
            </button>
          </div>
        </ng-template>

        <ng-template #blockedDocumentTooltip
          ><gk-translated-label
          [configLabel]="controlConfig.blockedDocumentTooltip"
          ></gk-translated-label
        ></ng-template>
