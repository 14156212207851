import { Component, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { takeWhile } from 'rxjs/operators';
import {
  Community,
  District,
} from '../../../services/community/community.model';
import { CommunityService } from '../../../services/community/community.service';
import { DistrictSelectionService } from '../../../services/district-selection/district-selection.service';

@Component({
  selector: 'app-district-multi-select',
  templateUrl: './district-multi-select.component.html',
  styleUrls: ['./district-multi-select.component.scss'],
})
export class DistrictMultiSelectComponent implements OnInit, OnDestroy {
  private isAlive = true;
  allCommunities: Community[] = [];
  chosenCommunity: Community;
  availableDistricts: District[] = [];
  selectedAvailableDistricts: District[] = [];
  chosenDistricts: District[] = [];
  selectedChosenDistricts: District[] = [];
  selectedDistricts: District[] = [];

  constructor(
    private communityService: CommunityService,
    private districtSelectionService: DistrictSelectionService
  ) {}

  ngOnInit(): void {
    this.subscribeToCommunitiesWithDistricts();
    this.subscribeToSelectedDistricts();
    this.subscribeToResetDistrictsState();
  }

  subscribeToResetDistrictsState(): void {
    this.districtSelectionService.resetDistrictsState
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(() => this.initDistricts());
  }

  subscribeToSelectedDistricts(): void {
    this.districtSelectionService.selectedDistricts
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((districts) => (this.selectedDistricts = districts));
  }

  subscribeToCommunitiesWithDistricts(): void {
    this.communityService.communities
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((communities) => {
        this.allCommunities = communities;
        this.chosenCommunity = this.allCommunities[0];
        this.initDistricts();
      });
  }

  initDistricts(): void {
    this.selectedAvailableDistricts = [];
    this.selectedChosenDistricts = [];
    this.chosenDistricts = _.intersectionBy(
      _.get(this.chosenCommunity, 'districts', []),
      this.selectedDistricts,
      'id'
    );
    this.availableDistricts = _.get(
      this.chosenCommunity,
      'districts',
      [] as District[]
    ).filter((dstr) => !_.find(this.chosenDistricts, dstr));
  }

  moveAllToAvailable(event: Event): void {
    event.preventDefault();
    this.availableDistricts = [..._.get(this.chosenCommunity, 'districts', [])];
    this.chosenDistricts = [];
    this.selectedChosenDistricts = [];
    this.updateSelectedDistricts();
  }

  moveAllToChosen(event: Event): void {
    event.preventDefault();
    this.chosenDistricts = [..._.get(this.chosenCommunity, 'districts', [])];
    this.availableDistricts = [];
    this.selectedAvailableDistricts = [];
    this.updateSelectedDistricts();
  }

  moveAvailableSelectedToChosen(event: Event): void {
    event.preventDefault();
    this.chosenDistricts = [
      ...this.chosenDistricts,
      ...this.selectedAvailableDistricts,
    ];
    this.availableDistricts = this.availableDistricts.filter(
      (dstr) => !_.find(this.selectedAvailableDistricts, dstr)
    );
    this.selectedAvailableDistricts = [];
    this.updateSelectedDistricts();
  }

  moveChosenSelectedToAvailable(event: Event): void {
    event.preventDefault();
    this.availableDistricts = [
      ...this.availableDistricts,
      ...this.selectedChosenDistricts,
    ];
    this.chosenDistricts = this.chosenDistricts.filter(
      (dstr) => !_.find(this.selectedChosenDistricts, dstr)
    );
    this.selectedChosenDistricts = [];
    this.updateSelectedDistricts();
  }

  updateSelectedDistricts(): void {
    this.districtSelectionService.removeDistricts(this.availableDistricts);
    this.districtSelectionService.addDistricts(this.chosenDistricts);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
