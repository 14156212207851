import { Street } from '@gk/gk-modules';
import { Place } from '../place/place.model';
import { PostalCode } from '../postal-code/postal-code.model';

export interface SupplementaryDeclarationParcel {
  Adres: string;
  ArkuszId: string;
  ArkuszNazwa: string;
  Arowa: boolean;
  Geom: string;
  Id: number;
  IsArchival: boolean;
  JednostkaEwidencyjnaId: number;
  JednostkaEwidencyjnaNazwa: string;
  JednostkaRejestrowaId: string;
  JednostkaRejestrowaNazwa: string;
  Klasouzytki: string[];
  KlasouzytkiPowierzchniaText: string[];
  NrKw: string;
  Numer: string;
  ObrebId: number;
  ObrebNazwa: string;
  PowierzchniaEwidText: string;
  Uuid: string;
}

export class ParcelNumber {
  nominator: string;
  denominator: string;
  additionalNumber?: string;
}

export class SearchParcelFormModel {
  communityId?: number;
  districtId?: number;
  sheetId?: number;
  parcelNumber?: ParcelNumber;
}

export class AdvancedSearchParcelFormModel {
  communityId?: number;
  districtId?: number;
  parcelNumber?: ParcelNumber;
  parcelAddress?: ParcelAddress;
}

export class ParcelAddress {
  place?: Place;
  street?: Street;
  postalCode?: PostalCode;
  ordinalNumber?: string;
  localNumber?: string;
}

export enum RangeType {
  Parcels = 'parcels',
  Districts = 'districts',
  Wkt = 'wkt',
}

export enum ParcelListMode {
  Choosing = 0,
  Removing = 1,
}
