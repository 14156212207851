import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Control } from '../control';
import {
  ActionButtonConfig,
  ActionType,
  AppActionLinkControlConfig,
} from '../../../gk-dynamic-list.model';
import { takeWhile } from 'rxjs/operators';
import {
  AttributesDynamicListResult,
  MapAction,
  MapObjectApiType,
  MapObjectTableActionType,
  MapState,
  MapViewActionType,
  SubjectType,
  ToolActionType,
  ToolType,
  Wkt,
} from '../../../../gk-map/models';
import {
  MapExtentUtils,
  ModalUtils,
  ShapeUtils,
} from '../../../../gk-map/utils';
import { Observable } from 'rxjs';
import { AttributesDynamicListComponent } from '../../../../gk-map/components';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../../../services/data/data.service';

@Component({
  selector: 'gk-control-app-action-link',
  templateUrl: './app-action-link.component.html',
})
export class AppActionLinkComponent extends Control<string> implements OnInit {
  actionTypeEnum = ActionType;
  paramValue: string;
  @Input() override controlConfig: AppActionLinkControlConfig;
  @Input()
  mapState: MapState;
  toolType = ToolType.Info;
  @Output()
  dispatchMapAction = new EventEmitter<MapAction>();

  constructor(
    protected override translateService: TranslateService,
    protected override dataService: DataService,
    private modalService: NgbModal
  ) {
    super(translateService, dataService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.initParamValue();
  }

  getGeometry(buttonConfig: ActionButtonConfig): Observable<Wkt[]> {
    return this.dataService.getData(buttonConfig.requestConfig, this.data);
  }

  showGeometry(buttonConfig: ActionButtonConfig): void {
    this.getGeometry(buttonConfig)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((geometryArray) => {
        const mapObject = ShapeUtils.getAnyGeometryMapObject(
          geometryArray[0],
          MapObjectApiType.ExtentOrPolygon
        );
        this.dispatchMapAction.emit(
          new MapAction(
            MapViewActionType.ExtentToFitToChange,
            MapExtentUtils.getMapExtentFromMapObjects([mapObject])
          )
        );
        this.dispatchMapAction.emit(
          new MapAction(MapObjectTableActionType.Select, mapObject)
        );
      });
  }

  openAttributesModal(): void {
    this.getModalRefWithTransferredProperties(
      AttributesDynamicListComponent
    ).result.then((result) => this.dispatchAttributesDynamicListResult(result));
  }

  getModalRefWithTransferredProperties(entryComponent: any): NgbModalRef {
    const modalRef = this.modalService.open(
      entryComponent,
      ModalUtils.getCustomXlModalOptions()
    );
    modalRef.componentInstance.mapState = this.mapState;
    modalRef.componentInstance.toolType = this.toolType;
    modalRef.componentInstance.mapObject = {
      uuid: this.data.KdzialkaUuid,
      type: SubjectType.LandParcel,
    };

    return modalRef;
  }

  dispatchAttributesDynamicListResult(
    result: AttributesDynamicListResult
  ): void {
    this.dispatchMapAction.emit(
      new MapAction(ToolActionType.MapObjectsWithAttributesChange, {
        value: result.requestedMapObjectWithAttributes,
        options: { toolType: this.toolType },
      })
    );
  }

  shouldShowButton(
    buttonConfig: ActionButtonConfig,
    actionType: ActionType
  ): boolean {
    return (
      buttonConfig.actionType === actionType &&
      (buttonConfig.showIfSpecificValueOfProperties &&
      buttonConfig.showIfSpecificValueOfProperties.length
        ? buttonConfig.showIfSpecificValueOfProperties.every(
            (requiredValue) =>
              this.data[requiredValue.pathToValue] === requiredValue.value
          )
        : true)
    );
  }

  initParamValue(): void {
    this.paramValue = this.isEmptyValue() ? '' : this.getValue();
  }
}
