<div gkControlRow class="flex-column">
  @if (controlConfig.showLabel) {
    <span class="w-25 fw-bold text-right">
      <gk-translated-label
        [configLabel]="controlConfig.label"
        ></gk-translated-label
        >:</span
        >
      }
      <div class="w-100">
        <ul ngbNav #nav="ngbNav">
          @for (
            bookmark of controlConfig.bookmarkSectionSettings; track
            bookmark; let i = $index) {
            <li
              [ngbNavItem]="i + 1"
        title="{{ bookmark.label }} {{
          (dataTableBookmark[i] | async)?.length
            ? '(' + (dataTableBookmark[i] | async)?.length + ')'
            : ''
        }}"
        [ngClass]="{
          active: bookmark.isActive,
          disabled: bookmark.isDisabled
        }"
              >
              <ng-template ngbNavContent>
                <gk-table
                  [records]="dataTableBookmark[i] | async"
                  [settings]="bookmark.columnHeader"
                  >
                </gk-table>
              </ng-template>
            </li>
          }
        </ul>

        <div [ngbNavOutlet]="nav"></div>
      </div>
    </div>
