export const MainWebPortalUrl = 'cms';

export enum WebPortAlType {
  External = 'Z',
  Internal = 'W',
}

export enum PokCardOpacity {
  Default = '0.5',
  Hovered = '1',
}

export const RedirectionAfterLogoutQueryParamKey = 'redirectionAfterLogout';
