import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, ReplaySubject } from 'rxjs';
import {
  DictionaryField,
  DictionaryFromApi,
} from '../../../gk-dynamic-list/services/dictionary/dictionary.model';
import { ApiResponse } from '../../../services';

@Injectable()
export class BuildingService {
  buildingState = new ReplaySubject<DictionaryField[]>();
  constructor(private httpClient: HttpClient) {
    this.fetchBuildingStateDictionary();
  }

  private fetchBuildingStateDictionary(): void {
    this.httpClient
      .get<ApiResponse<DictionaryFromApi>>('/api/egib/slo/statusbud')
      .pipe(
        map((data) =>
          data.Response.map(
            (element) => new DictionaryField(element.Id, element.Nazwa)
          )
        )
      )
      .subscribe((dictionaryFields) =>
        this.buildingState.next(dictionaryFields)
      );
  }
}
