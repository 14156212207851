import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BasicResponse } from '../../../utils/basic-response/basic-response.model';
import { RodoClauseEndpoint } from './rodo-clause.model';

@Injectable()
export class RodoClauseService {
  private rodoClause: Observable<string>;
  private rodoClauseForDepartment:
    | { [key: string]: Observable<string> }
    | Record<string, never> = {};

  constructor(private httpClient: HttpClient) {}

  public getRodoClause(departmentId?: number): Observable<string> {
    return departmentId
      ? this.getRodoClauseForDepartment(departmentId)
      : this.getRodoClauseForOwner();
  }

  private getRodoClauseForDepartment(departmentId: number): Observable<string> {
    if (!this.rodoClauseForDepartment[departmentId]) {
      this.rodoClauseForDepartment[departmentId] = this.httpClient
        .get<BasicResponse>(`/api/system/wydzial/${departmentId}/klauzularodo`)
        .pipe(
          map((response) => response.Result),
          shareReplay(1)
        );
    }

    return this.rodoClauseForDepartment[departmentId];
  }

  private getRodoClauseForOwner(): Observable<string> {
    if (!this.rodoClause) {
      this.rodoClause = this.httpClient
        .get<BasicResponse>(RodoClauseEndpoint.GetClause)
        .pipe(
          map((response) => response.Result),
          shareReplay(1)
        );
    }

    return this.rodoClause;
  }
}
