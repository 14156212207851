import { Injectable } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { WebRequest, WebRequestDto } from './web-request.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class WebRequestService {
  readonly webRequestUrl = '/api/system/web/wniosek';
  readonly webRequestDownloadUrl = '/api/system/web/wniosek/download';
  webRequestData = new ReplaySubject<WebRequest[]>();

  constructor(private httpClient: HttpClient) {
    this.fetchWebRequestData();
  }

  private fetchWebRequestData(): void {
    this.getWebRequestData().subscribe((webRequestData) =>
      this.webRequestData.next(webRequestData)
    );
  }

  private getWebRequestData(): Observable<WebRequest[]> {
    return this.httpClient
      .get<WebRequestDto[]>(this.webRequestUrl)
      .pipe(
        map((apiFields) =>
          apiFields.map((apiField) => WebRequest.fromApiToApp(apiField))
        )
      );
  }

  downloadBlob(webRequest: WebRequest[]): Observable<HttpResponse<Blob>> {
    return this.httpClient.post(
      this.webRequestDownloadUrl,
      { FileUuids: [webRequest[0].uuid] },
      { responseType: 'blob', observe: 'response' }
    );
  }

  fetchArrayBufferData(relativeUrl: string): Observable<ArrayBuffer> {
    return this.httpClient.get(relativeUrl, {
      responseType: 'arraybuffer',
    });
  }
}
