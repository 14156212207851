import { GkKendoIdsFileSelectComponent } from './gk-kendo-ids-file-select.component';
import { uploadIcon } from '@progress/kendo-svg-icons';
import { GridActionButtonsConfig } from '../../gk-kendo-grid-action-buttons/gk-kendo-action-buttons-model';

export const getGkKendoGeomFileSelectActionButtonConfig: (
  c: GkKendoIdsFileSelectComponent,
) => GridActionButtonsConfig = (c: GkKendoIdsFileSelectComponent) => {
  return {
    actionsLayout: 'end',
    buttons: [
      {
        text: 'LOAD',
        svgIcon: uploadIcon,
        click: () => c.onLoadClick(),
        disabled: () => !c.isValid(),
      },
    ],
  };
};

export class ValidParcelIdsResult {
  constructor(
    public isValid: IdentifiersValidateStatuses,
    public validIdentifiers: Array<string>,
    public invalidIdentifiers: Array<string>,
  ) {}

  static fromAppToApi(
    validIdentifiers: string[],
    invalidIdentifiers: string[],
  ): ValidParcelIdsResult {
    return new this(
      getIdentifierValidationStatus(validIdentifiers, invalidIdentifiers),
      validIdentifiers,
      invalidIdentifiers,
    );
  }
}

const getIdentifierValidationStatus = (
  validIdentifiers: string[],
  invalidIdentifiers: string[],
): IdentifiersValidateStatuses => {
  if (!validIdentifiers.length) {
    return IdentifiersValidateStatuses.Invalid;
  }
  if (!invalidIdentifiers.length) {
    return IdentifiersValidateStatuses.Valid;
  }
  if (invalidIdentifiers.length) {
    return IdentifiersValidateStatuses.PartillyValid;
  }

  return '' as IdentifiersValidateStatuses;
};

export enum IdentifiersValidateStatuses {
  Valid = 'valid',
  PartillyValid = 'partiallyValid',
  Invalid = 'invalid',
}
