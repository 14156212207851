import {
  MapComponent,
  OverlayComponent,
  SourceVectorComponent,
} from 'ng-openlayers';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  CityData,
  Color,
  DepartmentCode,
  GkDynamicNavbarUiStateStoreService,
  MapFeatureStyle,
  MapGeometryStyleConfig,
  NavbarUiState,
  ProjectionCode,
  ProjectionCodeDefinition,
  SystemCitiesDataService,
  SystemConfig,
  SystemConfigGuestService,
} from '@gk/gk-modules';
import { TranslateService } from '@ngx-translate/core';
import { Feature } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { pointerMove } from 'ol/events/condition';
import { buffer, Extent, getCenter } from 'ol/extent';
import { WKT } from 'ol/format';
import { Select } from 'ol/interaction';
import { Tile } from 'ol/layer';
import { register } from 'ol/proj/proj4';
import { OSM, Vector } from 'ol/source';
import proj4 from 'proj4';
import { Observable, takeWhile } from 'rxjs';
import { PortalTranslationDropdownItemId } from '../../components/navbar-provider/dynamic-navbar.configuration';
import { MainRoutes } from '../../guards/guard.models';
import { WebPortal } from '../../services/web-portal/web-portal.model';
import { WebPortalService } from '../../services/web-portal/web-portal.service';
import {
  MainWebPortalUrl,
  WebPortAlType,
} from '../office-departments-portal/departments/departments.model';

@Component({
  selector: 'app-spatial-information-portal',
  templateUrl: './spatial-information-portal.component.html',
  styleUrls: ['./spatial-information-portal.component.scss'],
})
export class SpatialInformationPortalComponent implements OnInit, OnDestroy {
  private isAlive = true;
  webCmwVoivodeshipTranslatedHeaderText: string;
  webPortalData: WebPortal[];
  externalPortalsGeomCoordinates: Coordinate[][];
  @ViewChild('map') map: MapComponent;
  @ViewChild('featuresVectorSource')
  featuresVectorSource: SourceVectorComponent;
  @ViewChild('popupOverlay') popupOverlay: OverlayComponent;
  vectorSource: Vector;
  vectorSourceExtent: Extent;
  mapZoomFullExtentTranslation: string;
  externalPortalsOlFeatures: Feature[];
  popupContent: string;
  navBarUiState: NavbarUiState;
  $webCmwVoivodeshipTranslatedHeaderText: Observable<string>;
  spatialTranslationNavItemId: string;

  constructor(
    private systemConfigGuestService: SystemConfigGuestService,
    private webPortalService: WebPortalService,
    private router: Router,
    private systemCitiesDataService: SystemCitiesDataService,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private gkDynamicNavbarUiStateStoreService: GkDynamicNavbarUiStateStoreService,
    @Inject('window') public window: Window,
  ) {}

  ngOnInit(): void {
    this.setProjectionDefinitions();
    this.subscribeToMapZoomFullExtentTranslation();
    this.subscribeToNavbarUiState();
  }

  initMapWithFeatures(): void {
    if (!this.shouldShowMap()) {
      return;
    }
    this.featuresVectorSource.instance.addFeatures(
      this.externalPortalsOlFeatures,
    );
    this.vectorSourceExtent = buffer(this.getVectorSourceExtent(), 1500);
    const mapViewExtent = this.map.instance
      .getView()
      .calculateExtent(this.map.instance.getSize());
    const osmTileLayer = new Tile({
      extent: mapViewExtent,
      source: new OSM({
        url: 'assets/maps/{z}/{x}/{y}.png',
      }),
    });
    this.map.instance.addLayer(osmTileLayer);
    this.map.instance
      .getInteractions()
      .forEach((interaction) => interaction.setActive(false));
    const selectPointerMove = new Select({
      condition: pointerMove,
    });
    this.map.instance.addInteraction(selectPointerMove);
    selectPointerMove.on('select', (event: any) => {
      const selectedFeature = event.target.getFeatures().item(0);
      if (selectedFeature) {
        const featureProperties = selectedFeature.getProperties();
        const getFeatureUrl = (featurePropertyUrl: string) => {
          if (featurePropertyUrl === 'cms') {
            return `${window.location.origin}/${featurePropertyUrl}/`;
          }

          return featurePropertyUrl;
        };

        if (featureProperties.url) {
          const selectedFeatureExtent = selectedFeature
            .getGeometry()
            .getExtent();
          const selectedFeatureCenter = getCenter(selectedFeatureExtent);
          this.popupContent = `<a href='${getFeatureUrl(
            featureProperties.url,
          )}'>${featureProperties.description}</a>`;

          this.popupOverlay.instance.setPosition(selectedFeatureCenter);
        } else {
          this.popupOverlay.instance.setPosition(undefined);
        }
      }
    });
    this.zoomToVectorSourceExtent();
  }

  fetchWebPortalData(systemCitiesData: CityData[]): void {
    this.webPortalService
      .getWebPortalData()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => {
        this.webPortalData = data.filter(
          (webPortal) =>
            webPortal.type === WebPortAlType.External &&
            webPortal.portalId >= 100,
        );
        this.changeDetectorRef.detectChanges();
        this.externalPortalsOlFeatures =
          this.getExternalPortalsOlFeatures(systemCitiesData);
        this.initMapWithFeatures();
      });
  }

  getExternalPortalsOlFeatures(systemCitiesData: CityData[]): Feature[] {
    const featureEpsg = systemCitiesData[0].epsg;
    return this.webPortalData
      .filter((item) => item.wkt)
      .map((item) => {
        const olFeature = new WKT().readFeature(item.wkt, {
          dataProjection: `EPSG:${featureEpsg}`,
          featureProjection: 'EPSG:3857',
        });
        olFeature.setProperties(item);

        return olFeature;
      });
  }

  fetchSystemCitiesData(): void {
    this.systemCitiesDataService.systemCitiesData
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((systemCitiesData) =>
        this.fetchWebPortalData(systemCitiesData),
      );
  }

  subscribeToMapZoomFullExtentTranslation(): void {
    this.translateService
      .get('GK.MAP.ZOOM_TO_FULL_EXTENT')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (translations) => (this.mapZoomFullExtentTranslation = translations),
      );
  }

  isMainPortal(url: string): boolean {
    return url === MainWebPortalUrl;
  }

  onWebPortalCardClick(webPortal: WebPortal): void {
    if (this.isMainPortal(webPortal.url)) {
      this.router.navigateByUrl(
        `${MainRoutes.OfficeDepartmentsPortal}/department/${DepartmentCode.Geodesy}`,
      );
    } else {
      this.window.location.href = webPortal.url;
    }
  }

  zoomToVectorSourceExtent(): void {
    this.map.instance
      .getView()
      .fit(this.vectorSourceExtent, { size: this.map.instance.getSize() });
  }

  getVectorSourceExtent(): Extent {
    return this.featuresVectorSource.instance.getExtent();
  }

  getFeatureStyle(): MapGeometryStyleConfig {
    return new MapGeometryStyleConfig(
      new MapFeatureStyle(Color.OrangeTransparent, Color.OrangeSolid, 2),
    );
  }

  private setProjectionDefinitions(): void {
    proj4.defs(
      ProjectionCode.Pl2000zone5,
      ProjectionCodeDefinition.Pl2000zone5,
    );
    proj4.defs(
      ProjectionCode.Pl2000zone6,
      ProjectionCodeDefinition.Pl2000zone6,
    );
    proj4.defs(
      ProjectionCode.Pl2000zone7,
      ProjectionCodeDefinition.Pl2000zone7,
    );
    proj4.defs(
      ProjectionCode.Pl2000zone8,
      ProjectionCodeDefinition.Pl2000zone8,
    );
    register(proj4);
    this.fetchSystemCitiesData();
  }

  shouldShowMap(): boolean {
    if (!this.webPortalData) {
      return false;
    }
    const portalsWithWkt = this.webPortalData.filter((item) => item.wkt);

    return portalsWithWkt.length > 1;
  }

  subscribeToNavbarUiState(): void {
    this.gkDynamicNavbarUiStateStoreService.navbarUiState.subscribe(
      (navBarUiState) => {
        this.navBarUiState = navBarUiState;
        this.maybeSetSpatialTranslationNavItemId(
          navBarUiState?.navAction.payload.navItemId,
        );
        this.getTranslatedHeaderTextBySelectedInformationPortalTranslation();
      },
    );
  }

  maybeSetSpatialTranslationNavItemId(navItemId: string) {
    if (
      Object.values(PortalTranslationDropdownItemId).includes(
        navItemId as PortalTranslationDropdownItemId,
      )
    ) {
      this.spatialTranslationNavItemId = navItemId;
    }
  }

  getTranslatedWebPortalDescriptionBySelectedInformationPortalTranslation(
    webPortal: WebPortal,
  ): string {
    switch (this.spatialTranslationNavItemId) {
      case PortalTranslationDropdownItemId.PolishTranslation:
        return webPortal.description;
      case PortalTranslationDropdownItemId.EnglishTranslation:
        return webPortal.descriptionEn;
      case PortalTranslationDropdownItemId.CzechTranslation:
        return webPortal.descriptionCs;
      case PortalTranslationDropdownItemId.GermanTranslation:
        return webPortal.descriptionDe;
      default:
        return webPortal.description;
    }
  }

  getTranslatedHeaderTextBySelectedInformationPortalTranslation(): void {
    switch (this.spatialTranslationNavItemId) {
      case PortalTranslationDropdownItemId.PolishTranslation:
        this.$webCmwVoivodeshipTranslatedHeaderText =
          this.systemConfigGuestService.getSysConfigGuestValueByKey(
            SystemConfig.WebCmsVoivodeshipHeaderTextPl,
          );
        break;
      case PortalTranslationDropdownItemId.EnglishTranslation:
        this.$webCmwVoivodeshipTranslatedHeaderText =
          this.systemConfigGuestService.getSysConfigGuestValueByKey(
            SystemConfig.WebCmsVoivodeshipHeaderTextEn,
          );
        break;
      case PortalTranslationDropdownItemId.CzechTranslation:
        this.$webCmwVoivodeshipTranslatedHeaderText =
          this.systemConfigGuestService.getSysConfigGuestValueByKey(
            SystemConfig.WebCmsVoivodeshipHeaderTextCz,
          );
        break;
      case PortalTranslationDropdownItemId.GermanTranslation:
        this.$webCmwVoivodeshipTranslatedHeaderText =
          this.systemConfigGuestService.getSysConfigGuestValueByKey(
            SystemConfig.WebCmsVoivodeshipHeaderTextDe,
          );
        break;
      default:
        this.$webCmwVoivodeshipTranslatedHeaderText =
          this.systemConfigGuestService.getSysConfigGuestValueByKey(
            SystemConfig.WebCmsVoivodeshipHeaderTextPl,
          );
    }
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
