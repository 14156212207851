@if (mapState.toolsState[toolType].isPopupLoaderVisible) {
  <div class="d-flex justify-content-center my-3">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
    </div>
  </div>
}
<div
  class="row scroll-overflow"
  [hidden]="mapState.toolsState[toolType].isPopupLoaderVisible"
>
  <ul class="list-group list-group-flush">
    @for (
      mapObject of mapState.toolsState[toolType].previewedMapObjects;
      track mapObject.uuid
    ) {
      <li class="list-group-item">
        <div>
          {{ mapObject.typeName | translate }}:
          {{ getMapObjectLabel(mapObject) }}
        </div>
        <div>
          <button
            class="btn btn-outline-color-from-db btn-sm gk-map-icon-show-on-map bg-white text-secondary"
            type="button"
            [attr.aria-label]="'GK.MAP.SHOW_ON_THE_MAP' | translate"
            [title]="'GK.MAP.SHOW_ON_THE_MAP' | translate"
            (click)="showMapObject(mapObject)"
          ></button>
          <button
            class="btn btn-outline-color-from-db btn-sm gk-map-icon-zoom-to-extent bg-white text-secondary"
            type="button"
            [attr.aria-label]="'GK.MAP.ZOOM_TO_MAP_OBJECT' | translate"
            [title]="'GK.MAP.ZOOM_TO_MAP_OBJECT' | translate"
            (click)="showMapObjectWithZoom(mapObject)"
          ></button>
          <button
            class="btn btn-outline-color-from-db btn-sm gk-map-icon-show-attributes bg-white text-secondary"
            type="button"
            [attr.aria-label]="'GK.MAP.SHOW_ATTRIBUTES' | translate"
            [title]="'GK.MAP.SHOW_ATTRIBUTES' | translate"
            aria-haspopup="true"
            (click)="openAttributesModal(mapObject)"
          ></button>
        </div>
      </li>
    }
  </ul>
</div>
