<div class="modal-header">
  <h4 class="modal-title">{{ getFormHeader() | translate }}</h4>
  <button
    type="button"
    class="btn-close"
    [attr.aria-label]="'GK.MAP.CLOSE' | translate"
    [title]="'GK.MAP.CLOSE' | translate"
    (click)="closeModal()"
  ></button>
</div>
<div class="modal-body">
  <gk-dynamic-form
    #formContainer
    [fieldConfigs]="getFieldConfigs()"
  ></gk-dynamic-form>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-color-from-db"
    [attr.aria-label]="'GK.MAP.DOWNLOAD_RANGE_FILE' | translate"
    (click)="downloadFile()"
  >
    {{ 'GK.MAP.DOWNLOAD_RANGE_FILE' | translate }}
  </button>
</div>
