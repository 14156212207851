import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { FormBaseComponent } from '../../../form-base/form-base.component';
import { DistrictSelectFieldConfig } from '../../../gk-dynamic-form.model';
import { Sheet } from '../../../services/cached/cached.model';
import { CachedService } from '../../../services/cached/cached.service';

@Component({
  selector: 'gk-form-sheet-select',
  templateUrl: './sheet-select.component.html',
})
export class SheetSelectComponent
  extends FormBaseComponent
  implements OnInit, OnDestroy
{
  private isAlive = true;
  override config: DistrictSelectFieldConfig;
  sheetsForDistrict: Sheet[];

  constructor(private cachedService: CachedService) {
    super();
  }

  ngOnInit(): void {
    this.patchEmptyValue();
    this.subscribeToSheetsForDistrict();
  }

  subscribeToSheetsForDistrict(): void {
    this.cachedService.sheetsForDistrict
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((sheets) => {
        this.sheetsForDistrict = sheets.sort((a, b) =>
          a.name.localeCompare(b.name, 'pl', { numeric: true })
        );
        this.patchEmptyValue();
      });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
