@if (shouldShowControl()) {
  <div gkControlRow>
    <div gkControlLabel [showLabel]="controlConfig.showLabel">
      <gk-translated-label [configLabel]="controlConfig.label"></gk-translated-label>:</div>
      @if (!isEmptyValue()) {
        <div gkControlValue [showLabel]="controlConfig.showLabel" gkControlColumn>
          @for (element of getArray(); track element) {
            <gk-control-renderer [data]="element" [controlConfig]="controlConfig.arrayElementControlConfig"></gk-control-renderer>
          }
        </div>
      }
      @if (isEmptyValue()) {
        <div gkControlValue [showLabel]="controlConfig.showLabel">{{
            'GK.DYNAMIC_LIST.LACK' | translate
          }}</div>
      }
    </div>
  }
