import { AbstractControl, ValidatorFn } from '@angular/forms';

const isDocumentIdValid = (documentId: string): boolean => {
  return /^[A-Za-z0-9-]{36}$/.test(documentId);
};

export function documentIdValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!control.value) {
      return null;
    }

    return isDocumentIdValid(control.value) ? null : { documentId: true };
  };
}
