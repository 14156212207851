import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@gk/gk-modules';
import { BehaviorSubject, catchError, map } from 'rxjs';
import { handleApiError } from '../services.utils';
import {
  AdministrativeDivisionDictionaryFromApi,
  VoivodeshipDictionary,
} from './administrative-division.model';

@Injectable()
export class AdministrativeDivisionService {
  administrativeDivision = new BehaviorSubject<VoivodeshipDictionary[]>([]);

  constructor(private httpClient: HttpClient) {
    this.fetchAdministrativeDivision();
  }

  private fetchAdministrativeDivision(): void {
    this.httpClient
      .get<ApiResponse<AdministrativeDivisionDictionaryFromApi[]>>(
        '/api/slo/podzialadministr/lista'
      )
      .pipe(
        map((data) => data.Response[0] && data.Response[0].Wojewodztwa),
        map((voivodeships) =>
          voivodeships.map((data) => VoivodeshipDictionary.fromApiToApp(data))
        ),
        catchError(handleApiError)
      )
      .subscribe((data) => this.administrativeDivision.next(data));
  }
}
