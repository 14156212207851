import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainRoutes } from '../../guards/guard.models';
import { SessionGuard } from '../../guards/session/session.guard';
import { MainRequestsListComponent } from '../../shared/main-requests-list/main-requests-list.component';
import { NewOwnershipConversionRequestComponent } from './new-ownership-conversion-request/new-ownership-conversion-request.component';
import { OwnershipConversionPortalComponent } from './ownership-conversion-portal.component';

const ownershipConversionPortalRoutes: Routes = [
  {
    path: MainRoutes.OwnershipConversion,
    component: OwnershipConversionPortalComponent,
    canActivate: [SessionGuard],
    children: [
      {
        path: '',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
      {
        path: 'new-request',
        component: NewOwnershipConversionRequestComponent,
      },
      {
        path: 'requests-list',
        component: MainRequestsListComponent,
      },
      {
        path: '**',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(ownershipConversionPortalRoutes)],
  providers: [],
  exports: [RouterModule],
})
export class OwnershipConversionPortalRoutingModule {}
