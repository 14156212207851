import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiFilter,
  FilterLogic,
  FilterOperatorType,
  FilterWrapper,
  RequestBodyForList,
} from '@gk/gk-modules';
import { Observable, map } from 'rxjs';
import { PostalCode, PostalCodeFromApi } from './postal-code.model';

@Injectable()
export class PostalCodeService {
  constructor(private httpClient: HttpClient) {}

  getPostalCodes(term = ''): Observable<PostalCode[]> {
    const filters: ApiFilter[] = [
      new ApiFilter('Nazwa', term, FilterOperatorType.Contains),
    ];
    const postBody = new RequestBodyForList(
      new FilterWrapper(FilterLogic.And, filters)
    );

    return this.httpClient
      .post<PostalCodeFromApi[]>(
        '/api/interesant/slo/kodpoczt/cachedautocomplete',
        {
          ...postBody,
          Polskie: true,
        }
      )
      .pipe(
        map((postalCodesFromApi) =>
          postalCodesFromApi.map((postalCodeFromApi) =>
            PostalCode.fromApiToApp(postalCodeFromApi)
          )
        )
      );
  }
}
