import { Component } from '@angular/core';
import { FormBaseComponent } from '../../form-base/form-base.component';
import { SeparatorFieldConfig } from '../../gk-dynamic-form.model';

@Component({
  selector: 'gk-form-separator',
  templateUrl: './form-separator.component.html',
})
export class FormSeparatorComponent extends FormBaseComponent {
  override config: SeparatorFieldConfig;
}
