import {
  Shipping,
  ShippingDto,
} from '../../shared/shipping-form/shipping-form.model';
import { ApiNewDokumentPowiazanyDalDto } from '../designer-incoming-documents/designer-incoming-documents.model';
import { DecisionFormType } from '../excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';

export enum AddressNumberRequestControlName {
  DecisionForm = 'decisionForm',
  ShippingForm = 'shippingForm',
}

export interface AddressNumberRequestFormModel {
  [AddressNumberRequestControlName.DecisionForm]: DecisionFormType;
  [AddressNumberRequestControlName.ShippingForm]: Shipping;
}

export class AddressNumberRequestDto {
  constructor(
    public MiejscId: string | number,
    public UlicaId: string | number,
    public BudynekId: string | number,
    public FormaDecyzji: number,
    public Odbior: ShippingDto,
    public Zalaczniki: ApiNewDokumentPowiazanyDalDto[]
  ) {}

  static fromAppToApi(
    formRawValue: AddressNumberRequestFormModel,
    placeId: string | number,
    streetId: string | number,
    buildingId: string | number,
    files: ApiNewDokumentPowiazanyDalDto[]
  ): AddressNumberRequestDto {
    return new this(
      placeId,
      streetId,
      buildingId,
      formRawValue.decisionForm,
      ShippingDto.fromAppToApi(formRawValue.shippingForm),
      files
    );
  }
}
