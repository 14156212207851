import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessibilityStatementWrapperComponent } from './components/accessibility-statement-wrapper/accessibility-statement-component-wrapper';
import { NoAccessComponent } from './components/no-access/no-access.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PaymentSuccessComponent } from './components/payment-success/payment-success.component';
import { MainRouteParams, MainRoutes } from './guards/guard.models';
import { NoSessionGuard } from './guards/no-session/no-session.guard';
import { PortalAccessClientGuard } from './guards/portal-access-client/portal-access-client.guard';
import { SessionGuard } from './guards/session/session.guard';
import { DocumentVerificationFormWrapperComponent } from './modules/office-departments-portal/departments/geodesy/modal-document-verification/forms/document-verification-form-wrapper/document-verification-form-wrapper.component';

const routes: Routes = [
  {
    path: `:${MainRouteParams.ParentRoute}/${MainRoutes.SignIn}`,
    canActivate: [NoSessionGuard],
    loadComponent: () =>
      import('./components/sign-in/sign-in.component').then(
        (m) => m.SignInComponent,
      ),
  },
  {
    path: MainRoutes.SurveyorPortal,
    canActivate: [SessionGuard],
    loadChildren: () =>
      import('./modules/surveyor-portal/surveyor-portal.module').then(
        (m) => m.SurveyorPortalModule,
      ),
  },
  {
    path: MainRoutes.AppraiserPortal,
    canActivate: [SessionGuard],
    loadChildren: () =>
      import('./modules/appraiser-portal/appraiser-portal.module').then(
        (m) => m.AppraiserPortalModule
      ),
  },
  {
    path: MainRoutes.PresentationCommentsLandBuildingRegistryProject,
    canActivate: [SessionGuard, PortalAccessClientGuard],
    loadChildren: () =>
      import(
        './modules/presentation-comments-land-building-registry-project/presentation-comments-land-building-registry-project.module'
      ).then((m) => m.PresentationCommentsLandBuildingRegistryProjectModule),
  },
  { path: '404', component: PageNotFoundComponent },
  { path: 'no-access', component: NoAccessComponent },
  {
    path: 'accessibility-statement-regular',
    component: AccessibilityStatementWrapperComponent,
  },
  {
    path: 'accessibility-statement-mobile',
    component: AccessibilityStatementWrapperComponent,
  },
  {
    path: 'accessibility-statement-forClient',
    component: AccessibilityStatementWrapperComponent,
  },
  {
    path: 'document-verification',
    component: DocumentVerificationFormWrapperComponent,
  },
  {
    path: 'payment-success',
    component: PaymentSuccessComponent,
  },
  {
    path: MainRoutes.CoordinationMeetingPortal,
    canActivate: [SessionGuard],
    loadChildren: () =>
      import(
        './modules/coordination-meeting-portal/coordination-meeting-portal.module'
      ).then((m) => m.CoordinationMeetingPortalModule),
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: [SessionGuard, NoSessionGuard],
})
export class AppRoutingModule {}
