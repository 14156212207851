<form
  class="k-form k-form-sm k-form-horizontal"
  [formGroup]="form"
  (ngSubmit)="onFormSubmit()"
>
  <formly-form
    [form]="form"
    [fields]="formlyFormConfig.fields"
    [options]="formlyFormConfig.options!"
    [model]="formModel"
  ></formly-form>
  @if (!!gkMapSheetFormActionButtonsConfig) {
    <gk-kendo-action-buttons
      [buttonsConfigs]="gkMapSheetFormActionButtonsConfig"
    ></gk-kendo-action-buttons>
  }
</form>
