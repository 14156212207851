import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PlannedApplicationMessageService } from '../../../services';
import { ShowInfoServiceConfig } from '../../gk-dynamic-navbar.model';
import { GkDynamicNavbarUiStateStoreService } from '../../state/gk-dynamic-navbar-ui-state-store.service';
import { NavBaseComponent } from '../nav-base/nav-base.component';

@Component({
  selector: 'gk-nav-item-info-service-button',
  templateUrl: './nav-item-info-service-button.component.html',
  styleUrls: ['./nav-item-info-service-button.component.scss'],
  providers: [PlannedApplicationMessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavItemInfoServiceButtonComponent extends NavBaseComponent {
  @Input()
  infoServiceButtonNavItemConfig: ShowInfoServiceConfig;

  constructor(
    public plannedApplicationMessageService: PlannedApplicationMessageService,
    public override gkDynamicNavbarUiStateStoreService: GkDynamicNavbarUiStateStoreService
  ) {
    super(gkDynamicNavbarUiStateStoreService);
  }

  getIconClassSet(): string {
    return `fa fa-${this.infoServiceButtonNavItemConfig.faIconName}`;
  }

  showInfoService(): void {
    this.plannedApplicationMessageService.setServiceMessageInfoAcceptedLocalStorageDataKey(
      false
    );
    this.plannedApplicationMessageService.maybeShowMessage();
  }
}
