export enum StreetNamingControlName {
  LawPerson = 'lawPerson',
  ObjectCategoryOrDescription = 'objectCategoryOrDescription',
  ObjectProposedNewName = 'objectProposedNewName',
  ObjectLocation = 'objectLocation',
  ObjectNamingJustification = 'objectNamingJustification',
  DecisionForm = 'decisionForm',
  ShippingForm = 'shippingForm',
  Comments = 'comments'
}
