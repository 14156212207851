<aol-control>
  <aol-content>
    <div class="ol-unselectable ol-control popup-button">
      <button
        type="button"
        class="gk-map-icon-popup"
        (click)="togglePopup()"
        title="{{ 'GK.MAP.POPUP_CONTROL' | translate }}"
        attr.aria-label="{{ 'GK.MAP.POPUP_CONTROL' | translate }}"
      ></button>
    </div>
  </aol-content>
</aol-control>
