export enum PprfDictionaryKey {
  PropertyType = 'SloTypNieruch',
  Parties = 'SloStrony',
  SaleMethod = 'SloFob',
  TransactionType = 'SloCsz',
  OwnershipRightLand = 'SloPrawDlk',
  OwnershipRightBuilding = 'SloPrawBud',
  OwnershipRightPremises = 'SloPrawLok',
  OwnershipRightRealEstate = 'SloPrawNier',
  BuildingType = 'SloRodzZab',
  LandUseMPZP = 'SloPrzeznMpzp',
  BuildingCategory = 'SloRodzBud',
  FloorCount = 'SloKondygn',
  FunctionPremises = 'SloFunlok',
  PremisesType = 'SloFdl',
  MarketType = 'SloRodzRyn',
  Encumbrance = 'SloObc',
  LandUse = 'SloUzGrun',
  TransactionPrice = 'SloCenaTrans',
  ConstructionType = 'SloKonstr',
  UsageTypeLand = 'SloDlkSposUzyt',
}

export interface PprfDictionaryFieldDto {
  GmlVal: string;
  Id: string;
  Opis: string;
}

export interface PprfDictionaryContainerDto {
  Key: PprfDictionaryKey;
  Dict: PprfDictionaryFieldDto[];
}

export class PprfDictionaryField {
  constructor(
    public gmlVal: string,
    public id: string,
    public description: string,
  ) {}

  static fromApiToApp(dto: PprfDictionaryFieldDto): PprfDictionaryField {
    return new this(dto.GmlVal, dto.Id, dto.Opis);
  }
}

export class PprfDictionaryContainer {
  constructor(
    public key: PprfDictionaryKey,
    public dictionary: PprfDictionaryField[],
  ) {}

  static fromApiToApp(
    dto: PprfDictionaryContainerDto,
  ): PprfDictionaryContainer {
    return new this(
      dto.Key,
      dto.Dict.map((field) => PprfDictionaryField.fromApiToApp(field)),
    );
  }
}
