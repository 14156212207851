import { LawPersonAddress } from '@gk/gk-modules';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import {
  InvestorDetails,
  InvestorDetailsRequestDto,
} from '../../../shared/investor-details/investor-details.model';
import {
  Shipping,
  ShippingDto,
} from '../../../shared/shipping-form/shipping-form.model';
import { ApiNewDokumentPowiazanyDalDto } from '../../designer-incoming-documents/designer-incoming-documents.model';
import { DecisionFormType } from '../../excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';

export interface FishingCard {
  investorDetails: InvestorDetails;
  proxyDetails: InvestorDetails;
  idNumber: number;
  phoneNumber: number;
  email: string;
  birthDate: NgbDate;
  birthPlace: string;
  address: LawPersonAddress;
  proxyDetailsCheckbox: boolean;
  attachments: ApiNewDokumentPowiazanyDalDto;
  decisionForm: DecisionFormType;
  shippingForm: Shipping;
}

export class FishingCardRequestDto {
  constructor(
    public Wnioskodawca: InvestorDetailsRequestDto,
    public Pelnomocnik: InvestorDetailsRequestDto,
    public Zalaczniki: ApiNewDokumentPowiazanyDalDto[],
    public FormaDecyzji: number,
    public Odbior: ShippingDto
  ) {}
  static fromAppToApi(
    data: FishingCard,
    attachments: ApiNewDokumentPowiazanyDalDto[]
  ): FishingCardRequestDto {
    return new this(
      InvestorDetailsRequestDto.fromAppToApi({
        ...data.investorDetails,
        ...{ birthPlace: data.birthPlace },
        ...{ birthDate: data.birthDate },
      }),
      data.proxyDetailsCheckbox
        ? InvestorDetailsRequestDto.fromAppToApi(data.proxyDetails)
        : null,
      attachments,
      data.decisionForm,
      ShippingDto.fromAppToApi(data.shippingForm)
    );
  }
}
