import { Component, Input } from '@angular/core';
import { DynamicMapObjectControlConfig } from '../../../gk-dynamic-list.model';

@Component({
  selector: 'gk-control-dynamic-map-object',
  templateUrl: './control-dynamic-map-object.component.html'
})
export class ControlDynamicMapObjectComponent {
  @Input()
  data: any;
  @Input()
  controlConfig: DynamicMapObjectControlConfig;

  getValue(): any {
    return this.controlConfig.valueFromDictPropertyName
      ? this.data[this.controlConfig.valueFromDictPropertyName]
      : this.data[this.controlConfig.valuePropertyName];
  }

  getLabel(): string {
    return this.data[this.controlConfig.namePropertyName];
  }
}
