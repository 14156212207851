import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { WorkspaceStateId } from './request-workspace-state.model';

@Injectable()
export class RequestWorkspaceStateService {
  constructor(private httpClient: HttpClient) {}

  updateWorkspaceState<T>(workspaceState: WorkspaceStateId, value: T): Observable<void> {
    return this.httpClient.post<void>('/api/system/euslugi/cache/draft', {
      Type: workspaceState,
      Value: JSON.stringify(value)
    });
  }

  getWorkspaceState<T>(workspaceState: WorkspaceStateId): Observable<T> {
    return this.httpClient
      .get<string>(`/api/system/euslugi/cache/draft/${workspaceState}`)
      .pipe(map((value) => JSON.parse(value)));
  }
}
