import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DecisionFormType } from '../excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { ShippingFormService } from '../shipping-form/shipping-form.service';
import { AddressNumberRequestControlName } from './address-number-request-form.model';

@Injectable()
export class AddressNumberRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private shippingFormService: ShippingFormService
  ) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [AddressNumberRequestControlName.DecisionForm]: [
        DecisionFormType.ElectronicForm,
        [Validators.required],
      ],
      [AddressNumberRequestControlName.ShippingForm]:
        this.shippingFormService.getFormGroup(),
    });
  }
}
