<div class="dynamic-field" [formGroup]="group">
  <label for="land-and-mortgage-register-department-select">{{
    config.label | translate
  }}</label>
  <select
    id="land-and-mortgage-register-department-select"
    class="form-select"
    [formControlName]="config.name"
    [ngClass]="{ error: isErrorDetected() }"
    >
    @if (config.allValuesEnabled) {
      <option value="">
        {{ 'GK.DYNAMIC_FORM.ALL' | translate }}
      </option>
    }
    @for (register of registers; track register) {
      <option [value]="register.id">
        {{ register.name }}
      </option>
    }
  </select>
</div>
