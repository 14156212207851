<aol-layer-vector>
  <aol-source-vector>
    <aol-feature [id]="id">
      <aol-geometry-point>
        <aol-coordinate
          [x]="geometry[0]"
          [y]="geometry[1]"
          [srid]="projectionCode"
        ></aol-coordinate>
      </aol-geometry-point>
      <aol-style>
        <aol-style-circle [radius]="styles.labelOrPointStyle.radius">
          <aol-style-stroke
            [color]="styles.labelOrPointStyle.strokeColor"
            [width]="styles.labelOrPointStyle.strokeWidth"
          ></aol-style-stroke>
          <aol-style-fill
            [color]="styles.labelOrPointStyle.fillColor"
          ></aol-style-fill>
        </aol-style-circle>
      </aol-style>
    </aol-feature>
  </aol-source-vector>
</aol-layer-vector>
