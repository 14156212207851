import {
  MapPolygon,
  MapObjectTypeNameTranslationPath,
  getAreaInHectaresFromSquareMeters,
  Wkt,
  MapObjectApiType,
  MapSheetFormValue,
  Scale,
  Paper,
  AnyGeometryObject
} from '../../models';
import { ConversionUtils } from '../conversion/conversion.utils';
import { UUID } from 'angular2-uuid';
import { Polygon } from 'ol/geom';
import { Coordinate } from 'ol/coordinate';

export class ShapeUtils {
  static getAnyGeometryMapObject(
    wkt: Wkt,
    mapObjectApiType: MapObjectApiType,
    scale?: Scale,
    paper?: Paper
  ): AnyGeometryObject {
    switch (mapObjectApiType) {
      case MapObjectApiType.ExtentOrPolygon:
        return new AnyGeometryObject(
          UUID.UUID(),
          wkt,
          mapObjectApiType,
          MapObjectTypeNameTranslationPath.ExtentOrPolygon,
          ShapeUtils.getArea(
            ConversionUtils.getGeometryFromWkt(wkt) as Polygon
          ),
          scale ? scale.denominator : null,
          paper ? paper.name : null,
          parseFloat(
            (ConversionUtils.getGeometryFromWkt(wkt) as Polygon)
              .getArea()
              .toFixed(0)
          )
        );
      default:
        return {
          geom: wkt
        } as AnyGeometryObject;
    }
  }

  static getPolygon(coordinates: Coordinate[]): Polygon {
    return new Polygon([coordinates]);
  }

  static getArea(polygon: Polygon): string {
    return getAreaInHectaresFromSquareMeters(polygon.getArea());
  }

  static getMapSheetCoordinates(formValue: MapSheetFormValue): MapPolygon {
    const halfWidth = ShapeUtils.getHalfDistance(
      formValue.paper.size[1],
      formValue.scale.denominator
    );
    const halfHeight = ShapeUtils.getHalfDistance(
      formValue.paper.size[0],
      formValue.scale.denominator
    );

    return [
      [formValue.center[0] - halfWidth, formValue.center[1] - halfHeight],
      [formValue.center[0] - halfWidth, formValue.center[1] + halfHeight],
      [formValue.center[0] + halfWidth, formValue.center[1] + halfHeight],
      [formValue.center[0] + halfWidth, formValue.center[1] - halfHeight],
      [formValue.center[0] - halfWidth, formValue.center[1] - halfHeight]
    ];
  }

  static getHalfDistance(distance: number, denominator: number): number {
    return (distance * denominator) / (2 * 1000);
  }

  static getTranslatedCenter(
    currentCenter: Coordinate,
    translationStart: Coordinate,
    translationEnd: Coordinate
  ): Coordinate {
    const translationVector = [
      translationEnd[0] - translationStart[0],
      translationEnd[1] - translationStart[1]
    ] as Coordinate;

    return [
      currentCenter[0] + translationVector[0],
      currentCenter[1] + translationVector[1]
    ];
  }
}
