export enum EcoDocumentRouteParams {
  Id = 'id',
  Version = 'version'
}

export interface EcoDocumentHistoryApi {
  DataArch: string;
  DataD: string;
  DataM: string;
  DataPublikacji: string;
  KartaId: string;
  NazwaDokumentu: string;
  NrKartyRok: string;
  OsobaPublikujaca: string;
}

export class EcoDocumentHistory {
  constructor(
    public id: string,
    public creationDate: string,
    public modificationDate: string,
    public publicationDate: string,
    public name: string,
    public publisher: string,
    public number: string,
    public version: string
  ) {}

  static fromApiToApp(data: EcoDocumentHistoryApi): EcoDocumentHistory {
    return new this(
      data.KartaId,
      data.DataD,
      data.DataM,
      data.DataPublikacji,
      data.NazwaDokumentu,
      data.OsobaPublikujaca,
      data.NrKartyRok,
      data.DataArch
    );
  }
}
