@if (shouldShowControl()) {
  <div gkControlRow>
    <div gkControlLabel [showLabel]="controlConfig.showLabel">
      <gk-translated-label [configLabel]="controlConfig.label"></gk-translated-label>:</div>
      <div gkControlValue [showLabel]="controlConfig.showLabel" gkControlColumn>
        @for (row of getSimplyKeyValueRowsWithOmit(); track row; let last = $last) {
          <div gkControlRow [ngClass]="{'invisible-border' : last && isEmptyValue()}">
            <div gkControlLabel [showLabel]="true">{{row.label | translate}}:</div>
            <div gkControlValue [showLabel]="true">{{row.value}}</div>
          </div>
        }
        @if (!isEmptyValue()) {
          <gk-control-renderer [data]="data" [controlConfig]="mapObjectConfig"></gk-control-renderer>
        }
      </div>
    </div>
  }
