@if (shouldShowControl()) {
  <div gkControlRow>
    <span gkControlLabel [showLabel]="controlConfig.showLabel">
      <gk-translated-label
        [configLabel]="controlConfig.label"
        ></gk-translated-label
        >:</span
        >
        <div gkControlValue [showLabel]="controlConfig.showLabel">
          @if (!zudpGeometry && !noZudpGeometry) {
            <div
              class="d-flex justify-content-center my-3"
              >
              <div class="spinner-border" role="status">
                <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
              </div>
            </div>
          }
          @if (zudpGeometry) {
            <span>
              <a
                [routerLink]="controlConfig.routeToMapTab"
                class="btn btn-sm btn-color-from-db"
                (click)="zoomToZudpGeometry()"
                >{{ 'GK.DYNAMIC_LIST.GO_TO_MAP_PREVIEW' | translate }}</a
                >
              </span>
            }
            @if (noZudpGeometry) {
              <span>{{
                'GK.DYNAMIC_LIST.NO_ISSUE_EXTENT_PROVIDED' | translate
              }}</span>
            }
          </div>
        </div>
      }
