import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { FormBaseComponent } from '../../../form-base/form-base.component';
import { ControlNetworkFieldConfig } from '../../../gk-dynamic-form.model';
import {
  ControlNetwork,
  ControlNetworkType,
} from '../../../services/control-network/control-network.model';
import { ControlNetworkService } from '../../../services/control-network/control-network.service';

@Component({
  selector: 'gk-form-control-network',
  templateUrl: './control-network.component.html',
})
export class ControlNetworkComponent
  extends FormBaseComponent
  implements OnInit, OnDestroy
{
  private isAlive = true;
  override config: ControlNetworkFieldConfig;
  controlNetworks: ControlNetwork[] = [];

  constructor(private controlNetworkService: ControlNetworkService) {
    super();
  }

  ngOnInit(): void {
    this.patchEmptyValue();
    this.subscribeToControlNetworks();
  }

  subscribeToControlNetworks(): void {
    this.getProperControlNetworks()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((controlNetworks) => (this.controlNetworks = controlNetworks));
  }

  getProperControlNetworks(): BehaviorSubject<ControlNetwork[]> {
    switch (this.config.controlNetworkType) {
      case ControlNetworkType.Horizontal:
        return this.controlNetworkService.horizontalNetworks;
      case ControlNetworkType.Vertical:
        return this.controlNetworkService.verticalNetworks;
      default:
        return new BehaviorSubject<ControlNetwork[]>([]);
    }
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
