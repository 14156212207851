import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { blobToBase64 } from '@gk/gk-modules';
import { StatusCodes } from 'http-status-codes';
import { Observable, of } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';

@Injectable()
export class SystemCountyBackgroundService {
  private cache$: Observable<string>;
  constructor(private httpClient: HttpClient) {}

  getApplicationBase64BackgroundFromDb(): Observable<string> {
    if (!this.cache$) {
      this.cache$ = this.httpClient
        .get('/api/system/powiat/background', {
          responseType: 'blob',
          observe: 'response'
        })
        .pipe(
          switchMap(sysConfigBlobResponse =>
            sysConfigBlobResponse.body.size &&
            sysConfigBlobResponse.status === StatusCodes.OK
              ? blobToBase64(sysConfigBlobResponse.body)
              : of('')
          ),
          shareReplay(1)
        );
    }
    return this.cache$;
  }
}
