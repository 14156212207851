<aol-layer-vector>
  <aol-source-vector>
    <aol-feature [id]="id">
      <aol-geometry-linestring>
        <aol-collection-coordinates
          [coordinates]="geometry"
          [srid]="projectionCode"
        ></aol-collection-coordinates>
      </aol-geometry-linestring>
      <aol-style>
        <aol-style-stroke
          [color]="styles.featureStyle.strokeColor"
          [width]="styles.featureStyle.strokeWidth"
        ></aol-style-stroke>
      </aol-style>
    </aol-feature>
  </aol-source-vector>
</aol-layer-vector>
