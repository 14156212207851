import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable ,  ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NewPassword } from './new-password.model';

@Injectable()
export class NewPasswordService {
  currentUserLogin = new ReplaySubject<string>();
  resetPasswordToken = new ReplaySubject<string>();
  constructor(private httpClient: HttpClient) {}

  newPassword(data: NewPassword): Observable<string> {
    return this.resetPasswordToken.pipe(
      switchMap(passwordToken =>
        this.httpClient.post(
          '/api/system/login/passwordchange',
          NewPassword.fromAppToApi(data, passwordToken),
          {
            responseType: 'text'
          }
        )
      )
    );
  }
}
