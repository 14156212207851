export interface ConnectionAndNetworkFormGroupContent {
  connectionAndNetwork: ConnectionAndNetwork;
  quantity: number;
}

export class ConnectionAndNetwork {
  constructor(public name: string, public id: number) {}

  static getEmptyFormGroupContent(): ConnectionAndNetworkFormGroupContent {
    return { connectionAndNetwork: {} as any, quantity: 1 };
  }
}
