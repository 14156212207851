<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{ title }}</h4>
  <button
    type="button"
    class="btn-close"
    aria-describedby="modal-title"
    (click)="activeModal.dismiss()"
  ></button>
</div>
<div class="modal-body">
  {{ body }}
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-success" (click)="onOk()">Tak</button>
  <button type="button" class="btn btn-danger" (click)="onCancel()">Nie</button>
</div>
