import {
  LawPerson,
  LawPersonType,
  NewDesignerRequestDto,
  NewLegalPersonRequestDto,
  NewNaturalPersonRequestDto,
} from '@gk/gk-modules';
import {
  Shipping,
  ShippingDto,
} from '../../shared/shipping-form/shipping-form.model';
import { ApiNewDokumentPowiazanyDalDto } from '../designer-incoming-documents/designer-incoming-documents.model';
import { DecisionFormType } from '../excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { ZamowienieDzialkaDto } from '../excluding-land-from-agricultural-production/agricultural-land-use/agricultural-land-use.model';

export interface OwnershipConversionDto {
  Wnioskodawca:
    | NewNaturalPersonRequestDto
    | NewLegalPersonRequestDto
    | NewDesignerRequestDto;
  Zalaczniki: ApiNewDokumentPowiazanyDalDto[];
  Geom: string[];
  Dzialki: ZamowienieDzialkaDto[];
  FormaDecyzji: number;
  Odbior: ShippingDto;
  InfoDodatkowe: string;
}

export class OwnershipConversion {
  constructor(
    public lawPerson: LawPerson,
    public decisionForm: DecisionFormType,
    public shippingForm: Shipping,
    public comments: string
  ) {}

  static fromAppToApi(
    data: OwnershipConversion,
    issueRanges: string[],
    parcels: ZamowienieDzialkaDto[],
    files: ApiNewDokumentPowiazanyDalDto[]
  ): OwnershipConversionDto {
    return {
      Wnioskodawca:
        data.lawPerson.type === LawPersonType.Natural
          ? NewNaturalPersonRequestDto.fromAppToApi(data.lawPerson)
          : NewLegalPersonRequestDto.fromAppToApi(data.lawPerson),
      Zalaczniki: files,
      Geom: issueRanges,
      FormaDecyzji: data.decisionForm,
      Odbior: ShippingDto.fromAppToApi(data.shippingForm),
      Dzialki: parcels,
      InfoDodatkowe: data.comments,
    };
  }
}
