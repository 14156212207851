import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';
import { Street, StreetFromApi } from './street.model';

@Injectable()
export class StreetService {
  constructor(private http: HttpClient) {}

  getStreets(
    term: string,
    placeId?: number | string,
    streetUrlTypeahead = '/api/interesant/slo/ulice/cachedautocomplete',
  ): Observable<Street[]> {
    return this.http
      .post<StreetFromApi[]>(streetUrlTypeahead, {
        MiejscowoscId: placeId,
        filter: {
          logic: 'and',
          filters: [
            {
              field: 'Nazwa',
              ignoreCase: true,
              operator: 'contains',
              value: term,
            },
          ],
        },
      })
      .pipe(
        map((streets) => streets.map((street) => Street.fromApiToApp(street))),
      );
  }
}
