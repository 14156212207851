<nav
  class="navbar px-2 navbar-expand-{{
    navbarConfig.generalNavConfig.expandBreakPoint
  }} justify-content-between {{ getNavbarAdditionalClasses() }}"
  [ngClass]="[getNavbarColor(), getBgClass(), getNavbarModificatorClass()]"
>
  <button
    class="navbar-toggler align-self-start"
    type="button"
    data-bs-toggle="collapse"
    attr.data-bs-target="{{ getCollapseTargetSelector() }}"
    attr.aria-controls="{{ getCollapseTarget() }}"
    aria-expanded="false"
    attr.aria-label="{{ 'GK.DYNAMIC_NAVBAR.TOGGLE_NAVIGATION' | translate }}"
    [title]="'GK.DYNAMIC_NAVBAR.TOGGLE_NAVIGATION' | translate"
    (click)="toggleCollapsed()"
  >
    <i class="fa fa-bars" aria-hidden="true"></i>
  </button>

  @for (sectionConfig of navbarConfig.navSections; track sectionConfig) {
    <gk-nav-section-renderer
      [navSectionConfig]="sectionConfig"
      [navbarConfig]="navbarConfig"
    ></gk-nav-section-renderer>
  }
</nav>
