export interface RequestFileFromApi {
  NazwaWysw: string;
  NrSekcji: number;
  PlikId: string;
  PortalId: string;
  Srt: number;
  WydzialId: string;
  IdRodzDok: number;
}

export class RequestFile {
  constructor(
    public displayName: string,
    public sectionNumber: number,
    public fileId: string,
    public portalId: string,
    public srt: number,
    public departmentId: string,
    public docTypeId: number
  ) {}

  static fromApiToApp(data: RequestFileFromApi): RequestFile {
    return new this(
      data.NazwaWysw,
      data.NrSekcji,
      data.PlikId,
      data.PortalId,
      data.Srt,
      data.WydzialId,
      data.IdRodzDok
    );
  }
}
