import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class DocumentIdVerificationService {
  documentVerificationUrl = '/api/geodeta/dok/weryfikacja';

  constructor(private httpClient: HttpClient) {}

  verifyDocumentId(
    documentId: string,
    onlyVerify = true
  ): Observable<HttpResponse<Blob>> {
    return this.httpClient.post(
      this.documentVerificationUrl,
      {
        Identyfikator: documentId,
        TylkoWeryfikuj: onlyVerify
      },
      { responseType: 'blob', observe: 'response' }
    );
  }
}
