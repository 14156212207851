import { ApiFilter } from '../gk-dynamic-list/services/services.model';

export interface ApiResponseError {
  ResponseStatus: {
    ErrorCode: string;
    Message: string;
    StackTrace: string;
    Errors: any[];
    Meta: null;
  };
}

export interface ApiListResponse<T> {
  Count: number;
  TotalCount: number;
  IsThatAll: boolean;
  MaxCountLimit: number;
  Response: T[];
}

export interface ApiResponse<T> {
  UpdateNeeded: boolean;
  Count: number;
  Timestamp: string;
  Response: T;
}

export enum FilterLogic {
  And = 'and',
  Or = 'or',
}

export class FilterWrapper {
  constructor(public logic: FilterLogic, public filters: ApiFilter[]) {}
}

export class RequestBodyForList {
  constructor(public filter: FilterWrapper) {}
}

export interface SelectItemDto<T> {
  Label: string;
  Value: T;
}

export class SelectItem<T> {
  constructor(
    public value: T,
    public label: string,
    public initialSelected?: boolean
  ) {}

  static fromApi<T>(dto: SelectItemDto<T>): SelectItem<T> {
    return new this(dto.Value, dto.Label);
  }
}
