<a
  class="btn"
  [ngClass]="getCustomButtonClass()"
  [routerLink]="linkNavItemConfig.navigationRoute"
  [queryParams]="linkNavItemConfig.navigationQueryParams"
  [title]="linkNavItemConfig.linkLabel | translate"
  [target]="linkNavItemConfig.target"
  (click)="handleClick()"
  data-bs-toggle="collapse"
  data-bs-target=".navbar-collapse.show"
>
  @if (linkNavItemConfig.showIcon && linkNavItemConfig.faIconName) {
    <span [ngClass]="getIconClassSet()"></span>
  }
  @if (linkNavItemConfig.showLabel) {
    <span>
      {{ linkNavItemConfig.linkLabel | translate }}
    </span>
  }
</a>
