export interface NewPasswordApi {
  OldPassword: string;
  NewPassword: string;
  Token: string;
}

export class NewPassword {
  constructor(
    public currentPassword: string,
    public newPassword: string,
    public passwordConfirmation: string
  ) {}

  static fromAppToApi(data: NewPassword, token: string): NewPasswordApi {
    return {
      OldPassword: data.currentPassword,
      NewPassword: data.newPassword,
      Token: token
    };
  }
}
