import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile } from 'rxjs';
import { ControlDownloadFileService } from '../../../../services/control-download-file/control-download-file.service';
import { PrintControlConfig } from '../../../gk-dynamic-list.model';
import { DataService } from '../../../services/data/data.service';
import { Control } from '../control';

@Component({
  selector: 'gk-control-print',
  templateUrl: './control-print.component.html',
  styleUrls: ['./control-print.component.scss'],
})
export class ControlPrintComponent extends Control<any> implements OnInit {
  @Input()
  override controlConfig: PrintControlConfig;

  constructor(
    protected override translateService: TranslateService,
    protected override dataService: DataService,
    private controlDownloadFileService: ControlDownloadFileService,
  ) {
    super(translateService, dataService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  downloadFile(): void {
    this.controlDownloadFileService
      .downloadFile(
        this.controlConfig.printConfig.requestConfig,
        this.data,
        this.controlConfig,
        this.controlConfig.printConfig.pathToFileFullName,
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe();
  }
}
