import {
  DropdownNavItemId,
  NavbarAction,
  NavbarActionType,
} from '../gk-dynamic-navbar.model';

export interface NavbarUiState {
  navAction?: NavbarAction;
}

export interface NavbarUiState {
  navAction?: NavbarAction;
}

export type IconSrc = {
  [key in DropdownNavItemId]: string;
};

export const initialNavbarUiState = {
  navAction: new NavbarAction(NavbarActionType.Click, {
    navItemId: 'polish_translation',
    customIconSrc: 'assets/flags/pl.png',
    dropdownItemId: DropdownNavItemId.TranslationDropdown,
  }),
};
