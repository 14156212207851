import { DictionaryField } from '@gk/gk-modules';
import { EcoNewsletterControlName } from '../eco-newsletter-form/eco-newsletter-form.model';

export interface EcoNewsletterForm {
  [EcoNewsletterControlName.Types]: boolean[];
  [EcoNewsletterControlName.Subjects]: boolean[];
  [EcoNewsletterControlName.Email]: string;
}

export interface EcoNewsletterApi {
  Email: string;
  RodzajIds: Array<number | string>;
  TematIds: Array<number | string>;
}

export class EcoNewsletterSubscribeElement {
  constructor(public id: string | number, public value: boolean) {}
}

export class EcoNewsletter {
  constructor(
    public types: EcoNewsletterSubscribeElement[],
    public subjects: EcoNewsletterSubscribeElement[],
    public email: string
  ) {}

  static createFromForm(
    typesDictionary: DictionaryField[],
    subjectsDictionary: DictionaryField[],
    data: EcoNewsletterForm
  ): EcoNewsletter {
    return new this(
      data.subjects.map(
        (selected, i) =>
          new EcoNewsletterSubscribeElement(subjectsDictionary[i].id, selected)
      ),
      data.types.map(
        (selected, i) =>
          new EcoNewsletterSubscribeElement(typesDictionary[i].id, selected)
      ),
      data.email
    );
  }

  getApiObject(): EcoNewsletterApi {
    return {
      Email: this.email,
      RodzajIds: this.types.filter(element => element.value).map(element => element.id),
      TematIds: this.subjects.filter(element => element.value).map(element => element.id)
    };
  }
}
