import { Component } from '@angular/core';
import { ArrayControlConfig, ObjectControlConfig } from '../../../gk-dynamic-list.model';
import { ControlObjectComponent } from '../control-object/control-object.component';

@Component({
  selector: 'gk-control-object-position',
  styleUrls: ['./control-object-position.component.scss'],
  templateUrl: './control-object-position.component.html'
})
export class ControlObjectPositionComponent extends ControlObjectComponent {
  mapObjectConfig = new ArrayControlConfig(
    'Obiekty',
    'Objects',
    true,
    [],
    new ObjectControlConfig('Obiekt mapy', '', false, ['ObjectId'], ['Geom'])
  );
}
