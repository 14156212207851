import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { FieldConfig } from '../gk-dynamic-form.model';

@Component({
  template: ''
})
export class FormBaseComponent {
  @Input()
  submitted = false;
  @Input()
  config: FieldConfig;
  @Input()
  group: UntypedFormGroup;

  getControl(): AbstractControl {
    return this.group.get(this.config.name);
  }

  patchEmptyValue(): void {
    if (_.get(this.config, 'allValuesEnabled')) {
      this.group.patchValue({ [this.config.name]: '' });
    }
  }

  isErrorDetected(): boolean {
    return (
      this.group.controls[this.config.name].errors &&
      (this.group.controls[this.config.name].touched ||
        this.group.controls[this.config.name].dirty)
    );
  }
}
