import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ListConfigs,
  ListState,
  MapAction,
  MapSettingsService,
} from '@gk/gk-modules';
import { ConfigManagerService } from '../../../services/config-manager/config-manager.service';

@Component({
  selector: 'app-eco-documents-list',
  templateUrl: './eco-documents-list.component.html',
})
export class EcoDocumentsListComponent implements OnInit, OnDestroy {
  listConfigs: ListConfigs;
  listState = {} as ListState;
  pendingMapActions: MapAction[] = [];
  @Input() filters: any; // @todo: @Input() filters: ApiFullFilter;

  constructor(
    private configManagerService: ConfigManagerService,
    private mapSettingsService: MapSettingsService
  ) {}

  ngOnInit(): void {
    this.fetchListConfigs();
  }

  fetchListConfigs(): void {
    this.listConfigs =
      this.configManagerService.getConfigForEcoPortalDocumentsList();
  }

  handleMapAction(mapAction: MapAction): void {
    this.pendingMapActions = [...this.pendingMapActions, mapAction];
  }

  dispatchPendingMapActions(): void {
    this.mapSettingsService.pendingMapActions.next(this.pendingMapActions);
  }

  ngOnDestroy(): void {
    this.dispatchPendingMapActions();
  }
}
