import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LoginControlName } from './login-form.model';

@Injectable()
export class LoginFormService {
  constructor(private formBuilder: UntypedFormBuilder) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [LoginControlName.Login]: this.formBuilder.control('', [
        Validators.required,
        Validators.minLength(2)
      ]),
      [LoginControlName.Password]: this.formBuilder.control('', [
        Validators.required,
        Validators.minLength(3)
      ])
    });
  }
}
