import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainRoutes } from '../../guards/guard.models';
import { SessionGuard } from '../../guards/session/session.guard';
import { MainRequestsListComponent } from '../../shared/main-requests-list/main-requests-list.component';
import { CommunalPremisePurchasePortalComponent } from './communal-premise-purchase-portal.component';
import { NewCommunalPremisePurchaseRequestComponent } from './new-communal-premise-purchase-request/new-communal-premise-purchase-request.component';

const communalPremisePurchasePortalRoutes: Routes = [
  {
    path: MainRoutes.CommunalPremisePurchase,
    component: CommunalPremisePurchasePortalComponent,
    canActivate: [SessionGuard],
    children: [
      {
        path: '',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
      {
        path: 'new-request',
        component: NewCommunalPremisePurchaseRequestComponent,
      },
      {
        path: 'requests-list',
        component: MainRequestsListComponent,
      },
      {
        path: '**',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(communalPremisePurchasePortalRoutes)],
  providers: [],
  exports: [RouterModule],
})
export class CommunalPremisePurchasePortalRoutingModule {}
