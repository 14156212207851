import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { getDefaultControlValue } from '@gk/gk-modules';
import { AuthorizedPersonControlName } from '../../authorized-person-form/authorized-person-form.model';
import { AuthorizedPersonFormService } from '../../authorized-person-form/authorized-person-form.service';
import { InvestorDetailsFormService } from '../../investor-details-form/investor-details-form.service';
import { ConstructionLogControlName } from './construction-log-form.model';

@Injectable()
export class ConstructionLogFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private investorDetailsFormService: InvestorDetailsFormService,
    private authorizedPersonFormService: AuthorizedPersonFormService
  ) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [ConstructionLogControlName.InvestorDetails]:
        this.investorDetailsFormService.getFormGroup(),
      [ConstructionLogControlName.ProxyDetailsCheckbox]: [
        getDefaultControlValue(),
      ],
      [ConstructionLogControlName.DecisionNumber]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [ConstructionLogControlName.DecisionDate]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [ConstructionLogControlName.DecisionPublishedBy]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [ConstructionLogControlName.ConstructionLogType]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [AuthorizedPersonControlName.AuthorizedDetailsForm]:
        this.authorizedPersonFormService.getFormGroup(),
    });
  }
}
