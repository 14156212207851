import { PortalId } from '@gk/gk-modules';

export interface ApiDocType {
  Id: number;
  Nazwa: string;
  Prefix: string;
  NazdokId?: number;
  IsObligatory?: boolean;
  MaxSize?: number;
}

export class DocType<T = number> {
  public labelKey?: string;
  public downloadLabelKey?: string;
  public uploadAvailable = true;
  constructor(
    public id: T,
    public name: string,
    public sqlPattern?: SqlPattern,
    public isObligatory?: boolean,
    public matchingRequestFileId?: string,
    public maxSize?: number,
  ) {}

  static fromApiToApp(apiDocType: ApiDocType): DocType {
    return new this(
      apiDocType.Id,
      apiDocType.Nazwa,
      apiDocType.Prefix,
      apiDocType.IsObligatory,
      undefined,
      apiDocType.MaxSize,
    );
  }
}

export const powerOfAttorneyDocTypeId = 10035;

export type SqlPattern = string | null;
/*
  possible patterns:
 'ane%' - startsWith
 '%dz%' - contains
 '%.zip' - endsWith
*/

export function getEServiceI18nPrefix(portalId: PortalId): string {
  switch (portalId) {
    case PortalId.RoadExit:
      return 'ROAD_EXIT_PORTAL.BUILD_PERMISSION_REQUEST';
    case PortalId.ExcludingLandFromAgriculturalProductionStageFirst:
      return 'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.AGRICULTURAL_LAND_USE_REQUEST';
    case PortalId.LaneOccupancyBuildingWorks:
      return 'LANE_OCCUPANCY_PORTAL.BUILDING_WORKS';
    case PortalId.ExcludingLandFromAgriculturalProductionStageSecond:
      return 'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.NON_AGRICULTURAL_LAND_USE_REQUEST';
    case PortalId.LaneOccupancyInfrastructureDevices:
      return 'LANE_OCCUPANCY_PORTAL.INFRASTRUCTURE_DEVICES';
    case PortalId.LaneOccupancyBuildingObjects:
      return 'LANE_OCCUPANCY_PORTAL.BUILDING_OBJECTS';
    case PortalId.EnvironmentalProtectionPortalSimplifiedForestManagementPlanRequest:
      return 'ENVIRONMENTAL_PROTECTION_PORTAL.SIMPLIFIED_FOREST_MANAGEMENT_PLAN';
    case PortalId.EnvironmentalProtectionPortalFishingCardRequest:
      return 'FISHING_CARD';
    case PortalId.EnvironmentalProtectionPortalWaterEquipmentRequest:
      return 'WATER_EQUIPMENT';
    case PortalId.ConstructionPortalConstructionNotificationRequest:
      return 'CONSTRUCTION_PORTAL.CONSTRUCTION_NOTIFICATION';
    case PortalId.ConstructionPortalDemolitionNotificationRequest:
      return 'CONSTRUCTION_PORTAL.DEMOLITION_NOTIFICATION';
    case PortalId.BuildingPermitTransfer:
      return 'BUILDING_PERMIT_TRANSFER.NEW_REQUEST';
    case PortalId.ConstructionPortalPremiseIndependenceRequest:
      return 'CONSTRUCTION_PORTAL.PREMISE_INDEPENDENCE_NOTIFICATION';
    case PortalId.EnvironmentalProtectionPortalTreesAndShrubsFellingRequest:
      return 'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING';
    case PortalId.ConstructionPortalConstructionLogRequest:
      return 'CONSTRUCTION_PORTAL.CONSTRUCTION_LOG';
    case PortalId.PlanningDepartmentExtractFromLocalSpatialDevelopmentPlanRequest:
    case PortalId.PlanningDepartmentCertificateFromLocalSpatialDevelopmentPlanRequest:
    case PortalId.PlanningDepartmentExtractFromStudy:
      return 'PLANNING_DEPARTMENT.EXTRACT_CERTIFICATE_FROM_LOCAL_SPATIAL_DEVELOPMENT_PLAN_OR_STUDY_REQUEST';
    case PortalId.PropertyLease:
      return 'PROPERTY_LEASE';
    case PortalId.SupplementaryParcelPurchase:
      return 'SUPPLEMENTARY_PARCEL_PURCHASE';
    case PortalId.OwnershipConversion:
      return 'OWNERSHIP_CONVERSION';
    case PortalId.AddressNumberRequestPortal:
      return 'ADDRESS_NUMBER_REQUEST_PORTAL';
    case PortalId.StreetNaming:
      return 'STREET_NAMING';
    case PortalId.CommunalPremisePurchase:
      return 'COMMUNAL_PREMISE_PURCHASE';
    case PortalId.LandAndBuildingRegisterDataChangesNotificationRequest:
      return 'LAND_AND_BUILDING_REGISTER_DATA_CHANGES_NOTIFICATION.NEW_DATA_CHANGES_NOTIFICATION';
    case PortalId.SoilClassificationOfLandNewCarryOutRequest:
      return 'SOIL_CLASSIFICATION_OF_LAND.NEW_CARRY_OUT_REQUEST';
    case PortalId.PresentationCommentsLandBuildingRegistryProject:
      return 'PRESENTATION_COMMENTS_LAND_BUILDING_REGISTRY_PROJECT.NEW_COMMENTS_REQUEST';
    case PortalId.AccessToInformationOnEnvironmentAndItsProtection:
      return 'ACCESS_TO_INFORMATION_ON_ENVIRONMENT_AND_ITS_PROTECTION.NEW_REQUEST';
    case PortalId.BuildingPermit:
      return 'BUILDING_PERMIT.NEW_REQUEST';
    case PortalId.DemolitionPermit:
      return 'DEMOLITION_PERMIT.NEW_REQUEST';
    case PortalId.BuildingOrPartUseChange:
      return 'BUILDING_OR_PART_USE_CHANGE.NEW_REQUEST';
    case PortalId.TemporaryStructureBuildingPermit:
      return 'TEMPORARY_STRUCTURE_BUILDING_PERMIT.NEW_REQUEST';
    case PortalId.BuildingPermitModificationDecision:
      return 'BUILDING_PERMIT_MODIFICATION_DECISION.NEW_REQUEST';
    case PortalId.NotObjectedNotificationRightsObligationsTransfer:
      return 'NOT_OBJECTED_NOTIFICATION_RIGHTS_OBLIGATIONS_TRANSFER.NEW_REQUEST';
    case PortalId.PlotLandDevArchConstrProjSeparateDecision:
      return 'PLOT_LAND_DEV_ARCH_CONSTR_PROJ_SEPARATE_DECISION.NEW_REQUEST';
    case PortalId.AdjacentPropertyNeedEnterDecision:
      return 'ADJACENT_PROPERTY_NEED_ENTER_DECISION.NEW_REQUEST';
    case PortalId.SingleFamilyBuilding70ConstructionConversion:
      return 'SINGLE_FAMILY_BUILDING_70_CONSTRUCTION_CONVERSION.NEW_REQUEST';
    case PortalId.SingleFamilyBuildingConstructionConversion:
      return 'SINGLE_FAMILY_BUILDING_CONSTRUCTION_CONVERSION.NEW_REQUEST';
    default:
      return '';
  }
}
