<div class="preview-background-color width-content p-5">
  <div #printContainer class="a4-vertical bg-white mx-auto shadow px-5 py-3">
    <div class="d-flex flex-column text-center h-100">
      <div class="col-12 mx-n4 mt-n2 mb-1 small-info-text w-auto">
        <div class="row">
          @if (
            getBooleanFormControlValue(
              controlName.ShowDocumentWithoutLegalForceLabel
            )
          ) {
            <div class="col-auto text-start">
              <small>{{
                'GK.MAP.PRINT.PREVIEW.DOCUMENT_WITHOUT_LEGAL_FORCE' | translate
              }}</small>
            </div>
          }
          <div class="col text-end">
            {{
              'GK.MAP.PRINT.PREVIEW.PREPARED_BY_WEBEWID_PRODUCER' | translate
            }}
            ({{ currentDateTimeString | async }})
          </div>
        </div>
      </div>
      <div class="col-12">
        <h4 class="mb-0">{{ 'GK.MAP.PRINT.PREVIEW.TITLE' | translate }}</h4>
      </div>
      <div class="col-12">
        <h6 class="mb-0">
          {{ getSubtitle() }}
        </h6>
      </div>
      @if (getBooleanFormControlValue(controlName.ShowScaleInfo)) {
        <div class="col-12 lh-1">
          <span>
            {{ 'GK.MAP.PRINT.PREVIEW.SCALE' | translate }} {{ getScaleName() }}
          </span>
        </div>
      }
      @if (getBooleanFormControlValue(controlName.ShowCoordinatesInfo)) {
        <div class="col-12 lh-1">
          <span>
            {{ 'GK.MAP.PRINT.PREVIEW.COORDINATE_SYSTEM' | translate }}
            {{
              mapStateService.getCoordinateSystemDescription(
                mapState.viewState.nativeProjectionCode
              ) | translate
            }}
          </span>
        </div>
      }
      @if (getBooleanFormControlValue(controlName.ShowCornersCoordinates)) {
        <div class="col-12 lh-1 text-end">
          <span>
            {{ getRightTopCoordinates() }}
          </span>
        </div>
      }
      <div class="col-12 map-view-wrapper d-flex flex-fill position-relative">
        @if (getBooleanFormControlValue(controlName.ShowNorthArrow)) {
          <div
            class="north-arrow position-absolute"
            style="
              background: url(assets/north-arrow.png);
              background-size: cover;
            "
          ></div>
        }
        <gk-map-view
          [mapState]="mapState"
          (dispatch)="dispatch.emit($event)"
        ></gk-map-view>
      </div>
      @if (getBooleanFormControlValue(controlName.ShowCornersCoordinates)) {
        <div class="col-12 lh-1 text-start">
          <span>
            {{ getLeftBottomCoordinates() }}
          </span>
        </div>
      }
      <div class="mx-n4 mb-n2 small-info-text text-start">
        <small>{{ getPrintFooter() }}</small>
      </div>
    </div>
  </div>
</div>
