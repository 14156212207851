import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GkApiPostalCode, GkPostalCode } from './postal-code.model';

@Injectable()
export class GkPostalCodeService {
  constructor(private http: HttpClient) {}

  getPostalCodesSearchBody = (
    term: string,
    placeId?: string | number
  ): any => ({
    MiejscowoscId: placeId,
    filter: {
      logic: 'and',
      filters: [
        {
          field: 'Nazwa',
          ignoreCase: true,
          operator: 'startswith',
          value: term,
        },
      ],
    },
  });

  getPostalCodes(
    term: string,
    placeId?: string | number
  ): Observable<GkPostalCode[]> {
    return this.http
      .post<GkApiPostalCode[]>(
        '/api/interesant/slo/kodpoczt/cachedautocomplete',
        this.getPostalCodesSearchBody(term, placeId)
      )
      .pipe(
        map((apiPostalCodes) => apiPostalCodes.slice(0, 10)),
        map((apiPostalCodes) =>
          apiPostalCodes.map((apiPostalCode) =>
            GkPostalCode.fromApiToApp(apiPostalCode)
          )
        )
      );
  }
}
