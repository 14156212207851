import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { DictionaryField } from '@gk/gk-modules';
import { takeWhile } from 'rxjs';
import {
  CountyDictionary,
  VoivodeshipDictionary,
} from '../../../services/administrative-division/administrative-division.model';
import { AdministrativeDivisionService } from '../../../services/administrative-division/administrative-division.service';
import { DocumentSubjectService } from '../../../services/document-subject/document-subject.service';
import { DocumentTypeService } from '../../../services/document-type/document-type.service';
import { EcoDocumentSearchControlName } from '../../../services/eco-document-search-form/eco-document-search-form.model';
import { EcoDocumentSearchFormService } from '../../../services/eco-document-search-form/eco-document-search-form.service';
import { EcoDocumentSearch } from '../../../services/eco-document-search/eco-document-search.model';
import { NgbDateRange } from '../../../services/new-request/new-request.model';
import { DateRangePickerComponent } from '../../../shared/date-range-picker/date-range-picker.component';

@Component({
  templateUrl: './search-eco-document.component.html',
})
export class SearchEcoDocumentComponent implements OnInit, OnDestroy {
  searchDocumentFormGroup: UntypedFormGroup;
  ecoDocumentSearchControlName = EcoDocumentSearchControlName;
  documentTypes: DictionaryField[];
  documentSubjects: DictionaryField[];
  administrativeDivision: VoivodeshipDictionary[];
  filters: any; // @todo: filters: ApiFullFilter;
  @ViewChildren(DateRangePickerComponent)
  dateRangePickerComponents: QueryList<DateRangePickerComponent>;
  private isAlive = true;

  constructor(
    private ecoDocumentSearchFormService: EcoDocumentSearchFormService,
    private documentTypeService: DocumentTypeService,
    private documentSubjectService: DocumentSubjectService,
    private administrativeDivisionService: AdministrativeDivisionService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.fetchDocumentTypesList();
    this.fetchDocumentSubjectsList();
    this.fetchAdministrativeDivision();
    this.manageCountyFormControlActivity();
    this.manageCommunityFormControlActivity();
  }

  createForm(): void {
    this.searchDocumentFormGroup =
      this.ecoDocumentSearchFormService.getFormGroup();
  }

  fetchDocumentTypesList(): void {
    this.documentTypeService.documentTypes
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((documentTypes) => (this.documentTypes = documentTypes));
  }

  fetchDocumentSubjectsList(): void {
    this.documentSubjectService.documentSubjects
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (documentSubjects) => (this.documentSubjects = documentSubjects)
      );
  }

  fetchAdministrativeDivision(): void {
    this.administrativeDivisionService.administrativeDivision
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (administrativeDivision) =>
          (this.administrativeDivision = administrativeDivision)
      );
  }

  updateDateRangeFormControl(
    dateRange: NgbDateRange,
    formControlName: string
  ): void {
    this.getFormControlByName(formControlName).setValue(dateRange);
  }

  manageCountyFormControlActivity(): void {
    const countyFormControl = this.getCountyFormControl();
    this.getVoivodeshipFormControl()
      .valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => {
        if (data && data.counties && data.counties.length) {
          countyFormControl.enable();
        } else {
          countyFormControl.disable();
        }
        countyFormControl.reset();
      });
  }

  manageCommunityFormControlActivity(): void {
    const communityFormControl = this.getCommunityFormControl();
    this.getCountyFormControl()
      .valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => {
        if (data && data.communities && data.communities.length) {
          communityFormControl.enable();
        } else {
          communityFormControl.disable();
        }
        communityFormControl.reset();
      });
  }

  getCommunityFormControl(): AbstractControl {
    return this.getFormControlByName(
      this.ecoDocumentSearchControlName.Community
    );
  }

  getCountiesDictionary(): CountyDictionary[] {
    const formControlValue = this.getVoivodeshipFormControlValue();

    return formControlValue && formControlValue.counties;
  }

  getVoivodeshipFormControlValue(): VoivodeshipDictionary {
    return this.getVoivodeshipFormControl().value;
  }

  getVoivodeshipFormControl(): AbstractControl {
    return this.getFormControlByName(
      this.ecoDocumentSearchControlName.Voivodeship
    );
  }

  getCommunitiesDictionary(): DictionaryField[] {
    const formControlValue = this.getCountyFormControlValue();

    return formControlValue && formControlValue.communities;
  }

  getCountyFormControlValue(): CountyDictionary {
    return this.getCountyFormControl().value;
  }

  getCountyFormControl(): AbstractControl {
    return this.getFormControlByName(this.ecoDocumentSearchControlName.County);
  }

  getFormControlByName(formControlName: string): AbstractControl {
    return this.searchDocumentFormGroup.get(formControlName);
  }

  onFormSubmit(): void {
    this.filters = EcoDocumentSearch.fromAppToApi(this.getFormValue());
  }

  getFormValue(): EcoDocumentSearch {
    return this.searchDocumentFormGroup.value;
  }

  clearForm(): void {
    this.searchDocumentFormGroup.reset();
    this.resetDateRangePickerComponents();
  }

  resetDateRangePickerComponents(): void {
    this.dateRangePickerComponents.forEach((component) =>
      component.resetPeriod()
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
