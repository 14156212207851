@if (shouldShowAnyAlert()) {
  <div class="alert alert-danger">
    @if (shouldShowFieldIsRequiredAlert()) {
      <span>{{'GK.DYNAMIC_FORM.VALIDATION.FIELD_IS_REQUIRED' | translate}}</span>
    }
    @if (shouldShowFieldIsTooShortAlert()) {
      <span>{{'GK.DYNAMIC_FORM.VALIDATION.VALUE_IS_TOO_SHORT' | translate}}</span>
    }
    @if (shouldShowFieldIsTooLongAlert()) {
      <span>{{'GK.DYNAMIC_FORM.VALIDATION.VALUE_IS_TOO_LONG' | translate}}</span>
    }
  </div>
}
