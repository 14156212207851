import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GeometryControl } from '../../../../../controls';
import { MapPoint } from '../../../../../models';

@Component({
  selector: 'gk-point',
  templateUrl: './point.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PointComponent extends GeometryControl {
  @Input() override geometry: MapPoint;
}
