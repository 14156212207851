import {
  LawPersonAddress,
  LawPersonType,
  LegalPersonData,
  NaturalPersonData,
  getValueWhenPropertyIsAddressFields,
  getValueWhenPropertyIsString,
} from '@gk/gk-modules';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DateHelperUtil } from '../../utils/date-helper/date-helper.util';

export interface InvestorDetails {
  type: LawPersonType;
  naturalPersonData: NaturalPersonData;
  legalPersonData: LegalPersonData;
  idNumber: number;
  phoneNumber: number;
  email: string;
  birthDate: NgbDate;
  birthPlace: string;
  address: LawPersonAddress;
}

export class InvestorDetailsRequestDto {
  constructor(
    public Nazwisko: string,
    public Imie: string,
    public Nazwa: string,
    public Pesel: string,
    public Nip: string,
    public Regon: string,
    public Telefon: number,
    public Email: string,
    public NrDowodu: number,
    public MiejscId: string,
    public Miejsc: string,
    public UlicaId: string,
    public Ulica: string,
    public KodId: string,
    public Kod: string,
    public Poczta: string,
    public Numer: string,
    public Numer2: string,
    public Typ: LawPersonType,
    public DataUrodzenia: string,
    public MiejsceUrodzenia: string
  ) {}

  static fromAppToApi(
    investorDetails: InvestorDetails
  ): InvestorDetailsRequestDto {
    const petentData: NaturalPersonData & LegalPersonData = {
      ...investorDetails.naturalPersonData,
      ...investorDetails.legalPersonData,
    };
    const petentAddress: LawPersonAddress = investorDetails.address;

    return new this(
      petentData.lastName,
      petentData.firstName,
      petentData.name,
      petentData.pesel,
      petentData.nip,
      petentData.regon,
      investorDetails.phoneNumber,
      investorDetails.email,
      investorDetails.idNumber,
      getValueWhenPropertyIsAddressFields(petentAddress.place),
      getValueWhenPropertyIsString(petentAddress.place),
      getValueWhenPropertyIsAddressFields(petentAddress.street),
      getValueWhenPropertyIsString(petentAddress.street),
      getValueWhenPropertyIsAddressFields(petentAddress.postalCode),
      getValueWhenPropertyIsString(petentAddress.postalCode),
      petentAddress.postOffice,
      petentAddress.buildingNumber,
      petentAddress.localNumber,
      investorDetails.type,
      DateHelperUtil.ngbDateToApiDateTime(investorDetails.birthDate),
      investorDetails.birthPlace
    );
  }
}
