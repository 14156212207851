import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  LawPersonAddressControlName,
  LawPersonControlName,
  LawPersonType,
  LegalPersonControlName,
  MapState,
  NaturalPersonControlName,
  getDefaultControlValue,
} from '@gk/gk-modules';
import { DecisionFormType } from '../../excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { ShippingFormService } from '../../shipping-form/shipping-form.service';
import {
  BuildingWorksControlName,
  LaneOccupancyAreaControlName,
} from './building-works-form.model';

@Injectable()
export class BuildingWorksFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private shippingFormService: ShippingFormService
  ) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [BuildingWorksControlName.LawPerson]: this.getLawPersonFormGroup(),
      [BuildingWorksControlName.RoadNumber]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [BuildingWorksControlName.Place]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [BuildingWorksControlName.Street]: [getDefaultControlValue()],
      [BuildingWorksControlName.LaneOccupancyPurpose]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [BuildingWorksControlName.LaneOccupancyArea]: this.formBuilder.array([]),
      [BuildingWorksControlName.DecisionForm]: [
        DecisionFormType.NonElectronicForm,
        [Validators.required],
      ],
      [BuildingWorksControlName.ShippingForm]:
        this.shippingFormService.getFormGroup(),
    });
  }

  getLaneOccupancyAreaFormGroup(mapState: MapState): UntypedFormGroup {
    return this.formBuilder.group({
      [LaneOccupancyAreaControlName.RoadWidth]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [LaneOccupancyAreaControlName.Road]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [LaneOccupancyAreaControlName.RoadsideComponents]:
        getDefaultControlValue(),
      [LaneOccupancyAreaControlName.RoadLaneOtherElements]:
        getDefaultControlValue(),
      [LaneOccupancyAreaControlName.MapState]: mapState,
      [LaneOccupancyAreaControlName.DateRange]: null,
      [LaneOccupancyAreaControlName.CalculatedArea]: null,
    });
  }

  getLawPersonFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [LawPersonControlName.Type]: LawPersonType.Natural,
      [LawPersonControlName.LegalPersonData]: this.formBuilder.group({
        [LegalPersonControlName.Name]: [
          getDefaultControlValue(),
          Validators.required,
        ],
      }),
      [LawPersonControlName.NaturalPersonData]: this.formBuilder.group({
        [NaturalPersonControlName.FirstName]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [NaturalPersonControlName.LastName]: [
          getDefaultControlValue(),
          Validators.required,
        ],
      }),
      [LawPersonControlName.Address]: this.formBuilder.group({
        [LawPersonAddressControlName.Place]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [LawPersonAddressControlName.Street]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [LawPersonAddressControlName.StreetAbsence]: false,
        [LawPersonAddressControlName.BuildingNumber]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [LawPersonAddressControlName.LocalNumber]: [getDefaultControlValue()],
        [LawPersonAddressControlName.PostalCode]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [LawPersonAddressControlName.PostOffice]: [getDefaultControlValue()],
      }),
    });
  }
}
