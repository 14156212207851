import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { FileSaverService } from 'ngx-filesaver';
import { Observable, Subscription, throwError } from 'rxjs';
import { b64toBlob } from '../utils/files/files.util';
import { RequestType } from '../gk-dynamic-list/gk-dynamic-list.model';

interface ApiResponseError {
  ResponseStatus: {
    ErrorCode: string;
    Message: string;
    StackTrace: string;
    Errors: any[];
    Meta: null;
  };
}

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor(
    private httpClient: HttpClient,
    private fileSaverService: FileSaverService,
  ) {}

  downloadBlob(
    url: string,
    requestBody?: NonNullable<unknown>,
    method: RequestType = RequestType.Get,
  ): Observable<HttpResponse<Blob>> {
    if (method === 'POST') {
      return this.httpClient
        .post(url, requestBody, {
          responseType: 'blob',
          observe: 'response',
        })
        .pipe(catchError(this.handleApiError));
    } else {
      return this.httpClient
        .get(url, {
          responseType: 'blob',
          observe: 'response',
          params: requestBody ? this.toHttpParams(requestBody) : undefined,
        })
        .pipe(catchError(this.handleApiError));
    }
  }

  toHttpParams(obj: NonNullable<unknown>): HttpParams {
    return Object.getOwnPropertyNames(obj).reduce(
      (httpParams, key) => httpParams.set(key, obj[key as keyof typeof obj]),
      new HttpParams(),
    );
  }

  handleApiError = (err: ApiResponseError): Observable<never> => {
    console.error(err);
    return throwError(() => err);
  };

  getFileNameFromResponseHeaders(headers: HttpHeaders): string {
    return headers
      .get('Content-Disposition')
      .split('filename="')[1]
      .split('"')[0];
  }

  downloadAndSaveFile(
    url: string,
    requestBody: NonNullable<unknown>,
    method: RequestType = RequestType.Post,
  ): Subscription {
    return this.downloadBlob(url, requestBody, method).subscribe(
      (data): void => {
        const fileName = this.getFileNameFromResponseHeaders(data.headers);
        this.fileSaverService.save(data.body, fileName);
      },
    );
  }

  async saveBaseAsZip(docFile: string, fileName: string): Promise<void> {
    const b64 = await b64toBlob(docFile as string, 'application/zip');
    this.fileSaverService.save(b64 as Blob, fileName);
  }
}
