import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { MainRoutes, PzInitRoutes } from '../../guards/guard.models';
import { PortalConfigGetter } from '../../services/portal-config/portal-config.interface';
import { PortalConfig } from '../../services/portal-config/portal-config.model';
import { WebPortalService } from '../../services/web-portal/web-portal.service';

@Injectable({
  providedIn: 'root',
})
export class PresentationCommentsLandBuildingRegistryProjectConfigService
  implements PortalConfigGetter
{
  constructor(private webPortalService: WebPortalService) {}

  getPortalConfig(): Observable<PortalConfig> {
    return this.webPortalService
      .getWebPortalDataByCurrentRoute(
        MainRoutes.PresentationCommentsLandBuildingRegistryProject
      )
      .pipe(
        map((webPortal) => ({
          pzInitRoute:
            PzInitRoutes.PresentationCommentsLandBuildingRegistryProject,
          isPerunAuthenticationAvailable: webPortal.ewidLogging,
          isPzAuthenticationAvailable: webPortal.pzLogging,
          sessionToolsInfoAlertsVisible: true,
        }))
      );
  }
}
