import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ResetPasswordControlName } from './reset-password-form.model';

@Injectable()
export class ResetPasswordFormService {
  constructor(private formBuilder: UntypedFormBuilder) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [ResetPasswordControlName.Login]: this.formBuilder.control('', [
        Validators.required
      ])
    });
  }
}
