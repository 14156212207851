@if (shouldShowControl()) {
  <div gkControlRow>
    <span gkControlLabel [showLabel]="controlConfig.showLabel">
      <gk-translated-label [configLabel]="controlConfig.label"></gk-translated-label>:</span
      >
      <span gkControlValue [showLabel]="controlConfig.showLabel">
        {{ getDictValueOrValue() }}
        @for (button of controlConfig.buttons; track button) {
          @if (button.showIfValue === getValue()) {
            <gk-control-button
              [button]="button"
              [data]="data"
            ></gk-control-button>
          }
        }
      </span>
    </div>
  }
