export interface DefaultConfigFromApi {
  IsUserEditEnabled: boolean;
  WersjaEwida: number;
  IsPaymentEnabled: boolean;
}

export class DefaultConfig {
  constructor(
    public isUserEditEnabled: boolean,
    public ewidVersion: number,
    public isPaymentEnabled: boolean
  ) {}

  static fromApiToApp(data: DefaultConfigFromApi): DefaultConfig {
    return new this(
      data.IsUserEditEnabled,
      data.WersjaEwida,
      data.IsPaymentEnabled
    );
  }
}
