import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PortalAccessClientGuard } from '../../guards/portal-access-client/portal-access-client.guard';
import { PortalAccessGuard } from '../../guards/portal-access/portal-access.guard';
import { SessionGuard } from '../../guards/session/session.guard';
import { PaymentsListComponent } from '../../shared/payments-list/payments-list.component';
import { DesignerMapComponent } from './designer-map/designer-map.component';
import { DesignerPortalComponent } from './designer-portal.component';
import { NewDesignerRequestComponent } from './new-designer-request/new-designer-request.component';
import { RequestsListComponent } from './requests-list/requests-list.component';

const designerPortalRoutes: Routes = [
  {
    path: 'designer-portal',
    component: DesignerPortalComponent,
    canActivate: [SessionGuard, PortalAccessGuard, PortalAccessClientGuard],
    children: [
      {
        path: '',
        redirectTo: 'requests-list',
        pathMatch: 'full',
      },
      {
        path: 'new-designer-request',
        component: NewDesignerRequestComponent,
      },
      {
        path: 'requests-list',
        component: RequestsListComponent,
      },
      {
        path: 'map',
        component: DesignerMapComponent,
      },
      {
        path: 'payments',
        component: PaymentsListComponent,
      },
      {
        path: '**',
        redirectTo: 'new-designer-request',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(designerPortalRoutes)],
  providers: [SessionGuard, PortalAccessGuard, PortalAccessClientGuard],
  exports: [RouterModule],
})
export class DesignerPortalRoutingModule {}
