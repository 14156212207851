<div class="table-responsive">
  <table class="table table-sm text-center">
    <tbody>
      <tr class="border-bottom-header">
        <th class="color-bg-lightgrey m-2 border-bottom">
          {{ 'NAME_PATTERN_FILES.TYPE_OF_DOCUMENT' | translate }}
        </th>
        <td class="color-bg-lightgoldenrodyellow border-bottom">
          {{ 'NAME_PATTERN_FILES.PATTERN' | translate }}
        </td>
      </tr>
      @for (row of fileNamingPatterns; track row.Id) {
        <tr>
          <th scope="row" class="color-bg-lightgrey m-2">
            {{ row.Nazwa | translate }}:
          </th>
          <td class="color-bg-lightgoldenrodyellow">
            <span>{{
              row.Prefix || 'NAME_PATTERN_FILES.NO_PATTERN' | translate
            }}</span>
          </td>
        </tr>
      }
    </tbody>
  </table>
  <div>
    <span class="font-style-oblique">{{
      'NAME_PATTERN_FILES.USING_INFO' | translate
    }}</span>
  </div>
</div>
