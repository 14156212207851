export enum FeeWaiverControlName {
  FeeWaiverCause = 'feeWaiverCause',
  FeeWaiverForm = 'feeWaiverForm',
  FeeWaiverCheckbox = 'feeWaiverCheckbox',
  SocialAssistanceBecauseOfPoverty = 'socialAssistanceBecauseOfPoverty',
  Other = 'other'
}

export enum FeeWaiverCause {
  SocialAssistanceBecauseOfPoverty = 4,
  Other = 5
}

export interface FeeWaiverForm {
  feeWaiverCheckbox: boolean;
  feeWaiverCause: FeeWaiverCause;
}

export class FeeWaiverCauseDto {
  static fromAppToApi(data: FeeWaiverForm): FeeWaiverCause {
    return data.feeWaiverCheckbox ? data.feeWaiverCause : null;
  }
}
