import { Component, Input, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { TextModalControlConfig } from '../../../gk-dynamic-list.model';
import { DataService } from '../../../services/data/data.service';
import { Control } from '../control';

@Component({
  selector: 'gk-control-text-modal',
  templateUrl: './control-text-modal.component.html',
  styleUrls: ['./control-text-modal.component.scss'],
})
export class ControlTextModalComponent extends Control<string> {
  readonly threeDotsLength = 3;
  @Input()
  override controlConfig: TextModalControlConfig;

  constructor(
    private ngbModal: NgbModal,
    protected override translateService: TranslateService,
    protected override dataService: DataService,
  ) {
    super(translateService, dataService);
  }

  shouldOpen(): boolean {
    return !!this.getValue();
  }

  openModal(template: TemplateRef<NgbModalRef>): void {
    this.ngbModal.open(template);
  }

  getButtonText(): string {
    const maxLetters = this.controlConfig.maxLettersToShow;
    const btnText = this.getValue() || this.noData;

    return btnText.length > maxLetters
      ? `${btnText.substring(0, maxLetters - this.threeDotsLength)}...`
      : btnText;
  }
}
