import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { takeWhile } from 'rxjs/operators';
import {
  ColumnHeader,
  TableSelectionMode,
} from '../../../gk-components/table/table.model';
import { GkModalConfirmComponent } from '../../../gk-modal-confirm/gk-modal-confirm.component';
import { PendingInvoice } from '../../payments.model';
import { PaymentsService } from '../../payments.service';

@Component({
  selector: 'gk-pending',
  templateUrl: './pending.component.html',
})
export class PendingComponent implements OnInit, OnDestroy {
  private isAlive = true;
  invoices: PendingInvoice[] = [];
  selectedInvoices: PendingInvoice[] = [];
  columnSetting: ColumnHeader[];
  isInvoiceLoading: boolean;
  tableSelectionModeEnum = TableSelectionMode;
  cancelPaymentBodyTextTranslation: string;
  cancelPaymentNotificationTranslation: string;
  @Output()
  invoicesTotalCount = new EventEmitter<number>();

  constructor(
    private paymentsService: PaymentsService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.columnSetting = this.getColumnSettings();
    this.fetchPendingInvoices();
    this.subscribeToCancelPaymentBodyTextTranslation();
    this.subscribeToCancelPaymentNotificationTranslation();
  }

  subscribeToCancelPaymentBodyTextTranslation(): void {
    this.translateService
      .get('GK.PAYMENTS.TRANSACTION_CANCEL_BODY_TEXT')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (translation) => (this.cancelPaymentBodyTextTranslation = translation)
      );
  }

  subscribeToCancelPaymentNotificationTranslation(): void {
    this.translateService
      .get('GK.PAYMENTS.TRANSACTION_CANCEL_NOTIFICATION')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (translation) =>
          (this.cancelPaymentNotificationTranslation = translation)
      );
  }

  onInvoicesSelect(unpaidInvoices: PendingInvoice[]): void {
    this.selectedInvoices = unpaidInvoices;
  }

  onRefreshInvoices(): void {
    this.invoices = [];
    this.selectedInvoices = [];
    this.fetchPendingInvoices();
  }

  fetchPendingInvoices(): void {
    this.isInvoiceLoading = true;
    this.paymentsService
      .getPendingInvoices()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe({
        next: (invoices) => {
          this.invoices = invoices;
          this.invoicesTotalCount.emit(invoices.length);
          this.isInvoiceLoading = false;
        },
        error: (errorResponse: HttpErrorResponse) =>
          this.handleFailResponse(errorResponse),
      });
  }

  onCancelTransaction(): void {
    this.openCancelTransactionModal();
  }

  openCancelTransactionModal(): void {
    const modalRef = this.modalService.open(GkModalConfirmComponent)
      .componentInstance as GkModalConfirmComponent;
    modalRef.body = this.cancelPaymentBodyTextTranslation;
    modalRef.ok
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(() => this.cancelPaymentAjax());
  }

  cancelPaymentAjax(): void {
    this.paymentsService
      .cancelPayment(this.selectedInvoices[0].id)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe({
        next: () => {
          this.toastr.success(this.cancelPaymentNotificationTranslation);
          this.onRefreshInvoices();
        },
        error: (errorResponse: HttpErrorResponse) =>
          this.handleFailResponse(errorResponse),
      });
  }

  handleFailResponse(errorResponse: HttpErrorResponse): void {
    this.isInvoiceLoading = false;
    const errorMsg = _.get(errorResponse, 'error.ResponseStatus.Message');
    this.toastr.error(errorMsg);
  }

  getColumnSettings(): ColumnHeader[] {
    return [
      new ColumnHeader('id', 'GK.PAYMENTS.TRANSACTION_ID'),
      new ColumnHeader('feeDue', 'GK.PAYMENTS.FOR_VALUE_OF'),
      new ColumnHeader('paymentIssueData', 'GK.PAYMENTS.TRANSACTION_DATE'),
      new ColumnHeader('payment', 'GK.PAYMENTS.PAYMENTS'),
      new ColumnHeader('bankAccountNumber', 'GK.PAYMENTS.ACCOUNT_NUMBER'),
    ];
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
