import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  ColumnHeader,
  DocSignService,
  EgibObject,
  LawPersonSimpleFormComponent,
  MapId,
  MapObject,
  MapObjectApiType,
  MapObjectTableState,
  MapPortalName,
  MapSettings,
  MapSettingsService,
  MapState,
  MapStateService,
  PortalId,
  ToolType,
  markFormGroupsTouched,
} from '@gk/gk-modules';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { mergeMap, switchMap, takeWhile, tap } from 'rxjs';
import { AttachmentsComponent } from '../../../components/attachments/attachments.component';
import { MainRoutes } from '../../../guards/guard.models';
import { ApiNewDokumentPowiazanyDalDto } from '../../../services/designer-incoming-documents/designer-incoming-documents.model';
import { ZamowienieDzialkaDto } from '../../../services/excluding-land-from-agricultural-production/agricultural-land-use/agricultural-land-use.model';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { OwnershipConversionControlName } from '../../../services/ownership-conversion-form/ownership-conversion-form.model';
import { OwnershipConversionFormService } from '../../../services/ownership-conversion-form/ownership-conversion-form.service';
import { OwnershipConversion } from '../../../services/ownership-conversion/ownership-conversion.model';
import { BsMessageType } from '../../../services/request-workspace-state/request-workspace-state.model';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import { ShippingFormComponent } from '../../../shared/shipping-form/shipping-form.component';

@Component({
  selector: 'app-new-ownership-conversion-request',
  templateUrl: './new-ownership-conversion-request.component.html',
  styleUrls: ['./new-ownership-conversion-request.component.scss'],
})
export class NewOwnershipConversionRequestComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy
{
  override readonly controlName = OwnershipConversionControlName;
  override portalId = PortalId.OwnershipConversion;
  readonly mapObjectTableState = new MapObjectTableState(
    [
      new ColumnHeader('mapObjectNumber', 'PARCEL_SEARCH_FORM.PARCEL_NUMBER'),
      new ColumnHeader('districtName', 'PARCEL_SEARCH_FORM.DISTRICT'),
      new ColumnHeader('mapSheet', 'PARCEL_SEARCH_FORM.SHEET'),
      new ColumnHeader('typeName', 'GK.MAP.TYPE', true),
      new ColumnHeader('areaInSquareMeters', 'GK.MAP.AREA_IN_SQUARE_METERS'),
    ],
    undefined,
    this.defaultMapGeometryStyles,
    true,
    true,
    true,
    '25',
  );
  chosenFiles: { [key: string]: File[] } = {};
  @ViewChild(LawPersonSimpleFormComponent)
  lawPersonSimpleFormComponent: LawPersonSimpleFormComponent;
  @ViewChild(ShippingFormComponent)
  shippingFormComponent: ShippingFormComponent;
  @ViewChild(AttachmentsComponent) attachmentsComponent: AttachmentsComponent;

  constructor(
    private ownershipConversionFormService: OwnershipConversionFormService,
    private toastr: ToastrService,
    protected override newRequestHelperService: NewRequestHelperService,
    protected override docSignService: DocSignService,
    protected override router: Router,
    protected override translateService: TranslateService,
    protected override mapSettingsService: MapSettingsService,
    protected override mapStateService: MapStateService,
  ) {
    super(
      undefined,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService,
    );
  }

  override ngOnInit(): void {
    this.createForm();
    this.fetchWrongFileText();
    this.subscribeToDocSignTranslations();
    this.fetchApplicantMapSettings();
  }

  createForm(): void {
    this.formGroup = this.ownershipConversionFormService.getFormGroup();
    this.subscribeToDecisionFormRadioFormControl();
  }

  override setMapState(mapSettings: MapSettings): void {
    this.mapState = new MapState(
      MapId.OwnershipConversion,
      this.mapStateService.getViewState(MapId.OwnershipConversion, mapSettings),
      this.mapStateService.getToolbarState(
        [ToolType.AnyPolygon, ToolType.LandParcel],
        mapSettings.papers,
        undefined,
        undefined,
        true,
      ),
      this.initialToolsState,
      this.mapStateService.getLayersState(
        MapId.OwnershipConversion,
        mapSettings,
        MapPortalName.Applicant,
      ),
      [this.mapObjectTableState],
    );
  }

  isLawPersonFormGroupValid(): boolean {
    return this.getLawPersonFormGroup().valid;
  }

  getLawPersonFormGroup(): FormGroup {
    return this.formGroup.get(this.controlName.LawPerson) as FormGroup;
  }

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    markFormGroupsTouched(this.getLawPersonFormGroup() as UntypedFormGroup);
    if (!this.isRequestValid()) {
      return;
    }
    const apiFiles = await Promise.all(
      this.attachmentsComponent.getConvertedFiles(),
    ).catch(() => new Error());
    if (
      (Array.isArray(apiFiles) &&
        apiFiles.some((file) => file instanceof Error)) ||
      apiFiles instanceof Error
    ) {
      this.errorSubmitMessage = this.wrongFilesErrorText;
      this.toastr.error(this.wrongFilesErrorText);

      return;
    }

    this.sendRequest(apiFiles as ApiNewDokumentPowiazanyDalDto[]);
  }

  isRequestValid(): boolean {
    return (
      this.formGroup.valid &&
      this.attachmentsComponent.areDocumentsValid() &&
      this.areLandParcelsChosen()
    );
  }

  sendRequest(apiDocuments: ApiNewDokumentPowiazanyDalDto[]): void {
    this.lawPersonSimpleFormComponent
      .askForPostOfficeWhenPostalCodeIsNotFromDictionary()
      .pipe(
        mergeMap(() =>
          this.shippingFormComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
        ),
        tap(() => {
          this.docSignPending = true;
          this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');
        }),
        switchMap(() =>
          this.docSignService.addToSign(
            OwnershipConversion.fromAppToApi(
              this.getFormValue(),
              this.getIssueRanges(),
              this.getLandParcels(),
              apiDocuments,
            ),
            '/api/interesant/wniosek/przeksztalcenieWPrawoWlasnosci/addToSign',
          ),
        ),
        takeWhile(() => this.isAlive),
      )
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleSendAndValidateSuccess(addedDocToSignResponse);
        },
        error: () => this.handleSendAndValidateFailure(),
      });
  }

  getFormValue(): OwnershipConversion {
    return this.formGroup.getRawValue();
  }

  getIssueRanges(): string[] {
    return [...this.mapState.mapObjectTablesState[0].mapObjects]
      .filter(
        (mapObject) => mapObject.type === MapObjectApiType.ExtentOrPolygon,
      )
      .map((issueRange) => issueRange.geom);
  }

  getLandParcels(): ZamowienieDzialkaDto[] {
    return [...this.mapState.mapObjectTablesState[0].mapObjects]
      .filter(
        <(mapObject: MapObject) => mapObject is EgibObject>(
          ((mapObject) => mapObject.type === MapObjectApiType.LandParcel)
        ),
      )
      .map((data) => ZamowienieDzialkaDto.fromAppToApi(data));
  }

  areLandParcelsChosen(): boolean {
    return this.mapState.mapObjectTablesState[0].mapObjects.some(
      (mapObject) => mapObject.type === MapObjectApiType.LandParcel,
    );
  }

  override handleDocSignSuccess(): void {
    this.docSignUrl = '';
    this.router.navigateByUrl(
      `/${MainRoutes.OwnershipConversion}/requests-list`,
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
