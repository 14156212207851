@if (newsletterTopicsFormGroup) {
  <form
    class="mb-4"
    [formGroup]="newsletterTopicsFormGroup"
    (ngSubmit)="submitForm()"
  >
    <div class="container">
      <div class="row my-4">
        <div class="col-6">
          <div class="card bg-color-from-db">
            <h5 class="card-header">
              {{
                'ECO_PORTAL.SEARCH_DOCUMENT.FIELDS.DOCUMENT_TYPE' | translate
              }}
            </h5>
            <div class="list-group text-dark">
              @for (
                documentTypeControl of getDocumentTypesFormControls();
                track i;
                let i = $index
              ) {
                <div
                  class="form-check list-group-item"
                  [formArrayName]="ecoNewsletterControlName.Types"
                >
                  <input
                    id="document-type-{{ i }}"
                    type="checkbox"
                    [formControl]="documentTypeControl"
                  />
                  <label class="form-check-label" for="document-type-{{ i }}">
                    {{ documentTypes[i].name }}
                  </label>
                </div>
              }
            </div>
          </div>
          @if (
            shouldShowAtLeastOneRequiredFromArray(getDocumentTypesFormArray())
          ) {
            <div class="alert alert-danger">
              {{ 'AT_LEAST_ONE_CHECKBOX_CHECKED' | translate }}
            </div>
          }
        </div>
        <div class="col-6">
          <div class="card bg-color-from-db">
            <h5 class="card-header">
              {{
                'ECO_PORTAL.SEARCH_DOCUMENT.FIELDS.DOCUMENT_SUBJECT' | translate
              }}
            </h5>
            <div class="list-group text-dark">
              @for (
                documentSubjectControl of getDocumentSubjectsFormControls();
                track i;
                let i = $index
              ) {
                <div
                  class="form-check list-group-item"
                  [formArrayName]="ecoNewsletterControlName.Subjects"
                >
                  <input
                    id="document-subject-{{ i }}"
                    type="checkbox"
                    [formControl]="documentSubjectControl"
                  />
                  <label
                    class="form-check-label"
                    for="document-subject-{{ i }}"
                  >
                    {{ documentSubjects[i].name }}
                  </label>
                </div>
              }
            </div>
          </div>
          @if (
            shouldShowAtLeastOneRequiredFromArray(
              getDocumentSubjectsFormArray()
            )
          ) {
            <div class="alert alert-danger">
              {{ 'AT_LEAST_ONE_CHECKBOX_CHECKED' | translate }}
            </div>
          }
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12">
          <div class="card border-color-from-db">
            <div class="card-body text-color-from-db">
              <p class="card-text">
                {{ 'ECO_PORTAL.NEWSLETTER_INFO' | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12 form-group">
          <div class="card text-white bg-color-from-db">
            <h5 class="card-header">
              <label class="mb-0" for="newsletter-email">
                {{ 'ECO_PORTAL.EMAIL_PLACEHOLDER' | translate }}
              </label>
            </h5>
            <input
              id="newsletter-email"
              class="form-control form-control-lg"
              [formControlName]="ecoNewsletterControlName.Email"
              type="email"
              placeholder="{{ 'ECO_PORTAL.EMAIL_PLACEHOLDER' | translate }}"
            />
          </div>
          @if (shouldShowRequiredAlert(getEmailFormControl())) {
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          }
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button type="submit" class="btn btn-color-from-db btn-lg float-end">
            @if (!loading) {
              <span>{{ 'ECO_PORTAL.SUBMIT' | translate }}</span>
            }
            @if (loading) {
              <i class="fa fa-spinner fa-pulse fa-fw"></i>
            }
            @if (loading) {
              <span class="sr-only">{{ 'LOADING' | translate }}</span>
            }
          </button>
        </div>
      </div>
    </div>
  </form>
}
