<div class="modal-header">
  <h4 class="modal-title">{{ getFormHeader() | translate }}</h4>
  <button
    type="button"
    class="btn-close"
    [attr.aria-label]="'GK.MAP.CLOSE' | translate"
    [title]="'GK.MAP.CLOSE' | translate"
    (click)="closeModal(false)"
  ></button>
</div>
<div class="modal-body">
  <gk-dynamic-form
    #formContainer
    [fieldConfigs]="getFieldConfigs()"
  ></gk-dynamic-form>
  <div class="form-input">
    <label for="fileInput">{{
      'GK.MAP.SELECT_FILE_WITH_GEOMETRY_IN_FORMAT' | translate
    }}</label>
    <input
      id="fileInput"
      type="file"
      name="filesInput"
      class="form-control"
      (change)="handleFileInputChange($event)"
    />
  </div>
  <p>
    {{ 'GK.MAP.COORDINATE_SYSTEM' | translate }}:
    {{
      mapStateService.getCoordinateSystemDescription(
        mapState.viewState.nativeProjectionCode
      ) | translate
    }}
    |
    {{ 'GK.MAP.ENCLAVE_OMISSION' | translate }}
  </p>
  @if (fileList) {
    <p>
      <i [ngClass]="getValidationStatus(isValidFormat)" aria-hidden="true"></i>
      {{
        (isValidFormat ? 'GK.MAP.PROPER_FORMAT' : 'GK.MAP.WRONG_FORMAT')
          | translate
      }}
    </p>
    <p>
      <i
        [ngClass]="getValidationStatus(isValidGeometryType)"
        aria-hidden="true"
      ></i>
      {{
        (isValidGeometryType
          ? 'GK.MAP.PROPER_GEOMETRY_TYPE'
          : 'GK.MAP.WRONG_GEOMETRY_TYPE'
        ) | translate
      }}
    </p>
    <p>
      <i [ngClass]="getValidationStatus(isValidRange)" aria-hidden="true"></i>
      {{
        (isValidRange
          ? 'GK.MAP.GEOMETRY_FITS_EXTENT'
          : 'GK.MAP.GEOMETRY_OUT_OF_EXTENT'
        ) | translate
      }}
    </p>
    <p>
      <i
        [ngClass]="getValidationStatus(isValidTopology)"
        aria-hidden="true"
      ></i>
      {{
        (isValidTopology ? 'GK.MAP.TOPOLOGY_VALID' : 'GK.MAP.TOPOLOGY_ERROR')
          | translate
      }}
    </p>
  }
</div>
<div class="modal-footer flex-row-reverse justify-content-between">
  <button
    type="button"
    class="btn btn-outline-color-from-db"
    [attr.aria-label]="'GK.MAP.LOAD_TO_THE_MAP' | translate"
    (click)="closeModal(true)"
    [disabled]="!isValid()"
  >
    {{ 'GK.MAP.LOAD_TO_THE_MAP' | translate }}
  </button>
  @if (showFormatTxt) {
    <div class="flex-column">
      <span
        ><i>{{ 'GK.MAP.PAPER_SIZE' | translate }}: </i>"{{
          'GK.MAP.EXAMPLE_TXT_FORMAT' | translate
        }}"</span
      >
      <span
        ><i>{{ 'GK.MAP.EXAMPLE_WORD' | translate }}: </i>"{{
          'GK.MAP.EXAMPLE_COORDS' | translate
        }}"</span
      >
    </div>
  }
</div>
