import { AbstractControl, FormArray, ValidatorFn } from '@angular/forms';

export function atLeastOneArrayValidator(): ValidatorFn {
  return (controlArray: AbstractControl): { [key: string]: boolean } | null => {
    if (!(<FormArray>controlArray).controls) {
      return null;
    }
    const isValidControl = (<FormArray>controlArray).controls.some(
      (control) => control.value
    );

    return isValidControl ? null : { atLeastOneRequiredFromArray: true };
  };
}
