import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable()
export class UiVerboseService {
  constructor(private translateService: TranslateService) {}

  getUiBoolean(booleanValue: boolean): Observable<string> {
    return this.translateService.get(booleanValue ? 'YES' : 'NO');
  }
}
