export class ColumnHeader {
  constructor(
    public pathToValue: string,
    public label: string,
    public visible = true,
    public columnWidth = '',
    public wrapCellConfig = new WrapCellConfig(),
  ) {}
}

export class WrapCellConfig {
  constructor(
    public wrapContent = false,
    public characterLimitUntilWrap = 0,
  ) {}
}

export enum TableSelectionMode {
  Single = 'SINGLE',
  Multiple = 'MULTIPLE',
}
