import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as _ from 'lodash';

const isNipValid = (maybeNip: string): boolean => {
  if (
    !maybeNip ||
    !_.isString(maybeNip) ||
    !/^[0-9-]{10,13}$/.test(maybeNip) ||
    maybeNip === '0000000000'
  ) {
    return false;
  }

  maybeNip = maybeNip.replace(/[^0-9]/gi, '');

  if (!/^[0-9]{10}$/.test(maybeNip)) {
    return false;
  }

  const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];

  const totalSum = weights.reduce(
    (sum, nextWeight, i) => sum + +maybeNip[i] * nextWeight,
    0
  );

  return totalSum % 11 === +maybeNip[9];
};

export function nipValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!control.value) {
      return null;
    }

    return isNipValid(control.value) ? null : { nip: true };
  };
}
