<div class="container">
  <div class="row justify-content-center">
    <div class="col text-right">
      <label [attr.for]="paperOptionPropertyEnum.Orientation"
        >{{ 'GK.MAP.ORIENTATION' | translate }}:</label
      >
    </div>
    <div class="col">
      <gk-paper-dropdown
        [papers]="mapState.toolbarState.papers"
        [allowedPaper]="formValue.paper"
        [optionProperty]="paperOptionPropertyEnum.Orientation"
        (orientationChange)="handleMapSheetPaperOrientationChange($event)"
      ></gk-paper-dropdown>
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="col text-right">
      <label [attr.for]="paperOptionPropertyEnum.Name"
        >{{ 'GK.MAP.PAPER_SIZE' | translate }}:</label
      >
    </div>
    <div class="col">
      <gk-paper-dropdown
        [papers]="mapState.toolbarState.papers"
        [allowedPaper]="formValue.paper"
        [restriction]="paperRestriction"
        [optionProperty]="paperOptionPropertyEnum.Name"
        (nameChange)="handleMapSheetPaperNameChange($event)"
      ></gk-paper-dropdown>
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="col text-right">
      <label for="scale">{{ 'GK.MAP.SCALE' | translate }}:</label>
    </div>
    <div class="col">
      <gk-scale-dropdown
        [scales]="allAvailableScales"
        [allowedResolution]="formValue.scale.resolution"
        [restriction]="scaleRestriction"
        (resolutionChange)="handleMapSheetResolutionChange($event)"
      ></gk-scale-dropdown>
    </div>
  </div>
</div>
