<div class="sidebar bg-white">
  <button
    [id]="id"
    type="button"
    class="fa fa-lg fa-chevron-left collapse-button"
    [title]="'GK.MAP.COLLAPSE_LAYERS_TREE' | translate"
    [attr.aria-label]="'GK.MAP.COLLAPSE_LAYERS_TREE' | translate"
    (click)="handleToggleSidebar()"
    [elementFocus]="elementFocusTrigger"
  ></button>
  <div class="layers-tree-view">
    <gk-tree-node
      [providedData]="mapState.layersState.treeNode"
      (dispatch)="handleTreeNodeState($event)"
    ></gk-tree-node>
  </div>
</div>
