<div class="table-responsive table-bordered text-center">
  <table class="table table-sm mb-0" [formGroup]="designerRequestForm">
    <thead class="thead-light">
      <tr>
        <th scope="col">#</th>
        <th scope="col">{{ 'CONNECTIONS_AND_NETWORKS.TYPE' | translate }}</th>
        <th scope="col">
          {{ 'CONNECTIONS_AND_NETWORKS.QUANTITY' | translate }}
        </th>
        <th scope="col">{{ 'CONNECTIONS_AND_NETWORKS.DELETE' | translate }}</th>
      </tr>
    </thead>
    <tbody formArrayName="connectionAndNetworkGroupArray">
      @for (
        connectionAndNetworkGroup of connectionAndNetworkGroupArray.controls;
        track i;
        let i = $index
      ) {
        <tr>
          <ng-container [formGroupName]="i">
            <th scope="row">{{ i + 1 }}</th>
            <td>
              {{
                connectionAndNetworkGroup.get('connectionAndNetwork').value.name
              }}
            </td>
            <td class="w-80p">
              <input
                [title]="'CONNECTIONS_AND_NETWORKS.QUANTITY' | translate"
                type="number"
                formControlName="quantity"
                min="1"
                class="form-control form-control-sm positions-quantity required"
                aria-required="true"
              />
            </td>
            <td class="w-80p">
              <button
                type="button"
                class="btn btn-danger fa fa-times"
                attr.aria-label="{{
                  'CONNECTIONS_AND_NETWORKS.DELETE' | translate
                }}"
                [title]="'CONNECTIONS_AND_NETWORKS.DELETE' | translate"
                (click)="deleteConnectionAndNetwork(i)"
              ></button>
            </td>
          </ng-container>
        </tr>
      }
    </tbody>
  </table>
</div>

<button type="button" class="btn btn-sm btn-danger mt-1" (click)="deleteAll()">
  {{ 'CONNECTIONS_AND_NETWORKS.DELETE_ALL' | translate }}
</button>
