@if (plannedApplicationMessageService.getWeServiceInfoKeyOnOff() | async) {
  <button
    type="button"
    class="btn btn-color-from-db btn-md action-button service-button-size"
    [title]="infoServiceButtonNavItemConfig.title | translate"
    [attr.aria-label]="infoServiceButtonNavItemConfig.buttonLabel | translate"
    (click)="showInfoService()"
    >
    <span [ngClass]="getIconClassSet()"></span>
  </button>
}
