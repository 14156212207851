<app-authorized-person-to-registry-view-form
  [isAuthorizedPersonWithLoggedUserDuplicatePrevent]="
    isAuthorizedPersonWithLoggedUserDuplicatePrevent
  "
  [shouldBeMarkedAsRequired]="!authorizedPersonsToRegistryView.length"
  (newAuthorizedPersonToRegistryView)="addNewUniquePersonToList($event)"
></app-authorized-person-to-registry-view-form>
<app-authorized-person-to-registry-view-list
  [showInitialEmptyRequiredRow]="showInitialEmptyRequiredRow"
  [authorizedPersonsToRegistryView]="authorizedPersonsToRegistryView"
  (authorizedPersonsToRegistryViewChange)="
    handleAuthorizedPersonsToRegistryView($event)
  "
></app-authorized-person-to-registry-view-list>
