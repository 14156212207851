import { Component, Input } from '@angular/core';
import * as _ from 'lodash';

import { ArrayControlConfig } from '../../../gk-dynamic-list.model';
import { Control } from '../control';

@Component({
  selector: 'gk-control-array',
  templateUrl: './control-array.component.html',
})
export class ControlArrayComponent extends Control<any[]> {
  @Input() override controlConfig: ArrayControlConfig;

  getArray(): any[] {
    return _.get(this.data, this.controlConfig.pathToValue, []);
  }
}
