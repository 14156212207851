<aol-control>
  <aol-content>
    <div class="ol-unselectable ol-control pin-button">
      <button
        type="button"
        class="fa fa-thumb-tack"
        [ngClass]="{
          'fa-rotate-90': mapState.viewState.previewModeState.enabled
        }"
        (click)="changeToggle()"
        title="{{ 'GK.MAP.HELP_CONTROL.MAP_PIN' | translate }}"
        [attr.aria-label]="'GK.MAP.HELP_CONTROL.MAP_PIN' | translate"
      ></button>
    </div>
  </aol-content>
</aol-control>
