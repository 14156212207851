import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RequestInProgressGuard } from '../../guards/request-in-progress/request-in-progress.guard';
import { SessionGuard } from '../../guards/session/session.guard';
import { MainRequestsListComponent } from '../../shared/main-requests-list/main-requests-list.component';
import { BuildPermissionRequestComponent } from './build-permission-request/build-permission-request.component';
import { RoadExitPortalComponent } from './road-exit-portal.component';

const roadExitPortalRoutes: Routes = [
  {
    path: 'road-exit-portal',
    component: RoadExitPortalComponent,
    canActivate: [SessionGuard],
    children: [
      {
        path: '',
        redirectTo: 'build-permission-request',
        pathMatch: 'full',
      },
      {
        path: 'build-permission-request',
        component: BuildPermissionRequestComponent,
        canDeactivate: [RequestInProgressGuard],
      },
      {
        path: 'requests-list',
        component: MainRequestsListComponent,
      },
      {
        path: 'requests-list',
        component: MainRequestsListComponent,
      },
      {
        path: '**',
        redirectTo: 'build-permission-request',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(roadExitPortalRoutes)],
  providers: [SessionGuard, RequestInProgressGuard],
  exports: [RouterModule],
})
export class RoadExitPortalRoutingModule {}
