<ng-template #modalContent>
  <div class="modal-header">
    <h4 class="modal-title">
      {{
      'OFFICE_DEPARTMENTS_PORTAL.GEODESY.MODAL_DOCUMENT_VERIFICATION.TITLE' | translate
      }}
    </h4>
  </div>
  <div class="modal-body">
    <app-document-verification-form
      (documentNotFoundEvent)="onDocumentNotFoundEvent($event)"
      >
    </app-document-verification-form>
  </div>
  <div class="modal-footer">
    <div class="mx-auto">
      @if (!documentNotFound) {
        <button
          type="button"
          class="btn btn-secondary exit-doc-verification"
          (click)="onExit()"
          >
          {{
          'OFFICE_DEPARTMENTS_PORTAL.GEODESY.MODAL_DOCUMENT_VERIFICATION.EXIT' | translate
          }}
        </button>
      }
    </div>
  </div>
</ng-template>
