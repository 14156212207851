export interface OfficeDepartmentResponseDto {
  Id: number;
  Nazwa: string;
  Kod: string;
}

export class OfficeDepartment {
  constructor(public id: number, public name: string, public code: string) {}
  static fromApiToApp(
    officeDepartmentResponseDto: OfficeDepartmentResponseDto
  ): OfficeDepartment {
    return new this(
      officeDepartmentResponseDto.Id,
      officeDepartmentResponseDto.Nazwa,
      officeDepartmentResponseDto.Kod
    );
  }
}
