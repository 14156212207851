import { ValidatorFn, AbstractControl } from '@angular/forms';

const isRegonValid = (maybeRegon: string): boolean => {
  const len = (maybeRegon || '').length;

  if (
    !maybeRegon ||
    !(len === 9 || len === 14) ||
    !/^\d+$/.test(maybeRegon) ||
    maybeRegon === '00000000000000' ||
    maybeRegon === '000000000'
  ) {
    return false;
  }

  const weights =
    len === 9 ? [8, 9, 2, 3, 4, 5, 6, 7] : [2, 4, 8, 5, 0, 9, 7, 3, 6, 1, 2, 4, 8];

  let cd = 0;

  for (let i = 0; i < len - 1; i++) {
    cd += weights[i] * +maybeRegon.charAt(i);
  }

  cd %= 11;

  if (cd === 10) {
    cd = 0;
  }

  return cd === +maybeRegon.charAt(len - 1);
};

export function regonValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!control.value) {
      return null;
    }

    return isRegonValid(control.value) ? null : { regon: true };
  };
}
