import { Injectable, Inject } from '@angular/core';

@Injectable()
export class AppLocalStorageService {
  constructor(@Inject('window') private window: Window) {}

  getLocalStorage(): Storage {
    return this.window.localStorage;
  }
}
