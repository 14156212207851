import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable()
export class FormAlertService {
  private hasError(
    abstractControl: AbstractControl,
    errorName: string
  ): boolean {
    return !!(
      abstractControl &&
      abstractControl.errors &&
      abstractControl.errors[errorName]
    );
  }

  private isFieldReadyToShowAlert(
    abstractControl: AbstractControl,
    wasValidated: boolean
  ): boolean {
    return !!(
      abstractControl &&
      (abstractControl.dirty || abstractControl.touched || wasValidated)
    );
  }

  shouldShowErrorAlert(
    abstractControl: AbstractControl,
    errorName: string,
    wasValidated: boolean
  ): boolean {
    return (
      this.isFieldReadyToShowAlert(abstractControl, wasValidated) &&
      this.hasError(abstractControl, errorName)
    );
  }

  isRequired(abstractControl: AbstractControl): boolean {
    if (!abstractControl || !abstractControl.validator) {
      return false;
    }
    const validators = abstractControl.validator({} as AbstractControl);

    return !!(validators && validators['required']);
  }
}
