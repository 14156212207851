import { Injectable } from '@angular/core';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { landParcelSearchFormFieldGroup } from '../../form-field-groups/land-parcel-search-form-field-group';
import { getAddressSearchFormFieldGroup } from '../../form-field-groups/address-search-form-field-group';
import { StreetService } from '../../../../gk-dynamic-form/services/street/street.service';
import { PlaceService } from '../../../../gk-dynamic-form/services/place/place.service';
import { LandAndMortgageRegisterDepartmentService } from '../../../../gk-dynamic-form/form-fields/form-select/land-and-mortgage-register-department/services/land-and-mortgage-register/land-and-mortgage-register-department.service';
import { getLandAndMortgageRegisterFormFieldGroup } from '../../form-field-groups/land-and-mortgage-register-form-field-group';

@Injectable({
  providedIn: 'root',
})
export class LandParcelSearchFormService {
  constructor(
    private placeService: PlaceService,
    private streetService: StreetService,
    private landAndMortgageRegisterDepartmentService: LandAndMortgageRegisterDepartmentService
  ) {}

  getFormConfig(): {
    fields: FormlyFieldConfig[];
    options?: FormlyFormOptions;
  } {
    const fields = [
      landParcelSearchFormFieldGroup,
      getAddressSearchFormFieldGroup(this.placeService, this.streetService),
      getLandAndMortgageRegisterFormFieldGroup(
        this.landAndMortgageRegisterDepartmentService
      ),
    ];

    return { fields };
  }
}
