import { EgibObject, MapObject, Street } from '@gk/gk-modules';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import {
  InvestorDetails,
  InvestorDetailsRequestDto,
} from '../../../shared/investor-details/investor-details.model';
import {
  Shipping,
  ShippingDto,
} from '../../../shared/shipping-form/shipping-form.model';
import { DateHelperUtil } from '../../../utils/date-helper/date-helper.util';
import { ApiNewDokumentPowiazanyDalDto } from '../../designer-incoming-documents/designer-incoming-documents.model';
import { DecisionFormType } from '../../excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { ZamowienieDzialkaDto } from '../../excluding-land-from-agricultural-production/agricultural-land-use/agricultural-land-use.model';
import { Place } from '../../place/place.model';
import { FellingPurpose } from '../trees-and-shrubs-felling-form/trees-and-shrubs-felling-form.model';

export interface TreesAndShrubsFelling {
  investorDetails: InvestorDetails;
  proxyDetails: InvestorDetails;
  proxyDetailsCheckbox: boolean;
  attachments: ApiNewDokumentPowiazanyDalDto;
  decisionForm: DecisionFormType;
  shippingForm: Shipping;
  treesList: Tree[];
  shrubsList: Shrub[];
  businessPurpose: FellingPurpose;
  fellingCause: string;
  fellingDate: NgbDate;
  growthPlace: string;
  investmentDescription: string;
  landParcel: ZamowienieDzialkaDto;
  fellingPlace: Place;
  fellingStreet: Street;
  landRegister: string;
  fellingLandNumber: string;
}

export interface ObjectForFelling {
  inventoryNumber: string;
  speciesName: string;
}

export interface Tree extends ObjectForFelling {
  trunkCircumference: number;
}

export interface Shrub extends ObjectForFelling {
  projectionSurface: number;
}

export abstract class ObjectForFellingRequestDto {
  constructor(public NrInwentaryzacyjny: string, public NazwaGatunku: string) {}
}

export class TreeRequestDto extends ObjectForFellingRequestDto {
  constructor(
    public override NrInwentaryzacyjny: string,
    public override NazwaGatunku: string,
    public ObwodPnia: number
  ) {
    super(NrInwentaryzacyjny, NazwaGatunku);
  }

  static fromApiToApp(data: Tree): TreeRequestDto {
    return new this(
      data.inventoryNumber,
      data.speciesName,
      data.trunkCircumference
    );
  }
}

export class ShrubRequestDto extends ObjectForFellingRequestDto {
  constructor(
    public override NrInwentaryzacyjny: string,
    public override NazwaGatunku: string,
    public PowierzchniaRzutu: number
  ) {
    super(NrInwentaryzacyjny, NazwaGatunku);
  }

  static fromApiToApp(data: Shrub): ShrubRequestDto {
    return new this(
      data.inventoryNumber,
      data.speciesName,
      data.projectionSurface
    );
  }
}

export class TreesAndShrubsFellingRequestDto {
  constructor(
    public Wnioskodawca: InvestorDetailsRequestDto,
    public Pelnomocnik: InvestorDetailsRequestDto,
    public Zalaczniki: ApiNewDokumentPowiazanyDalDto[],
    public FormaDecyzji: number,
    public Dzialki: ZamowienieDzialkaDto[],
    public Odbior: ShippingDto,
    public MiejscId: number | string,
    public Miejsc: string,
    public UlicaId: number | string,
    public Ulica: string,
    public Numer: string,
    public NrKw: string,
    public PrzyczynaUsuniecia: string,
    public MiejsceRosniecia: string,
    public CelZwiazanyZDzialalnosciaGospodarcza: boolean,
    public PlanowanyTermin: string,
    public OpisInwestycji: string,
    public Drzewa: TreeRequestDto[],
    public Krzewy: ShrubRequestDto[],
    public Geom: string[]
  ) {}
  static fromAppToApi(
    data: TreesAndShrubsFelling,
    parcels: EgibObject[],
    issueRanges: MapObject[],
    attachments: ApiNewDokumentPowiazanyDalDto[]
  ): TreesAndShrubsFellingRequestDto {
    return new this(
      InvestorDetailsRequestDto.fromAppToApi(data.investorDetails),
      data.proxyDetailsCheckbox
        ? InvestorDetailsRequestDto.fromAppToApi(data.proxyDetails)
        : null,
      attachments,
      data.decisionForm,
      parcels.map((element) => ZamowienieDzialkaDto.fromAppToApi(element)),
      ShippingDto.fromAppToApi(data.shippingForm),
      data.fellingPlace.id,
      data.fellingPlace.name,
      data.fellingStreet && data.fellingStreet.id,
      data.fellingStreet && data.fellingStreet.name,
      data.fellingLandNumber,
      data.landRegister,
      data.fellingCause,
      data.growthPlace,
      data.businessPurpose === FellingPurpose.Business,
      DateHelperUtil.ngbDateToApiDateTime(data.fellingDate),
      data.investmentDescription,
      data.treesList.map((tree) => TreeRequestDto.fromApiToApp(tree)),
      data.shrubsList.map((shrub) => ShrubRequestDto.fromApiToApp(shrub)),
      issueRanges.map((range) => range.geom)
    );
  }
}
