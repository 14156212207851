import { AbstractControl } from '@angular/forms';

const isIpValid = (maybeIp: string): boolean => {
  const regExp = new RegExp(
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
  );

  return regExp.test(maybeIp);
};

export function ValidateIp(control: AbstractControl) {
  return isIpValid(control.value) ? null : { validIp: true };
}
