import { Injectable } from '@angular/core';
import { Observable, filter, map, switchMap } from 'rxjs';
import { AppTitleService } from '../../services/app-title/app-title.service';
import { PortalConfigGetter } from '../../services/portal-config/portal-config.interface';
import { PortalConfig } from '../../services/portal-config/portal-config.model';
import { WebPortalService } from '../../services/web-portal/web-portal.service';

@Injectable({
  providedIn: 'root',
})
export class PlanningDepartmentConfigService
  implements PortalConfigGetter
{
  constructor(private appTitleService: AppTitleService, private webPortalService: WebPortalService) {}

  getPortalConfig(): Observable<PortalConfig> {
    return this.appTitleService.currentPortal.pipe(
      filter((portalRoute) => !!portalRoute),
      switchMap((portalRoute) =>
        this.webPortalService.getWebPortalDataByCurrentRoute(portalRoute)
      ),
        map((webPortal) => ({
          departmentId: webPortal.departmentId,
        }))
      );
  }
}
