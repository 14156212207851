import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GeometryControl } from '../../../../../controls';
import { MapLineString } from '../../../../../models';

@Component({
  selector: 'gk-line-string',
  templateUrl: './line-string.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineStringComponent extends GeometryControl {
  @Input() override geometry: MapLineString;
}
