import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainRoutes } from '../../guards/guard.models';
import { SessionGuard } from '../../guards/session/session.guard';
import { MainRequestsListComponent } from '../../shared/main-requests-list/main-requests-list.component';
import { ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestComponent } from './extract-certificate-from-local-spatial-development-plan-or-study-request/extract-certificate-from-local-spatial-development-plan-or-study-request.component';
import { PlanningDepartmentComponent } from './planning-department.component';

const routes: Routes = [
  {
    path: MainRoutes.PlanningDepartmentExtractFromLocalSpatialDevelopmentPlan,
    component: PlanningDepartmentComponent,
    canActivate: [SessionGuard],
    children: [
      {
        path: '',
        redirectTo: 'requests-list',
        pathMatch: 'full',
      },
      {
        path: 'new-request',
        component:
          ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestComponent,
      },
      {
        path: 'requests-list',
        component: MainRequestsListComponent,
      },
      {
        path: '**',
        redirectTo: 'requests-list',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: MainRoutes.PlanningDepartmentCertificateFromLocalSpatialDevelopmentPlan,
    component: PlanningDepartmentComponent,
    canActivate: [SessionGuard],
    children: [
      {
        path: '',
        redirectTo: 'requests-list',
        pathMatch: 'full',
      },
      {
        path: 'new-request',
        component:
          ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestComponent,
      },
      {
        path: 'requests-list',
        component: MainRequestsListComponent,
      },
      {
        path: '**',
        redirectTo: 'requests-list',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: MainRoutes.PlanningDepartmentExtractFromStudy,
    component: PlanningDepartmentComponent,
    canActivate: [SessionGuard],
    children: [
      {
        path: '',
        redirectTo: 'requests-list',
        pathMatch: 'full',
      },
      {
        path: 'new-request',
        component:
          ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestComponent,
      },
      {
        path: 'requests-list',
        component: MainRequestsListComponent,
      },
      {
        path: '**',
        redirectTo: 'requests-list',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PlanningDepartmentRoutingModule {}
