@if (pdfSrc) {
  <ng2-pdfjs-viewer [pdfSrc]="pdfSrc"></ng2-pdfjs-viewer>
}
@if (imgSrc) {
  <div>
    <img
      [src]="imgSrc"
      [alt]="'PDF_VIEWER.PREVIEW_DOCUMENT_ALT_TEXT' | translate"
      class="img-fluid"
    />
  </div>
}
@if (textSrc) {
  <div>
    <pre>{{ textSrc }}</pre>
  </div>
}
