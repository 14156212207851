<button
  type="button"
  class="btn btn-outline-color-from-db btn-sm bg-white text-secondary"
  [ngClass]="rangeGenerationInProgress ? '' : 'gk-map-icon-range-from-parcels'"
  [title]="'GK.MAP.RANGE_FROM_PARCELS' | translate"
  [attr.aria-label]="'GK.MAP.RANGE_FROM_PARCELS' | translate"
  [disabled]="rangeGenerationInProgress"
  (click)="createRangeFromParcels()"
  >
  @if (rangeGenerationInProgress) {
    <i
      class="fa fa-spinner fa-pulse fa-fw"
      aria-hidden="true"
    ></i>
  }
</button>
