import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiResponse } from '../../../services';
import { Place, PlaceFromApi } from './place.model';

@Injectable()
export class PlaceService {
  constructor(private http: HttpClient) {}

  getPlacesByTerm(
    term: string,
    placeUrlTypeahead = '/api/interesant/slo/miejscowosci/global/cachedautocomplete',
  ): Observable<Place[]> {
    return this.http
      .post<PlaceFromApi[]>(placeUrlTypeahead, {
        Set: 'global',
        filter: {
          filters: {
            field: 'Nazwa',
            ignoreCase: true,
            operator: 'startswith',
            value: term,
          },
        },
      })
      .pipe(
        map((placesFromApi) =>
          placesFromApi.map((placeFromApi) => Place.fromApiToApp(placeFromApi)),
        ),
      );
  }

  getPlacesOfDistrict(districtId: string | number): Observable<Place[]> {
    return this.http
      .get<
        ApiResponse<PlaceFromApi[]>
      >(`/api/interesant/slo/obreb/${districtId}/miejscowosci`)
      .pipe(
        map((placesFromApi) =>
          placesFromApi.Response.map((placeFromApi) =>
            Place.fromApiToApp(placeFromApi),
          ),
        ),
      );
  }
}
