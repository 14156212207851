<div class="dynamic-field" [formGroup]="group">
  <label for="sheetSelect">{{ config.label | translate }}</label>
  <select
    id="sheetSelect"
    class="form-select"
    [formControlName]="config.name"
    [ngClass]="{ error: isErrorDetected() }"
    [attr.disabled]="sheetsForDistrict.length ? null : ''"
    >
    <option value="">
      {{
      config.allValuesEnabled && sheetsForDistrict.length
      ? ('GK.DYNAMIC_FORM.ALL' | translate)
      : ('GK.DYNAMIC_FORM.SELECT_DISTRICT' | translate)
      }}
    </option>

    @for (sheet of sheetsForDistrict; track sheet) {
      <option [ngValue]="config.fullObjectFormValue ? sheet : sheet.id">
        {{ sheet.name }}
      </option>
    }
  </select>
</div>
