import { FormControl } from '@angular/forms';
import { LawPerson } from '@gk/gk-modules';
import { ECSControlName } from '../../../../components/electronic-correspondence-statement/models/form/form.model';
import { DecisionFormType } from '../../../../services/excluding-land-from-agricultural-production/agricultural-land-use-form/agricultural-land-use-form.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';

export enum APNEDNewRequestControlName {
  LawPerson = 'lawPerson',
  DecisionForm = 'decisionForm',
  ShippingForm = 'shippingForm',
  ConstructionWorkDefinition = 'constructionWorkDefinition',
  ConstructionWorkBasis = 'constructionWorkBasis',
  RequestJustification = 'requestJustification',
}

export interface APNEDNewRequestFormGroup {
  [APNEDNewRequestControlName.LawPerson]: FormControl<LawPerson>;
  [APNEDNewRequestControlName.DecisionForm]: FormControl<DecisionFormType>;
  [APNEDNewRequestControlName.ShippingForm]: FormControl<Shipping>;
  [APNEDNewRequestControlName.ConstructionWorkDefinition]: FormControl<string>;
  [APNEDNewRequestControlName.ConstructionWorkBasis]: FormControl<string>;
  [APNEDNewRequestControlName.RequestJustification]: FormControl<string>;
  [ECSControlName.ElectronicCorrespondenceStatement]: FormControl<boolean>;
}
