export interface CoordinateSystemFromApi {
  Id: number;
  Nazwa: string;
}

export class CoordinateSystem {
  constructor(public id: number, public name: string) {}

  static fromApiToApp(
    coordinateSystemFromApi: CoordinateSystemFromApi
  ): CoordinateSystem {
    return new this(coordinateSystemFromApi.Id, coordinateSystemFromApi.Nazwa);
  }
}

export enum CoordinateSystemType {
  Horizontal = 'horizontal',
  Vertical = 'vertical'
}

export enum CoordinateSystemEndpoint {
  Horizontal = '/api/system/pktosnxy/uklady',
  Vertical = '/api/system/pktosnh/uklady'
}
