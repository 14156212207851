import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GkComponentsModule, GkMapModule } from '@gk/gk-modules';
import { SharedModule } from '../../shared/shared.module';
import { ConstructionLogRequestComponent } from './construction-log-request/construction-log-request.component';
import { ConstructionNotificationRequestComponent } from './construction-notification-request/construction-notification-request.component';
import { ConstructionPortalRoutingModule } from './construction-portal-routing.module';
import { ConstructionPortalComponent } from './construction-portal.component';
import { DemolitionNotificationRequestComponent } from './demolition-notification-request/demolition-notification-request.component';
import { PremiseIndependenceNotificationRequestComponent } from './premise-independence-request/premise-independence-notification-request.component';

@NgModule({
  imports: [
    CommonModule,
    ConstructionPortalRoutingModule,
    SharedModule,
    GkMapModule,
    GkComponentsModule,
  ],
  declarations: [
    ConstructionPortalComponent,
    ConstructionNotificationRequestComponent,
    DemolitionNotificationRequestComponent,
    PremiseIndependenceNotificationRequestComponent,
    ConstructionLogRequestComponent,
  ],
})
export class ConstructionPortalModule {}
