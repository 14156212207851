import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { takeWhile } from 'rxjs';
import { SwManagerService } from '../../services/sw-manager/sw-manager.service';

@Component({
  selector: 'app-version-update',
  templateUrl: './app-version-update.component.html',
  styleUrls: ['./app-version-update.component.scss'],
})
export class AppVersionUpdateComponent implements OnInit, OnDestroy {
  private isAlive = true;
  @ViewChild('appVersionModal') modal: NgbModalRef;

  constructor(
    private swManagerService: SwManagerService,
    private ngbModal: NgbModal
  ) {}

  ngOnInit(): void {
    this.swManagerService.init();
    this.subscribeToNewAppVersionAvailable();
  }

  subscribeToNewAppVersionAvailable(): void {
    this.swManagerService.newAppVersionAvailable
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(() => this.showModal());
  }

  showModal(): void {
    this.ngbModal.open(this.modal).result.then(
      (result) => {
        if (result) {
          this.swManagerService.updateAppVersion();
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
