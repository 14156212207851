import { Pipe, PipeTransform } from '@angular/core';
import {
  ExtendedGkKendoGridComponent,
  getGenericGridToolbarItems,
  GridToolBarItem,
  GridToolBarItemPayload,
} from './gk-kendo-grid.model';
import { GkKendoGridComponent } from './gk-kendo-grid.component';

@Pipe({
  name: 'gridToolbarItems',
})
export class GridToolbarItemsPipe implements PipeTransform {
  public transform(
    value: GridToolBarItem[],
    componentInstance: GkKendoGridComponent<never>,
  ): GridToolBarItem[] {
    return this.maybeHandleGenericGridToolbarItems(value, componentInstance);
  }

  private maybeHandleGenericGridToolbarItems(
    gridToolbarItems: GridToolBarItem[],
    componentInstance: ExtendedGkKendoGridComponent<any>,
  ): GridToolBarItem[] {
    if (!gridToolbarItems) {
      return [];
    }

    const genericItems = getGenericGridToolbarItems(componentInstance);

    return gridToolbarItems.map((item) => {
      // Handle nested toolbar items recursively
      if (item.data?.length) {
        item = {
          ...item,
          data: this.maybeHandleGenericGridToolbarItems(
            item.data,
            componentInstance,
          ),
        };
      }

      const genericItem = genericItems.find(
        (generic) => generic.name === item.name,
      );

      // If there's no matching generic item, adjust the click function and return the item
      if (!genericItem) {
        return {
          ...item,
          callback: this.adjustClickFunction(item),
        };
      }

      // Merge generic item with the current item, prioritizing properties of the current item
      return {
        ...genericItem,
        ...item,
        callback:
          item.callback ||
          ((): void => this.handleGenericItemClick(item, genericItem)),
      };
    });
  }

  private adjustClickFunction(
    item: GridToolBarItem,
  ): (event: number | GridToolBarItemPayload | Event) => void {
    return (event): void => {
      if (item.beforeClickValidation && !item.beforeClickValidation()) {
        return;
      }
      if (typeof item.callback === 'function') {
        item.callback(event);
      }
    };
  }

  private handleGenericItemClick(
    item: GridToolBarItem,
    genericItem: GridToolBarItem,
  ): void {
    if (item.beforeClickValidation && !item.beforeClickValidation()) {
      return;
    }
    if (item.payload) {
      genericItem.callback(item.payload());
    } else {
      genericItem.callback();
    }
  }
}
