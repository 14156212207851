<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{ title }}</h4>
  <button
    type="button"
    class="btn-close"
    aria-describedby="modal-title"
    (click)="closeModal()"
  ></button>
</div>
<div class="modal-body overflow-auto">
  <gk-table
    [records]="newMessages"
    [settings]="columns"
    (select)="onMessageSelect($event)"
    [selectionMode]="tableSelectionModeEnum.Single"
    [selectedRows]="selectedMessages"
    [loading]="false"
  ></gk-table>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-success"
    [disabled]="!selectedMessages.length"
    (click)="onReadMessage()"
  >
    {{ callbackButtonConfig.label }}
  </button>
  <button
    type="button"
    class="btn fa fa-refresh btn-outline-color-from-db-active"
    [attr.aria-label]="'GK.DYNAMIC_LIST.REFRESH_LIST' | translate"
    title="{{ 'GK.DYNAMIC_LIST.REFRESH_LIST' | translate }}"
    (click)="onRefreshList()"
  ></button>
</div>
