import {
  ChangeDetectionStrategy,
  Component,
  Type,
  ViewEncapsulation,
} from '@angular/core';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FieldType, FormlyFieldProps } from '@ngx-formly/kendo/form-field';
import { FormlyFieldCheckbox } from '@ngx-formly/kendo/checkbox';

type CheckboxProps = FormlyFieldProps

export interface FormlyCheckboxFieldConfig
  extends FormlyFieldConfig<CheckboxProps> {
  type: 'checkbox' | Type<FormlyFieldCheckbox>;
}

@Component({
  selector: 'gk-formly-field-kendo-checkbox',
  template: `
    <input
      type="checkbox"
      kendoCheckBox
      [formControl]="formControl"
      [formlyAttributes]="field"
    />
    <label [for]="id" class="k-checkbox-label">
      {{ props.label | translate }}
      <span
        *ngIf="props.required && props.hideRequiredMarker !== true"
        aria-hidden="true"
        class="k-required"
        >*</span
      >
    </label>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .k-form formly-field-kendo-checkbox .k-label {
        display: inherit;
      }
    `,
  ],
})
export class FormlyFieldKendoCheckboxComponent extends FieldType<
  FieldTypeConfig<CheckboxProps>
> {
  override defaultOptions = {
    props: {
      hideLabel: true,
    },
  };
}
