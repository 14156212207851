import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MapAction } from '../../../gk-map/models';
import { ValueType } from '../../gk-dynamic-list.model';

@Component({
  selector: 'gk-control-renderer',
  templateUrl: './control-renderer.component.html',
  styleUrls: ['./control-renderer.component.scss'],
})
export class ControlRendererComponent {
  @Input() data: any;
  @Input() controlConfig: any;
  valueTypeEnum = ValueType;
  @Output()
  dispatchMapAction = new EventEmitter<MapAction>();
}
