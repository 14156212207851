<form [formGroup]="formGroup" novalidate>
  <section>
    <div
      class="card mb-4"
      [ngClass]="
        !isElectronicCorrespondenceStatementFormControlValid() && submitted
          ? 'border-danger border-2'
          : 'border-dark'
      "
      >
      <div class="card-header text-white bg-dark">
        <label class="mb-0">
          {{ 'ELECTRONIC_CORRESPONDENCE_STATEMENT.NAME' | translate }}
        </label>
      </div>

      <div class="card-body">
        <div class="form-check form-check-inline">
          <input
            type="radio"
            [id]="electronicCorrespondenceStatement.Consent"
            [attr.name]="controlName.ElectronicCorrespondenceStatement"
            [formControlName]="controlName.ElectronicCorrespondenceStatement"
            [value]="true"
            class="form-check-input"
            />

          <label
            class="form-check-label"
            [attr.for]="electronicCorrespondenceStatement.Consent"
            >
            {{ 'ELECTRONIC_CORRESPONDENCE_STATEMENT.CONSENT' | translate }}
          </label>
        </div>

        <div class="form-check form-check-inline">
          <input
            type="radio"
            [id]="electronicCorrespondenceStatement.NotConsent"
            [attr.name]="controlName.ElectronicCorrespondenceStatement"
            [formControlName]="controlName.ElectronicCorrespondenceStatement"
            [value]="false"
            class="form-check-input"
            />

          <label
            class="form-check-label"
            [attr.for]="electronicCorrespondenceStatement.NotConsent"
            >
            {{ 'ELECTRONIC_CORRESPONDENCE_STATEMENT.NOT_CONSENT' | translate }}
          </label>
        </div>

        <P class="mt-2 mb-0">{{
        'ELECTRONIC_CORRESPONDENCE_STATEMENT.INTENTION_TYPE_INFO' | translate
      }}</P>
    </div>

    @if (
      !isElectronicCorrespondenceStatementFormControlValid() && submitted
      ) {
      <div
        class="card-footer"
        >
        <div class="alert alert-danger mt-2">
          {{ 'FIELD_REQUIRED' | translate }}
        </div>
      </div>
    }
  </div>
</section>
</form>
