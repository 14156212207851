import {
  DictionaryField,
  FieldId,
} from '../../../gk-dynamic-list/services/dictionary/dictionary.model';
import { DBObjectModel } from '../models';

export interface SheetFromApi {
  Id: number;
  Nazwa: string;
}

export class Sheet extends DictionaryField {
  constructor(public override id: number, public override name: string) {
    super(id, name);
  }

  static override fromApiToApp(sheetFromApi: SheetFromApi): Sheet {
    return new this(sheetFromApi.Id, sheetFromApi.Nazwa);
  }
}

export interface DistrictFromApi {
  Id: number;
  Nazwa: string;
  Gus: string;
  Arkusze: SheetFromApi[];
}

export class District extends DictionaryField {
  constructor(
    public override id: FieldId,
    public override name: string,
    public gus: string,
    public sheets: Sheet[]
  ) {
    super(id, name);
  }

  static override fromApiToApp(districtFromApi: DistrictFromApi): District {
    return new this(
      districtFromApi.Id,
      districtFromApi.Nazwa,
      districtFromApi.Gus,
      (districtFromApi.Arkusze || []).map((sheetFromApi) =>
        Sheet.fromApiToApp(sheetFromApi)
      )
    );
  }
}

export interface CommunityFromApi extends DBObjectModel {
  Gus?: string;
  Miasto?: string;
  MiastoGus?: string;
  MiastoId?: number;
  Obreby?: DistrictFromApi[];
  Urzad?: string;
}

export class Community extends DictionaryField {
  constructor(
    public override id: number,
    public override name: string,
    public gus?: string,
    public city?: string,
    public cityGus?: string,
    public cityId?: number,
    public districts?: District[],
    public office?: string
  ) {
    super(id, name);
  }

  static override fromApiToApp(communityFromApi: CommunityFromApi): Community {
    return new this(
      communityFromApi.Id,
      communityFromApi.Nazwa,
      communityFromApi.Gus,
      communityFromApi.Miasto,
      communityFromApi.MiastoGus,
      communityFromApi.MiastoId,
      (communityFromApi.Obreby || []).map((districtFromApi) =>
        District.fromApiToApp(districtFromApi)
      ),
      communityFromApi.Urzad
    );
  }
}
