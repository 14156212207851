import { Injectable } from '@angular/core';
import { filter, Observable, ReplaySubject, shareReplay } from 'rxjs';
import { DefaultConfigGuestService } from '../default-config-guest/default-config-guest.service';
import { ColorSet } from './color-set.model';

@Injectable()
export class ColorsService {
  private cache$: Observable<ColorSet>;
  defaultThemeSelected = new ReplaySubject<boolean>();

  constructor(private defaultConfigGuestService: DefaultConfigGuestService) {}

  private getColorSet(): Observable<ColorSet> {
    if (!this.cache$) {
      this.cache$ = this.defaultConfigGuestService.getConfig().pipe(
        filter((data) => !!data),
        shareReplay(1)
      );
    }

    return this.cache$;
  }

  public updateDocumentColors(): void {
    this.getColorSet().subscribe((data) => {
      document.documentElement.style.setProperty(
        '--colorFromDb',
        `#${data.main}`
      );
      document.documentElement.style.setProperty(
        '--colorFromDbBorder',
        `#${data.mainBorder}`
      );
      document.documentElement.style.setProperty(
        '--colorFromDbFont',
        `#${data.mainFont}`
      );
      document.documentElement.style.setProperty(
        '--colorFromDbHover',
        `#${data.hover}`
      );
      document.documentElement.style.setProperty(
        '--colorFromDbHoverBorder',
        `#${data.hoverBorder}`
      );
      document.documentElement.style.setProperty(
        '--colorFromDbHoverFont',
        `#${data.hoverFont}`
      );
      document.documentElement.style.setProperty(
        '--colorFromDbActive',
        `#${data.active}`
      );
      document.documentElement.style.setProperty(
        '--colorFromDbActiveBorder',
        `#${data.activeBorder}`
      );
      document.documentElement.style.setProperty(
        '--colorFromDbActiveFont',
        `#${data.activeFont}`
      );
    });
  }
}
