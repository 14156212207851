@if (config.name) {
  <div class="dynamic-field form-input" [formGroup]="group">
    <label [attr.for]="config.name">{{ config.label | translate }}</label>
    <input
      [id]="config.name"
      type="text"
      [name]="config.name"
      placeholder="{{ config.placeholder | translate }}"
      [formControlName]="config.name"
      class="form-control"
      [pattern]="landParcelNumberPattern"
      />
    @if (getControl().errors?.['pattern']) {
      <div class="alert alert-danger">
        {{ 'GK.DYNAMIC_LIST.LAND_PARCEL_NUMBER_BAD_FORMAT_ERROR' | translate }}
      </div>
    }
  </div>
}
