import { Injectable } from '@angular/core';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';

export function isNumber(value: any): value is number {
  return !isNaN(toInteger(value));
}

export function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

export function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  isDateValid = (value: string): string | any => {
    const regexValid: boolean =
      value.match(
        /^(0[1-9]|1[0-9]|2[0-9]|3[0-1]){1}\/(0[1-9]|1[0-2]){1}\/\d\d\d\d$/g
      ) === null
        ? false
        : true;

    if (!regexValid) {
      return null;
    }

    const dateItems: string[] = value.split(/\//g);

    const isValid: boolean =
      regexValid &&
      parseInt(dateItems[1], 10) <=
        new Date(
          parseInt(dateItems[2], 10),
          parseInt(dateItems[1], 10),
          0
        ).getDate();

    return isValid;
  };

  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split(/\//g);
      if (!this.isDateValid(value)) {
        return null as any;
      }
      if (dateParts.length === 1 && isNumber(dateParts[0])) {
        return { day: toInteger(dateParts[0]), month: null, year: null } as any;
      } else if (
        dateParts.length === 2 &&
        isNumber(dateParts[0]) &&
        isNumber(dateParts[1])
      ) {
        return {
          day: toInteger(dateParts[0]),
          month: toInteger(dateParts[1]),
          year: null,
        } as any;
      } else if (
        dateParts.length === 3 &&
        isNumber(dateParts[0]) &&
        isNumber(dateParts[1]) &&
        isNumber(dateParts[2])
      ) {
        return {
          day: toInteger(dateParts[0]),
          month: toInteger(dateParts[1]),
          year: toInteger(dateParts[2]),
        };
      }
    }
    return null as any;
  }

  format(date: NgbDateStruct): string {
    const toReturn = date
      ? `${isNumber(date.day) ? padNumber(date.day) : ''}/${
          isNumber(date.month) ? padNumber(date.month) : ''
        }/${date.year}`
      : '';
    return toReturn;
  }
}
