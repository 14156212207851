import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, shareReplay } from 'rxjs';
import { SelectItem, SelectItemDto } from '../../../services';

@Injectable()
export class GMPPrintFooterService {
  private printFooterCache: Observable<SelectItem<string>[]>;
  constructor(private httpClient: HttpClient) {}

  public get(): Observable<SelectItem<string>[]> {
    if (!this.printFooterCache) {
      this.printFooterCache = this.httpClient
        .get<SelectItemDto<string>[]>('/api/mapa/wydruk/slo/footers')
        .pipe(
          map((response: SelectItemDto<string>[]) =>
            response.map((item) => SelectItem.fromApi(item)),
          ),
          shareReplay(1),
        );
    }

    return this.printFooterCache;
  }
}
