import { Component, Input } from '@angular/core';
import {
  ButtonStateControl,
  ControlTextWithCallbackButtonControlConfig,
} from '../../../gk-dynamic-list.model';
import { Control } from '../control';

@Component({
  selector: 'gk-control-text-with-callback-button',
  templateUrl: './control-text-with-callback-button.component.html',
})
export class ControlTextWithCallbackButtonComponent extends Control<string> {
  @Input() override controlConfig: ControlTextWithCallbackButtonControlConfig;
  actionPending: boolean;

  clickCallback(): void {
    this.controlConfig.button.callback(
      this.getValue(),
      new ButtonStateControl(
        () => this.setActionPendingTrue(),
        () => this.setActionPendingFalse()
      )
    );
  }

  setActionPendingTrue(): void {
    this.actionPending = true;
  }

  setActionPendingFalse(): void {
    this.actionPending = false;
  }
}
