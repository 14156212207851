import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { takeWhile } from 'rxjs/operators';
import { MapControl } from '../../../../controls/map-control/map-control';
import {
  MapAction,
  MapObject,
  MapObjectApiType,
  MapObjectTableActionType,
  SourceActionType,
  ToolActionType,
} from '../../../../models';
import { PolygonTopologyService } from '../../../../services/polygon-topology';
import { ShapeUtils } from '../../../../utils/shape/shape.utils';

@Component({
  selector: 'gk-increase-range-by-buffer-button',
  templateUrl: './increase-range-by-buffer-button.component.html',
  styleUrls: ['./increase-range-by-buffer-button.component.scss'],
  providers: [PolygonTopologyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IncreaseRangeByBufferButtonComponent
  extends MapControl
  implements OnDestroy, OnInit
{
  private isAlive = true;
  @ViewChild('buttonTemplate') buttonTemplate: TemplateRef<any>;
  @Input() buttonClass: string;
  @Input() buttonText: string;
  noSingleRangeTranslation: string;
  noBufferTranslation: string;
  increasingRangeInProgress: boolean;
  bufferValue: number;
  selectedMapObjects: MapObject[];
  wktResponse: string;
  constructor(
    private translateService: TranslateService,
    private toastr: ToastrService,
    private polygonTopologyService: PolygonTopologyService
  ) {
    super();
  }

  ngOnInit(): void {
    this.fetchNoSingleRangeNotificationText();
    this.fetchNoBufferValueNotificationText();
  }

  fetchNoSingleRangeNotificationText(): void {
    this.translateService
      .get('GK.MAP.SELECT_RANGE')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (translation) => (this.noSingleRangeTranslation = translation)
      );
  }

  fetchNoBufferValueNotificationText(): void {
    this.translateService
      .get('GK.MAP.ENTER_BUFFER')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((translation) => (this.noBufferTranslation = translation));
  }

  validateAndIncreaseRangeByBuffer(): void {
    this.increasingRangeInProgress = true;
    if (!this.bufferValue || this.bufferValue <= 0) {
      this.toastr.warning(this.noBufferTranslation);
      this.increasingRangeInProgress = false;

      return;
    }
    this.assignSelectedMapObjects();
    if (!this.selectedMapObjects || this.selectedMapObjects.length !== 1) {
      this.toastr.warning(this.noSingleRangeTranslation);
      this.increasingRangeInProgress = false;

      return;
    }
    this.addRangeWithBufferToMapObjectsTable();
  }

  addRangeWithBufferToMapObjectsTable(): void {
    this.polygonTopologyService
      .getPolygonsUnion(
        this.selectedMapObjects.map((object) => object.geom),
        this.bufferValue
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe({
        next: (polygon) => {
          this.wktResponse = polygon.wkt;
          this.removePreviousRange();
          this.emitNewRange();
          this.increasingRangeInProgress = false;
        },
        error: () => {
          this.increasingRangeInProgress = false;
        },
      });
  }

  removePreviousRange(): void {
    this.dispatch.emit(
      new MapAction(
        MapObjectTableActionType.Deselect,
        this.selectedMapObjects[0],
        this.mapState.mapObjectTableStateCurrentIndex
      )
    );
    this.dispatch.emit(
      new MapAction(
        MapObjectTableActionType.Remove,
        this.selectedMapObjects[0],
        this.mapState.mapObjectTableStateCurrentIndex
      )
    );
    this.dispatch.emit(
      new MapAction(ToolActionType.Clear, {
        value: this.selectedMapObjects[0],
        options: { toolType: this.toolType },
      })
    );
  }

  emitNewRange(): void {
    this.dispatch.emit(
      new MapAction(SourceActionType.LastWktChange, {
        value: this.wktResponse,
        options: { toolType: this.toolType, sourceType: this.sourceType },
      })
    );
    const newMapObject = ShapeUtils.getAnyGeometryMapObject(
      this.wktResponse,
      MapObjectApiType.ExtentOrPolygon
    );
    this.dispatch.emit(
      new MapAction(MapObjectTableActionType.AddToExisting, [newMapObject])
    );
    this.dispatch.emit(
      new MapAction(
        MapObjectTableActionType.Select,
        newMapObject,
        this.mapState.mapObjectTableStateCurrentIndex
      )
    );
  }

  assignSelectedMapObjects(): void {
    this.selectedMapObjects =
      this.mapState.mapObjectTablesState.length === 1
        ? this.mapState.mapObjectTablesState[0].selectedMapObjects.filter(
            (mapObject) => mapObject.type === MapObjectApiType.ExtentOrPolygon
          )
        : this.mapState.mapObjectTablesState.find(
            (mapObjectTableState) =>
              _.isEmpty(mapObjectTableState.mapObjectTypes) ||
              mapObjectTableState.mapObjectTypes.includes(
                MapObjectApiType.ExtentOrPolygon
              )
          ).selectedMapObjects;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
