@if (hasHref() && shouldShowControl()) {
  <div class="d-flex">
    <span gkControlLabel [showLabel]="controlConfig.showLabel">{{'GK.DYNAMIC_LIST.LINK' | translate}}:</span>
    <span gkControlValue [showLabel]="controlConfig.showLabel">
      <a [attr.href]="href">
        <gk-translated-label [configLabel]="controlConfig.label"></gk-translated-label>
      </a>
    </span>
  </div>
}
