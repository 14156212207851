import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SessionGuard } from '../../guards/session/session.guard';
import { MainRequestsListComponent } from '../../shared/main-requests-list/main-requests-list.component';
import { AddressNumberRequestPortalComponent } from './address-number-request-portal.component';
import { NewRequestComponent } from './new-request/new-request.component';

const addressNumberRequestPortalRoutes: Routes = [
  {
    path: 'address-number-request-portal',
    component: AddressNumberRequestPortalComponent,
    canActivate: [SessionGuard],
    children: [
      {
        path: '',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
      {
        path: 'new-request',
        component: NewRequestComponent,
      },
      {
        path: 'requests-list',
        component: MainRequestsListComponent,
      },
      {
        path: '**',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(addressNumberRequestPortalRoutes)],
  providers: [SessionGuard],
  exports: [RouterModule],
})
export class AddressNumberRequestPortalRoutingModule {}
