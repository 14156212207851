import { EgibObject, LawPersonAddress, MapObject } from '@gk/gk-modules';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {
  InvestorDetails,
  InvestorDetailsRequestDto,
} from '../../../shared/investor-details/investor-details.model';
import {
  Shipping,
  ShippingDto,
} from '../../../shared/shipping-form/shipping-form.model';
import { DateHelperUtil } from '../../../utils/date-helper/date-helper.util';
import { ApiNewDokumentPowiazanyDalDto } from '../../designer-incoming-documents/designer-incoming-documents.model';
import { DecisionFormType } from '../agricultural-land-use-form/agricultural-land-use-form.model';

export interface ExcludingLandFromAgriculturalProduction {
  investorDetails: InvestorDetails;
  proxyDetails: InvestorDetails;
  idNumber: number;
  phoneNumber: number;
  email: string;
  address: LawPersonAddress;
  proxyDetailsCheckbox: boolean;
  exclusionPurpose: number;
  investmentCommencementDate: NgbDateStruct;
  attachments: ApiNewDokumentPowiazanyDalDto;
  decisionForm: DecisionFormType;
  landParcels: ZamowienieDzialkaDto[];
  exclusionArea: ZamowienieGeomDto;
  shippingForm: Shipping;
}

export class ZamowienieDzialkaDto {
  constructor(
    public Uuid: string,
    public DzialkaId: number,
    public Numer: string,
    public Gmina: string,
    public Obreb: string,
    public Arkusz: string
  ) {}
  static fromAppToApi(data: EgibObject): ZamowienieDzialkaDto {
    return new this(
      data.uuid,
      data.parcelId,
      data.mapObjectNumber,
      data.cadastralUnit,
      data.districtName,
      data.mapSheet
    );
  }
}

export class ZamowienieGeomDto {
  constructor(public Geom: string) {}
  static fromAppToApi(data: MapObject): ZamowienieGeomDto {
    return new this(data.geom);
  }
}

export class ExcludingLandFromAgriculturalProductionRequestDto {
  constructor(
    public DaneInwestora: InvestorDetailsRequestDto,
    public DanePelnomocnika: InvestorDetailsRequestDto,
    public CelWylaczenia: number,
    public TerminRozpoczeciaInwestycji: string,
    public Zalaczniki: ApiNewDokumentPowiazanyDalDto[],
    public FormaDecyzji: number,
    public Dzialki: ZamowienieDzialkaDto[],
    public Geom: ZamowienieGeomDto,
    public Odbior: ShippingDto
  ) {}
  static fromAppToApi(
    data: ExcludingLandFromAgriculturalProduction,
    attachments: ApiNewDokumentPowiazanyDalDto[]
  ): ExcludingLandFromAgriculturalProductionRequestDto {
    return new this(
      InvestorDetailsRequestDto.fromAppToApi(data.investorDetails),
      data.proxyDetailsCheckbox
        ? InvestorDetailsRequestDto.fromAppToApi(data.proxyDetails)
        : null,
      data.exclusionPurpose,
      data.investmentCommencementDate
        ? DateHelperUtil.ngbDateToApiDateTime(data.investmentCommencementDate)
        : undefined,
      attachments,
      data.decisionForm,
      data.landParcels,
      data.exclusionArea,
      ShippingDto.fromAppToApi(data.shippingForm)
    );
  }
}
