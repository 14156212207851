<div
  class="nested-group"
  [formGroup]="group"
  [ngClass]="{
    alignItems: config.verticalAlignOnMobile
      ? (config.alignItems | addTextAfterBase: 'align-items' : 'md')
      : config.alignItems,
    'flex-column': config.verticalAlign,
    'flex-row': !config.verticalAlign,
    'flex-column flex-md-row': config.verticalAlignOnMobile
  }"
>
  @for (fieldConfig of config.children; track fieldConfig) {
    <gk-form-renderer
      [fullFormConfig]="config.children"
      [fieldConfig]="fieldConfig"
      [formGroup]="$any(getControl())"
      [submitted]="submitted"
    ></gk-form-renderer>
  }
</div>
