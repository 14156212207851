import {
  LegalPersonSearch,
  NaturalPersonSearch,
  isNaturalPerson,
} from '../law-person/law-person-search.model';
import {
  NewLawPersonRequestDto,
  NewLegalPersonRequestDto,
  NewNaturalPersonRequestDto,
} from '../law-person/law-person.model';

export enum EnablementType {
  Lack = 0,
  Statutory = 1,
  Written = 2,
}

export class Enablement {
  constructor(
    public type: EnablementType,
    public document: string,
    public documentName: string,
    public principal: NaturalPersonSearch | LegalPersonSearch | undefined,
    public valid?: boolean,
    public principalSetByUser = true
  ) {}

  static getInitialStruct(): Enablement {
    return new this(
      EnablementType.Lack,
      undefined,
      undefined,
      undefined,
      false
    );
  }

  static getInitialStatutory(): Enablement {
    return new this(
      EnablementType.Statutory,
      undefined,
      undefined,
      undefined,
      false
    );
  }

  static isWritten(type: EnablementType): boolean {
    return type === EnablementType.Written;
  }

  static isStatutory(type: EnablementType): boolean {
    return type === EnablementType.Statutory;
  }

  static isLack(type: EnablementType): boolean {
    return type === EnablementType.Lack;
  }
}

export class EnablementDto {
  constructor(
    public RodzajPelnomocnictwa: EnablementType,
    public PlikPelnomocnictwo: string,
    public PlikPelnomocnictwoNazwa: string,
    public DaneMocodawcy: NewLawPersonRequestDto
  ) {}

  static fromAppToApi(data: Enablement): EnablementDto {
    const isLack = Enablement.isLack(data.type);
    return new this(
      data.type,
      isLack ? undefined : data.document,
      isLack ? undefined : data.documentName,
      isLack
        ? undefined
        : isNaturalPerson(data.principal)
        ? NewNaturalPersonRequestDto.fromAppToApi(data.principal.data)
        : NewLegalPersonRequestDto.fromAppToApi(
            data.principal.data,
            data.principal.id
          )
    );
  }
}
