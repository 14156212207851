import { Component, Input } from '@angular/core';
import {
  ActionButtonNavItemConfig,
  DropdownNavItemConfig,
  NavbarAction,
  NavbarActionType,
} from '../../gk-dynamic-navbar.model';
import { NavBaseComponent } from '../nav-base/nav-base.component';

@Component({
  selector: 'gk-nav-item-action-button',
  templateUrl: './nav-item-action-button.component.html',
  styleUrls: ['./nav-item-action-button.component.scss'],
})
export class NavItemActionButtonComponent extends NavBaseComponent {
  @Input()
  actionButtonNavItemConfig: ActionButtonNavItemConfig;
  @Input()
  dropdownConfig: DropdownNavItemConfig;

  getButtonClassWhenBadgeIsActive(): string {
    return this.actionButtonNavItemConfig.showBadge
      ? 'action-button--badged'
      : '';
  }

  getButtonCustomClassWhenProvided(): string {
    return (
      this.actionButtonNavItemConfig.customBtnClass || this.getButtonClass()
    );
  }

  getIconClassSet(): string {
    return `fa fa-${this.actionButtonNavItemConfig.faIconName}`;
  }

  getButtonClasses(): string[] {
    return this.actionButtonNavItemConfig.isDropdownItem
      ? ['dropdown-item']
      : [
          'btn',
          this.getButtonClassWhenBadgeIsActive(),
          this.getButtonCustomClassWhenProvided(),
        ];
  }

  handleClick(): void {
    this.dispatch(
      new NavbarAction(NavbarActionType.Click, {
        navItemId: this.actionButtonNavItemConfig.navItemId,
        customIconSrc: this.actionButtonNavItemConfig.customIconSrc,
        dropdownItemId: this.dropdownConfig?.navItemId,
      })
    );
  }
}
