import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { SettingsFromApi } from './settings.model';

@Injectable()
export class SettingsService {
  settings = new BehaviorSubject<SettingsFromApi>({} as SettingsFromApi);

  constructor(private http: HttpClient) {
    this.fetchSettings();
  }

  getSettings(): Observable<SettingsFromApi> {
    return this.http.get<SettingsFromApi>('/api/interesant/sklep/settings');
  }

  fetchSettings(): void {
    this.getSettings().subscribe(dataFromApi => this.settings.next(dataFromApi));
  }
}
