import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map } from 'rxjs';
import {
  DictionaryField,
  DictionaryFieldFromApi,
} from '../../../gk-dynamic-list/services/dictionary/dictionary.model';
import { ApiResponse } from '../../../services';

@Injectable()
export class LegalPersonTypeService {
  legalPersonTypes = new BehaviorSubject<DictionaryField[]>([]);

  constructor(private httpClient: HttpClient) {
    this.fetchLegalPersonTypesList();
  }

  fetchLegalPersonTypesList(): void {
    this.httpClient
      .get<ApiResponse<DictionaryFieldFromApi[]>>(
        '/api/petent/slo/rodzajOsobaPrawna'
      )
      .pipe(
        map((data) => data.Response),
        map((legalPersonTypes) =>
          legalPersonTypes.map((legalPersonType) =>
            DictionaryField.fromApiToApp(legalPersonType)
          )
        ),
        catchError((err) => {
          console.error(err);
          return [];
        })
      )
      .subscribe((data) => this.legalPersonTypes.next(data));
  }
}
