<section
  class="card mb-4"
  [ngClass]="
    checkAuthorizedPersonDetailsCheckedFormControlsInvalidAndFormValidated()
      ? 'border-danger border-2'
      : 'border-dark'
  "
  >
  <form [formGroup]="parentForm">
    <div class="card-header text-white bg-dark">
      <div class="form-check">
        <input
          type="checkbox"
          class="form-check-input"
          [id]="authorizedPersonControlName.AuthorizedDetailsCheckbox"
          [formControlName]="
            authorizedPersonControlName.AuthorizedDetailsCheckbox
          "
          />
        <label
          class="form-check-label"
          [attr.for]="authorizedPersonControlName.AuthorizedDetailsCheckbox"
          >{{
          'AUTHORIZED_PERSON_FORM.AUTHORIZED_DETAILS_CHECKBOX' | translate
          }}</label
          >
        </div>
      </div>
      @if (getAuthorizedDetailsCheckboxFormControl().value) {
        <div
          class="card-body"
          >
          <div class="row">
            <div class="form-group col-md-4">
              <label [attr.for]="authorizedPersonControlName.Name">{{
                'AUTHORIZED_PERSON_FORM.AUTHORIZED_PERSON_NAME' | translate
              }}</label>
              <input
                type="text"
                [name]="authorizedPersonControlName.Name"
                [id]="authorizedPersonControlName.Name"
                class="form-control form-control-sm required"
                [formControlName]="authorizedPersonControlName.Name"
                aria-required="true"
                />
              @if (
                shouldShowRequiredAlert(this.getAuthorizedPersonNameFormControl())
                ) {
                <div
                  class="alert alert-danger"
                  tabindex="0"
                  >
                  {{ 'FIELD_REQUIRED' | translate }}
                </div>
              }
            </div>
            <div class="form-group col-md-4">
              <label [attr.for]="authorizedPersonControlName.Surname">{{
                'AUTHORIZED_PERSON_FORM.AUTHORIZED_PERSON_SURNAME' | translate
              }}</label>
              <input
                type="text"
                [name]="authorizedPersonControlName.Surname"
                [id]="authorizedPersonControlName.Surname"
                class="form-control form-control-sm required"
                [formControlName]="authorizedPersonControlName.Surname"
                aria-required="true"
                />
              @if (
                shouldShowRequiredAlert(
                this.getAuthorizedPersonSurnameFormControl()
                )
                ) {
                <div
                  class="alert alert-danger"
                  tabindex="0"
                  >
                  {{ 'FIELD_REQUIRED' | translate }}
                </div>
              }
            </div>
            <div class="form-group col-md-4">
              <label [attr.for]="authorizedPersonControlName.IdNumber">{{
                'AUTHORIZED_PERSON_FORM.AUTHORIZED_PERSON_ID_NUMBER' | translate
              }}</label>
              <input
                type="text"
                [name]="authorizedPersonControlName.IdNumber"
                [id]="authorizedPersonControlName.IdNumber"
                class="form-control form-control-sm required"
                [formControlName]="authorizedPersonControlName.IdNumber"
                aria-required="true"
                />
              @if (
                shouldShowRequiredAlert(
                this.getAuthorizedPersonIdNumberFormControl()
                )
                ) {
                <div
                  class="alert alert-danger"
                  tabindex="0"
                  >
                  {{ 'FIELD_REQUIRED' | translate }}
                </div>
              }
            </div>
          </div>
        </div>
      }
    </form>
  </section>
