export enum PprfPremisesFormControlName {
  PremisesFunction = 'premisesFunction',
  Floor = 'floor',
  RoomsNumberFrom = 'roomsNumberFrom',
  RoomsNumberTo = 'roomsNumberTo',
  PremisesUsableAreaFrom = 'premisesUsableAreaFrom',
  PremisesUsableAreaTo = 'premisesUsableAreaTo',
  AssociatedPremisesAreaFrom = 'associatedPremisesAreaFrom',
  AssociatedPremisesAreaTo = 'associatedPremisesAreaTo',
}

export class PprfPremisesFormModel {
  constructor(
    public premisesFunction: number[],
    public floor: number[],
    public roomsNumberFrom: number,
    public roomsNumberTo: number,
    public premisesUsableAreaFrom: number,
    public premisesUsableAreaTo: number,
    public associatedPremisesAreaFrom: number,
    public associatedPremisesAreaTo: number,
  ) {}

  static getEmptyModel(): PprfPremisesFormModel {
    return new this(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
  }
}

export const pprfPremisesFormModel = PprfPremisesFormModel.getEmptyModel();
