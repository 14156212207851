<form [formGroup]="form" novalidate class="row">
  @if (!searchFieldsToHide?.includes(legalPersonControlName.TypeId)) {
    <div class="form-group col-lg-3 col-md-5 col-12 my-1">
      <label [attr.for]="idPrefix + legalPersonControlName.TypeId">{{
        'LEGAL_PERSON_FORM.LEGAL_PERSON_TYPE' | translate
      }}</label>
      <select
        class="form-select form-select-sm"
        [ngClass]="{ required: isRequired }"
        [attr.aria-required]="isRequired"
        [id]="idPrefix + legalPersonControlName.TypeId"
        [required]="isRequired"
        [formControlName]="legalPersonControlName.TypeId"
        [name]="idPrefix + legalPersonControlName.TypeId"
      >
        @for (legalPersonType of legalPersonTypes; track legalPersonType) {
          <option [ngValue]="legalPersonType.id">
            {{ legalPersonType.name }}
          </option>
        }
      </select>
    </div>
  }
  @if (!searchFieldsToHide?.includes(legalPersonControlName.Name)) {
    <div class="form-group col-lg-5 col-md-7 col-12 my-1">
      <label [attr.for]="idPrefix + legalPersonControlName.Name">{{
        'LEGAL_PERSON_FORM.PRINCIPAL.NAME' | translate
      }}</label>
      <input
        type="text"
        class="form-control form-control-sm"
        [ngClass]="{ required: isRequired && newPersonMode }"
        [attr.aria-required]="isRequired && newPersonMode"
        [id]="idPrefix + legalPersonControlName.Name"
        [formControlName]="legalPersonControlName.Name"
        [name]="idPrefix + legalPersonControlName.Name"
        [autocomplete]="undefined"
      />
      @if (shouldShowRequiredAlert(getNameFormControl())) {
        <div class="alert alert-danger" tabindex="0">
          {{ 'FIELD_REQUIRED' | translate }}
        </div>
      }
    </div>
  }
  @if (!searchFieldsToHide?.includes(legalPersonControlName.Nip)) {
    <div class="form-group col my-1">
      <label [attr.for]="idPrefix + legalPersonControlName.Nip">{{
        'LEGAL_PERSON_FORM.PRINCIPAL.NIP' | translate
      }}</label>
      <input
        type="text"
        class="form-control form-control-sm"
        [ngClass]="{ required: isRequired && newPersonMode }"
        [attr.aria-required]="isRequired && newPersonMode"
        [id]="idPrefix + legalPersonControlName.Nip"
        [formControlName]="legalPersonControlName.Nip"
        [name]="idPrefix + legalPersonControlName.Nip"
        [autocomplete]="undefined"
      />
      @if (shouldShowRequiredAlert(getNipFormControl())) {
        <div class="alert alert-danger" tabindex="0">
          {{ 'FIELD_REQUIRED' | translate }}
        </div>
      }
      @if (shouldShowNipAlert(getNipFormControl())) {
        <div class="alert alert-danger" tabindex="0">
          {{ 'LEGAL_PERSON_FORM.PRINCIPAL.NIP_ERROR' | translate }}
        </div>
      }
    </div>
  }
  @if (!searchFieldsToHide?.includes(legalPersonControlName.Regon)) {
    <div class="form-group col my-1">
      <label [attr.for]="idPrefix + legalPersonControlName.Regon">{{
        'LEGAL_PERSON_FORM.PRINCIPAL.REGON' | translate
      }}</label>
      <input
        type="text"
        class="form-control form-control-sm"
        [ngClass]="{ required: isRequired && newPersonMode }"
        [attr.aria-required]="isRequired && newPersonMode"
        [id]="idPrefix + legalPersonControlName.Regon"
        [formControlName]="legalPersonControlName.Regon"
        [name]="idPrefix + legalPersonControlName.Regon"
        [autocomplete]="undefined"
      />
      @if (shouldShowRequiredAlert(getRegonFormControl())) {
        <div class="alert alert-danger mt-2" tabindex="0">
          {{ 'FIELD_REQUIRED' | translate }}
        </div>
      }
      @if (shouldShowRegonAlert(getRegonFormControl())) {
        <div tabindex="0" class="alert alert-danger mt-2">
          {{ 'LEGAL_PERSON_FORM.PRINCIPAL.REGON_ERROR' | translate }}
        </div>
      }
    </div>
  }
  @if (!searchFieldsToHide?.includes(legalPersonControlName.KrsNumber)) {
    <div class="form-group col my-1">
      <label [attr.for]="legalPersonControlName.KrsNumber">{{
        'LEGAL_PERSON_FORM.PRINCIPAL.KRS_NUMBER' | translate
      }}</label>
      <input
        type="text"
        class="form-control form-control-sm"
        [ngClass]="{ required: isRequired && newPersonMode }"
        [attr.aria-required]="isRequired && newPersonMode"
        [id]="legalPersonControlName.KrsNumber"
        [formControlName]="legalPersonControlName.KrsNumber"
        [name]="legalPersonControlName.KrsNumber"
        [autocomplete]="undefined"
      />
      @if (shouldShowRequiredAlert(getKrsNumberFormControl())) {
        <div class="alert alert-danger mt-2" tabindex="0">
          {{ 'FIELD_REQUIRED' | translate }}
        </div>
      }
      @if (shouldShowKrsNumberAlert()) {
        <div tabindex="0" class="alert alert-danger mt-2">
          {{ 'LEGAL_PERSON_FORM.PRINCIPAL.KRS_NUMBER_ERROR' | translate }}
        </div>
      }
    </div>
  }
</form>
@if (shouldShowAtLeastOneAlert()) {
  <div class="d-flex flex-column col-12 alert alert-danger mt-2">
    <span tabindex="0">{{ 'AT_LEAST_ONE_FIELD_REQUIRED' | translate }}</span>
  </div>
}
