export type SharingPurposeControlBindId = 'Par1' | 'Par2';

export interface SharingPurposeDynamicFieldFromApi {
  BindId: SharingPurposeControlBindId;
  Label: string;
}

export class SharingPurposeDynamicField {
  constructor(public bindId: SharingPurposeControlBindId, public label: string) {}

  static sharingPurposeDynamicFieldFromApiToSharingPurposeDynamicField = (
    sharingPurposeDynamicFields: SharingPurposeDynamicFieldFromApi[]
  ): SharingPurposeDynamicField[] =>
    sharingPurposeDynamicFields.map(
      sharingPurposeDynamicField =>
        new SharingPurposeDynamicField(
          sharingPurposeDynamicField.BindId,
          sharingPurposeDynamicField.Label
        )
    );
}

export interface SharingPurposeFromApi {
  CelId: string;
  SkrotPl: string;
  SkrotEn: string;
  OpisPl: string;
  OpisEn: string;
  Fields: SharingPurposeDynamicFieldFromApi[];
}

export class SharingPurpose {
  constructor(
    public id: string,
    public namePl: string,
    public nameEn: string,
    public descriptionPl: string,
    public descriptionEn: string,
    public fields: SharingPurposeDynamicField[]
  ) {}

  static sharingPurposeFromApiToSharingPurpose = (
    sharingPurposesFromApi: SharingPurposeFromApi[]
  ): SharingPurpose[] =>
    sharingPurposesFromApi.map(
      sharingPurposeFromApi =>
        new SharingPurpose(
          sharingPurposeFromApi.CelId,
          sharingPurposeFromApi.SkrotPl,
          sharingPurposeFromApi.SkrotEn,
          sharingPurposeFromApi.OpisPl,
          sharingPurposeFromApi.OpisEn,
          SharingPurposeDynamicField.sharingPurposeDynamicFieldFromApiToSharingPurposeDynamicField(
            sharingPurposeFromApi.Fields
          )
        )
    );
}

export interface PetitionerSharingPurposeFromApi {
  IdCelUdost: number;
  Nazwa: string;
  SkrotPl: string;
  SkrotEn: string;
  Cl: number;
  K: number;
  Par1: string;
  Par2: string;
  OpisPl: string;
  OpisEn: string;
}

export class PetitionerSharingPurpose {
  constructor(
    public id: number,
    public name: string,
    public shortDescPl: string,
    public shortDescEn: string,
    public cl: number,
    public k: number,
    public par1: string,
    public par2: string,
    public descriptionPl: string,
    public descriptionEn: string
  ) {}

  static fromApiToApp = (
    petitionerSharingPurposesFromApi: PetitionerSharingPurposeFromApi[]
  ): PetitionerSharingPurpose[] =>
    petitionerSharingPurposesFromApi.map(
      petitionerSharingPurposeFromApi =>
        new PetitionerSharingPurpose(
          petitionerSharingPurposeFromApi.IdCelUdost,
          petitionerSharingPurposeFromApi.Nazwa,
          petitionerSharingPurposeFromApi.SkrotPl,
          petitionerSharingPurposeFromApi.SkrotEn,
          petitionerSharingPurposeFromApi.Cl,
          petitionerSharingPurposeFromApi.K,
          petitionerSharingPurposeFromApi.Par1,
          petitionerSharingPurposeFromApi.Par2,
          petitionerSharingPurposeFromApi.OpisPl,
          petitionerSharingPurposeFromApi.OpisEn
        )
    );
}

export enum SharingPurposeEnum {
  OwnNeeds = 21
}
