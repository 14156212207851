<section class="card mb-4">
  <form [formGroup]="parentForm">
    <div class="card-header text-white bg-dark">
      <div class="form-check">
        <input
          type="checkbox"
          class="form-check-input"
          [id]="feeWaiverControlName.FeeWaiverCheckbox"
          [formControlName]="feeWaiverControlName.FeeWaiverCheckbox"
          />
        <label
          class="form-check-label"
          [attr.for]="feeWaiverControlName.FeeWaiverCheckbox"
          >{{ 'FEE_WAVER_FORM.FEE_WAVER_CHECKBOX' | translate }}</label
          >
        </div>
      </div>
      @if (getFeeWaiverCheckboxFormControl().value) {
        <div class="card-body">
          <div class="custom-control custom-radio">
            <input
              type="radio"
              [id]="feeWaiverControlName.SocialAssistanceBecauseOfPoverty"
              [attr.name]="feeWaiverControlName.FeeWaiverCause"
              [formControlName]="feeWaiverControlName.FeeWaiverCause"
              [value]="feeWaiverCause.SocialAssistanceBecauseOfPoverty"
              class="form-check-input"
              />
            <label
              class="form-check-label"
              [attr.for]="feeWaiverControlName.SocialAssistanceBecauseOfPoverty"
              >
              {{
              'FEE_WAVER_FORM.SOCIAL_ASSISTANCE_BECAUSE_OF_POVERTY' | translate
              }}</label
              >
            </div>
            <div class="custom-control custom-radio">
              <input
                type="radio"
                [id]="feeWaiverControlName.Other"
                [attr.name]="feeWaiverControlName.FeeWaiverCause"
                [formControlName]="feeWaiverControlName.FeeWaiverCause"
                [value]="feeWaiverCause.Other"
                class="form-check-input"
                />
              <label class="form-check-label" [attr.for]="feeWaiverControlName.Other">
                {{ 'FEE_WAVER_FORM.OTHER' | translate }}</label
                >
              </div>
            </div>
          }
        </form>
      </section>
