export enum PprfPropertyFormControlName {
  PropertyType = 'propertyType',
  RightType = 'rightType',
  ShareNumeratorFrom = 'shareNumeratorFrom',
  ShareDenominatorFrom = 'shareDenominatorFrom',
  ShareNumeratorTo = 'shareNumeratorTo',
  ShareDenominatorTo = 'shareDenominatorTo',
  LandRegistryAreaFrom = 'landRegistryAreaFrom',
  LandRegistryAreaTo = 'landRegistryAreaTo',
  RealEstateAreaFrom = 'realEstateAreaFrom',
  RealEstateAreaTo = 'realEstateAreaTo',
  Place = 'place',
  Street = 'street',
  Number = 'number',
}

export class PprfPropertyFormModel {
  constructor(
    public propertyType: number[],
    public rightType: number[],
    public shareNumeratorFrom: number,
    public shareDenominatorFrom: number,
    public shareNumeratorTo: number,
    public shareDenominatorTo: number,
    public landRegistryAreaFrom: number,
    public landRegistryAreaTo: number,
    public realEstateAreaFrom: number,
    public realEstateAreaTo: number,
    public place: string,
    public street: string,
    public number: string,
  ) {}

  static getEmptyModel(): PprfPropertyFormModel {
    return new this(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
  }

  getShareByRangesControl(): { from: string; to: string } {
    const formatShare = (numerator: number, denominator: number): string =>
      `${numerator}/${denominator}`;

    const fromShare =
      this.shareNumeratorFrom && this.shareDenominatorFrom
        ? formatShare(this.shareNumeratorFrom, this.shareDenominatorFrom)
        : undefined;
    const toShare =
      this.shareNumeratorTo && this.shareDenominatorTo
        ? formatShare(this.shareNumeratorTo, this.shareDenominatorTo)
        : undefined;

    if (fromShare && toShare) {
      const fromRatio = this.shareNumeratorFrom / this.shareDenominatorFrom;
      const toRatio = this.shareNumeratorTo / this.shareDenominatorTo;

      return fromRatio > toRatio
        ? { from: toShare, to: fromShare }
        : { from: fromShare, to: toShare };
    }

    return { from: fromShare, to: toShare };
  }
}

export const pprfPropertyFormModel = PprfPropertyFormModel.getEmptyModel();
