export interface ControlNetworkFromApi {
  Id: number;
  Nazwa: string;
}

export class ControlNetwork {
  constructor(public id: number, public name: string) {}

  static fromApiToApp(controlNetworkFromApi: ControlNetworkFromApi): ControlNetwork {
    return new this(controlNetworkFromApi.Id, controlNetworkFromApi.Nazwa);
  }
}

export enum ControlNetworkType {
  Horizontal = 'horizontal',
  Vertical = 'vertical'
}

export enum ControlNetworkEndpoint {
  Horizontal = '/api/system/pktosn/osnowa/pozioma',
  Vertical = '/api/system/pktosn/osnowa/wysokosciowa'
}
