export enum ExtractCertificateFromLocalSpatialDevelopmentPlanOrStudyRequestFormControlName {
  InvestorDetails = 'investorDetails',
  DismissalReasonExplanationDescription = 'dismissalReasonExplanationDescription',
  Comments = 'comments',
  Subject = 'subject',
  WrittenExtractCheckbox = 'writtenExtractCheckbox',
  GraphicExtractCheckbox = 'graphicExtractCheckbox',
  CertificateCheckbox = 'certificateCheckbox',
  Quantity = 'quantity',
  ShippingForm = 'shippingForm',
  RodoClauseAgreeCheckbox = 'rodoClauseAgreeCheckbox'
}
