<div class="jumbotron jumbotron-fluid text-center pt-1 pb-0 mb-0">
  <div [innerHTML]="$webCmwVoivodeshipTranslatedHeaderText | async"></div>
  <hr class="m-0" />
</div>
<div class="d-flex flex-column">
  <div class="container pt-3">
    <div class="row">
      @if (shouldShowMap()) {
        <div class="col-lg-6">
          <div id="map" class="map border border-secondary w-100 mt-1">
            <aol-map #map [width]="'100%'" [height]="'100%'">
              <aol-view
                [center]="[0, 0]"
                [zoom]="0"
                [constrainResolution]="true"
              ></aol-view>
              <aol-interaction-default></aol-interaction-default>
              <aol-layer-vector [zIndex]="1">
                <aol-source-vector #featuresVectorSource> </aol-source-vector>
              </aol-layer-vector>
              <aol-overlay #popupOverlay [autoPan]="true">
                <aol-content>
                  <div id="popup" class="ol-popup">
                    <div [innerHTML]="popupContent"></div>
                  </div>
                </aol-content>
              </aol-overlay>
            </aol-map>
          </div>
        </div>
      }
      <div class="col">
        <div class="d-flex flex-row flex-wrap justify-content-center">
          @for (webPortal of webPortalData; track webPortal.portalId) {
            <li class="web-portal-list">
              <div
                class="card shadow-sm text-center m-1 web-portal-card"
                (click)="onWebPortalCardClick(webPortal)"
              >
                <div class="card-body img-flex-wrapper">
                  <img
                    [src]="webPortal.logoUrl"
                    [alt]="webPortal.name"
                    class="web-portal-logo"
                    [ngClass]="{ grayscale: !isMainPortal(webPortal.url) }"
                  />
                </div>
                <div class="card-footer p-1">
                  <div class="d-inline-block">
                    <div class="block-ellipsis">
                      {{
                        getTranslatedWebPortalDescriptionBySelectedInformationPortalTranslation(
                          webPortal
                        )
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </li>
          }
        </div>
      </div>
    </div>
  </div>
</div>
