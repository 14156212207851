import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { FormBaseComponent } from '../../../form-base/form-base.component';
import { OrthophotomapSelectFieldConfig } from '../../../gk-dynamic-form.model';
import { SettingsFromApi } from '../../../services/settings/settings.model';
import { SettingsService } from '../../../services/settings/settings.service';
import {
  Composition,
  OrthophotomapSelectOption,
} from './orthophotomap-select.model';

@Component({
  selector: 'gk-form-orthophotomap-select.',
  templateUrl: './orthophotomap-select.component.html',
})
export class OrthophotomapSelectComponent
  extends FormBaseComponent
  implements OnInit, OnDestroy
{
  private isAlive = true;
  settings: SettingsFromApi;
  override config: OrthophotomapSelectFieldConfig;
  options: any[] = [];

  constructor(private settingsService: SettingsService) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToSettings();
  }

  subscribeToSettings(): void {
    this.settingsService.settings
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((settings) => {
        this.settings = settings;
        this.updateSelectOptions();
      });
  }

  updateSelectOptions(): void {
    this.options = [
      ...this.getCombinedOrtoSelectOptions(
        this.settings.OrtofotomapaRgbBwLataWykonania,
        Composition.RgbBw
      ),
      ...this.getCombinedOrtoSelectOptions(
        this.settings.OrtofotomapaRgbCirLataWykonania,
        Composition.RgbCir
      ),
    ];
  }

  getCombinedOrtoSelectOptions(
    releaseYears: number[] | undefined,
    composition: Composition
  ): OrthophotomapSelectOption[] {
    return (releaseYears || []).map(
      (releaseYear) => new OrthophotomapSelectOption(composition, releaseYear)
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
