import { Scale } from '../models';

export const scales: Scale[] = [
  new Scale(50, '1: 50', 0.017638879363894, 12),
  // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
  new Scale(100, '1: 100', 0.035277758727788067, 11),
  new Scale(250, '1: 250', 0.08819439681947, 10),
  new Scale(500, '1: 500', 0.17638879363894, 9),
  new Scale(1000, '1: 1 000', 0.35277758727788067, 8),
  new Scale(2000, '1: 2 000', 0.7055551745557613, 7),
  new Scale(5000, '1: 5 000', 1.763887936389403, 6),
  new Scale(10000, '1: 10 000', 3.527775872778806, 5),
  new Scale(25000, '1: 25 000', 8.819439681947, 4),
  new Scale(50000, '1: 50 000', 17.63887936389403, 3),
  new Scale(100000, '1: 100 000', 35.27775872778806, 2),
  new Scale(200000, '1: 200 000', 70.55551745557612, 1),
  new Scale(500000, '1: 500 000', 176.38879363894037, 0),
];

export const mapSheetScales: Scale[] = [
  new Scale(500, '1: 500', 0.17638879363894, 9),
  new Scale(1000, '1: 1 000', 0.35277758727788067, 8),
  new Scale(2000, '1: 2 000', 0.7055551745557613, 7),
  new Scale(5000, '1: 5 000', 1.763887936389403, 6),
];

export function getScaleByResolution(resolution: number): Scale {
  return scales.find((scale) => scale.resolution === resolution);
}

export function getScaleByZoom(zoom: number): Scale {
  return scales.find((scale) => scale.zoom === zoom);
}
