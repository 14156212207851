import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainRoutes } from '../../guards/guard.models';
import { RequestInProgressGuard } from '../../guards/request-in-progress/request-in-progress.guard';
import { SessionGuard } from '../../guards/session/session.guard';
import { MainRequestsListComponent } from '../../shared/main-requests-list/main-requests-list.component';
import { ConstructionLogRequestComponent } from './construction-log-request/construction-log-request.component';
import { ConstructionNotificationRequestComponent } from './construction-notification-request/construction-notification-request.component';
import { ConstructionPortalComponent } from './construction-portal.component';
import { DemolitionNotificationRequestComponent } from './demolition-notification-request/demolition-notification-request.component';
import { PremiseIndependenceNotificationRequestComponent } from './premise-independence-request/premise-independence-notification-request.component';

const constructionPortalRoutes: Routes = [
  {
    path: MainRoutes.ConstructionPortalConstructionNotificationRequest,
    component: ConstructionPortalComponent,
    canActivate: [SessionGuard],
    children: [
      {
        path: '',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
      {
        path: 'new-request',
        component: ConstructionNotificationRequestComponent,
        canDeactivate: [RequestInProgressGuard],
      },
      {
        path: 'requests-list',
        component: MainRequestsListComponent,
      },
      {
        path: '**',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: MainRoutes.ConstructionPortalDemolitionNotificationRequest,
    component: ConstructionPortalComponent,
    canActivate: [SessionGuard],
    children: [
      {
        path: '',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
      {
        path: 'new-request',
        component: DemolitionNotificationRequestComponent,
        canDeactivate: [RequestInProgressGuard],
      },
      {
        path: 'requests-list',
        component: MainRequestsListComponent,
      },
      {
        path: '**',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: MainRoutes.ConstructionPortalPremiseIndependenceRequest,
    component: ConstructionPortalComponent,
    canActivate: [SessionGuard],
    children: [
      {
        path: '',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
      {
        path: 'new-request',
        component: PremiseIndependenceNotificationRequestComponent,
        canDeactivate: [RequestInProgressGuard],
      },
      {
        path: 'requests-list',
        component: MainRequestsListComponent,
      },
      {
        path: '**',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: MainRoutes.ConstructionPortalConstructionLogRequest,
    component: ConstructionPortalComponent,
    canActivate: [SessionGuard],
    children: [
      {
        path: '',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
      {
        path: 'new-request',
        component: ConstructionLogRequestComponent,
        canDeactivate: [RequestInProgressGuard],
      },
      {
        path: 'requests-list',
        component: MainRequestsListComponent,
      },
      {
        path: '**',
        redirectTo: 'new-request',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(constructionPortalRoutes)],
  exports: [RouterModule],
})
export class ConstructionPortalRoutingModule {}
