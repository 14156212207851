<div class="table-wrapper-vh-70">
  <gk-table
    [records]="invoices"
    [settings]="columnSetting"
    (select)="onInvoicesSelect($event)"
    [selectionMode]="tableSelectionModeEnum.Single"
    [loading]="isInvoiceLoading"
    [selectedRows]="selectedInvoices"
  >
  </gk-table>
</div>
<div class="d-flex pt-4">
  <div class="pe-2">
    <button
      (click)="onRefreshInvoices()"
      [disabled]="isInvoiceLoading"
      class="btn btn-sm btn-success"
    >
      <i class="fa fa-refresh"></i>
    </button>
  </div>
  <div>
    <button
      (click)="onCancelTransaction()"
      [disabled]="!selectedInvoices.length"
      class="btn btn-sm btn-success"
    >
      {{ 'GK.PAYMENTS.TRANSACTION_CANCEL' | translate }}
    </button>
  </div>
</div>
