import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { takeWhile } from 'rxjs/operators';
import { ObjectControlConfig } from '../../../gk-dynamic-list.model';
import { Control } from '../control';
import { DetailsRow } from './control-object.model';

@Component({
  selector: 'gk-control-object',
  styleUrls: ['./control-object.component.scss'],
  templateUrl: './control-object.component.html',
})
export class ControlObjectComponent
  extends Control<Record<string, unknown>>
  implements OnInit
{
  @Input() override controlConfig: ObjectControlConfig;
  yes = '';
  no = '';

  override ngOnInit(): void {
    super.ngOnInit();
    this.getTranslationForBool();
  }

  getTranslationForBool(): void {
    this.translateService
      .get('GK.DYNAMIC_LIST.YES')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((yesString) => (this.yes = yesString));
    this.translateService
      .get('GK.DYNAMIC_LIST.NO')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((noString) => (this.no = noString));
  }

  getUiStringForBoolean(value: boolean): string {
    return value ? this.yes : this.no;
  }

  getSimplyKeyValueRows(): DetailsRow[] {
    if (this.isEmptyValue()) {
      return [];
    }
    const data = this.getValue();

    return Object.keys(data).reduce((rows, key) => {
      return !_.isUndefined(data[key as keyof typeof data]) &&
        !_.isNull(data[key as keyof typeof data]) &&
        !_.isObject(data[key as keyof typeof data])
        ? [
            ...rows,
            new DetailsRow(
              key,
              _.isBoolean(data[key as keyof typeof data])
                ? this.getUiStringForBoolean(
                    data[key as keyof typeof data] as unknown as boolean
                  )
                : (data[key as keyof typeof data] as any)
            ),
          ]
        : rows;
    }, []);
  }

  getSimplyKeyValueRowsWithOmit(): DetailsRow[] {
    return this.getSimplyKeyValueRows().filter(
      (detailsRow) =>
        !_.includes(this.controlConfig.propertiesToOmit, detailsRow.label)
    );
  }
}
